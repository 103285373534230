import * as S from '@unionfab/ufc-shop-commons';
import { useEffect, useRef } from 'react';

import { useInquiryQuoteCheckoutStore } from '@/stores';

import styles from './index.module.less';

/** 让用户填写信用卡的表单 */
export const OrderOceanPaymentSafeCodeWindow = ({
  openNewWindow,
}: {
  openNewWindow: boolean;
}) => {
  const safeCodeWindowRef: any = useRef();

  const {
    oceanpaymentPayUrlWindowOpen,
    setOceanpaymentPayUrlWindowOpen,
    oceanpaymentPayUrl,
  } = useInquiryQuoteCheckoutStore();

  if (openNewWindow) {
    return (
      <>
        {/* {oceanpaymentPayUrlWindowOpen && oceanpaymentPayUrl && (
          <NewWindow
            url={oceanpaymentPayUrl}
            features={{ left: 200, top: 200, width: 500, height: 500 }}
            onUnload={() => setOceanpaymentPayUrlWindowOpen(false)}
          />
        )} */}
      </>
    );
  } else {
    return (
      <>
        {oceanpaymentPayUrl && !openNewWindow && (
          <iframe
            ref={safeCodeWindowRef}
            width="100%"
            height="500px"
            src={oceanpaymentPayUrl}
            frameBorder="0"
            onAbort={() => setOceanpaymentPayUrlWindowOpen(false)}
          />
        )}
      </>
    );
  }
};

export const OrderOceanPaymentForm = ({
  oceanpaymentRef,
}: {
  oceanpaymentRef: any;
}) => {
  const {
    order,
    oceanpaymentFrameVersion,
    oceanpaymentEndpoint,
    oceanpaymentCheckoutPageUrl,
    setPaymentResult,
  } = useInquiryQuoteCheckoutStore();

  useEffect(() => {
    if (oceanpaymentRef && oceanpaymentRef.current) {
      const cssUrl =
        'https://ufc-oversea.oss-accelerate.aliyuncs.com/oceanpayment.css';

      oceanpaymentRef.current.onload = () => {
        oceanpaymentRef.current.contentWindow.postMessage(
          { methodType: 'init', cssUrl, language: 'en_US' },
          oceanpaymentEndpoint,
        );
      };
    }

    if (
      oceanpaymentFrameVersion > 0 &&
      oceanpaymentRef &&
      oceanpaymentRef.current
    ) {
      const src = oceanpaymentRef.current.src;
      oceanpaymentRef.current.src = src;
    }

    const informationValidCallback = createOceanpayValidationMsgCb(
      oceanpaymentEndpoint,
      msg => {
        setPaymentResult({ success: false, failureMessage: msg });
      },
    );
    window.addEventListener('message', informationValidCallback);

    return () => {
      window.removeEventListener('message', informationValidCallback);
    };
  }, [
    oceanpaymentFrameVersion,
    oceanpaymentEndpoint,
    oceanpaymentRef,
    setPaymentResult,
  ]);

  if (!order) {
    return <></>;
  }

  return (
    <div className={styles.oceanpaymentContainer}>
      <div className={styles.oceanpaymentFrameContainer}>
        <iframe
          ref={oceanpaymentRef}
          id="oceanpayment-iframe-region"
          name="oceanpayment-iframe-region"
          width="100%"
          height="170px"
          style={{ overflow: 'hidden' }}
          src={`${oceanpaymentCheckoutPageUrl}`}
          frameBorder="0"
          seamless
        />
      </div>
    </div>
  );
};

/**
 * 用户卡号相关信息输入校验异常消息处理
 *
 * @param endpoint 处理来自该源地址的消息
 */
function createOceanpayValidationMsgCb(
  endpoint: string,
  onInformationError: (msg: string) => void,
) {
  return async (e: MessageEvent) => {
    if (!e || e.origin !== endpoint) {
      return;
    }
    const msg = S.parseOceanpayCbEvent(e);
    if (!msg || msg.type !== 'informationInvalid') {
      return;
    }

    onInformationError(msg.data.msg);
  };
}
