import { isNil } from 'lodash-es';

import { BaseEntity } from '../../../common';

export class PayMethod extends BaseEntity<PayMethod> {
  name: string;
  // 支付比例
  payRatio: number;
  // 支付期限
  timeLimit: number;
  // 期限单位
  timeLimitUnit: 'HOURS' | 'DAYS';

  get payMethodStr() {
    const { timeLimitUnit, timeLimit, payRatio, name } = this;
    const timeLimitUnitStr = timeLimitUnit === 'DAYS' ? '天' : '小时';

    return `预付${!isNil(payRatio) ? payRatio * 100 : '-'}% ${name || '-'} ${
      !isNil(timeLimit) ? timeLimit : '-'
    }${timeLimitUnitStr}内结清`;
  }

  constructor(props?: Partial<PayMethod>) {
    super(props);

    Object.assign(this, props);

    // 兼容为 null 显示的情况
    if (this.name === 'null') {
      this.name = '';
    }

    if (!this.timeLimitUnit) {
      this.timeLimitUnit = 'DAYS';
    }
  }
}

export const getPaymentFailureSolutions = (code: number) => {
  switch (code) {
    case 80010:
    case 80011:
    case 80012:
    case 80013: {
      return [
        '1. Try to Pay again, your card issuer may accept your payment. Or change another card to pay.',
        '2. Call the 800 number on the back of the card, ask your card issuer accept your payment, then pay again.',
      ];
    }
    case 80014:
      return ['Contact us to find out how to do'];
    case 80020:
      return ['Contact us to confirm the transaction amount.'];
    case 80021:
      return [
        'Please confirm the card number is right or change another card to pay.',
      ];
    case 80022:
      return [
        'Please confirm the card has enough money or change another card to pay.',
      ];
    case 80023:
      return [
        'Please try  Pay again or Call your card issuer or change another card to pay.',
      ];
    case 80024:
      return ['Please input the right PIN.'];
    case 80025:
      return [
        'Sorry, your card is unable to authorised. Please try  Pay again or Call your card issuer or change another card to pay.',
      ];
    case 80026:
      return ['Please input the right 3-digital CVV2/CSC.'];
    case 80027:
      return [
        'Please contact your card issuer to fix the problem or Change another card to pay',
      ];
    case 80028:
    case 80030:
      return [
        'Please contact your card issuer to fix the problem or Change another card to pay.',
      ];
    case 80031:
      return ['Please cancel this payment and try to pay again.'];
    case 80032:
    case 80033:
    case 80034:
    case 80035:
    case 80036:
    case 80037:
      return [
        'Sorry, Please try  Pay again or Please contact us，we’ll reply as soon as possible.',
      ];
    case 80038:
      return ['Contact us to find out how to do.'];
    case 80039:
      return [
        'Sorry, the card type is not supported.Please change a credit card.',
      ];
    case 80040:
    case 80041:
    case 80042:
      return [
        'Your payment request is declined, please contact customer service.',
      ];
    case 10000:
    case 80093:
    case 80071:
    case 80091:
      return [
        'Your payment request is declined, please contact us for more details.',
      ];
    default:
      return [];
  }
};
