export function getMetricsFromWindowPerformance() {
  const pageMetrics = {
    ttfb: undefined,
    firstContentfulPaint: undefined,
    largestContentfulPaint: undefined,
  };

  if (typeof window === 'undefined') {
    return pageMetrics;
  }

  const entryList = window.performance.getEntries();

  for (const entry of entryList) {
    if (entry.entryType === 'navigation') {
      pageMetrics.ttfb =
        (entry as PerformanceNavigationTiming).responseStart -
        (entry as PerformanceNavigationTiming).requestStart;
    }

    if (entry.entryType === 'paint') {
      if (entry.name === 'first-contentful-paint') {
        pageMetrics.firstContentfulPaint = entry.startTime;
      }
    }
  }

  return pageMetrics;
}
