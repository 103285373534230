import {
  DeleteOutlined,
  HistoryOutlined,
  PlusOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import * as S from '@unionfab/ufc-shop-commons';
import { getBuiltinTechCategoryList } from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Button, Checkbox, Divider, Modal, Popconfirm, Space } from 'antd';
import { useEffect } from 'react';

import { useInquiryQuoteEditorStore } from '@/stores';

import { useAppNavigate } from '../../../../../shared';
import { QuoteOrderItemBulkConfiguration } from '../QuoteOrderItemBulkConfiguration';
import styles from './index.module.less';

export const QuoteOrderItemsHeader = () => {
  const nav = useAppNavigate();

  const {
    modelFileMap,
    changeTechNameInInquiryQuoteEditorStore,
    curTechCategoryName,
    modelFileIdsToEdit = [],
    setModelFileIdsToEdit,
    removeOrderItemViaFiles,
    d3ModelUploaderId,
    resetInquiryOrder,
  } = useInquiryQuoteEditorStore();
  const modelFiles = Object.values(modelFileMap);

  const modelCountText = (() => {
    if (modelFileIdsToEdit.length !== 0) {
      if (modelFileIdsToEdit.length > 1) {
        return `${modelFileIdsToEdit.length} files selected`;
      } else {
        return `${modelFileIdsToEdit.length} file selected`;
      }
    } else {
      if (modelFiles.length > 1) {
        return `${modelFiles.length} files uploaded`;
      } else {
        return `${modelFiles.length} file uploaded`;
      }
    }
  })();

  useEffect(() => {
    const techType = localStorage.getItem('techCategoryType');
    const tech = techType
      ? (getBuiltinTechCategoryList().find(
          el => el.type === techType,
        ) as S.TechCategory)
      : null;

    if (!!tech && tech.name !== curTechCategoryName) {
      changeTechNameInInquiryQuoteEditorStore(tech.name);
    }
  }, []);

  const deleteModelBtn = (
    <Space>
      <Button
        type="primary"
        style={{ textShadow: 'none', marginRight: '10px' }}
        onClick={() => {
          Modal.confirm({
            wrapClassName: 'order_items_creator_file_indicator_delete_modal',
            title: i18nFormat(
              `Are you sure you want to delete ${modelFileIdsToEdit.length} ${
                modelFileIdsToEdit.length > 1 ? 'parts' : 'part'
              } ?`,
            ),
            okButtonProps: {
              style: { textShadow: 'none', boxShadow: 'none' },
            },
            okText: i18nFormat('Delete'),
            cancelText: i18nFormat('Cancel'),
            onOk: () => removeOrderItemViaFiles(modelFileIdsToEdit),
          });
        }}
      >
        {`${i18nFormat('Delete')} ${modelFileIdsToEdit.length} ${i18nFormat(
          modelFileIdsToEdit.length > 1 ? 'files' : 'file',
        )}`}
      </Button>
    </Space>
  );

  const addModelBtn = (
    <Button
      icon={<PlusOutlined />}
      type="link"
      onClick={() => {
        const $el = document
          .querySelector(`#${d3ModelUploaderId}`)
          ?.querySelector('input');
        if ($el) {
          $el.click();
        }
      }}
    >
      {i18nFormat('Add Models')}
    </Button>
  );

  const selectAllCheckbox = (
    <Checkbox
      defaultChecked={false}
      checked={modelFileIdsToEdit.length > 0}
      onChange={e => {
        if (e.target.checked) {
          const nextToEditModelIds = modelFiles.map(model => model.id!);
          setModelFileIdsToEdit(nextToEditModelIds);
        } else {
          setModelFileIdsToEdit([]);
        }
      }}
    />
  );

  const bulkConfigurationBtn = (
    <QuoteOrderItemBulkConfiguration>
      <Button icon={<SettingOutlined />} type="link">
        {i18nFormat('Bulk Configuration')}
      </Button>
    </QuoteOrderItemBulkConfiguration>
  );

  const resetBtn = (
    <Popconfirm
      title={i18nFormat('Are you sure you want to remove all?')}
      onConfirm={resetInquiryOrder}
    >
      <Button icon={<DeleteOutlined />} type="link">
        {i18nFormat('Reset')}
      </Button>
    </Popconfirm>
  );

  return (
    <div className={styles.headerOption} style={{ marginTop: 16 }}>
      <div className={styles.headerOptionFileSelection}>
        {selectAllCheckbox}
        <span className={styles.modelCountText}>{modelCountText}</span>
      </div>
      <div className={styles.headerOptionBtns}>
        <Space size={8}>
          {modelFileIdsToEdit.length !== 0 ? (
            <>
              {deleteModelBtn}
              {addModelBtn}
              {bulkConfigurationBtn}
            </>
          ) : (
            <>
              {addModelBtn}
              {bulkConfigurationBtn}
              {resetBtn}
              <Divider type="vertical" style={{ margin: 0 }} />
              <Button
                icon={<HistoryOutlined />}
                type="link"
                onClick={() => nav.navToQuotes()}
              >
                {i18nFormat('Quotes History')}
              </Button>
            </>
          )}
        </Space>
      </div>
    </div>
  );
};
