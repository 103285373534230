import { CloseCircleFilled } from '@ant-design/icons';
import {
  doConfirmOrderByCustomer,
  get,
  getTenantIdFromGConfig,
  i18nFormat,
} from '@unionfab/ufc-shop-commons';
import { Button, message, Tooltip } from 'antd';

import { useInquiryQuoteCheckoutStore } from '@/stores';

import { OrderBankTransferBtn } from '../../../../../components/pay/bank-transfer/OrderBankTransferBtn';
import { OrderCheckoutTerms } from '../../order-checkout-fields';
import styles from './index.module.less';
import { OrderCouponSelector } from './OrderCouponSelector';
import { OrderOceanpaymentBtn } from './OrderOceanpayment';
import { OrderPayViaPaypalButton } from './OrderPayViaPayPal';

export const OrderPaymentPaySubmitters = ({
  oceanpaymentRef,
}: {
  oceanpaymentRef: any;
}) => {
  const {
    order,
    couponError,
    paymentChannel,
    shipOptionDraft,
    saveShipOption,
    orderDeliverAddress,
    canAutoConfirmAndWaitPay,
    billingAddress,
    setPaymentResult,
    paymentPrice,
    canPay,
  } = useInquiryQuoteCheckoutStore();

  // 支付前提交所有需要被提交的数据；若订单待审核，自动确认
  const saveOrderShipOptionForPayment = async (antdMessageKey: string) => {
    if (!order) {
      message.loading({ content: 'No order loaded', key: antdMessageKey });
      return;
    }

    // 提交、保存物流选项
    if (shipOptionDraft) {
      message.loading({
        content: 'Saving shipping options',
        key: antdMessageKey,
      });

      try {
        await saveShipOption();
      } catch (e) {
        setPaymentResult({
          success: false,
          failureMessage: 'Error saving shipping options',
        });
        return;
      }
    }

    switch (order.status) {
      case 'WAIT_REVIEW':
        if (canAutoConfirmAndWaitPay) {
          message.loading({
            content: 'Confirming order',
            key: antdMessageKey,
          });
          await doConfirmOrderByCustomer(getTenantIdFromGConfig(), orderCode);
        } else {
          setPaymentResult({
            success: false,
            failureMessage:
              'The order need to be reviewed, please contact our customer service',
          });
        }
        break;
      case 'WAIT_CUSTOM_CONFIRM':
        break;
      default:
        setPaymentResult({
          success: false,
          failureMessage:
            'Illegal order state, refresh and try again, or contact our customer service for help',
        });
    }
  };

  const orderCode = get(order, o => o.code);

  const getWarningText = () => {
    let warningText;

    if (!orderDeliverAddress) {
      warningText = i18nFormat('Please select a shipping address');
    } else if (paymentPrice && !paymentPrice.shipping) {
      warningText = i18nFormat('Please select a shipping option');
    } else if (!paymentChannel) {
      warningText = i18nFormat('Please select a payment method');
    } else if (paymentChannel === 'oceanpay') {
      if (!billingAddress) {
        warningText = i18nFormat('Please select a billing receive address');
      } else if (!billingAddress.firstName || !billingAddress.lastName) {
        warningText = i18nFormat(
          'Please select a billing address with First name & Last name',
        );
      }
    }

    return warningText;
  };

  return (
    <div>
      {order && <OrderCouponSelector />}

      {(!paymentChannel || paymentChannel !== 'paypal') && (
        <div className={styles.agreementTermOfService}>
          <OrderCheckoutTerms />
        </div>
      )}

      {couponError && (
        <div className={styles.errorContainer}>
          <CloseCircleFilled />
          <span className={styles.errorContainerDesc}>{couponError}</span>
        </div>
      )}

      <div className={styles.paymentBtnContainer}>
        {!paymentChannel && (
          <Tooltip
            title={getWarningText()}
            overlayInnerStyle={{ width: '100%' }}
          >
            <Button
              type="primary"
              size="large"
              style={{ width: '100%' }}
              disabled={getWarningText()}
            >
              {i18nFormat('Continue')}
            </Button>
          </Tooltip>
        )}

        {paymentChannel === 'paypal' && (
          <OrderPayViaPaypalButton
            saveOrderShipOptionForPayment={saveOrderShipOptionForPayment}
          />
        )}

        {paymentChannel === 'oceanpay' && (
          <OrderOceanpaymentBtn
            oceanpaymentRef={oceanpaymentRef}
            saveOrderShipOptionForPayment={saveOrderShipOptionForPayment}
          />
        )}

        {paymentChannel === 'bank-transfer' && (
          <OrderBankTransferBtn orderCode={order?.code} canPay={canPay} />
        )}
      </div>
    </div>
  );
};
