import { BaseEntity, CurrencyAndAmount } from '../../../common';
import { ShopOrderQuotationRfqReason } from './ShopOrderQuotation';
import { ShopOrderQuotationStatus } from './types';

/** 物流报价 */
export class ShopOrderDeliveryQuotation extends BaseEntity<ShopOrderDeliveryQuotation> {
  /** rfq 原因列表 */
  reason: ShopOrderQuotationRfqReason[] = [];

  /** 仅在获取报价接口中起效果，表示是否计算中 */
  status: ShopOrderQuotationStatus;

  prices: {
    expressId: string;
    price: CurrencyAndAmount;
    status: ShopOrderQuotationStatus;
  }[];

  constructor(data: Partial<ShopOrderDeliveryQuotation> = {}) {
    super(data);

    BaseEntity.assignInConstructor(this, data);
  }
}
