import { Dayjs } from 'dayjs';
import { create, StoreApi, UseBoundStore } from 'zustand';
import { devtools } from 'zustand/middleware';

import {
  getTenantIdFromGConfig,
  getUfcShopComposedApi,
  OverseaOrderStatus,
  ShopOrderStatusToInquiryOrderStatus,
} from '../../apis';
import * as S from '../../schema';
import { PaginationInfo } from '../../schema';

export interface OrderListQueryParams extends S.ShopOrdersQueryParams {
  orderStatus: OverseaOrderStatus;
}

export interface IOrderListStoreState {
  isLoading: boolean;
  queryParams: Partial<OrderListQueryParams>;
  orderStatusList: OverseaOrderStatus[];
  orderList: S.ShopOrder[];
  orderMap: Partial<Record<OverseaOrderStatus, Record<S.Id, S.ShopOrder>>>;

  orderPagination?: PaginationInfo;
  orderCounts?: Record<S.InquiryOrderStatus, number>;
}

export type IOrderListStore = UseBoundStore<StoreApi<IOrderListStoreState>>;

export const useOrderListStore: IOrderListStore = create<IOrderListStoreState>()(
  devtools(
    (set, get) => {
      return {
        isLoading: false,
        queryParams: {
          pageNum: 1,
          pageSize: 3,
          orderStatus: 'PENDING',
          sortBy: 'desc(updateTime)',
        },
        orderStatusList: [
          'ALL',
          'PENDING',
          'PRINTING',
          'TOSHIP',
          'SHIPPED',
          'COMPLETED',
          'CANCELED',
        ],
        orderList: [],
        orderMap: {},
      };
    },
    { name: 'orderListStore' },
  ),
);

export const setQueryParamsInOrderListStore = async (
  queryParams: Partial<OrderListQueryParams>,
  store: IOrderListStore = useOrderListStore,
) => {
  store.setState({ queryParams });

  loadOrdersInOrderListStore(queryParams, store);

  loadOrderCountsInOrderListStore(queryParams, store);
};

export const loadOrdersInOrderListStore = async (
  queryParams?: Partial<OrderListQueryParams>,
  store: IOrderListStore = useOrderListStore,
) => {
  queryParams = queryParams || store.getState().queryParams;

  store.setState({ isLoading: true });

  try {
    const {
      pageNum,
      orderStatus,
      startDateTime,
      endDateTime,
      searchText,
      sortBy,
      pageSize,
    } = queryParams;

    const curStatusMapList = ShopOrderStatusToInquiryOrderStatus[orderStatus];

    const {
      data,
      pagination,
    } = await getUfcShopComposedApi().shopOrderQueryApi.queryShopOrders({
      pageNum: pageNum - 1,
      pageSize,
      startDateTime,
      endDateTime,
      searchText,
      sortBy,
      status: curStatusMapList,
      tenantId: getTenantIdFromGConfig(),
      includes: ['material', 'sku'],
    });

    store.setState({ orderList: data, orderPagination: pagination });
  } finally {
    store.setState({ isLoading: false });
  }
};

export const loadOrderCountsInOrderListStore = async (
  queryParams?: Partial<OrderListQueryParams>,
  store: IOrderListStore = useOrderListStore,
) => {
  queryParams = queryParams || store.getState().queryParams;
  const { startDateTime, endDateTime, searchText } = queryParams;

  const orderCounts = await getUfcShopComposedApi().shopOrderQueryApi.queryShopOrderStatistics(
    {
      searchText,
      endDateTime,
      startDateTime,
      tenantId: getTenantIdFromGConfig(),
    },
  );

  store.setState({ orderCounts });
};

export const loadOrderMapInOrderListStore = async (
  queryParams?: Partial<OrderListQueryParams>,
  reload = false,
) => {
  const store = useOrderListStore;

  queryParams = queryParams || store.getState().queryParams;

  store.setState({ isLoading: true });

  try {
    const {
      pageNum,
      orderStatus,
      startDateTime,
      endDateTime,
      searchText,
      sortBy,
    } = queryParams;
    const curStatusMapList = ShopOrderStatusToInquiryOrderStatus[orderStatus];

    const {
      data,
      pagination,
    } = await getUfcShopComposedApi().shopOrderQueryApi.queryShopOrders({
      pageNum: pageNum - 1,
      pageSize: 5,
      sortBy,
      startDateTime,
      endDateTime,
      searchText,
      status: curStatusMapList,
      tenantId: getTenantIdFromGConfig(),
      includes: ['material', 'sku'],
    });

    const currentStatusOrderMap = reload
      ? {}
      : store.getState().orderMap[orderStatus] || {};

    const orderMap = {
      ...store.getState().orderMap,
      [orderStatus]: currentStatusOrderMap,
    };

    (data || []).forEach(order => {
      orderMap[orderStatus][order.id] = order;
    });

    store.setState({ orderMap, orderPagination: pagination });
  } finally {
    store.setState({ isLoading: false });
  }
};
