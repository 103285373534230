import { fetchCountries } from '@unionfab/ufc-shop-commons';
import * as S from '@unionfab/ufc-shop-commons';
import { AutoComplete, Input } from 'antd';
import {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { getCountryCallingCode } from 'react-phone-number-input';

import styles from './CountrySelect.module.less';
import { highlight } from './utils';

const CountryFlag = lazy(() => import('./CountryFlag'));

export interface CountrySelectProps {
  country:
    | undefined
    | { type: 'iso2'; value: string }
    | { type: 'iso3'; value: string }
    | { type: 'name'; value: string };
  onCountryChange: (v: {
    name: string;
    // iso2 不存在可以认为填、选中国家未通过校验
    iso2?: string;
    iso3?: string;
    callingCode?: string;
    flag?: JSX.Element;
  }) => void;
}

interface CountryMaps {
  iso2ToCountry: { [k: string]: S.Country };
  iso3ToCountry: { [k: string]: S.Country };
  nameToCountry: { [k: string]: S.Country };
}

// 此组件当前国家状态
interface CurCountry {
  // 实际输入值
  name: string;
  // 输入值对应国家
  country?: S.Country;
}

export const CountrySelect = ({
  country,
  onCountryChange,
}: CountrySelectProps) => {
  const [countries, setCountries] = useState<S.Country[]>([]);

  useEffect(() => {
    fetchCountries().then(setCountries);
  }, []);

  const countryMaps: CountryMaps = useMemo(() => {
    const iso2ToCountry: { [k: string]: S.Country } = {};
    const iso3ToCountry: { [k: string]: S.Country } = {};
    const nameToCountry: { [k: string]: S.Country } = {};
    countries.forEach(c => {
      iso2ToCountry[c.iso2] = c;
      iso3ToCountry[c.iso3] = c;
      nameToCountry[c.name] = c;
    });
    return { iso2ToCountry, iso3ToCountry, nameToCountry };
  }, [countries]);

  const createFlag = useCallback(
    (name?: string) => {
      const c = countryMaps.nameToCountry[name!];
      const flagStyle = { width: 20 };
      return (
        <Suspense
          fallback={<span className={styles.countryFlag} style={flagStyle} />}
        >
          <span className={styles.countryFlag} style={flagStyle}>
            <CountryFlag iso2={c?.iso2!} title={c?.name} />
          </span>
        </Suspense>
      );
    },
    [countryMaps],
  );

  const curCountry: CurCountry | undefined = useMemo(() => {
    let c: S.Country | undefined;
    let name = '';
    switch (country?.type) {
      case 'iso2':
        c = countryMaps.iso2ToCountry[country.value];
        name = c?.name!;
        break;
      case 'iso3':
        c = countryMaps.iso3ToCountry[country.value];
        name = c?.name!;
        break;
      case 'name':
        name = country.value;
        c = countryMaps.nameToCountry[country.value];
        break;
      default:
    }

    const flag = createFlag(name);
    return { name, flag, country: c };
  }, [country, countryMaps, createFlag]);

  const filterFunction = (inputValue: string | undefined, v: any) => {
    if (!v || !v.value || !inputValue || inputValue.length === 0) {
      return true;
    }
    return v.value.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
  };

  const onCountryName = (v: string) => {
    if (onCountryChange) {
      const country = countryMaps.nameToCountry[v];
      if (country) {
        const flag = createFlag(v);
        let callingCode = '';
        try {
          callingCode = getCountryCallingCode(country.iso2 as any);
        } catch (e) {
          //
        }
        onCountryChange({
          iso2: country.iso2,
          iso3: country.iso3,
          name: country.name,
          callingCode: callingCode as string,
          flag,
        });
      } else {
        onCountryChange({ name: v });
      }
    }
  };

  const countryOptions = useMemo(() => {
    return countries.map(v => {
      const name = v.name;
      const flag = createFlag(name);

      const nameTexts = highlight(name, curCountry.name);

      return {
        value: name,
        label: (
          <div className={styles.countryNameContainer}>
            <span className={styles.countryFlag}>{flag}</span>
            <span className={styles.countryName}>
              {nameTexts.map((t, idx) =>
                t.highlight ? (
                  <b key={idx}>{t.text}</b>
                ) : (
                  <span key={idx}>{t.text}</span>
                ),
              )}
            </span>
          </div>
        ),
      };
    });
  }, [countries, curCountry, createFlag]);

  return (
    <AutoComplete
      allowClear
      backfill={true}
      value={curCountry?.name}
      onChange={onCountryName}
      onSelect={onCountryName}
      filterOption={filterFunction}
      options={countryOptions}
      className={styles.countrySelectWrapper}
    >
      <Input prefix={createFlag(curCountry?.name)} placeholder="Country" />
    </AutoComplete>
  );
};
