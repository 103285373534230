import { isNil, omitBy } from 'lodash-es';

import * as S from '../../../../schema';
import { BillingAddress } from '../../../../schema';
import {
  getTenantIdFromGConfig,
  getUfcShopComposedApi,
} from '../../../../singleton-ins';

export const LK_CUR_COUPON_CODE = 'LK_CUR_COUPON_CODE';

export const createPaymentOrderOnPaypal = async (
  orderId: string,
  couponCode?: string,
) => {
  const couponCodeFromStorage = localStorage.getItem(LK_CUR_COUPON_CODE);

  const params = omitBy(
    { couponCode: couponCode || couponCodeFromStorage },
    isNil,
  );

  const {
    data,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.postUmi<{
    data: { payOrderId: string; payPalOrderId: string };
  }>(`/order/${orderId}/pay/paypal?tenantId=${getTenantIdFromGConfig()}`, {
    data: params,
  });

  localStorage.removeItem(LK_CUR_COUPON_CODE);

  return data;
};

export const completePaymentOrderOnPaypal = async (
  orderId: string,
  payPalOrderId: string,
  payerId: string,
) => {
  if (!orderId || !payPalOrderId || !payerId) {
    return Promise.reject(
      new Error('orderId, payPalOrderId, payerId 不能为空'),
    );
  }
  return await getUfcShopComposedApi().umiRequestCompatibleApi.postUmi(
    `/order/${orderId}/pay/paypal/${payPalOrderId}?tenantId=${getTenantIdFromGConfig()}`,
    {
      data: {
        payerId,
      },
    },
  );
};

export const noticeOceanPayRes = async (
  tenantId: S.Id,
  payOrderId: S.Id,
  xmlData: string,
) => {
  const res = await getUfcShopComposedApi().umiRequestCompatibleApi.postUmi<any>(
    `/order/pay/notify/oceanpay/notice`,
    { params: { tenantId, payOrderId }, data: xmlData },
  );

  return 'receive-ok' === res;
};

/** 创建/获取钱海支付订单信息 */
export const getOceanpayInfo = async (
  orderId: S.Id,
  tenantId: S.Id,
  billingEmail: string,
  billingAddress: S.ReceiveAddress,
  o: Partial<{
    methods: string;
    backUrl: string;
    couponCode: string;
    description: string;
  }>,
) => {
  const options = omitBy(
    {
      ...o,
      billing: {
        ip: '0.0.0.0',
        email: billingEmail,
        firstName: billingAddress.firstName,
        lastName: billingAddress.firstName,
        country: billingAddress.country,
        state: billingAddress.province,
        city: billingAddress.city,
        zip: billingAddress.postcode,
        address: billingAddress.street,
        phone: billingAddress.phoneNumber,
      },
      ship: {
        email: billingEmail,
        firstName: billingAddress.firstName,
        lastName: billingAddress.lastName,
        phone: billingAddress.phoneNumber,
        country: billingAddress.country,
        state: billingAddress.province,
        city: billingAddress.city,
        addr: billingAddress.street,
        zip: billingAddress.postcode,
      },
    },
    isNil,
  );

  if (o.couponCode && o.couponCode == "Don't choose coupon") {
    delete options.couponCode;
  }

  const {
    data: { data },
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.postUmi<{
    data: { data: S.OceanpaymentInfo };
  }>(`/order/${orderId}/pay/oceanpay`, {
    params: { tenantId },
    data: options,
  });

  return new S.OceanpaymentInfo(data);
};

/** 获取钱海曾用信用卡信息 */
export const getHistoryCreditCard = async () => {
  return await getUfcShopComposedApi().umiRequestCompatibleApi.getUmi(
    `/person/order/customer/history_credit_card`,
  );
};

/** 获取最新账单信息 */
export const getLatestBillingAddress = async (tenantId: S.Id) => {
  const {
    data,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.getUmi<{
    data: any;
  }>(`/order/pay/oceanpay/latest_billing`, {
    params: { tenantId },
  });

  return {
    ...data,
    phoneNumber: S.get(data, d => d.phone),
    street: S.get(data, d => d.address),
    postcode: S.get(data, d => d.zip),
    province: S.get(data, d => d.state),
  } as BillingAddress;
};
