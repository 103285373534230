import { AccessTokenData } from '../../skeleton';
import { Person } from '../user';

const getUrl = (host: string) =>
  host.includes('http') ? host : `https://${host}`;

/** 一般表示来自于 runtime.js 的配置内容 */
export class UfcShopSiteConfig {
  /** 租户配置 */
  tenantId: string;
  OAUTH_URL?: string;

  theme?: {
    defaultTheme: {
      mainColor: '#192C59';
      mainColorTransparent: '#F8990233';
      footerBgColor: '#111013';
      footerFontColor: '#fff';
      textFontColor: '#666';
      footerHeight: '70px';
      layoutBackgroundColor: '#fff';
      unhoverMenuFontColor: 'rgb(128, 129, 138)';
      selectedMenuBackgroundColor: 'rgb(25, 26, 36)';
      tableBorderColor: '#EAEAEA';
    };
  };

  ENV: string;
  /** 强制使用的 Token */
  TOKEN: string;
  /** 当前站点的根域名 */
  BASE_URL: string;
  ORDER_ENDPOINT: string;

  PAYPAL_CLIENT: string;
  CLIENT_INFO_CLIENT: string;

  constructor(data: Partial<UfcShopSiteConfig> = {}) {
    Object.assign(this, data);

    if (this.BASE_URL) {
      this.BASE_URL = getUrl(this.BASE_URL);
    }
  }
}

/**
 * 表示运行中的一些状态
 * 这是 Next.js 与 Web 项目在都可以使用的一些状态
 * 在 ufc-shop-commons 尽量避免使用 window 对象，而是使用该类的数据
 */
export class UfcShopRuntimeEnv {
  apiEndpoint: string;

  person?: Person;

  user?: AccessTokenData | null;

  constructor(data: Partial<UfcShopSiteConfig>) {
    Object.assign(this, data);
  }
}
