import * as S from '@unionfab/ufc-shop-commons';
import { getUfcShopComposedApi, i18nFormat } from '@unionfab/ufc-shop-commons';
import { Button, Col, Input, message, Row, Spin } from 'antd';
import cn from 'classnames';
import { compact } from 'lodash';
import { useMutation, useQuery } from 'react-query';

import { getUseShopQuoteStoreByCode } from '@/stores';

import styles from './index.module.less';

export interface ShopQuoteBillingInfoProps {
  className?: string;
  style?: React.CSSProperties;

  orderCode: string;
}

export const ShopQuoteBillingInfo = ({
  className,
  style,

  orderCode,
}: ShopQuoteBillingInfoProps) => {
  const shopOrderStore = getUseShopQuoteStoreByCode(orderCode);

  const { billingInfo, isBillingInfoValid, isBillingInfoRequired } =
    shopOrderStore(s => ({
      billingInfo: s.billingInfo,
      isBillingInfoValid: s.isBillingInfoValid,
      isBillingInfoRequired: s.isBillingInfoRequired,
    }));

  const { isLoading, refetch } = useQuery(
    ['billing_info', orderCode],
    async () => {
      try {
        const resp =
          await getUfcShopComposedApi().shopOrderQueryApi.queryShopOrderBillingInfo(
            orderCode,
          );

        shopOrderStore.setState({ billingInfo: resp });
      } catch (e) {
        message.error(i18nFormat('Failed to retrieve billing information.'));
      }
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!orderCode,
    },
  );

  const billingInfoMutation = useMutation({
    mutationFn: async (o: {
      code: string;
      billingInfo: S.ShopOrderBillingInfo;
    }) => {
      if (
        isBillingInfoRequired &&
        compact(Object.values(billingInfo)).length !== 6
      ) {
        shopOrderStore.setState({ isBillingInfoValid: false });

        return Promise.reject('Incomplete form.');
      }

      return await getUfcShopComposedApi().shopOrderUpdateApi.updateShopOrderBillingInfo(
        o.code,
        o.billingInfo,
      );
    },
    onError: err => {
      message.error(
        i18nFormat(typeof err == 'string' ? err : 'Update failed.'),
      );
    },
    onSuccess: () => {
      refetch();
      message.success(i18nFormat('Update successful.'));
    },
  });

  const isValid = (key: string) => {
    if (!billingInfo || !isBillingInfoRequired) return true;

    return !!S.get(billingInfo, b => b[key]);
  };

  return (
    <div style={style} className={cn(className, styles.container)}>
      <Spin spinning={isLoading}>
        <Row gutter={[40, 14]}>
          {[
            { label: 'Company', key: 'companyName' },
            { label: 'VAT/TAX Number', key: 'billingVat' },
            { label: 'Telephone', key: 'companyPhoneNumber' },
            { label: 'Invoice Address', key: 'billingAddress' },
            { label: 'Email', key: 'billingToEmail' },
          ].map(({ label, key }) => (
            <Col span={12} key={key}>
              <span
                className={
                  isBillingInfoRequired ? styles.requiredLabel : styles.label
                }
              >
                {i18nFormat(label)}
              </span>
              <Input
                status={!isValid(key) ? 'error' : ''}
                value={S.get(billingInfo, b => b[key])}
                placeholder={i18nFormat('Please Enter')}
                onChange={({ target: { value } }) => {
                  shopOrderStore.setState({
                    billingInfo: { ...billingInfo, [key]: value },
                  });
                }}
              />
            </Col>
          ))}
          {/* <Col style={{ marginTop: 30 }}>
            <Button onClick={() => refetch()}>{i18nFormat('Reset')}</Button>
            <Button
              type="primary"
              style={{ marginLeft: 12 }}
              loading={billingInfoMutation.isLoading}
              onClick={() =>
                billingInfoMutation.mutateAsync({
                  billingInfo,
                  code: orderCode,
                })
              }
            >
              {i18nFormat('Save')}
            </Button>
          </Col> */}
        </Row>
      </Spin>
    </div>
  );
};

ShopQuoteBillingInfo.displayName = 'ShopQuoteBillingInfo';
