import {
  DownOutlined,
  QuestionCircleOutlined,
  RightOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { usePrevious } from 'ahooks';
import { Button, Tooltip } from 'antd';
import { useEffect, useMemo, useState } from 'react';

import { useInquiryQuoteCheckoutStore } from '@/stores';

import { RfqTooltip } from '../../../../../../../commons/components/Element/RfqTooltip';
import { OrderPaymentPaySubmitters } from '../OrderPaymentPaySubmitters';
import styles from './index.module.less';

export const OrderPaymentSummary = ({
  oceanpaymentRef,
}: {
  oceanpaymentRef: any;
}) => {
  const {
    order,
    paymentPrice,
    estDeliveryTime,
    paymentChannel,
  } = useInquiryQuoteCheckoutStore();
  const previousPaymentChannel = usePrevious(paymentChannel);
  const [isOrderDetailVisible, setIsOrderDetailVisible] = useState(true);

  useEffect(() => {
    // 用户首次勾选了协议，则默认收起
    if (!previousPaymentChannel && paymentChannel) {
      setIsOrderDetailVisible(false);
    }
  }, [paymentChannel]);

  // 目前 subtotal 即材料费
  const subtotal = paymentPrice?.subtotal;
  const subtotalRfq = subtotal == null;
  const discount = paymentPrice?.discount;
  const shipping = paymentPrice?.shipping;
  const total = paymentPrice?.total;

  const estDeliveryTimeDesc = useMemo(() => {
    return S.calcEstDeliverTime(estDeliveryTime, subtotalRfq);
  }, [estDeliveryTime, subtotalRfq]);

  const orderCode = S.get(order, o => o.code);
  const currency = S.get(order, o => o.currency);

  let _maxLeadTimeStr, _dueDate;

  if (order) {
    const { maxLeadTimeStr, dueDate } = S.calcOrderLeadTimes(order);

    dueDate && (_dueDate = dueDate);
    maxLeadTimeStr && (_maxLeadTimeStr = maxLeadTimeStr);
  }

  return (
    <div className={styles.orderSummary}>
      <div className={styles.orderBodyBox}>
        <div className={styles.orderBodyTitle}>
          <span>{i18nFormat('Order Summary')}</span>
          <Button
            size="small"
            shape="circle"
            icon={isOrderDetailVisible ? <DownOutlined /> : <RightOutlined />}
            onClick={() => setIsOrderDetailVisible(!isOrderDetailVisible)}
          />
        </div>

        {isOrderDetailVisible && (
          <div className={styles.orderDetail}>
            <div className={styles.price}>
              <div className={styles.orderCode}>
                {orderCode ? `Code: ${orderCode}` : i18nFormat('Wait Submit')}
                {order && order.isQuotating && (
                  <span style={{ cursor: 'pointer' }}>
                    <Tooltip
                      color={'rgba(248, 153, 2)'}
                      overlayInnerStyle={{ width: '250px' }}
                      overlayClassName={styles.overlayClass}
                      title={i18nFormat(
                        'The order is under quoting, contact customer service for progress.',
                      )}
                      defaultVisible={true}
                    >
                      <img
                        className="order-item-header-right-price-bulb"
                        src={
                          S.getBuiltinImageCollection()
                            .QUOTE_ORDER_ITEM_HANDLER_BULB
                        }
                        alt=""
                        style={{ marginLeft: 8 }}
                      />
                    </Tooltip>
                  </span>
                )}
              </div>

              <div className={styles.spaceBetweenEST}>
                <span className={styles.light}>{i18nFormat('Due Date')}:</span>
                <div className={styles.spaceBetweenRight}>
                  <span>
                    {!subtotalRfq ? (
                      _maxLeadTimeStr
                    ) : (
                      <span className={styles.rfqText}>
                        {_maxLeadTimeStr} <RfqTooltip />
                      </span>
                    )}
                  </span>

                  <div style={{ fontSize: 12 }}>
                    {`(Lead Time: ${_dueDate} days)`}
                    {subtotalRfq && <RfqTooltip />}
                  </div>
                </div>
              </div>
              <div className={styles.spaceBetween}>
                <span className={styles.light}>{i18nFormat('Delivery')}:</span>
                <span>{estDeliveryTimeDesc}</span>
              </div>
            </div>

            <div className={styles.price} style={{ marginTop: '18px' }}>
              <div className={styles.spaceBetween}>
                <span className={styles.light}>{i18nFormat('Subtotal')}:</span>
                <span className={styles.rfqTextContainer}>
                  {order &&
                    subtotal &&
                    S.toPriceStringWithOption(subtotal, {
                      currency: currency,
                    })}
                </span>
              </div>

              {orderCode && (
                <div
                  className={styles.spaceBetween}
                  style={{ margin: '8px 0' }}
                >
                  <span className={styles.light}>
                    {i18nFormat('Discount')}:
                  </span>
                  <span>
                    {order &&
                      discount &&
                      `- ${S.toPriceStringWithOption(discount, {
                        currency: currency,
                      })}`}
                  </span>
                </div>
              )}

              <div className={styles.spaceBetween}>
                <span className={styles.light}>{i18nFormat('Shipping')}:</span>
                <span className={styles.rfqTextContainer}>
                  {order &&
                    shipping != null &&
                    S.toPriceStringWithOption(shipping, {
                      currency: currency,
                    })}
                  {shipping == null && (
                    <Tooltip title="Check shipment options on the left side.">
                      <span className={styles.warningIcon}>
                        <WarningOutlined />
                      </span>
                    </Tooltip>
                  )}
                </span>
              </div>
            </div>
          </div>
        )}

        <div className={styles.totalPrice}>
          <span>
            <span>{i18nFormat('Total')}</span>
            <span className={styles.vatTip}>
              ({i18nFormat('VAT excluded')})
            </span>
            <Tooltip
              title={i18nFormat(
                'According to the Trade Terms: DAP, our price excluded import tariffs.',
              )}
              className={styles.vatIcon}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </span>
          <span>
            {order &&
              total != null &&
              S.toPriceStringWithOption(total, { currency: currency })}
            {total == null && (
              <Tooltip
                title={i18nFormat(
                  'Check options on the left side, if you have any questions, please contact our contact service.',
                )}
              >
                <span className={styles.warningIcon}>
                  <WarningOutlined />
                </span>
              </Tooltip>
            )}
          </span>
        </div>
      </div>

      <OrderPaymentPaySubmitters oceanpaymentRef={oceanpaymentRef} />
    </div>
  );
};
