import * as S from '@unionfab/ufc-shop-commons';
import { Spin } from 'antd';
import Pagination from 'antd/es/pagination';
import cn from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useInquiryQuoteEditorStore } from '@/stores';

import {
  QuoteFilesUploader,
  QuoteFilesUploadingList,
} from '../../../../components/uploader';
import { QuoteEditorEmptyPlaceholder } from '../QuoteEditorEmptyPlaceholder';
import { QuoteOrderItemCard } from '../QuoteOrderItemCard';
import { QuoteOrderItemsHeader } from '../QuoteOrderItemsHeader';
import { QuoteSubmitor } from '../QuoteSubmitPage';
import styles from './index.module.less';

const QuoteOrderItems = () => {
  const { inquiryOrder } = useInquiryQuoteEditorStore();

  const [curPage, setCurPage] = useState(1);
  const [pageSize, setPageSize] = useState(1000);

  const validItems = useMemo(
    () => (inquiryOrder?.items || []).filter(i => S.isValidArray(i.modelFiles)),
    [inquiryOrder?.items],
  );

  const showItems = useMemo(() => {
    return validItems.slice((curPage - 1) * pageSize, curPage * pageSize);
  }, [validItems, curPage, pageSize]);

  return (
    <div className={styles.orderItems}>
      {showItems.map((orderItem, idx) => (
        <QuoteOrderItemCard
          key={idx}
          index={idx}
          orderItem={orderItem}
          size="desktop"
        />
      ))}

      <Pagination
        hideOnSinglePage={true}
        total={validItems.length}
        current={curPage}
        pageSize={pageSize}
        onChange={(page, pageSize) => {
          setCurPage(page);
          setPageSize(pageSize);
        }}
      />
    </div>
  );
};

export const QuoteEditorBody = () => {
  const {
    toggleModelFileD3AttrWatcher,
    isInquiryOrderLoading,
    modelFileMap,
    d3ModelUploaderId,

    loadInquiryOrder,
    onUnloadQuoteEditor,
  } = useInquiryQuoteEditorStore();

  // 通过上传文件映射，计算出当前是否有上传中的文件
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code') || undefined;
  const hasModelFiles = Object.values(modelFileMap).length !== 0;

  useEffect(() => {
    toggleModelFileD3AttrWatcher(true);
    loadInquiryOrder(code);

    return () => {
      toggleModelFileD3AttrWatcher(false);
      // 卸载报价编辑器
      onUnloadQuoteEditor();
    };
  }, []);

  return (
    <Spin spinning={isInquiryOrderLoading} wrapperClassName={styles.spin}>
      <div
        className={cn(
          styles.quoteEditorContainer,
          hasModelFiles && styles.modelFilesContainer,
        )}
      >
        <div className={styles.quoteEditorContent}>
          <div className={styles.quoteEditorHeader}>
            {/* 报价编辑头部按钮 */}
            {hasModelFiles && (
              <span className={styles.quoteEditorItemsHeader}>
                <QuoteOrderItemsHeader />
              </span>
            )}
          </div>

          {/* 报价编辑内容 */}
          <div className={styles.quoteEditorBody}>
            {/* 未上传任何文件时展示它 */}
            {!hasModelFiles && <QuoteEditorEmptyPlaceholder />}
            {/* 上传文件之后，展示已经上传、创建的订单条目，和上传中的列表 */}
            {hasModelFiles && <QuoteOrderItems />}
            {/* 报价编辑底部*/}
            <div>
              <QuoteFilesUploadingList />
              <QuoteFilesUploader uploaderId={d3ModelUploaderId} />
            </div>
          </div>
        </div>

        {/* 询价、概览信息，操作相关 */}
        {hasModelFiles && (
          <div className={styles.quoteEditorOrderOperationsContainer}>
            <div className={styles.quoteEditorOrderOperations}>
              <QuoteSubmitor size="desktop" />
            </div>
          </div>
        )}
      </div>
    </Spin>
  );
};
