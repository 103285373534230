import { LoadingOutlined } from '@ant-design/icons';
import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { getOrderPayOrder } from '@unionfab/ufc-shop-commons';
import { Spin } from 'antd';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';

import { getUseShopQuoteStoreByCode } from '@/stores';

import styles from './index.module.less';

export const ShopQuoteProcessingPage = () => {
  const orderCode = useParams().orderCode;
  const useShopQuoteStore = getUseShopQuoteStoreByCode(orderCode);

  const { shopOrder } = useShopQuoteStore(s => ({
    shopOrder: s.shopOrder,
  }));

  const { isLoading, data: payOrder } = useQuery(['payOrder', shopOrder.id], {
    queryFn: () => {
      if (!shopOrder || !shopOrder.id) return;

      return getOrderPayOrder(shopOrder.id);
    },
  });

  if (!shopOrder) return <></>;

  return (
    <Spin spinning={isLoading} wrapperClassName={styles.container}>
      <div className={styles.result}>
        <div className={styles.processingIcon}>
          <LoadingOutlined />
        </div>

        <div className={styles.thanks}>{i18nFormat('Processing Your Order')}</div>

        <div className={styles.info}>
          <span>
            {i18nFormat('Your order code')}: {orderCode}
          </span>
          {payOrder && (
            <span>
              {i18nFormat('Total Price')}:{' '}
              {S.toPriceStringWithOption(Number(payOrder.amount.amount), {
                currency: payOrder.amount.currency,
              })}
            </span>
          )}
          <br />
          <span>{i18nFormat('We are currently processing your order and finalizing your transaction.')}</span>
          <span>{i18nFormat('This may take a moment.')}</span>
        </div>
      </div>
    </Spin>
  );
};
