import { LoadingOutlined } from '@ant-design/icons';
import { get } from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { StepProps, Steps } from 'antd';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import { getUseShopQuoteStoreByCode } from '../../../../../stores';
import styles from './index.module.less';

export interface ShopNewQuoteStepsProps {
  className?: string;
  style?: Record<string, string | number>;

  currentIndex: number;
  orderCode?: string;
}

export const ShopNewQuoteSteps = ({
  className,
  style,

  currentIndex,
  orderCode,
}: ShopNewQuoteStepsProps) => {
  const nav = useNavigate();
  // orderCode 可能为空，不过不妨碍使用
  const { isLoading, shopOrder } = getUseShopQuoteStoreByCode(orderCode)(s => ({
    shopOrder: s.shopOrder,
    isLoading: s.isLoadingOrComputing,
  }));

  const getIsDisabled = (step: number) => {
    if (!shopOrder || shopOrder.code !== orderCode) {
      return true;
    }

    if (step === 2) return !(currentIndex > 2);

    const hasFile = get(shopOrder, o => o.items, []).length > 0;
    const hasAddress = get(shopOrder, o => o.delivery.items, []).length > 0;

    if (step === 3) return !hasAddress || !hasFile;
  };

  const items: StepProps[] = [
    {
      title: i18nFormat('Upload your models'),
    },
    {
      style: { cursor: getIsDisabled(2) ? 'not-allowed' : 'pointer' },
      title: i18nFormat('Choose material and finish'),
      onClick: () => {
        if (!getIsDisabled(2)) {
          nav(`/new_shop_quote/${orderCode}`);
        }
      },
    },
    {
      title: i18nFormat('Checkout'),
      style: {
        cursor: getIsDisabled(3) ? 'not-allowed' : 'pointer',
      },
      onClick: () => {
        if (!getIsDisabled(3)) {
          nav(`/new_shop_quote/${orderCode}/checkout`);
        }
      },
    },
  ];

  if (isLoading) {
    items[currentIndex - 1].icon = <LoadingOutlined />;
  }

  return (
    <div
      id="ShopNewQuoteSteps"
      className={cn(className, styles.container)}
      style={style}
    >
      <Steps size="small" current={currentIndex - 1} items={items} />
    </div>
  );
};

ShopNewQuoteSteps.displayName = 'ShopNewQuoteSteps';
