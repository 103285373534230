import {
  i18nFormat,
  useReceiveAddressMgtStore,
} from '@unionfab/ufc-shop-commons';
import { useEffect } from 'react';

import { ReceiveAddressCheckCardGroup } from '@/features/receive_address/components/ReceiveAddressCheckCardGroup';
import { useInquiryQuoteCheckoutStore } from '@/stores';

export const OrderCheckoutShipAddressSelect = ({
  hideChangeBtn,
}: {
  hideChangeBtn?: boolean;
}) => {
  const { addressList, loadAddressList } = useReceiveAddressMgtStore();

  // 当前发货地址
  const {
    order,
    orderDeliverAddress,
    updateOrderDeliverAddress,
  } = useInquiryQuoteCheckoutStore();

  useEffect(() => {
    loadAddressList();
  }, []);

  useEffect(() => {
    if (order && addressList && addressList.length !== 0) {
      // 订单加载完成且，地址已经加载
      if (!orderDeliverAddress) {
        // 若订单地址不存在，设默认收货地址为订单地址
        const addr = addressList.find(v => v.defaultAddress);

        addr && updateOrderDeliverAddress(addr);
      }
    }
  }, [order, addressList, orderDeliverAddress]);

  return (
    <ReceiveAddressCheckCardGroup
      createAndSelectText={i18nFormat('Select a ship address')}
      address={orderDeliverAddress}
      selectAddress={updateOrderDeliverAddress}
      hideChangeBtn={hideChangeBtn}
    />
  );
};
