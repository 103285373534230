import {
  changeInquiryOrderFromWaitSubmitToWaitReview,
  createInquiryOrderToWaitSubmit,
  Deliver,
  DeliverAddress,
  doConfirmOrderByCustomer,
  get,
  getShopExpresses,
  getTenantConfigByKeys,
  getTenantIdFromGConfig,
  i18nFormat,
  InquiryOrder,
  UnionfabEventTracker,
  updateInquiryOrderByCodeForOverseasCustomer,
} from '@unionfab/ufc-shop-commons';
import { message } from 'antd';
import { useState } from 'react';

import { useAppNavigate } from '@/features/shared';
import {
  useInquiryQuoteCheckoutStore,
  useInquiryQuoteEditorStore,
} from '@/stores';

export interface OrderSubmitState {
  submitting: boolean;
  submitOrder: (isAutoConfirmToPayment: boolean) => Promise<void>;
}

export function useOrderSubmitState(): OrderSubmitState {
  const { order, quotation } = useInquiryQuoteCheckoutStore();

  const [submitting, setSubmitting] = useState(false);

  const nav = useAppNavigate();

  const submitOrder = async (isAutoConfirmToPayment: boolean) => {
    if (!order) {
      message.warning('order is not loaded');
      return;
    }

    try {
      // 首先创建订单到待提交状态
      let orderCode = order.code;

      setSubmitting(true);

      message.loading({ content: i18nFormat('Submit Order'), key: 'submit' });

      if (!orderCode) {
        orderCode = await createInquiryOrderToWaitSubmit(order);
      } else {
        const { deliver } = order;

        const config = await getTenantConfigByKeys(
          window?.gConfig?.tenantId as any,
          ['orderDefaultTransportMethodId'],
        );

        const transportMethodId = get(
          config,
          c => c.orderDefaultTransportMethodId,
        );

        if (transportMethodId) {
          (deliver.deliverAddress || []).forEach(d => {
            d.transportMethodId = transportMethodId;
          });
        }

        const [country] = (deliver.deliverAddress || []).map(d => d.country);

        let address = deliver.deliverAddress || [];
        const selectedEXId = get(order, o => o.customerShipOption.expressId);

        if (selectedEXId) {
          const expressCompany = (await getShopExpresses(country)).find(
            v => v.id === order?.customerShipOption?.expressId,
          ).name;

          address = address.map(
            v => new DeliverAddress({ ...v, expressCompany }),
          );
        }

        await updateInquiryOrderByCodeForOverseasCustomer(
          orderCode,
          new InquiryOrder({
            ...order,
            stashedChanges: ['ITEM', 'PRODUCT_REMARK', 'OTHER'],
            deliver: { ...order.deliver, deliverAddress: address } as Deliver,
          }),
        );
      }

      if (!orderCode) {
        message.error(i18nFormat('Submit failed'));
        return;
      }

      // 然后将订单状态从 WAIT_SUBMIT 变为 WAIT_REVIEW
      await changeInquiryOrderFromWaitSubmitToWaitReview(orderCode);

      // 重置 QuoteEditor 的数据
      useInquiryQuoteEditorStore.getState().resetInquiryOrder();

      // 重置 Checkout 的数据
      useInquiryQuoteCheckoutStore.setState({
        order: undefined,
        shipOptionDraft: undefined,
      });

      if (isAutoConfirmToPayment) {
        // 能够自动支付的跳转到自动支付
        message.loading({
          content: 'Placing order',
          key: 'submit',
        });

        // 客户确认下单
        await doConfirmOrderByCustomer(getTenantIdFromGConfig(), order.code);

        nav.navigateToOrderPayment(order.code, 'checkout');
      } else {
        UnionfabEventTracker.getIns().addUserEventRecord({
          typeName: 'quotation_submitted',
        });

        nav.navigateToRfqSuccess(order.code, order.id);
      }
    } catch (e) {
      message.error({
        content:
          'Error submitting order, please contact our customer service for help.',
        key: 'submit',
      });
    } finally {
      setSubmitting(false);
      message.destroy('submit');
    }
  };

  return { submitting, submitOrder };
}
