import { isNil, omitBy } from 'lodash';

import { get, isValid, isValidArray } from '../../../utils';
import { ShopOrderDeliveryItemRequestParams } from '../delivery';
import { ShopOrderRequestParams } from '.';
import { ShopOrder } from './ShopOrder';

export class ShopOrderRequestParamsBuilder {
  static fromShopOrder(shopOrder: Partial<ShopOrder>) {
    // 从 shopOrder 构造出 RequestParam

    const params = new ShopOrderRequestParams({
      requirements: shopOrder.requirements,
      delivery: {
        items: (shopOrder.delivery.items || []).map(
          i => new ShopOrderDeliveryItemRequestParams(omitBy(i, isNil)),
        ),
      },
      items: shopOrder.items.map(i => {
        const {
          modelFileVO,
          materialVO,
          materialSkuVO,
          remarkFileVO,
          ...extra
        } = i;
        return extra;
      }) as any,
    });

    if (
      !isValid(params.delivery) ||
      !isValidArray(get(params, p => p.delivery.items, []))
    ) {
      delete params.delivery;
    }

    if (
      !get(params, p => p.requirements.customerSelections.delivery.expressId)
    ) {
      delete params.requirements.customerSelections.delivery.expressId;
    }

    if (!isValid(params.requirements.customerSelections.delivery)) {
      delete params.requirements.customerSelections.delivery;
    }

    if (!isValid(params.requirements.customerSelections)) {
      delete params.requirements.customerSelections;
    }

    return params;
  }
}
