import { Id } from '../../../common';
import { OrderExpressQuotation } from './InquiryOrderQuotation';

// 客户给定的物流选项
export interface OrderShipOption {
  expressId?: string;
  remark?: string;
}

//// 物流报价

// 订单条目报价
export interface OrderItemQuotation {
  // 订单条目 ID
  id: Id;

  // 对应条目关联的文件列表
  fileIds: Id[];

  // 条目总价
  price?: number;
  // 条目单件价格
  perPrice?: number;

  // 条目价格是否需要人工审核
  requiresManualQuote: boolean;
}

// 报价计算结果
export interface OrderQuotationV2 {
  // 订单总价是否需要人工审核
  // 即使材料费和所选快递方式邮费都不需要人工审核，整个订单可能也会因为某些原因总体需要人工审核报价
  // 当它为 False 的时候；订单是否需要审核，取决于所选物流
  requiresManualQuote: boolean;

  // 当前计价适用的券
  couponCode?: string;

  // 已支付的结果
  total?: number;
  shipQuotation?: number;
  minProductCostFee?: number;

  // 已支付的用券结果
  discountAmount?: number;
  discountAppliedTo?: 'SUBTOTAL' | 'SHIPPING' | 'TOTAL';

  // 未支付订单，选择特定快递公司时的总价与折扣价
  express2total?: Record<string, number>;
  express2discountAmount?: Record<string, number>;

  // 材料总价
  subtotal?: number;
  // 材料总价是否需要人工审核
  subtotalRequiresManualQuote: boolean;

  // 订单条目的报价（材料费组成）
  itemQuotations: OrderItemQuotation[];

  // 订单支持的物流的报价
  shipExpressQuotations: OrderExpressQuotation[];
  // 订单物流报价是否计算中 (有值且为 true 则表示正在计算中)
  shipQuotationsCalculating?: boolean;
  // 物流报价是否需要审核
  shipQuotationsRequiresManualQuote: boolean;

  // 预计发货周期
  estDeliverTimeInHours?: number;
}

export function calcOrderShipQuotation(
  expressId: string | undefined,
  // 物流报价是否需要审核
  shipQuotationsRequiresManualQuote?: boolean,
  // 订单支持的物流的报价
  shipExpressQuotations?: OrderExpressQuotation[],
):
  | {
      requiresManualQuote: true;
      quotation?: number;
      expressQuotation?: OrderExpressQuotation;
    }
  | {
      requiresManualQuote: false;
      quotation: number;
      expressQuotation: OrderExpressQuotation;
    } {
  if (!shipExpressQuotations) {
    return { requiresManualQuote: true };
  }

  if (!expressId) {
    return { requiresManualQuote: true };
  }

  const expressQuotation = shipExpressQuotations.find(
    v => v.expressId === expressId,
  );

  const quotation: number | undefined =
    expressQuotation?.quotation != null
      ? expressQuotation.quotation
      : expressQuotation?.requiresManualQuote != null &&
        !expressQuotation.requiresManualQuote
      ? expressQuotation.systemQuotation
      : undefined;

  const requiresManualQuote: boolean =
    typeof quotation === 'number'
      ? false
      : shipQuotationsRequiresManualQuote == null
      ? true
      : shipQuotationsRequiresManualQuote;

  return { quotation, expressQuotation, requiresManualQuote };
}
