import {
  getUploadAuth,
  selectFileStores,
  UfcShopTokenUtils,
} from '@unionfab/ufc-shop-commons';
import * as S from '@unionfab/ufc-shop-commons';
import { message } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import { RcFile, UploadProps } from 'antd/lib/upload';
import * as React from 'react';

const storeAuthCache: Partial<
  Record<
    S.UPLOAD_FILE_TYPE,
    {
      createdAt: number;
      storeAuth: S.FileStoreAuth;
    }
  >
> = {};

export type UploadingEvent =
  | 'onInitializing'
  | 'onUploading'
  | 'onUploadError'
  | 'onMaxCountOverflow'
  | 'onTypeIsNotFit'
  | 'onSkipTheFileWithSameName'
  | 'onLargerThanMaxSize'
  | 'onFileNameOverflow'
  | 'onNetworkBad';

export interface IAbcFileUploaderProps extends Omit<UploadProps, 'type'> {
  type: S.UPLOAD_FILE_TYPE;
  accept?: string;
  fileCount?: number; // 文件数量 版本兼容
  maxCount?: number; // 文件数量
  maxFileSize?: number; // 最大文件体积
  fileStores?: S.FileStore[];
  storeAuth?: S.FileStoreAuth;
  tenantConfig?: S.TenantConfig;

  label?: any;
  buttonType?: 'link' | 'default' | 'primary' | 'ghost' | 'dashed';
  suffix?: string; // 文件后缀

  onInit?: ({ storeAuth }: { storeAuth: S.FileStoreAuth }) => void; // 组件初始化完毕
  onSuccess?: (fileInfo: {
    rcFile: UploadFile;
    d3ModelFile: S.D3ModelFile;
  }) => void; // 上传成功的回调
  onBatchUploadSuccess?: (
    files: {
      rcFile: UploadFile;
      d3ModelFile: S.D3ModelFile;
    }[],
  ) => void; // 批次上传成功

  onEventHandler?: (
    evt?: UploadingEvent,
    file?: RcFile | UploadFile | S.D3ModelFile,
    fileList?: {
      waitUploadFileList?: RcFile[] | UploadFile[];
      uploadedFileList?: {
        rcFile: UploadFile;
        d3ModelFile: S.D3ModelFile;
      }[];
    },
  ) => void;
}

export interface IAbcFileUploaderState {
  isInitializing?: boolean;
  isUploading?: boolean;
  percent?: number;
  storeAuth?: Partial<S.FileStoreAuth>;
}

/** 基于 FileStore 的文件上传组件 */
export abstract class AbcFileUploaderComp<
  P extends IAbcFileUploaderProps,
  S extends IAbcFileUploaderState
> extends React.PureComponent<P, S> {
  static defaultProps: Partial<IAbcFileUploaderProps> = {
    type: 'IMAGE',
    showUploadList: false,
    fileStores: [],
  };

  get accept() {
    if (this.props.accept) {
      return this.props.accept;
    }

    switch (this.props.type) {
      case 'IMAGE':
        return 'image/*';
      case 'BPP':
        return '.bpp';
      case 'MODEL': // 模型文件放开上传
        return S.ModelUploaderAccept;
      case 'PART':
        return '.cli,.usp,.ucf,.utk,.magics';
      case 'DOC':
        return '.xlsx,.xls,.json,.txt,.pdf,.doc,.docx';
      case 'OTHER':
      default:
        return '*';
    }
  }

  /** 获取到当前使用的 FileStore 信息 */
  getFileStore() {
    const { storeAuth } = this.state;

    return storeAuth!.fileStore;
  }

  componentDidMount() {
    if (!this.props.storeAuth) {
      this.setState({ isInitializing: true });
    } else {
      this.refreshStoreAuth();
    }
  }

  componentDidUpdate(
    prevProps: Readonly<P>,
    _prevState: Readonly<S>,
    _snapshot?: any,
  ): void {
    this.refreshStoreAuth(prevProps);

    if (this.props.storeAuth !== prevProps.storeAuth) {
      this.refreshStoreAuth(this.props);
    }
  }

  /** 刷新 Store 所需要的认证信息 */
  async refreshStoreAuth(props = this.props) {
    try {
      if (!UfcShopTokenUtils.isTokenValid()) return;

      // 判断是否手动指定了 storeAuth
      if (props.storeAuth) {
        this.setState(
          { storeAuth: props.storeAuth, isInitializing: false },
          () => {
            if (props.onInit) {
              props.onInit({ storeAuth: props.storeAuth });
            }
          },
        );
      }
      return;

      // const { tenantConfig, type } = props;

      // this.setState({ isInitializing: true });

      // let storeAuth: S.FileStoreAuth;

      // // 判断是否包含 storeAuth
      // if (
      //   storeAuthCache[type] &&
      //   storeAuthCache[type]!.createdAt + 60 * 1000 < Date.now()
      // ) {
      //   storeAuth = storeAuthCache[type]!.storeAuth;
      // } else {
      //   const selectedFileStores = await selectFileStores(
      //     props.fileStores || [],
      //     this.props.type,
      //     tenantConfig,
      //   );

      //   storeAuth = await getUploadAuth(
      //     type,
      //     // 这里会根据本地的 FileStore 的信息进行上传地址的选择
      //     selectedFileStores.map(f => ({
      //       endpoint: f.endpoint as string,
      //       storeId: f.id as S.Id,
      //     })),
      //   );
      // }

      // if (storeAuth.url) {
      //   this.setState({ storeAuth, isInitializing: false }, () => {
      //     if (props.onInit) {
      //       props.onInit({ storeAuth });
      //     }
      //   });
      // } else {
      //   message.error({
      //     content: `May be network error, no file storage available, please refresh and try later (${this.props.type})`,
      //     key: `store-fetch-error-${this.props.type}`,
      //     duration: 10 * 1000,
      //   });
      // }
    } catch (_) {
      console.error('>>>AbcFileUploaderComp>>>refreshStoreAuth>>>err>>>', _);
    }
  }
}
