import { getShopExpresses } from '@unionfab/ufc-shop-commons';
import * as S from '@unionfab/ufc-shop-commons';
import { Table } from 'antd';
import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';

import { useInquiryQuoteCheckoutStore } from '@/stores';

import { getColumns } from './common';
import styles from './index.module.less';

export const SelectExpressOptionsForPayment = ({}: { orderCode?: string }) => {
  const store = useInquiryQuoteCheckoutStore();
  const {
    quotation: orderQuotation,
    shipOptionDraft,
    setEstDeliveryTime,
    setShipOptionDraft,
    orderDeliverAddress,
    order,
  } = store;

  const { data: expresses } = useQuery(
    ['express', orderDeliverAddress],
    async (): Promise<S.ShopExpress[]> => {
      if (!orderDeliverAddress || !orderDeliverAddress.country) return [];

      const expresses = await getShopExpresses(orderDeliverAddress.country);

      /** 支付页面物流筛选出已有可用报价的条目 */
      return (expresses || []).filter(e => {
        const r = S.calcOrderShipQuotation(
          e.id!,
          orderQuotation.shipQuotationsRequiresManualQuote,
          orderQuotation.shipExpressQuotations,
        );

        return (
          (!r.requiresManualQuote && r.expressQuotation) ||
          typeof r.quotation == 'number'
        );
      });
    },
  );

  const shipOption = useMemo(() => {
    if (!!order) {
      if (!!shipOptionDraft) return shipOptionDraft;
      if (!!order.customerShipOption) return order.customerShipOption;
    }

    return { expressId: undefined, remark: undefined };
  }, [shipOptionDraft, order]);

  const selectedExpressId: string | undefined = useMemo(() => {
    if (shipOption && shipOption.expressId) {
      return shipOption.expressId;
    }
    return null;
  }, [shipOption]);

  useEffect(() => {
    if (!!order) {
      if (!selectedExpressId) {
        if (!!expresses && S.isValidArray(expresses)) {
          const [address] = S.get(order, o => o.deliver.deliverAddress, []);

          let targetExpress = expresses[0];

          if (!!address && address.expressCompany) {
            const index = expresses.findIndex(
              e => e.name == address.expressCompany,
            );
            index > -1 && (targetExpress = expresses[index]);
          }

          // 默认选择第一个存在有效价格的物流方式
          setShipOptionDraft({
            remark: shipOption.remark,
            expressId: targetExpress.id!,
          });
        }
      } else {
        //存在有发货选项直接设置触发paymentPrice计算
        setShipOptionDraft(shipOption);
      }
    }
  }, [selectedExpressId, expresses, order]);

  const columns = getColumns(
    S.get(order, o => o.currency),
    orderQuotation,
  );

  useEffect(() => {
    if (!!shipOption && S.isValidArray(expresses)) {
      const v = expresses.find(v => v.id === shipOption?.expressId);

      !!v && setEstDeliveryTime(v.deliveryStr);
    }
  }, [expresses, shipOption]);

  return (
    <span className={styles.expressOptionsContainer}>
      <Table
        rowKey="id"
        columns={columns}
        pagination={false}
        dataSource={expresses}
        rowSelection={{
          type: 'radio',
          selectedRowKeys: [selectedExpressId],
          onSelect: r => {
            setShipOptionDraft({
              expressId: r.id!,
              remark: shipOption.remark,
            });
          },
        }}
      />
    </span>
  );
};
