import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Alert } from 'antd';
import cn from 'classnames';
import React from 'react';

import styles from './index.module.less';
import { ShopQuoteItemsFinishBulkSelector } from './ShopQuoteItemsFinishBulkSelector';
import { ShopQuoteItemsMaterialBulkSelector } from './ShopQuoteItemsMaterialBulkSelector';
import { ShopQuoteItemsQuantityInput } from './ShopQuoteItemsQuantityInput';

export interface ShopQuoteBulkConfigurationProps {
  orderCode: string;
  itemIds: string[];
  className?: string;
  style?: React.CSSProperties;
}

export const ShopQuoteBulkConfiguration = ({
  orderCode,
  className,
  style,
}: ShopQuoteBulkConfigurationProps) => {
  return (
    <div
      id="ShopQuoteBulkConfiguration"
      className={cn(className, styles.container)}
      style={style}
    >
      <ShopQuoteItemsMaterialBulkSelector orderCode={orderCode} />
      <ShopQuoteItemsFinishBulkSelector orderCode={orderCode} />
      <ShopQuoteItemsQuantityInput orderCode={orderCode} />

      <Alert
        banner
        message={i18nFormat(
          'Attachments , remarks , and other details need to be specified for each part.',
        )}
        type="info"
        showIcon
      />
    </div>
  );
};

ShopQuoteBulkConfiguration.displayName = 'ShopQuoteBulkConfiguration';
