import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Button, InputNumber, Typography } from 'antd';
import { CSSProperties, useState } from 'react';

import {
  getUseShopQuoteStoreByCode,
  ShopOrderQuoteStoreModifiers,
} from '@/stores';

import styles from './index.module.less';
export interface ShopQuoteItemsQuantityInputProps {
  style?: CSSProperties;

  orderCode: string;
}

export const ShopQuoteItemsQuantityInput = ({
  style,
  orderCode,
}: ShopQuoteItemsQuantityInputProps) => {
  const onUpdateItemQty = (count: number) => {
    ShopOrderQuoteStoreModifiers.updateOrderDraft(orderCode, o => {
      for (const item of o.items) {
        item.printCount = count;
      }
    });
  };

  return (
    <div
      style={style}
      id="ShopQuoteItemsQuantityInput"
      className={styles.shopQuoteBulkConfigurationItem}
    >
      <div className={styles.title}>
        <Typography.Title level={5}>{i18nFormat('Quantity')}</Typography.Title>
      </div>
      <InputNumber
        min={1}
        style={{ width: '30%' }}
        onChange={onUpdateItemQty}
      />
    </div>
  );
};

ShopQuoteItemsQuantityInput.displayName = 'ShopQuoteItemsQuantityInput';
