import { Id } from '../../../common';
import { parseReceiverName } from '../../../crm/customer/receive_address';
import { ReceiveAddress } from '../../../crm/customer/ReceiveAddress';
import { ShopOrderDeliveryItemRequestParams } from '../../shop-order/delivery';
import { DeliverAddress } from './DeliverAddress';

// 将地址格式化为有序字符串
export const formateAddressToString = (
  address: ReceiveAddress | DeliverAddress,
) => {
  const addressTextCollection = [
    address.detail,
    address.street,
    address.city,
    address.province,
    address.country,
    address.postcode,
  ];

  return addressTextCollection.reduce((prev, curr, idx) => {
    return `${prev} ${
      curr
        ? curr + `${idx === addressTextCollection.length - 1 ? '' : ','}`
        : ''
    }`;
  }, '');
};

/**
 * 地址唯一标识；作为值对象，唯一能标识它就是构成其的所有属性
 */
export function calcAddressId(addr: ReceiveAddress): string {
  return `${addr.country}-${addr.province}-${addr.city}-${addr.detail}-${addr.postcode}-${addr.phoneNumber}-${addr.firstName}-${addr.lastName}`;
}

// 客户的收货地址转为订单的物流地址
export function convertDeliverAddressToReceiveAddress(
  addr?: DeliverAddress,
): ReceiveAddress | undefined {
  if (addr) {
    const t = new ReceiveAddress({
      ...addr,
      phoneNumber: addr.receiverPhoneNumber || addr.contactInfo,
      name: addr.receiverName || addr.name,
      detail: addr.receiveAddress || addr.detail,
    });
    const { firstName, lastName } = parseReceiverName(t);
    const id = calcAddressId(t) as Id;
    return new ReceiveAddress({ ...t, id, firstName, lastName });
  }
}

/** 将收货地址转化为发货地址 */
export function convertReceiveAddressToDeliverAddress(addr: ReceiveAddress) {
  return new DeliverAddress({
    ...addr,
    contactInfo: addr.phoneNumber,
    isSelected: true,
    id: undefined,
  });
}

/** 将收货地址转化为发货地址 */
export function convertShopOrderDeliveryItemToReceiveAddress(
  addr: ShopOrderDeliveryItemRequestParams,
) {
  if (!addr) return new ReceiveAddress({});

  return new ReceiveAddress({
    recipient: addr.recipient || '',
    contactInfo: addr.contactInfo || '',
    country: addr.country || '',
    province: addr.province || '',
    city: addr.city || '',
    district: addr.district || '',
    street: addr.street || '',
    detail: addr.detail || '',
    postcode: addr.postcode || '',
    companyName: addr.companyName || '',
  });
}
