export function toggleUnionfabWpHeaderVisibility(visible: boolean): boolean {
  let prevVisibility = true;
  const header = document.getElementsByClassName(
    'xz-header mh-sticky mh-pinned',
  )[0] as any;
  if (!header || !header.style) {
    return prevVisibility;
  }

  prevVisibility = header.style.display !== 'none';

  if (visible) {
    header.style.display = '';
    return prevVisibility;
  } else {
    header.style.display = 'none';
    return prevVisibility;
  }
}
