import './index.css';
import './index.less';

import * as S from '@unionfab/ufc-shop-commons';
import { UfcShopTokenUtils } from '@unionfab/ufc-shop-commons';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';

import { App } from './app/web';

export function render(elementId: string, getContent: () => JSX.Element) {
  let element: HTMLElement | undefined = undefined;

  document.addEventListener('DOMContentLoaded', function (_) {
    if (element) {
      return;
    }
    const e = document.getElementById(elementId);
    if (e) {
      element = e as HTMLElement;
    }
    if (!element) {
      console.warn('Mount point not found: ', elementId);
      return;
    }

    ReactDOM.createRoot(element as HTMLElement).render(
      <StrictMode>{getContent()}</StrictMode>,
    );
  });
}

const bootstrap = async () => {
  // 初始化的时候，在 runtime.js 中会注入一个 gConfig 对象
  window.gConfig = new S.UfcShopSiteConfig(window.gConfig);
  window.getRuntimeEnv = S.getUfcShopRuntimeEnv;

  // 设置请求的路径
  S.setUfcShopRuntimeEnv({
    apiEndpoint: window.gConfig.ORDER_ENDPOINT,
  });

  // 初始化请求对象
  S.setupDtcRequestApi({});

  // 刷新当前的 Token 信息
  UfcShopTokenUtils.refreshToken();

  render('root', () => <App />);
};

bootstrap().catch(r => console.warn('error rendering', r));
