import dayjs from 'dayjs';

import { i18nFormat } from '../../../../i18n';
import { BaseEntity } from '../../../common';
import { parseJson } from '../../../utils';

export const InquiryCouponTypeList = [
  'PERCENT_OFF',
  'AMOUNT_OFF',
  'FREE_PRINTING',
  'FREE_PRINTING_FOR_REFERRAL',
  'FREE_PRINTING_FOR_KIRI',
];

export const InquiryCouponStatusDesMap = {
  UNUSED: '未使用',
  USED: '已使用',
  EXPIRED: '已过期',
};

export const InquiryCouponStatusList = Object.keys(InquiryCouponStatusDesMap);

export type InquiryCouponType = typeof InquiryCouponTypeList[number];

export type InquiryCouponStatus = typeof InquiryCouponStatusList[number];

/**
 * 优惠券
 */
export class InquiryCoupon extends BaseEntity<InquiryCoupon> {
  name: string;

  code: string;

  type: InquiryCouponType;

  status: InquiryCouponStatus;

  startTime: string;

  endTime: string;

  amountOffWithAmount: number;

  amountOff: number;

  percentOff: number;

  /** 折扣上限 */
  limitDiscount: number;

  /** 后端返回使用规则 */
  rule: string;

  parsedRuleVO: { technology: ('SLA' | 'SLM')[] };

  /** 使用规则描述 */
  ruleText: string;

  //最大使用上限金额
  maxUseBound: number;
  // 活动名
  promotionName?: string;
  //兑换码
  redemptionCode?: string;

  get statusEn() {
    return {
      UNUSED: 'Unused',
      USED: 'Used',
      EXPIRED: i18nFormat('Expired'),
    }[this.status];
  }

  get desc() {
    if (this.type === 'AMOUNT_OFF') {
      return `满 $${this.amountOffWithAmount} 减 $${this.amountOff}`;
    }

    if (this.type === 'PERCENT_OFF') {
      return `${(1 - this.percentOff) * 10} 折`;
    }

    if (this.type === 'FREE_PRINTING') {
      return `免费打印`;
    }

    if (this.type === 'FREE_PRINTING_METAL') {
      return '免费打印金属';
    }

    if (this.type === 'FREE_PRINTING_FOR_KIRI') {
      return 'FREE_PRINTING_FOR_KIRI';
    }

    return '';
  }

  get descEn() {
    if (this.type === 'AMOUNT_OFF') {
      return `Get $ ${this.amountOff} off $ ${this.amountOffWithAmount}`;
    }

    if (this.type === 'PERCENT_OFF') {
      return `${this.percentOff * 100}% off`;
    }

    if (this.type === 'FREE_PRINTING') {
      return `Free printing`;
    }

    if (this.type === 'FREE_PRINTING_METAL') {
      return 'Free printing metal';
    }

    if (this.type === 'FREE_PRINTING_FOR_KIRI') {
      return 'Free printing For Kiri';
    }

    return '-';
  }

  get descWithExpires() {
    const expiresStr = `Expires ${dayjs(this.endTime).format('MM-DD-YYYY')}`;

    if (this.type === 'AMOUNT_OFF') {
      const amountOffWithAmount = this.amountOffWithAmount
        ? `off $ ${this.amountOffWithAmount}`
        : 'off';

      return `Get $ ${this.amountOff} ${amountOffWithAmount}, ${expiresStr}`;
    }

    if (this.type === 'PERCENT_OFF') {
      return `${this.percentOff * 100}% off, ${expiresStr}`;
    }

    if (this.type === 'FREE_PRINTING') {
      return `Free printing, ${expiresStr}`;
    }

    if (this.type === 'FREE_PRINTING_METAL') {
      return `Free printing metal, ${expiresStr}`;
    }

    if (this.type === 'FREE_PRINTING_FOR_KIRI') {
      return `Free printing For Kiri, ${expiresStr}`;
    }

    if (this.type === 'FREE_PRINTING_FOR_REFERRAL') {
      return `$20 Free, ${expiresStr}`;
    }

    return '';
  }

  constructor(props?: Partial<InquiryCoupon>) {
    super(props);

    Object.assign(this, props);

    if (this.rule) {
      this.parsedRuleVO = parseJson(this.rule);
    }
  }
}
