import { compact } from 'lodash-es';

import { BaseEntity, CurrencySymbolType, LengthUnit } from '../../../common';
import { isValidArray } from '../../../utils/ds/validator';
import { get } from '../../../utils/func/get';
import {
  InquiryOrderSortByProperty,
  InquiryOrderStatus,
} from '../../inquiry-order';
import { ShopCncRequirements } from '../../material/cnc/ShopCncRequirements';
import { ShopOrderDeliveryItemRequestParams } from '../delivery';

export type ShopOrderQueryParamsExtra = 'material' | 'sku' | 'itemRemarkFile';
export class ShopOrderRequestParamsItem extends BaseEntity<ShopOrderRequestParamsItem> {
  /** 最小的下单项 */
  skuId: string;

  /** 关联的文件数据 */
  fileId: string;

  previewFileId: string;

  lengthUnit: LengthUnit;

  printCount: number;

  d3Requirements: {
    punchCount: number;

    nutCount: number;
  };

  cncRequirements: ShopCncRequirements;

  /** 备注 */
  remark: {
    remark: string;
    fileIds: string[];
  };

  /** 后处理备注 */
  handleRemark: {
    method: string[];
    desc: string;
    desc1?: string;
    color?: string;
  };

  /** 螺母数量 */
  nutCount?: number;
  /** 打孔数量 */
  punchCount?: number;

  get handleMethodStr() {
    const method = compact(get(this, t => t.handleRemark.method, []));

    if (isValidArray(method)) {
      /**
       *  统一后处理字符串排序
       * 用于后处理单分组
       */
      return method.sort((a, b) => a.localeCompare(b)).join('、');
    } else {
      return 'Standard';
    }
  }

  constructor(data: Partial<ShopOrderRequestParamsItem> = {}) {
    super(data);
    BaseEntity.assignInConstructor(this, data);
  }
}

export class ShopOrderRequestParams extends BaseEntity<ShopOrderRequestParams> {
  requirements: {
    quotation?: {
      currency: CurrencySymbolType;
    };
    customerSelections?: {
      couponCode?: string;
      delivery: {
        expressId?: string;
      };
    };
  } = { quotation: { currency: 'USD' }, customerSelections: { delivery: {} } };

  /** 工艺 */
  technology?: '3D_PRINTING' | 'CNC';

  // 发货信息
  delivery: {
    items?: ShopOrderDeliveryItemRequestParams[];
  } = { items: [] };

  items: ShopOrderRequestParamsItem[];

  constructor(data: Partial<ShopOrderRequestParams> = {}) {
    super(data);

    BaseEntity.assignInConstructor(this, data);

    // 确保深层嵌套的 requirements 有值，这样在业务中可以直接嵌套赋值
    if (!this.requirements) {
      this.requirements = {};
    }

    if (!this.requirements.quotation) {
      this.requirements.quotation = { currency: 'USD' };
    }

    if (!this.requirements.customerSelections) {
      this.requirements.customerSelections = { delivery: {} };
    }

    if (!this.requirements.customerSelections.delivery) {
      this.requirements.customerSelections.delivery = {};
    }
  }
}

export interface ShopOrdersQueryParams {
  tenantId: string;
  /** 查询时间范围 */
  startDateTime: string;
  endDateTime: string;
  /** 查询关键字 */
  searchText: string;

  pageNum: number;
  pageSize: number;
  /** 订单状态 */
  status: InquiryOrderStatus[];
  /** 排序方式 */
  sortBy: InquiryOrderSortByProperty;
  /** 额外的数据 */
  includes: ShopOrderQueryParamsExtra[];
}
