import { cloneDeep } from 'lodash';
import { create, StoreApi, UseBoundStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import computed from 'zustand-computed';

import { getCurrencyExchangeRate, getUfcShopComposedApi } from '../../../apis';
import * as S from '../../../schema';
import { LK_SELECTED_COUPON_CODE } from './ShopOrderQuoteStoreModifiers';

interface ShopOrderEditorStoreComputedState {
  isLoadingOrComputing?: boolean;
}
export interface ShopOrderEditorStoreState {
  /** 当前订单是否允许编辑 */
  allowEdit: boolean;
  /** 订单编号 */
  orderCode: string;

  /** 是否正在同步到服务端 */
  isLoading?: boolean;

  /** 当前订单所选优惠券 */
  couponCode?: string;

  shopOrder?: Omit<S.ShopOrder, 'quotation'>;

  shopOrderDraft?: Omit<S.ShopOrder, 'quotation'>;

  /** 这是来自单独的 Quotation 接口，每次 Order 更新以后，Order 并不会从服务端刷新，Quotation 会进行动态更新 */
  shopOrderQuotation?: S.ShopOrderQuotation;

  /** 订单价格，价格是在客户选了 expressId 以后才会有意义，之前都用的是 Quotation */
  shopOrderPrice?: S.ShopOrderPrice;

  /** 当前货币到美元系数 */
  ratioFromUsdToOrderCurrency?: number;

  /** 是否已经同意协议 */
  isAgreeTermOfService?: boolean;

  /** 用户已支付等待处理 */
  isProcessing?: boolean;
  /** 是否正在进行支付过程（即跳转、弹窗第三方进行支付中） */
  isWaitingUserPay?: boolean;
  // 钱海支付二次验证链接，安全码输入
  oceanpaymentPayUrl?: string;
  // 钱海支付中间状态信息
  oceanpaymentPayState?: { detailMessage: string; solution: string };

  /** 支付结果 */
  paymentResult?: {
    success: boolean;
    failureMessage?: string;
  };

  /** 选中的条目id */
  selectedOrderItemIds: string[];

  // 账单地址
  billingAddress?: S.ReceiveAddress;

  isBillingInfoValid?: boolean;
  isBillingInfoRequired: boolean;
  billingInfo?: S.ShopOrderBillingInfo;
}

/** 设置每个 InquiryOrder 单独 Store */
const storeMap: Record<
  string /** code */,
  UseBoundStore<StoreApi<ShopOrderEditorStoreState & ShopOrderEditorStoreComputedState>>
> = {};

/** 当有 Location 选项的时候，Technical drawing 必填 */
export const useBluePrintRequiredStore = create((set, get: any) => ({
  locationsAndOtherRemarkStatus: new Map(), // 键是那个 Location 选项独一无二的名称，值表示这个选项是否填过了
  setStatus(key: string, val: boolean) {
    if (get().locationsAndOtherRemarkStatus.get(key) === val) return; // 避免重复渲染
    set((state: any) => ({
      locationsAndOtherRemarkStatus: new Map([...state.locationsAndOtherRemarkStatus.set(key, val)]),
    }));
  },
}));

export const getUseShopQuoteStoreByCode = (orderCode: string) => {
  if (storeMap[orderCode]) {
    return storeMap[orderCode];
  }

  const computeState = (state: ShopOrderEditorStoreState): ShopOrderEditorStoreComputedState => ({
    isLoadingOrComputing:
      state.isLoading ||
      S.get(state.shopOrderQuotation, q => q.hasComputingItem) ||
      S.get(state.shopOrderPrice, p => p.status === 'COMPUTING') ||
      false,
  });

  const useShopQuoteEditorStore: UseBoundStore<
    StoreApi<ShopOrderEditorStoreState & ShopOrderEditorStoreComputedState>
  > = create<ShopOrderEditorStoreState & ShopOrderEditorStoreComputedState>()(
    computed(
      devtools(
        (set, get) => {
          return {
            orderCode,
            allowEdit: true,
            isBillingInfoRequired: false,
            ratioFromUsdToOrderCurrency: 1,
            // 默认为加载中，需要 load 事件获取数据
            isLoading: false,
            selectedOrderItemIds: [],
            couponCode: localStorage.getItem(`${LK_SELECTED_COUPON_CODE}_${orderCode}`),
          };
        },
        {
          name: 'shopQuoteEditorStore-' + orderCode,
        },
      ),
      computeState,
    ),
  );

  storeMap[orderCode] = useShopQuoteEditorStore;

  return useShopQuoteEditorStore;
};

export const loadOrderInShopEditorStore = async (code: string) => {
  const store = getUseShopQuoteStoreByCode(code);

  store.setState({ isLoading: true });

  // 从服务端抓取数据
  const shopOrder = await getUfcShopComposedApi().shopOrderQueryApi.queryShopOrderByCode(code);
  // 刷新报价
  const quotation = await getUfcShopComposedApi().shopOrderQueryApi.queryQuotation(code);

  const allowEdit = S.get(shopOrder, s => s.status) === 'WAIT_SUBMIT';

  if (shopOrder && shopOrder.status == 'WAIT_CUSTOM_CONFIRM') {
    const couponCode = store.getState().couponCode;

    const shopOrderPrice = await getUfcShopComposedApi().shopOrderQueryApi.calcPrice(shopOrder.code, {
      expressId: S.get(shopOrder, o => o.requirements.customerSelections.delivery.expressId),
      couponCode: couponCode !== "Don't choose coupon" ? couponCode : undefined,
    });

    store.setState({ shopOrderPrice });
  }

  /** 目前仅未提交的订单允许编辑 */

  // 加载今日货币转化系数
  const currency = shopOrder.requirements.quotation.currency;
  let ratioFromUsdToOrderCurrency = 1;
  if (currency !== 'USD') {
    ratioFromUsdToOrderCurrency = await getCurrencyExchangeRate('USD', currency);
  }

  // 后续 Quotation 字段是来自于组件内触发的自动抓取
  store.setState({
    shopOrder,
    allowEdit,
    isLoading: false,
    shopOrderDraft: cloneDeep(shopOrder),
    ratioFromUsdToOrderCurrency,
    shopOrderQuotation: new S.ShopOrderQuotation(quotation),
  });
};

export const removeTargetUseShopQuoteStore = (orderCode: string) => {
  if (!!orderCode && orderCode in storeMap) {
    delete storeMap[orderCode];
  }
};

// 全局定时监听，用于对于 Store 中 quotation 为 Calculating 的进行状态更新
setInterval(() => {
  Object.values(storeMap).forEach(async store => {
    const { orderCode, shopOrderQuotation, shopOrder, couponCode, shopOrderPrice } = store.getState();

    if (!S.isValidArray(S.get(shopOrder, o => o.items, []))) return;

    if (shopOrderQuotation && shopOrderQuotation.hasComputingItem) {
      // 执行 quotation 更新
      store.setState({ isLoading: true });

      // 刷新报价
      const quotation = await getUfcShopComposedApi().shopOrderQueryApi.queryQuotation(orderCode);

      store.setState({ shopOrderQuotation: quotation, isLoading: false });
    }

    const expressId = S.get(shopOrder, o => o.requirements.customerSelections.delivery.expressId);

    if ((!shopOrderPrice && expressId) || (shopOrderPrice && shopOrderPrice.status === 'COMPUTING')) {
      // 执行 quotation 更新
      store.setState({ isLoading: true });

      // 刷新报价
      const price = await getUfcShopComposedApi().shopOrderQueryApi.calcPrice(orderCode, {
        expressId,
        couponCode: couponCode !== "Don't choose coupon" ? couponCode : undefined,
      });

      store.setState({ shopOrderPrice: price, isLoading: false });
    }
  });
}, 2500);
