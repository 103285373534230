import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { useMemo, useRef } from 'react';

import { useInquiryQuoteEditorStore } from '@/stores';

import styles from './index.module.less';

export const OrderItemPriceAndLeadTime = ({
  orderItem,
}: {
  orderItem: S.InquiryOrderItem;
}) => {
  const {
    isLoadingEstPrice,
    estPrice,
    inquiryOrder,
  } = useInquiryQuoteEditorStore();

  const itemPrice = S.get(estPrice, p =>
    p.itemQuotations.find(v => v.id === orderItem.id),
  );
  const itemRfq =
    itemPrice?.requiresManualQuote == null
      ? true
      : itemPrice.requiresManualQuote;
  const itemPriceValue = itemPrice?.price;

  const ref = useRef(null);

  const leadTimeStr = `${S.get(orderItem, oi =>
    oi.materialSpu.deliverLeadTime
      ? oi.materialSpu.deliverLeadTime.enName
      : oi.materialSpu.leadTimeStrFromHours,
  )}`;

  const leadTimeEle = (
    <div className={styles.orderItemLeadTime}>
      <span>{i18nFormat('Lead Time')}: </span>
      <span className={styles.orderItemLeadTimeDigital}>{leadTimeStr}</span>
    </div>
  );

  const priceContent = useMemo(() => {
    const digitalStyle = isLoadingEstPrice
      ? styles.orderItemPriceDigitalLoading
      : styles.orderItemPriceDigital;

    return itemPriceValue ? (
      <span>
        {i18nFormat('Price')}:
        <span className={digitalStyle}>{`${S.toPriceStringWithOption(
          itemPriceValue,
          {
            rfq: itemRfq,
            currency: inquiryOrder!.currency,
          },
        )}`}</span>
      </span>
    ) : (
      <span>
        {i18nFormat('Price')}:{' '}
        <span className={digitalStyle}>{i18nFormat('RFQ')}</span>
      </span>
    );
  }, [itemPriceValue, itemRfq, isLoadingEstPrice]);

  const itemPriceEle = (
    <div ref={ref} className={styles.orderItemPrice}>
      {priceContent}
    </div>
  );

  return (
    <span className={styles.orderItemPriceAndLeadTime}>
      {leadTimeEle}
      {itemPriceEle}
    </span>
  );
};
