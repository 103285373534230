import {
  isDev,
  queryClient,
  UfcShopTokenUtils,
  useGlobalStore,
} from '@unionfab/ufc-shop-commons';
import { useAsyncEffect } from 'ahooks';
import { Alert, Button, Result } from 'antd';
import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClientProvider } from 'react-query';
import { HashRouter as Router, NavLink } from 'react-router-dom';

interface AppContextProviderProps {
  children: React.ReactNode;
}

function ErrorFallback({
  error,
  resetErrorBoundary,
}: {
  error: Error;
  resetErrorBoundary?: () => void;
}) {
  const refreshAccessToken = useGlobalStore(g => g.refreshAccessToken);

  useAsyncEffect(async () => {
    console.log('>>>Context>>>', error.message);

    const accessToken = UfcShopTokenUtils.getAccessToken(true);

    if (!UfcShopTokenUtils.isTokenValid(accessToken)) {
      refreshAccessToken();
    }
  }, []);

  return isDev() ? (
    <Alert
      message={<pre>{error.message}</pre>}
      description={<pre>{error.stack}</pre>}
      type="error"
    />
  ) : (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button type="primary" onClick={resetErrorBoundary}>
          <Router>
            <NavLink to={'/'}>Back Home</NavLink>
          </Router>
        </Button>
      }
    />
  );
}

export const AppContextProvider = ({ children }: AppContextProviderProps) => {
  return (
    <React.Suspense fallback={<div />}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <QueryClientProvider client={queryClient}>
          <Router>{children}</Router>
        </QueryClientProvider>
      </ErrorBoundary>
    </React.Suspense>
  );
};
