import { useEffect } from 'react';

import { useGlobalStore, useInquiryMaterialStore } from '../../../stores';

export const useOrderOfferingsLoad = () => {
  const { loadMaterialTypes, loadMaterials } = useInquiryMaterialStore();

  const { groupId, loadQuotationGroupId } = useGlobalStore(g => ({
    isDemoUser: g.isDemoUser,
    groupId: g.quotationGroupId,
    hasAuthenticated: g.hasAuthenticated,
    loadQuotationGroupId: g.loadQuotationGroupId,
  }));

  useEffect(() => {
    loadMaterialTypes();
  }, []);

  useEffect(() => {
    // if (hasAuthenticated && !isDemoUser) {
    //   groupId ? loadMaterials(groupId) : loadQuotationGroupId();
    // }

    /** 允许匿名用户询单，这里放开对材料加载的限制 */
    groupId ? loadMaterials(groupId) : loadQuotationGroupId();
  }, [groupId]);
};
