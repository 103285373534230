import { BaseEntity, Id } from '../../common';
import { User } from './User';
import { WechatUser } from './WechatUser';

export class AvatarFile extends BaseEntity<AvatarFile> {
  name: string;
  md5: string;
  size: number;
  saveType: string;
  url: string;

  constructor(props?: Partial<AvatarFile>) {
    super(props);

    Object.assign(this, props);
  }
}

export class Person extends BaseEntity<Person> {
  username: string;
  nickname: string;
  customerName: string;

  customerId: string;

  get name() {
    return this.nickname || this.username || '-';
  }
  firstName?: string;
  lastName?: string;

  phoneNumber: string;
  wechatUser: WechatUser;
  /** 关联的企业微信客户 */
  wecomUserId: string;
  avatarUrl: string;
  email: string;
  avatarFileId: Id; // 用户头像 ID

  userIds: Id[];
  users: User[];
  englishName?: string;

  constructor(data: Partial<Person> = {}) {
    super(data);

    Object.assign(this, data);

    if (this.users && !this.userIds) {
      this.userIds = this.users.map(u => u.id);
    }
  }
}
