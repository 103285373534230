import dayjs from 'dayjs';
import { default as jwt_decode, default as jwtDecode } from 'jwt-decode';
import { isNil } from 'lodash';

import { get } from '../../schema';
import { setupDtcRequestApi } from '../api-libs';

export interface AccessTokenData {
  customerId: string;
  personId: string;
  exp: number;
  iat: number;
  iss: string;
  firstName: string;
  lastName: string;
  nickname: string;
  scopes: string[];
  username: string;
  customerName: string;

  /** 匿名登录获取 token 时传入的 sessionId，可根据 anonymousSessionId 是否有值判断当前是否为匿名登录 */
  anonymousSessionId: string;
}

export interface IdTokenData {
  personId: string;
  username: string;
  nickname: string;
  scopes: string[];
  dtcTenantId: string;
  dtcAccountId: string;
  firstName: string;
}

const storagePrefix = 'uoms_';

export const TOKEN_KEY = `${storagePrefix}token`;
export const ID_TOKEN_KEY = `${storagePrefix}id_token`;

const USER_KEY = `${storagePrefix}user`;

let __TOKEN_CACHE: string | undefined = undefined;
let __ID_TOKEN_CACHE: string | undefined = undefined;

export const decodeAccessToken = (
  token: string,
): AccessTokenData | undefined => {
  if (!token) return;

  if (token.startsWith('Bearer ')) {
    token = token.substring(7);
  }

  try {
    return jwtDecode(token) as AccessTokenData;
  } catch (e) {
    console.warn('illegal token', token);
    return undefined;
  }
};

const getPersonIdFromAccessTokenOrIdToken = () => {
  const token = getIdToken() || getAccessToken();

  if (!token) return undefined;

  try {
    const user = jwtDecode(token) as AccessTokenData | IdTokenData;

    return user.personId;
  } catch (e) {
    console.warn('illegal token', token);
    return undefined;
  }
};

const getAccessToken = (force?: boolean): string | undefined => {
  if (!__TOKEN_CACHE || force) {
    const s = window.localStorage.getItem(TOKEN_KEY) as string | undefined;

    if (!s) {
      return undefined;
    } else {
      try {
        __TOKEN_CACHE = JSON.parse(s);
      } catch (e) {
        __TOKEN_CACHE = s;
      }
    }
  }
  return __TOKEN_CACHE;
};

const setToken = (token: string | undefined, sentTokenEvent = true) => {
  __TOKEN_CACHE = token;

  if (token && isTokenValid(token)) {
    // 设置请求头

    setupDtcRequestApi({ Authorization: `Bearer ${token}` });

    localStorage.setItem(TOKEN_KEY, JSON.stringify(token));

    if (sentTokenEvent) {
      const setTokenEvent = new Event('setTokenEvent');

      setTokenEvent[TOKEN_KEY] = token;

      /** dispatch 触发token更新 */
      window.dispatchEvent(setTokenEvent);
    }
  } else {
    // 设置请求头
    setupDtcRequestApi({});

    window.localStorage.removeItem(TOKEN_KEY);
  }
};

const getIdToken = (force?: boolean): string | undefined => {
  if (!__ID_TOKEN_CACHE || force) {
    const s = window.localStorage.getItem(ID_TOKEN_KEY) as string | undefined;
    if (!s) {
      return undefined;
    } else {
      try {
        __ID_TOKEN_CACHE = JSON.parse(s);
      } catch (e) {
        __ID_TOKEN_CACHE = s;
      }
    }
  }
  return __ID_TOKEN_CACHE;
};

const setIdToken = (token: string | undefined) => {
  __ID_TOKEN_CACHE = token;

  if (token == null) {
    window.localStorage.removeItem(ID_TOKEN_KEY);
  } else {
    window.localStorage.setItem(ID_TOKEN_KEY, JSON.stringify(token));
  }
};

const isTokenValid = (token?: string) => {
  const _token = token || getAccessToken(true);

  if (!_token) return false;

  try {
    const user: AccessTokenData = jwt_decode(_token);

    const expiredTime = dayjs.unix(user.exp);

    const isExpired = expiredTime.isBefore(dayjs());

    if (isExpired) {
      console.warn('token expired', _token);
    }
    return !isExpired;
  } catch (e) {
    console.warn('invalid token', _token);
    return false;
  }
};

const clearToken = () => {
  __TOKEN_CACHE = undefined;
  __ID_TOKEN_CACHE = undefined;
  window.localStorage.removeItem(ID_TOKEN_KEY);
  window.localStorage.removeItem(TOKEN_KEY);
  window.localStorage.removeItem(USER_KEY);
};

const isDemoUser = () => {
  const token = getAccessToken();

  const r = decodeAccessToken(token);

  return !isNil(get(r, r => r.anonymousSessionId));
};

const refreshToken = () => {
  __TOKEN_CACHE = undefined;

  const token = getAccessToken(true);

  setToken(token, false);
};

export const UfcShopTokenUtils: {
  getAccessToken: (force?: boolean) => string | undefined;
  getIdToken: (force?: boolean) => string | undefined;
  setToken: (token: string | undefined, sentTokenEvent?: boolean) => void;
  setIdToken: (token: string | undefined) => void;
  isTokenValid: (token?: string) => boolean;
  clearToken: () => void;
  isDemoUser: () => boolean;
  refreshToken: () => void;
  decodeAccessToken: (token: string) => AccessTokenData | undefined;
  getPersonIdFromAccessTokenOrIdToken: () => string | undefined;
} = {
  getAccessToken,
  getIdToken,
  setToken,
  setIdToken,
  isTokenValid,
  clearToken,
  isDemoUser,
  refreshToken,
  decodeAccessToken,
  getPersonIdFromAccessTokenOrIdToken,
};
