import * as S from '@unionfab/ufc-shop-commons';
import {
  CurrencySymbolType,
  getMaintainerUserInfo,
  i18nFormat,
} from '@unionfab/ufc-shop-commons';
import { Descriptions, Typography } from 'antd';
import { useQuery } from 'react-query';

import styles from './index.module.less';

export const OrderBankTransferDetailsByLocaleIsRu = ({
  code,
  total,
  currency,
}: {
  code: string;
  total: number;
  currency: CurrencySymbolType;
}) => {
  const { data } = useQuery(['maintainer'], getMaintainerUserInfo);

  return (
    <div className={styles.bankTransferDetails}>
      <Typography.Title level={3} className={styles.title}>
        {i18nFormat(
          'Thank you for your order! Payment in full is necessary to validate order.',
        )}
      </Typography.Title>
      <div className={styles.orderInfo}>
        <Descriptions labelStyle={{ fontWeight: 'bold' }} colon={true}>
          <Descriptions.Item span={3} label={i18nFormat('Code')}>
            {code}
          </Descriptions.Item>
          <Descriptions.Item span={3} label={i18nFormat('Payment Total')}>
            {S.toPriceStringWithOption(total, { currency: currency })}
          </Descriptions.Item>
        </Descriptions>
      </div>
      <Typography.Paragraph>
        {i18nFormat(
          'Please provide the following details to your bank. As soon as we have received payment, your order will be processed in full and we will begin production.',
        )}
      </Typography.Paragraph>
      <div style={{ marginBottom: 24 }}>
        <Descriptions bordered labelStyle={{ textAlign: 'right' }}>
          <Descriptions.Item span={3} label={i18nFormat('Account Name')}>
            Unionfab AM Technology （Shanghai）Co.,Ltd
          </Descriptions.Item>
          <Descriptions.Item span={3} label={i18nFormat('RMB Account No')}>
            03004523848
          </Descriptions.Item>
          <Descriptions.Item span={3} label={i18nFormat('Bank Name')}>
            Bank of Shanghai Luwan Sub-branch
          </Descriptions.Item>
          <Descriptions.Item span={3} label={i18nFormat('Bank Address')}>
            NO.369 FUXINGZHONG ROAD,HUANG PU DISTRICT,SHANGHAI
          </Descriptions.Item>
          <Descriptions.Item span={3} label={i18nFormat('SWIFT')}>
            BOSHCNSHXXX
          </Descriptions.Item>
        </Descriptions>
      </div>

      <Typography.Paragraph type="danger">
        {i18nFormat(
          'Please note the Unionfab Order Code when transfer payment and notify your customer service representative after payment, so we can start production in time.',
        )}
      </Typography.Paragraph>
      <Typography.Paragraph>
        {i18nFormat(
          'Transfer the full amount in one payment. Do not divide payment into more than one transfer.',
        )}
      </Typography.Paragraph>
      <Typography.Paragraph>
        {i18nFormat('Keep your bank remittance slip for future reference.')}
      </Typography.Paragraph>
      <Typography.Paragraph>
        {i18nFormat(
          'Please contact the Unionfab Customer Service Team if the order status has not changed to Pre-Processing 3-5 Working days after your transfer.',
        )}
      </Typography.Paragraph>
      <Typography.Paragraph>
        {i18nFormat(
          'When using bank transfers, please transfer enough money to cover all banks service fees. This will ensure that Unionfab receives your full payment and will help you avoid monetary loss caused by refunds or dispute.',
        )}
      </Typography.Paragraph>
      <Typography.Paragraph type="danger">
        {i18nFormat("What's next?")}
      </Typography.Paragraph>
      <Typography.Paragraph>
        <ol type="1">
          <li>
            {i18nFormat('You can send an email to your sales manager')}:&nbsp;
            <span style={{ color: '#192C59' }}>
              {S.get(data, a => a.email)}, {S.get(data, a => a.nickname)}
            </span>
            .
          </li>
          <li>
            {i18nFormat(
              'Then we will to check the account,after the remittance has been received, we will send you an email.',
            )}
          </li>
          <li>
            {i18nFormat(
              'Meanwhile we will start producing,you can follow your orders in list "Orders".',
            )}
          </li>
        </ol>
      </Typography.Paragraph>
    </div>
  );
};
