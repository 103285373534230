import {
  getTenantIdFromGConfig,
  getUfcShopComposedApi as apis,
  getUfcShopComposedApi,
  getUfcShopRuntimeEnv,
  i18nFormat,
  useProfileStore,
} from '@unionfab/ufc-shop-commons';
import * as S from '@unionfab/ufc-shop-commons';
import { Button, message } from 'antd';
import cn from 'classnames';
import { isNil } from 'lodash';
import { LegacyRef, useEffect, useRef } from 'react';
import NewWindow from 'react-new-window';

import { getUseShopQuoteStoreByCode, LK_SELECTED_COUPON_CODE } from '@/stores';

import styles from './index.module.less';
import { OceanpayBillingAddress } from './OceanpayBillingAddress';

interface ShopQuotePayOceanBtnProps {
  orderCode: string;
  className?: string;
  onClosePayOrder?: () => void;
  onCreatePayOrder?: () => void;
}

export const ShopQuotePayOceanBtn = ({
  className,
  orderCode,
  onClosePayOrder,
  onCreatePayOrder,
}: ShopQuotePayOceanBtnProps) => {
  const oceanpaymentRef: LegacyRef<HTMLIFrameElement> = useRef();
  const useShopOrderStore = getUseShopQuoteStoreByCode(orderCode);

  const accountInfo = useProfileStore(a => a.accountInfo);

  const {
    order,
    billingAddress,
    couponCode,
    isWaitingUserPay,
    oceanpaymentPayUrl,
  } = useShopOrderStore(s => ({
    order: s.shopOrder,
    couponCode: s.couponCode,
    billingAddress: s.billingAddress,
    isWaitingUserPay: s.isWaitingUserPay,
    oceanpaymentPayUrl: s.oceanpaymentPayUrl,
  }));

  useEffect(() => {
    if (oceanpaymentRef && oceanpaymentRef.current) {
      oceanpaymentRef.current.onload = () => {
        oceanpaymentRef.current.contentWindow.postMessage(
          {
            language: 'en_US',
            methodType: 'init',
            cssUrl: S.OceanpaymentPageCssUrl,
          },
          S.getOceanpaymentEndpoint(),
        );
      };
    }
  }, [oceanpaymentRef]);

  if (!order) return <></>;

  const getBillingEmail = async () => {
    try {
      const resp =
        accountInfo || (await apis().userApi.getAccountProfileInfo());

      if (resp.email != null) return resp.email;

      if (resp.id != null) {
        const s = resp.personId.split('-', 2);

        if (s.length === 2) {
          return `${s[1]}@unionfab-billing.com`;
        } else {
          return `${s}@unionfab-billing.com`;
        }
      }
    } catch (e) {
      console.warn('error get user info', e);
    }
    return (
      [billingAddress?.firstName, billingAddress?.lastName]
        .filter(v => !!v)
        .join('-')
        .substring(0, 25) + '@unionfab-billing.com'
    );
  };

  const createOceanPayOrder = async (antdMessageKey: string) => {
    message.info({
      key: antdMessageKey,
      content: i18nFormat('Creating pay order'),
    });

    // 钱海对该字段描述：没有该值可以默认传[消费者id@域名/简称.com]
    // 长度有限制 50
    const billingEmail = await getBillingEmail();

    /** 获取支付参数 */
    const orderCode = order.code;
    const tenantId = getTenantIdFromGConfig();

    const options = S.getCreateOceanpaymentPayOrderOptions({
      couponCode,
      billingEmail,
      billingAddress,
      methods: 'Credit Card',
      backUrl: window.location.href,
    });

    return await getUfcShopComposedApi().ufcPaymentApi.createOceanpaymentPayOrder(
      {
        options,
        tenantId,
        orderCode,
      },
    );
  };

  const onOceanpaySuccessMessage = async ({
    msg,
    orderNumber,
    payOrderInfo,
  }: {
    msg: string;
    orderNumber: string;
    payOrderInfo: { payOrderId: string; oceanPayOrderNumber: string };
  }) => {
    if (!payOrderInfo) {
      return console.warn('recv', orderNumber, 'no cur order');
    }

    const { oceanPayOrderNumber, payOrderId } = payOrderInfo;

    if (orderNumber !== oceanPayOrderNumber) {
      return console.warn('recv', orderNumber, 'cur', payOrderInfo);
    }

    console.log('recv', orderNumber, msg);

    try {
      // await noticeOceanPayRes(getTenantIdFromGConfig(), payOrderId, msg);
      await getUfcShopComposedApi().ufcPaymentApi.noticeOceanPayRes({
        payOrderId,
        xmlData: msg,
        orderCode: order.code,
        tenantId: getTenantIdFromGConfig(),
      });

      localStorage.removeItem(`${LK_SELECTED_COUPON_CODE}_${orderCode}`);

      useShopOrderStore.setState({ paymentResult: { success: true } });
    } catch (e) {
      console.warn('error on success response', msg, e);
      useShopOrderStore.setState({
        paymentResult: {
          success: false,
          failureMessage: 'Something wrong with the payment',
        },
      });
    }
  };

  const pay = async () => {
    const antdMessageKey = 'create_order';

    if (!oceanpaymentRef || !oceanpaymentRef.current) {
      useShopOrderStore.setState({
        paymentResult: {
          success: false,
          failureMessage: 'Oceanpay component is not loaded correctly',
        },
      });
      return;
    }

    try {
      if (
        !billingAddress ||
        !billingAddress.firstName ||
        !billingAddress.lastName
      ) {
        return useShopOrderStore.setState({
          paymentResult: {
            success: false,
            failureMessage: 'Billing information is not completed',
          },
        });
      }

      const payOrder = await createOceanPayOrder(antdMessageKey);

      typeof onCreatePayOrder == 'function' && onCreatePayOrder();

      useShopOrderStore.setState({
        isWaitingUserPay: true,
        paymentResult: undefined,
        oceanpaymentPayUrl: undefined,
        oceanpaymentPayState: undefined,
      });

      if (!payOrder) {
        return useShopOrderStore.setState({
          isWaitingUserPay: false,
          paymentResult: {
            success: false,
            failureMessage: 'Oceanpay pay order is not correctly created',
          },
        });
      }

      const { id: payOrderId, oceanPayOrder } = payOrder;
      const oceanPayOrderNumber = S.get(oceanPayOrder, o => o.orderNumber);

      // 注册当前支付订单的支付回调
      const cb = S.createOceanpayPayResCb(
        (payUrl, state) => {
          console.log('>>> requires auth code', payUrl, state);
          useShopOrderStore.setState({
            oceanpaymentPayUrl: payUrl,
            oceanpaymentPayState: state,
          });

          window.removeEventListener('message', cb);
        },
        async (orderNumber, rawXmlMsg) => {
          await onOceanpaySuccessMessage({
            orderNumber,
            msg: rawXmlMsg,
            payOrderInfo: { payOrderId, oceanPayOrderNumber },
          });
          window.removeEventListener('message', cb);
        },
        (failureMessage: string) => {
          useShopOrderStore.setState({
            isWaitingUserPay: false,
            paymentResult: {
              success: false,
              failureMessage,
            },
          });

          S.removeLocalStoragePayOrderId(order.code, getTenantIdFromGConfig());

          window.removeEventListener('message', cb);
        },
      );

      window.addEventListener('message', cb);

      // 回调到后端的接口地址
      const noticeUrl = S.getOceanpayNoticeUrl({
        payOrderId,
        orderCode: order.code,
        tenantId: getTenantIdFromGConfig(),
        host: getUfcShopRuntimeEnv().apiEndpoint,
      });
      // 支付完成跳转回的地址
      const resp = S.transformOceanpaymentInfo({
        ...oceanPayOrder,
        noticeUrl,
      });

      oceanpaymentRef.current.contentWindow.postMessage(
        resp,
        S.getOceanpaymentEndpoint(),
      );
    } catch (e) {
      useShopOrderStore.setState({
        isWaitingUserPay: false,
        paymentResult: { success: false, failureMessage: 'Payment failed' },
      });
    }
  };

  const onClosePaymentPopout = async () => {
    /** 关闭窗口代表取消支付 */
    const orderCode = order.code;
    const tenantId = getTenantIdFromGConfig();
    const payOrderId = S.getPayOrderIdFromLocalStorage(orderCode, tenantId);

    /** 这里 payOrderId 有值代表 payOrder 正处于等待支付阶段 */
    if (payOrderId) {
      const payOrder =
        await getUfcShopComposedApi().ufcPaymentApi.getPayOrderInfo({
          orderCode,
          tenantId,
          payOrderId,
        });

      if (payOrder && S.CanOrderPayStatusList.includes(payOrder.status)) {
        /** 手动关闭支付订单 */
        await getUfcShopComposedApi().ufcPaymentApi.closePayOrder({
          tenantId,
          orderCode,
          payOrderId,
        });

        typeof onClosePayOrder == 'function' && onClosePayOrder();

        S.removeLocalStoragePayOrderId(orderCode, tenantId);

        useShopOrderStore.setState({
          isWaitingUserPay: false,
          paymentResult: {
            success: false,
            failureMessage: i18nFormat(
              'The current pay order seems to be canceled.',
            ),
          },
        });
      }
    }
  };

  return (
    <div className={cn(styles.oceanpaymentFrameContainer, className)}>
      <p className={styles.tips}>
        {i18nFormat(
          'You can pay with credit card: Visa, Master Card, American Express, JCB, Diners Club, Discover.',
        )}
        &nbsp;
        <span className={styles.heightLight}>
          {i18nFormat(
            '3D Secure will be requested when the amount is over $2000.',
          )}
        </span>
      </p>
      <div className={styles.paymentContent}>
        <div>
          <iframe
            seamless
            height="170px"
            ref={oceanpaymentRef}
            id="oceanpayment-iframe-region"
            name="oceanpayment-iframe-region"
            src={S.getOceanpaymentCheckoutPageUrl()}
            style={{ border: 'none', minWidth: '450px' }}
          />
          <Button block type="primary" size="large" onClick={pay}>
            {i18nFormat('Pay Now')}
          </Button>
        </div>
        <OceanpayBillingAddress orderCode={orderCode} />
      </div>
      {isWaitingUserPay && oceanpaymentPayUrl && !isNil(window) && (
        <NewWindow
          title=""
          url={oceanpaymentPayUrl}
          onUnload={onClosePaymentPopout}
        ></NewWindow>
      )}
    </div>
  );
};
