import { DeleteOutlined, FormOutlined, TagOutlined } from '@ant-design/icons';
import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import {
  Button,
  Divider,
  message,
  Modal,
  Popconfirm,
  Space,
  Tooltip,
} from 'antd';

import { Ellipsis } from '@/commons/components/Element/ellipsis';

import { ReceiveAddressCard } from '../ReceiveAddressCard';
import { ReceiveAddressForm } from '../ReceiveAddressForm';
import defaultStyles from './index.module.less';

export type ReceiveAddressEditOperationType = 'edit' | 'delete';

export interface ReceiveAddressEditCardProps {
  address: S.ReceiveAddress;

  operation?: ReceiveAddressEditOperationType;
  toggleOperation: (operation?: ReceiveAddressEditOperationType) => void;

  updateAddress: (addr: S.ReceiveAddress) => Promise<void>;
  deleteAddress: () => Promise<void>;
  setAsDefault: () => Promise<boolean>;

  setAsDeliverAddress?: (addr: S.ReceiveAddress) => Promise<boolean>;

  styles?: CSSModuleClasses;
}

export const ReceiveAddressEditCard = ({
  address,

  operation,
  toggleOperation,

  updateAddress,
  deleteAddress,
  setAsDefault,

  styles,
}: ReceiveAddressEditCardProps) => {
  styles = styles || defaultStyles;

  async function _setAsDefault() {
    try {
      await setAsDefault();
      message.success(i18nFormat('Set Successfully'));
    } catch (e) {
      message.error(i18nFormat('Set Failed'));
    }
  }

  async function _updateAddress(addr: S.ReceiveAddress) {
    const key = 'updateReceiveAddress';
    try {
      await updateAddress(addr);
      toggleOperation();
    } catch (e) {
      message.error({ content: i18nFormat('Update Failed'), key });
    } finally {
      message.destroy(key);
    }
  }

  async function _deleteAddress() {
    message.loading({
      content: i18nFormat('Deleting...'),
      key: 'deleteReceiveAddress',
    });
    try {
      await deleteAddress();
      toggleOperation();
      message.success(i18nFormat('Delete Successfully'));
      message.destroy('deleteReceiveAddress');
    } catch (e) {
      message.error(i18nFormat('Delete Failed'));
      message.destroy('deleteReceiveAddress');
    }
  }

  return (
    <div className={styles.receiveAddressEditCard}>
      <div className={styles.receiveAddressCardContainer}>
        <ReceiveAddressCard address={address} />
      </div>
      <Space
        split={<Divider type="vertical" />}
        className={styles.receiveAddressEditActions}
      >
        <Tooltip title={i18nFormat('Edit')}>
          <Button
            type="link"
            size="small"
            icon={<FormOutlined />}
            onClick={() => toggleOperation('edit')}
          />
        </Tooltip>
        <Popconfirm
          trigger="click"
          placement="bottom"
          cancelText={i18nFormat('No')}
          okText={i18nFormat('Delete')}
          open={operation === 'delete'}
          onCancel={() => toggleOperation()}
          onConfirm={_deleteAddress}
          title={i18nFormat('Are you sure to delete this address')}
        >
          <Button
            type="link"
            size="small"
            icon={<DeleteOutlined />}
            onClick={() => toggleOperation('delete')}
          />
        </Popconfirm>
        {!address.defaultAddress && (
          <Button
            type="link"
            size="small"
            icon={<TagOutlined />}
            style={{ display: 'flex', alignItems: 'center' }}
            onClick={_setAsDefault}
          >
            <Ellipsis maxWidth={100} style={{ marginLeft: 4 }}>
              {i18nFormat('Set as Default')}
            </Ellipsis>
          </Button>
        )}
      </Space>
      {operation === 'edit' && (
        <Modal
          open={operation === 'edit'}
          footer={null}
          closable={false}
          className={styles.editAddressModal}
          onCancel={() => toggleOperation()}
        >
          <ReceiveAddressForm
            address={address}
            onClose={() => toggleOperation()}
            onSubmit={_updateAddress}
          />
        </Modal>
      )}
    </div>
  );
};
