import './index.css';

import { EyeOutlined } from '@ant-design/icons';
import {
  getImagePreSignedUrl,
  getModelFileById,
  i18nFormat,
} from '@unionfab/ufc-shop-commons';
import * as S from '@unionfab/ufc-shop-commons';
import { useInterval, useSize } from 'ahooks';
import { Modal, Typography } from 'antd';
import cn from 'classnames';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

const DynamicD3ModelPreview = React.lazy(() => {
  return import('../D3ModelPreview');
});

function ErrorFallback({
  error,
  resetErrorBoundary,
}: {
  error: Error;
  resetErrorBoundary: () => void;
}) {
  React.useEffect(() => {
    console.log('>>>D3ModelAvatar>>>', error.message);
  }, []);

  return (
    <div role="alert">
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

export interface D3ModelAvatarProps {
  model?: S.D3ModelFile;
  size?: number | string;
  unit?: S.LengthUnit;
  className?: string;
}

async function getAvatarUrl(
  model: S.D3ModelFile,
  errorAvatarUrl?: string,
): Promise<string | undefined> {
  // 判断是否为图片文件
  if (model.attr.type === 'image') {
    return S.get(model, m => m.attr.thumbnail || (m.attr as any)['url']);
  } else {
    try {
      // 补全缩略图信息
      if (
        model.attr.isStandard3DModel &&
        model.attr.thumbnailFileId &&
        (!model.attr.thumbnail || model.attr.thumbnail === errorAvatarUrl)
      ) {
        model.attr.thumbnail = await getImagePreSignedUrl(
          model.attr.thumbnailFileId,
          undefined,
        );
      }
    } catch (_) {
      console.error('>>>D3ModelAvatar>>>', _);
    }
    return model.attr.thumbnail;
  }
}

async function reloadAvatarUrl(
  modelId: S.Id,
  errorAvatarUrl?: string,
): Promise<string | undefined> {
  const model = await getModelFileById(modelId);

  if (model) {
    return getAvatarUrl(model, errorAvatarUrl);
  }
}

export const D3ModelAvatar: React.FC<D3ModelAvatarProps> = ({
  size,
  unit,
  model,
  className,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState<string | undefined>();
  const [avatarDisplayError, setAvatarDisplayError] = useState<
    boolean | undefined
  >(false);

  const windowSize = useSize(document.getElementsByTagName('body')[0]);
  const modalSize = useMemo(() => {
    const w = windowSize?.width && windowSize.width * 0.6;
    const h = windowSize?.height && windowSize.height * 0.8;
    return { width: w, height: h == null || h < 600 ? 600 : h };
  }, [windowSize]);
  const previewSize = useMemo(() => {
    const w = modalSize.width;
    const h = modalSize.height;
    return { width: w == null ? w : w - 50, height: h == null ? h : h - 150 };
  }, [modalSize]);

  const delayReloadAvatarUrl = useMemo(() => {
    if (
      model &&
      (avatarDisplayError ||
        !avatarUrl ||
        avatarUrl === S.DefaultObjectAvatarPlaceholder)
    ) {
      return 3000;
    }
  }, [model, avatarDisplayError, avatarUrl]);

  useEffect(() => {
    (async () => {
      if (model) {
        const url = await getAvatarUrl(model);
        setAvatarUrl(url);
      }
    })();
  }, []);

  useInterval(() => {
    if (
      avatarDisplayError ||
      !avatarUrl ||
      avatarUrl === S.DefaultObjectAvatarPlaceholder
    ) {
      (async () => {
        try {
          const url = await reloadAvatarUrl(model.id, avatarUrl);
          setAvatarUrl(url);
          setAvatarDisplayError(undefined);
        } catch (e) {
          console.warn('error loading avatar url', model?.id);
        }
      })();
    }
  }, delayReloadAvatarUrl);

  const onClick = async () => {
    setShowModal(true);
  };

  const renderAvatarImg = (style: React.CSSProperties) => {
    return (
      <div className="d3-model-avatar-img-wrapper" style={style}>
        <img
          style={style}
          src={avatarUrl ? avatarUrl : S.DefaultObjectAvatarPlaceholder}
          alt="img"
          onError={() => {
            console.warn('something wrong about', avatarUrl);
            setAvatarDisplayError(true);
          }}
        />
        <EyeOutlined className="d3-model-avatar-img-eye-icon" />
      </div>
    );
  };

  if (!model || avatarDisplayError) {
    return (
      <div
        style={{
          width: size,
          height: size,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {i18nFormat('Loading')}...
      </div>
    );
  }

  return (
    <div
      aria-hidden="true"
      className={cn('d3-model-avatar-container', className)}
      onClick={onClick}
    >
      {renderAvatarImg({ height: size, width: size, objectFit: 'contain' })}
      {showModal ? (
        <Modal
          open={true}
          className="d3-model-avatar-container-modal"
          wrapClassName="d3-model-avatar-container-modal-wrapper"
          title={
            <Typography.Paragraph ellipsis={true}>
              {model.name}
            </Typography.Paragraph>
          }
          footer={null}
          width={modalSize.width}
          style={{ height: modalSize.height }}
          onCancel={e => {
            e.stopPropagation();
            setShowModal(false);
          }}
        >
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            {!S.get(model, i => i.attr.isStandard3DModel) ? (
              renderAvatarImg({ width: '100%' })
            ) : (
              <DynamicD3ModelPreview
                {...previewSize}
                unit={unit}
                modelFile={model}
              />
            )}
          </ErrorBoundary>
        </Modal>
      ) : (
        <></>
      )}
    </div>
  );
};
