import { getShopExpresses } from '@unionfab/ufc-shop-commons';
import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Input, Table } from 'antd';
import { isNil } from 'lodash-es';
import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';

import { useInquiryQuoteCheckoutStore } from '@/stores';

import { getColumns } from './common';
import styles from './index.module.less';

export const SelectExpressOptionsForCheckout = ({
  readonly,
}: {
  orderCode?: string;
  readonly?: boolean;
}) => {
  readonly = readonly == null ? false : readonly;
  const store = useInquiryQuoteCheckoutStore();
  const {
    quotation: orderQuotation,
    shipOptionDraft,
    setEstDeliveryTime,
    setShipOptionDraft,
    orderDeliverAddress,
    loadQuotation,
    order,
  } = store;

  const { data: expresses } = useQuery(
    ['express', orderDeliverAddress],
    async (): Promise<S.ShopExpress[]> => {
      if (!orderDeliverAddress || !orderDeliverAddress.country) return [];

      return await getShopExpresses(orderDeliverAddress.country);
    },
  );

  useQuery(['orderQuotation', orderQuotation], loadQuotation, {
    refetchInterval: 4000,
    /** 有 orderQuotation 且 shipQuotationsCalculating 为 true 时进行轮询 */
    enabled:
      !!orderQuotation &&
      S.get(orderQuotation, o => o.shipQuotationsCalculating, false),
  });

  const shipOption = useMemo(() => {
    if (!!order) {
      if (!!shipOptionDraft) return shipOptionDraft;
      if (!!order.customerShipOption) return order.customerShipOption;
    }

    return { expressId: undefined, remark: undefined };
  }, [shipOptionDraft, order]);

  const selectedExpressId: string | undefined = useMemo(() => {
    if (shipOption && shipOption.expressId) {
      return shipOption.expressId;
    }
    return null;
  }, [shipOption]);

  useEffect(() => {
    if (order && !selectedExpressId && S.isValidArray(expresses || [])) {
      // 筛选已有可用报价的物流公司
      const targetExpress = expresses.find(e => {
        const resp = S.calcOrderShipQuotation(
          e.id,
          orderQuotation.shipQuotationsRequiresManualQuote,
          orderQuotation.shipExpressQuotations,
        );
        return (
          !resp.requiresManualQuote && resp.expressQuotation && resp.quotation
        );
      });

      // 默认选择第一个存在有效价格的物流方式
      targetExpress && onSelectExpress(targetExpress.id!, shipOption.remark);
    }
  }, [selectedExpressId, expresses, order, orderQuotation]);

  useEffect(() => {
    if (
      !isNil(order) &&
      !isNil(selectedExpressId) &&
      isNil(S.get(order, o => o.customerShipOption.expressId))
    ) {
      onSelectExpress(selectedExpressId, shipOption.remark);
    }
  }, [order, shipOption]);

  const onSelectExpress = (expressId: string, remark?: string) => {
    setShipOptionDraft({ expressId, remark });
  };

  const columns = getColumns(
    S.get(order, o => o.currency),
    orderQuotation,
  );

  useEffect(() => {
    if (!!shipOption && S.isValidArray(expresses)) {
      const v = expresses.find(v => v.id === shipOption?.expressId);

      !!v && setEstDeliveryTime(v.deliveryStr);
    }
  }, [expresses, shipOption]);

  return (
    <span className={styles.expressOptionsContainer}>
      {!readonly && (
        <>
          <span>
            {i18nFormat(
              `If you don't known which shipping method to select, just leave it the default value, and you can note here you requirements or concerns, also feel free to consult our customer service later for detail.`,
            )}
          </span>
          <Input.TextArea
            style={{ marginTop: '10px', marginBottom: '10px' }}
            placeholder={i18nFormat('Please note your shipping requirements.')}
            value={shipOption.remark || ''}
            onChange={e =>
              setShipOptionDraft({
                expressId: shipOption.expressId,
                remark: e.target.value,
              })
            }
          />
        </>
      )}

      {readonly && shipOption.remark && (
        <Input.TextArea value={shipOption.remark} readOnly={true} />
      )}

      <Table
        rowKey="id"
        columns={columns}
        pagination={false}
        dataSource={expresses}
        rowSelection={{
          type: 'radio',
          selectedRowKeys: [selectedExpressId],
          onSelect: r => onSelectExpress(r.id, shipOption.remark),
        }}
      />
    </span>
  );
};
