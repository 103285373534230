import dayjs from 'dayjs';
import { max, min } from 'lodash-es';

import { get } from '../../utils';
import { InquiryOrder } from './InquiryOrder';
import { InquiryOrderItem } from './InquiryOrderItem';

export interface ILeadTimeInfo {
  maxLeadtimeInDays: number;
  minLeadtimeInDays: number;
  shipmentDate: string;
}

export function getLeadTimeInfo(inquiryOrder: InquiryOrder) {
  const leadTimeList: number[] = (inquiryOrder.items || []).map(
    (item: InquiryOrderItem) => {
      return get(item, i => i.materialSpu.leadTime.amount) as number;
    },
  );

  const maxLeadtimeInHours: number = max(leadTimeList) || 0;
  const minLeadtimeInHours = min(leadTimeList) || 0;
  const maxLeadtimeInDays = maxLeadtimeInHours / 24;
  const minLeadtimeInDays = minLeadtimeInHours / 24;
  const shipmentDate = dayjs()
    .add(maxLeadtimeInDays, 'day')
    .format('YYYY-MM-DD');

  return {
    maxLeadtimeInDays,
    minLeadtimeInDays,
    shipmentDate,
  } as ILeadTimeInfo;
}
