import * as S from '../../../../schema';
import {
  getTenantIdFromGConfig,
  getUfcShopComposedApi,
} from '../../../../singleton-ins';

export interface CalcQuotationItemV3 {
  fileId: S.Id;
  spuId: S.Id;
  lengthUnit: S.LengthUnit;
  volume: number;
  count: number;
  handle: S.HandleMethod;
}

export interface CalcQuotationV3 {
  currency: string;
  extraSpuIds: S.Id[];
  itemQuotationReqs: CalcQuotationItemV3[];
  country: string;
  couponCode: string;
}

export interface CalcQuotationMaterialPriceV3 {
  couponCode: string;
  discountAppliedTo: string;
  express2discountAmount: string;
  express2total: {};
  itemRespList: Array<{
    params: CalcQuotationItemV3;
    price: number;
    perPrice: number;
    requiresManualQuote: boolean;
  }>;
  requiresManualQuote: boolean;
  totalPrice: number;
}

export interface CalcQuotationRespV3 {
  // spu id -> price
  extraMaterialPrices: Record<S.Id, CalcQuotationMaterialPriceV3>;
  materialPrice: CalcQuotationMaterialPriceV3;
  shipExpressQuotations: {};
}

export async function calcPrice(
  calc: Partial<CalcQuotationV3>,
): Promise<CalcQuotationRespV3> {
  const {
    data,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.postUmi(
    `/customer/order/quotation?tenantId=${getTenantIdFromGConfig()}`,
    { data: calc },
  );
  return data;
}
