import * as S from '../../../../schema';
import {
  getTenantIdFromGConfig,
  getUfcShopComposedApi,
} from '../../../../singleton-ins';
import {
  getExpressMethodListForCustomer,
  getExpressTimingListForCustomer,
} from '../../order';

// 获取物流方式列表
export async function getShopExpresses(country: string) {
  const {
    data,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.getUmi<{
    data: S.ShopExpress[];
  }>(`/v2/shop/express`, {
    params: { country, tenantId: getTenantIdFromGConfig() },
  });

  return (data || []).map(d => new S.ShopExpress(d));
}

// 获取可用物流方式
export async function getExpresses(): Promise<S.Express[]> {
  return await getExpressMethodListForCustomer(getTenantIdFromGConfig());
}

// 快递公司支持的时效
export async function getExpressTimingRelations(): Promise<
  S.ExpressTimingRel[]
> {
  const d = await getExpressTimingListForCustomer(getTenantIdFromGConfig());
  return (d || []).map(v => ({
    id: v.id,
    expressId: v.expressId,
    expressTimingType: v.expressTimingType,
    systemQuote: v.systemQuote,
    status: v.status,
  }));
}

export async function getExpressTimings(
  country: string,
): Promise<S.ExpressTiming[]> {
  const tenantId = getTenantIdFromGConfig();
  const params = { tenantId, country };
  const {
    data,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.getUmi<{
    data: S.ExpressTiming[];
  }>(`/customer/order/express_timing`, {
    params,
  });
  return data || [];
}

export async function getExpressTimingsToLocation(
  country: string,
  data: {
    expresses?: S.Express[];
    expressTimingRelation?: S.ExpressTimingRel[];
  },
): Promise<S.ExpressTimingToLocation[]> {
  const expresses: S.Express[] = data.expresses
    ? data.expresses
    : await getExpresses();
  const expressTimingRelation: S.ExpressTimingRel[] = data.expressTimingRelation
    ? data.expressTimingRelation
    : await getExpressTimingRelations();

  // 发到给定国家支持的时效
  const timings = (await getExpressTimings(country)).filter(
    v => v.status === 'ENABLE',
  );
  const timingTypeToTiming = {} as Record<S.ExpressTimingType, S.ExpressTiming>;
  for (const v of timings) {
    timingTypeToTiming[v.timingType] = v;
  }

  // 支持的物流公司及其时效
  const expressIdToTimingType = {} as Record<string, S.ExpressTimingType>;
  for (const v of expressTimingRelation) {
    if (v.status === 'ENABLE' && timingTypeToTiming[v.expressTimingType]) {
      expressIdToTimingType[v.expressId] = v.expressTimingType;
    }
  }

  const res: S.ExpressTimingToLocation[] = [];
  for (const v of expresses) {
    const timingType = expressIdToTimingType[v.id!];
    if (!timingType) {
      continue;
    }
    const timing = timingTypeToTiming[timingType];
    if (!timing) {
      continue;
    }
    let timingDesc;
    if ((v.name || '').toLocaleLowerCase().includes('expedite')) {
      timingDesc = formatTimingDesc(timing.timingDesc);
    } else {
      timingDesc = formatTimingDesc(timing.timingDesc, [-2, -1]);
    }
    // console.log(v.name, timing.timingDesc, timingDesc);
    res.push({
      country,
      expressId: v.id!,
      timingDesc,
      restriction: '≤ 10000 KG',
    });
  }

  return res;
}

const TIMING_RE = /(\d+)-(\d+).*[dD][aA][yY]s?/;
function formatTimingDesc(s: string, inc?: [number, number]) {
  if (!s) {
    return s;
  }
  inc = inc == null ? [0, 0] : inc;
  const m = s.match(TIMING_RE);
  if (m) {
    let start = parseInt(m[1]) + inc[0];
    start = start <= 1 ? 2 : start;
    let end = parseInt(m[2]) + inc[1];
    end = end <= start + 1 ? start + 2 : end;
    return `${start}-${end} days`;
  } else {
    return s;
  }
}
