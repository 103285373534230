import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Divider, Space } from 'antd';

import { useAppNavigate } from '@/features/shared';
import { useInquiryQuoteEditorStore } from '@/stores';

import { TechCategorySelector } from '../../../../components/select/TechCategorySelector';
import styles from './index.module.less';

export const QuoteEditorEmptyPlaceholder = () => {
  const nav = useAppNavigate();

  const {
    changeTechNameInInquiryQuoteEditorStore,
    technology,
  } = useInquiryQuoteEditorStore();

  // CNC/3D Printing
  const techCollectionSelection = (
    <div className={styles.quoteEmptyPlaceholderFlow1Container}>
      <div className={styles.quoteEmptyPlaceholderFlowTitle}>
        1. {i18nFormat('Choose a service')}
      </div>
      <TechCategorySelector
        tech={technology}
        onChange={changeTechNameInInquiryQuoteEditorStore}
      />
    </div>
  );

  const uploadHint = (
    <div className={styles.quoteEmptyPlaceholderFlow2Container}>
      <span className={styles.quoteEmptyPlaceholderFlowTitle}>
        2. {i18nFormat('Upload design and configure parts to get a quote')}
      </span>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '1.025rem',
        }}
      >
        <Space split={<Divider type="vertical" />}>
          <a onClick={() => nav.navigateToPdfPageNewTab('design_spec')}>
            {i18nFormat('Design specification >')}
          </a>
        </Space>
      </div>
    </div>
  );

  return (
    <div className={styles.quoteEmptyPlaceholderCreatorContainer}>
      {techCollectionSelection}
      {uploadHint}
    </div>
  );
};
