import { PageLoading } from '@ant-design/pro-components';
import {
  get,
  getUseShopQuoteStoreByCode,
  i18nFormat,
  useProfileStore,
} from '@unionfab/ufc-shop-commons';
import { Button } from 'antd';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { OrderBankTransferDetails } from '../../../../components/pay/bank-transfer/OrderBankTransferDetails';
import styles from './index.module.less';

export interface ShopQuotePayBankTransferPageProps {
  className?: string;
  style?: Record<string, string | number>;
}

export const ShopQuotePayBankTransferPage = ({
  className,
  style,
}: ShopQuotePayBankTransferPageProps) => {
  const orderCode = useParams().orderCode;

  const nav = useNavigate();
  const { shopOrder, shopOrderPrice } = getUseShopQuoteStoreByCode(orderCode)();

  const { accountInfo, loadPersonInfo } = useProfileStore(a => ({
    accountInfo: a.accountInfo,
    loadPersonInfo: a.loadPersonInfo,
  }));

  useEffect(() => {
    !accountInfo && loadPersonInfo();
  }, [accountInfo]);

  if (!shopOrder) {
    return <PageLoading />;
  }

  // 目前 subtotal 即材料费
  const total = shopOrderPrice?.price ? shopOrderPrice?.price.amount : 0;

  useEffect(() => {
    if (!shopOrderPrice || total == null) {
      nav('/');
    }
  }, [shopOrderPrice]);

  if (!shopOrderPrice || total == null) {
    console.warn('illegal state');
    return <></>;
  }

  return (
    <div className={styles.bankTransferPage}>
      <OrderBankTransferDetails
        code={orderCode}
        total={total}
        currency={shopOrderPrice?.price.currency}
        userEmail={get(accountInfo, a => a.email)}
      />
      <div className={styles.backToCheckout}>
        <Button
          size="large"
          type="primary"
          onClick={() => {
            nav(`/new_shop_quote/${orderCode}/payment`);
          }}
        >
          {i18nFormat('back to change')}
        </Button>
      </div>
    </div>
  );
};

ShopQuotePayBankTransferPage.displayName = 'ShopQuotePayBankTransferPage';
