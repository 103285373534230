import { Address, Id } from '../../common';

export class ReceiveAddress extends Address {
  customerId: Id;

  /** 收货人 */
  recipient: string;

  firstName?: string;

  lastName?: string;

  /** 收货人联系电话 */
  contactInfo: string;

  email?: string;

  constructor(props?: Partial<ReceiveAddress>) {
    super(props);

    Object.assign(this, props);
  }
}

export interface BillingAddress extends ReceiveAddress {
  firstName: string;

  lastName: string;

  email: string;

  country: string;
}
