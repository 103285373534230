import { useEffect } from 'react';

import { useGlobalStore } from './common/useGlobalStore';

export const USER_ENTRY_TYPES = [
  // 登录
  'SignIn',
  // 注册
  'Register',
  // 发起密码修改
  'ChangePassword',
  // 重置密码（如邮件链接中的密码重置）
  'ResetPassword',
] as const;

export type UserEntryType = typeof USER_ENTRY_TYPES[number];

export type UserRegisterStep = 'wait-register' | 'register' | 'registered';

export interface UserEntryStore {

  registerStep: UserRegisterStep;
  setRegisterStep: (step: UserRegisterStep) => void;

  onRegisterContactFormFilledContinue?: () => UserRegisterStep | undefined;
  setOnRegisterContactFormFilledContinue: (
    cb?: () => UserRegisterStep | undefined,
  ) => void;

  onRegistered?: () => void;
  setOnRegistered: (cb?: () => void) => void;

  entryType?: UserEntryType;
  setEntryType: (entryType?: UserEntryType) => void;

  onSigned?: () => void;

  /** 控制对应弹窗的展示与否 */
  signInSignUpModalVisible: boolean;

  onSignUpModalClose?: () => void;
  /**
   *  是否是匿名用户登录或者注册
   * 当为 true 时不展示信息收集弹窗 CollectInfoModal 组件，等待匿名订单 fork 结束后再展示
   */
  isAnonymousLoginOrRegister: boolean;

  setState: (p: Partial<UserEntryStore>) => void;
}

export function getNavbarUserEntryStore(): UserEntryStore | undefined {
  const w = window as any;
  return w?.unionfab?.stores?.navbarUserEntryStore;
}

export function openRegisterFormAndContinueSignUp(
  onSignUpSuccess: () => void,
): boolean {
  const entryStore = getNavbarUserEntryStore();
  if (entryStore) {
    entryStore.setOnRegistered(() => {
      onSignUpSuccess();
      entryStore.setEntryType(undefined);
    });
    if (
      entryStore.registerStep === 'wait-register' ||
      entryStore.registerStep === 'register'
    ) {
      entryStore.setRegisterStep('register');
      entryStore.setEntryType('Register');
    } else {
      // entryStore.setRegisterStep('contact-form');
      entryStore.setEntryType('Register');
    }
    return true;
  } else {
    return false;
  }
}

export function useDoRegisterAndContinue(
  onRegisterFail: () => void,
  onSignUpSuccess?: () => void,
) {
  const { loadUser, user, hasAuthenticated, isDemoUser } = useGlobalStore();
  useEffect(() => {
    if (onSignUpSuccess) {
      if (!openRegisterFormAndContinueSignUp(() => loadUser(true))) {
        onRegisterFail();
      }
    }
  }, [loadUser, onSignUpSuccess, onRegisterFail]);

  useEffect(() => {
    if (hasAuthenticated && !isDemoUser && onSignUpSuccess) {
      console.info('logged into', user);
      onSignUpSuccess();
    }
  }, [hasAuthenticated, isDemoUser, onSignUpSuccess]);
}
