import { RightOutlined } from '@ant-design/icons';
import { Button, Descriptions, Popover } from 'antd';
import { Fragment } from 'react';

import {
  i18nFormat,
  ShopCncRequirements,
} from '../../../../../../../../ufc-shop-commons/src';
const DItem = Descriptions.Item;

interface Props {
  m: string;
  fs: string;
  lt: string;
  cncRequirements: ShopCncRequirements;
  renderedFiles: any;
  n: string;
}

export default function CNCDescriptions({
  m,
  fs,
  lt,
  cncRequirements,
  renderedFiles,
  n,
}: Props) {
  const allowAdapt = cncRequirements?.allowProductAdapt;
  const allowAdaptText = allowAdapt == null ? 'Yes' : allowAdapt ? 'Yes' : 'No';
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Descriptions title="User Info" column={2} size="small">
        <DItem label={i18nFormat('Material')}>{m}</DItem>
        <DItem label={i18nFormat('Tolerance')}>
          {(cncRequirements || {})?.tolerance?.option}
          <Locations>
            {(cncRequirements || {})?.tolerance?.otherRemark}
          </Locations>
        </DItem>
        <DItem label={i18nFormat('Finish')}>{fs}</DItem>
        <DItem label={i18nFormat('Surface Roughness')}>
          {(cncRequirements || {})?.surfaceRoughness?.option}
          <Locations>
            {(cncRequirements || {})?.surfaceRoughness?.otherRemark}
          </Locations>
        </DItem>
        <DItem label={i18nFormat('Lead Time')}>{lt}</DItem>
        <DItem label={i18nFormat('Part Marking')}>
          {(cncRequirements || {})?.partMarking?.option}
        </DItem>
        <DItem label={i18nFormat('Technical Drawing')}>{renderedFiles}</DItem>
        <DItem label={i18nFormat('Locational Fit')}>
          {((cncRequirements || {})?.locationFits || []).map((fit, i) => (
            <Fragment key={i}>
              {i18nFormat(fit.option)}
              <Locations>{fit.location}</Locations>
              <br />
            </Fragment>
          ))}
        </DItem>
        <DItem label={i18nFormat('Notes')}>{n}</DItem>
      </Descriptions>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Popover
          content={
            <div style={{ maxWidth: 320, maxHeight: 400, overflow: 'auto' }}>
              <div>
                <div style={{ fontWeight: 600 }}>
                  {i18nFormat(
                    'Considering that the product structure cannot be manufactured as per the drawings, would you be open to either revising the drawings or producing the product in separate parts and assemblies?',
                  )}
                </div>
                <div style={{ marginLeft: 6 }}>{allowAdaptText}</div>
              </div>
              {(cncRequirements?.inspectSelection || []).length > 0 && (
                <div>
                  <div style={{ fontWeight: 600 }}>
                    {i18nFormat('Inspection')}
                  </div>
                  {(cncRequirements?.inspectSelection || []).map(ins => (
                    <>
                      <div style={{ fontWeight: 500, marginLeft: 6 }}>
                        {ins.title}
                      </div>
                      <div style={{ marginLeft: 18 }}>{ins.desc}</div>
                    </>
                  ))}
                </div>
              )}
            </div>
          }
        >
          <Button type="link">
            {i18nFormat('More')} <RightOutlined />
          </Button>
        </Popover>
      </div>
    </div>
  );
}

function Locations({ children }: { children: any }) {
  return children == null ? <></> : <>, {children}</>;
}
