import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Table } from 'antd';

import styles from './index.module.less';
import insertsData from './inserts.json';

const columns = [
  {
    title: i18nFormat('Specifications/Models'),
    dataIndex: 'spec',
    align: 'center',
    render: (text: number[], record: any) => {
      return <div style={{ fontWeight: 'bold' }}>{text}</div>;
    },
  },
  {
    title: i18nFormat('High (mm)'),
    dataIndex: 'high',
    align: 'center',
    render: (text: number[], record: any) => {
      return (
        <div>
          {(text || []).map((insertValue: number, idx: number) => {
            return (
              <div key={`${record.spec}-high-${insertValue}-${idx}`}>
                {insertValue}
              </div>
            );
          })}
        </div>
      );
    },
  },
  {
    title: i18nFormat('Diameter size (mm)'),
    dataIndex: 'diameter',
    align: 'center',
    render: (text: number[], record: any, idx: number) => {
      return (
        <div>
          {(text || []).map((insertValue: number, idx: number) => {
            return (
              <div key={`${record.spec}--${insertValue}-${idx}`}>
                {insertValue}
              </div>
            );
          })}
        </div>
      );
    },
  },
];

export const InsertTable = () => {
  return (
    <div className={styles.insertTable}>
      <Table
        rowClassName={(_, idx) => (idx % 2 ? styles.oddRow : styles.evenRow)}
        pagination={false}
        title={() => {
          return (
            <div className="standard-inserts-table-title">
              {i18nFormat('Standard Inserts')}
            </div>
          );
        }}
        dataSource={insertsData}
        columns={columns as any}
      />
    </div>
  );
};
