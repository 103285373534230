import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import {
  AliyunSlsApmTrackerUtils,
  trackGA4BuiltinEvent,
  UnionfabEventTracker,
} from '../../apis';

const renderPageStart = dayjs();

// depends on react-router.
export const usePageTracking = () => {
  const location = useLocation();

  AliyunSlsApmTrackerUtils.getAliyunSlsApmTracker().pageRenderDuration = dayjs().diff(
    renderPageStart,
    'milliseconds',
  );

  useEffect(() => {
    if (typeof gtag !== 'undefined') {
      gtag('config', 'G-9JBLM1QTRY', {
        page_title: window.document.title,
        page_location: 'https://www.unionfab.com' + location.pathname,
      });

      trackGA4BuiltinEvent('page_view', {
        page_title: window.document.title,
        page_location: 'https://www.unionfab.com' + location.pathname,
        page_path: location.pathname,
        send_to: 'G-9JBLM1QTRY',
      });
    }

    UnionfabEventTracker.getIns().addUserEventRecord({
      typeName: 'page_view',
      urlId: location.pathname,
    });

    AliyunSlsApmTrackerUtils.getAliyunSlsApmTracker().sendPageRecord({
      page_path_template: '/order' + location.pathname,
      page_path: '/order' + location.pathname,
    });
  }, [location]);
};
