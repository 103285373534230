export default {
  // HTTP Code
  'error.http.200': 'The server successfully returned the requested data.',
  'error.http.201': 'New or modified data successfully.',
  'error.http.202':
    'A request has entered the background queue (asynchronous task).',
  'error.http.204': 'Data deleted successfully.',
  'error.http.400':
    'There was an error in the request sent, and the server did not create or modify data.',
  'error.http.401':
    'The user does not have permission (token, user name, password is wrong), please log in again.',
  'error.http.403': 'The user is authorized, but access is forbidden.',
  'error.http.404':
    'The request sent was for a record that did not exist, and the server did not operate.',
  'error.http.406': 'The requested format is not available.',
  'error.http.410':
    'The requested resource is permanently deleted and will no longer be available.',
  'error.http.422': 'When creating an object, a validation error occurred.',
  'error.http.500': 'An error occurred in the server, please check the server.',
  'error.http.502': 'Gateway error.',
  'error.http.503':
    'The service is unavailable, and the server is temporarily overloaded or maintained.',
  'error.http.504': 'The gateway timed out.',
  // Backend Error Code
  // 通用异常
  'error.backend.INTERNAL_SERVER_ERROR': 'Other abnormalities.',
  'error.backend.PARAM_ERROR': 'Interface parameter verification failed.',
  'error.backend.PARAM_TYPE_ERROR':
    'The interface parameter type does not meet the requirements.',
  'error.backend.CLIENT_ABORT':
    'Before the service is completed, the client actively disconnects.',
  'error.backend.NOT_ACCEPTABLE':
    'There is a problem with the requested data format or the request body cannot be parsed normally.',
  'error.backend.BAD_REQUEST': 'The request cannot be processed normally.',
  'error.backend.FORBIDDEN': 'Not authorized to perform the given operation.',
  'error.backend.UNAUTHORIZED': 'No login information was found.',
  'error.backend.NOT_FOUND': 'Resource does not exist.',
  // 账户相关
  'error.backend.ACCOUNT_0001': 'User mailbox has been registered.',
  'error.backend.ACCOUNT_0002': 'Incorrect username or password.',
  'error.backend.ACCOUNT_0003': 'User does not exist.',
  'error.backend.ACCOUNT_0004': 'Invalid or expired TOKEN.',
  'error.backend.ACCOUNT_0005': 'Invalid verification code.',
  'error.backend.ACCOUNT_0006': 'User WeChat applet user is not bound.',
  'error.backend.ACCOUNT_0007':
    'Invalid login data (WeChat applet, WeChat login, etc. login data is abnormal).',
  'error.backend.ACCOUNT_0008':
    'Sorry, Google registration cannot be supported due to system upgrade. Please register an account by email, thank you',
  // 订单相关
  'error.backend.ORDER_0001':
    'The order entry does not meet the production conditions.',
  // 设备 RPC
  'error.backend.DEVICE_RPC_0001': 'Device not connected.',
  // 文件上传相关错误
  FILE_UPLOAD_FAILED_ERROR:
    'File {fileName} upload failed, please try again, it may be because the file is too large or the network is abnormal',
  PARSE_MODEL_FILE_LOADING:
    'File {fileName} is uploaded, volume analysis failed/screenshot failed, trying to resolve locally.',
  MAX_FILE_SIZE_WARN: '{fileName} model file is larger than {maxFileSize}M',
  MODEL_UPLOAD_FILE_PER_TIME_MAX_COUNT:
    'The number of uploaded files is greater than {maxCount}.',
  MODEL_FILE_UPLOAD_MAX_LIMIT:
    'A maximum of {maxCount} files can be uploaded per time.',
};
