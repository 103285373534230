import { message } from 'antd';
import { debounce } from 'lodash-es';

import {
  copyFile,
  getCurrencyExchangeRate,
  getDefaultSpuByMaterialId,
  getTenantIdFromGConfig,
  getUfcShopComposedApi,
  UnionfabEventTracker,
} from '../../../apis';
import * as S from '../../../schema';
import { useInquiryMaterialStore } from '../../offerings/useInquiryMaterialStore';
import { getUseShopQuoteStoreByCode } from './useShopQuoteStore';
import { useShopQuoteUiStore } from './useShopQuoteUiStore';

export type ShopOrderModifier = (o: Partial<S.ShopOrder>) => void;

export const LK_SELECTED_COUPON_CODE = 'LK_SELECTED_COUPON_CODE';

const orderHistoryMap: Record<string, S.ShopOrder> = {};

export class ShopOrderQuoteStoreModifiers {
  static validateCouponAndReturnErrorMsgByOrderCode(
    orderCode: string,
    coupon: S.InquiryCoupon,
  ) {
    const store = getUseShopQuoteStoreByCode(orderCode);
    const {
      shopOrder,
      shopOrderPrice,
      ratioFromUsdToOrderCurrency,
    } = store.getState();

    return S.InquiryCouponValidator.validateCouponAndReturnErrorMsg(coupon, {
      price: {
        subtotal: S.get('', () => shopOrderPrice.production.price.amount),
        shipping: S.get('', () => shopOrderPrice.delivery.price.amount),
        total: S.get('', () => shopOrderPrice.price.amount),
        discount: S.get('', () => shopOrderPrice.couponApplied.price.amount),
      },
      ratioFromUsdToOrderCurrency,
      countryList: S.get('', () =>
        shopOrder.delivery.items.map(i => i.country),
      ),
      isOnlySelectSla: S.get('', () =>
        S.ShopOrderAccessors.isOnlySlaMaterialType(shopOrder),
      ),
      isOnlySelectMetal: S.get('', () =>
        S.ShopOrderAccessors.isOnlyMetalMaterialType(shopOrder),
      ),
    });
  }

  /** 对于添加或者更新文件的抽象方法 */
  static addOrUpdateOrderItemByFile = (
    orderCode: string,
    modelFiles: S.D3ModelFile[],
    cb?: Function,
    cncOpts?: any,
  ) => {
    const store = getUseShopQuoteStoreByCode(orderCode);

    const shopOrder = store.getState().shopOrder;

    const {
      techTypeToMaterials,
      materials: inquiryMaterials,
    } = useInquiryMaterialStore.getState();

    // 如果当前订单还没有条目，则从界面选择中获取；否则从全局默认中获取
    let curTechCategory: S.TechCategory;

    if (shopOrder.items.length === 0) {
      curTechCategory = useShopQuoteUiStore.getState().curTechCategory;
    } else {
      curTechCategory = S.ShopOrderAccessors.getCurTechCategory(shopOrder);
    }

    UnionfabEventTracker.getIns().addUserEventRecord({
      typeName: 'upload_file',
      fileIds: modelFiles.map(f => f.id),
    });

    ShopOrderQuoteStoreModifiers.updateOrder({
      cb,
      orderCode,
      onChange: prevShopOrder => {
        for (const modelFile of modelFiles) {
          // 判断文件是否存在，存在则替换
          const existedOrderItem = prevShopOrder.items.find(
            o => o.fileId === modelFile.id,
          );

          if (existedOrderItem) {
            // 对于已存在的先不做处理
          } else {
            const defaultNewMaterial: S.InquiryMaterial = S.get(
              techTypeToMaterials,
              () => techTypeToMaterials[curTechCategory.type][0],
            );

            // 根据当前的工艺类别获取到 Sku
            let defaultNewSku = getDefaultSpuByMaterialId(
              S.get(
                techTypeToMaterials,
                m => m[curTechCategory.type][0].id,
              ) as S.Id,
              inquiryMaterials,
            );

            if (!defaultNewSku) {
              message.error(
                `${curTechCategory.type} doesn't have available quotation materials`,
              );

              // 如果选定的工艺没有材料，则默认选择第一个
              defaultNewSku = inquiryMaterials.filter(
                m => m.spuList.length > 0,
              )[0].spuList[0];
            }

            const baseShopItem: any = {
              fileId: modelFile.id,
              skuId: defaultNewSku.id,
              printCount: 1,
              tenantId: getTenantIdFromGConfig(),
              handleRemark: { method: [''], desc: '', desc1: '' },
              remark: { remark: '', fileIds: [] },
              lengthUnit: S.LengthUnitList[1],

              modelFileVO: modelFile,
              materialVO: defaultNewMaterial,
              materialSkuVO: defaultNewSku,
            };
            // 提供了 cnc 工艺就添加默认值
            if (S.isValid(cncOpts)) {
              baseShopItem.cncRequirements = {
                tolerance: {
                  option: S.get(cncOpts, c => c.tolerance.options[0]),
                },
                surfaceRoughness: {
                  option: S.get(cncOpts, c => c.surfaceRoughness.options[0]),
                },
                locationFits: [
                  {
                    option: S.get(cncOpts, c => c.locationsFit.options[0]),
                  },
                ],
                allowProductAdapt: true,
              };
            }

            // 对于不存在的则添加
            const newOrderItem = new S.ShopOrderItem(baseShopItem, {});

            prevShopOrder.items.push(newOrderItem);
          }
        }
      },
    });
  };

  /** 选择优惠券并重新获取价格信息 */
  static selectShopOrderCoupon = async (
    orderCode: string,
    couponCode: string,
  ) => {
    const store = getUseShopQuoteStoreByCode(orderCode);

    const shopOrder = store.getState().shopOrder;

    // 判断是否需要刷新价格
    if (S.ShopOrderAccessors.isReadyToCalcPrice(shopOrder)) {
      try {
        store.setState({ isLoading: true, couponCode });

        localStorage.setItem(
          `${LK_SELECTED_COUPON_CODE}_${orderCode}`,
          couponCode,
        );

        const price = await getUfcShopComposedApi().shopOrderQueryApi.calcPrice(
          orderCode,
          {
            couponCode:
              couponCode !== "Don't choose coupon" ? couponCode : undefined,
            expressId:
              shopOrder.requirements.customerSelections.delivery.expressId,
          },
        );

        store.setState({ shopOrderPrice: price, isLoading: false });
      } catch (e) {
        store.setState({ isLoading: false });
        console.log('>>>selectShopOrderCoupon>>>error', e);
      }
    }
  };
  /** 批量查询有效优惠券 */
  static batchQueryOrderCoupon = async (
    orderCode: string,
    couponCodes: string[],
    expressId: string,
  ) => {
    if (expressId) {
      try {
        const ShopOrderPriceList = await getUfcShopComposedApi().shopOrderQueryApi.calcPriceByCoupons(
          orderCode,
          {
            couponCodes: couponCodes || undefined,
            expressId,
          },
        );
        return ShopOrderPriceList;
      } catch (e) {
        console.log('>>>batchQueryOrderCoupon>>>error', e);
      }
    }
    return [];
  };
  /** 更新订单的快捷方法 */
  static updateOrder = async ({
    orderCode,
    onChange,
    cb,
    autoSave = true,
  }: {
    orderCode: string;
    onChange: ShopOrderModifier;
    autoSave?: boolean;
    cb?: Function;
  }) => {
    const store = getUseShopQuoteStoreByCode(orderCode);

    if (store.getState().isLoading) {
      message.error('Server is processing, please retry later.');
      return;
    }

    // 设置状态
    store.setState(({ shopOrder }) => {
      const prevShopOrder = new S.ShopOrder(shopOrder);

      // 第一次更新的时候，记录下订单 Snapshot，后续会在 debounce 中记录
      if (!orderHistoryMap[prevShopOrder.code]) {
        orderHistoryMap[prevShopOrder.code] = new S.ShopOrder(
          JSON.parse(JSON.stringify(prevShopOrder)),
        );
      }

      onChange(prevShopOrder);

      return {
        shopOrder: new S.ShopOrder(prevShopOrder),
        shopOrderDraft: new S.ShopOrder(prevShopOrder),
      };
    }, false);

    // 控制向服务端提交
    if (typeof autoSave == 'boolean' && autoSave) {
      await ShopOrderQuoteStoreModifiers.debouncedOnOrderUpdateSideEffect(
        orderCode,
        cb,
      );
    }
  };

  /** 更新订单的快捷方法 */
  static updateOrderDraft = async (
    orderCode: string,
    onChange: ShopOrderModifier,
  ) => {
    const store = getUseShopQuoteStoreByCode(orderCode);

    // 设置状态
    store.setState(({ shopOrderDraft }) => {
      const prevShopOrder = new S.ShopOrder(shopOrderDraft);

      onChange(prevShopOrder);

      return {
        shopOrderDraft: new S.ShopOrder(prevShopOrder),
      };
    }, false);
  };

  /** 复制条目 */
  static async copyOrderItems(
    orderCode: string,
    orderItemIds: string[],
    cncOpts?: any,
  ) {
    const store = getUseShopQuoteStoreByCode(orderCode);

    const shopOrder = store.getState().shopOrder;

    const copiedModelFiles: S.D3ModelFile[] = [];
    const orderItems = shopOrder.items.filter(i => orderItemIds.includes(i.id));

    // 复制模型，然后复制条目
    for (const orderItem of orderItems) {
      const copiedModelFileId = await copyFile(orderItem.modelFileVO);
      copiedModelFiles.push(
        new S.D3ModelFile({ ...orderItem.modelFileVO, id: copiedModelFileId }),
      );
    }

    ShopOrderQuoteStoreModifiers.addOrUpdateOrderItemByFile(
      orderCode,
      copiedModelFiles,
      () => {},
      cncOpts,
    );
  }

  /** 移除条目 */
  static removeOrderItemByFileIds(orderCode: string, modelFileIds: S.Id[]) {
    useShopQuoteUiStore.setState({
      editingFileId: undefined,
      batchEditingFileIds: [],
    });

    const store = getUseShopQuoteStoreByCode(orderCode);
    const { selectedOrderItemIds, shopOrder } = store.getState();

    const waitToRemoveItemIds: string[] = [];

    const newItems = (shopOrder.items || []).filter(item => {
      if (modelFileIds.includes(item.fileId)) {
        waitToRemoveItemIds.push(item.id);
      }
      return !modelFileIds.includes(item.fileId);
    });

    const ids = (selectedOrderItemIds || []).filter(
      i => !waitToRemoveItemIds.includes(i),
    );

    ShopOrderQuoteStoreModifiers.updateOrder({
      orderCode,
      onChange: o => {
        o.items = newItems;
      },
      cb: () => store.setState({ selectedOrderItemIds: ids }),
    });
  }

  static async saveOrderToServer(orderCode: string) {
    const store = getUseShopQuoteStoreByCode(orderCode);

    const shopOrder = store.getState().shopOrder;

    // 更新数据
    await getUfcShopComposedApi().shopOrderUpdateApi.updateShopOrderByCode(
      orderCode,
      S.ShopOrderRequestParamsBuilder.fromShopOrder(shopOrder),
    );
  }

  /** 防抖执行一些异步更新的操作 */
  static debouncedOnOrderUpdateSideEffect = debounce(
    async (orderCode: string, cb?: Function) => {
      const store = getUseShopQuoteStoreByCode(orderCode);

      try {
        store.setState({ isLoading: true });

        await ShopOrderQuoteStoreModifiers.saveOrderToServer(orderCode);

        // 从服务端获取最新的订单信息
        const shopOrder = await getUfcShopComposedApi().shopOrderQueryApi.queryShopOrderByCode(
          orderCode,
        );

        /** 目前仅未提交的订单允许编辑 */
        const allowEdit = S.get(shopOrder, s => s.status) === 'WAIT_SUBMIT';

        // 刷新报价
        const quotation = await getUfcShopComposedApi().shopOrderQueryApi.queryQuotation(
          orderCode,
        );

        store.setState({
          shopOrderQuotation: quotation,
          shopOrder,
          shopOrderDraft: shopOrder,
          allowEdit,
        });

        // 判断是否需要刷新价格
        if (
          S.ShopOrderAccessors.isReadyToCalcPrice(store.getState().shopOrder)
        ) {
          const shopOrder = store.getState().shopOrder;

          const price = await getUfcShopComposedApi().shopOrderQueryApi.calcPrice(
            orderCode,
            {
              couponCode: store.getState().couponCode,
              expressId:
                shopOrder.requirements.customerSelections.delivery.expressId,
            },
          );

          store.setState({ shopOrderPrice: price });
        }

        // 对比某些属性变化做出调整
        await this.diffAndPatchAfterUpdate(
          orderHistoryMap[orderCode],
          store.getState().shopOrder,
        );

        // 对当前订单设置快照
        orderHistoryMap[orderCode] = new S.ShopOrder(
          JSON.parse(JSON.stringify(store.getState().shopOrder)),
        );

        store.setState({ isLoading: false });

        typeof cb == 'function' && cb();
      } catch (_e) {
        console.log('>>>debouncedOnOrderUpdateSideEffect>>>error', _e);
        store.setState({ isLoading: false });
      }
    },
    1500,
  );

  static async diffAndPatchAfterUpdate(
    prevOrder: Partial<S.ShopOrder>,
    nextOrder: Partial<S.ShopOrder>,
  ) {
    if (!prevOrder) return;

    const store = getUseShopQuoteStoreByCode(prevOrder.code);

    /** 要进行单独触发副作用更新的属性提取出来 */
    const prevCurrency = prevOrder.requirements.quotation.currency;
    const nextCurrency = nextOrder.requirements.quotation.currency;

    if (prevCurrency !== nextCurrency) {
      let ratioFromUsdToOrderCurrency = 1;
      if (nextCurrency !== 'USD') {
        ratioFromUsdToOrderCurrency = await getCurrencyExchangeRate(
          'USD',
          nextCurrency,
        );
        store.setState({ ratioFromUsdToOrderCurrency });
      }
    }
  }
}
