import { escapeStringBugs, isLanIp, isValidArray, parseUrl } from '../../utils';
import { BaseEntity, Id } from '../type';

export type FileStoreAclType =
  | 'DEFAULT'
  | 'PRIVATE'
  | 'PUBLIC_READ'
  | 'PUBLIC_READ_WRITE';
export type FileStoreType = 'LOCAL' | 'ALI_OSS' | 'UFC' | 'HTTP_READ_ONLY';

/** 文件存储 */
export class FileStore extends BaseEntity<FileStore> {
  gatewayId: Id;
  tenantId: Id;
  type: FileStoreType;

  bucketName: string;

  // 这里存放的是 Store 的默认的 endpoint
  endpoint: string;

  // 衍生属性，在获得到 FileStore 的时候使用
  connectiveCheckUrl: string;

  acl: FileStoreAclType;

  // 该 FileStore 作用域
  scope: 'tenant' | 'global';

  extraSetting: {
    // 访问码
    accessKeyId: string;

    // 是否在公共可用
    onPublicNetwork: boolean;

    // 关于该 FileStore 的描述
    description: string;

    // 可用性检查地址，这里使用数组的原因是，某个 FileStore 可能存在多个可达的地址
    connectiveCheckUrls: Record<string, string>;

    // 对于内网中的端点，可能存在多个端点
    endpoints: string[];
  };

  get isLan() {
    // tslint:disable-next-line: no-string-literal
    return isLanIp(parseUrl(this.endpoint)['host']);
  }

  get name() {
    // 返回网关的描述或者阿里云的 BucketName
    return this.gatewayId ? this.extraSetting.description : this.bucketName;
  }

  constructor(props?: Partial<FileStore>) {
    super(props);

    Object.assign(this, props);
  }
}

export class FileStoreUploadOption extends BaseEntity<FileStoreUploadOption> {
  url: string;
  method: string;
  expiresAt: string;
  uploadedUrl: string;

  constructor(data: Partial<FileStoreUploadOption> = {}) {
    super(data);

    Object.assign(this, data);
  }
}

export class FileStoreAuth extends BaseEntity<FileStoreAuth> {
  // 关联的存储
  storeId: Id;

  // 上传的地址
  url: string;

  // 上传的方法
  method: string;

  // 过期时间
  expiresAt: string;

  headers: Partial<{
    key: string;
  }> = {};

  fileStore: FileStore;

  getStoreKey(fileName: string, ext = '', prefix = '') {
    // 移除全部的特殊字符
    let normalizedFileName = escapeStringBugs(fileName);

    const splitFrags = normalizedFileName.split('.') || [];

    if (ext) {
      splitFrags[0] = `${splitFrags}_${ext}`;
    }

    normalizedFileName = splitFrags.join('.');

    // 这里会对 fileName 进行统一处理
    if (this.headers.key.includes('${filename}')) {
      return this.headers.key.replace(
        '${filename}',
        `${prefix}${normalizedFileName}`,
      );
    }

    return `${this.headers.key}/${normalizedFileName}`;
  }

  constructor(data: Partial<FileStoreAuth> = {}) {
    super(data);

    Object.assign(this, data);

    // 针对 headers 进行转化
    Object.keys(this.headers || {}).forEach((k: string) => {
      this.headers[k] = isValidArray(this.headers[k])
        ? this.headers[k][0]
        : this.headers[k];
    });

    // tslint:disable-next-line: no-string-literal 这里服务端返回的是 store 对象
    this.fileStore = new FileStore(data['store'] || this.fileStore || {});

    if (!this.fileStore.type) {
      this.fileStore.type =
        (this.url || '').indexOf('aliyuncs') > -1 ? 'ALI_OSS' : 'UFC';
    }

    if (!this.fileStore.id) {
      this.fileStore.id = this.storeId;
    }
  }
}

export interface GlobalFileStoreMap {
  // 图片存储的 Store，全局会保证至少存在一个
  imageStore: FileStore;

  // 模型的 Store，全局会保证至少存在一个；一般来说，该 Store 指的是 OSS Store
  modelStore: FileStore;
  // 专指与当前可访问网关相对应的 FileStore
  modelGatewayStore?: FileStore;

  // 零件的 Store，全局会保证至少存在一个；一般来说，该 Store 指的是 OSS Store
  partStore: FileStore;
  // 专指与当前可访问网关相对应的 FileStore
  partGatewayStore?: FileStore;
}

export function hasLocalFileStore(fileStores: FileStore[]) {
  const localFileStores = fileStores.filter(f => f.isLan);

  return isValidArray(localFileStores);
}
