import { UfcShopMaterialQueryApi } from '../biz/material/UfcShopMaterialQueryApi';
import { UfcQualityInspectionApi } from '../biz/order/UfcQualityInspectionApi';
import { UfcShopOrderQueryApi } from '../biz/order/UfcShopOrderQueryApi';
import { UfcShopOrderUpdateApi } from '../biz/order/UfcShopOrderUpdateApi';
import { UfcPaymentApi } from '../biz/payment/UfcPaymentApi';
import { UserApi } from '../biz/tenant/UserApi';
import { UfcShopAuthApi } from '../common/UfcShopAuthApi';
import { BaseAxiosApi, UfcShopApiConfig } from '../shared';
import { UmiRequestCompatibleApi } from './UmiRequestCompatibleApi';

/** 集成最为全面的 API 复合 */
export class UfcShopComposedApi extends BaseAxiosApi {
  umiRequestCompatibleApi: UmiRequestCompatibleApi;

  userApi: UserApi;

  shopOrderQueryApi: UfcShopOrderQueryApi;
  shopOrderUpdateApi: UfcShopOrderUpdateApi;
  shopMaterialQueryApi: UfcShopMaterialQueryApi;
  ufcPaymentApi: UfcPaymentApi;
  qualityInspectionApi: UfcQualityInspectionApi;

  ufcShopAuthApi: UfcShopAuthApi;

  constructor(apiConfig: UfcShopApiConfig = new UfcShopApiConfig()) {
    super(apiConfig);

    this.umiRequestCompatibleApi = new UmiRequestCompatibleApi(apiConfig);

    this.userApi = new UserApi(apiConfig);

    this.ufcPaymentApi = new UfcPaymentApi(apiConfig);
    this.shopOrderQueryApi = new UfcShopOrderQueryApi(apiConfig);
    this.shopOrderUpdateApi = new UfcShopOrderUpdateApi(apiConfig);
    this.shopMaterialQueryApi = new UfcShopMaterialQueryApi(apiConfig);
    this.qualityInspectionApi = new UfcQualityInspectionApi(apiConfig);
    this.ufcShopAuthApi = new UfcShopAuthApi(apiConfig);
  }
}
