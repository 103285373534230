import * as S from '../../utils';
import { InquiryMaterialSku } from '../material';
import { InquiryOrder } from './InquiryOrder';

/* 海外订单信息类型 */
export interface PaymentInfo {
  amount: { amount: string; currency: string };
  channel: string;
  closeRemark: null;
  completedAt: string;
  createdAt: string;
  id: string;
  orderId: string;
  oceanPay: any;
  payPal: PayPalOrder;
  payerId: string;
  status: string;
  tenantId: string;
}

export interface PayPalOrder {
  order: {
    id: string;
    links: string[];
    payer: {
      address: {
        country_code: string;
      };
      email_address: string;
      name: {
        given_name: string;
        surname: string;
      };
      payer_id: string;
    };
    purchase_units: any;
    status: string;
  };
}

export function getLeadTimeByInquiryOrder(spuList: InquiryMaterialSku[] = []) {
  /** 最快发货日期 */
  let maxLeadTime = S.get(spuList, list => list[0].leadTime.amount) || 0;
  let minLeadTime = S.get(spuList, list => list[0].leadTime.amount) || 0;
  spuList
    .map(spu => spu.leadTime.amount)
    .forEach(a => {
      if (maxLeadTime < a) {
        maxLeadTime = a;
      }
      if (minLeadTime > a) {
        minLeadTime = a;
      }
    });
  return maxLeadTime;
}

// export type PayPalOrderBillingAddress = {};
