import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { useAsyncEffect } from 'ahooks';
import {
  Button,
  Divider,
  Empty,
  Input,
  Modal,
  Radio,
  Space,
  Tabs,
  Typography,
} from 'antd';
import cn from 'classnames';
import { CSSProperties, useEffect, useState } from 'react';

import { useInquiryHandleMethod } from '@/stores';

import { ImagesCarousel } from '../../../../../../commons/components/Element/ImagesCarousel';
import styles from './index.module.less';

interface QuoteFinishSelectModalProps {
  visible?: boolean;
  toggleVisible: () => void;

  materialTypeId: string;
  value?: {
    method: string[];
    desc: string; // 除喷漆外的描述字段
    desc1?: string; // 喷漆描述字段
  };
  onChange?: (p: { finishMethod: string; color: S.HandleMethodColor }) => void;
}

// 订单条目后处理方式选择
export const QuoteFinishSelectModal = ({
  visible,
  toggleVisible,
  materialTypeId,
  value,
  onChange,
}: QuoteFinishSelectModalProps) => {
  const [activeFinish, setActiveFinish] = useState<string>();
  const [colorType, toggleColorType] = useState<'CONFIG' | 'CUSTOM'>('CONFIG');
  const [selectedColor, selectColor] = useState<S.HandleMethodColor>({
    name: '',
    color: '',
  });
  const [customColor, setCustomColor] = useState<string>();

  const {
    materialTypeIdToHandleMethods,
    handleMethodPrevMap,
    loadHandleMethodPrevImages,
    loadInquiryHandleMethods,
  } = useInquiryHandleMethod();

  const targetHandleMethods =
    materialTypeIdToHandleMethods[materialTypeId] || [];

  useAsyncEffect(async () => {
    /** 加载后处理数据 */
    if (!S.isValid(materialTypeIdToHandleMethods)) {
      await loadInquiryHandleMethods();
    }

    const color = S.get(value, v => v.desc, '');
    const handleMethodName = S.get(value, v => v.method[0], '');

    if (S.isValidArray(targetHandleMethods) && visible) {
      const targetHandleMethod = targetHandleMethods.find(
        t => t.name === handleMethodName,
      );

      if (!!targetHandleMethod) {
        selectColor({ name: color, color });
        setActiveFinish(targetHandleMethod.id);
        targetHandleMethod.id &&
          loadHandleMethodPrevImages(targetHandleMethod.id);
      } else {
        setActiveFinish(targetHandleMethods[0].id);
        targetHandleMethods[0].id &&
          loadHandleMethodPrevImages(targetHandleMethods[0].id);
      }
    }
  }, [visible, materialTypeIdToHandleMethods]);

  useEffect(() => {
    if (colorType === 'CUSTOM') {
      selectColor({ name: '', color: '' });
    }
  }, [colorType]);

  const onFinishChange = async (finishId: string) => {
    /** 切换后处理清空选择颜色 */
    setCustomColor('');
    selectColor({ name: '', color: '' });

    setActiveFinish(finishId);

    loadHandleMethodPrevImages(finishId);
  };

  const onSubmit = () => {
    let color: S.HandleMethodColor = { name: '', color: '' };

    /** 优先使用 customColor */
    if (!!customColor && customColor.length > 0) {
      color = { name: customColor, color: customColor };
    } else {
      color = selectedColor;
    }

    const targetFinish = targetHandleMethods.find(h => h.id === activeFinish);

    if (onChange) {
      onChange && onChange({ finishMethod: targetFinish?.name || '', color });

      toggleVisible();
    }
  };

  const renderPrevImages = () => {
    if (!activeFinish) {
      return;
    }

    const urls = handleMethodPrevMap[activeFinish] || [];

    if (!S.isValidArray(urls)) {
      return <Empty className={styles.empty} />;
    }

    return <ImagesCarousel className={styles.prevImgs} urls={urls} />;
  };

  const colorSelect = () => {
    if (!activeFinish) {
      return;
    }

    const targetFinish = targetHandleMethods.find(h => h.id === activeFinish);

    return (
      <div className={styles.colorSelect}>
        <div className={styles.colorSelectItem}>
          <div className={styles.spaceBetween}>
            <Radio
              checked={colorType === 'CONFIG'}
              onClick={() => toggleColorType('CONFIG')}
            >
              <span className={styles.radioText}>Common Colors</span>
            </Radio>
            <Typography.Text ellipsis className={styles.colorStr}>
              {selectedColor.name}
            </Typography.Text>
          </div>

          <div className={styles.colorBlockWrapper}>
            {(targetFinish?.commonColors || []).map((c, i) => {
              const style: CSSProperties = {
                backgroundColor: c.color,
                cursor: colorType === 'CONFIG' ? 'pointer' : 'not-allowed',
              };
              const isSelected =
                selectedColor.color === c.color ||
                selectedColor.name === c.name;
              const className = cn(styles.colorBlock, {
                [styles.selectedBlock]: isSelected,
              });

              const onClick = () => {
                if (colorType === 'CUSTOM') return;
                selectColor(isSelected ? { name: '', color: '' } : c);
              };
              return (
                <span
                  key={i}
                  style={style}
                  className={className}
                  onClick={onClick}
                />
              );
            })}
          </div>
        </div>
        <Divider style={{ margin: '1rem 0' }} />
        <div className={styles.colorSelectItem}>
          <Radio
            checked={colorType === 'CUSTOM'}
            onClick={() => toggleColorType('CUSTOM')}
          >
            <span className={styles.radioText}>Custom Colors</span>
          </Radio>

          <div className={styles.customColor}>
            <span>{i18nFormat('Pantone Color')}</span>
            <Input
              style={{ width: 180 }}
              value={customColor}
              placeholder="Please Input"
              disabled={colorType !== 'CUSTOM'}
              onChange={e => setCustomColor(e.target.value)}
            />
          </div>
        </div>
        <Divider style={{ margin: '1rem 0' }} />
        <div className={cn(styles.colorSelectItem, styles.introduction)}>
          <div className={styles.spaceBetween}>
            <span className={styles.title}>Introduction</span>
            <Button
              type="link"
              disabled={!targetFinish?.jumpLink}
              href={targetFinish?.jumpLink}
              target="_blank"
            >
              {i18nFormat('Learn More')} &gt;&gt;
            </Button>
          </div>
          <Typography.Paragraph
            ellipsis={{ rows: 6, expandable: true, symbol: 'more' }}
            className={styles.desc}
          >
            {targetFinish?.desc}
          </Typography.Paragraph>
        </div>
      </div>
    );
  };

  const renderModalFooer = () => {
    return (
      <div className={styles.spaceBetween}>
        <p style={{ fontWeight: 'bold', color: '#f45b6c', margin: '0' }}>
          {i18nFormat(
            'For more accurate quotation and production, please upload technical drawings.',
          )}
        </p>

        <Space>
          <Button onClick={() => toggleVisible()}>
            {i18nFormat('Cancel')}
          </Button>
          <Button type="primary" onClick={onSubmit}>
            {i18nFormat('Submit')}
          </Button>
        </Space>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <Modal
        width={1000}
        open={visible}
        title="Finishing"
        footer={renderModalFooer()}
        onCancel={() => toggleVisible()}
        className={styles.quoteFinishSelectModal}
      >
        {S.isValidArray(targetHandleMethods) ? (
          <div className={styles.finishModalContent}>
            <Tabs
              tabPosition="left"
              activeKey={activeFinish}
              style={{ height: '100%' }}
              onChange={onFinishChange}
              className={styles.handleMethodsFilter}
            >
              {targetHandleMethods.map(m => (
                <Tabs.TabPane key={m.id} tab={m.name} />
              ))}
            </Tabs>

            {renderPrevImages()}
            {colorSelect()}
          </div>
        ) : (
          <Empty style={{ backgroundColor: '#fff' }} />
        )}
      </Modal>
    </div>
  );
};
