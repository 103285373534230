import { CaretDownOutlined } from '@ant-design/icons';
import * as S from '@unionfab/ufc-shop-commons';
import {
  getLocale,
  i18nDateTimeFormat,
  i18nFormat,
} from '@unionfab/ufc-shop-commons';
import cn from 'classnames';
import { CSSProperties } from 'react';

import { getUseShopQuoteStoreByCode } from '@/stores';

import styles from './index.module.less';
import { ShopQuoteMinOrderFeeTips } from './ShopQuoteMinOrderFeeTips';
// import { ShopQuoteSubtotalCurrencyTips } from './ShopQuoteSubtotalCurrencyTips';

export interface ShopOrdertQuotationInfoProps {
  className?: string;
  style?: CSSProperties;

  orderCode: string;
}

export const ShopOrderQuotationInfo = ({
  className,
  style,

  orderCode,
}: ShopOrdertQuotationInfoProps) => {
  const { shopOrder, shopOrderQuotation } = getUseShopQuoteStoreByCode(
    orderCode,
  )(s => ({
    shopOrder: s.shopOrder,
    shopOrderQuotation: s.shopOrderQuotation,
  }));

  const { production } = shopOrderQuotation;

  const isRfq = !!production && production.status !== 'OK';

  const minProductionFee = S.get(production, o => o.minProductionFee.amount);
  const minOrderFeeCurrency = S.get(
    production,
    o => o.minProductionFee.currency,
  );

  return (
    <div
      style={style}
      className={cn(styles.shopOrdertQuotationInfo, className)}
    >
      <h3 className={styles.title}>{i18nFormat('Order Summary')}</h3>
      <div className={styles.quoteInfoItem}>
        <div className={styles.flexStart}>
          <span className={styles.subTitle}>
            {i18nFormat('Subtotal')}:&nbsp;
            <CaretDownOutlined />
          </span>
        </div>
        <div className={styles.priceInfo}>
          <span>{S.get(production, q => q.shopOrderSubtotalPrice)}</span>
        </div>
      </div>
      <div className={styles.subQuoteInfoItem}>
        <div className={styles.flexStart}>
          <span className={styles.subTitle}>
            {i18nFormat('Production Fee')}:
          </span>
        </div>
        <div className={styles.priceInfo}>
          {S.get(production, q => q.shopOrderProductionPrice)}
        </div>
      </div>
      <div className={styles.subQuoteInfoItem}>
        <div className={styles.flexStart}>
          <span className={styles.subTitle}>
            {i18nFormat('Minimum Order Fee')}:&nbsp;
            <ShopQuoteMinOrderFeeTips />
          </span>
        </div>
        <div className={styles.priceInfo}>
          {isRfq
            ? '-'
            : S.toPriceStringWithOption(minProductionFee, {
                currency: minOrderFeeCurrency,
                rfq: isRfq,
              })}
        </div>
      </div>
      <div className={styles.quoteInfoItem}>
        <span className={styles.subTitle}>{i18nFormat('Shipment Date')}:</span>
        <div className={styles.priceInfo}>
          {i18nDateTimeFormat(S.getShopOrderShipmentDate(shopOrder))}
        </div>
      </div>
    </div>
  );
};

ShopOrderQuotationInfo.displayName = 'ShopOrderQuotationInfo';
