import { BaseEntity, Id } from '../type';
import { FileStore } from './FileStore';

export interface StoreInfo {
  storeId: Id;
  acl: string;
  storeKey: string;
  attributeKey: string;
}

export interface FilePreSignedUrl {
  url: string;
  expiresAt: string;
  name?: string;
}

export type FilePreSignedUrlMap = Record<Id, FilePreSignedUrl>;

// 文件
export class File<T = any> extends BaseEntity<Partial<File>> {
  name: string;
  size: number;
  // 如果是图片文件，则必然会存在 Url；其他的文件需要手动获取
  url = '';
  md5: string;

  stores: StoreInfo[];
  fileStoresVO: FileStore[];

  createUserId: Id;
  createPersonId: Id;

  // 文件属性
  attr: Partial<T>;

  constructor(props?: Partial<File>) {
    super(props);

    Object.assign(this, props);
  }
}

export type FileId = string;
