import { i18nFormat, isMobile } from '@unionfab/ufc-shop-commons';
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom';

import { OrderDetail } from './OrderDetails/OrderDetails';
import { OrderHistoryList } from './OrderHistoryList';
import { QuoteHistory } from './QuoteList';

const OrderHistoryContainer = () => <Outlet />;

export const OrderHistory = () => {
  const navigate = useNavigate();

  // 判断是否为 isMobile
  if (isMobile()) {
    navigate('/m/order_history');
  }

  return (
    <Routes>
      <Route path="/" element={<OrderHistoryContainer />}>
        <Route path="/quotes" element={<QuoteHistory />} />
        <Route
          path="/quote/:orderCode"
          element={<OrderDetail title={i18nFormat('Quote Details')} />}
        />
        <Route
          path="/:orderCode"
          element={<OrderDetail title={i18nFormat('Order Details')} />}
        />
        <Route path="/" element={<OrderHistoryList />} />
      </Route>
    </Routes>
  );
};
