import * as S from '@unionfab/ufc-shop-commons';
import {
  getBuiltinTechCategoryList,
  getTechnologySupportedFiles,
  isFileFitTechCategory,
} from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Checkbox, Divider } from 'antd';

import { PanelAlert } from '@/commons/components/Element/AlertPanel';
import { useInquiryQuoteEditorStore } from '@/stores';

import styles from './index.module.less';
import { OrderItemEditBtn } from './OrderItemEditBtn';
import { OrderItemSummary } from './OrderItemSummary';
import { QuoteOrderItemDetails } from './QuoteOrderItemDetails';

export interface OrderItemCardProps {
  index: number;
  orderItem: S.InquiryOrderItem;
  size?: 'mobile' | 'desktop';
}

export const QuoteOrderItemCard = ({ orderItem, size }: OrderItemCardProps) => {
  const {
    modelFileIdsToEdit,
    setModelFileIdsToEdit,
    estPrice,
    curTechCategoryName,
    technology,
  } = useInquiryQuoteEditorStore();

  const isCNC = technology.type === 'CNC';
  const curTechnology = getBuiltinTechCategoryList().find(
    techCategory => techCategory.name === curTechCategoryName,
  );
  const curModel = orderItem.modelFiles[0];

  const itemPrice = S.get(estPrice, p =>
    p.itemQuotations.find(v => v.id === orderItem.id),
  );
  const itemRfq =
    itemPrice?.requiresManualQuote == null
      ? true
      : itemPrice.requiresManualQuote;
  const fileNameOrType = S.get(curModel, m => m.attr.name || m.attr.type);

  let alertMsg: { message: string; reason: string } | undefined = undefined;

  if (itemRfq) {
    alertMsg = {
      message: 'RFQ(Special parts/Materials/Finish may need manual quotation)',
      reason: 'Failed to process the file',
    };
  }

  if (!isFileFitTechCategory(curTechnology, fileNameOrType as string)) {
    alertMsg = {
      message: i18nFormat(
        `This files are not suitable for ${
          !isCNC ? '3D Printing' : 'CNC machining'
        }. Please re-upload your part in one of the following formats: ${getTechnologySupportedFiles(
          curTechnology,
        )}.`,
      ),
      reason: 'The file type is not supported',
    };
  }

  const onItemChecked = (checked: boolean) => {
    if (checked) {
      setModelFileIdsToEdit([...(modelFileIdsToEdit as S.Id[]), curModel.id!]);
    } else {
      setModelFileIdsToEdit(
        modelFileIdsToEdit.filter(id => id !== curModel.id),
      );
    }
  };

  return (
    <div className={styles.orderItemCardContainer}>
      <div className={styles.orderItemCard}>
        <Checkbox
          checked={modelFileIdsToEdit.includes(curModel.id!)}
          onChange={e => onItemChecked(e.target.checked)}
        />
        <div className={styles.orderItemContent}>
          <OrderItemSummary orderItem={orderItem} size={size} />
          <Divider style={{ margin: '18px 0' }} />
          <div className={styles.title}>
            <span>{i18nFormat('Information')}</span>
            <OrderItemEditBtn orderItem={orderItem} />
          </div>
          <QuoteOrderItemDetails orderItem={orderItem} />
        </div>
      </div>
      {!!alertMsg && <PanelAlert isShow={true} message={alertMsg.message} />}
    </div>
  );
};
