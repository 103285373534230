import * as S from '@unionfab/ufc-shop-commons';
import { Checkbox, Space } from 'antd';

import { ColorSelect } from '@/commons/components/Element';
import { useInquiryQuoteEditorStore } from '@/stores';
import { useInquiryMaterialStore } from '@/stores';

import { OrderItemMaterialSpuSelect } from '../../InquiryQuoteItemMaterialSpuSelect';

export const OrderItemProcess = ({
  orderItem,
}: {
  orderItem: S.InquiryOrderItem;
}) => {
  const { materials: inquiryMaterials } = useInquiryMaterialStore();
  const curMaterial = S.get(inquiryMaterials, () =>
    inquiryMaterials.find(m => orderItem.materialSpu.materialId === m.id),
  );
  return <span>{S.get(curMaterial, cm => cm.materialType.name)}</span>;
};

export const OrderItemColor = ({
  orderItem,
}: {
  orderItem: S.InquiryOrderItem;
}) => {
  const { materials: inquiryMaterials } = useInquiryMaterialStore();
  const curMaterial = S.get(inquiryMaterials, () =>
    inquiryMaterials.find(m => orderItem.materialSpu.materialId === m.id),
  );
  return (
    <span style={{ display: 'flex', flexDirection: 'row' }}>
      <ColorSelect value={curMaterial && S.get(curMaterial, cm => cm.color)} />
      (Nature)
    </span>
  );
};

export const OrderItemMaterialFilterGroup = ({
  orderItem,
}: {
  orderItem: S.InquiryOrderItem;
}) => {
  const {
    orderItemMaterialGroupMap,
    toggleOrderItemMaterialGroupMap,
  } = useInquiryQuoteEditorStore();

  const id = orderItem.id;

  if (!id) {
    return <></>;
  }

  const groups = ['Metals', 'Resins', 'Plastics', 'Others'];

  const targetGroup = S.get(orderItemMaterialGroupMap, o => o[id], '');

  return (
    <Space style={{ flexWrap: 'wrap' }} onClick={e => e.stopPropagation()}>
      {groups.map(g => (
        <Checkbox
          key={g}
          checked={g === targetGroup}
          onClick={() => toggleOrderItemMaterialGroupMap(id, g)}
        >
          {g}
        </Checkbox>
      ))}
    </Space>
  );
};

export const OrderItemMaterialSpu = ({
  orderItem,
}: {
  orderItem: S.InquiryOrderItem;
}) => {
  const { editingFileIds } = useInquiryQuoteEditorStore();
  const { materials: inquiryMaterials } = useInquiryMaterialStore();
  const curMaterial = S.get(inquiryMaterials, () =>
    inquiryMaterials.find(m => orderItem.materialSpu.materialId === m.id),
  );
  const editing = !!editingFileIds.find(
    v => v === orderItem.printFiles[0].fileId,
  );

  if (editing) {
    return <OrderItemMaterialSpuSelect orderItem={orderItem} />;
  } else {
    const matName = S.get(curMaterial, cm => cm.name);
    return <span>{matName}</span>;
  }
};
