import { BaseEntity, Id } from '../../../common';
import { ReceiveAddress } from '../../../crm/customer/ReceiveAddress';

export class ShopOrderDeliveryItemRequestParams extends BaseEntity<Id> {
  /** 收货人 */
  recipient: string;

  /** 联系方式 */
  contactInfo: string;

  country: string;

  province: string;

  city: string;

  district: string;

  street: string;

  detail: string;

  postcode: string;

  companyName: string;

  isEqual(p: ShopOrderDeliveryItemRequestParams) {
    return (
      (this.recipient || '') === (p.recipient || '') &&
      (this.contactInfo || '') === (p.contactInfo || '') &&
      (this.country || '') === (p.country || '') &&
      (this.province || '') === (p.province || '') &&
      (this.city || '') === (p.city || '') &&
      (this.district || '') === (p.district || '') &&
      (this.street || '') === (p.street || '') &&
      (this.detail || '') === (p.detail || '') &&
      (this.postcode || '') === (p.postcode || '') &&
      (this.companyName || '') === (p.companyName || '')
    );
  }

  static fromReceiveAddress(receiveAddress: ReceiveAddress) {
    return new ShopOrderDeliveryItem({
      // 显式赋值，方便对比
      recipient: receiveAddress.recipient || '',
      country: receiveAddress.country || '',
      province: receiveAddress.province || '',
      city: receiveAddress.city || '',
      district: receiveAddress.district || '',
      street: receiveAddress.street || '',
      detail: receiveAddress.detail || '',
      postcode: receiveAddress.postcode || '',
      companyName: receiveAddress.companyName || '',
      contactInfo:
        receiveAddress.contactInfo || receiveAddress.phoneNumber || '',
    });
  }

  constructor(data: Partial<ShopOrderDeliveryItemRequestParams> = {}) {
    super(data);

    BaseEntity.assignInConstructor(this, data);
  }
}

export class ShopOrderDeliveryItem extends ShopOrderDeliveryItemRequestParams {
  id: Id;

  parentOrderDeliverAddressId: Id;

  deliveryType: 'EXPRESS_DELIVER' | 'SCENE_DELIVER';

  shippingDays: number;

  senderPhoneNumber: string;

  receiveTime: string;

  receiverName: string;

  receiverPhoneNumber: string;

  constructor(data: Partial<ShopOrderDeliveryItem> = {}) {
    super(data);

    BaseEntity.assignInConstructor(this, data);
  }
}

interface DeliveryLogisticsItem {
  expressId: string;
  /** 物流公司 */
  expressName: string;
  /** 物流单号 */
  expressNumber: string;
  /** 发货时间 */
  shippedAt: string;
}

export class ShopOrderDelivery extends BaseEntity<Id> {
  capabilities: {
    /** 预估发货日期 */
    estimatedShipmentAt: string;
    /** 预估运输天数 */
    estimatedTransitDays: number;
  } = {
    estimatedShipmentAt: null,
    estimatedTransitDays: null,
  };

  logistics: {
    /** 条目物流相关信息 */
    items: DeliveryLogisticsItem[];
  } = {
    items: [],
  };

  items: (ShopOrderDeliveryItemRequestParams | ShopOrderDeliveryItem)[];

  constructor(data: Partial<ShopOrderDelivery> = {}) {
    super(data);

    BaseEntity.assignInConstructor(this, data);

    this.items = (this.items || []).map(i => new ShopOrderDeliveryItem(i));
  }
}
