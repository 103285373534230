import {
  getFilesByIds,
  getModelDetails,
  getNutCountByOrderItem,
  getPunchCountByOrderItem,
  i18nFormat,
  InquiryOrderEstPrice,
} from '@unionfab/ufc-shop-commons';
import * as S from '@unionfab/ufc-shop-commons';
import { D3ModelAvatar } from '@unionfab/ufc-shop-ui-commons';
import { Descriptions, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { includes } from 'lodash-es';
import * as React from 'react';
import { useQuery } from 'react-query';

import { RemarkUploader } from '@/features/new_quote/components/uploader/RemarkUploader';

import styles from './CheckoutOrderItemTable.module.less';

const productCellRender = (file: S.InquiryPrintFile) => {
  const part = file.modelFile!;
  const curLengthUnit = file.lengthUnit;
  const unitStr = S.getLengthUnitDesc(curLengthUnit);

  const { sizeXStr, sizeYStr, sizeZStr } = getModelDetails(part);
  const dimensionStr = `${i18nFormat(
    'Dimension',
  )}: ${sizeXStr}*${sizeYStr}*${sizeZStr} ${unitStr}`;

  return (
    <div className={styles.productCell}>
      <div className={styles.productCellAvatar}>
        <D3ModelAvatar
          key={S.get(part, m => m.id)}
          size={80}
          unit={curLengthUnit}
          model={part}
        />
        <div className={styles.productCellTitle}>{part.name}</div>
      </div>

      <div className={styles.productDimension}>
        {
          <Typography.Paragraph ellipsis={{ rows: 1, tooltip: true }}>
            {dimensionStr}
          </Typography.Paragraph>
        }
      </div>
    </div>
  );
};

const renderColorBlock = (color: string) => {
  return (
    <div className={styles.colorBlockCell} style={{ background: color }} />
  );
};

const skuCellRender = (orderItem: S.InquiryOrderItem) => {
  const materialSpu = orderItem.materialSpu;
  const categoryName =
    S.get(materialSpu, (m: any) => m.material.materialType.categoryName) ||
    S.get(
      materialSpu,
      (m: any) => m.materialSnapshot.materialType.categoryName,
    ) ||
    '';

  const processName =
    S.get(materialSpu, (m: any) => m.material.materialType.name) ||
    S.get(materialSpu, (m: any) => m.materialSnapshot.materialType.name) ||
    '';
  const isCNC = includes(categoryName, 'CNC');

  const holeTitle = !isCNC
    ? i18nFormat('Inserts')
    : i18nFormat('Threads and Tapped Holes');
  const nutCount = getNutCountByOrderItem(orderItem) || 0;
  const punchCount = getPunchCountByOrderItem(orderItem) || 0;
  const printFile = S.get(orderItem, oi => oi.printFiles[0]);
  const color =
    S.get(orderItem, f => f.materialSpu.color) ||
    S.get(orderItem, f => f.materialSpu.materialSnapshot.color) ||
    '#fff';
  const colorDesc = S.get(printFile, f => f.handle.desc, '');
  const materialName = `${processName}-${S.get(
    orderItem,
    f => f.materialSpu.materialNameStr,
  )}`;

  return (
    <div className={styles.skuCell}>
      <Descriptions title="" column={1}>
        <Descriptions.Item label={i18nFormat('Material')}>
          {materialName}
        </Descriptions.Item>
        <Descriptions.Item label={i18nFormat('Color')}>
          <div className={styles.colorCellWrapper}>
            {renderColorBlock(color)}{' '}
            <span style={{ marginLeft: '10px' }}>{color}</span>
          </div>
        </Descriptions.Item>
        <Descriptions.Item label={i18nFormat('Finish')}>
          {S.get(printFile, f => f.handle.method[0]) || 'Standard'}
          {colorDesc.length > 0 && `-${colorDesc}`}
        </Descriptions.Item>
        <Descriptions.Item label={holeTitle}>
          {!isCNC ? nutCount : punchCount}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

const renderSpecialRequest = (orderItem: S.InquiryOrderItem) => {
  return (
    <div className={styles.noteCell}>
      {orderItem.remark ? (
        <Typography.Paragraph ellipsis={{ rows: 2, tooltip: true }}>
          {orderItem.remark}
        </Typography.Paragraph>
      ) : (
        '-'
      )}
    </div>
  );
};

const renderTechnicalDrawing = (remarkFileList: any[]) => {
  const notEmpty = remarkFileList.length > 0;
  return (
    notEmpty && (
      <div className={styles.drawingCell}>
        <RemarkUploader
          type="OTHER"
          defaultFileList={remarkFileList}
          showUploadList={true}
        />
      </div>
    )
  );
};

interface ColumnDataType {
  key: string;
  product: S.InquiryPrintFile;
  sku: S.InquiryOrderItem;
  specialRequest: S.InquiryOrderItem;
  remarkFiles: any;
  price?: number;
  quantity: number;
  subtotal?: number;
}

const productTableHeadSource: ColumnsType<ColumnDataType> = [
  {
    title: i18nFormat('Product'),
    dataIndex: 'product',
    key: 'product',
    align: 'center',
    width: '400px',
    render: productCellRender,
  },
  {
    title: i18nFormat('SKU'),
    dataIndex: 'sku',
    key: 'sku',
    align: 'center',
    render: skuCellRender,
  },
  {
    title: i18nFormat('Notes'),
    dataIndex: 'specialRequest',
    key: 'specialRequest',
    render: renderSpecialRequest,
    width: 150,
  },
  {
    title: i18nFormat('Technical drawing'),
    width: 400,
    dataIndex: 'remarkFiles',
    key: 'remarkFiles',
    align: 'center',
    render: renderTechnicalDrawing,
  },
  {
    title: i18nFormat('Per'),
    dataIndex: 'price',
    key: 'price',
    align: 'center',
    render: (price: string) =>
      `${
        isNaN(price as any) || !price ? '-' : '$' + parseFloat(price).toFixed(2)
      }`,
  },
  {
    title: i18nFormat('Qty'),
    dataIndex: 'quantity',
    key: 'quantity',
    align: 'center',
  },
  {
    title: i18nFormat('Price'),
    dataIndex: 'subTotal',
    key: 'subTotal',
    align: 'center',
    render: (subTotal: string | null) =>
      `${
        isNaN(subTotal as any) || !subTotal
          ? '-'
          : '$' + parseFloat(subTotal).toFixed(2)
      }`,
  },
];

const getTableDataSource = (
  order: S.InquiryOrder,
  remarkFileList: any[],
  inquiryOrderEstPrice?: InquiryOrderEstPrice,
) => {
  const orderItems = order.items;
  const isWaitingPayment = order.status === 'WAIT_CUSTOM_CONFIRM';
  const isQuotating = order.status === 'WAIT_REVIEW' && order.isQuotating;
  const isConfirmed = S.InquiryOrderStatusList.indexOf(order.status) > 2;
  const isPriced = isQuotating || isWaitingPayment || isConfirmed;
  const filePrices = inquiryOrderEstPrice?.fileItemPrices;

  return orderItems.map((orderItem, idx) => {
    const printFile = S.get(
      orderItem,
      oi => oi.printFiles[0],
    )! as S.InquiryPrintFile;
    const file = new S.InquiryPrintFile({
      ...printFile,
      isForceUseStartingPriceVO: true,
    });
    const handleMethod = S.get(file, f => f.handle.method[0]);
    const isStandard = !handleMethod;
    let filePriceItem;

    if (!isPriced) {
      filePriceItem = filePrices ? filePrices![idx] : undefined;
    }

    return {
      key: file.fileId,
      product: file,
      sku: orderItem,
      specialRequest: orderItem,
      remarkFiles: remarkFileList[idx],
      price: isPriced
        ? file.price
        : isStandard
        ? filePriceItem?.perPrice
        : null,
      quantity: file.printCount,
      subTotal: isPriced
        ? file.finalPrice
        : isStandard
        ? filePriceItem?.price
        : null,
    } as ColumnDataType;
  });
};

export const CheckoutOrderItemTable = ({
  order,
  inquiryOrderEstPrice,
}: {
  order: S.InquiryOrder;
  inquiryOrderEstPrice?: InquiryOrderEstPrice;
}) => {
  const { data: remarkFilesOrigin, isSuccess: hasGotRemarkFiles } = useQuery(
    [`queryRemarkFiles`, order],
    () => {
      const fileList = order.items.map(orderItem => {
        const remarkFileIdList =
          S.get(orderItem, oi => oi.itemRemark.fileIds) ||
          S.get(orderItem, oi => oi.remarkFileIds) ||
          [];
        if (remarkFileIdList) {
          return getFilesByIds(remarkFileIdList);
        }
        return Promise.resolve([]);
      });
      return Promise.all(fileList);
    },
  );
  const remarkFileListCollection = React.useMemo(() => {
    return (remarkFilesOrigin || []).map((fileMap: any) => {
      return Object.keys(fileMap || {}).map(key => ({
        uid: fileMap[key].id,
        id: fileMap[key].id,
        status: 'done',
        url: fileMap[key].url,
        name: fileMap[key].name,
      }));
    });
  }, [remarkFilesOrigin]);

  const dataSource = React.useMemo(() => {
    return (
      getTableDataSource(
        order,
        remarkFileListCollection,
        inquiryOrderEstPrice,
      ) || []
    );
  }, [remarkFileListCollection, order, inquiryOrderEstPrice]);

  return (
    <>
      {hasGotRemarkFiles && (
        <div className={styles.container}>
          <Table
            pagination={false}
            bordered
            dataSource={dataSource}
            columns={productTableHeadSource}
          />
        </div>
      )}
    </>
  );
};
