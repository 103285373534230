import {
  BaseEntity,
  CurrencySymbolType,
  D3ModelFile,
  Id,
} from '../../../common';
import { InquiryOrderStatus } from '../../inquiry-order';
import { InquiryMaterial, InquiryMaterialSku } from '../../material';
import { ShopOrderDelivery } from '../delivery';
import { ShopOrderQuotation } from '../quotation';
import { ShopOrderItem } from './ShopOrderItem';
import { ShopOrderPrice } from './ShopOrderPrice';

export class ShopOrderExtra extends BaseEntity<ShopOrderExtra> {
  // 关联的材料数据
  materials?: Record<string, InquiryMaterial> = {};

  /** 关联的 Spu 数据 */
  skus?: Record<string, InquiryMaterialSku> = {};

  /** 关联的模型数据 */
  fileMap?: Record<string, D3ModelFile> = {};

  /** 关联的文件信息（备注文件等） */
  files?: Record<string, Record<'name' | 'size' | 'url', string>>;

  constructor(data: Partial<ShopOrderExtra>) {
    super(data);

    BaseEntity.assignInConstructor(this, data);

    for (const key of Object.keys(this.materials)) {
      this.materials[key] = new InquiryMaterial(this.materials[key]);
    }

    for (const key of Object.keys(this.skus)) {
      this.skus[key] = new InquiryMaterialSku(this.skus[key]);
    }

    for (const key of Object.keys(this.fileMap)) {
      this.fileMap[key] = new D3ModelFile(this.fileMap[key]);
    }
  }
}

/**
 * ShopOrder 本质上是 InquiryOrderV2
 * - 报价时候的状态变化
 *   - WAIT_SUBMIT (任意编辑)
 *   - WAIT_REVIEW (订单提交), 此时 canAutoQuote=true, 表示可以直接支付 (通过 auto_confirm 接口切换到 WAIT_CUSTOM_CONFIRM 进行支付) 或者等待客服人工报价提交订单到 WAIT_CUSTOM_CONFIRM
 *   - WAIT_CUSTOM_CONFIRM 状态另外有一个 isQuoting 字段，如果 true，不能支付（客服在重新修改报价中）
 */
export class ShopOrder extends BaseEntity<ShopOrder> {
  customerId: Id;

  code: string;

  status: InquiryOrderStatus;

  requirements: {
    quotation: { currency: CurrencySymbolType };
    customerSelections?: {
      delivery: { expressId?: string };
      remark?: { remark: string; fileIds: string[] };
    };
  } = {
    quotation: { currency: 'USD' },
    customerSelections: { delivery: {}, remark: { remark: '', fileIds: [] } },
  };

  delivery: ShopOrderDelivery;

  /** WAIT_CUSTOM_CONFIRM 状态下表示当前客服正在报价，不允许客户进行支付 */
  isQuoting: boolean;

  /** 这里是存储的订单的报价记录，实际报价过程中访问 quotation 接口； */
  quotation: ShopOrderQuotation;

  /** 下单后使用 price 字段 */
  price: ShopOrderPrice;

  /** 工艺 */
  technology?: 'CNC' | '3D_PRINTING';

  items: ShopOrderItem[] = [];

  cancelReason: {
    remark: string;
    cancelledAt: string;
    remarkFileIds: string[];
  };

  /**
   * 包括 InquiryOrder 所需要的额外的字段
   * extra 作为属性，是为了方便快速复制
   */
  extraVO: Partial<ShopOrderExtra>;

  constructor(data: Partial<ShopOrder> = {}) {
    super(data);

    BaseEntity.assignInConstructor(this, data);

    this.delivery = new ShopOrderDelivery(this.delivery);

    // extra 中会包含很多额外的信息
    this.extraVO = new ShopOrderExtra(this.extraVO);

    this.items = this.items.map(item => new ShopOrderItem(item, this.extraVO));

    if (this.quotation) {
      this.quotation = new ShopOrderQuotation(this.quotation);
    }

    if (this.price) {
      this.price = new ShopOrderPrice(this.price);
    }

    // 确保深层嵌套的 requirements 有值，这样在业务中可以直接嵌套赋值
    if (!this.requirements) {
      this.requirements = { quotation: { currency: 'USD' } };
    }

    if (!this.requirements.quotation) {
      this.requirements.quotation = { currency: 'USD' };
    }

    if (!this.requirements.customerSelections) {
      this.requirements.customerSelections = { delivery: {} };
    }

    if (!this.requirements.customerSelections.delivery) {
      this.requirements.customerSelections.delivery = {};
    }
  }
}
