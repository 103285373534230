import * as S from '@unionfab/ufc-shop-commons';

import { OrderItemModelPreview } from '../../QuoteOrderItemFields/OrderItemModelPreview';
import {
  OrderItemCount,
  OrderItemModelDimension,
  OrderItemPriceAndLeadTime,
  OrderItemTitle,
} from '../common';
import styles from './index.module.less';

export const OrderItemSummary = ({
  orderItem,
}: {
  orderItem: S.InquiryOrderItem;
}) => {
  const left = (
    <div className={styles.orderItemSummaryLeft}>
      <OrderItemModelPreview orderItem={orderItem} />
    </div>
  );

  const right = (
    <div className={styles.orderItemSummaryRight}>
      <div className={styles.orderItemSummaryContent}>
        <OrderItemModelDimension orderItem={orderItem} />
      </div>
      <div className={styles.orderItemFieldQuantity}>
        <OrderItemCount orderItem={orderItem} />
      </div>
      <OrderItemPriceAndLeadTime orderItem={orderItem} />
    </div>
  );

  return (
    <div>
      <OrderItemTitle orderItem={orderItem} />
      <div className={styles.orderItemSummary}>
        {left}
        {right}
      </div>
    </div>
  );
};
