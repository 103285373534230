import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Checkbox, Tooltip } from 'antd';

import Protection from '@/assets/svgs/protection.svg';
import { useAppNavigate } from '@/features/shared';
import { useInquiryQuoteCheckoutStore } from '@/stores';

import styles from './index.module.less';

const oceanpaymentProtection =
  'If you received the wrong goods or did not receive any goods, Oceanpayment can handle a full refund for you, including the shipping costs for eligible transactions.';

const paypalProtection =
  'If you received the wrong goods or did not receive any goods, Paypal can handle a full refund for you, including the shipping costs for eligible transactions.';

const UnionfabProtection =
  'If you received the wrong goods or did not receive any goods, Unionfab can handle a full refund for you, including the shipping costs for eligible transactions.';

export const OrderCheckoutTerms = () => {
  const {
    agreeTermOfService,
    setAgreeTermOfService,
  } = useInquiryQuoteCheckoutStore();
  const nav = useAppNavigate();

  return (
    <div className={styles.orderCheckoutTerms}>
      <div>
        <Checkbox
          checked={agreeTermOfService}
          onChange={e => setAgreeTermOfService(e.target.checked)}
        >
          {i18nFormat("I agree to Unionfab's")}{' '}
          <a
            onClick={() => nav.navigateToPdfPageNewTab('csa')}
            style={{ color: '#f89902' }}
          >
            {i18nFormat('Customer Service Agreement')}
          </a>
        </Checkbox>
      </div>
    </div>
  );
};

export const SafeTransactionSupport = () => {
  const { paymentChannel } = useInquiryQuoteCheckoutStore();

  return (
    <div className={styles.orderCheckoutTermsDetail}>
      <div>Safe Transactions With Support Of </div>
      <div>
        {paymentChannel === 'paypal' && (
          <>
            <Tooltip
              placement="bottom"
              arrowPointAtCenter={false}
              title={paypalProtection}
            >
              <a>
                <Protection />
                <span>PayPal Buyer Protection</span>
              </a>
            </Tooltip>
            |
          </>
        )}
        {paymentChannel === 'oceanpay' && (
          <>
            <Tooltip
              placement="bottom"
              arrowPointAtCenter={false}
              title={oceanpaymentProtection}
            >
              <a>
                <Protection />
                <span>Oceanpayment Buyer Protection</span>
              </a>
            </Tooltip>
            |
          </>
        )}
        <Tooltip
          placement="bottom"
          arrowPointAtCenter={false}
          title={UnionfabProtection}
        >
          <a>
            <Protection />
            <span>Unionfab Buyer Protection</span>
          </a>
        </Tooltip>
      </div>
    </div>
  );
};
