import {
  BaseEntity,
  CurrencySymbolType,
  FilePreSignedUrlMap,
  Id,
} from '../../common';
import { get, isValidArray } from '../../utils';
import { InquiryMaterialSku } from './InquiryMaterialSku';
import { InquiryMaterialTag, InquiryMaterialType } from './InquiryMaterialType';

export interface InquiryMaterialStartingPrices {
  currencyToStartingPrice: Record<CurrencySymbolType, number>;
}

export interface MaterialMarketing {
  /**材料特性 */
  features: string[];
  /** 材料预览图 */
  overview: string;
  /** 后处理效果图 */
  finishingPreview: Record<string, string[]>;
  /** 最小厚度 */
  minimumWallThickness: string;
}

/** 询价关联的材料 */
export class InquiryMaterial extends BaseEntity<InquiryMaterial> {
  name: string;
  color: string;
  /** 密度 */
  density: number;
  /** 耐热温度 */
  heatResistantCelsius: string;
  /** 韧性评分（1-5） */
  ductilityGrade: number;
  /** 强度评分（1-5） */
  strengthGrade: number;
  desc?: string;

  startingPrice?: number; // 材料的起步价
  startingPrices?: InquiryMaterialStartingPrices; // 不同币种的材料起步价

  imageId: Id;
  imageIds: Id[];
  attachmentIds?: Id[];
  attachmentUrls?: FilePreSignedUrlMap;
  imageUrls?: FilePreSignedUrlMap;
  imageUrlMap: FilePreSignedUrlMap;
  tags: InquiryMaterialTag[];
  tagIds: Id[];

  /** 材料类型 Id  */
  materialTypeId: Id;
  materialType: InquiryMaterialType;

  spuIds?: Id[];
  // 仅用于前端暂存 spu 信息
  spuList: InquiryMaterialSku[] = [];
  allSpuList: InquiryMaterialSku[] = []; // 全部的 Spu 列表

  // 材料组
  materialGroup?: string;

  // 材料标签
  marketLabels?: string[];

  maxBuildSize?: string;

  marketing?: MaterialMarketing;

  get hiddenSpuList() {
    const visibleSpuIds = new Set(this.spuList.map(s => s.id));

    return this.allSpuList.filter(s => !visibleSpuIds.has(s.id));
  }

  constructor(data: Partial<InquiryMaterial> = {}) {
    super(data);

    Object.assign(this, data);

    this.materialType = new InquiryMaterialType(this.materialType);

    if (!this.materialTypeId && this.materialType) {
      this.materialTypeId = get(this.materialType, t => t.id);
    }

    // 去除材料前后的空格以及 Tab
    if (this.name) {
      this.name = this.name.trim();
    }

    if (isValidArray(this.tags) && !isValidArray(this.tagIds)) {
      this.tagIds = this.tags.map(t => t.id);
    }
  }
}

/**
 * 将一组 Spu 映射为材料列表
 * @param spus Spu 列表
 * @param inquiryMaterials 全部的材料列表，用于补充那些不存在 Spu 的场景
 */
export function groupSpusByInquiryMaterial(
  spus: InquiryMaterialSku[] = [],
  inquiryMaterials: InquiryMaterial[] = [],
) {
  // 这里是将 Spu 转化为材料的映射
  const mMap: Partial<Record<Id, InquiryMaterial>> = spus.reduce<
    Partial<Record<Id, InquiryMaterial>>
  >((prev, curr) => {
    if (!curr) {
      return prev;
    }

    const materialFromInquiryMaterials = inquiryMaterials.find(
      m => m.id === curr.materialId,
    );

    const materialFromSpu = curr.materialSnapshot;

    // 优先从材料中取，然后再从 Snapshot 中获取，这里是为了获得到报价组的信息，取最新的
    const finalUsedMaterial = materialFromInquiryMaterials || materialFromSpu;

    if (!finalUsedMaterial) {
      console.error(
        '>>>InquiryMaterial>>>groupSpusByInquiryMaterial>>>',
        curr,
        '>>>inquiryMaterials>>>',
        inquiryMaterials,
      );

      return prev;
    }

    if (finalUsedMaterial) {
      if (!finalUsedMaterial.id) {
        finalUsedMaterial.id = curr.materialId || curr.inquiryMaterialId;
      }
    }

    // 补齐 materialFromSpu 中的数据
    if (finalUsedMaterial && materialFromInquiryMaterials) {
      if (
        !isValidArray(finalUsedMaterial.tags) ||
        !isValidArray(finalUsedMaterial.tagIds)
      ) {
        finalUsedMaterial.tags = materialFromInquiryMaterials.tags;
        finalUsedMaterial.tagIds = materialFromInquiryMaterials.tagIds;
      }

      if (!get(finalUsedMaterial, m => m.materialType.name)) {
        finalUsedMaterial.materialType =
          materialFromInquiryMaterials.materialType;
      }
    }

    curr.materialSnapshot = finalUsedMaterial;
    const materialId = finalUsedMaterial.id;

    if (!prev[materialId]) {
      // 为了避免循环引用，这里父 Material 重新构造
      prev[materialId] = new InquiryMaterial({
        ...finalUsedMaterial,
        spuList: [],
      });
    }

    // 不存在该属性则置空
    if (!prev[materialId].spuList) {
      prev[materialId].spuList = [];
    }

    // 存在是否有重复的，这里需要在多次运行的时候进行去重判断
    const spuIds = prev[materialId].spuList.map(s => s.id);
    if (!spuIds.includes(curr.id)) {
      prev[materialId].spuList.push(curr);
    }

    return prev;
  }, {});

  const richIds = Object.keys(mMap || {});
  const richIdSet = new Set(richIds);

  const richInquiryMaterials = richIds.map(k => {
    const material = mMap[k];

    // 这里根据时间对于 Spu 进行一个排序，然后每个时间点仅取一个值，注意，优先上架的
    material.spuList = material.spuList.sort(
      (s1: InquiryMaterialSku, s2: InquiryMaterialSku) => {
        if (s1.status !== s1.status) {
          return s1.status > s2.status ? 1 : -1;
        }

        if (
          get(s1, s1 => s1.leadTime.amount) !==
          get(s2, s2 => s2.leadTime.amount)
        ) {
          return (
            get(s1, s1 => s1.leadTime.amount) -
            get(s2, s2 => s2.leadTime.amount)
          );
        }

        return s1.updatedAt > s2.updatedAt // 如果时间一致，则根据更新时间进行排序
          ? -1
          : 1;
      },
    );

    // 进行去重处理
    const leadTimeMap: Record<number, InquiryMaterialSku> = {};

    // 保留全部的 Spu 列表
    material.allSpuList = [...material.spuList];

    // 筛选，仅保留材料的 spuList
    material.spuList.forEach((s: InquiryMaterialSku) => {
      const amount = get(s, s => s.leadTime.amount);

      // 优先保证 ONLINE 的 SPU
      if (leadTimeMap[amount] && s.status !== 'ONLINE') {
        return false;
      }

      leadTimeMap[amount] = s;

      return true;
    });

    material.spuList = Object.keys(leadTimeMap).map(k => leadTimeMap[k]);

    if (
      !material.spuIds ||
      (isValidArray(material.spuList) && !isValidArray(material.spuIds))
    ) {
      material.spuIds = (material.spuList || []).map(i => i.id);
    }

    return material;
  });

  // 遍历传入的全部的材料列表，补齐尚不包含的材料
  inquiryMaterials.forEach(m => {
    if (!richIdSet.has(m.id)) {
      m.spuList = [];
      richInquiryMaterials.push(m);
    }
  });

  return richInquiryMaterials;
}

export class InquiryQuotationCode extends BaseEntity<InquiryQuotationCode> {
  code: string;
  customerId: Id;
  spuId: Id;
  quotationGroupId: Id;

  constructor(props?: Partial<InquiryQuotationCode>) {
    super(props);

    Object.assign(this, props);

    if (typeof this.quotationGroupId === 'object') {
      this.quotationGroupId = (this.quotationGroupId as any).id;
    }
  }
}
