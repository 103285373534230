import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Space,
  Typography,
} from 'antd';
import cn from 'classnames';
import { CSSProperties, useState } from 'react';

import {
  getUseShopQuoteStoreByCode,
  ShopOrderQuoteStoreModifiers,
  useInquiryMaterialStore,
  useShopQuoteUiStore,
} from '../../../../../../../stores';
import styles from './index.module.less';

export interface ShopQuoteItemNotesProps {
  className?: string;
  style?: CSSProperties;

  orderCode: string;
  itemId: string;
}

export const ShopQuoteItemNotes = ({
  className,
  style,
  orderCode,
  itemId,
}: ShopQuoteItemNotesProps) => {
  const { item } = getUseShopQuoteStoreByCode(orderCode)(s => ({
    item: (s.shopOrderDraft.items || []).find(i => i.id === itemId),
  }));

  const onChange = (value: string) => {
    ShopOrderQuoteStoreModifiers.updateOrderDraft(orderCode, o => {
      o.items.find(i => i.id === itemId).remark.remark = value;
    });
  };

  return (
    <div className={cn(className, styles.container)} style={style}>
      <div className={styles.shopQuoteItemInsertsTitle}>
        <Typography.Title level={5}>{i18nFormat('Notes')}</Typography.Title>
      </div>
      <div className={styles.flexStart} style={{ width: '80%' }}>
        <Form.Item
          style={{ width: '100%' }}
          name="remark"
          initialValue={S.get(item, i => i.remark.remark)}
        >
          <Input.TextArea
            onChange={e => onChange(e.target.value)}
            value={S.get(item, i => i.remark.remark)}
            placeholder={i18nFormat(
              'Leave your note about specific requirement in here.',
            )}
          />
        </Form.Item>
      </div>
    </div>
  );
};

ShopQuoteItemNotes.displayName = 'ShopQuoteItemNotes';
