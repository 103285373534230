import { assignInConstructor } from '../../../schema';

/** 请求参数 */
export class CustomRequestConfig {
  url?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  params?: Record<string, string | number | object | boolean> | object;

  paramsSerializer?: (params: any) => string;

  constructor(data: Partial<CustomRequestConfig> = {}) {
    assignInConstructor(this, data);
  }
}

/** Post 请求参数 */
export class PostRequestConfig<D> extends CustomRequestConfig {
  data?: D;

  constructor(data: Partial<PostRequestConfig<D>> = {}) {
    super(data);

    assignInConstructor(this, data);
  }
}

export class ResponseError extends Error {
  /** 异常原因 */
  message: string;

  /** 异常的状态码 */
  statusCode: number;
  /** 异常的内部 code */
  code: string;
  /** 异常原因 */
  exception: string;
  reason: string;
  /** 编号 */
  exceptionId: string;

  constructor(data: Partial<ResponseError> = {}) {
    super();

    assignInConstructor(this, data);

    this.message =
      this.message || this.reason || this.exception || this.exceptionId || '-';
  }
}
