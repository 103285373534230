import {
  getAxiosAgent,
  getUfcShopComposedApi,
} from '../../../../singleton-ins';

export interface UserResponseError {
  code: string;
  reason: string;
  exception: string;
}

export interface UserResponse {
  token: string;
}

export interface LoginCredentials {
  username: string;
  password: string;
}

export interface RegisterCredentials {
  firstName: string;
  lastName: string;
  nickname?: string;
  email: string;
  password: string;
  phoneNumber: string;
  postCode?: string;
  blackFridayLandingPage?: boolean;
  origin?: string | null;
  referer?: string | null;
  /**
   * 当有邀请码的时候：
   * - customerData: { invitationCode: str }
   */
  customerData?: Record<string, string>;

  inviteCode?: string | null;
}

export interface EmailConfirmingCredentials {
  code: string;
}

export type ResponseUserStatus = 'ok' | 'error';

export interface AuthResponseUser {
  status: ResponseUserStatus;
  data: UserResponse;
  err: UserResponseError;
}

export interface SendRegisteredCodeResponse {
  status: ResponseUserStatus;
  err: UserResponseError;
}

export interface SendRecoveryCodeResponse {
  status: ResponseUserStatus;
  err: UserResponseError;
}

export interface ResetPasswordResponse {
  status: ResponseUserStatus;
  err: UserResponseError;
}

export interface GoogleAuthUser {
  mail: string;
  familyName: string;
  givenName: string;
  googleId: string;
  imageUrl: string;
  name: string;
}

export interface RecoveryEmailType {
  email: string;
}

export interface ResetPasswordType {
  email: string;
  password: string;
  verifyCode: string;
}

export const loginWithEmailAndPassword = (
  data: LoginCredentials,
): Promise<AuthResponseUser> => {
  return getAxiosAgent().post('/auth/sign_in', data);
};

export const appendSeoData = (data: any) => {
  const searchParams = new URLSearchParams(window.location.search);
  // 补齐数据
  const origin =
    searchParams.get('origin') ||
    window.localStorage.getItem('origin') ||
    window.localStorage.getItem('curOrigin');

  const referer =
    searchParams.get('referer') ||
    searchParams.get('referrer') ||
    window.localStorage.getItem('referer');

  const customerData: Record<string, string> = {};

  for (const key of [
    'companyName',
    'partsTotalVolume',
    'preferMaterials',
    'newProjectHappenTime',
  ]) {
    const v = localStorage.getItem('customerData_' + key);
    if (v) {
      customerData[key] = v;
    }
  }

  return {
    googleTrackData: getGoogleTrackData(),
    origin,
    referer,
    customerData,
    ...data,
  };
};

export function getGoogleTrackData() {
  const googleTrackData: Record<string, string> = {};

  for (const key of [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'campaign_name',
    'utm_term',
    'matchtype',
    'device',
    'placement',
  ]) {
    const v = localStorage.getItem(key);
    if (v) {
      googleTrackData[key] = v;
    }
  }

  return googleTrackData;
}

export const registerWithEmailAndPassword = (
  data: RegisterCredentials,
): Promise<AuthResponseUser> => {
  return getAxiosAgent().put('/auth/sign_up', appendSeoData(data));
};

export const confirmEmail = (
  data: EmailConfirmingCredentials,
): Promise<AuthResponseUser> => {
  return getAxiosAgent().put('/auth/sign_up_confirm', data);
};

export const sendRecoveryCode = (
  data: RecoveryEmailType,
): Promise<SendRecoveryCodeResponse> => {
  return getAxiosAgent().post('/auth/send/reset_code', data);
};
export const resetPasswordForOverseasCustomer = (data: ResetPasswordType) => {
  return getAxiosAgent().post('/auth/reset_password', data);
};

export const validateEmailOrPhoneNumberExists = async (data: {
  email?: string;
  phoneNumber?: string;
}): Promise<boolean> => {
  const res = await getAxiosAgent().get('/auth/registry_check', {
    params: data,
  });

  return res.data;
};
