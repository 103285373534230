import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Input, Typography } from 'antd';
import cn from 'classnames';

import { useInquiryQuoteEditorStore } from '@/stores';

export const OrderItemNotes = ({
  editing,
  orderItem,
  className,
}: {
  className?: string;
  editing: boolean;
  orderItem: S.InquiryOrderItem;
}) => {
  const { updateInquiryOrder, technology } = useInquiryQuoteEditorStore();

  const placeholder =
    technology && technology.type == 'CNC'
      ? 'Leave you note or specific requirement here (E.X. :Material grade 6061 T6. / I need check you Measurement report before sent.)'
      : 'Leave your note about specific requirements here';

  if (editing) {
    return (
      <Input.TextArea
        className={cn(className)}
        placeholder={i18nFormat(placeholder)}
        autoSize={{ minRows: 2, maxRows: 6 }}
        value={orderItem.remark}
        onChange={e =>
          updateInquiryOrder(
            'changeOrderItemRemark',
            () => (orderItem.remark = e.target.value),
          )
        }
      />
    );
  } else if (!!orderItem.remark) {
    return (
      <span className={cn(className)}>
        <Typography.Paragraph ellipsis={{ rows: 2 } as any}>
          {S.get(orderItem, oi => oi.remark)}
        </Typography.Paragraph>
      </span>
    );
  }

  return <></>;
};
