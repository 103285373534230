import { BaseEntity, Id } from '../../common';

export interface HandleMethodColor {
  /** 潘通色号名称 */
  name: string;
  /** 颜色编码 */
  color: string;
}

export class HandleMethod extends BaseEntity<HandleMethod> {
  /** 后处理名 */
  name: string;

  /** 英文名 */
  enName?: string;

  tenantId: Id;

  seq?: number;
  /** 后处理单价 */
  workingHoursUnitPrice?: number;
  /** 适用的材料工艺 */
  materialTypeIds: Id[];
  /** 常见颜色 */
  commonColors?: HandleMethodColor[] = [];
  /** 后处理描述 */
  desc?: string;
  /** 跳转链接 */
  jumpLink?: string;
  /** 后处理预览图文件 */
  prevFileIds: Id[] = [];

  constructor(props?: HandleMethod) {
    super(props);

    Object.assign(this, props);
  }
}
