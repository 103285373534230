import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Typography } from 'antd';
import cn from 'classnames';

import {
  getUseShopQuoteStoreByCode,
  ShopOrderQuoteStoreModifiers,
} from '../../../../../../../stores';
import { QuoteFinishSelect } from '../../../../../components/select/QuoteFinishSelect';
import styles from './index.module.less';

export interface ShopQuoteItemFinishSelectProps {
  className?: string;
  style?: Record<string, string | number>;

  orderCode: string;
  itemId: string;
}

export const ShopQuoteItemFinishSelect = ({
  className,
  style,
  orderCode,
  itemId,
}: ShopQuoteItemFinishSelectProps) => {
  const { item } = getUseShopQuoteStoreByCode(orderCode)(s => ({
    shopOrder: s.shopOrderDraft,
    item: (s.shopOrderDraft.items || []).find(i => i.id === itemId),
  }));

  const onChange = ({
    finishMethod,
    color,
  }: {
    finishMethod: string;
    color: S.HandleMethodColor;
  }) => {
    ShopOrderQuoteStoreModifiers.updateOrderDraft(orderCode, o => {
      const item = o.items.find(i => i.id === itemId);
      item.handleRemark = {
        method:
          finishMethod && finishMethod !== 'Standard' ? [finishMethod] : [],
        // 这里为了兼容老的数据模型，还是把 color 也是放一份到 desc
        desc: color.name,
        color: color.name,
      };
    });
  };

  return (
    <div
      id="ShopQuoteItemFinishSelect"
      className={cn(className, styles.container)}
      style={style}
    >
      <div className={styles.shopQuoteItemFinishSelectTitle}>
        <Typography.Title level={5}>{i18nFormat('Finish')}</Typography.Title>
        <a
          href={'https://www.unionfab.com/service_cat/finishing'}
          target="_blank"
          rel="noreferrer"
        >
          {i18nFormat('Learn about our finishes')}
        </a>
      </div>
      <div className={styles.flexStart} style={{ width: '80%' }}>
        <QuoteFinishSelect
          style={{ flex: '1 1 1px', marginRight: '1rem' }}
          materialTypeIds={[
            S.get(item, item => item.materialVO.materialTypeId),
          ]}
          value={S.get(item, item => item.handleRemark)}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

ShopQuoteItemFinishSelect.displayName = 'ShopQuoteItemFinishSelect';
