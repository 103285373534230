import { UploadFile } from 'antd';
import { create, StoreApi, UseBoundStore } from 'zustand';
import { devtools } from 'zustand/middleware';

import { getDefaultTechCategory, TechCategory } from '../../../schema';

export interface QuoteUiStoreState {
  /** 当前正在编辑的文件 ID */
  editingFileId?: string;

  batchEditingFileIds: string[];

  /**
   * 当前选中的工艺类别
   * 这里仅用于空白询价页面中用于记录初始的工艺类型；一旦创建过条目，后续的工艺类别都是从订单中获取
   */
  curTechCategory?: TechCategory;

  /**
   * 上传中的文件列表，在 UI 中用到了 uploadFile 的两个属性
   * - 如果 uploadFile 为 undefined，表示已经上传完毕，从上传列表中移除
   * - percent：表示当前文件的上传百分比
   * - status:
   *  - removed: 表示已经被用户手动移除，不再显示，并且上传完毕后不再操作
   */
  uploadingMap?: Record<string, UploadFile>;
}

export const useShopQuoteUiStore: UseBoundStore<
  StoreApi<QuoteUiStoreState>
> = create<QuoteUiStoreState>()(
  devtools(
    (set, get) => {
      return {
        batchEditingFileIds: [],
        curTechCategory: getDefaultTechCategory(),
        uploadingMap: {},
      };
    },
    { name: 'useShopQuoteUiStore' },
  ),
);

export class UseShopQuoteUiStoreUtils {
  /** 控制条目是否编辑 */
  static toggleFileItemEditing = (id: string, checked?) => {
    const { batchEditingFileIds } = useShopQuoteUiStore.getState();

    if (checked === true || !batchEditingFileIds.includes(id)) {
      useShopQuoteUiStore.setState({
        batchEditingFileIds: Array.from(
          new Set([...batchEditingFileIds, id]),
        ) as string[],
      });
    }

    if (checked === false || batchEditingFileIds.includes(id)) {
      useShopQuoteUiStore.setState({
        batchEditingFileIds: batchEditingFileIds.filter(i => i !== id),
      });
    }
  };
}
