import { CacheableBatchDataLoader } from '@m-fe/ts-async';

import * as S from '../../../../schema';
import { D3ModelFile } from '../../../../schema';
import { hasUserLogined } from '../../../../singleton-ins';
import { getUfcShopComposedApi } from '../../..';
import { getCacheableFileById } from '.';
import { getD3ModelProcessInfoMap } from './d3_model_process';
import {
  getCacheablePreSignedUrl,
  getImagePreSignedUrl,
  updateFileAttr,
} from './file';

/** 还是从批量中进行获取 */
export async function getModelFileById(
  fileId: S.Id,
  { disableLocalCache }: { disableLocalCache?: boolean } = {},
) {
  const fileMap = disableLocalCache
    ? await getModelFilesByIds([fileId])
    : await getModelFileMapByIdsWithCache({ fileIds: [fileId] });

  return fileMap[fileId];
}

export const getModelFileMapByIdsLoader = new CacheableBatchDataLoader<
  string,
  S.D3ModelFile
>(getModelFilesByIds, {
  instantiateObj: v => {
    return new S.D3ModelFile(v);
  },
});

export async function getModelFileMapByIdsWithCache({
  fileIds,
  useCache = true,
}: {
  fileIds: S.Id[];
  useCache?: boolean;
}) {
  return getModelFileMapByIdsLoader.loadByKeys({ keys: fileIds, useCache });
}

/** 批量获取模型文件信息，不从缓存中获取 */
export async function getModelFilesByIds(
  fileIds: S.Id[],
): Promise<Partial<Record<S.Id, S.D3ModelFile>>> {
  const obj: Partial<Record<S.Id, S.D3ModelFile>> = {};

  if (!hasUserLogined()) {
    for (const fileId of fileIds) {
      if (!!fileId) {
        const file = await getCacheableFileById(fileId);

        obj[fileId] = new D3ModelFile(file);
      }
    }
  } else {
    const { data } =
      (await getUfcShopComposedApi().umiRequestCompatibleApi.postUmi<{
        data: S.File[];
      }>(`/files/batch`, {
        data: fileIds.filter(i => !!i).filter(i => !i.includes(' ')),
      })) || {};

    const models = (data || []).map(d => new S.D3ModelFile(d));

    // TODO: REMOVE THIS
    const d3ModelFileProcessAttributesMap = await getD3ModelProcessInfoMap(
      fileIds.filter(s => !!s),
    );

    for (const fileId of fileIds) {
      // 这里进行一次图片兼容，如果发现有 thumbnailFileId 但是没有 thumbnail 则直接获取
      const model = models.find(m => m.id === fileId);
      const modelAttr = d3ModelFileProcessAttributesMap[fileId];

      // 模型获取失败，则跳过
      if (!model) {
        continue;
      }

      // 在存在缩略图文件的情况下进行补全
      if (model.attr.thumbnailFileId && !model.attr.thumbnail) {
        model.attr.thumbnail = await getImagePreSignedUrl(
          model.attr.thumbnailFileId,
          undefined,
        );

        updateFileAttr(model.id, {
          thumbnail: model.attr.thumbnail,
        });
      }

      model.attr.sizeX = S.get(modelAttr, m => m.sizeX) || model.attr.sizeX;
      model.attr.sizeY = S.get(modelAttr, m => m.sizeY) || model.attr.sizeY;
      model.attr.sizeZ = S.get(modelAttr, m => m.sizeZ) || model.attr.sizeZ;
      model.attr.volume =
        typeof S.get(modelAttr, m => m.volume) === 'undefined'
          ? model.attr.volume
          : S.get(modelAttr, m => m.volume);

      obj[fileId] = model;
    }
  }

  return obj;
}

/** 获取模型可用于预览的信息 */
export async function getD3ModelWebViewableInfo(
  modelFile: S.D3ModelFile,
  {
    previewPreference = 'ORIGIN_FILE',
  }: {
    previewPreference?: 'ORIGIN_FILE' | 'CONVERTED_FILE';
  } = {},
): Promise<{
  modelFile: S.D3ModelFile;
  src: string;
  type: S.D3ModelFileType;
  compressType: S.D3ModelFileCompressType;
}> {
  const loadModelFile = (fileId: S.Id) => {
    return getModelFileById(fileId);
  };

  // 判断是否为可以直接展示的文件，如果是，则直接展示；否则获取转换后的文件信息
  if (
    ['stl', 'obj'].includes(modelFile.attr.type) &&
    previewPreference !== 'CONVERTED_FILE'
  ) {
    const src = await getCacheablePreSignedUrl(
      modelFile.id,
      S.get(modelFile, modelFile => modelFile.stores[0].storeId),
    );

    // 直接返回源信息
    return {
      modelFile,
      src,
      compressType: modelFile.attr.compressType,
      type: modelFile.attr.type as S.D3ModelFileType,
    };
  } else {
    if (modelFile.attr.glbFileId) {
      // 对于非可直接展示的，获取其 STL 模型信息
      const glbFile = await loadModelFile(modelFile.attr.glbFileId);

      const src = await getCacheablePreSignedUrl(
        modelFile.attr.glbFileId,
        S.get(glbFile, modelFile => modelFile.stores[0].storeId),
      );

      return {
        modelFile,
        src,
        type: 'glb',
        compressType: glbFile.attr.compressType || 'none',
      };
    }

    if (modelFile.attr.stlFileId) {
      // 对于非可直接展示的，获取其 STL 模型信息
      const stlFile = await loadModelFile(modelFile.attr.stlFileId);

      const src = await getCacheablePreSignedUrl(
        modelFile.attr.stlFileId,
        S.get(stlFile, modelFile => modelFile.stores[0].storeId),
      );

      return {
        modelFile,
        src,
        type: 'stl',
        compressType: stlFile.attr.compressType || 'none',
      };
    }
  }
}
