import { Route, Routes } from 'react-router-dom';

import {
  OrderPaymentCheckoutPage,
  OrderPaymentSuccessPage,
  OrderQuoteCheckoutPage,
  OrderQuoteCheckoutSuccessPage,
} from './NewQuote/CheckoutPage';
import { OrderBankTransferPage } from './NewQuote/CheckoutPage/OrderPaymentCheckoutPage/OrderPaymentPaySubmitters/InquiryOrderBankTransferPage';

export const PaymentRoutes = () => {
  return (
    <Routes>
      <Route path="/success" element={<OrderPaymentSuccessPage />} />
      <Route path="/bank-transfer" element={<OrderBankTransferPage />} />
      <Route path="*" element={<OrderPaymentCheckoutPage />} />
    </Routes>
  );
};

export const CheckoutRoutes = () => {
  return (
    <Routes>
      <Route path="success" element={<OrderQuoteCheckoutSuccessPage />} />
      <Route path="*" element={<OrderQuoteCheckoutPage />} />
    </Routes>
  );
};
