import {
  DownOutlined,
  InfoCircleOutlined,
  RightOutlined,
} from '@ant-design/icons';
import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Button, Collapse, Modal, Tooltip } from 'antd';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useInquiryQuoteCheckoutStore } from '@/stores';

import { OrderItemReviewTable } from '../../../../../../order_history/components/OrderItemReviewTable';
import { QuoteOrderPrices } from '../../../../../hooks/useOrderPrices';
import { OrderSubmitState } from '../../../../../hooks/useOrderSubmitState';
import { OrderRemark } from '../../order-checkout-fields/OrderRemark';
import { OrderCheckoutShipAddressSelect } from '../../order-checkout-fields/OrderShipOptions';
import { SelectExpressOptionsForCheckout } from '../../order-checkout-fields/OrderShipOptions/OrderCheckoutExpressOptionsSelect';
import { InquiryOrderCheckoutStep } from '../../order-checkout-fields/types';
import styles from './index.module.less';

export interface OrderQuoteCheckoutProps {
  visibleSteps: InquiryOrderCheckoutStep[];
  setVisibleSteps: (steps: InquiryOrderCheckoutStep[]) => void;

  submitState: OrderSubmitState;
  quoteOrderPrices: QuoteOrderPrices;
}

const WARNING_TEXT = i18nFormat(
  `As long as you want to have some special requirements for the products you order, please enter them in the input box below.`,
);

export const OrderQuoteCheckoutPanelLayout = ({
  visibleSteps,
  setVisibleSteps,
  quoteOrderPrices,
}: OrderQuoteCheckoutProps) => {
  const [reviewModalVisible, setReviewModalVisible] = useState(false);

  const { orderDeliverAddress, quotation } = useInquiryQuoteCheckoutStore();

  let order = useInquiryQuoteCheckoutStore().order;

  const [searchParams] = useSearchParams();
  const orderCode = searchParams.get('orderCode');
  order = order?.code === orderCode ? order : undefined;

  const renderCollapseBtn = (step: InquiryOrderCheckoutStep) => {
    if (visibleSteps.includes(step)) {
      return (
        <Button
          icon={<DownOutlined />}
          shape="circle"
          size="small"
          onClick={() => {
            setVisibleSteps(S.addOrRemove(visibleSteps, step));
          }}
        />
      );
    }

    return (
      <Button
        icon={<RightOutlined />}
        shape="circle"
        size="small"
        onClick={() => {
          setVisibleSteps(S.addOrRemove(visibleSteps, step));
        }}
      />
    );
  };

  return (
    <div className={styles.checkoutProcess}>
      <div className={styles.checkoutHeader}>
        <div className={styles.checkoutText}>
          <span>
            {quoteOrderPrices.isTotalRfq
              ? i18nFormat('Request a Quote')
              : i18nFormat('Order Checkout')}
          </span>
          <span>{i18nFormat('Your Information is Protected')}</span>
          {order && (
            <Button
              type="link"
              onClick={() => setReviewModalVisible(true)}
              style={{ padding: 0, position: 'absolute', right: 0 }}
            >
              {i18nFormat('Review')} {order.printFiles.length}{' '}
              {i18nFormat('Parts')}
            </Button>
          )}
        </div>
      </div>

      <div className={styles.panelWrapper}>
        <Collapse activeKey={visibleSteps}>
          <Collapse.Panel
            key={'select-ship-address'}
            header={i18nFormat('Shipping Address')}
            extra={renderCollapseBtn('select-ship-address')}
          >
            <div style={{ marginTop: '-16px' }}>
              <OrderCheckoutShipAddressSelect />
            </div>
          </Collapse.Panel>
          <Collapse.Panel
            key={'select-ship-option'}
            header={i18nFormat('Shipping Method')}
            extra={
              orderDeliverAddress ? (
                renderCollapseBtn('select-ship-option')
              ) : (
                <Tooltip
                  title={i18nFormat('Please select a shipping address')}
                  color="black"
                  className={styles.orderRemarkHeaderTips}
                >
                  <InfoCircleOutlined />
                </Tooltip>
              )
            }
          >
            <SelectExpressOptionsForCheckout />
          </Collapse.Panel>
          <Collapse.Panel
            key={'order-remark'}
            header={
              <div>
                {i18nFormat('Order Remark')}
                <Tooltip
                  title={WARNING_TEXT}
                  color="black"
                  className={styles.orderRemarkHeaderTips}
                >
                  <InfoCircleOutlined />
                </Tooltip>
              </div>
            }
            extra={renderCollapseBtn('order-remark')}
          >
            <div className={styles.orderRemarkContainer}>
              <OrderRemark />
            </div>
          </Collapse.Panel>
        </Collapse>
      </div>

      {order && (
        <Modal
          title={i18nFormat('Checkout {0} parts').replace(
            '{0}',
            (order.printFiles || []).length,
          )}
          open={reviewModalVisible}
          width={'80vw'}
          onOk={() => setReviewModalVisible(false)}
          onCancel={() => setReviewModalVisible(false)}
        >
          <OrderItemReviewTable order={order} quotation={quotation} />
        </Modal>
      )}
    </div>
  );
};
