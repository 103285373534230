import { DownloadOutlined } from '@ant-design/icons';
import * as S from '@unionfab/ufc-shop-commons';
import {
  getTenantIdFromGConfig,
  getUfcShopComposedApi,
  i18nFormat,
} from '@unionfab/ufc-shop-commons';
import { Button, Empty, Image, Modal, Space } from 'antd';
import { compact } from 'lodash';
import { Fragment, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import { UfcFileDownloader } from '@/commons/components/file/UfcFileDownloader';

import styles from './index.module.less';

export const OrderQualityInspectionButton = ({
  shopOrder,
  linkBtn,
}: {
  shopOrder: S.ShopOrder;
  /** link 样式的触发按钮 */
  linkBtn?: boolean;
}) => {
  const [modalVisible, toggleModalVisible] = useState<boolean>(false);
  const visible =
    shopOrder &&
    ['ORDER_COMPLETE', 'WAIT_RECEIVE', 'WAIT_DELIVER'].includes(
      shopOrder?.status,
    );

  const { data: qualityInspection, isLoading } = useQuery(
    ['qualityInspection', shopOrder, visible],
    () => {
      if (visible) {
        return getUfcShopComposedApi().qualityInspectionApi.getOrderQualityInspectionInfo(
          shopOrder.code,
          getTenantIdFromGConfig(),
        );
      }
    },
  );

  const qualityInspectionInfo = useMemo(() => {
    if (!qualityInspection) return <></>;

    const { imageIds, filesMap, attachmentIds } = qualityInspection;

    const photos = compact((imageIds || []).map(i => filesMap[i]));
    const attachments = compact((attachmentIds || []).map(i => filesMap[i]));
    const isEmpty = !S.isValidArray(photos) && !S.isValidArray(attachments);

    if (isEmpty) return <Empty />;

    const fileIds = [...imageIds, ...attachmentIds];

    return (
      <div className={styles.qualityInspectionInfo}>
        {S.isValidArray(photos) && (
          <div className={styles.items}>
            <h3>{i18nFormat('Photos')}</h3>
            <Space>
              {photos.map(p => (
                <Image key={p.md5} src={p.url} width={200} />
              ))}
            </Space>
          </div>
        )}
        {S.isValidArray(attachments) && (
          <div className={styles.items}>
            <h3>{i18nFormat('Inspection')}</h3>
            <Space direction="vertical">
              {attachmentIds.map(fileId => (
                <Fragment key={fileId}>
                  <UfcFileDownloader fileId={fileId} />
                </Fragment>
              ))}
            </Space>
          </div>
        )}
        <div className={styles.flexCenter}>
          <UfcFileDownloader style={{ width: '50%' }} fileIds={fileIds}>
            <Button icon={<DownloadOutlined />} block size="large">
              {i18nFormat('download all')}
            </Button>
          </UfcFileDownloader>
        </div>
      </div>
    );
  }, [qualityInspection]);

  if (!visible) return <></>;

  return (
    <div className={styles.container}>
      {linkBtn ? (
        <Button
          type="link"
          loading={isLoading}
          onClick={() => toggleModalVisible(true)}
          style={{ padding: 0, height: 'auto' }}
        >
          {i18nFormat('Inspection')}
        </Button>
      ) : (
        <Button
          loading={isLoading}
          onClick={() => toggleModalVisible(true)}
          type="primary"
        >
          {i18nFormat('Inspection')}
        </Button>
      )}

      <Modal
        width="50%"
        footer={false}
        open={modalVisible}
        onCancel={() => toggleModalVisible(false)}
      >
        {qualityInspectionInfo}
      </Modal>
    </div>
  );
};
