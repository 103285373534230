import * as S from '@unionfab/ufc-shop-commons';
import {
  i18nFormat,
  useReceiveAddressMgtStore,
} from '@unionfab/ufc-shop-commons';
import { message, Typography } from 'antd';
import { useEffect, useState } from 'react';

import {
  ReceiveAddressCreateCard,
  ReceiveAddressEditCard,
  ReceiveAddressEditOperationType,
} from '../components';
import styles from './ReceiveAddressMgt.module.less';

interface EditingAddress {
  type: ReceiveAddressEditOperationType;
  address: S.ReceiveAddress;
}

interface CreatingAddress {
  visible: boolean;
  address: S.ReceiveAddress;
}

export const ReceiveAddressMgt = () => {
  const store = useReceiveAddressMgtStore();

  const [editing, setEditing] = useState<EditingAddress | undefined>();
  const [creating, setCreating] = useState<CreatingAddress>({
    address: new S.ReceiveAddress({}),
    visible: false,
  });

  useEffect(() => {
    store.loadAddressList();
  }, []);

  const { addressList } = store;

  const createAddress = async (addr: S.ReceiveAddress) => {
    if (creating?.visible) {
      try {
        await store.createAddress(addr);
        setCreating({
          address: new S.ReceiveAddress({}),
          visible: creating?.visible,
        });
      } catch (e) {
        message.error({ content: i18nFormat('Error creating address') });
      }
    } else {
      console.warn('illegal state', addr, editing);
    }
  };

  const updateAddress = async (addr: S.ReceiveAddress) => {
    if (editing?.type === 'edit' && editing?.address.id === addr.id) {
      await store.updateAddress(addr);
    } else {
      console.warn('illegal state', addr, editing);
    }
  };

  const deleteAddress = async (addr: S.ReceiveAddress) => {
    if (editing?.type === 'delete' && editing?.address.id === addr.id) {
      await store.deleteAddress(addr);
    } else {
      console.warn('illegal state', addr, editing);
    }
  };

  // 地址卡片列表
  const addressCols: JSX.Element[] = [];

  // 新建地址
  addressCols.push(
    <ReceiveAddressCreateCard
      key="receive-address-create-card"
      address={creating.address}
      modalVisible={creating.visible}
      createAddress={createAddress}
      toggleModal={visible =>
        setCreating({ visible, address: creating.address })
      }
    />,
  );

  addressList.forEach((address: S.ReceiveAddress, idx) => {
    const operation =
      address.id === editing?.address.id ? editing?.type : undefined;
    addressCols.push(
      <ReceiveAddressEditCard
        key={idx}
        address={address}
        operation={operation}
        toggleOperation={type => {
          setEditing(type ? { type, address } : undefined);
        }}
        updateAddress={updateAddress}
        deleteAddress={async () => await deleteAddress(address)}
        setAsDefault={async () => await store.setAsDefault(address)}
      />,
    );
  });

  return (
    <div className={styles.receiveAddressMgt}>
      <div className={styles.receiveAddressMgtTitle}>
        <Typography.Title level={3}>
          {i18nFormat('Shipping Addresses')}
        </Typography.Title>
      </div>
      <div className={styles.receiveAddressMgtContent}>{addressCols}</div>
    </div>
  );
};
