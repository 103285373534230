import * as S from '../../../../schema';
import { getTenantIdFromGConfig } from '../../..';
import { AliyunSlsApmTrackerUtils } from '../../../vendor-apis/aliyun-apm';
import { BaseAxiosApi } from '../../shared';

export class UfcShopMaterialQueryApi extends BaseAxiosApi {
  /** 查询材料列表 */
  async queryShopMaterials({
    groupId,
  }: {
    groupId: string;
  }): Promise<S.InquiryMaterial[]> {
    return AliyunSlsApmTrackerUtils.getAliyunSlsApmTracker().runAndSendApiRecord(
      {
        api_id: 'get-/v2/shop/material',
        request_url: `${this.apiConfig.apiEndpoint}/v2/shop/material`,
        request_params: JSON.stringify({ quotationGroupId: groupId }),
      },
      async () => {
        const { data } = await this.get<S.InquiryMaterial[]>({
          url: `${this.apiConfig.apiEndpoint}/v2/shop/material`,
          params: {
            quotationGroupId: groupId,
            tenantId: getTenantIdFromGConfig(),
          },
        });

        if (S.isValidArray(data)) {
          const types = await this.queryShopMaterialTypes();

          return data.map(d => {
            if (!d.materialTypeId) return new S.InquiryMaterial(d);
            const materialType = (types || []).find(
              t => t.id == d.materialTypeId,
            );
            return new S.InquiryMaterial({ ...d, materialType });
          });
        }

        return [];
      },
    );
  }

  /** 查询材料类型列表 */
  async queryShopMaterialTypes() {
    return AliyunSlsApmTrackerUtils.getAliyunSlsApmTracker().runAndSendApiRecord(
      {
        api_id: 'get-/v2/shop/material_type',
        request_url: `${this.apiConfig.apiEndpoint}/v2/shop/material_type`,
        request_params: JSON.stringify({ tenantId: getTenantIdFromGConfig() }),
      },
      async () => {
        const { data } = await this.get<S.InquiryMaterialType[]>({
          url: `${this.apiConfig.apiEndpoint}/v2/shop/material_type`,
          params: { tenantId: getTenantIdFromGConfig() },
        });

        return (data || []).map(d => new S.InquiryMaterialType(d));
      },
    );
  }
}
