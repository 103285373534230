import { CheckCircleOutlined } from '@ant-design/icons';
import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Button } from 'antd';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useAppNavigate } from '@/features/shared';
import { useInquiryQuoteCheckoutStore } from '@/stores';

import styles from './index.module.less';

export const OrderPaymentSuccessPage = () => {
  const { order, paymentPrice } = useInquiryQuoteCheckoutStore();
  const nav = useAppNavigate();

  useEffect(() => {
    if (!order) {
      // 该页面只应该由 payment 转过来
      console.warn('illegal state, no order loaded (payment success)');
      nav.navigateToHome();
    }
  }, []);

  if (!order) {
    return <></>;
  }

  const orderCode = order.code;
  const orderId = order.id;

  return (
    <section className={styles.container}>
      <div className={styles.result}>
        <div className={styles.successIcon}>
          <CheckCircleOutlined />
        </div>

        <div className={styles.thanks}>
          {i18nFormat('Thank you for your order')} !
        </div>

        <div className={styles.info}>
          <span>
            {i18nFormat('Your order code')}: {orderCode}
          </span>
          {paymentPrice && paymentPrice.total && (
            <span>
              {i18nFormat('Total Price')}:{' '}
              {S.toPriceStringWithOption(paymentPrice.total, {
                currency: order.currency,
              })}
            </span>
          )}
        </div>

        <div className={styles.goDetail}>
          <Link to={`/orders_history/${orderCode}?id=${orderId}`}>
            <Button type="primary">{i18nFormat('See the Order Detail')}</Button>
          </Link>
        </div>
      </div>
    </section>
  );
};
