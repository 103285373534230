import { useInterval } from 'ahooks';
import dayjs from 'dayjs';
import { default as jwtDecode } from 'jwt-decode';
import { isNil } from 'lodash';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { get, getUrlParameterByName } from '../../schema';
import {
  useGlobalStore,
  useInquiryCouponStore,
  useReceiveAddressMgtStore,
} from '../../stores';
import { AccessTokenData, TOKEN_KEY, UfcShopTokenUtils } from '../env';
import {
  getUfcShopComposedApi,
  getUfcShopRuntimeEnv,
} from '../../singleton-ins';
import { debounce } from 'lodash';

const ALLOW_DEMO_PATH = [
  '/anonymous_new_shop_quote',
  '/quality-inspection',
  '/cancel-subscribe-email',
];

// AUTH_PATH 中，如果有 token 和 redirectTo 则不需要重定向
const AUTH_PATH = [
  '/auth',
  'auth',
]

// depends on react-router.
export const useAuthToken = () => {
  const { pathname } = useLocation();

  const { loadInquiryCoupons } = useInquiryCouponStore(o => ({
    loadInquiryCoupons: o.loadInquiryCoupons,
  }));
  const { loadAddressList } = useReceiveAddressMgtStore(o => ({
    loadAddressList: o.loadAddressList,
  }));

  const {
    isDemoUser,
    hasAuthenticated,
    loadUser,
    clearToken,
    refreshAccessToken,
    loginOrdinary,
  } = useGlobalStore(g => ({
    loadUser: g.loadUser,
    clearToken: g.clearToken,
    isDemoUser: g.isDemoUser,
    hasAuthenticated: g.hasAuthenticated,
    refreshAccessToken: g.refreshAccessToken,
    loginOrdinary: g.loginOrdinary,
  }));

  useEffect(() => {
    /** 调用 UfcShopTokenUtils 中的 setToken 方法会触发 setTokenEvent 事件，这边会接收到最新的 token  */
    window.addEventListener('setTokenEvent', (e: StorageEvent) => {
      const eventToken = e[TOKEN_KEY];

      if (eventToken && UfcShopTokenUtils.isTokenValid(eventToken)) {
        UfcShopTokenUtils.refreshToken();
        loadUser(true);
        loadAddressList();
        loadInquiryCoupons();
      }
    });

    return () => {
      window.removeEventListener('setTokenEvent', () => { });
    };
  }, []);

  /** 判断 token 是否有效 是否为 demo token */
  useEffect(() => {
    // 创建防抖版本的函数
    const debouncedOpenLoginPage = debounce(openLoginPage, 300);
    const debouncedRedirectToLogin = debounce(redirectToLogin, 300);

    // 核心参数
    // hasAuthenticated 是否授权

    // 0° 跳过对根路径（'/'）的身份验证检查
    if (pathname === '/') {
      return;
    }

    // 1° 获取判定条件 允许 demo 用户访问的情况  即无鉴权访问
    const isDemoPathAllowed = ALLOW_DEMO_PATH.some(path =>
      pathname.startsWith(path),
    );
    //  允许访问 终止后续判断
    if (isDemoPathAllowed) {
      return; // 终止后续判断
    }

    // 2° 针对 /auth?request=true 的请求，重定向到登录页面
    const requestAuth = getUrlParameterByName('request') === 'true';
    if (pathname.startsWith('/auth') && requestAuth) {
      if (hasAuthenticated) {
        const currentPath = window.location.pathname;
        const newPath = currentPath.split('#')[0] + '#/new_shop_quote';
        window.location.href = newPath;
        return;
      } else {
        return debouncedRedirectToLogin();
      }
    }

    // 3° 针对 /auth/sign-in 的请求，重定向到登录页面
    if (pathname.startsWith('/auth/sign-in')) {
      return debouncedRedirectToLogin();
    }

    // 4° 处理认证回调：如果是认证路径且有重定向URL和token，则跳过后续登录逻辑
    const isAuthPath = AUTH_PATH.some(path => pathname.startsWith(path));
    const redirectTo = getUrlParameterByName('redirectTo');
    const token = getUrlParameterByName('token');
    if (isAuthPath && redirectTo && token) {
      return;
    }

    // 5° 处理非demo用户路径登录逻辑，使用防抖版本
    if (
      !UfcShopTokenUtils.getIdToken(true) ||
      !hasAuthenticated ||
      (hasAuthenticated && isDemoUser)
    ) {
      debouncedOpenLoginPage();
    }

    // 清理防抖函数
    return () => {
      debouncedOpenLoginPage.cancel();
      debouncedRedirectToLogin.cancel();
    };
  }, [hasAuthenticated, isDemoUser, pathname]);

  useInterval(
    () => {
      /** 匿名登录页面触发登录后刷新数据 */
      const user = UfcShopTokenUtils.decodeAccessToken(
        UfcShopTokenUtils.getAccessToken(true),
      );

      if (!!user && isNil(get(user, u => u.anonymousSessionId))) {
        loadUser(true);
      }
    },
    hasAuthenticated && isDemoUser ? 600 : undefined,
    { immediate: false },
  );

  /** 监听 accessToken 是否过期 */
  useInterval(() => {
    /** 有 idToken */
    if (UfcShopTokenUtils.getIdToken(true)) {
      const accessToken = UfcShopTokenUtils.getAccessToken(true);

      /** 提前十分钟刷新 accessToken */
      if (!accessToken || isJwtTokenExpired(accessToken, 10)) {
        console.warn('wait refresh accessToken');
        /** 首先判断 idToken 是否过期 */
        if (!isJwtTokenExpired(UfcShopTokenUtils.getIdToken(true), 10)) {
          refreshAccessToken();
        } else {
          /** idToken 过期直接清空token信息 */
          clearToken();
        }
      }
    } else {
      /** 重置用户信息并更新 hasAuthenticated 相关信息触发页面跳转逻辑或 demo token 刷新逻辑 */
      if (hasAuthenticated && !isDemoUser) {
        useGlobalStore.setState({ hasAuthenticated: false, isDemoUser: false });
      }

      /** 判断 demo token 是否过期  */
      if (hasAuthenticated && isDemoUser) {
        const demoAccessToken = UfcShopTokenUtils.getAccessToken(true);

        /** 提前十分钟刷新 demoAccessToken */
        if (!demoAccessToken || isJwtTokenExpired(demoAccessToken, 10)) {
          console.warn('wait refresh accessToken');
          /** 这里直接重新获取 demoAccessToken */
          loginOrdinary();
        }
      }
    }
  }, 3000);
};

const isJwtTokenExpired = (
  token: string | undefined,
  lead = 0,
  leadType: dayjs.ManipulateType = 'm',
) => {
  if (!token || token == '') return true;

  const { exp } = jwtDecode(token) as AccessTokenData;

  if (!exp || typeof exp !== 'number') return true;

  // 这里引入提前量，以提前进行 Token 更新
  if (dayjs.unix(exp).isAfter(dayjs().add(lead, leadType))) return false;

  return true;
};

function createAuthRecvUrl() {
  const { protocol, host, pathname } = window.location;
  const recvUrl = `${protocol}//${host}${pathname}#/auth?token=__TOKEN__&idToken=__IDTOKEN__&redirectTo=${encodeURIComponent(
    window.location.href,
  )}`;
  return recvUrl;
}

// new_shop_quote
function createQuoteRecvUrl() {
  const { protocol, host, pathname } = window.location;
  const baseUrl = `${protocol}//${host}${pathname}`;
  const newShopQuoteUrl = baseUrl + '#/new_shop_quote';
  const recvUrl = `${baseUrl}#/auth?token=__TOKEN__&idToken=__IDTOKEN__&redirectTo=${encodeURIComponent(
    newShopQuoteUrl
  )}`;
  return recvUrl;
}

function openLoginPage() {
  // fixme: 临时使用 instamach 的登录地址 commons
  // const authUrl = 'https://instamach.unionfab.com/auth/sign-in';
  // 不可访问，意味着需要登录才能访问
  let authUrl: string;
  try {
    authUrl =
      window.gConfig.OAUTH_URL ||
      import.meta.env.VITE_AUTH_URL ||
      'https://www.unionfab.com/auth/login';
  } catch (e) {
    authUrl = 'https://www.unionfab.com/auth/login';
  }

  const back = createAuthRecvUrl();

  window.open(
    `${authUrl}?redirectTo=${encodeURIComponent(back)}&redirectTarget=_self`,
    '_self',
  );
}

// 重定向到登录页面
function redirectToLogin() {
  let authUrl: string;
  try {
    authUrl =
      window.gConfig.OAUTH_URL ||
      import.meta.env.VITE_AUTH_URL ||
      'https://www.unionfab.com/auth/login';
  } catch (e) {

    authUrl = 'https://www.unionfab.com/auth/login';
  }

  const back = createQuoteRecvUrl();

  window.open(
    `${authUrl}?redirectTo=${encodeURIComponent(back)}&redirectTarget=_self`,
    '_self',
  );
}
