import { compact, uniqBy } from 'lodash';

import { BaseEntity, D3ModelFile } from '../../../common';
import { get, isValid, isValidArray } from '../../../utils';
import { InquiryMaterial, InquiryMaterialSku } from '../../material';
import { ShopOrderExtra } from './ShopOrder';
import { ShopOrderRequestParamsItem } from './ShopOrderRequestParams';

export class ShopOrderItem extends ShopOrderRequestParamsItem {
  get rowKey() {
    return this.id || this.fileId;
  }

  /** 关联的材料 */
  materialVO: InquiryMaterial;

  /** 关联的材料 Spu */
  materialSkuVO: InquiryMaterialSku;

  /** 关联的模型文件 */
  modelFileVO: D3ModelFile;

  remarkFileVO: Partial<Record<'name' | 'size' | 'url' | 'id', string>>[] = [];

  constructor(
    data: Partial<ShopOrderItem> = {},
    {
      fileMap = {},
      materials = {},
      skus = {},
      files = {},
    }: Partial<ShopOrderExtra> = {},
  ) {
    super(data);

    BaseEntity.assignInConstructor(this, data);

    if (data.modelFileVO && data.modelFileVO.id === this.fileId) {
      this.modelFileVO = new D3ModelFile(this.modelFileVO);
    } else {
      this.modelFileVO = fileMap[this.fileId];
    }

    if (data.materialSkuVO && data.materialSkuVO.id === this.skuId) {
      this.materialSkuVO = new InquiryMaterialSku(this.materialSkuVO);
    } else {
      this.materialSkuVO = skus[this.skuId];
    }

    if (this.materialVO || this.materialSkuVO) {
      const material =
        this.materialVO || materials[this.materialSkuVO.materialId];

      const materialTypeId = get(
        skus[this.skuId],
        s => s.materialSnapshot.materialTypeId,
      );

      this.materialVO = new InquiryMaterial({ ...material, materialTypeId });
    }

    const remarkFileIds = get(this, t => t.remark.fileIds, []);

    if (
      isValid(files) &&
      isValidArray(remarkFileIds) &&
      !isValidArray(this.remarkFileVO)
    ) {
      const remarkFileVO = compact(
        Object.keys(files).map(id => {
          if (remarkFileIds.includes(id)) {
            return { ...files[id], id };
          }
        }),
      );

      this.remarkFileVO = uniqBy(remarkFileVO, r => r.id);
    }
  }
}
