import { PayCircleOutlined } from '@ant-design/icons';
import {
  convertReceiveAddressToDeliverAddress,
  readOrderReceiveAddress,
  UnionfabEventTracker,
  updateInquiryOrderByCodeForOverseasCustomer,
} from '@unionfab/ufc-shop-commons';
import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Button, ButtonProps, message, Tooltip } from 'antd';
import { useState } from 'react';

import { 客服Svg } from '@/assets';
import { useAppNavigate } from '@/features/shared';
import {
  doLoadAddressList,
  useGlobalStore,
  useInquiryQuoteEditorStore,
  useShopQuoteUiStore,
} from '@/stores';

export interface GoOrderCheckoutBtnProps {
  className?: string;
  style?: Record<string, string | number>;

  price?: number;
  buttonProps?: ButtonProps;
  signUp: (signUpSuccess: () => void) => void;
}

export const GoOrderCheckoutBtn = ({
  className,
  style,
  price,
  signUp,
  buttonProps = {},
}: GoOrderCheckoutBtnProps) => {
  const { isDemoUser } = useGlobalStore();
  const nav = useAppNavigate();
  const quoteEditorStore = useInquiryQuoteEditorStore();
  const {
    inquiryOrder,
    estPrice,
    isLoadingEstPrice,
    technology,
    invalidItemIndexListForModelFileType,
    invalidItemIndexListForRemark,
    processingFileMap,
  } = quoteEditorStore;
  const { uploadingMap } = useShopQuoteUiStore();

  const [btnLoading, setBtnLoading] = useState(false);

  const getButtonDisableMsg = () => {
    const hasItems =
      inquiryOrder &&
      inquiryOrder.code &&
      !!inquiryOrder.items &&
      inquiryOrder.items.length !== 0;

    const hasProcessFinished =
      Object.keys(uploadingMap).length === 0 &&
      Object.keys(processingFileMap).length === 0;

    if (!hasItems) {
      return i18nFormat('Please upload files');
    }

    if (!hasProcessFinished) {
      return i18nFormat('Please wait file process finish');
    }

    return null;
  };

  const checkout = async () => {
    try {
      setBtnLoading(true);

      const isCNC = technology.type === 'CNC';

      if (!inquiryOrder.items || inquiryOrder.items.length === 0) {
        message.error(
          i18nFormat("Can't submit, there is no model file added."),
        );
        return;
      }

      if (
        inquiryOrder.items.find(
          v => !v.printFiles || !v.printFiles[0].printCount,
        )
      ) {
        message.error(
          i18nFormat("Can't submit, some model's print count is less than 1."),
        );
        return;
      }

      if (invalidItemIndexListForRemark.length !== 0) {
        message.error(
          `The ${invalidItemIndexListForRemark.join(
            ',',
          )} file's technical drawing is required.`,
        );
        return;
      }

      if (invalidItemIndexListForModelFileType.length !== 0) {
        message.error(
          `The ${invalidItemIndexListForModelFileType.join(
            ',',
          )} file format is not suitable for ${
            isCNC ? 'CNC machining' : '3D Printing'
          }.`,
        );
        return;
      }

      if (!readOrderReceiveAddress(inquiryOrder)) {
        /** 这边在跳转至 checkout 页面之前先指定订单默认地址 */
        const addressList = await doLoadAddressList();

        if (S.isValidArray(addressList)) {
          /** 没有默认地址则取第一个地址 */
          const addr =
            addressList.find(v => v.defaultAddress) || addressList[0];

          if (addr && inquiryOrder.code) {
            !inquiryOrder.deliver && (inquiryOrder.deliver = new S.Deliver({}));
            inquiryOrder.deliver.deliverAddress = [
              convertReceiveAddressToDeliverAddress(addr),
            ];

            await updateInquiryOrderByCodeForOverseasCustomer(
              inquiryOrder.code,
              inquiryOrder,
            );
          }
        }
      }

      // 跳转到 Checkout 页面，这里复用本地缓存的那个 Order Code 数据
      nav.navigateToCheckoutQuote(inquiryOrder.code, false);
    } catch (e) {
      message.error(
        i18nFormat('Something went wrong while saving the quote for checkout.'),
      );
    } finally {
      setBtnLoading(false);
    }
  };

  const onCheckoutClick = async () => {
    UnionfabEventTracker.getIns().addUserEventRecord({
      typeName: 'begin_checkout',
      value: price || 0,
    });

    if (isDemoUser) {
      signUp(() => checkout());
    } else {
      checkout();
    }
  };

  const buttonDisableMsg = getButtonDisableMsg();
  const btn = (
    <Button
      className={className}
      type="primary"
      style={style}
      icon={
        estPrice && estPrice.requiresManualQuote ? (
          <客服Svg
            style={{
              color: 'white',
              marginRight: 8,
              fill: 'white',
              transform: 'translateY(1px)',
            }}
          />
        ) : (
          <PayCircleOutlined />
        )
      }
      loading={btnLoading || isLoadingEstPrice}
      {...buttonProps}
      disabled={!!buttonDisableMsg}
      onClick={onCheckoutClick}
    >
      {estPrice ? (
        estPrice.subtotalRequiresManualQuote ? (
          i18nFormat('Request a Manual Quote')
        ) : (
          <Tooltip
            title={i18nFormat(
              'This quote does not include shipping fees, you need to add other information to obtain an accurate quote.',
            )}
          >
            {i18nFormat('Continue')}
          </Tooltip>
        )
      ) : (
        i18nFormat('Estimating')
      )}
    </Button>
  );

  return buttonDisableMsg ? (
    <Tooltip title={buttonDisableMsg} style={{ width: '100%' }}>
      {btn}
    </Tooltip>
  ) : (
    btn
  );
};

GoOrderCheckoutBtn.displayName = 'SaveQuoteToDraftBtn';
