import { BaseEntity, Id } from '../../common';

export class InquirySurvey extends BaseEntity<InquirySurvey> {
  tenantId?: Id;

  personId?: Id;

  customerId?: Id;

  type: string;

  data: Record<string, string | string[] | number | number[]>;

  constructor(props?: Partial<InquirySurvey>) {
    super(props);

    Object.assign(this, props);
  }
}
