import { BaseEntity } from '../../common';
import * as U from '../../utils';

export function getCustomerShortName(name: string) {
  return U.replaceAll(
    U.replaceAll(
      U.replaceAll(
        U.replaceAll(name || '匿名客户', '科技有限公司', ''),
        '有限公司',
        '',
      ),
      '分公司',
      '',
    ),
    '上海',
    '',
  )
    .replaceAll('（', '')
    .replaceAll('）', '')
    .replaceAll('(', '')
    .replaceAll(')', '')
    .replaceAll('有限责任公司', '')
    .replaceAll('公司', '')
    .replaceAll('-', '');
}

export class Customer extends BaseEntity<Customer> {
  name: string;

  /** 是否启用 */
  enable: boolean;

  /** 是否支持 */
  support: boolean;

  constructor(props?: Partial<Customer>) {
    super(props);

    Object.assign(this, props);
  }
}
