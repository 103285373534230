import dayjs from 'dayjs';
import { max, min } from 'lodash-es';

import { get, isValidArray } from '../../../utils';
import { ILeadTimeInfo } from '../../inquiry-order/inquiry_order_leadtime';
import {
  getBuiltinTechCategoryList,
  getDefaultTechCategory,
} from '../../material';
import { ShopOrder } from './ShopOrder';
import { ShopOrderItem } from './ShopOrderItem';

export class ShopOrderAccessors {
  /** 是否选项都填完了，可以计算价格了 */
  static isReadyToCalcPrice(order: Partial<ShopOrder>) {
    if (
      isValidArray(order.items || []) &&
      get(order, o => o.requirements.customerSelections.delivery.expressId)
    ) {
      return true;
    }

    return false;
  }

  /** 判断是否仅为 SLM 类型 */
  static isOnlyMetalMaterialType(order: Partial<ShopOrder>) {
    for (const item of order.items) {
      // 如果有材料非 SLM，则返回 false
      if (!item.materialVO.materialType.name.toUpperCase().includes('SLM')) {
        return false;
      }
    }
    return true;
  }

  /** 判断是否仅为 SLA 类型 */
  static isOnlySlaMaterialType(order: Partial<ShopOrder>) {
    for (const item of order.items) {
      // 如果有材料非 SLM，则返回 false
      if (!item.materialVO.materialType.name.toUpperCase().includes('SLA')) {
        return false;
      }
    }
    return true;
  }

  /** 获取当前订单关联的工艺类型 */
  static getCurTechCategory(order: Partial<ShopOrder>) {
    const builtInTechCategoryList = getBuiltinTechCategoryList();

    return (
      builtInTechCategoryList.find(
        tC =>
          get(
            tC,
            tC =>
              tC.name === order.items[0].materialVO.materialType.categoryName,
          ),
        // 没找到则使用全局默认的工艺类别
      ) || getDefaultTechCategory()
    );
  }

  /** 获取交期信息 */
  static getLeadTimeInfo(order: Partial<ShopOrder>) {
    const leadTimeList: number[] = (order.items || []).map(
      (item: ShopOrderItem) => {
        return get(item, i => i.materialSkuVO.leadTime.amount) as number;
      },
    );

    const maxLeadtimeInHours: number = max(leadTimeList) || 0;
    const minLeadtimeInHours = min(leadTimeList) || 0;
    const maxLeadtimeInDays = maxLeadtimeInHours / 24;
    const minLeadtimeInDays = minLeadtimeInHours / 24;
    const shipmentDate = dayjs()
      .add(maxLeadtimeInDays, 'day')
      .format('YYYY-MM-DD');

    return {
      maxLeadtimeInDays,
      minLeadtimeInDays,
      shipmentDate,
    } as ILeadTimeInfo;
  }
}
