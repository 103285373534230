import { getUfcShopComposedApi } from '@unionfab/ufc-shop-commons';
import { useAsyncEffect } from 'ahooks';
import { Result, Spin } from 'antd';
import { useState } from 'react';
import { useQuery } from 'react-query';
import {
  Outlet,
  Route,
  Routes,
  useNavigate,
  useParams,
} from 'react-router-dom';

import {
  getNavbarUserEntryStore,
  loadOrderInShopEditorStore,
} from '../../../stores';
import { useOrderOfferingsLoad } from '../hooks/useOrderOfferingsLoad';
import NewShopQuote from './NewShopQuote';
import { ShopQuoteCheckoutPage } from './NewShopQuote/ShopQuoteCheckoutPage';
import { ShopQuoteCheckoutSuccessPage } from './NewShopQuote/ShopQuoteCheckoutPage/ShopQuoteCheckoutSuccessPage';
import { ShopQuoteEditor } from './NewShopQuote/ShopQuoteEditor';
import { ShopQuotePaymentHub } from './NewShopQuote/ShopQuotePaymentHub';
import { ShopQuotePayBankTransferPage } from './NewShopQuote/ShopQuotePaymentHub/ShopQuotePayBankTransferPage';
import { ShopQuotePaymentSuccessPage } from './NewShopQuote/ShopQuotePaymentHub/ShopQuotePaymentSuccessPage';

const ConnectedShopQuotePageContainer = () => {
  const [isInitializing, setIsInitializing] = useState(true);
  const [error, setError] = useState<Error>(undefined);
  const orderCode = useParams().orderCode;

  useAsyncEffect(async () => {
    try {
      await loadOrderInShopEditorStore(orderCode);
    } catch (error) {
      setError(error as Error);
    } finally {
      setIsInitializing(false);
    }
  }, []);

  if (isInitializing) {
    return (
      <div
        id="ShopQuoteEditor"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin />
      </div>
    );
  }

  if (error) {
    return (
      <div
        id="ShopQuoteEditor"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Result
          status="error"
          title={'Something Wrong: Invalid code or Network error'}
          subTitle={error.message}
        />
      </div>
    );
  }

  return (
    <>
      <Outlet />
    </>
  );
};

const ForkAnonymousOrderPageContainer = () => {
  const navigate = useNavigate();

  const { sessionId, orderCode } = useParams();
  const entryStore = getNavbarUserEntryStore();
  const [error, setError] = useState<Error>(undefined);

  const { isLoading, isFetching } = useQuery(
    ['fork_order', sessionId, orderCode],
    async () => {
      const code = await getUfcShopComposedApi().shopOrderUpdateApi.createShopOrderFromAnonymousOrder(
        sessionId,
        orderCode,
      );
      await loadOrderInShopEditorStore(code);

      return code;
    },
    {
      enabled: !!sessionId && !!orderCode,
      onError: err => setError(err as Error),
      onSuccess: code => {
        /** 匿名订单转移成功后挂载信息收集弹窗 */
        if (entryStore) {
          entryStore.setState({ isAnonymousLoginOrRegister: false });
        }

        navigate(`/new_shop_quote/${code}`);
      },
    },
  );

  if (isLoading || isFetching) {
    return (
      <div
        id="ShopQuoteEditor"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin />
      </div>
    );
  }

  if (error) {
    return (
      <div
        id="ShopQuoteEditor"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Result
          status="error"
          title={'Something Wrong: Invalid code or Network error'}
          subTitle={error.message}
        />
      </div>
    );
  }

  return (
    <>
      <Outlet />
    </>
  );
};

export default () => {
  useOrderOfferingsLoad();

  return (
    <Routes>
      <Route
        path="/fork-order/:sessionId/:orderCode"
        element={<ForkAnonymousOrderPageContainer />}
      />
      <Route path="/:orderCode" element={<ConnectedShopQuotePageContainer />}>
        <Route path="/:orderCode" element={<ShopQuoteEditor />} />
        <Route
          path="/:orderCode/checkout"
          element={<ShopQuoteCheckoutPage />}
        />
        <Route
          path="/:orderCode/checkout-success"
          element={<ShopQuoteCheckoutSuccessPage />}
        />
        <Route
          path="/:orderCode/bank-transfer"
          element={<ShopQuotePayBankTransferPage />}
        />
        <Route path="/:orderCode/payment" element={<ShopQuotePaymentHub />} />
        <Route
          path="/:orderCode/payment-success"
          element={<ShopQuotePaymentSuccessPage />}
        />
      </Route>
      <Route path="*" element={<NewShopQuote />} />
    </Routes>
  );
};
