import { QuestionCircleFilled } from '@ant-design/icons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Tooltip } from 'antd';

export const ShopQuoteTotalTips = () => {
  return (
    <Tooltip
      title={i18nFormat(
        'According to the Trade Terms: DAP, our price excluded import tariffs',
      )}
    >
      <QuestionCircleFilled style={{ color: '#faad14', marginLeft: 4 }} />
    </Tooltip>
  );
};

ShopQuoteTotalTips.displayName = 'ShopQuoteTotalTips';
