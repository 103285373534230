export const APP_IDS = [
  'pdf-viewer',
  'booking-video',
  'invoice',
  'quotation',
  'checkout',
  'payment',
  'new-shop-quote',
  'anonymous-new-shop-quote',
  'new-quote',
  'order-history',
  'discount',
  'referral',
  'rapid-consulting',
  'account-setting',
  'receive-address',
  'mobile',
  'qualityInspection',
  'cancel-subscribe-email',
] as const;

export type AppId = (typeof APP_IDS)[number];

// 默认需要登录用户才能访问
export const ALLOW_ANONYMOUS: AppId[] = ['pdf-viewer', 'booking-video'];

export const ALLOW_DEMO: AppId[] = [
  'anonymous-new-shop-quote',
  'qualityInspection',
  'cancel-subscribe-email',
];

export const ALLOW_DEMO_PATH: string[] = [
  '/anonymous_new_shop_quote',
  '/quality-inspection',
  '/cancel-subscribe-email',
];
