import { genId } from '../../../schema';

let isNewUser = false;

export class TrackerUtils {
  static getIsNewUser() {
    return isNewUser;
  }

  static getUserSessionId() {
    let storedSessionId = localStorage.getItem('uoms_session_id');

    if (!storedSessionId) {
      storedSessionId = genId();
      localStorage.setItem('uoms_session_id', storedSessionId);
      isNewUser = true;
    } else {
      isNewUser = false;
    }

    return storedSessionId;
  }
}
