import { BaseEntity } from '../../../common';
import { InquiryOrder, InquiryOrderItem, ShipQuotations } from '..';
import { InquiryCoupon } from './InquiryCoupon';
import { OrderExpressQuotation } from './InquiryOrderQuotation';

export interface OrderPaymentPrice {
  subtotal: number;
  discount: number;
  shipping?: number;
  total?: number;
  minProductCostFee?: number;
}

export class InquiryPrice extends BaseEntity<InquiryPrice> {
  /** Ref */
  getInquiryOrderRef: () => InquiryOrder;

  minProductCostFee: number;
  /** 后处理费 */
  handleFee: number;
  /** 包装费 */
  packingFee: number;
  /** 物流费 */
  postage: number;
  /** 附加费 */
  surcharge: number;
  surchargeRemark: string;
  /** 税率，提交给后台的时候需要乘以 10 */
  taxRate: number;
  /** 材料费 */
  materialFee: number;
  /** 总价格，来自于后端 */
  totalPriceWithTax: number;
  /** 不含税总价格，来自于后端 */
  totalPriceWithoutTax: number;
  /** 包含的子项，由父类传入 */
  items: InquiryOrderItem[];

  /** 合并报价价格信息 */
  itemMergePrice: Record<number, number> = {};

  validateKeysOfPrice: string[];

  /** 回款金额 */
  prePayPrice?: number;

  /**
   * 订单运费报价信息
   *
   * @deprecated 使用 shipExpressQuotations
   */
  shipQuotations?: ShipQuotations;
  // 订单各个支持的物流的报价信息
  shipExpressQuotations: OrderExpressQuotation[];

  /** 折扣券 code */
  couponCode?: string;

  /** 折扣金额 */
  discountAmount?: number;

  coupon?: InquiryCoupon;

  constructor(
    props?: Partial<InquiryPrice> & { getInquiryOrderRef: () => InquiryOrder },
  ) {
    super(props);

    Object.assign(this, props);

    this.getInquiryOrderRef = props.getInquiryOrderRef;

    if (!this.validateKeysOfPrice) {
      this.validateKeysOfPrice = [];
    }

    if (!this.getInquiryOrderRef) {
      console.error(
        '>>>InquiryPrintFile>>>constructor>>>InquiryPrice is undefined',
      );
    }
  }
}
