import { isNil } from 'lodash-es';

import * as S from '../../../../schema';
import {
  getTenantIdFromGConfig,
  getUfcShopComposedApi,
} from '../../../../singleton-ins';
import { updateFileAttr } from '../../common';

export const getOrderDeliverErrorString = {
  ORDER_DELIVER_0001: '发货错误',
  ORDER_DELIVER_0002: '超出最大可发货数量',
  ORDER_DELIVER_0003: '未找到发货订单',
  ORDER_DELIVER_0004: '发货方式为空',
  ORDER_DELIVER_0005: '未上传合同',
};

/** 将 InquiryOrder 转化为所需要的请求参数 */
export function mapInquiryOrderToRequestParams(
  inquiryOrder: Partial<S.InquiryOrder>,
) {
  // 将 inquiryOrder 转化为接口所需要的数据结构
  const params: Record<string, any> = {};

  const { deliver, safeTime } = inquiryOrder;

  if (inquiryOrder.type === 'PROOF_ORDER') {
    params.proofApplicationFileIds = inquiryOrder.proofApplicationFileIds || [];
  }

  if (!inquiryOrder.source || inquiryOrder.source === 'UNKNOWN') {
    inquiryOrder.source = 'SITE_UNIONFAB';
  }

  params.currency = inquiryOrder.currency;
  params.sourceOrderCode = inquiryOrder.sourceOrderCode;
  params.type = inquiryOrder.type;
  params.source = inquiryOrder.source || 'INTERNAL';
  params.customerId = inquiryOrder.customerId;
  params.urgent = inquiryOrder.urgent; // 是否是加急单
  params.isSynergy = inquiryOrder.isSynergy; // 是否为外协单
  if (inquiryOrder?.parentOrderId) {
    params.parentOrderId = inquiryOrder.parentOrderId;
  }
  if (inquiryOrder.autoQuoted) {
    // 如果是自动报价，则传入到对应参数
    params.autoQuote = inquiryOrder.autoQuoted;
  }
  params.attr = inquiryOrder.attr;
  // 订单名称
  params.name =
    `${S.formatDateByCustom(inquiryOrder.createdAt, 'MM/DD')}-${
      inquiryOrder.deliver ? inquiryOrder.deliver.customerName : ''
    }` || '';
  // 订单备注
  params.remark = inquiryOrder.remark || '';
  params.remarkFileIds = inquiryOrder.remarkFileIds;
  // 租户ID
  params.tenantId = getTenantIdFromGConfig();
  params.safeTime = safeTime;
  params.deliver = isNil(deliver)
    ? null
    : {
        ...deliver,
        deliverAddress: (deliver.deliverAddress || [])
          .filter(d => d.isSelected)
          .map(a => ({
            ...a,
            expressNumber: S.isValidArray(a.expressNumber)
              ? a.expressNumber.join('/')
              : null,
          })),
      };

  // 仅当外协单，才会填报外协属性
  params.synergy = S.get(inquiryOrder, i => i.isSynergy)
    ? {
        supplier: S.get(inquiryOrder, i => i.synergy.supplier),
        representativeName: S.get(
          inquiryOrder,
          i => i.synergy.representativeName,
        ),
        weight: S.get(inquiryOrder, i => i.synergy.weight),
        totalAmount: S.get(inquiryOrder, i => i.synergy.totalAmount),
      }
    : null;

  params.volumeWeightInKg = S.get(inquiryOrder, i => i.volumeWeightInKg);

  // 仅当条目发生变化时候，才传入条目信息
  if (
    inquiryOrder.stashedChanges.includes('ITEM') ||
    inquiryOrder.isDirty ||
    inquiryOrder?.isCreateChild
  ) {
    // 订单子项
    const items = (inquiryOrder.items || []).map(i => {
      const printFiles = (i.printFiles || []).map(p => {
        if (!p.volume) {
          // 如果存在为空的体积，直接提示报错
          console.error('存在体积为空的条目，请截图反馈给研发人员');
        }

        return {
          id: p.isDraft ? undefined : p.id,
          fileId: p.modelFile.id,
          volume: Math.abs(p.volume),
          count: p.printCount,
          lengthUnit: p.lengthUnit,
          partCountPerFile: p.partCountPerFile,
          preViewId: p.previewFileId,
          parentItemId: inquiryOrder?.isCreateChild
            ? p.parentItemId
            : undefined,
          handle: {
            method: S.get(p, i => i.handle.method, []),
            desc: S.get(p, i => i.handle.desc) || '',
            desc1: S.get(p, i => i.handle.desc1) || '',
          },
          enabledPrint: true,
          supportVolume: Math.abs(p.supportVolume),
          nutCount: p?.nutCount,
          punchCount: p.punchCount,
        };
      });

      return {
        id: i.isDraft ? undefined : i.id,
        printFiles,
        remark: i.remark,
        remarkFileIds: i.remarkFileIds,
        spuId: i.materialSpu.id,
      };
    });

    params.items = items;
  }

  return params;
}

/**
 * 更新询价单，目前更新询价单会分为两步：
 * - 更新或创建条目
 * - 更新报价，对于报价的更新必须依赖于条目的 ID
 */
export async function updateInquiryOrderByCode(
  code: string,
  inquiryOrder: S.InquiryOrder,
) {
  if (!inquiryOrder) {
    return false;
  }

  let status;

  const hasChangesExceptQuotation = S.get(
    inquiryOrder,
    i => i.stashedChanges.filter(c => c !== 'QUOTATION').length > 0,
  );

  if (hasChangesExceptQuotation || inquiryOrder.isDirty) {
    // 判断是否有除了报价之外的更新，如果有的话则更新条目信息
    const params = mapInquiryOrderToRequestParams(inquiryOrder);

    // 更新条目时候，也设置为报价中
    params.isQuoting = true;

    params.submitOnCustomerUpdate = false;

    const { status } =
      (await getUfcShopComposedApi().umiRequestCompatibleApi.postUmi<{
        status: string;
      }>(`/customer/order/${code}`, {
        data: params,
      })) || {};

    // 判断是否需要更新订单的报价信息
    if (status !== 'ok') {
      return false;
    }
  }

  // 这里需要针对模型中的数目与条目中的零件数不一致的进行修正，保证两边数据同步
  syncInquiryOrderItemPrintFileToModelAttr(inquiryOrder);

  return status === 'ok';
}

/** 同步更新 InquiryOrder 条目中的数目与模型中的数目 */
export async function syncInquiryOrderItemPrintFileToModelAttr(
  inquiryOrder: S.InquiryOrder,
) {
  for (const p of inquiryOrder.printFiles) {
    // 这里在前端部分地方修改了 InquiryPrintFile 中的信息时候，也会同步修改 ModelFile 中的 Attr
    // 但是会将其标志为 true，因此也需要根据该属性进行判断是否要更新
    let hasModified = p.modelFile.attr.isDirtyVO;
    const newAttr = {} as S.D3FormatAttr;

    if (p.partCountPerFile && p.partCountPerFile !== newAttr.partsNum) {
      newAttr.partsNum = p.partCountPerFile;
      hasModified = true;
    }

    if (p.volume && p.volume !== newAttr.volume) {
      newAttr.volume = p.volume;
      hasModified = true;
    }

    // 如果进行了修改，则提交到服务端
    if (hasModified) {
      await updateFileAttr(p.modelFile.id, {
        ...newAttr,
      });
    }
  }
}

/** 订单评价 */
export async function inquiryOrderEvaluation(
  tenantId: S.Id,
  orderCode: string,
  evaluation: Record<string, any>,
) {
  const {
    status,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.postUmi<{
    status: string;
  }>(`/inquiry_order/${orderCode}/evaluation`, {
    data: evaluation,
    params: { tenantId },
  });

  return status === 'ok';
}
