import { FormInstance } from 'antd';
import { create } from 'zustand';

type BoolFunc = (b: boolean) => void;

interface Forms {
  [key: string]: FormInstance<any>;
}

interface DrawerTriggers {
  [key: string]: BoolFunc | null;
}

interface AppliedRequired {
  [key: string]: boolean | null;
}

interface CNCFormStore {
  form: Forms;
  setForm: (id: string, form: FormInstance<any>) => void;
  /** 更新了 cnc form，必须要点一下 apply，不然不给继续下单的操作 */
  appliedRequiredAfterUpdating: AppliedRequired;
  setAppliedRequired: (i: string, b: boolean) => void;
  /** 打开 cnc 面板的函数，`drawerTrigger(true)` 则打开，需要先在面板所在的组件把 set 函数传给 drawerTrigger */
  drawerTrigger: DrawerTriggers;
  /** 在面板所在的组件把 set 函数传给 drawerTrigger */
  initDrawerTrigger: (id: string, f: BoolFunc) => void;
  /** 是否打开了 cnc 表单的抽屉 */
  openedDrawer: boolean;
  setDrawer: (o: boolean) => void;
}

/** 用于保存 cnc 表单的 antd 管理的 form 信息，用于在其它地方调用 validateFields，验证是否满足 */
export const useCNCFormStore = create<CNCFormStore>(set => ({
  form: null,
  setForm: (id, form) => set(s => ({ form: { ...s.form, [id]: form } })),
  appliedRequiredAfterUpdating: {},
  setAppliedRequired: (id, r) =>
    set(s => ({
      appliedRequiredAfterUpdating: {
        ...s.appliedRequiredAfterUpdating,
        [id]: r,
      },
    })),
  drawerTrigger: null,
  initDrawerTrigger: (id, f) =>
    set(s => ({ drawerTrigger: { ...s.drawerTrigger, [id]: f } })),
  openedDrawer: false,
  setDrawer: o => set({ openedDrawer: o }),
}));
