import { Checkbox, Form, Space, Typography } from 'antd';

import {
  getUseShopQuoteStoreByCode,
  i18nFormat,
  ShopOrderQuoteStoreModifiers,
} from '../../../../../../../../../ufc-shop-commons/src';
import useCNCOptions from '../../../../../../../../../ufc-shop-commons/src/stores/quote/shop-order-quote/useCNCOptions';
import { ShopQuoteItemNotesProps } from '../ShopQuoteItemNotes';

export default function ShopQuoteItemInspection({
  orderCode,
  itemId,
}: ShopQuoteItemNotesProps) {
  const INSPECTION = useCNCOptions(s => s.options).inspection || [];
  const onChange = (val: number[]) => {
    ShopOrderQuoteStoreModifiers.updateOrderDraft(orderCode, o => {
      const item = o.items.find(i => i.id === itemId);
      item.cncRequirements = {
        ...item.cncRequirements,
        inspectSelection: val.map(inspectionIdx => INSPECTION[inspectionIdx]),
      };

      if (val.length === 0) delete item.cncRequirements.inspectSelection;
    });
  };

  const item = getUseShopQuoteStoreByCode(orderCode)(s =>
    (s.shopOrderDraft.items || []).find(i => i.id === itemId),
  );
  const defaultInspections = (
    item?.cncRequirements?.inspectSelection || []
  ).map(ins => INSPECTION.findIndex(_ins => _ins.title === ins.title));

  return (
    <Form.Item
      name="inspection"
      label={
        <Typography.Title level={5}>
          {i18nFormat('Inspection')}
        </Typography.Title>
      }
      initialValue={defaultInspections}
    >
      <Checkbox.Group onChange={onChange}>
        <Space direction="vertical">
          {INSPECTION.map((v, idx) => (
            <Checkbox value={idx} key={idx}>
              <div style={{ fontWeight: 500 }}>{i18nFormat(v.title)}</div>
              <div>{i18nFormat(v.desc)}</div>
            </Checkbox>
          ))}
        </Space>
      </Checkbox.Group>
    </Form.Item>
  );
}
