import {
  getOrderShopStatusByInquiryOrderStatus,
  i18nFormat,
  InquiryOrderStatus,
} from '@unionfab/ufc-shop-commons';

export const getNoteText = (s: InquiryOrderStatus): string => {
  if (!s) return '';

  const curStatus = getOrderShopStatusByInquiryOrderStatus(s);

  switch (curStatus) {
    case 'Pre-Processing':
      return i18nFormat('We will produce as planned, please wait patiently.');
    case 'In-Process':
      return i18nFormat(
        'The production of your order has started. We will arrange delivery after the production is completed.',
      );
    case 'To Ship':
      return i18nFormat(
        'Your order has been manufactured and we will check it according to your requirement. The goods will be delivered as soon as everything is ready.',
      );
    case 'Shipped':
      return i18nFormat(
        'The goods have been sent out, please pay attention to the logistics information. If you have any questions, please contact our sales.',
      );
    case 'Completed':
    case 'Canceled':
      return '';
    default:
      return '';
  }
};
