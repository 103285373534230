import { Tooltip } from 'antd';
import { toUpper } from 'lodash-es';
import * as React from 'react';

import styles from './index.module.less';

export const ColorSelect = ({
  value,
  onChange,
}: {
  value?: string;
  onChange?: (value: string) => void;
}) => {
  const [curValue, setCurValue] = React.useState<string | undefined>();

  React.useEffect(() => {
    setCurValue(toUpper(value));
  }, [value]);

  const isWhite = React.useCallback(() => {
    return curValue === '#FFF' || curValue === '#FFFFFF';
  }, [curValue]);

  return (
    <div className={styles.colorSelect}>
      <Tooltip title={toUpper(curValue)}>
        <div
          className={styles.colorBlock}
          style={{
            backgroundColor: curValue,
            borderColor: isWhite() ? '#d8d8d8' : curValue,
            borderWidth: 1,
            borderStyle: 'solid',
          }}
        />
      </Tooltip>
      <div className="color-name">{toUpper(curValue)}</div>
    </div>
  );
};
