import * as S from '../../../../schema';
import { AliyunSlsApmTrackerUtils } from '../../../vendor-apis/aliyun-apm';
import { BaseAxiosApi } from '../../shared';

export class UfcQualityInspectionApi extends BaseAxiosApi {
  /** 查询订单列表 */
  async getOrderQualityInspectionInfo(
    orderCode: string,
    tenantId: string,
  ): Promise<S.QualityInspectionInfo> {
    const request_url = `${this.apiConfig.apiEndpoint}/v2/shop/order/${orderCode}/quality_inspection`;

    return AliyunSlsApmTrackerUtils.getAliyunSlsApmTracker().runAndSendApiRecord(
      {
        request_url,
        request_order_code: orderCode,
        request_params: JSON.stringify({
          tenantId,
          includes: ['imageFile', 'attachmentFile'],
        }),
        api_id: 'get-/v2/shop/order/:code/quality_inspection',
      },
      async () => {
        const { data, extra } = await this.get<
          Partial<S.QualityInspectionInfo>,
          Record<'files', Record<string, S.QualityInspectionFileInfo>>
        >({
          url: request_url,
          params: { tenantId, includes: ['imageFile', 'attachmentFile'] },
        });

        return new S.QualityInspectionInfo({
          ...data,
          filesMap: S.get(extra, e => e.files, {}),
        });
      },
    );
  }
}
