import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import {
  completePaymentOrderOnPaypal,
  createPaymentOrderOnPaypal,
  i18nFormat,
  useInquiryQuoteCheckoutStore,
} from '@unionfab/ufc-shop-commons';
import { message, Modal } from 'antd';
import { useEffect } from 'react';

import styles from './index.module.less';

export interface OrderPayViaPaypalButtonProps {
  saveOrderShipOptionForPayment: (antdMessageKey: string) => Promise<void>;
}

const saveCoupon = (orderCode: string, couponCode?: string) => {
  if (couponCode) {
    window.localStorage.setItem(`coupon-${orderCode}`, couponCode);
  } else {
    window.localStorage.removeItem(`coupon-${orderCode}`);
  }
};

const getCouponCode = (orderCode: string) => {
  return window.localStorage.getItem(`coupon-${orderCode}`);
};

export const OrderPayViaPaypalButton = ({
  saveOrderShipOptionForPayment,
}: OrderPayViaPaypalButtonProps) => {
  const {
    order,
    coupon,
    canPay,
    setOnPay,
    setPaymentResult,
  } = useInquiryQuoteCheckoutStore();

  const couponCode = coupon?.code;
  const orderCode = order?.code;

  const paypalClientId = window.gConfig.PAYPAL_CLIENT;
  const initialPaypalOptions = {
    'client-id': paypalClientId,
    currency: order?.currency,
  };

  useEffect(() => {
    if (orderCode) {
      saveCoupon(orderCode, couponCode);
    }
    return () => {
      if (orderCode) {
        saveCoupon(orderCode, undefined);
      }
    };
  }, [couponCode, orderCode]);

  if (order == null || orderCode == null) {
    console.warn('OrderPayViaPaypalButton: illegal state, order not loaded');
    return <></>;
  }

  const orderId = order.id!;

  const _createOrder = async () => {
    const antdMessageKey = 'create_order';

    await saveOrderShipOptionForPayment(antdMessageKey);

    try {
      const couponCode = getCouponCode(orderCode) || undefined;
      message.loading({
        content: i18nFormat('Creating paypal order'),
        key: antdMessageKey,
      });
      const payment = await createPaymentOrderOnPaypal(orderId, couponCode);
      setOnPay(true);
      if (!payment.payPalOrderId) {
        setPaymentResult({
          success: false,
          failureMessage:
            'Error creating pay order, refresh and try again, or contact our customer service for help',
        });
        return '';
      }
      return payment.payPalOrderId;
    } catch (e) {
      setPaymentResult({
        success: false,
        failureMessage:
          'Error creating pay order, refresh and try again, or contact our customer service for help',
      });
      return '';
    }
  };

  const _onApprove = async (data: {
    billingToken?: string | null;
    facilitatorAccessToken: string;
    orderID: string;
    payerID?: string | null;
    paymentID?: string | null;
    subscriptionID?: string | null;
    authCode?: string | null;
  }) => {
    const paymentRes = await completePaymentOrderOnPaypal(
      orderId,
      data.orderID,
      data.payerID!,
    );
    if (paymentRes.status !== 'ok') {
      setPaymentResult({
        success: false,
        failureMessage:
          'Error completing your payment, please contact our customer service for help.',
      });
    } else {
      setPaymentResult({ success: true });
    }
  };

  return (
    <div className={styles.payPalButton}>
      <PayPalScriptProvider deferLoading={false} options={initialPaypalOptions}>
        <PayPalButtons
          disabled={!canPay}
          className="paypal-button-container"
          style={{ layout: 'vertical' }}
          createOrder={_createOrder}
          onApprove={_onApprove}
          onCancel={() => {
            Modal.confirm({
              title: 'Cancel payment?',
              onOk: () => {
                console.log('cancel');
                setPaymentResult({
                  success: false,
                  failureMessage: i18nFormat('Payment cancelled'),
                });
              },
            });
          }}
        />
      </PayPalScriptProvider>
    </div>
  );
};

export default OrderPayViaPaypalButton;
