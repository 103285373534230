import * as S from '@unionfab/ufc-shop-commons';

import { OrderItemModelPreview } from '../../QuoteOrderItemFields/OrderItemModelPreview';
import {
  OrderItemCount,
  OrderItemModelDimension,
  OrderItemPriceAndLeadTime,
  OrderItemTitle,
} from '../common';
import styles from './index.module.less';

export const OrderItemSummary = ({
  orderItem,
}: {
  orderItem: S.InquiryOrderItem;
}) => {
  return (
    <div className={styles.orderItemSummary}>
      <OrderItemTitle orderItem={orderItem} />
      <div className={styles.orderItemSummaryDetails}>
        <OrderItemModelPreview orderItem={orderItem} />

        <div className={styles.orderItemSummaryParams}>
          <OrderItemModelDimension orderItem={orderItem} showLabel={false} />
          <OrderItemCount orderItem={orderItem} showLabel={true} />
          <OrderItemPriceAndLeadTime orderItem={orderItem} />
        </div>
      </div>
    </div>
  );
};
