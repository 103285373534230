import {
  decodeAccessToken,
  getUfcShopComposedApi,
  UfcShopTokenUtils,
} from '@unionfab/ufc-shop-commons';

import {
  RecoveryEmailType,
  sendRecoveryCode,
  UnionfabEventTracker,
} from '../../apis';

export async function loginOrdinary() {
  const AnonymousAccessToken = await getUfcShopComposedApi().ufcShopAuthApi.getAnonymousAccessToken();

  if (AnonymousAccessToken) {
    UfcShopTokenUtils.setToken(AnonymousAccessToken);

    // 登录完毕记录事件
    UnionfabEventTracker.getIns().addUserEventRecord({
      typeName: 'newperson_login',
    });

    const user = decodeAccessToken(UfcShopTokenUtils.getAccessToken());

    return user;
  }

  // const {
  //   idToken,
  //   customerIdentity,
  // } = await getUfcShopComposedApi().ufcShopAuthApi.getIdToken({
  //   type: 'ANONYMOUS',
  // });

  // if (idToken && isValid(customerIdentity)) {
  //   const accessToken = await getUfcShopComposedApi().ufcShopAuthApi.getAccessToken(
  //     idToken,
  //     customerIdentity.id,
  //   );

  //   UfcShopTokenUtils.setIdToken(idToken);

  //   if (accessToken) {
  //     UfcShopTokenUtils.setToken(accessToken);

  //     // 登录完毕记录事件
  //     UnionfabEventTracker.getIns().addUserEventRecord({
  //       typeName: 'newperson_login',
  //     });

  //     const user = decodeAccessToken(UfcShopTokenUtils.getAccessToken());

  //     return user;
  //   }
  // }
}

export async function sendRecoveryEmail(data: RecoveryEmailType) {
  return await sendRecoveryCode(data);
}
