import { AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders } from 'axios';

import * as S from '../../../schema';
import { CustomRequestConfig, ResponseError } from './RequestConfig';

export interface NoticeParams {
  type: 'success' | 'error' | 'info';
  title: string;
  content: string;
}

export interface ApiErrorContext {
  requestConfig?: CustomRequestConfig;
  axiosRequestConfig?: AxiosRequestConfig;
  error?: ResponseError;
  rawError?: Error;
}

export enum HttpMethods {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete',
  PATCH = 'patch',
}

/** Api 配置参数 */
export class UfcShopApiConfig extends S.BaseEntity<UfcShopApiConfig> {
  /** 在 UfcShop 背景下，tenantId 是必传参数 */
  tenantId?: S.Id;

  headers?: Record<'Authorization' | string, string> | AxiosRequestHeaders = {};

  /** 数据端口 */
  apiEndpoint?: string;

  /** 等待时长 */
  timeout?: number;

  /** 运行时 */
  axiosAgent?: AxiosInstance;
  quickAxiosAgent?: AxiosInstance;
  /** OSS 请求参数 */
  ossProcess?: string;
  /** 业务领域 */
  bizName?: string;

  /** 回调函数 */
  onMessage?: (message: NoticeParams) => void;
  onError?: (errorContext: ApiErrorContext) => void;
  /** 进度提示 */
  onProgress?: (progress: number) => void;

  constructor(data: Partial<UfcShopApiConfig> = {}) {
    super(data);

    Object.assign(this, data);
  }
}
