import { DeleteOutlined, PaperClipOutlined } from '@ant-design/icons';
import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Button, message, Spin, Typography } from 'antd';
import { UploadChangeParam } from 'antd/es/upload/interface';
import cn from 'classnames';
import dayjs from 'dayjs';
import { compact } from 'lodash';
import { memo, useEffect, useMemo, useState } from 'react';

import { RemarkUploader } from '@/features/new_quote/components/uploader/RemarkUploader';
import { ControlledUploadFile } from '@/features/new_quote/components/uploader/RemarkUploader';
import { useGlobalStore } from '@/stores';

import styles from './index.module.less';

export interface TechDrawingUploaderProps {
  onFinishSolely: (
    remarkIds: S.Id[],
    fileInfos?: Record<'name' | 'id', string>[],
  ) => void;
  onDelete: (remarkId: S.Id) => void;
  isRequired: boolean;
  children?: React.ReactNode;
  uploaderClassName?: string;
  showUploadList?: boolean;
  /** 是否是用在 cnc 工艺上传，如果是，则提示文案不同 */
  isCNCTip?: boolean;
  fileList?: ControlledUploadFile[];
  onChange?: (info: UploadChangeParam) => void;
}

export const TechDrawingUploader = memo(
  ({
    onFinishSolely,
    onDelete,
    isRequired,
    children,
    uploaderClassName,
    showUploadList = true,
    isCNCTip = false,
    fileList,
    onChange,
  }: TechDrawingUploaderProps) => {
    const [uploadFiles, setFiles] = useState<any[]>(fileList);

    const [loading, toggleLoading] = useState<boolean>(false);
    const { fileStoreAuth, loadFileStoreAuth } = useGlobalStore();

    useEffect(() => {
      setFiles(fileList);
    }, [fileList]);

    useEffect(() => {
      if (!fileStoreAuth) {
        loadFileStoreAuth();
      }
    }, []);

    const beforeUpload = () => {
      const isExpried =
        !fileStoreAuth || dayjs(fileStoreAuth.expiresAt).isBefore(dayjs());

      if (isExpried) {
        message.warning(
          i18nFormat(
            'The file store auth has expired. Please try again later.',
          ),
        );

        useGlobalStore.setState({ fileStoreAuth: undefined });

        loadFileStoreAuth();

        return false;
      }

      return true;
    };

    const FileUploader = (
      <RemarkUploader
        className={cn('tech-drawing-uploader', uploaderClassName)}
        type="OTHER"
        disabled={!fileStoreAuth}
        beforeUpload={beforeUpload}
        label="Uploader remark files."
        storeAuth={fileStoreAuth}
        fileList={fileList}
        onFinishSolely={fileInfos => {
          const files = compact((fileInfos || []).map(f => f && f.file));
          const fileIds = (fileInfos || []).map(f => f.fileId);

          const partialFileInfos = (fileInfos || []).map(f => ({
            ...f,
            id: f.fileId,
            name: f.file.name,
          }));

          S.isValidArray(files) && setFiles(files);
          !!onFinishSolely && onFinishSolely(fileIds, partialFileInfos);
        }}
        onChange={onChange}
        onUploading={async _loading => {
          if (typeof _loading == 'boolean' && _loading !== loading) {
            if (!_loading) await S.sleep(1000);

            toggleLoading(_loading);
          }
        }}
      >
        {children ? (
          <Spin spinning={loading || !fileStoreAuth}>{children as any}</Spin>
        ) : (
          <>
            <Button
              type="primary"
              loading={loading}
              className={styles.techDrawingUploadBtn}
            >
              {i18nFormat(
                !fileStoreAuth
                  ? 'Initializing network, please wait.'
                  : 'Upload a technical drawing',
              )}
            </Button>
            {isRequired && (
              <div className={styles.techDrawingTips}>
                *Technical drawing is required
              </div>
            )}
          </>
        )}
      </RemarkUploader>
    );

    const UploadFiles = useMemo(() => {
      if (!S.isValidArray(uploadFiles) || !showUploadList) return <></>;

      return (
        <div className={styles.filesWrapper}>
          {(uploadFiles || []).map((f, id) => (
            <div className={styles.file} key={f.id || id}>
              <PaperClipOutlined />
              <Typography.Paragraph
                ellipsis={{ rows: 1 }}
                className={styles.fileName}
              >
                {f.name}
              </Typography.Paragraph>
              {!loading && (
                <DeleteOutlined
                  onClick={() => {
                    if (onDelete) {
                      onDelete(f.id);
                      setFiles(uploadFiles.filter(({ id }) => id !== f.id));
                    }
                  }}
                />
              )}
            </div>
          ))}
        </div>
      );
    }, [uploadFiles]);

    return (
      <div className={styles.container}>
        <div className={styles.techDrawingUpload}>
          {FileUploader}
          {UploadFiles}
        </div>
        <div
          className={cn(styles.techDrawingContent, {
            [styles.required]: isRequired,
          })}
        >
          {i18nFormat(
            isCNCTip
              ? 'Generally there are marked dimensions according to two-dimensional drawings production, the missing size according to 3D production. The drawing is recommended to include all features, dimensions, materials, quantities, heat treatment, surface treatment, non-toleranced dimensions, and other special requirements of the product.'
              : 'Upload a technical drawing or image identifying additional features or custom requests in the drawings section.(file format: PDF, DXF, images, or CAD file)',
          )}
        </div>
      </div>
    );
  },
);
