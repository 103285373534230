import { InquiryOrder, InquiryOrderSource } from '../../../../schema';
import { createInquiryOrderForOverseaCustomer } from './order_query';

/** 创建订单的 Hooks */
export const createInquiryOrderToWaitSubmit = async (
  draft: InquiryOrder,
  token?: string,
) => {
  if (!draft.items || draft.items.length === 0) {
    throw new Error('cant save empty order');
  }

  for (const item of draft.items || []) {
    item.id = undefined;
    for (const f of item.printFiles || []) {
      f.id = undefined;
    }
  }

  /** 待创建的订单 */
  const toCreate = new InquiryOrder({
    ...draft,
    isQuotating: false,
    source: 'SITE_OVERSEA' as InquiryOrderSource,
    code: undefined,
  });

  (toCreate.items || []).forEach(item => {
    (item.printFiles || []).forEach(p => {
      p.volume = p.finalVolume;
    });
  });

  return await createInquiryOrderForOverseaCustomer(toCreate, token);
};
