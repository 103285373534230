import { ReceiveAddress } from './ReceiveAddress';

export function formatReceiverName(addr?: ReceiveAddress) {
  if (!addr) return;
  const { firstName, lastName } = parseReceiverName(addr);
  if (firstName && lastName) return `${firstName} ${lastName}`;
  if (firstName) return firstName;
  return lastName;
}

export function parseReceiverName(
  addr?: ReceiveAddress,
): {
  firstName?: string;
  lastName?: string;
} {
  if (!addr) return {};
  if (addr.firstName || addr.lastName) {
    return {
      firstName: addr.firstName || addr.lastName,
      lastName: addr.lastName || addr.firstName,
    };
  }
  if (!addr.recipient) {
    return {};
  }
  const s = addr.recipient.replace(/ +/, ' ').split(' ');
  if (s.length === 0) {
    return { firstName: addr.recipient, lastName: addr.recipient };
  } else if (s.length === 1) {
    return { firstName: s[0], lastName: s[0] };
  } else {
    const firstName = s[0];
    const lastName = s.splice(1).join(' ');
    return { firstName, lastName };
  }
}
