import {
  calcPrice,
  compareBySequence,
  i18nFormat,
  MATERIAL_SHOW_ORDERS,
  MATERIAL_TYPE_SHOW_ORDERS,
  OrderItemEstPrice,
} from '@unionfab/ufc-shop-commons';
import * as S from '@unionfab/ufc-shop-commons';
import { Popover, Select } from 'antd';
import { useEffect, useMemo, useState } from 'react';

import { getRecursiveChildText } from '@/commons/utils';
import { useInquiryQuoteEditorStore } from '@/stores';
import { useInquiryMaterialStore } from '@/stores';

import { InquiryMaterialShowCard } from '../../../../components/select/InquiryMaterialSkuSelect/InquiryMaterialShowCard';
import styles from './index.module.less';

const { Option, OptGroup } = Select;

export interface OrderItemMaterialSpuSelectProps {
  orderItem: S.InquiryOrderItem;
}

// 订单条目的 SPU 选择
export const OrderItemMaterialSpuSelect: React.FC<OrderItemMaterialSpuSelectProps> = ({
  orderItem,
}) => {
  const { materials, materialTypes } = useInquiryMaterialStore();
  const {
    updateInquiryOrder,
    technology,
    inquiryOrder,
    orderItemMaterialGroupMap,
  } = useInquiryQuoteEditorStore();

  const selectedMaterialGroup = S.get(
    orderItemMaterialGroupMap,
    o => (!!orderItem.id ? o[orderItem.id] : null),
    null,
  );

  // Select 组件是否拉开受控，根据 focus 和鼠标位置判断
  const [selectOpen, setSelectOpen] = useState<boolean | undefined>(undefined);

  // 条目所有 SPU 预估价
  const [
    spuIdToOrderItemEstPriceMap,
    setSpuIdToOrderItemEstPriceMap,
  ] = useState<Record<string, OrderItemEstPrice>>({});

  const volume = useMemo(() => {
    return orderItem.printFiles[0]?.modelInfo?.d3ModelAttribute?.volume as
      | number
      | undefined;
  }, [orderItem]);

  useEffect(() => {
    if (volume) {
      (async () => {
        const prices = await calcPrice({
          currency: inquiryOrder?.currency,
          extraSpuIds: materials
            .map(v => v.spuIds && v.spuIds[0])
            .filter(v => !!v)
            .map(v => v as S.Id),
          itemQuotationReqs: [
            {
              count: orderItem.printCount,
              handle: (orderItem.printFiles[0]
                .handle as unknown) as S.HandleMethod,
              lengthUnit: orderItem.printFiles[0].lengthUnit,
              fileId: orderItem.printFiles[0].fileId,
              volume: volume,
              spuId: orderItem.spuId,
            },
          ],
        });

        const d: Record<string, OrderItemEstPrice> = {};
        for (const [spuId, price] of Object.entries(
          prices.extraMaterialPrices || {},
        )) {
          const itemPrice = price.itemRespList && price.itemRespList[0];
          if (itemPrice) {
            d[spuId] = {
              id: orderItem.id!,
              perPrice: itemPrice.perPrice,
              price: itemPrice.price,
              count: itemPrice.params.count,
              requiresManualQuote: itemPrice.requiresManualQuote,
            };
          }
        }

        setSpuIdToOrderItemEstPriceMap(d);
      })();
    }
  }, [volume]);

  const technologyMaterials = materials.filter(
    m => m.materialType.categoryName === technology.name,
  );

  const curTechMaterialTypes = materialTypes
    .filter(m => m.categoryName === technology.name)
    .sort((a, b) =>
      compareBySequence(a.name, b.name, MATERIAL_TYPE_SHOW_ORDERS),
    );

  // 当前订单条目选中的 SPU
  const curSelectedSpuMaterialId = S.get(
    orderItem,
    () => orderItem.materialSpu.materialId,
  );

  // 更新当前订单条目的 SPU
  const changeItemMaterialSpu = (spuId?: S.Id) => {
    // 这里默认将订单条目修改为首个 Spu
    updateInquiryOrder('changeItemMaterialSpu', () => {
      if (spuId) {
        orderItem.materialSpu = technologyMaterials.find(
          (m: S.InquiryMaterial) => m.id === spuId,
        )!.spuList[0];
        orderItem.spuId = orderItem.materialSpu?.id as S.Id;
      } else {
        orderItem.materialSpu = undefined;
        orderItem.spuId = undefined;
      }
    });

    // 执行选择后，直接关闭 select 选项
    setSelectOpen(false);
  };

  // 材料默认展示内容（颜色 + 名称）
  const renderMaterialLabel = (m: S.InquiryMaterial) => {
    return (
      <div className={styles.materialOptionItem}>
        <span
          className={styles.materialOptionItemColorSample}
          style={{ backgroundColor: m.color }}
        />
        {m.name}
      </div>
    );
  };

  // 该条目该价格的预估价格
  const _renderEstPrice = (mT: S.InquiryMaterial) => {
    const spu = (mT.spuList || [])[0];
    if (!spu?.id) {
      return <></>;
    }

    const estPrice = spuIdToOrderItemEstPriceMap[spu.id];
    if (!estPrice?.price) {
      return <></>;
    }

    return (
      <div className={styles.estPrice}>
        {i18nFormat('from')}
        <span className={styles.amount}>
          {S.toPriceStringWithOption(estPrice.price, {
            // showing from ... price, it's estimate already.
            rfq: false,
            currency: inquiryOrder!.currency,
          })}
        </span>
      </div>
    );
  };

  const renderMaterialOption = (
    mT: S.InquiryMaterial,
    searchTxt: string,
    key: string,
  ) => {
    const materialLabel = renderMaterialLabel(mT);

    return (
      <Option label={materialLabel} key={key} value={mT.id}>
        <div className={styles.materialSelectorOption}>
          <Popover
            placement="right"
            overlayClassName={styles.materialPopup}
            zIndex={1060}
            content={<InquiryMaterialShowCard material={mT} />}
          >
            <div className={styles.materialSelectorOptionContent}>
              {materialLabel}
            </div>
          </Popover>
          <span hidden={true}>{searchTxt}</span>
        </div>
      </Option>
    );
  };

  // 可选材料 SPU
  const renderMaterialOptions = () =>
    curTechMaterialTypes
      .filter(mT => materials.find(m => m.materialTypeId === mT.id))
      .map(mT => {
        const materials = technologyMaterials
          .filter(m => m.materialTypeId === S.get(mT, () => mT.id))
          .filter(m => m.spuList.length > 0)
          .filter(m =>
            !!selectedMaterialGroup
              ? m.materialGroup === selectedMaterialGroup
              : true,
          )
          .sort((a, b) =>
            compareBySequence(a.name, b.name, MATERIAL_SHOW_ORDERS),
          );

        if (materials.length === 0) {
          return <></>;
        }

        return (
          <OptGroup key={`material-type-${mT.id}`} label={mT.name}>
            {materials.map(m =>
              renderMaterialOption(m, mT.name, `material-type${mT.id}-${m.id}`),
            )}
          </OptGroup>
        );
      })
      .filter(v => !!v);

  return (
    <Select
      className={styles.orderItemMaterialSpuSelect}
      size="small"
      open={selectOpen}
      value={curSelectedSpuMaterialId}
      autoClearSearchValue={false}
      dropdownMatchSelectWidth={false}
      filterOption={(inp, opt) => {
        const text = getRecursiveChildText(opt);
        return !inp || (text || '').toLowerCase().includes(inp.toLowerCase());
      }}
      showSearch={true}
      optionLabelProp="label"
      placeholder="Please select a material"
      onChange={changeItemMaterialSpu}
      onBlur={() => setSelectOpen(false)}
      onClick={() => {
        !selectOpen && setSelectOpen(!selectOpen);
      }}
    >
      {renderMaterialOptions()}
    </Select>
  );
};

OrderItemMaterialSpuSelect.displayName = 'OrderItemMaterialSpuSelect';
