import { useNavigate } from 'react-router-dom';

const openNewTab = (routeWithoutPrefix: string) => {
  const baseUrl =
    window.gConfig.BASE_URL ||
    `${window.location.origin}${window.location.pathname}`;

  let finalUrl: string;
  if (baseUrl.includes('#')) {
    finalUrl = `${baseUrl}${routeWithoutPrefix}`;
  } else {
    finalUrl = `${baseUrl}#${routeWithoutPrefix}`;
  }

  finalUrl = finalUrl.replaceAll('#//', '#/');

  window.open(finalUrl, '__blank');
};

export const useAppNavigate = () => {
  const navigate = useNavigate();

  const navToRapidConsulting = (newTab?: boolean) => {
    const route = `/rapid_consulting`;

    if (newTab) {
      openNewTab(route);
    } else {
      navigate(route);
    }
  };

  const navToDealWithPaymentProblem = () => {
    window.open(
      'https://www.unionfab.com/how-to-deal-with-abnormal-payment-problem',
    );
  };

  const navToQuotes = () => {
    navigate('/orders_history/quotes');
  };

  const navToNewQuote = (options?: { after?: 'register'; code?: string }) => {
    const params = [];
    if (options?.after) {
      params.push(`after=${options.after}`);
    }
    if (options?.code) {
      params.push(`code=${options.code}`);
    }
    const route = params
      ? `/new_shop_quote?${params.join('&')}`
      : `/new_shop_quote`;
    navigate(route);
  };

  const navToRegister = () => {
    navigate('/register');
  };

  const navigateToOrderDetail = (orderCode: string) => {
    const route = `/orders_history/${orderCode}`;
    navigate(route);
  };

  const navToInvoicePage = (
    orderCode: string,
    invoiceType: 'Proforma' | 'Commercial',
    newTab = false,
  ) => {
    if (newTab) {
      const route = `/invoice/${orderCode}?type=${(
        invoiceType || ''
      ).toLocaleLowerCase()}`;
      openNewTab(route);
    } else {
      const route = `/orders_history/invoice/${orderCode}?type=${(
        invoiceType || ''
      ).toLocaleLowerCase()}`;
      navigate(route);
    }
  };

  const navigateToOrderPayment = (orderCode: string, from?: 'checkout') => {
    const route =
      `/payment?orderCode=${orderCode}` + (from ? `&from=${from}` : '');
    navigate(route);
  };

  const navigateToCheckoutQuote = (orderCode: string, newTab: boolean) => {
    if (newTab) {
      const route = `#/checkout?orderCode=${orderCode}`;
      openNewTab(route);
    } else {
      const route = `/checkout?orderCode=${orderCode}`;
      navigate(route);
    }
  };

  const navToBankTransfer = () => {
    navigate('/payment/bank-transfer');
  };

  const navigateToRfqSuccess = (orderCode: string, orderId: string) => {
    const route = `/checkout/success?orderCode=${orderCode}&orderId=${orderId}`;
    navigate(route);
  };

  const navigateToPaymentSuccess = (orderCode: string, orderId: string) => {
    const route = `/payment/success?orderCode=${orderCode}&orderId=${orderId}`;
    navigate(route);
  };

  const navigateToQuoteDetail = (orderCode: string) => {
    const route = `/orders_history/quote/${orderCode}`;
    navigate(route);
  };

  const navigateToPdfPageNewTab = (
    doc: 'privacy_policy' | 'csa' | 'design_spec',
  ) => {
    const route = `/pdfviewer?doc=${doc}`;
    openNewTab(route);
  };

  const navigateToReferral = () => {
    navigate('/discount');
  };

  const navigateToHome = () => {
    navigate('/');
  };

  return {
    navTo: navigate,
    navToQuotes,
    navigateToReferral,
    navigateToHome,
    navToRegister,
    navToNewQuote,
    navToInvoicePage,
    navigateToOrderDetail,
    navigateToCheckoutQuote,
    navigateToOrderPayment,
    navigateToRfqSuccess,
    navToBankTransfer,
    navigateToPaymentSuccess,
    navigateToPdfPageNewTab,
    navigateToQuoteDetail,
    navToRapidConsulting,
    navToDealWithPaymentProblem,
  };
};
