import { Area } from '../../common';
import { BaseTenant } from './BaseTenant';
import { TenantAdminUser } from './TenantAdminUser';

export class Tenant extends BaseTenant {
  name: string;
  companyId: number;

  createdAt: string;
  updatedAt: string;

  areaCode: string;
  area: Area;

  tenantAdminUser: TenantAdminUser;
  utkPrinterCount: number;

  constructor(props?: Partial<Tenant>) {
    super(props);

    Object.assign(this, props);
  }
}
