import { TenantConfigBooleanEnum } from '../../user/tenant/TenantConfig';

/** 用户自定义配置 */
export class UserConfig {
  /** 个人职位 */
  jobPosition: string;

  /** 是否开启消息推送 */
  enableMessageNotice: TenantConfigBooleanEnum = 'true';

  /** 是否开启系统辅助，目前主要是扫码枪监控 */
  enableSystemAssistant: TenantConfigBooleanEnum = 'false';

  /** 是否开启模型预览界面简约模式 */
  enableModelPreviewSimplicity: TenantConfigBooleanEnum = 'true';

  /** 模型预览界面的背景色 */
  modelPreviewBackgroundColor: string;

  /** 需要被隐藏的模块 */
  userHiddenModules: string;

  /** 首选的是同步上传还是异步上传 */
  modelProcessType: 'async' | 'sync' = 'sync';

  constructor(c: Partial<UserConfig>) {
    Object.assign(this, c);
  }
}

export type UserConfigKey = keyof UserConfig;

export const UserConfigKeyDescMap: Record<UserConfigKey, string> = {
  jobPosition: '个人职位',
  enableSystemAssistant: '是否开启系统助手',
  enableMessageNotice: '是否开启消息推送',
  modelPreviewBackgroundColor: '模型预览界面的背景色',
  enableModelPreviewSimplicity: '模型预览界面的简约模式开关',
  userHiddenModules: '需要对某个客户被隐藏的模块',
  modelProcessType: '模型处理类型',
};

export const UserConfigKeys = Object.keys(UserConfigKeyDescMap);
