import { compact, fill } from 'lodash-es';

import { BaseEntity, File, Id } from '../../common';
import { isValid, isValidArray } from '../../utils';
import { InquiryOrder } from './InquiryOrder';

/** 价格限制类型 */
export type InquiryOrderPriceConstraintType =
  /** 文件起步价 */
  | 'FILE_MIN_PRICE'
  /** 材料最低价 */
  | 'MATERIAL_MIN_PRICE'
  /** 文件指导价 */
  | 'FILE_GUIDE_PRICE';

export interface InquiryOrderPriceConstraint {
  modelFileId?: Id; // 使用文件名作为关联项
  type: InquiryOrderPriceConstraintType; // 约束类型
  desc: string; // 描述，材料名
  actualPrice: number; // 实际价格
  expectPrice: number; // 预期的价格
}

/** 后处理单分组关联的情况 */
export interface PostHandleChecklistInquiryOrderSubGroup {
  /** 分组名 */
  groupName: string;
  /** 打印机信息，可先不用 */
  printerId: Id;
  printerName: string;
  items: PostHandleChecklistInquiryOrderSubGroupItem[];
}

export interface PostHandleChecklistInquiryOrderSubGroupItem {
  /** 关联的 itemId */
  orderItemId: Id;

  /**
   * 包含在该分组中关联的零件数目
   * - 注意这里单位是 套
   * partsCount 一般用于表示 件，但是这里为了兼容旧数据而表示 套
   */
  partsCount: number;
}

export class InquiryOrderAttr extends BaseEntity<InquiryOrderAttr> {
  getInquiryOrderRef: () => InquiryOrder;

  priceConstraints: InquiryOrderPriceConstraint[];
  /** 开票信息 */
  invoiceInfo?: InquiryOrderInvoiceInfo;

  /** @fields 自定义发货单相关 */
  /** 该订单是否需要打印发货单 */
  printInvoice: boolean;
  /** 发货单是否含有 logo */
  invoiceWithLogo: boolean;
  /** 用于报价单编辑中自定义的模型文件列表 */
  modelFilesExcelData: string;
  /** 自定义条款说明 */
  customStipulation: string;
  /** 订单后处理报价的明细 */
  inquiryOrderHandleFeeDetail: Record<
    string,
    {
      /** 工时 */
      workhour: number;
      /** 工时费 */
      pricePerHour: number;
      /** 一口价 */
      fixedPrice: number;
    }
  >;

  /** 订单的评价 */
  orderEvaluation: {
    param1: string;
    param2: string;
    param3: string;
    param4: string;
    desc: string;
  };

  // 操作人信息
  operator: string;
  operatorPhoneNumber: string;
  operatorEmail: string;
  orderCloseRemark: string;
  orderCloseRemarkFileIds: Id[];

  /** @fields 生产相关 */
  /** 零件下载人信息 */
  fileHandlerDownloadModelsUserName: string;
  fileHandlerDownloadModelsTime: string;
  /** 后处理单打印人信息 */
  handlerChecklistPrintUserName: string;
  handlerChecklistPrintTime: string;
  postHandleChecklistInquiryOrderSubGroups: PostHandleChecklistInquiryOrderSubGroup[] = [];
  /** 发货单打印人信息 */
  handlerDeliveryChecklistPrintUserName: string;
  handlerDeliveryChecklistPrintTime: string;
  /** 发货单是否需要显示公章 */
  handlerDeliveryChecklistWithCachet: boolean;

  /** 质检图片信息 */
  qcImageIds: Id[];
  qcImages: File[];

  // 重打订单的原始订单
  sourceOrderId?: Id;
  sourceOrderCode?: Id;
  rePrintReason?: string; // 重打原因

  /** 该订单的预处理文件是否已经被数据处理员下载过 */
  hasDownloadPreFileHandleByFileHandler?: boolean;
  /** 该订单的模型文件是否被数据处理员下载过 */
  hasDownloadModelFileByFileHandler?: boolean;

  /** @fields 备注相关 */
  /** 标准化订单要求，存放的是 ID， */
  /** @deprecated */
  standardizedRequirements: string[];
  /** 需要上传附件的备注 */
  requiredAttachmentIdsMap: Record<string, Id[]>;
  // 销售自定义的备注
  requirementRemark: Partial<
    Record<'其他打印要求' | '其他后处理要求' | '其他发货要求' | string, string>
  >;

  /** 收货人电子签字图片 */
  receiverSignImgUrl?: string;

  get requirementRemarkStr() {
    if (isValidArray(this.standardizedRequirements)) {
      return compact(
        this.standardizedRequirements.map(k => this.requirementRemark[k]) || [],
      ).join('、');
    }

    return '';
  }

  /** @fields 其他属性 */
  linkedCrossTenantInquiryOrder: {
    tenantId?: Id;
    inquiryOrderId?: Id;
    inquiryOrderCode?: string;
  } = {};

  constructor(props: Partial<InquiryOrderAttr> = {}) {
    super(props);

    Object.assign(this, props);

    // 兼容旧数据 其他要求默认为 其他打印要求
    if (
      isValidArray(this.standardizedRequirements) &&
      this.standardizedRequirements.includes('其他要求')
    ) {
      const index = this.standardizedRequirements.findIndex(
        r => r === '其他要求',
      );

      this.standardizedRequirements = fill(
        this.standardizedRequirements,
        '其他打印要求',
        index,
        index + 1,
      );
    }

    if (!this.inquiryOrderHandleFeeDetail) {
      this.inquiryOrderHandleFeeDetail = {};
    }

    if (!this.standardizedRequirements) {
      this.standardizedRequirements = [];
    }

    if (!this.requirementRemark) {
      this.requirementRemark = {};
    } else if (
      this.requirementRemark &&
      typeof this.requirementRemark === 'string'
    ) {
      this.requirementRemark = {
        printRemark: this.requirementRemark,
      };
    }

    if (!isValid(this.requiredAttachmentIdsMap)) {
      this.requiredAttachmentIdsMap = {};
    }

    if (typeof this.printInvoice === 'undefined') {
      this.printInvoice = true;
    }

    if (typeof this.invoiceWithLogo === 'undefined') {
      this.invoiceWithLogo = true;
    }
  }
}

/** 订单发票信息 */
export class InquiryOrderInvoiceInfo extends BaseEntity<InquiryOrderInvoiceInfo> {
  /** 是否开票 */
  hasInvioce?: boolean;

  /** 发票类型 */
  type?: '增值税普通发票' | '增值税专用发票';

  /** 发票内容 */
  content?: '明细' | string;

  /** 抬头类型 */
  titleType?: '个人' | '企业';

  /** 抬头 */
  title?: string;

  /** 纳税人识别号 */
  taxpayerIdentityNumber?: string;

  /** 注册地址 */
  address?: string;

  /** 联系方式 */
  phoneNum?: string;

  /** 开户银行 */
  depositBank?: string;

  /** 银行账号 */
  bankAccount?: string;

  /** 邮箱地址 */
  email?: string;

  constructor(
    props: Partial<InquiryOrderInvoiceInfo> & {
      getInquiryOrderRef?: () => InquiryOrder;
    } = {},
  ) {
    super(props);

    Object.assign(this, props);
  }
}
