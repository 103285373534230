import * as S from '@unionfab/ufc-shop-commons';

import { OrderItemFinishSelect } from '@/features/new_quote/routes/NewQuote/QuoteEditor/InquiryQuoteItemFinishSelect';
import { useInquiryQuoteEditorStore } from '@/stores';

export const OrderItemFinish = ({
  orderItem,
}: {
  orderItem: S.InquiryOrderItem;
}) => {
  const { editingFileIds } = useInquiryQuoteEditorStore();
  const editing = !!editingFileIds.find(
    v => v === orderItem.printFiles[0].fileId,
  );
  return editing ? (
    <OrderItemFinishSelect orderItem={orderItem} />
  ) : (
    <span>
      {S.get(orderItem, () => orderItem.printFiles[0].handle.method[0]) ||
        'Standard'}
    </span>
  );
};
