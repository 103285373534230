export const ImagePathMap = {
  LOGO_UNIONFAB_WHITE: '/logo/logo-w.png',
  LOGO_UNIONFAB: '/unionfab.png',
  LOGO_FACEBOOK: '/logo/facebook.svg',
  LOGO_GOOGLE: '/logo/google.svg',
  LOGO_LINKEDIN: '/logo/linkedin.svg',
  LOGO_TWITTER: '/logo/twitter.svg',
  Auth_BG: '/background/auth-background.png',
  USER_ICON: '/icon/user.svg',
  PSW_ICON: '/icon/password.svg',
  DEFAULT_UPLOAD_THUMBNAIL: '/thumbnail/default-upload.png',
  MODEL_PREVIEW_THUMBNAIL: '/thumbnail/model-preview.png.png',
  MENU_ICON_ADDRESS: '/icon/menu_address.svg',
  MENU_ICON_INBOX: '/icon/menu_inbox.svg',
  MENU_ICON_NEW_QUOTE: '/icon/menu_new_quote.svg',
  MENU_ICON_ORDER_HISTORY: '/icon/menu_order_history.svg',
  MENU_ICON_QUOTE_HISTORY: '/icon/menu_quote_history.svg',
  QUOTE_SERVICE_ICON_CNC: '/icon/service-icon-cnc.png',
  QUOTE_SERVICE_ICON_3D_PRINTING: '/icon/service-icon-3dprinting.png',
  QUOTE_UPLOAD_ICON: '/icon/fileUploadIcon.svg',
  QUOTE_MODEL_AVATAR_SEARCH_ICON: '/icon/searchOutline.svg',
  QUOTE_ORDER_ITEM_HANDLER_DELETE: '/icon/delete.svg',
  QUOTE_ORDER_ITEM_HANDLER_MODIFY: './svgs/modify.svg',
  QUOTE_ORDER_ITEM_HANDLER_CLOSE: './svgs/close.svg',
  QUOTE_ORDER_ITEM_HANDLER_WARNING: '/icon/warning.svg',
  QUOTE_ORDER_ITEM_HANDLER_BULB: '/icon/bulb.svg',
  客服: '/icon/客服.svg',

  GIVEAWAY_BANNER_BALLOON: '/giveaway/bf-balloon.png',
  GIVEAWAY_BANNER_BG: '/giveaway/bf-bg.svg',
  GIVEAWAY_BANNER_BF: '/giveaway/black-friday.png',

  GIVEAWAY_SERVICE_PRINTING: '/giveaway/printing.png',
  GIVEAWAY_SERVICE_CNC: '/giveaway/cnc.png',
  GIVEAWAY_SERVICE_CASTING: '/giveaway/casting.png',
  GIVEAWAY_SERVICE_FINISHING: '/giveaway/finishing.png',

  GIVEAWAY_SERVICE_DSC1: '/giveaway/dsc1.png',
  GIVEAWAY_SERVICE_DSC2: '/giveaway/dsc2.png',
  GIVEAWAY_SERVICE_SLA: '/giveaway/sla.png',
  GIVEAWAY_SERVICE_TUBE: '/giveaway/tube.png',
  GIVEAWAY_SERVICE_SHELL: '/giveaway/shell.png',
  GIVEAWAY_SERVICE_SOHUCS: '/giveaway/sohucs.png',
  GIVEAWAY_SERVICE_CAR: '/giveaway/car.png',
  GIVEAWAY_SERVICE_POT: '/giveaway/pot.png',

  GIVEAWAY_SERVICE_MODAL_BG: '/giveaway/pop_bg.png',
  GIVEAWAY_SERVICE_MODAL_LOGO: '/giveaway/congratulation.svg',

  BOOKING_VIDEO_CO_SCHNEIDER: '/logo/schneider.png',
  BOOKING_VIDEO_CO_SIEMENS: '/logo/siemens.png',
  BOOKING_VIDEO_CO_HUAWEI: '/logo/huawei.png',
  BOOKING_VIDEO_CO_GREE: '/logo/gree.png',
  BOOKING_VIDEO_CO_TESLA: '/logo/tesla.png',
  BOOKING_VIDEO_CO_ADIDAS: '/logo/adidas.png',
  BOOKING_VIDEO_CO_CONGRAZ: '/icon/congraz.png',

  MENU_DISCOUNT: '/icon/icon-menu_discount.sgv',
  MENU_DISCOUNT_DISABLE: '/icon/icon-menu_discount_disable.sgv',
  MY_VIP: '/svgs/MyVIP.sgv',
  REMIND: '/svgs/remind.svg',
};

export type ImagePathMapType = typeof ImagePathMap;
export type ImagePathKey = keyof ImagePathMapType;

const defaultOssUrl = 'https://0.assets.sunionfab.com/ufc-oversea/uoms/assets';

const imageCollection: Partial<ImagePathMapType> = {};
for (const pathKey in ImagePathMap) {
  Object.defineProperty(imageCollection, pathKey, {
    get: () => {
      return `${defaultOssUrl}/image${ImagePathMap[pathKey]}`;
    },
  });
}

export function getBuiltinImageCollection() {
  return imageCollection;
}
