import cn from 'classnames';

import styles from './index.module.less';
import { ShopQuoteItem3DPOptionsEditor } from './ShopQuoteItem3DPOptionsEditor';
import { ShopQuoteItemPreview } from './ShopQuoteItemPreview';

export interface ShopQuoteItemBlockEditorProps {
  className?: string;
  style?: Record<string, string | number>;

  orderCode: string;
  itemId: string;
}

export const ShopQuoteItemBlockEditor = ({
  className,
  style,

  orderCode,
  itemId,
}: ShopQuoteItemBlockEditorProps) => {
  return (
    <div
      id="ShopQuoteItemBlockEditor"
      className={cn(className, styles.container)}
      style={style}
    >
      <ShopQuoteItemPreview orderCode={orderCode} itemId={itemId} />
      <ShopQuoteItem3DPOptionsEditor orderCode={orderCode} itemId={itemId} />
      {/* <ShopQuoteItemQuantity orderCode={orderCode} itemId={itemId} />
      <ShopQuoteItemLeadTime orderCode={orderCode} itemId={itemId} /> */}
    </div>
  );
};

ShopQuoteItemBlockEditor.displayName = 'ShopQuoteItemBlockEditor';
