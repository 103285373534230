import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Spin, Tooltip } from 'antd';

import { useInquiryQuoteEditorStore } from '@/stores';

import styles from './index.module.less';

export const OrderSubtotal = () => {
  const newQuoteStore = useInquiryQuoteEditorStore();
  const { estPrice, isLoadingEstPrice, inquiryOrder } = newQuoteStore;
  const rfq =
    estPrice?.subtotalRequiresManualQuote == null
      ? true
      : estPrice.subtotalRequiresManualQuote;
  const curPrice = estPrice?.subtotal;

  return (
    <div className={styles.orderSubmitorHeader}>
      <span className={styles.orderSubmitorHeaderLeft}>
        {i18nFormat('Subtotal')}
      </span>

      <span className={styles.orderSubmitorHeaderRight}>
        <Spin spinning={isLoadingEstPrice}>
          {curPrice == null ? (
            'Require For Quotation'
          ) : (
            <span className={styles.orderItemPriceDigital}>
              {S.toPriceStringWithOption(curPrice, {
                rfq,
                currency: inquiryOrder!.currency,
              })}
            </span>
          )}

          {!curPrice && (
            <Tooltip
              color={'rgba(248, 153, 2)'}
              overlayInnerStyle={{ width: '250px' }}
              overlayClassName={styles.overlayClass}
              title={i18nFormat(
                'We will complete reviewing your order in 24 hours. (Except special order!)',
              )}
              placement="bottomLeft"
              trigger={['click']}
              defaultVisible={true}
            >
              <img
                className="order-item-header-right-price-bulb"
                src={
                  S.getBuiltinImageCollection().QUOTE_ORDER_ITEM_HANDLER_BULB
                }
                alt=""
                style={{ marginLeft: 8 }}
              />
            </Tooltip>
          )}
        </Spin>
      </span>
    </div>
  );
};
