import { Mutex, wrapSingletonAsyncFunc } from '@m-fe/ts-async';

import * as S from '../../../../schema';
import {
  HandleMethod,
  Id,
  InquiryMaterial,
  isValidArray,
} from '../../../../schema';
import {
  getTenantIdFromGConfig,
  getUfcShopComposedApi,
} from '../../../../singleton-ins';
import { getCacheablePreSignedUrl } from '../../common';

export interface MediaImg {
  name: string;
  fileId: Id;
  file: File;

  categoryName: string;
  materialTypeId: Id;

  material: InquiryMaterial;

  postHandleMethodId: Id;
  postHandleMethod: HandleMethod;

  imageType: 'MATERIAL_POST_HANDLE';
}

export interface SearchImageParams {
  tenantId?: S.Id;
  categoryNames?: string[];
  materialTypeIds?: S.Id[];
  materialIds?: S.Id[];
  postHandleMethodIds?: S.Id[];
  searchText?: string;
}

async function searchMediaImages(
  params: SearchImageParams,
): Promise<MediaImg[]> {
  if (!params.tenantId) {
    params.tenantId = getTenantIdFromGConfig();
  }

  const {
    data,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.postUmi<{
    data: MediaImg[];
  }>(`/customer/order/image/search`, {
    data: params,
  });

  return (data || []).map(d => {
    if (d.postHandleMethod) {
      d.postHandleMethod = new S.HandleMethod(d.postHandleMethod);
    }
    if (d.material) {
      d.material = new S.InquiryMaterial(d.material);
    }
    return d;
  });
}

const searchMediaImagesByPostHandleMethodIdsMutex = new Mutex();
const searchMediaImagesByPostHandleMethodIdsCache: Record<
  string,
  MediaImg
> = {};
export async function searchMediaImagesByPostHandleMethodIds(
  postHandleMethodIds: Id[],
  tenantId: Id,
) {
  const release = await searchMediaImagesByPostHandleMethodIdsMutex.acquire();
  // 判断当前哪些有缓存的
  const mediaImgs: MediaImg[] = [];

  try {
    const uncachedPostHandleMethodIds = [];
    for (const postHandleMethodId of postHandleMethodIds) {
      if (searchMediaImagesByPostHandleMethodIdsCache[postHandleMethodId]) {
        mediaImgs.push(
          searchMediaImagesByPostHandleMethodIdsCache[postHandleMethodId],
        );
      } else {
        uncachedPostHandleMethodIds.push(postHandleMethodId);
      }
    }

    if (isValidArray(uncachedPostHandleMethodIds)) {
      const handleMethodMediaImgs = await searchMediaImages({
        postHandleMethodIds: uncachedPostHandleMethodIds,
        tenantId,
      });

      handleMethodMediaImgs.forEach(
        i =>
          (searchMediaImagesByPostHandleMethodIdsCache[
            i.postHandleMethodId
          ] = i),
      );

      // 如果没获取到，那就是没有，没有的话置为空，避免重复请求
      for (const missedId of uncachedPostHandleMethodIds) {
        if (!searchMediaImagesByPostHandleMethodIdsCache[missedId]) {
          searchMediaImagesByPostHandleMethodIdsCache[
            missedId
          ] = {} as MediaImg;
        }
      }

      mediaImgs.push(...handleMethodMediaImgs);
    }
  } finally {
    release();
  }

  return mediaImgs.filter(i => i.name);
}

const searchMaterialImageUrlsCache = {} as Record<string, string[]>;

/** 搜索材料及其对应后处理的图片 */
export async function searchMaterialImageUrls(
  materialId?: S.Id,
  handleMethodId?: S.Id,
): Promise<string[]> {
  const cacheKey = `${materialId || ''}-${handleMethodId || ''}`;

  if (!searchMaterialImageUrlsCache[cacheKey]) {
    const singletonSearchMaterialImageUrls = wrapSingletonAsyncFunc<string>(
      _searchMaterialImageUrls,
      {
        fnKey: 'searchMaterialImageUrls',
        serialize: () => cacheKey,
      },
    );

    searchMaterialImageUrlsCache[
      cacheKey
    ] = await singletonSearchMaterialImageUrls(materialId, handleMethodId);
  }

  return searchMaterialImageUrlsCache[cacheKey];
}

export async function _searchMaterialImageUrls(
  materialId?: S.Id,
  handleMethodId?: S.Id,
): Promise<string[]> {
  if (materialId == null && handleMethodId == null) {
    return [];
  }

  const params: SearchImageParams = {};

  if (materialId) {
    params.materialIds = [materialId];
  }

  if (handleMethodId) {
    params.postHandleMethodIds = [handleMethodId];
  }

  const images = await searchMediaImages(params);

  const urls = await Promise.all(
    images.map(async v => {
      try {
        return await getCacheablePreSignedUrl(v.fileId);
      } catch (_) {}
    }),
  );
  return urls.filter(v => !!v);
}
