import { LoadingOutlined } from '@ant-design/icons';
import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Select } from 'antd';
import { useEffect } from 'react';

import { useD3ModelMetrics, useInquiryQuoteEditorStore } from '@/stores';

import styles from './index.module.less';

export const OrderItemModelDimension = ({
  orderItem,
  showLabel,
}: {
  orderItem: S.InquiryOrderItem;
  showLabel?: boolean;
}) => {
  showLabel = showLabel == null ? true : showLabel;

  const {
    updateInquiryOrder,
    modelFileMap,
    modelFileD3AttrWatcher,
    removeFromProcessFileMap,
    addToProcessingFileMap,
  } = useInquiryQuoteEditorStore();

  const printFile = orderItem.printFiles[0];
  const fileId = orderItem.modelFiles[0].id;
  const curModel = modelFileMap[fileId] || orderItem.modelFiles[0];
  const curLengthUnit = printFile.lengthUnit;
  const unitStr = S.getLengthUnitDesc(curLengthUnit);

  const {
    sizeEstimateFinished,
    volumeEstimateFinished,
    sizeXStr,
    sizeYStr,
    sizeZStr,
    volumeStr,
    // 如果  modelFileD3AttrWatcher 存在（即 modelFileMap 已经在轮询）这里将不轮询
  } = useD3ModelMetrics(curModel, 5000, !modelFileD3AttrWatcher, 500);

  const changeItemUnit = (unit: string) => {
    updateInquiryOrder(
      'changeOrderItemLengthUnit',
      _ => (orderItem.printFiles[0].lengthUnit = unit),
    );
  };

  useEffect(() => {
    addToProcessingFileMap(fileId);
  }, []);

  useEffect(() => {
    return () => {
      if (sizeEstimateFinished && volumeEstimateFinished) {
        removeFromProcessFileMap(fileId);
      }
    };
  }, [sizeEstimateFinished, volumeEstimateFinished]);

  const itemLengthUnitSelector = (
    <Select
      showArrow
      size="small"
      value={unitStr}
      style={{ marginLeft: 8, width: 65 }}
      onChange={v => changeItemUnit(v)}
      options={S.LengthUnitList.map(i => ({
        label: S.getLengthUnitDesc(i),
        value: i,
      }))}
    />
  );

  return (
    <div className={styles.orderItemDimensionViewer}>
      {showLabel && (
        <span className={styles.label}>{i18nFormat('Dimension')}:</span>
      )}

      {!sizeEstimateFinished || !volumeEstimateFinished ? (
        <LoadingOutlined style={{ marginLeft: '10px' }} />
      ) : (
        <span className={styles.orderItemHeaderRightDimension}>
          <span>{` ${sizeXStr}${unitStr} * ${sizeYStr}${unitStr} * ${sizeZStr}${unitStr}`}</span>
          <span>
            {`  | ${volumeStr}${unitStr}`}
            <sup>3</sup>
          </span>
        </span>
      )}
      {itemLengthUnitSelector}
    </div>
  );
};
