import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Button } from 'antd';
import * as React from 'react';
import { useState } from 'react';

import {
  ReceiveAddressEditCard,
  ReceiveAddressEditCardProps,
} from '@/features/receive_address/components';

import styles from './index.module.less';

export interface DeliverToAddressCardProps extends ReceiveAddressEditCardProps {
  deliverToAddress: (addr: S.ReceiveAddress) => Promise<void>;
}

export const DeliverToAddressCard = (props: DeliverToAddressCardProps) => {
  const { deliverToAddress, address } = props;

  const [loading, setLoading] = useState(false);

  const _deliverToAddress = async () => {
    try {
      setLoading(true);
      await deliverToAddress(address);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.deliverToAddressCard}>
      <ReceiveAddressEditCard {...props} />
      <Button onClick={_deliverToAddress} loading={loading} type="primary">
        {i18nFormat('Deliver to this address')}
      </Button>
    </div>
  );
};
