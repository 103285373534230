import {
  get,
  i18nFormat,
  InquiryOrderStatus,
  OrderStatusTitleMap,
  OverseaOrderStatus,
  PLACED_ORDER_STATUS_LIST,
  ShopOrderStatusToInquiryOrderStatus,
} from '@unionfab/ufc-shop-commons';
import { Tabs } from 'antd';
import cn from 'classnames';
import { sum } from 'lodash';

import styles from './index.module.less';

interface StatusTabsProps {
  activeKey: OverseaOrderStatus;
  statusList?: OverseaOrderStatus[];
  statusStats: Record<InquiryOrderStatus, number> | undefined;
  onTabsChange: (activeKey: string) => void;
}

export const StatusTabs = ({
  statusStats,
  activeKey,
  statusList = PLACED_ORDER_STATUS_LIST,
  onTabsChange,
}: StatusTabsProps) => {
  const items = statusList.map(status => {
    const isActive = activeKey === status;

    /** 获取对应的订单国内平台状态 */
    const targetStatusList = ShopOrderStatusToInquiryOrderStatus[status];

    const count = sum(
      (targetStatusList || []).map(r => get(statusStats, s => s[r], 0)),
    );
    const className = cn(styles.tab, isActive && styles.activeTab);

    const tab = (
      <div className={`${className}`}>
        <span>{i18nFormat(OrderStatusTitleMap[status])}</span>
        <span>{count}</span>
      </div>
    );

    return { key: status, label: tab };
  });

  return <Tabs activeKey={activeKey} onChange={onTabsChange} items={items} />;
};
