import { BaseEntity } from '../../type';
import { D3ModelProcessFacetStatus } from './D3ModelProcessFacetStatus';

/** STL 文件解析出来的属性 */
export class D3ModelProcessStlFileAttr extends BaseEntity<D3ModelProcessStlFileAttr> {
  minX: number;
  maxX: number;
  minY: number;
  maxY: number;
  minZ: number;
  maxZ: number;

  /** 原始文件的面片信息 */
  originalFacetStatus: D3ModelProcessFacetStatus;

  /** 最终处理完成的面片的信息 */
  finalFacetStatus: D3ModelProcessFacetStatus;

  /** 体积 */
  volume: number;

  /** 内涵零件数量 */
  partsNum: number;

  degenerateFacetsNum: number;
  fixedEdgesNum: number;
  removedFacetsNum: number;
  addedFacetsNum: number;
  reversedFacetsNum: number;
  backwardsEdgesNum: number;
  fixedNormalsNum: number;

  sizeY: number;
  sizeZ: number;
  sizeX: number;

  constructor(props: Partial<D3ModelProcessStlFileAttr> = {}) {
    super(props);

    Object.assign(this, props);

    if (props.originalFacetStatus) {
      this.originalFacetStatus = new D3ModelProcessFacetStatus({
        ...props.originalFacetStatus,
      });
    }

    if (props.finalFacetStatus) {
      this.finalFacetStatus = new D3ModelProcessFacetStatus({
        ...props.finalFacetStatus,
      });
    }
  }
}
