import { includes, isNumber } from 'lodash-es';

import * as S from '../../../../schema';

export const getHandleMethodByOrderItem = (orderItem: S.InquiryOrderItem) => {
  return (
    S.get(orderItem, i => i.printFiles[0].handle) || { method: [''], desc: '' }
  );
};

/** 根据子订单，获取当前操作订单中该模型螺母数*/
export const getNutCountByOrderItem = (orderItem: S.InquiryOrderItem) => {
  return S.get(orderItem, i => i.printFiles[0]?.nutCount);
};

/** 根据子订单，获取当前操作订单中该模型单位*/
export const getLengthUnitByOrderItem = (orderItem: S.InquiryOrderItem) => {
  return S.get(orderItem, i => i.printFiles[0].lengthUnit);
};

/** 根据子订单，获取当前操作订单中该模型打孔数*/
export const getPunchCountByOrderItem = (orderItem: S.InquiryOrderItem) => {
  return S.get(orderItem, i => i.printFiles[0].punchCount);
};

/** 根据子订单，获取当前操作订单中该模型打印数量 */
export const getPrintCountByOrderItem = (orderItem: S.InquiryOrderItem) => {
  const cnt = S.get(orderItem, i => i.printCount);

  if (cnt || cnt === 0) {
    return cnt;
  }

  return 1;
};

/** 根据模型，获取当前操作订单中该模型所使用的备注信息 */
export const getItemRemarkByModel = (orderItem: S.InquiryOrderItem) => {
  return {
    remark: S.get(orderItem, i => i.remark, ''),
    remarkFileIds: S.get(orderItem, i => i.remarkFileIds, []),
  };
};

function calcDimensionSize(attr: any, n: 'X' | 'Y' | 'Z'): number | undefined {
  if (attr['size' + n]) return attr['size' + n];
  if (
    attr.fixedSTlFileAttribute &&
    attr.fixedSTlFileAttribute['max' + n] &&
    attr.fixedSTlFileAttribute['min' + n]
  ) {
    return Math.abs(
      attr.fixedSTlFileAttribute['max' + n] -
        attr.fixedSTlFileAttribute['min' + n],
    );
  }
  if (
    attr.stlFileAttribute &&
    attr.stlFileAttribute['max' + n] &&
    attr.stlFileAttribute['min' + n]
  ) {
    return Math.abs(
      attr.stlFileAttribute['max' + n] - attr.stlFileAttribute['min' + n],
    );
  }
}

/** 根据模型，获取当前操作订单中该模型数据 */
export const getModelDetails = (curModel: S.D3ModelFile) => {
  const curAttr = S.get(curModel, m => m.attr) as S.D3FormatAttr;
  const sizeX = calcDimensionSize(curAttr, 'X');
  const sizeY = calcDimensionSize(curAttr, 'Y');
  const sizeZ = calcDimensionSize(curAttr, 'Z');
  const volume = curAttr.volume;
  const sizeEstimateFinished =
    [sizeX, sizeY, sizeZ].filter(v => isNumber(v) && v > 0).length === 3;
  const volumeEstimateFinished = isNumber(volume) && volume > 0;
  return {
    sizeX,
    sizeY,
    sizeZ,
    volume,
    sizeXStr: sizeX?.toFixed(2),
    sizeYStr: sizeY?.toFixed(2),
    sizeZStr: sizeZ?.toFixed(2),
    volumeStr: volume?.toFixed(2),
    sizeEstimateFinished,
    volumeEstimateFinished,
  };
};

export const isSLMByOrderItem = (orderItem: S.InquiryOrderItem) => {
  const printFile = S.get(orderItem, oi => oi.printFiles[0]);
  const materialName =
    S.get(printFile, pf => {
      return pf.materialSpu.materialSnapshot.materialType.name;
    }) || '';
  return includes(materialName, 'SLM');
};
