import { WarningOutlined } from '@ant-design/icons';
import {
  BillingAddress,
  getLatestBillingAddress,
  getTenantIdFromGConfig,
  i18nFormat,
} from '@unionfab/ufc-shop-commons';
import { Tooltip } from 'antd';
import { useEffect, useState } from 'react';

import { ReceiveAddressCheckCardGroup } from '@/features/receive_address/components/ReceiveAddressCheckCardGroup';
import { useInquiryQuoteCheckoutStore } from '@/stores';

import styles from './index.module.less';

export const BillInfoEditor = () => {
  const {
    orderDeliverAddress,
    billingAddress: address,
    setBillingAddress,
  } = useInquiryQuoteCheckoutStore();

  const [
    lastBillingAddress,
    setLastBillingAddress,
  ] = useState<BillingAddress>();

  useEffect(() => {
    getLatestBillingAddress(getTenantIdFromGConfig()).then(
      setLastBillingAddress,
    );
  }, []);

  const useLastBillingAddress = () => {
    const address = lastBillingAddress;
    setBillingAddress(address);
  };

  const useShippingAddressBtn = orderDeliverAddress ? (
    <span
      className={styles.useShipAddressBtn}
      onClick={() => setBillingAddress(orderDeliverAddress)}
    >
      {i18nFormat('Use shipping address')}
    </span>
  ) : (
    <></>
  );

  const useLastBillingInfo = lastBillingAddress ? (
    <span
      className={styles.useLastBillingInfoBtn}
      onClick={useLastBillingAddress}
    >
      {i18nFormat('Use last billing info')}
    </span>
  ) : (
    <></>
  );

  const editBtn = (
    <>
      {useShippingAddressBtn}
      {useLastBillingInfo}
    </>
  );

  let billingWarningText = '';
  if (!address) {
    billingWarningText += 'Billing address is not selected' + '\n';
  } else {
    if (!address.firstName) {
      billingWarningText += 'Address receiver has no first name' + '\n';
    }
    if (!address.lastName) {
      billingWarningText += 'Address receiver has no last name' + '\n';
    }
  }

  const billingInfoWarning = billingWarningText ? (
    <span className={styles.billingInfoWarningContainer}>
      <Tooltip title={billingWarningText}>
        <WarningOutlined />
      </Tooltip>
    </span>
  ) : (
    <></>
  );

  return (
    <div className={styles.billInfoContainer}>
      <div className={styles.billingInfoTitle}>
        {i18nFormat('Billing address')}
        {billingInfoWarning}
        <span className={styles.billingAddressEditBtn}>{editBtn}</span>
      </div>
      <div className={styles.billingInfoContent}>
        <ReceiveAddressCheckCardGroup
          createAndSelectText={i18nFormat('Select a billing address')}
          address={address}
          selectAddress={async addr => setBillingAddress(addr)}
          hideChangeBtn={false}
        />
      </div>
    </div>
  );
};
