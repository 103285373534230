import { GiftOutlined } from '@ant-design/icons';
import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat, useProfileStore } from '@unionfab/ufc-shop-commons';
import { useAsyncEffect } from 'ahooks';
import { Button, Divider, Layout, Menu, Typography } from 'antd';
import axios from 'axios';
import cn from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';

import AddressIcon from '@/assets/svgs/address.svg';
import CSIcon from '@/assets/svgs/customer_service.svg';
import MENU_DISCOUNT from '@/assets/svgs/icon-menu_discount.svg';
import MUI_SVG_FIBER_NEW from '@/assets/svgs/mui-svg-icon-fiber-new.svg';
import MY_VIP from '@/assets/svgs/MyVIP.svg';
import RAPID_CONSULTING from '@/assets/svgs/rapid_consulting.svg';
import { Ellipsis } from '@/commons/components/Element/ellipsis';
import { InquirySurveyModal } from '@/features/auth/components/InquirySurveyModal';
import { useAppNavigate } from '@/features/shared';
import { useGlobalStore, useInquiryCouponStore } from '@/stores';

import styles from './index.module.less';

export const SideMenu = () => {
  const [version, setVersion] = useState('');

  const { user, isDemoUser, hasAuthenticated } = useGlobalStore(g => ({
    user: g.user,
    isDemoUser: g.isDemoUser,
    hasAuthenticated: g.hasAuthenticated,
  }));

  const { checkNewCoupon, newCouponInfo } = useInquiryCouponStore(c => ({
    newCouponInfo: c.newCouponInfo,
    checkNewCoupon: c.checkNewCoupon,
  }));

  const { accountInfo, loadPersonInfo } = useProfileStore(s => ({
    accountInfo: s.accountInfo,
    loadPersonInfo: s.loadPersonInfo,
  }));

  const location = useLocation();
  const curMenuPath = `${location.pathname}`;
  const nav = useAppNavigate();

  useAsyncEffect(async () => {
    const { data: version } = await axios.get(
      'https://service.unionfab.com/config/version',
    );
    setVersion(version);
  }, []);

  useEffect(() => {
    if (hasAuthenticated && !isDemoUser) {
      checkNewCoupon();
      loadPersonInfo();
    }
  }, [hasAuthenticated, isDemoUser]);

  const getMenuItemNameAndIcon = ({
    name,
    icon,
  }: {
    name: string;
    icon: string;
  }) => {
    const referralCode = S.get(accountInfo, a => a.referralCode);
    const discoverySource = S.get(accountInfo, a => a.discoverChannels);

    switch (icon) {
      case 'rapid_consulting':
        return { icon: <RAPID_CONSULTING />, name };
      case 'new_quote':
        return { icon: <span className={`icon-menu_${icon}`} />, name };
      case 'quote_history':
        return { icon: <span className={`icon-menu_${icon}`} />, name };
      case 'order_history':
        return { icon: <span className={`icon-menu_${icon}`} />, name };
      case 'address':
        return { icon: <AddressIcon />, name };
      case 'discount':
        return {
          icon: <MENU_DISCOUNT style={{ marginLeft: -3 }} />,
          name: (
            <span>
              {name}&nbsp;
              {newCouponInfo.hasNew && <MUI_SVG_FIBER_NEW />}
            </span>
          ),
        };
      case 'account_settings':
        return {
          icon: <CSIcon />,
          name: (
            <span>
              {name}&nbsp;
              {(referralCode || discoverySource === 'Gift card(s)') && (
                <MY_VIP />
              )}
            </span>
          ),
        };

      case 'referral':
        return {
          icon: <GiftOutlined />,
          name,
        };
      default:
        return { icon: <span className={`icon-menu_${icon}`} />, name: '-' };
    }
  };

  const renderNavMenu = (
    menuList: { name: string; path: string; icon: string }[],
  ) => {
    return (
      <Menu
        theme="light"
        className={styles.menu}
        selectedKeys={[curMenuPath]}
        items={(menuList || []).map((m, i) => {
          const v = getMenuItemNameAndIcon(m);
          const selected = curMenuPath === m.path;

          return {
            key: i,
            className: cn(styles.textContainer, {
              [styles.selectedTextContainer]: selected,
            }),
            icon: <span className={styles.iconContainer}>{v.icon}</span>,
            label: (
              <span
                className={cn(styles.textContainer, {
                  [styles.selectedTextContainer]: selected,
                })}
              >
                <Link to={m.path}>{m.name}</Link>
              </span>
            ),
          };
        })}
      />
    );
  };

  const renderMenuTitle = (title: string) => (
    <Typography.Title
      level={5}
      style={{ fontSize: '1.25rem', paddingLeft: 12 }}
    >
      {title}
    </Typography.Title>
  );

  const MenuHeader = useMemo(() => {
    return (
      <div className={styles.menuHeader}>
        <div className={styles.menuHeaderContent}>
          <Ellipsis maxWidth={180} className={styles.subTitle}>{`${i18nFormat(
            'Hi',
          )}, ${S.get(user, a => a.customerName, '-')} `}</Ellipsis>
          {/* <Button
            type="link"
            icon={<SwapIcon />}
            onClick={changeAccount}
            style={{ marginLeft: 6 }}
            loading={isChangeAccount}
          /> */}
        </div>

        <Button type="primary" block onClick={() => nav.navToNewQuote()}>
          <Ellipsis maxWidth={180}>{i18nFormat('Get Instant Quote')}</Ellipsis>
        </Button>
      </div>
    );
  }, [user, curMenuPath]);

  const sidebar = useMemo(() => {
    return (
      <Layout.Sider
        width={240}
        className={cn('uoms-oversea-order-home-sider', styles.sider)}
      >
        <div className={styles.siderContent}>
          {MenuHeader}
          {/* {renderMenuTitle('New Quote')}
        {renderNavMenu([
          {
            name: 'New Quote',
            path: '/new_quote',
            icon: 'new_quote',
          },
          {
            name: 'Rapid Consulting',
            path: '/rapid_consulting',
            icon: 'rapid_consulting',
          },
        ])} */}
          {renderMenuTitle(i18nFormat('Order'))}
          {renderNavMenu([
            {
              name: i18nFormat('Quotes'),
              path: '/orders_history/quotes',
              icon: 'order_history',
            },
            {
              name: i18nFormat('Orders'),
              icon: 'quote_history',
              path: '/orders_history',
            },
          ])}
          <Divider className={styles.divider} />
          {renderMenuTitle(i18nFormat('Account'))}
          {renderNavMenu([
            {
              name: i18nFormat('Profile'),
              path: '/account_settings',
              icon: 'account_settings',
            },
            {
              name: i18nFormat('Shipping Addresses'),
              path: '/address',
              icon: 'address',
            },
            {
              name: i18nFormat('Coupons'),
              path: '/discount',
              icon: 'discount',
            },
            {
              name: i18nFormat('Referral & Earn'),
              path: '/referral',
              icon: 'referral',
            },
          ])}
        </div>
        <div className={styles.versionInfo}>
          {i18nFormat('version')}: {version}
        </div>
      </Layout.Sider>
    );
  }, [curMenuPath, accountInfo, newCouponInfo]);

  const outlet = useMemo(() => {
    return (
      <Layout className={styles.content}>
        <Layout.Content className={styles.contentContainer}>
          <Outlet />
        </Layout.Content>
      </Layout>
    );
  }, []);

  return (
    <Layout className={styles.home}>
      {/* DEMO 体验用户不展示左侧 sidebar */}
      {hasAuthenticated && !isDemoUser && sidebar}
      {outlet}
      <InquirySurveyModal reload={() => loadPersonInfo()} />
    </Layout>
  );
};
