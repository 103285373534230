import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Button, Spin } from 'antd';
import React, { Suspense, useEffect, useMemo } from 'react';
import { Route } from 'react-router-dom';

import { retryFn } from '@/commons/utils';
import { openLoginPage, useAppNavigate } from '@/features/shared';
import { useGlobalStore } from '@/stores';

import { ALLOW_ANONYMOUS, ALLOW_DEMO, AppId } from './apps';

export interface ResolvedModule {
  default?: React.ComponentType<any>;
}

export interface LoadableContainerProps {
  appId: AppId;
  appLoader: () => Promise<ResolvedModule>;
  fallback?: React.ReactNode;
}

export const LoadableContainer = ({
  appId,
  appLoader,
  fallback,
}: LoadableContainerProps) => {
  const { isDemoUser, hasAuthenticated, loginOrdinary } = useGlobalStore();
  const canVisit = useMemo(() => {
    if (ALLOW_ANONYMOUS.includes(appId)) return 'yes';
    if (hasAuthenticated && !isDemoUser) return 'yes';
    if (ALLOW_DEMO.includes(appId))
      return hasAuthenticated && isDemoUser ? 'yes' : 'getAnonymous';
    return 'no';
  }, [hasAuthenticated, isDemoUser, appId]);

  useEffect(() => {
    switch (canVisit) {
      case 'no':
        console.log(`cannot visit ${appId}, redirect to home`);
        openLoginPage();
        break;
      case 'getAnonymous':
        console.log(`visiting ${appId}, wait for anonymous login`);
        // 登录到匿名用户
        loginOrdinary();
        break;
      default:
    }
  }, [canVisit]);

  const App = useMemo(
    () =>
      React.lazy(
        () => retryFn(appLoader, -1, 1000, 0, `loading ${appId}`) as any,
      ),
    [appId, appLoader],
  );

  switch (canVisit) {
    case 'yes':
      return (
        <Suspense fallback={fallback}>
          <App />
        </Suspense>
      );
    case 'no':
      return <></>;
    case 'getAnonymous':
    default:
      return <>{fallback}</>;
  }
};

const LoadingSpin = () => {
  return (
    <div
      className="hello"
      style={{
        display: 'flex',
        height: '90vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spin
        spinning={true}
        tip={
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <span style={{ color: 'black' }}>
              {i18nFormat(
                'Loading the page resource. If the page does not refresh automatically, try to refresh manually again.',
              )}
            </span>
            <Button
              type="link"
              onClick={() => {
                window.location.reload();
              }}
            >
              {i18nFormat('Click to Refresh')}
            </Button>
          </div>
        }
      />
    </div>
  );
};

export const createLoadableRoute = ({
  appId,
  path,
  appLoader,
}: LoadableContainerProps & { path: string }) => {
  return (
    <Route
      path={path}
      element={
        <LoadableContainer
          appId={appId}
          appLoader={appLoader}
          fallback={<LoadingSpin />}
        />
      }
    />
  );
};
