import { create, StoreApi, UseBoundStore } from 'zustand';
import { devtools } from 'zustand/middleware';

import {
  closeInquiryOrderByCustomer,
  getTenantIdFromGConfig,
} from '../../apis';

export interface IOrderCloseState {
  // to close
  code?: string;
  isQuote: boolean;
  reason: string;
  availableReasons: string[];

  standardReason?: string;
  customReason?: string;

  toggleClosing: (code?: string, isQuote?: boolean) => void;
  setStandardReason: (s: string) => void;
  setCustomReason: (s: string) => void;
  close: (onClose?: () => void) => Promise<void>;
}

const QUOTE_STANDARD_REASONS = [
  'The cost of materials is high',
  'The delivery time is long',
  "I don't need to print anymore",
  'The print service needs to be modified',
  'Unable to produce after confirmation',
  'The Quotation time is long',
  'Poor Customer Service',
  'Other(s)',
];

const ORDER_STANDARD_REASONS = [
  'The cost of materials is high.',
  'The logistics cost are expensive.',
  'The delivery time is long.',
  `I don't need to print anymore.`,
  'The print service needs to be modified.',
  'Unable to produce after confirmation.',
  'The Quotation time is long.',
  'Poor Customer Service.',
  'Other(s)',
];

const getReason = (customReason?: string, standardReason?: string) => {
  let reason;
  if (standardReason === 'Other(s)') {
    reason = customReason || standardReason;
  } else {
    reason = standardReason;
  }
  return reason || '';
};

export const useCloseOrderStore: UseBoundStore<
  StoreApi<IOrderCloseState>
> = create<IOrderCloseState>()(
  devtools(
    (set, get) => {
      const close = async (onClose?: () => void) => {
        const { code: orderCode, reason } = get();
        if (!orderCode) {
          console.warn('illegal state: no order to be closed');
          return;
        }

        const res = await closeInquiryOrderByCustomer({
          tenantId: getTenantIdFromGConfig(),
          orderCode,
          reason,
        });

        if (res) {
          set({ code: undefined }, false, 'onClose');
          if (onClose) {
            onClose();
          }
        }
      };

      const setCustomReason = (customReason: string) => {
        set({
          customReason,
          reason: getReason(customReason, get().standardReason),
        });
      };

      const setStandardReason = (standardReason: string) => {
        set({
          standardReason,
          reason: getReason(get().customReason, standardReason),
        });
      };

      const initStates = {
        reason: '',
        isQuote: true,
        availableReasons: QUOTE_STANDARD_REASONS,
        customReason: undefined,
        standardReason: undefined,
      };

      const toggleClosing = (code?: string, isQuote?: boolean) => {
        if (code) {
          set({
            ...initStates,
            code,
            isQuote: isQuote || true,
            availableReasons: isQuote
              ? QUOTE_STANDARD_REASONS
              : ORDER_STANDARD_REASONS,
          });
        } else {
          set({ code, ...initStates });
        }
      };

      return {
        ...initStates,
        close,
        toggleClosing,
        setCustomReason,
        setStandardReason,
      };
    },
    { name: 'closeOrderStore' },
  ),
);
