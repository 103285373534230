import { RawUfcApiReturnType } from '../../shop-func-apis/common';
import { BaseAxiosApi, CompatibleRequestOptionsInit } from '../shared';

export class UmiRequestCompatibleApi extends BaseAxiosApi {
  private polishUrl = (rawUrl: string) => {
    if (rawUrl.includes('http') || rawUrl.includes('https')) {
      return rawUrl;
    }

    return `${this.apiConfig.apiEndpoint}${rawUrl}`;
  };

  /** 兼容 Umi 的请求格式 */
  getUmi = async <T extends Partial<RawUfcApiReturnType<any>>>(
    url: string,
    config: CompatibleRequestOptionsInit = {},
  ) => {
    // Umi 传入的是完整的响应体，这里 get 不好做类型兼容，就直接怼返回值兼容
    const resp = (await this.get<any>({
      url: this.polishUrl(url),
      ...config,
    })) as T;

    return resp;
  };

  postUmi = async <T extends Partial<RawUfcApiReturnType<any, any>>>(
    url: string,
    config: CompatibleRequestOptionsInit = {},
  ) => {
    const resp = await this.post<T>({
      url: this.polishUrl(url),
      ...config,
    });

    return resp as T;
  };

  deleteUmi = async <T extends Partial<RawUfcApiReturnType<any>>>(
    url: string,
    config: CompatibleRequestOptionsInit = {},
  ) => {
    return this.delete<T>({
      url: this.polishUrl(url),
      ...config,
    });
  };

  putUmi = async <T extends Partial<RawUfcApiReturnType<any>>>(
    url: string,
    config: CompatibleRequestOptionsInit = {},
  ) => {
    const resp = await this.put<T>({
      url: this.polishUrl(url),
      ...config,
    });

    return resp as T;
  };

  patchUmi = async <T extends Partial<RawUfcApiReturnType<any>>>(
    url: string,
    config: CompatibleRequestOptionsInit = {},
  ) => {
    const resp = await this.patch<T>({
      url: this.polishUrl(url),
      ...config,
    });

    return resp as T;
  };
}
