import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { getTenantIdFromGConfig } from '../../apis';
import {
  IOrderListStore,
  IOrderListStoreState,
  loadOrderCountsInOrderListStore,
  loadOrdersInOrderListStore,
  OrderListQueryParams,
} from '../order/useOrderListStore';

export const useQuoteListStore: IOrderListStore = create<IOrderListStoreState>()(
  devtools(
    () => {
      return {
        isLoading: false,
        queryParams: {
          pageNum: 1,
          pageSize: 3,
          orderStatus: 'WAIT_PAYMENT',
          sortBy: 'desc(updateTime)',
        },
        orderList: [],
        orderMap: {},
        orderStatusList: ['REVIEWING', 'WAIT_PAYMENT'],
      };
    },
    { name: 'quoteListStore' },
  ),
);

export const setQueryParamsInQuoteListStore = async (
  queryParams: Partial<OrderListQueryParams>,
) => {
  useQuoteListStore.setState({ queryParams });

  loadOrdersInQuoteListStore(queryParams);
  loadOrderCountsInQuoteListStore(queryParams);
};

export const loadOrdersInQuoteListStore = async (
  queryParams?: Partial<OrderListQueryParams>,
) => {
  return loadOrdersInOrderListStore(queryParams, useQuoteListStore);
};

export const loadOrderCountsInQuoteListStore = async (
  queryParams?: Partial<OrderListQueryParams>,
) => {
  return loadOrderCountsInOrderListStore(queryParams, useQuoteListStore);
};
