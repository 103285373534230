import {
  getFilesByIds,
  getModelDetails,
  getNutCountByOrderItem,
  getPunchCountByOrderItem,
  i18nFormat,
} from '@unionfab/ufc-shop-commons';
import * as S from '@unionfab/ufc-shop-commons';
import { D3ModelAvatar } from '@unionfab/ufc-shop-ui-commons';
import { Descriptions, Space, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { includes } from 'lodash-es';
import * as React from 'react';
import { useQuery } from 'react-query';

import { RemarkUploader } from '../../../new_quote/components/uploader/RemarkUploader';
import styles from './index.module.less';

interface ColumnDataType {
  key: string;
  product: S.InquiryPrintFile;
  sku: S.InquiryOrderItem;
  specialRequest: S.InquiryOrderItem;
  remarkFiles: any;
  price?: string;
  quantity: number;
  subTotal?: string;
}

export const OrderItemReviewTable = ({
  order,
  quotation,
}: {
  order: S.InquiryOrder;
  quotation?: S.OrderQuotationV2;
}) => {
  const { data: remarkFilesOrigin, isSuccess: hasGotRemarkFiles } = useQuery(
    [`queryRemarkFiles`, order],
    () => {
      const fileList = order.items.map(orderItem => {
        const remarkFileIdList =
          S.get(orderItem, oi => oi.itemRemark.fileIds) ||
          S.get(orderItem, oi => oi.remarkFileIds) ||
          [];
        if (remarkFileIdList) {
          return getFilesByIds(remarkFileIdList);
        }
        return Promise.resolve([]);
      });
      return Promise.all(fileList);
    },
  );
  const remarkFileListCollection = React.useMemo(() => {
    return (remarkFilesOrigin || []).map((fileMap: any) => {
      return Object.keys(fileMap || {}).map(key => ({
        uid: fileMap[key].id,
        id: fileMap[key].id,
        status: 'done',
        url: fileMap[key].url,
        name: fileMap[key].name,
      }));
    });
  }, [remarkFilesOrigin]);

  const dataSource = React.useMemo(() => {
    return getTableDataSource(order, remarkFileListCollection, quotation) || [];
  }, [remarkFileListCollection, order, quotation]);

  return (
    <>
      {hasGotRemarkFiles && (
        <div className={styles.orderItemReviewTable}>
          <Table
            pagination={false}
            bordered
            dataSource={dataSource}
            columns={productTableHeadSource}
          />
        </div>
      )}
    </>
  );
};

const productCellRender = (file: S.InquiryPrintFile) => {
  const part = file.modelFile!;
  const curLengthUnit = file.lengthUnit;
  const unitStr = S.getLengthUnitDesc(curLengthUnit);

  const { sizeXStr, sizeYStr, sizeZStr } = getModelDetails(part);
  const dimensionStr = `${i18nFormat(
    'Dimension',
  )}: ${sizeXStr}*${sizeYStr}*${sizeZStr} ${unitStr}`;

  return (
    <div className={styles.productCell}>
      <Space direction="vertical" align="start">
        <div className={styles.productCellAvatar}>
          <div className={styles.productCellModelAvatar}>
            <D3ModelAvatar
              size={80}
              key={S.get(part, m => m.id)}
              unit={curLengthUnit}
              model={part}
            />
          </div>
        </div>

        <div className={styles.productCellTitle}>{part.name}</div>

        <div className={styles.productDimension}>
          <Typography.Paragraph ellipsis={{ rows: 1, tooltip: true }}>
            {dimensionStr}
          </Typography.Paragraph>
        </div>
      </Space>
    </div>
  );
};

const renderColorBlock = (color: string) => {
  return <div className="color-block-cell" style={{ background: color }} />;
};

const skuCellRender = (orderItem: S.InquiryOrderItem) => {
  const materialSpu = orderItem.materialSpu;
  const categoryName =
    S.get(materialSpu, (m: any) => m.material.materialType.categoryName) ||
    S.get(
      materialSpu,
      (m: any) => m.materialSnapshot.materialType.categoryName,
    ) ||
    '';

  const processName =
    S.get(materialSpu, (m: any) => m.material.materialType.name) ||
    S.get(materialSpu, (m: any) => m.materialSnapshot.materialType.name) ||
    '';
  const isCNC = includes(categoryName, 'CNC');

  const holeTitle = !isCNC
    ? i18nFormat('Inserts')
    : i18nFormat('Threads and Tapped Holes');
  const nutCount = getNutCountByOrderItem(orderItem) || 0;
  const punchCount = getPunchCountByOrderItem(orderItem) || 0;
  const printFile = S.get(orderItem, oi => oi.printFiles[0]);
  const color =
    S.get(orderItem, f => f.materialSpu.color) ||
    S.get(orderItem, f => f.materialSpu.materialSnapshot.color) ||
    '#fff';
  const colorDesc = S.get(printFile, f => f.handle.desc, '');

  const materialName = `${processName}-${S.get(
    orderItem,
    f => f.materialSpu.materialNameStr,
  )}`;

  return (
    <div className="sku-cell">
      <Descriptions title="" column={1}>
        <Descriptions.Item label="Material">{materialName}</Descriptions.Item>
        <Descriptions.Item label={i18nFormat('Color')}>
          <div className="color-cell-wrapper">
            {renderColorBlock(color)}{' '}
            <span style={{ marginLeft: '10px' }}>{color}</span>
          </div>
        </Descriptions.Item>
        <Descriptions.Item label={i18nFormat('Finish')}>
          {S.get(printFile, f => f.handle.method[0]) || 'Standard'}
          {colorDesc.length > 0 && `-${colorDesc}`}
        </Descriptions.Item>
        <Descriptions.Item label={holeTitle}>
          {!isCNC ? nutCount : punchCount}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

const renderSpecialRequest = (orderItem: S.InquiryOrderItem) => {
  return (
    <div className={styles.noteCell}>
      {orderItem.remark ? (
        <Typography.Paragraph ellipsis={{ rows: 2, tooltip: true }}>
          {orderItem.remark}
        </Typography.Paragraph>
      ) : (
        '-'
      )}
    </div>
  );
};

const renderTechnicalDrawing = (remarkFileList: any[]) => {
  const notEmpty = remarkFileList.length > 0;
  return (
    notEmpty && (
      <div className={styles.drawingCell}>
        <RemarkUploader
          type="OTHER"
          defaultFileList={remarkFileList}
          showUploadList={true}
        />
      </div>
    )
  );
};

const productTableHeadSource: ColumnsType<ColumnDataType> = [
  {
    title: 'Product',
    dataIndex: 'product',
    key: 'product',
    align: 'center',
    render: productCellRender,
  },
  {
    title: 'SKU',
    dataIndex: 'sku',
    key: 'sku',
    align: 'center',
    render: skuCellRender,
  },
  {
    title: 'Price',
    key: 'price',
    width: 180,
    render: (_, record) => {
      return (
        <Descriptions title="" column={1}>
          <Descriptions.Item label={i18nFormat('Per')}>
            {record.price}
          </Descriptions.Item>
          <Descriptions.Item label={i18nFormat('Qty')}>
            {record.quantity}
          </Descriptions.Item>
          <Descriptions.Item label={i18nFormat('Price')}>
            {record.subTotal}
          </Descriptions.Item>
        </Descriptions>
      );
    },
  },
  {
    title: i18nFormat('Notes'),
    key: 'specialRequest',
    render: (_, record) => {
      return (
        <Descriptions title="" column={1}>
          <Descriptions.Item label={i18nFormat('Remark')}>
            {renderSpecialRequest(record.specialRequest)}
          </Descriptions.Item>
          <Descriptions.Item label={i18nFormat('Technical drawing')}>
            {renderTechnicalDrawing(record.remarkFiles)}
          </Descriptions.Item>
        </Descriptions>
      );
    },
  },
];

const getTableDataSource = (
  order: S.InquiryOrder,
  remarkFileList: any[],
  quotation?: S.OrderQuotationV2,
) => {
  const orderItems = order.items;
  const priceConfirmed =
    order.status !== 'WAIT_SUBMIT' && order.status !== 'WAIT_REVIEW';

  return orderItems.map((orderItem, idx) => {
    const printFile = S.get(
      orderItem,
      oi => oi.printFiles[0],
    )! as S.InquiryPrintFile;
    const file = new S.InquiryPrintFile({
      ...printFile,
      isForceUseStartingPriceVO: true,
    });
    const itemPrice = (quotation?.itemQuotations || []).find(
      v => v.id === orderItem.id,
    );
    let rfq =
      itemPrice?.requiresManualQuote == null
        ? true
        : itemPrice?.requiresManualQuote;
    if (rfq && priceConfirmed) {
      rfq = false;
    }

    rfq = rfq || itemPrice?.price == null || itemPrice?.perPrice == null;

    const price = S.toPriceStringWithOption(itemPrice?.price, {
      rfq,
      currency: order.currency,
    });

    const perPrice = S.toPriceStringWithOption(itemPrice?.perPrice, {
      rfq,
      currency: order.currency,
    });

    return {
      key: file.fileId,
      product: file,
      sku: orderItem,
      specialRequest: orderItem,
      remarkFiles: remarkFileList[idx],
      price: perPrice,
      quantity: file.printCount,
      subTotal: price,
    } as ColumnDataType;
  });
};
