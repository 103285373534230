import { BaseEntity } from '../../../common';

export class ShopOrderProgress extends BaseEntity<ShopOrderProgress> {
  status: string;
  statusHistory: {
    status: string;
    at: string;
  }[];

  constructor(data: Partial<ShopOrderProgress> = {}) {
    super(data);

    Object.assign(this, data);
  }
}
