import { useInterval } from 'ahooks';
import { useEffect, useState } from 'react';

import { getModelFileById } from '../../../apis';
import { calcModelMetrics, D3ModelFile } from '../../../schema';

export const useD3ModelMetrics = (
  _model: D3ModelFile,
  gap = 5000,
  repeatedCheck = true,
  repeatedCheckThreshold = 500,
) => {
  const [model, setModel] = useState(_model);
  const [checkInterval, setCheckInterval] = useState<number | undefined>(
    repeatedCheck ? gap : undefined,
  );

  useEffect(() => {
    setModel(_model);
  }, [_model]);

  const metrics = calcModelMetrics(model);
  const [count, setCount] = useState(0);

  useInterval(() => {
    if (!metrics.sizeEstimateFinished || !metrics.volumeEstimateFinished) {
      if (count == repeatedCheckThreshold) {
        setCheckInterval(undefined);
      }
      (async () => {
        if (!!model) {
          const m = await getModelFileById(model.id!, {
            disableLocalCache: true,
          });

          setModel(m);
          setCount(count + 1);
        }
      })();
    } else {
      setCheckInterval(undefined);
    }
  }, checkInterval);

  return metrics;
};
