export const compareBySequence = (
  n1: string,
  n2: string,
  orderList: string[],
) => {
  let i1 = orderList.findIndex(v =>
    (n1 || '').toLowerCase().includes(v.toLowerCase()),
  );
  i1 = i1 < 0 ? 999999 : i1;

  let i2 = orderList.findIndex(v =>
    (n2 || '').toLowerCase().includes(v.toLowerCase()),
  );
  i2 = i2 < 0 ? 999999 : i2;

  return i1 - i2;
};

export const MATERIAL_TYPE_SHOW_ORDERS = [
  'SLM',
  'SLA',
  'SLS',
  'MJF',
  'FDM',
  'DLP',
  'PloyJet',
  'CNC Machining',
];

export const MATERIAL_SHOW_ORDERS = [
  'Aluminum(Alsi 10Mg)',
  '316 L STEEL',
  'Titanium(TC4)',
  'Maraging Steel',
  'Standard Resin White',
  'Translucent Resin',
  'Transparent Clear Resin',
  'Somos Perform',
  'Rigid Resin（white）',
  '[Toughness]UTR8220',
  '[Toughness-Heat]Somos Taurus',
  '[Dampproof]Somos Ledo',
  'CastingResin',
  'Standard Resin Black',
  'Rigid Resin（black）',
  'High Detail Resin',
  'Somos EvoLve 128',
  'UTR Therm',
  'TPU',
  'SLS Nylon 11-Black',
  'PA6 GF',
  'SLS Nylon11-White',
  'SLS Nylon 12 Black',
  'SLS Nylon 12 White',
  'GLASS-FILLED NYLON-Offwhite',
  'GLASS-FILLED NYLON-Black',
  'MJF Nylon 12-Black',
  'MJFPA11Gray',
  'PLA',
  'PETG',
  'Ultem 9085',
  'WOOD - FILLED PLA',
  'ASA',
  'ABS',
  'Ultra Detail Resin',
  'Rubber like Resin',
  'Multi Color Resin',
  'Titanium Alloy TC2',
  'Titanium Alloy TC4',
  'Stainless Steel 304',
  'Stainless Steel 316L',
  'Spring Steel 60Si2Mn',
  'Spring Steel 65Mn',
  'Alloy Steel 40Cr',
  'Alloy Steel 42CrMo',
  'Alloy Steel Cr12Mov',
  'Alloy Steel Cr12',
  'Zin Calloy',
  'Aluminum 7075',
  'Aluminum 6061',
  'Aluminum 2A12',
  'Copper',
  'Carbon Steel',
  'AL2014-T6',
  'Polyformaldehyde(POM)',
  'PET',
  'PEEK',
  'Bakelite plastics',
  'PC',
  'ABS( Acrylonitrile-butdiene-styrene)',
  'PC-ABS',
  'Acrylic-PMMA',
  'PP(Polyproylene)',
  'PTFE(Polytetrafluoroethylene)',
  'POM',
];
