import { wrapSingletonAsyncFunc } from '@m-fe/ts-async';

import * as S from '../../../../schema';
import { getUfcShopComposedApi } from '../../../../singleton-ins';

const getHandleMethodByMaterialTypeCache: Record<string, string[]> = {};

export async function getHandleMethodByMaterialType(
  materialTypeId: S.Id,
  tenantId?: S.Id,
) {
  const cacheKey = `${materialTypeId}-${tenantId}`;
  if (getHandleMethodByMaterialTypeCache[cacheKey]) {
    return getHandleMethodByMaterialTypeCache[cacheKey];
  }

  const singletonGetHandleMethodByMaterialTypeCache = wrapSingletonAsyncFunc<string>(
    _getHandleMethodByMaterialType,
    {
      fnKey: '_getHandleMethodByMaterialType',
      serialize: ([materialTypeId]) => materialTypeId as string,
    },
  );

  getHandleMethodByMaterialTypeCache[
    cacheKey
  ] = await singletonGetHandleMethodByMaterialTypeCache(
    materialTypeId,
    tenantId,
  );

  return getHandleMethodByMaterialTypeCache[cacheKey];
}

/** 查询材料类型所支持的后处理方式 */
export async function _getHandleMethodByMaterialType(
  materialTypeId: S.Id,
  tenantId?: S.Id,
) {
  const {
    data,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.getUmi<{
    data: string[];
  }>(`/inquiry/material/type/${materialTypeId}/handle_method`, {
    params: { tenantId },
  });

  return data;
}
