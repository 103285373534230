import {
  CopyOutlined,
  DeleteOutlined,
  DownloadOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import { getUfcShopComposedApi, i18nFormat } from '@unionfab/ufc-shop-commons';
import { Button, Drawer, Dropdown, Form, message, Modal, Space } from 'antd';
import cn from 'classnames';
import { useEffect, useState } from 'react';

import useCNCOptions from '../../../../../../../../../ufc-shop-commons/src/stores/quote/shop-order-quote/useCNCOptions';
import { UfcFileDownloader } from '../../../../../../../commons/components/file/UfcFileDownloader';
import {
  getUseShopQuoteStoreByCode,
  ShopOrderQuoteStoreModifiers,
} from '../../../../../../../stores';
import { ShopQuoteItemBlockEditor } from '../../ShopQuoteItemEditor/ShopQuoteItemBlockEditor';
import styles from './index.module.less';
import { useCNCFormStore } from './useCNCForm';
import { cloneDeep } from 'lodash';

export interface ShopQuoteItemActionProps {
  className?: string;
  style?: Record<string, string | number>;

  orderCode: string;
  itemId: string;
}

export const ShopQuoteItemAction = ({
  className,
  style,

  orderCode,
  itemId,
}: ShopQuoteItemActionProps) => {
  const [isConfigurationDrawerVisible, setIsConfigurationDrawerVisible] =
    useState(false);
  const store = getUseShopQuoteStoreByCode(orderCode);
  const { setOpts: setCNCOpts, options: cncOpts } = useCNCOptions();

  const { allowEdit, isLoading, shopOrder, shopOrderDraft } = store(s => ({
    allowEdit: s.allowEdit,
    shopOrder: s.shopOrder,
    isLoading: s.isLoading,
    shopOrderDraft: s.shopOrderDraft,
  }));

  useEffect(() => {
    if (shopOrder.technology === 'CNC' && Object.keys(cncOpts).length === 0) {
      getUfcShopComposedApi()
        .shopOrderQueryApi.getCNCOptions()
        .then((res: any) => {
          setCNCOpts(res.cnc);
        })
        .catch(e => {
          console.error(e);
        });
    }
  }, []);

  if (!shopOrder) return <></>;

  const item = (shopOrder.items || []).find(i => i.id === itemId);

  const copyItemBtn = (
    <Button
      type="link"
      disabled={!allowEdit}
      icon={<CopyOutlined />}
      style={{ color: '#1F1F2C', margin: 0, padding: 0 }}
      className={styles.orderItemHeaderRightFirstLineModelHandlerBtn}
      onClick={() => {
        Modal.confirm({
          wrapClassName: 'order_items_creator_file_indicator_delete_modal',
          title: i18nFormat(`Are you sure you want to copy this part`),
          okText: i18nFormat('Copy'),
          okButtonProps: {
            style: { textShadow: 'none', boxShadow: 'none' },
          },
          cancelText: i18nFormat('Cancel'),
          onOk: async () => {
            await ShopOrderQuoteStoreModifiers.copyOrderItems(
              orderCode,
              [itemId],
              cncOpts,
            );
            message.success(i18nFormat('Item copied successfully'));
          },
        });
      }}
    >
      {i18nFormat('Copy')}
    </Button>
  );

  const removeItemBtn = (
    <Button
      type="link"
      disabled={!allowEdit}
      icon={<DeleteOutlined />}
      style={{ color: '#1F1F2C', margin: 0, padding: 0 }}
      onClick={() => {
        Modal.confirm({
          wrapClassName: 'order_items_creator_file_indicator_delete_modal',
          title: i18nFormat(`Are you sure you want to delete this part?`),
          okText: i18nFormat('Delete'),
          okButtonProps: {
            style: { textShadow: 'none', boxShadow: 'none' },
          },
          cancelText: i18nFormat('Cancel'),
          onOk: () => {
            ShopOrderQuoteStoreModifiers.removeOrderItemByFileIds(orderCode, [
              item.fileId,
            ]);
          },
        });
      }}
    >
      {i18nFormat('Remove')}
    </Button>
  );

  const [form] = Form.useForm();

  const {
    setAppliedRequired,
    initDrawerTrigger,
    setForm,
    appliedRequiredAfterUpdating,
    setDrawer,
  } = useCNCFormStore();

  useEffect(() => {
    initDrawerTrigger(itemId, setIsConfigurationDrawerVisible);
  }, [setIsConfigurationDrawerVisible, itemId]);

  useEffect(() => {
    setForm(itemId, form);
  }, [form, setForm, itemId]);

  // 打开或关闭抽屉的时候，清空表单
  useEffect(() => {
    form.resetFields();
  }, [isConfigurationDrawerVisible, form]);

  useEffect(() => {
    setDrawer(isConfigurationDrawerVisible);
    if (!isConfigurationDrawerVisible) {
      setAppliedRequired(itemId, false);
    }
  }, [isConfigurationDrawerVisible]);

  return (
    <div
      id="ShopQuoteItemAction"
      className={cn(className, styles.container)}
      style={style}
    >
      <Space direction="vertical" align="end">
        <Button
          type="primary"
          onClick={() => {
            setIsConfigurationDrawerVisible(true);
          }}
        >
          {i18nFormat('Edit Item')}
        </Button>
        <Button type="link">
          <Dropdown
            menu={{
              items: [
                { key: 'copy', label: copyItemBtn },
                {
                  key: 'remove',
                  label: removeItemBtn,
                },
                {
                  key: 'download',
                  label: (
                    <UfcFileDownloader fileId={item.fileId}>
                      <Button
                        icon={<DownloadOutlined />}
                        type="link"
                        style={{
                          color: '#1F1F2C',
                          margin: 0,
                          padding: 0,
                          float: 'left',
                        }}
                      >
                        {i18nFormat('Download')}
                      </Button>
                    </UfcFileDownloader>
                  ),
                },
              ],
            }}
          >
            <Space>
              {i18nFormat('More')}
              <MoreOutlined />
            </Space>
          </Dropdown>
        </Button>
      </Space>

      <Drawer
        width="40%"
        open={isConfigurationDrawerVisible}
        drawerStyle={{ backgroundColor: '#f0f1f5' }}
        contentWrapperStyle={{ backgroundColor: '#f0f1f5' }}
        bodyStyle={{ backgroundColor: '#f0f1f5', padding: 0 }}
        onClose={() => {
          // 如果工艺是 CNC，如果修改了表单，则提示退出前需要提交数据
          if (
            appliedRequiredAfterUpdating[itemId] &&
            shopOrder.technology === 'CNC'
          ) {
            Modal.confirm({
              title: i18nFormat(
                `Closing the drawer will clear all form data. Are you sure you want to proceed?`,
              ),
              okText: i18nFormat('Yes, Clear Data'),
              cancelText: i18nFormat('No, Keep Editing'),
              onOk: async () => {
                setIsConfigurationDrawerVisible(false);
                store.setState({ shopOrderDraft: cloneDeep(shopOrder) });
              },
            });
          } else {
            setIsConfigurationDrawerVisible(false);
            store.setState({ shopOrderDraft: cloneDeep(shopOrder) });
          }
        }}
        title={i18nFormat('Upload design and configure parts to get a quote')}
        forceRender // 直接渲染，用于加载 cnc 表单的 useForm
      >
        <div className={styles.configurationDrawerContent}>
          <Form
            preserve={false}
            layout="vertical"
            className={styles.drawerForm}
            scrollToFirstError
            onValuesChange={() => {
              setAppliedRequired(itemId, true);
            }}
            onFinish={() => {
              store.setState({ shopOrder: shopOrderDraft });
              setAppliedRequired(itemId, false);
              ShopOrderQuoteStoreModifiers.debouncedOnOrderUpdateSideEffect(
                orderCode,
                () => setIsConfigurationDrawerVisible(false),
              );
            }}
            form={form}
          >
            <ShopQuoteItemBlockEditor orderCode={orderCode} itemId={itemId} />
            <div className={styles.partPriceInfo}>
              <Button htmlType="submit" type="primary" loading={isLoading}>
                {i18nFormat('Apply')}
              </Button>
            </div>
          </Form>
        </div>
      </Drawer>
    </div>
  );
};

ShopQuoteItemAction.displayName = 'ShopQuoteItemAction';
