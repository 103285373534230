import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Button, Empty, Space, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import cn from 'classnames';
import dayjs from 'dayjs';
import { CSSProperties, useEffect, useMemo } from 'react';

import EmptyIcon from '@/assets/svgs/empty.svg';
import { Ellipsis } from '@/commons/components/Element/ellipsis';
import { useAppNavigate } from '@/features/shared';
import {
  useGlobalStore,
  useInquiryMaterialStore,
  useQuoteListStore,
} from '@/stores';

import styles from './index.module.less';
import { OrderActions } from './OrderActions';
import { OrderItemPrintFilesInfo } from './OrderItemPrintFileInfo';
import { OrderStatus } from './OrderStatus';
import { OrderTotalPrice } from './OrderTotalPrice';

export interface OrdersTableProps {
  className?: string;
  style?: CSSProperties;

  isQuoteTable: boolean;
  orders: S.ShopOrder[];

  // 重新加载订单列表
  loadOrders: () => void;
}

export const OrdersTable = ({
  className,
  style,
  orders,
  isQuoteTable,

  loadOrders,
}: OrdersTableProps) => {
  const nav = useAppNavigate();
  const { groupId, loadQuotationGroupId } = useGlobalStore(g => ({
    groupId: g.quotationGroupId,
    loadQuotationGroupId: g.loadQuotationGroupId,
  }));

  const { loadMaterialTypes, loadMaterials } = useInquiryMaterialStore();
  const { queryParams } = useQuoteListStore();

  useEffect(() => {
    loadMaterialTypes();
  }, []);

  useEffect(() => {
    groupId ? loadMaterials(groupId) : loadQuotationGroupId();
  }, [groupId]);

  const columns: ColumnsType<S.ShopOrder> = useMemo(() => {
    const goToOrderDetail = code => {
      if (isQuoteTable) {
        nav.navigateToQuoteDetail(code);
      } else {
        nav.navigateToOrderDetail(code);
      }
    };

    const res: ColumnsType<S.ShopOrder> = [
      {
        align: 'left',
        width: '20%',
        title: i18nFormat('Code'),
        render: (o: S.ShopOrder) => (
          <span
            className={styles.orderCode}
            onClick={() => goToOrderDetail(o.code)}
          >
            <Ellipsis maxWidth="100%">{o.code}</Ellipsis>
          </span>
        ),
      },
      {
        width: '12%',
        title: i18nFormat('Last Updated'),
        render: (o: S.ShopOrder) => {
          const formatStr = 'MM/DD/YYYY';
          const date = o.updatedAt || o.createdAt;

          if (dayjs(date).isValid()) {
            return dayjs(date).format(formatStr);
          }
          return '';
        },
      },
      {
        width: '25%',
        title: i18nFormat('Items & Quantity'),
        render: (o: S.ShopOrder) => (
          <OrderItemPrintFilesInfo order={o} className={styles.rowContent} />
        ),
      },
      {
        width: '13%',
        title: i18nFormat(
          queryParams.orderStatus == 'WAIT_SUBMIT' ? 'SubTotal' : 'Total',
        ),
        render: (o: S.ShopOrder) => <OrderTotalPrice order={o} />,
      },
      {
        width: '15%',
        title: i18nFormat('Status'),
        render: (o: S.ShopOrder) => <OrderStatus order={o} />,
      },
      {
        width: '15%',
        title: i18nFormat('Operate'),
        render: (o: S.ShopOrder) => {
          return (
            <OrderActions
              order={o}
              isQuoteTable={isQuoteTable}
              onRefresh={loadOrders}
            />
          );
        },
      },
    ];

    return res;
  }, [queryParams, isQuoteTable]);

  const empty = (
    <Empty
      image={<EmptyIcon />}
      className={styles.empty}
      description={
        <div className={styles.description}>
          <span>
            {isQuoteTable
              ? i18nFormat('You have no quotes.')
              : i18nFormat('You have not placed an order yet.')}
          </span>
          <Space size={24}>
            <Button onClick={() => nav.navToQuotes()}>
              {i18nFormat('View Your Quotes')}
            </Button>
            <Button type="primary" onClick={() => nav.navToNewQuote()}>
              {i18nFormat('Create a new quote')}
            </Button>
          </Space>
        </div>
      }
    />
  );

  return (
    <div
      id="OrdersTable"
      style={style}
      className={cn(className, styles.container)}
    >
      {!S.isValidArray(orders) && empty}
      {S.isValidArray(orders) && (
        <Table
          rowKey="id"
          columns={columns}
          tableLayout="fixed"
          dataSource={orders}
          pagination={false}
          className={styles.ordersTable}
          rowClassName={(_, i) => cn(i % 2 !== 0 && styles.tableRow)}
        />
      )}
    </div>
  );
};

OrdersTable.displayName = 'OrdersTable';
