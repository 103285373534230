import { InfoCircleOutlined } from '@ant-design/icons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Tooltip } from 'antd';

import styles from './index.module.less';

export const DeliveryTimeTitle = () => {
  const WARNING_TEXT = i18nFormat(
    `Reminder: We are relying on our logistic partners to deliver your order, and the estimations listed here are only for your reference. Order might be delayed due to weather, traffic and other force majeure factors.`,
  );

  return (
    <span className={styles.deliveryTimeTitle}>
      <span>{i18nFormat('Delivery Time')}</span>
      <Tooltip
        title={WARNING_TEXT}
        color="gold"
        className={styles.selectShipOptionsTipsIcon}
      >
        <InfoCircleOutlined />
      </Tooltip>
    </span>
  );
};
