import * as U from '../../../utils';
import { BaseEntity, Id } from '../../type';
import { D3ModelUfcServerProcessStatusMap } from '../d3attr';

export type PrintableFileFormat = 'CLI' | 'USP' | 'UCF' | 'MAGICS';

export type D3ModelFileCompressType =
  | 'none'
  | 'zlib'
  | 'zip'
  | 'gzip'
  | 'zip_dir';

const D3ModelFileTypes = [
  'stl',
  'obj',
  'stp',
  'step',
  '3dm',
  '3ds',
  '3mf',
  'cob',
  'blender',
  'dxf',
  'ply',
  'x3d',
  'gitf',
  'gltf',
  'glb',
  'igs',
  'iges',
  'fbx',
  '3dxml',
  'catpart',
  'x_t',
  'x_b',
] as const;

export type D3ModelFileType = typeof D3ModelFileTypes[number];

export type D3SupportUploadFormatType =
  | D3ModelFileType
  | 'image'
  | 'usp'
  | 'cli'
  | 'zip'
  | 'rar';

export const isStlFileType = (fileName = '') =>
  /^.*\.(stl)$/g.test(fileName.toLocaleLowerCase());

export const getFileType = (fileName = ''): any =>
  fileName.split('.')[fileName.split('.').length - 1].toLocaleLowerCase();

/** 是否为图片文件 */
export const isImageFile = (fileName: string) => {
  const type = getFileType(fileName);

  return ['jpg', 'png', 'bmp', 'jpeg'].includes(type);
};

export class D3FormatAttr extends BaseEntity<D3FormatAttr> {
  /** 合并两个属性 */
  static merge(
    attr1: Partial<D3FormatAttr>,
    attr2: Partial<D3FormatAttr>,
  ): D3FormatAttr {
    const attr = new D3FormatAttr({ ...attr1 });

    Object.keys(attr2 || {}).forEach(k => {
      if (attr2[k]) {
        attr[k] = attr2[k];
      }
    });

    return attr;
  }

  type: D3SupportUploadFormatType;
  /** 兼容老的数据 */
  compressedType: D3ModelFileCompressType;
  compressType: D3ModelFileCompressType;

  /** 这里对于类型进行了二次判断 */
  get derivedType(): D3SupportUploadFormatType {
    if (this.type) {
      return this.type;
    }

    return getFileType(this.name);
  }
  processStatuses: D3ModelUfcServerProcessStatusMap;

  get isStandard3DModel() {
    return D3ModelFileTypes.indexOf(this.derivedType as any) > -1;
  }

  /** 记录来自于客户端处的 UID 信息 */
  uid: string;
  name: string;
  /** 用于进行预览的 STL 文件 */
  stlFileId: Id;
  stlFileUrl: string;
  /** 用于进行预览的 GLB 文件 */
  glbFileId: Id;
  glbFileUrl: string;
  /** @deprecated 仅用于预览的文件的地址，这个地址可能是 stl，可能是 glb，后面会优先使用 glb 进行预览 */
  preViewUrl: string;
  thumbnail: string; // 缩略图信息
  thumbnailFileId: Id; // 缩略图关联的文件 ID

  /** 包含的零件数目 */
  partsNum = 1;
  /** 该体积是用户在选择上传文件时候获取到的 */
  uploadSize: number;
  /** 文件体积 */
  size: number;
  md5: string;
  /** 尺寸，单位是 mm */
  sizeX: number;
  sizeY: number;
  sizeZ: number;
  get topologyDesc() {
    return `x:${U.toFixedNumber(this.sizeX, 0) || '-'}, y:${
      U.toFixedNumber(this.sizeY, 0) || '-'
    }, z:${U.toFixedNumber(this.sizeZ, 0) || '-'} mm`;
  }
  /** 体积，单位是 mm3 */
  volume: number;
  area: number;

  /** 三角面片数量 */
  triangleCnt: number;
  /** 顶点数量 */
  vertexCnt: number;
  edgeCnt: number;
  /** 坏边梳理 */
  badEdgeCnt: number;
  /** 孔洞数量 */
  holeCnt: number;
  /** 厚度 */
  thick: number;
  /** 壳体数量 */
  shellCnt: number;
  /** 翻转梳理 */
  flipped: number;
  /** 非流行边数量 */
  nonManifol: number;
  /** 自相交数量 */
  intersectionCnt: number;
  /** 其他辅助属性 */
  boundary: number;
  /** Admesh 修复信息 */
  admeshProcess: string;

  /** 前端暂存的属性 */
  /** 是否发生过修改，需要保存到服务端 */
  isDirtyVO = false;

  urlExpiresAt: number;

  get hasProcessedByServer() {
    return this.processStatuses && this.processStatuses.hasFinished;
  }

  constructor(props: Partial<D3FormatAttr> = {}) {
    super(props);

    const { isStandard3DModel, ...rest } = props;

    const volume = rest?.volume;

    Object.assign(this, rest, { volume });

    this.compressType = this.compressType || this.compressedType;
    this.compressedType = this.compressType;

    if (!this.type) {
      this.type = getFileType(this.name);
    }

    this.stlFileUrl = this.stlFileUrl || this.preViewUrl;

    if (this.processStatuses) {
      this.processStatuses = new D3ModelUfcServerProcessStatusMap(
        this.processStatuses,
      );
    }
  }
}
