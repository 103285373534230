import {
  DownOutlined,
  InfoCircleOutlined,
  QuestionCircleOutlined,
  RightOutlined,
} from '@ant-design/icons';
import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Button, Collapse, Input, Modal, Space, Tooltip } from 'antd';
import { isNil } from 'lodash-es';
import { useState } from 'react';

import { useInquiryQuoteCheckoutStore } from '@/stores';

import { OrderItemReviewTable } from '../../../../../../order_history/components/OrderItemReviewTable';
import { useAppNavigate } from '../../../../../../shared';
import { OrderPaymentOptions } from '../../order-checkout-fields';
import { OrderCheckoutShipAddressSelect } from '../../order-checkout-fields/OrderShipOptions';
import { SelectExpressOptionsForPayment } from '../../order-checkout-fields/OrderShipOptions/OrderCheckoutExpressOptionsSelect';
import { InquiryOrderCheckoutStep } from '../../order-checkout-fields/types';
import styles from './index.module.less';

export interface OrderQuoteCheckoutProps {
  oceanpaymentRef: any;
}

const WARNING_TEXT = `As long as you want to have some special requirements for the products you order, please enter them in the input box below.`;
const WAIT_PAY_ORDER_WARNING =
  'The order is under payment state, if you want to change this, please contact customer service.';

export const OrderPaymentCheckoutFields = ({
  oceanpaymentRef,
}: OrderQuoteCheckoutProps) => {
  const [visibleSteps, setVisibleSteps] = useState<InquiryOrderCheckoutStep[]>([
    'select-ship-address',
    'select-ship-option',
    'order-remark',
    'payment-methods',
  ]);

  const {
    order,
    quotation,
    orderDeliverAddress,
    remarkText: editingRemarkText,
    setRemarkText,
    saveRemarkText,
    canAutoConfirmAndWaitPay,
  } = useInquiryQuoteCheckoutStore();

  const { navToDealWithPaymentProblem } = useAppNavigate();

  const [reviewModalVisible, setReviewModalVisible] = useState(false);

  const remarkText = editingRemarkText || !!order ? order.remark : '';

  // 已经确认过订单、待支付，不允许修改订单地址、备注等信息
  const isOrderWaitCustomConfirm = order?.status === 'WAIT_CUSTOM_CONFIRM';

  const renderHeader = () => {
    const underReviewing =
      order?.status === 'WAIT_REVIEW' && !canAutoConfirmAndWaitPay;

    return (
      <div className={styles.checkoutHeader}>
        <span className={styles.checkoutText}>
          <span>{i18nFormat('Order Checkout')}</span>
          <span>{i18nFormat('Your Information is Protected')}</span>
          <Button
            type="link"
            disabled={isNil(order)}
            onClick={() => setReviewModalVisible(true)}
            style={{ padding: 0, position: 'absolute', right: 0 }}
          >
            {i18nFormat('Review')} {!!order ? order.printFiles.length : 0}{' '}
            {i18nFormat('Parts')}
          </Button>
        </span>

        {order?.isQuotating && (
          <div className={styles.underQuoting}>
            {i18nFormat('Your order is under quoting.')}
          </div>
        )}

        {underReviewing && (
          <span className={styles.checkoutReviewingTips}>
            <span>
              {i18nFormat(
                `Note: This order is Manual Quote Required. Please wait for our engineer's feedback after placing the order for the final quotation, post-processing fee, and freight.`,
              )}
            </span>
            <span>
              {i18nFormat(
                'We will notify you of the final price by email within 24 hours. (Except special order!)',
              )}
            </span>
          </span>
        )}
      </div>
    );
  };

  const renderCollapseBtn = (step: InquiryOrderCheckoutStep) => {
    if (visibleSteps.includes(step)) {
      return (
        <Button
          icon={<DownOutlined />}
          shape="circle"
          size="small"
          onClick={() => {
            setVisibleSteps(S.addOrRemove(visibleSteps, step));
          }}
        />
      );
    }

    return (
      <Button
        icon={<RightOutlined />}
        shape="circle"
        size="small"
        onClick={() => {
          setVisibleSteps(S.addOrRemove(visibleSteps, step));
        }}
      />
    );
  };

  const contentCantChangeTips = (
    <span className={styles.orderContentCantChangeTips}>
      <Tooltip title={i18nFormat(WAIT_PAY_ORDER_WARNING)}>
        <InfoCircleOutlined />
      </Tooltip>
    </span>
  );

  return (
    <div className={styles.container}>
      {renderHeader()}
      <div className={styles.panelWrapper}>
        <Collapse activeKey={visibleSteps}>
          <Collapse.Panel
            key={'select-ship-address'}
            header={
              <div className={styles.panelHeader}>
                <span>{i18nFormat('Shipping Address')}</span>
                {isOrderWaitCustomConfirm && contentCantChangeTips}
              </div>
            }
            extra={renderCollapseBtn('select-ship-address')}
          >
            <div style={{ marginTop: '-16px' }}>
              <OrderCheckoutShipAddressSelect
                hideChangeBtn={isOrderWaitCustomConfirm}
              />
            </div>
          </Collapse.Panel>
          <Collapse.Panel
            key={'select-ship-option'}
            header={i18nFormat('Shipping Method')}
            extra={
              orderDeliverAddress ? (
                renderCollapseBtn('select-ship-option')
              ) : (
                <Tooltip
                  title={'Please select a shipping address'}
                  color="black"
                  className={styles.orderContentCantChangeTips}
                >
                  <InfoCircleOutlined />
                </Tooltip>
              )
            }
          >
            <SelectExpressOptionsForPayment />
          </Collapse.Panel>
          <Collapse.Panel
            key={'order-remark'}
            header={
              <div className={styles.panelHeader}>
                {i18nFormat('Order Remark')}
                <Tooltip
                  title={i18nFormat(WARNING_TEXT)}
                  color="black"
                  className={styles.orderContentCantChangeTips}
                >
                  <InfoCircleOutlined />
                </Tooltip>
              </div>
            }
            extra={renderCollapseBtn('order-remark')}
          >
            <div className={styles.orderRemarkContainer}>
              {!isOrderWaitCustomConfirm && (
                <Input.TextArea
                  onBlur={saveRemarkText}
                  value={remarkText}
                  onChange={evt => setRemarkText(evt.target.value)}
                  placeholder={i18nFormat(
                    'Please note your special requirements.',
                  )}
                  autoSize
                />
              )}
              {isOrderWaitCustomConfirm && (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {isOrderWaitCustomConfirm && contentCantChangeTips}
                  <div className={styles.orderRemarkTextDisplay}>
                    {remarkText || (
                      <span
                        style={{
                          fontStyle: 'italic',
                          opacity: 0.5,
                          marginLeft: 16,
                        }}
                      >
                        {i18nFormat('No message.')}
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
          </Collapse.Panel>
          <Collapse.Panel
            key={'payment-methods'}
            header={
              <div className={styles.panelHeader}>
                {i18nFormat('Payment Methods')}
              </div>
            }
            extra={
              <Space>
                <Tooltip
                  title={`
                  ${
                    order &&
                    !['USD', 'EUR'].includes(order.currency) &&
                    'PayPal can only pay in US dollars and Euros;'
                  }
                  ${i18nFormat('If payment fails, click here')}`}
                >
                  <Button
                    type="primary"
                    icon={<QuestionCircleOutlined />}
                    size="small"
                    shape="circle"
                    onClick={() => navToDealWithPaymentProblem()}
                  />
                </Tooltip>

                {renderCollapseBtn('payment-methods')}
              </Space>
            }
          >
            <OrderPaymentOptions oceanpaymentRef={oceanpaymentRef} />
          </Collapse.Panel>
        </Collapse>
      </div>

      {order && (
        <Modal
          title={i18nFormat('Checkout {0} parts').replace(
            '{0}',
            (order.printFiles || []).length,
          )}
          open={reviewModalVisible}
          width={'80vw'}
          onOk={() => setReviewModalVisible(false)}
          onCancel={() => setReviewModalVisible(false)}
        >
          <OrderItemReviewTable order={order} quotation={quotation} />
        </Modal>
      )}
    </div>
  );
};
