import * as S from '@unionfab/ufc-shop-commons';
import { genId, uploadRequest } from '@unionfab/ufc-shop-commons';
import {
  createFile,
  getCacheablePreSignedUrl,
  i18nFormat,
} from '@unionfab/ufc-shop-commons';
import { message, Upload } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/es/upload/interface';
import { RcFile } from 'antd/lib/upload';

import { useGlobalStore } from '@/stores';

import {
  AbcFileUploaderComp,
  IAbcFileUploaderProps,
  IAbcFileUploaderState,
} from '../../../../../commons/components/file';

export interface IRemarkUploaderProps extends IAbcFileUploaderProps {
  fileStores?: S.FileStore[];
  // 图片上传数量限制
  fileCount?: number;
  // 已上传图片数量
  uploadedFileCount?: number;

  defaultFileList?: UploadFile[];
  fileList?: ControlledUploadFile[];
  enableDirectory?: boolean; // 是否上传文件夹
  onChange?: (info: UploadChangeParam) => void;
  onUploading?: (o: boolean) => void;
  onDelete?: (fileId: S.Id, fileList: ControlledUploadFile[]) => void;
  onFinishSolely?: (i: ControlledUploadFile[]) => void;
}

export type IRemarkUploaderState = IAbcFileUploaderState;
/** 基于 FileStore 的文件上传组件 */
export class _RemarkUploader extends AbcFileUploaderComp<
  IRemarkUploaderProps,
  IRemarkUploaderState
> {
  error = false;
  fileList: RcFile[] | undefined;

  static defaultProps: Partial<IRemarkUploaderProps> = {
    type: 'IMAGE',
    showUploadList: false,
    buttonType: 'primary',
    maxFileSize: 1024 * 1024 * 1024 * 4,
  };

  state: IRemarkUploaderState = {
    storeAuth: {},
  };

  beforeUpload = async (file: RcFile, fileList: RcFile[]) => {
    if (this.state.isInitializing) {
      message.warning(i18nFormat('The uploader is initializing...'));
      return false;
    }

    const { beforeUpload } = this.props;

    if (beforeUpload && !(await beforeUpload(file, fileList))) {
      return false;
    }

    this.fileList = fileList;
    const fileName = S.escapeStringBugs(file.name);

    const { maxFileSize } = this.props;

    file.uid = genId();

    // 默认最大限制 4096MB，file.size 单位是 B
    const isLtMaxSize = file.size < maxFileSize!;

    if (!isLtMaxSize) {
      message.error(i18nFormat('File must smaller than 4096MB!'));
    }

    if (fileName.length > 250) {
      message.error(i18nFormat('文件名过长'));
      return false;
    }

    return isLtMaxSize;
  };

  /** 处理单个文件上次逻辑 */
  handleChange = async (info: {
    file: ControlledUploadFile;
    fileList: ControlledUploadFile[];
  }): Promise<any> => {
    const { storeAuth } = this.state;
    const { onDelete, type, suffix } = this.props;

    const fileName = S.escapeStringBugs(info.file.name);

    if (info.file.skipCreateFile) {
      const fileId = info.file.uid;
      return {
        ...info.file,
        fileId,
        file: {
          name: info.file.name,
          fileId,
        },
      };
    }

    if (info.file.status === 'removed') {
      if (onDelete) {
        onDelete((info.file as any).id, info.fileList);
      }
    }

    if (info.file.status === 'error') {
      this.error = true;
    }

    if (info.file.status === 'uploading') {
      this.error = false;
    }

    if (info.file.status === 'done') {
      // 如果上传失败，则不触发后续操作
      if (this.error) {
        return;
      }

      const fileStore = this.getFileStore();

      let storeKey;
      let fileUrl;

      if (fileStore.type == 'ALI_OSS') {
        const { url } = storeAuth;

        storeKey = storeAuth.getStoreKey(`${fileName}`);

        fileUrl = `${url}/${storeKey}`;
      } else {
        const { data } = info.file.response;

        storeKey = S.get(data, data => data.data.id) || data.data;
        fileUrl = storeKey;
      }

      const attr = new S.D3FormatAttr({
        name: fileName,
        size: info.file.size,
        uid: info.file.uid,
        type:
          type === 'IMAGE'
            ? 'image'
            : ((
                suffix || ''
              ).toLocaleLowerCase() as S.D3SupportUploadFormatType),
      });

      const fileId = await createFile(fileStore, storeKey, attr);

      // 这里对于上传到局域网环境下的文件需要重新获取下载地址
      if (fileStore.type !== 'ALI_OSS') {
        fileUrl = await getCacheablePreSignedUrl(fileId, fileStore.id);
      }

      const _file = new S.D3ModelFile({
        id: fileId,
        name: fileName,
        attr,
        url: fileUrl,
      });

      return { fileId, fileUrl, file: _file };
    }
  };

  handleUpload = async ({
    file,
    fileList,
  }: {
    file: ControlledUploadFile;
    fileList: ControlledUploadFile[];
  }) => {
    const { onFinishSolely, onUploading, onChange } = this.props;
    onChange({ file, fileList });
    if (!S.isValidArray(fileList) && onFinishSolely) return;

    const statusList = fileList.map(s => s.status);

    if (statusList.includes('uploading')) {
      onUploading && onUploading(true);
    } else {
      onUploading && onUploading(false);
    }

    const allDone =
      fileList.filter(f => f.status == 'done').length == fileList.length;

    if (fileList.length > 1 && allDone) {
      const resp = await Promise.all(
        fileList.map(f => this.handleChange({ file: f, fileList })),
      );

      onFinishSolely(resp);
    } else if (fileList.length == 1) {
      const resp = await this.handleChange({ file, fileList });

      resp && onFinishSolely([resp]);
    }
  };

  render() {
    const {
      children,
      multiple = true,
      showUploadList,
      disabled,
      listType,
      defaultFileList,
      fileList,
      enableDirectory,
      onRemove,
    } = this.props;

    const { storeAuth } = this.state;

    const action = storeAuth?.url;

    const data = { ...storeAuth?.headers, storeAuth };

    return (
      <Upload
        className={this.props.className}
        action={action}
        accept={this.props.accept || this.accept}
        data={data}
        name="file"
        disabled={disabled}
        defaultFileList={defaultFileList}
        fileList={fileList}
        customRequest={(options: any) => {
          uploadRequest({
            ...options,
            store: storeAuth,
          });
        }}
        itemRender={OriginNode => {
          return <div style={{ width: '100%' }}>{OriginNode}</div>;
        }}
        multiple={multiple}
        showUploadList={showUploadList}
        beforeUpload={this.beforeUpload}
        listType={listType}
        directory={enableDirectory}
        onChange={this.handleUpload}
        onRemove={onRemove}
      >
        {children}
      </Upload>
    );
  }
}

export const RemarkUploader = (props: IRemarkUploaderProps) => {
  const { stores } = useGlobalStore();

  return (
    <_RemarkUploader
      {...props}
      fileStores={props.fileStores || (stores as any)}
    />
  );
};

/** 受控的 UploadFile */
export type ControlledUploadFile = UploadFile & {
  fileId?: string;
  file?: S.File;
  skipCreateFile?: boolean;
  fileUrl?: string;
};
