import { flatten, isNil, omitBy, pick } from 'lodash';

import * as S from '../../../../schema';
import {
  getModelFileMapByIdsWithCache,
  getTenantIdFromGConfig,
} from '../../..';
import { AliyunSlsApmTrackerUtils } from '../../../vendor-apis/aliyun-apm';
import { BaseAxiosApi } from '../../shared';

export class UfcShopOrderQueryApi extends BaseAxiosApi {
  /** 查询订单列表 */
  async queryShopOrders(
    params: Partial<S.ShopOrdersQueryParams>,
  ): Promise<{ data: S.ShopOrder[]; pagination: S.PaginationInfo }> {
    const url = `${this.apiConfig.apiEndpoint}/v2/shop/orders`;

    return AliyunSlsApmTrackerUtils.getAliyunSlsApmTracker().runAndSendApiRecord(
      {
        request_url: url,
        api_id: 'get-/v2/shop/orders',
        request_params: JSON.stringify(params || {}),
      },
      async () => {
        const { data = [], extra, pagination } = await this.get<
          S.ShopOrder[],
          S.ShopOrderExtra
        >({ url, params: params });

        const fileIds = flatten(
          data.map(order => (order.items || []).map(i => i.fileId)),
        );

        const orderFileMap = await getModelFileMapByIdsWithCache({ fileIds });

        const orders = data.map(order => {
          const skuIds = (order.items || []).map(i => i.skuId);
          const fileIds = (order.items || []).map(i => i.fileId);

          const fileMap = pick(orderFileMap, fileIds);
          const skus = (pick<Record<string, S.InquiryMaterialSku>, string>(
            (extra || {}).skus!,
            skuIds,
          ) as unknown) as Record<string, S.InquiryMaterialSku>;
          const materialIds = Object.values(skus || {}).map(s => s!.materialId);
          const materials = pick((extra || {}).materials || {}, materialIds);

          return new S.ShopOrder({
            ...order,
            extraVO: { fileMap, skus, materials },
          });
        });

        return { data: orders, pagination };
      },
    );
  }

  /** 获取订单数量 */
  async queryShopOrderStatistics(params: {
    tenantId: string;
    searchText?: string;
    endDateTime?: string;
    startDateTime?: string;
  }) {
    return AliyunSlsApmTrackerUtils.getAliyunSlsApmTracker().runAndSendApiRecord(
      {
        api_id: 'get-/v2/shop/orders/count_by_status',
        request_url: `${this.apiConfig.apiEndpoint}/v2/shop/orders/count_by_status`,
        request_params: JSON.stringify(params || {}),
      },
      async () => {
        const { data } = await this.get<Record<S.InquiryOrderStatus, number>>({
          url: `${this.apiConfig.apiEndpoint}/v2/shop/orders/count_by_status`,
          params: omitBy(params, isNil),
        });
        return data;
      },
    );
  }

  /** 获取最近未支付的订单 */
  async queryRecentUnpaidShopOrders(count: number) {
    return this.queryShopOrders({
      pageNum: 0,
      pageSize: count,
      sortBy: 'desc(updateTime)',
      tenantId: getTenantIdFromGConfig(),
      includes: ['sku'],
      status: ['WAIT_SUBMIT', 'WAIT_REVIEW', 'WAIT_CUSTOM_CONFIRM'],
    });
  }

  /** 获取 cnc 选项们 */
  async getCNCOptions() {
    const { data } = await this.get({
      url: `${this.apiConfig.apiEndpoint}/v2/shop/cnc_options`,
      params: {
        tenantId: getTenantIdFromGConfig(),
      },
    });

    return data;
  }

  /** 查询单个订单 */
  async queryShopOrderByCode(code: string) {
    return AliyunSlsApmTrackerUtils.getAliyunSlsApmTracker().runAndSendApiRecord(
      {
        api_id: 'get-/v2/shop/order/:orderCode',
        request_url: `${this.apiConfig.apiEndpoint}/v2/shop/order/{$code}`,
        request_order_code: code,
      },
      async () => {
        const { data, extra } = await this.get<
          S.ShopOrder,
          {
            materials: Record<string, S.InquiryMaterial>;
            skus: Record<string, S.InquiryMaterialSku>;
          }
        >({
          url: `${this.apiConfig.apiEndpoint}/v2/shop/order/${code}`,
          params: {
            tenantId: getTenantIdFromGConfig(),
            includes: ['material', 'sku', 'itemRemarkFile'],
          },
        });

        const fileMap = await getModelFileMapByIdsWithCache({
          // 空白订单的 items 为空
          fileIds: (data.items || []).map(i => i.fileId),
        });

        return new S.ShopOrder({ ...data, extraVO: { ...extra, fileMap } });
      },
    );
  }

  /** 刷新 quotation 接口报价 */
  async queryQuotation(code: string) {
    return AliyunSlsApmTrackerUtils.getAliyunSlsApmTracker().runAndSendApiRecord(
      {
        api_id: 'get-/v2/shop/order/:orderCode/quotation',
        request_url: `${this.apiConfig.apiEndpoint}/v2/shop/order/${code}/quotation`,
        request_order_code: code,
      },
      async () => {
        const { data } = await this.get<S.ShopOrderQuotation>({
          url: `${this.apiConfig.apiEndpoint}/v2/shop/order/${code}/quotation`,
          params: {
            tenantId: getTenantIdFromGConfig(),
          },
        });

        if (data.hasComputingItem === false) {
          AliyunSlsApmTrackerUtils.getAliyunSlsApmTracker().onEventFinish({
            event_type: 'wait-auto-quotation',
            attr_order_code: code,
            attr_order_quotation_status: data.status,
          });
        }

        return new S.ShopOrderQuotation(data);
      },
    );
  }

  /** 查询报价，这里仅考虑切换了 expressId 与优惠券的情况下 */
  async calcPrice(
    code: string,
    { expressId, couponCode }: { expressId?: string; couponCode?: string } = {},
  ) {
    return AliyunSlsApmTrackerUtils.getAliyunSlsApmTracker().runAndSendApiRecord(
      {
        api_id: 'get-/v2/shop/order/:orderCode/calc_price',
        request_url: `${this.apiConfig.apiEndpoint}/v2/shop/orders`,
        request_body: JSON.stringify(omitBy({ expressId, couponCode }, isNil)),
        request_order_code: code,
      },
      async () => {
        const { data } = await this.post<S.ShopOrderPrice>({
          url: `${this.apiConfig.apiEndpoint}/v2/shop/order/${code}/calc_price`,
          params: {
            tenantId: getTenantIdFromGConfig(),
          },
          data: omitBy({ expressId, couponCode }, isNil),
        });

        if (data.status !== 'COMPUTING') {
          AliyunSlsApmTrackerUtils.getAliyunSlsApmTracker().onEventFinish({
            event_type: 'wait-auto-quotation',
            attr_order_code: code,
            attr_order_quotation_status: data.status,
          });
        }

        return new S.ShopOrderPrice(data);
      },
    );
  }
  // 批量查询报价
  async calcPriceByCoupons(
    code: string,
    {
      expressId,
      couponCodes,
    }: { expressId?: string; couponCodes?: string[] } = {},
  ) {
    return AliyunSlsApmTrackerUtils.getAliyunSlsApmTracker().runAndSendApiRecord(
      {
        api_id: 'get-/v2/shop/order/:orderCode/calc_price_by_coupons',
        request_url: `${this.apiConfig.apiEndpoint}/v2/shop/orders`,
        request_body: JSON.stringify(omitBy({ expressId, couponCodes }, isNil)),
        request_order_code: code,
      },
      async () => {
        const { data } = await this.post<S.ShopOrderPrice[]>({
          url: `${this.apiConfig.apiEndpoint}/v2/shop/order/${code}/calc_price_by_coupons`,
          params: {
            tenantId: getTenantIdFromGConfig(),
          },
          data: omitBy({ expressId, couponCodes }, isNil),
        });
        return data;
        // const shopOrderPrices = Object.entries(data).map(([key, value]) => ({
        //   key: new S.ShopOrderPrice(value),
        // }));

        // return shopOrderPrices;
      },
    );
  }

  /** 查询进度 */
  async queryProgress(code: string) {
    return AliyunSlsApmTrackerUtils.getAliyunSlsApmTracker().runAndSendApiRecord(
      {
        api_id: 'get-/v2/shop/order/:orderCode/progress',
        request_url: `${this.apiConfig.apiEndpoint}/v2/shop/order/${code}/progress`,
        request_order_code: code,
      },
      async () => {
        const { data } = await this.get<S.ShopOrderProgress>({
          url: `${this.apiConfig.apiEndpoint}/v2/shop/order/${code}/progress`,
          params: {
            tenantId: getTenantIdFromGConfig(),
          },
        });

        return new S.ShopOrderProgress(data);
      },
    );
  }

  async queryShopOrderBillingInfo(code: string, withCustomerDefault = true) {
    return AliyunSlsApmTrackerUtils.getAliyunSlsApmTracker().runAndSendApiRecord(
      {
        api_id: 'get-/v2/shop/order/:orderCode/billing_info',
        request_url: `${this.apiConfig.apiEndpoint}/v2/shop/order/${code}/billing_info`,
        request_order_code: code,
      },
      async () => {
        const { data } = await this.get<S.ShopOrderBillingInfo>({
          url: `${this.apiConfig.apiEndpoint}/v2/shop/order/${code}/billing_info?withCustomerDefault=${withCustomerDefault}`,
        });

        return data;
      },
    );
  }
}
