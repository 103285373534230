import { getUrlParameterByName } from '../../../../schema/utils';
import { decodeAccessToken, UfcShopTokenUtils } from '../../../../skeleton';
import { GA4BuiltinEventType } from '../types';

// 当存在某个 origin 时候，再触发某个事件
export const trackGA4ConversionEventWithOrigin = (
  requiredOrigin: 'youtube3dp' | 'googlesearch' | 'freemetal',
  label: GA4BuiltinEventType,
) => {
  const currentOrigin = getUrlParameterByName('origin');

  // 判断是否触发过，如果触发过，则不再触发
  if (localStorage.getItem(label)) {
    return;
  }

  localStorage.setItem(label, new Date().toISOString());

  if (currentOrigin === requiredOrigin) {
    trackGA4BuiltinEvent(label);
  }
};

/** 记录 Google 事件 */
export const trackGA4BuiltinEvent = (
  label: GA4BuiltinEventType,
  data:
    | Gtag.ConfigParams
    | Gtag.ControlParams
    | Gtag.EventParams
    | Gtag.CustomParams = {},
) => {
  try {
    if (typeof gtag !== 'undefined') {
      const user = decodeAccessToken(UfcShopTokenUtils.getAccessToken());

      gtag('event', label, {
        ...(user || {}),
        origin: localStorage.getItem('origin'),
        referer: localStorage.getItem('referer'),
        ...data,
      });
    }
  } catch (_) {
    console.error('>>>GoogleEventTracker>>>trackGA4BuiltinEvent>>>error:', _);
  }
};
