import { isNil } from 'lodash-es';

import { get } from '../../../utils';
import { BaseEntity, Id } from '../../type';
import { D3FormatAttr } from '../format';
import { D3ModelProcessStlFileAttr } from './D3ModelProcessStlFileAttr';
import { D3ModelUfcServerProcessStatusMap } from './D3ModelUfcServerProcessStatusMap';

/** D3 模型文件的解析 属性 */
export class D3ModelProcessInfo extends BaseEntity<D3ModelProcessInfo> {
  /** 缩略图 ID */
  thumbnailFileId: Id;

  /** 缩略图 地址 */
  thumbnail: string;

  sizeY: number;
  sizeZ: number;
  sizeX: number;

  /** 体积 */
  volume: number;

  /** 原始文件（转成STL） 的文件 ID */
  stlFileId: Id;

  /** 原始文件（转成STL） 的文件属性  */
  stlFileAttribute: D3ModelProcessStlFileAttr;

  /** 修复后的文件（转成STL） 的文件 ID  */
  fixedStlFileId: Id;

  /** 修复后的文件（转成STL） 的文件 属性  */
  fixedSTlFileAttribute: D3ModelProcessStlFileAttr;

  /** 文件解析进度状态 */
  processStatuses: D3ModelUfcServerProcessStatusMap;

  /**
   * 文件是否解析成功
   */
  get isSuccess(): boolean {
    if (isNil(this.processStatuses)) {
      return false;
    }

    const {
      decompress,
      stlConvert,
      readRawStlAttribute,
      fixStl,
      readFixedStlAttribute,
      thumbnailStl,
    } = this.processStatuses || {};

    if (decompress && decompress !== 'SUCCESS') {
      return false;
    }
    if (stlConvert && stlConvert !== 'SUCCESS') {
      return false;
    }
    if (readRawStlAttribute && readRawStlAttribute !== 'SUCCESS') {
      return false;
    }
    if (fixStl && fixStl !== 'SUCCESS') {
      return false;
    }
    if (readFixedStlAttribute && readFixedStlAttribute !== 'SUCCESS') {
      return false;
    }
    if (thumbnailStl && thumbnailStl !== 'SUCCESS') {
      return false;
    }

    return true;
  }

  /**
   * 文件解析是否超时
   */
  get isTimeout(): boolean {
    const {
      decompress,
      stlConvert,
      readRawStlAttribute,
      fixStl,
      readFixedStlAttribute,
      thumbnailStl,
    } = this.processStatuses || {};

    if (decompress === 'TIMEOUT') {
      return true;
    }
    if (stlConvert === 'TIMEOUT') {
      return true;
    }
    if (readRawStlAttribute === 'TIMEOUT') {
      return true;
    }
    if (fixStl === 'TIMEOUT') {
      return true;
    }
    if (readFixedStlAttribute === 'TIMEOUT') {
      return true;
    }
    if (thumbnailStl === 'TIMEOUT') {
      return true;
    }

    return false;
  }

  /**
   * 文件解析失败
   */
  get isFailed(): boolean {
    if (isNil(this.processStatuses)) {
      return true;
    }

    const {
      decompress,
      stlConvert,
      readRawStlAttribute,
      fixStl,
      readFixedStlAttribute,
      thumbnailStl,
    } = this.processStatuses || {};

    if (decompress === 'FAILED') {
      return true;
    }
    if (stlConvert === 'FAILED') {
      return true;
    }
    if (readRawStlAttribute === 'FAILED') {
      return true;
    }
    if (fixStl === 'FAILED') {
      return true;
    }
    if (readFixedStlAttribute === 'FAILED') {
      return true;
    }
    if (thumbnailStl === 'FAILED') {
      return true;
    }

    return false;
  }

  /** 文件是否解析完毕 */
  get isProcessing(): boolean {
    const processingStatus = ['PENDING', 'RUNNING'];
    if (isNil(this.processStatuses)) {
      return true;
    }

    const {
      decompress,
      stlConvert,
      readRawStlAttribute,
      fixStl,
      readFixedStlAttribute,
      thumbnailStl,
    } = this.processStatuses || {};

    if (processingStatus.includes(decompress)) {
      return true;
    }
    if (processingStatus.includes(stlConvert)) {
      return true;
    }
    if (processingStatus.includes(readRawStlAttribute)) {
      return true;
    }
    if (processingStatus.includes(fixStl)) {
      return true;
    }
    if (processingStatus.includes(readFixedStlAttribute)) {
      return true;
    }
    if (processingStatus.includes(thumbnailStl)) {
      return true;
    }

    return false;
  }

  /** 将当前值转化为局部的 d3FormatAttr */
  applyToD3FormatAttr(d3FormatAttr: Partial<D3FormatAttr>) {
    if (this.isSuccess) {
      d3FormatAttr.stlFileId = this.stlFileId || this.fixedStlFileId;
      d3FormatAttr.sizeX =
        this.sizeX || get(this.stlFileAttribute, s => s.sizeX);
      d3FormatAttr.sizeY =
        this.sizeY || get(this.stlFileAttribute, s => s.sizeY);
      d3FormatAttr.sizeZ =
        this.sizeZ || get(this.stlFileAttribute, s => s.sizeZ);
      d3FormatAttr.volume =
        this.volume || get(this.stlFileAttribute, s => s.volume);
      d3FormatAttr.thumbnailFileId = this.thumbnailFileId;
      d3FormatAttr.thumbnail = this.thumbnail;
    }

    return d3FormatAttr;
  }

  constructor(props: Partial<D3ModelProcessInfo> = {}) {
    super(props);

    Object.assign(this, props);

    if (props.stlFileAttribute) {
      this.stlFileAttribute = new D3ModelProcessStlFileAttr({
        ...props.stlFileAttribute,
      });
    }

    if (props.fixedSTlFileAttribute) {
      this.fixedSTlFileAttribute = new D3ModelProcessStlFileAttr({
        ...props.fixedSTlFileAttribute,
      });
    }

    if (props.processStatuses) {
      this.processStatuses = new D3ModelUfcServerProcessStatusMap({
        ...props.processStatuses,
      });
    }

    if (
      !this.volume &&
      this.fixedSTlFileAttribute &&
      this.fixedSTlFileAttribute.volume
    ) {
      this.volume = this.fixedSTlFileAttribute.volume;
    }
  }
}
