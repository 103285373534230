import * as S from '@unionfab/ufc-shop-commons';
import { getUfcShopComposedApi, i18nFormat } from '@unionfab/ufc-shop-commons';
import { useDebounceFn } from 'ahooks';
import { Button, message } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import { RcFile } from 'antd/lib/upload';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';

import UploadIcon from '@/assets/svgs/uploadIcon.svg';
import { UfcFileUploader, UploadingEvent } from '@/commons/components/file';
import {
  getNavbarUserEntryStore,
  ShopOrderQuoteStoreModifiers,
  useGlobalStore,
  useInquiryQuoteEditorStore,
  useShopQuoteUiStore,
} from '@/stores';

import { QuoteFilesUploadingList } from '../QuoteFilesUploadingList';
import styles from './index.module.less';
import PlayVideo from './playVideo.svg';
import Backdrop from '@mui/material/Backdrop';
import { createPortal } from 'react-dom';

import ReactPlayer from 'react-player';

export const QuoteFilesUploader = ({
  uploaderId,
  orderCode,
  onBatchUploadSuccess,
  disabled = false,
}: {
  disabled?: boolean;
  uploaderId: string;
  /** orderCode 存在，则表示已经创建了 Order，直接添加，否则跳转到订单详情界面 */
  orderCode?: string;
  onBatchUploadSuccess?: (files: S.D3ModelFile[]) => void;
}) => {
  // 通过上传文件映射，计算出当前是否有上传中的文件
  const uploaderContainerId = `${uploaderId}-container`;

  const [beforeUploadLoading, toggleBeforeUploadLoading] =
    useState<boolean>(false);

  const { run: warnMaxCount } = useDebounceFn(
    () => message.warning(i18nFormat('You can only upload up to 50 models')),
    { wait: 500 },
  );

  const { fileStoreAuth, loadFileStoreAuth, isDemoUser, hasAuthenticated } =
    useGlobalStore();

  const { modelFileMap, addOrUpdateOrderItemByFiles } =
    useInquiryQuoteEditorStore();

  const { curTechCategory: techCollection, uploadingMap } =
    useShopQuoteUiStore();

  useEffect(() => {
    if (!fileStoreAuth) {
      loadFileStoreAuth();
    }
  }, []);

  const notAllowUpload = useMemo(() => {
    /** 外部传入了 disabled = true 直接禁止上传 */
    if (disabled) return true;
    /** token 失效或为 demo 用户禁止上传 */
    // if (!hasAuthenticated || isDemoUser || !fileStoreAuth) return true;
    if (!fileStoreAuth) return true;

    return false;
  }, [disabled, fileStoreAuth]);

  const beforeUpload = async (_file: RcFile, _fileList: RcFile[]) => {
    const mgs_key = 'beforeUpload';

    const isExpried =
      !fileStoreAuth || dayjs(fileStoreAuth.expiresAt).isBefore(dayjs());

    if (isExpried) {
      message.warning({
        key: mgs_key,
        content: i18nFormat(
          'The file store auth has expired. Please try again later.',
        ),
      });

      useGlobalStore.setState({ fileStoreAuth: undefined });

      await loadFileStoreAuth();

      return false;
    }

    if (hasAuthenticated && !isDemoUser) {
      return true;
    }

    /** 匿名登录用户限制文件解析次数 */
    if (hasAuthenticated && isDemoUser) {
      toggleBeforeUploadLoading(true);

      try {
        const resp =
          await getUfcShopComposedApi().ufcShopAuthApi.getAnonymousQuotaInfo();

        const processedCount = S.get(
          resp,
          r => r.withinDay.modelProcess.processedCount,
          0,
        );

        if (processedCount + (_fileList || []).length < 11) {
          toggleBeforeUploadLoading(false);

          return true;
        }

        message.warning({
          key: mgs_key,
          content: i18nFormat(
            'Anonymous user has reached the limit of file parses for today.',
          ),
        });

        toggleBeforeUploadLoading(false);

        return false;
      } catch (e) {
        toggleBeforeUploadLoading(false);

        console.log('>>>err>>>QuoteFilesUploader>>>beforeUpload', e);

        return false;
      }
    }

    return await new Promise<boolean>(res => {
      const entryStore = getNavbarUserEntryStore();

      if (entryStore) {
        if (entryStore.registerStep === 'wait-register') {
          return res(true);
        } else {
        }
      } else {
        res(true);
      }
    });
  };

  const curFileTypes = techCollection?.extensions
    .map(ext => `.${ext}`)
    .join(',');

  const onUploadEvent = (
    evt?: UploadingEvent,
    file?: RcFile | UploadFile | S.D3ModelFile,
  ) => {
    if (evt === 'onUploading') {
      if (file) {
        const { uid } = file;

        useShopQuoteUiStore.setState(state => ({
          uploadingMap: {
            ...state.uploadingMap,
            [uid]: { ...file } as UploadFile,
          },
        }));
      }
    } else if (evt === 'onUploadError') {
      const { uid } = file!;
      useShopQuoteUiStore.setState(state => ({
        uploadingMap: { ...state.uploadingMap, [uid]: undefined },
      }));
    } else if (evt === 'onMaxCountOverflow') {
      warnMaxCount();
    } else if (evt === 'onLargerThanMaxSize') {
      message.warning(i18nFormat('Each individual file size < 200 MB'));
    } else if (evt === 'onNetworkBad') {
      const { uid } = file as S.D3ModelFile;
      useShopQuoteUiStore.setState(state => ({
        uploadingMap: { ...state.uploadingMap, [uid]: file as UploadFile },
      }));

      message.warning(i18nFormat('Network is bad, please try again later'));
    }
  };

  const isUploading =
    Object.keys(uploadingMap).filter(uid => !!uploadingMap[uid]).length > 0;

  const [openedX, setOpenX] = useState(false);
  const openVideoX = () => setOpenX(true);
  const closeVideoX = () => setOpenX(false);
  const videoX = 'https://youtu.be/2llJsr9yfjc?si=v1aZZNkqa588LXe3';
  return (
    <div id={uploaderContainerId}>
      {isUploading && <QuoteFilesUploadingList />}
      <div
        id={uploaderId}
        hidden={isUploading}
        className={styles.d3ModelUploaderContainer}
      >
        <UfcFileUploader
          type="MODEL"
          disabled={notAllowUpload}
          accept={curFileTypes}
          storeAuth={fileStoreAuth!}
          maxFileSize={500 * 1024 * 1024}
          maxCount={50}
          uploadedFileCount={Object.values(modelFileMap).length}
          beforeUpload={beforeUpload}
          onEventHandler={onUploadEvent}
          onSuccess={({ d3ModelFile, rcFile }) => {
            // 提交给模型解析处理程序
            S.defaultD3ModelProcessManager.processModelFileAndWait(
              d3ModelFile,
              {},
            );

            // 因为客户端不需要等体积返回，直接触发 Store 中的模型条目更新
            // 兼容 inquiry 中的添加
            addOrUpdateOrderItemByFiles('uploadSuccess', [d3ModelFile]);

            // 如果传入 onBatchUploadSuccess 的话，由 onBatchUploadSuccess 来触发 uploadingMap 的隐藏
            if (!onBatchUploadSuccess) {
              useShopQuoteUiStore.setState(state => ({
                uploadingMap: {
                  ...state.uploadingMap,
                  [rcFile.uid]: undefined,
                },
              }));

              if (orderCode) {
                // 存在 orderCode 表示在订单详情页，直接添加文件
                ShopOrderQuoteStoreModifiers.addOrUpdateOrderItemByFile(
                  orderCode,
                  [d3ModelFile],
                );
              }
            }
          }}
          // 目前 onBatchUploadSuccess 用于创建空白询价单页面里批量将文件传入订单
          onBatchUploadSuccess={files => {
            useShopQuoteUiStore.setState(state => {
              const newObj = { ...state.uploadingMap };
              for (const { rcFile } of files) {
                newObj[rcFile.uid] = undefined;
              }

              return {
                uploadingMap: newObj,
              };
            });

            if (onBatchUploadSuccess) {
              onBatchUploadSuccess(files.map(file => file.d3ModelFile));
            }
          }}
        >
          <div
            className={styles.uploaderContainer}
            style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
          >
            <Button
              type="primary"
              /** 这边 disabled 不使用 notAllowUpload 是因为诱导未登录用户点击上传按钮后触发登录 */
              disabled={disabled}
              loading={!fileStoreAuth || beforeUploadLoading}
              icon={<UploadIcon style={{ marginRight: '1rem' }} />}
              className={styles.uploaderTitle}
            >
              <span className={styles.uploaderTitleText}>
                {i18nFormat(
                  !fileStoreAuth
                    ? 'Initializing network, please wait.'
                    : 'Upload 3D Models',
                )}
              </span>
            </Button>

            <div className={styles.uploaderDetail}>
              <div>
                {i18nFormat('You can upload multiple files at once')},{' '}
                {i18nFormat('Support up to 50 multiple files upload per time.')}
              </div>
              <div>
                {`${i18nFormat(
                  'Each individual file size < 500 MB',
                )} (${i18nFormat('Recommended file format')}: ${techCollection.type === '3DPRINT'
                  ? '*.stl, *.obj, *.step, *.stp'
                  : '*.step, *.stp, *.dxml , *.catpart'
                  })`}
                {techCollection.type === 'CNC' && (
                  <div>
                    {i18nFormat('Provide dimension tolerance 2D drawing.')}
                  </div>
                )}
              </div>
              {isDemoUser && (
                <div>
                  {i18nFormat(
                    'Anonymous users can only inquire 10 times a day, and there are no restrictions after registration.',
                  )}
                </div>
              )}
            </div>
          </div>
        </UfcFileUploader>
      </div>

      {/* <div className={styles.modelUploaderLaws}>
        {i18nFormat(
          'All uploads are protected by US and European data protection laws.',
        )}
      </div> */}
      <div className={styles.modelUploaderLaws}>
        <div className={styles.centerText}>
          {i18nFormat(
            'All uploads are protected by US and European data protection laws.',
          )}
        </div>
        <div className={styles.rightContent}>
          <span className={styles.rightContentText}>
            <span className={styles.rcWatch} onClick={openVideoX}>
              {i18nFormat('Watch the Video')}
            </span>
            <span style={{ color: '#000000' }}>
              {i18nFormat('How to Use the Instant Quoting Engine')}
            </span>
          </span>
          <PlayVideo onClick={openVideoX} style={{ cursor: 'pointer' }} />
        </div>
      </div>
      {createPortal(
        <Backdrop
          open={openedX}
          style={{ height: '100vh' }}
          onClick={closeVideoX}
          sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        >
          {openedX && (
            <ReactPlayer
              width="75vw"
              height="60%"
              playing={openedX}
              controls={true}
              url={videoX}
            />
          )}
        </Backdrop>,
        document.body,
      )}
    </div>
  );
};
