import dayjs, { Dayjs } from 'dayjs';
import toArray from 'dayjs/plugin/toArray';

dayjs.extend(toArray);

/** 从用户输入的 createdAt 中提取出关联数据，注意，这里的数据是包头不包尾 */
export function getDateRangeFromStr(
  createdAt: string,
  createdAtRange?: [Dayjs, Dayjs],
) {
  let beginCreateTimeScope;
  let endCreateTimeScope;

  const nextDayStr = dayjs()
    .startOf('day')
    .add(1, 'day')
    .add(1, 'minute')
    .format();

  switch (createdAt) {
    case 'today': {
      beginCreateTimeScope = dayjs().startOf('day').format();
      endCreateTimeScope = nextDayStr;
      break;
    }
    case 'in-week': {
      beginCreateTimeScope = dayjs().startOf('week').format();
      endCreateTimeScope = nextDayStr;
      break;
    }
    case 'in-month': {
      beginCreateTimeScope = dayjs().startOf('month').format();
      endCreateTimeScope = nextDayStr;
      break;
    }
    case 'in-year': {
      beginCreateTimeScope = dayjs().startOf('year').format();
      endCreateTimeScope = nextDayStr;
      break;
    }
    case 'custom': {
      const [c1, c2] = createdAtRange || [];
      beginCreateTimeScope = dayjs(c1).startOf('day').format();
      endCreateTimeScope = dayjs(c2).startOf('day').add(1, 'day').format();
      break;
    }
  }

  return [beginCreateTimeScope, endCreateTimeScope];
}

/**
 * 时间长度格式化
 * xx天 xx小时 xx分钟 xx秒
 * @param v 毫秒
 */
export const getDurationFormat = (v: number) => {
  const S = 1000;
  const M = 60 * S;
  const H = 60 * M;
  const D = 24 * H;

  let s = 0;
  let m = 0;
  let h = 0;
  let d = 0;

  let res = '';

  if (v > D) {
    d = Math.floor(v / D);
    res = `${res}${d}天 `;
  }

  if (v > H) {
    h = Math.floor((v - d * D) / H);
    res = `${res}${h}小时 `;
  }
  if (v - h * H > M) {
    m = Math.floor((v - d * D - h * H) / M);
    res = `${res}${m}分钟 `;
  }
  if (v - h * H - m * M > S) {
    s = Math.floor((v - d * D - h * H - m * M) / S);
    res = `${res}${s}秒`;
  }

  return res || '--秒';
};

/** 补全时间范围中月份数据 */
export const fillMonthInDateRange = (startDate: Dayjs, endDate: Dayjs) => {
  if (!!startDate && !!endDate) {
    /** 计算时间范围相差月份数，注意这里不考虑是否足月 */
    const diffMonth = (start: Dayjs, end: Dayjs) => {
      const isStartBeforeEnd = start.isBefore(end);

      const beforeDateArray = dayjs(isStartBeforeEnd ? start : end).toArray();

      const afterDateArray = dayjs(isStartBeforeEnd ? end : start).toArray();

      const diffYear = afterDateArray[0] - beforeDateArray[0];

      const diffMonth = afterDateArray[1] - beforeDateArray[1];

      return diffYear * 12 + diffMonth;
    };

    const dateRanges: Set<string> = new Set([startDate.format()]);

    const loopTimes = diffMonth(startDate, endDate);

    if (loopTimes !== 0) {
      for (let i = 1; i <= loopTimes; i++) {
        dateRanges.add(startDate.add(i, 'M').startOf('M').format());
      }
    }

    dateRanges.add(endDate.format());

    return Array.from(dateRanges);
  }

  return;
};
