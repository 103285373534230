import { BaseEntity } from '../type';

export class Address extends BaseEntity<Address> {
  /** 国家 */
  country: string;

  /** 省份 (一级行政区) */
  province: string;

  /** 市 (二级行政区) */
  city: string;

  /** 区 (三级行政区) */
  district: string;

  /** 街道 (四级行政区) */
  street: string;

  /** 详细地址 */
  detail: string;

  /** 邮编 */
  postcode: string;

  /** 姓名 */
  name: string;

  /** 手机号 */
  phoneNumber: string;

  defaultAddress?: boolean;

  /** 公司名 */
  companyName?: string;

  get formattedString() {
    const str = `${this.country ? `${this.country}, ` : ''}${
      this.province ? `${this.province}, ` : ''
    }${this.city ? `${this.city}, ` : ''}${
      this.district ? `${this.district}, ` : ''
    }${this.street ? `${this.street}, ` : ''}${this.detail ? this.detail : ''}`;

    return str;
  }

  constructor(data: Partial<Address> = {}) {
    super(data);

    Object.assign(this, data);
  }
}

/**
 * 获取地址，根据不同的精度
 */
export function formatAddressDescByUnit(
  address: Address,
  accuracy: 'province' | 'city' | 'district' | 'street' | 'detail',
) {
  if (!address) {
    return '';
  }

  let str = `${address.country ? `${address.country}` : ''}${
    address.province ? `${address.province}` : ''
  }`;

  if (accuracy === 'province') {
    return `${str}***`;
  }

  str = `${str}${address.city ? `${address.city}` : ''}`;
  if (accuracy === 'city') {
    return `${str}***`;
  }

  str = `${str}${address.district ? `${address.district}` : ''}`;
  if (accuracy === 'district') {
    return `${str}***`;
  }

  str = `${str}${address.street ? `${address.street}` : ''}`;
  if (accuracy === 'street') {
    return `${str}***`;
  }

  str = `${str}${address.detail ? `${address.detail}` : ''}`;
  if (accuracy === 'detail') {
    return str;
  }

  return str;
}
