import { i18nFormat } from '@unionfab/ufc-shop-commons';
import * as React from 'react';

import { useAppNavigate } from '@/features/shared';

import styles from './index.module.less';

export const OrderSubmitTerms = () => {
  const nav = useAppNavigate();

  return (
    <div className={styles.orderSubmitorTerms}>
      {i18nFormat('By submit request,you agree to the ')}
      <a
        onClick={() => nav.navigateToPdfPageNewTab('csa')}
        style={{ color: '#f89902' }}
      >
        {i18nFormat('Terms of Service')}.
      </a>{' '}
      {i18nFormat('and')}{' '}
      <a
        onClick={() => nav.navigateToPdfPageNewTab('privacy_policy')}
        style={{ color: '#f89902' }}
      >
        {i18nFormat('Privacy Policy & Agreement')}.
      </a>
    </div>
  );
};
