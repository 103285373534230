import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Descriptions, Popover, Space } from 'antd';

import {
  get,
  getUseShopQuoteStoreByCode,
  useInquiryMaterialStore,
} from '../../../../../../../../../ufc-shop-commons/src';

export default function PopoverContent({
  orderCode,
  itemId,
}: {
  orderCode: string;
  itemId: string;
}) {
  const shopOrderDraft = getUseShopQuoteStoreByCode(orderCode)(
    s => s.shopOrderDraft,
  );

  const itemDraft = shopOrderDraft.items.find(i => i.id === itemId);

  const isCNCService = shopOrderDraft.technology === 'CNC';

  const { materialTypes = [] } = useInquiryMaterialStore();
  const materialV0 = get(itemDraft, data => data.materialVO);
  const materialTypeId =
    get(materialV0, m => m.materialTypeId) ||
    get(materialV0, m => m.materialType.id);
  const materialType = materialTypes.find(m => m.id == materialTypeId);
  const categoryName = get(materialType, m => m.categoryName);

  const cncRequirements = itemDraft?.cncRequirements;

  return (
    <Space
      direction="vertical"
      style={{ maxWidth: 420, maxHeight: 300, overflow: 'auto' }}
    >
      <Descriptions column={1} size="small">
        <Descriptions.Item label={i18nFormat('Process')}>
          {categoryName}
        </Descriptions.Item>
        <Descriptions.Item label={i18nFormat('Material')}>
          {materialV0?.name}
        </Descriptions.Item>
        <Descriptions.Item label={i18nFormat('Finish')}>
          {itemDraft?.handleRemark?.method?.[0] || 'Standard'}
        </Descriptions.Item>
        {!isCNCService && (
          <Descriptions.Item label={i18nFormat('Inserts')}>
            {itemDraft.nutCount}
          </Descriptions.Item>
        )}
        {isCNCService && (
          <>
            <Descriptions.Item label={i18nFormat('Tolerance')}>
              {cncRequirements?.tolerance?.option}
            </Descriptions.Item>
            <Descriptions.Item label={i18nFormat('Surface Roughness')}>
              {cncRequirements?.surfaceRoughness?.option}
            </Descriptions.Item>
            <Descriptions.Item label={i18nFormat('Locational Fit')}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
                {(cncRequirements?.locationFits || []).map((fit, idx) => (
                  <div style={{ lineHeight: 1.2 }} key={idx}>
                    {fit?.option}
                    {fit?.location && (
                      <div style={{ fontWeight: 500, color: 'gray' }}>
                        Locations: {fit.location}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </Descriptions.Item>
            <Descriptions.Item label={i18nFormat('Part Marking')}>
              {cncRequirements?.partMarking?.option}
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <Popover
                  content={
                    <div style={{ maxWidth: 300 }}>
                      Considering that the product structure cannot be
                      manufactured as per the drawings, would you be open to
                      either revising the drawings or producing the product in
                      separate parts and assemblies?
                    </div>
                  }
                >
                  Considering that the pro...
                </Popover>
              }
            >
              {cncRequirements?.allowProductAdapt == null ||
              cncRequirements?.allowProductAdapt === true
                ? ' Yes'
                : ' No'}
            </Descriptions.Item>
            <Descriptions.Item label={i18nFormat('Inspection')}>
              <div>
                {(cncRequirements?.inspectSelection || []).map(ins => (
                  <>
                    <div style={{ fontWeight: 500 }}>{ins.title}</div>
                    <div>{ins.desc}</div>
                  </>
                ))}
              </div>
            </Descriptions.Item>
          </>
        )}
      </Descriptions>
    </Space>
  );
}
