import { calcOrderShipQuotation } from '@unionfab/ufc-shop-commons';
import { useEffect, useMemo } from 'react';

import { useInquiryQuoteCheckoutStore } from '@/stores';

export interface QuoteOrderPrices {
  total?: number;
  isTotalRfq: boolean;
  shipping?: number;
  shippingRfq: boolean;
  subtotal?: number;
  subtotalRfq: boolean;
}

export function useOrderPrices(): QuoteOrderPrices {
  const { order, shipOptionDraft, quotation } = useInquiryQuoteCheckoutStore();

  const customerShipOption =
    !!order && !!order.customerShipOption ? order.customerShipOption : {};

  const shipOption = shipOptionDraft || customerShipOption;

  // 目前 subtotal 即材料费
  const subtotal = quotation?.subtotal;
  const subtotalRfq =
    subtotal == null
      ? true
      : quotation?.subtotalRequiresManualQuote == null
      ? true
      : quotation?.subtotalRequiresManualQuote;

  // 物流费，根据所选物流选项确定
  let shipping: number | undefined;
  let shippingRfq = true;
  if (shipOption?.expressId == null) {
    shipping = undefined;
  } else {
    const t = calcOrderShipQuotation(
      shipOption?.expressId,
      quotation?.shipQuotationsRequiresManualQuote,
      quotation?.shipExpressQuotations,
    );

    shippingRfq = t.requiresManualQuote;
    /** 物流报价需要人工进行报价这里设置为 undefined 展示为 RFQ */
    shipping = shippingRfq ? undefined : t.quotation;
  }

  const minOrderFee = order?.price?.minProductCostFee || 0;

  // 总价 = subtotal + shipping
  const total = useMemo(() => {
    if (!shipping || !subtotal) return;
    return shipping + subtotal + minOrderFee;
  }, [shipping, subtotal, minOrderFee]);

  const isTotalRfq = subtotalRfq || shippingRfq || !total;

  return { isTotalRfq, total, shipping, shippingRfq, subtotal, subtotalRfq };
}
