import { SignInRequest } from '../../../apis';
import { AnonymousUserQuotaInfo, CustomerIdentity, get } from '../../../schema';
import { getTenantIdFromGConfig } from '../../../singleton-ins';
import { TrackerUtils } from '../../vendor-apis/shared/TrackerUtils';
import { BaseAxiosApi, UfcShopApiConfig } from '../shared';

export interface UfcShopAuthParams {
  type?: string;
  username: string;
  password: string;
  captcha?: {
    key: string;
    value: string;
  };
}

export class UfcShopAuthApi extends BaseAxiosApi {
  async login(
    { type, username, password, captcha }: UfcShopAuthParams,
    _apiConfig: UfcShopApiConfig = {},
  ) {
    const { status, data: { idToken } = {}, err } = await this.noauthPost<{
      idToken: string;
    }>({
      url: `${this.apiConfig.apiEndpoint}/v2/auth/login`,
      data: {
        type,
        username,
        password,
        captcha,
      },
    });

    const {
      code: errCode,
      data: { captcha: receivedCaptcha },
    } = err || {
      data: {},
    };

    if (status === 'ok') {
      return {
        personToken: idToken,
      };
    } else {
      return { personToken: null, errCode, captcha: receivedCaptcha };
    }
  }
  catch(e) {
    console.error('>>>UfcShopAuthApi>>>login>>>error: ', e);
    return { personToken: null };
  }

  async getIdToken(
    params: SignInRequest,
  ): Promise<{ idToken: string; customerIdentity: CustomerIdentity }> {
    const url = `${this.apiConfig.apiEndpoint}/v2/shop/auth/login`;

    const { data, extra } = await this.noauthPost<{ idToken: string }>({
      url,
      data: {
        ...params,
        withCustomerIdentity: true,
        tenantId: getTenantIdFromGConfig(),
      },
    });
    return {
      idToken: get(data, r => r.idToken),
      customerIdentity: get(extra, (r: any) => r.customerIdentity),
    };
  }

  async getAccessToken(idToken: string, customerId: string) {
    const url = `${this.apiConfig.apiEndpoint}/v2/shop/auth/token`;

    const { data } = await this.post<{ token: string }>(
      {
        url,
        data: { customerId },
      },
      {
        headers: {
          ...this.apiConfig.headers,
          Authorization: `Bearer ${idToken}`,
        },
      },
    );

    return data.token;
  }

  async getAnonymousAccessToken() {
    const url = `${this.apiConfig.apiEndpoint}/v2/shop/auth/anonymous_token`;

    const { data } = await this.post<{ token: string }>({
      url,
      data: {
        tenantId: getTenantIdFromGConfig(),
        sessionId: TrackerUtils.getUserSessionId(),
      },
    });

    return data.token;
  }

  /** 获取匿名用户文件解析次数 */
  async getAnonymousQuotaInfo() {
    const url = `${this.apiConfig.apiEndpoint}/v2/shop/anonymous_quota`;

    const { data } = await this.get<AnonymousUserQuotaInfo>({
      url,
    });

    return data;
  }
}
