import * as S from '../../../../schema';
import { getUfcShopComposedApi } from '../../../../singleton-ins';
import { getCountriesFully } from '../../order';

let COUNTRIES: S.Country[] | undefined = undefined;
const STATES: { [key: string]: S.Country[] } = {};

export async function fetchCountries(): Promise<S.Country[]> {
  if (COUNTRIES) {
    return COUNTRIES;
  }

  const countries: S.Country[] = await getCountriesFully();
  const iso2Set = new Set();
  let res: S.Country[] = [];
  for (const c of countries) {
    if (!iso2Set.has(c.iso2)) {
      iso2Set.add(c.iso2);
      res.push(c);
    }
  }
  res = res.filter(v => {
    // 台湾 古巴 伊朗 朝鲜 叙利亚
    if (['TWN', 'CUB', 'IRN', 'PRK', 'SYR'].includes(v.iso3)) {
      return false;
    }
    return true;
  });
  COUNTRIES = res;
  return res;
}

/** 全量获取所有国家信息 */
async function getStatesFully(countryCode: string) {
  const {
    data,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.getUmi<{
    data: S.Country[];
  }>(`/world_info/state`, {
    params: { countryCode },
  });

  return (data || []).filter(v => {
    // 乌克兰
    if (countryCode === 'UKR') {
      // 卢甘斯克、顿涅斯克、克里米亚
      if (['09', '14', '43'].includes(v.iso2)) {
        return false;
      }
    }
    return true;
  });
}

export async function fetchStates(countryCode: string): Promise<S.Country[]> {
  const cached = STATES[countryCode];
  if (cached) {
    return cached;
  }

  const res = await getStatesFully(countryCode);
  STATES[countryCode] = res;

  return res;
}
