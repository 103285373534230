import {
  getUseShopQuoteStoreByCode,
  i18nFormat,
  ShopOrderQuoteStoreModifiers,
  useBluePrintRequiredStore,
} from '@unionfab/ufc-shop-commons';
import { Form, Input, Select, Typography } from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';

import useCNCOptions from '../../../../../../../../../ufc-shop-commons/src/stores/quote/shop-order-quote/useCNCOptions';
import { ShopQuoteItemGrayHint } from '../ShopQuoteItemGrayHint';
import { ShopQuoteItemNotesProps } from '../ShopQuoteItemNotes';
import styles from './index.module.less';

export function ShopQuoteItemSurfaceRoughness({
  orderCode,
  itemId,
}: ShopQuoteItemNotesProps) {
  const { options: ROUGHNESS_OPTS = [] } =
    useCNCOptions(s => s.options).surfaceRoughness || {};
  const antdSelectOpts = ROUGHNESS_OPTS.map((opt, idx) => ({
    label: opt,
    value: idx,
  }));
  const item = getUseShopQuoteStoreByCode(orderCode)(s =>
    (s.shopOrderDraft.items || []).find(i => i.id === itemId),
  );
  /** 已经上传了蓝图吗 */
  const hasBlueprint = (item?.remarkFileVO || []).length > 0;
  const originValId = ROUGHNESS_OPTS.indexOf(
    item?.cncRequirements?.surfaceRoughness?.option,
  );
  const formValId = originValId < 0 ? undefined : originValId;
  const setStatus = useBluePrintRequiredStore((s: any) => s.setStatus);

  useEffect(() => {
    const option = item?.cncRequirements?.surfaceRoughness?.option;
    const idx = ROUGHNESS_OPTS.findIndex(opt => opt === option);
    setStatus(itemId + '-surface-roughness', idx > 1);
  }, [item]);

  const onChange = (val: number) => {
    ShopOrderQuoteStoreModifiers.updateOrderDraft(orderCode, o => {
      const item = o.items.find(i => i.id === itemId);
      item.cncRequirements = {
        ...item.cncRequirements,
        surfaceRoughness: {
          ...item.cncRequirements?.surfaceRoughness,
          option: ROUGHNESS_OPTS[val],
        },
      };

      if (val == null) delete item.cncRequirements.surfaceRoughness;

      if (
        val < 4 &&
        item?.cncRequirements?.surfaceRoughness?.otherRemark != null
      ) {
        delete item.cncRequirements.surfaceRoughness.otherRemark;
      }
    });
  };

  const isOtherOpt =
    item?.cncRequirements?.surfaceRoughness?.option === 'Other';

  const blurIpt = (e: ChangeEvent<HTMLInputElement>) => {
    const iptVal = e?.target?.value;
    const hasVal = iptVal != null;
    if (hasVal) {
      ShopOrderQuoteStoreModifiers.updateOrderDraft(orderCode, o => {
        const item = o.items.find(i => i.id === itemId);
        item.cncRequirements = {
          ...item.cncRequirements,
          surfaceRoughness: {
            ...item.cncRequirements?.surfaceRoughness,
            otherRemark: iptVal || '',
          },
        };
      });
    }
  };

  return (
    <>
      <div className={styles.shopQuoteItemSurfaceRoughnessTitle}>
        <Form.Item
          rules={[
            {
              required: !hasBlueprint,
              message: 'Surface Roughness is required',
            },
          ]}
          name="surface-roughness"
          label={
            <Typography.Title level={5}>
              {i18nFormat('Surface Roughness')}
            </Typography.Title>
          }
          initialValue={formValId}
        >
          <Select
            allowClear
            placeholder={i18nFormat('Tap to Select')}
            style={{ maxWidth: 220 }}
            options={antdSelectOpts}
            onChange={onChange}
            value={formValId}
          />
        </Form.Item>

        {isOtherOpt && (
          <Form.Item
            style={{ maxWidth: 220 }}
            name="roughness-other"
            rules={[
              {
                required: true,
                message: i18nFormat('Surface Roughness is required.'),
              },
            ]}
            initialValue={item?.cncRequirements?.surfaceRoughness?.otherRemark}
          >
            <Input
              placeholder={i18nFormat('Please input Surface Roughness value')}
              onBlur={blurIpt}
            />
          </Form.Item>
        )}

        <ShopQuoteItemGrayHint>
          {i18nFormat(
            'Surface roughness will be held to 125uin/3.2um Ra unless otherwise specified.',
          )}
        </ShopQuoteItemGrayHint>
      </div>
    </>
  );
}

ShopQuoteItemSurfaceRoughness.displayName = 'ShopQuoteItemSurfaceRoughness';
