import Icon from '@ant-design/icons';
import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import cn from 'classnames';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import { useMemo } from 'react';

import CheckIcon from '@/assets/svgs/check.svg';
import { useOrderDetailsStore } from '@/stores';

import styles from './index.module.less';
import { getOverseaOrderCurrentSteps, OverseaOrderSteps } from './utils';

interface OrderCurrentStepProps {}

export const OrderCurrentStep = ({}: OrderCurrentStepProps) => {
  const { order, loadingOrder, orderStepTimeMap } = useOrderDetailsStore();

  const steps = useMemo(
    () => getOverseaOrderCurrentSteps({ order, orderStepTimeMap }),
    [order, loadingOrder, orderStepTimeMap],
  );

  const renderStepItem = (i: OverseaOrderSteps, last: boolean) => {
    const { label, updateAt, icon, isActive } = i;

    const formatTemp = 'MM/DD/YYYY HH:mm:ss';
    const hasMiddleState = !isNil(i.middleState) && i.middleState.isActive;

    return (
      <div key={i.label} className={styles.stepItem}>
        <span className={cn(styles.icon, isActive && styles.activeIcon)}>
          {isActive ? (
            <CheckIcon />
          ) : (
            <Icon component={icon} style={{ fontSize: '2rem' }} />
          )}
          <div className={styles.description}>
            <span>{i18nFormat(label)}</span>
            <span>
              {updateAt && dayjs(updateAt).isValid()
                ? dayjs(updateAt).format(formatTemp)
                : '-'}
            </span>
          </div>
        </span>
        {!last && (
          <div
            className={cn(
              styles.lineWrapper,
              isActive && styles.activeLine,
              hasMiddleState && styles.middleState,
            )}
          >
            <>
              {isActive && <span className={styles.line} />}
              {(hasMiddleState || !isActive) && (
                <span className={styles.middleLine} />
              )}

              {hasMiddleState && (
                <span className={styles.label}>
                  {S.get(i, i => i.middleState.label)}
                </span>
              )}
            </>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {steps.map((s, i) => renderStepItem(s, i + 1 == steps.length))}
    </div>
  );
};
