import { i18nFormat, getLocale } from '@unionfab/ufc-shop-commons';
import * as S from '@unionfab/ufc-shop-commons';
import { Modal, Typography } from 'antd';
import cn from 'classnames';
import { useState } from 'react';

import {
  getUseShopQuoteStoreByCode,
  ShopOrderQuoteStoreModifiers,
} from '@/stores';

import { ColorSelect } from '../../../../../../../commons/components/Element';
import { InquiryMaterialSkuSelect } from '../../../../../components/select/InquiryMaterialSkuSelect';
import styles from './index.module.less';
import { ShopQuoteItemMaterialGallery } from './ShopQuoteItemMaterialGallery';
export interface ShopQuoteItemMaterialSelectProps {
  className?: string;

  itemId: string;
  orderCode: string;
}

export const ShopQuoteItemMaterialSelect = ({
  itemId,
  orderCode,
  className,
}: ShopQuoteItemMaterialSelectProps) => {
  const [modalVisible, toggleModalVisible] = useState<boolean>(false);

  const { item, orderDraft } = getUseShopQuoteStoreByCode(orderCode)(s => ({
    orderDraft: s.shopOrderDraft,
    item: (s.shopOrderDraft.items || []).find(i => i.id === itemId),
  }));

  const onUpdateItemMaterial = ({
    material,
    sku,
    closeModal = false,
  }: {
    closeModal?: boolean;
    sku?: S.InquiryMaterialSku;
    material: S.InquiryMaterial;
  }) => {
    ShopOrderQuoteStoreModifiers.updateOrderDraft(orderCode, o => {
      const item = o.items.find(i => i.id === itemId);
      // 这里因为前端部分显示使用了 VO 数据，所以直接赋值
      item.materialVO = material;

      if (sku || S.isValidArray(material.spuList)) {
        item.skuId = sku ? sku.id : material.spuList[0].id;
        item.materialSkuVO = sku || material.spuList[0];
      }
    });

    closeModal && toggleModalVisible(false);
  };

  return (
    <div
      id="ShopQuoteItemMaterialSelect"
      className={cn(className, styles.container)}
    >
      <div className={styles.shopQuoteItemMaterialSelectTitle}>
        <Typography.Title level={5}>{i18nFormat('Material')}</Typography.Title>
        <a
          href={'https://www.unionfab.com/materials'}
          target="_blank"
          rel="noreferrer"
        >
          {i18nFormat('Learn about our materials')}
        </a>
        {getLocale() === 'ru' && (
          <a
            className={styles.comparePrice}
            href={
              'https://www.unionfab.com/blog/2024/06/quotation-guide-russian'
            }
            target="_blank"
            rel="noreferrer"
          >
            {i18nFormat('Quotation Guide')}
          </a>
        )}
      </div>
      <div className={styles.flexStart} style={{ width: '80%' }}>
        <InquiryMaterialSkuSelect
          filterOptions={{
            techCategory: S.ShopOrderAccessors.getCurTechCategory(orderDraft),
          }}
          value={item?.skuId}
          style={{ flex: '1 1 1px', marginRight: '1rem' }}
          onChange={(_skuId, { material, materialSku }) => {
            onUpdateItemMaterial({ material, sku: materialSku });
          }}
        />
        {/* <Button onClick={() => toggleModalVisible(true)}>
          {i18nFormat('See All')}
        </Button> */}
      </div>

      <Modal
        width={850}
        footer={null}
        open={modalVisible}
        title={i18nFormat('Material')}
        onCancel={() => toggleModalVisible(false)}
        className={styles.shopQuoteItemMaterialGalleryModal}
      >
        <ShopQuoteItemMaterialGallery
          techCategory={S.ShopOrderAccessors.getCurTechCategory(orderDraft)}
          onChange={m =>
            onUpdateItemMaterial({ material: m, closeModal: true })
          }
        />
      </Modal>
    </div>
  );
};

ShopQuoteItemMaterialSelect.displayName = 'ShopQuoteItemMaterialSelect';
