import * as S from '../../../../schema';
import {
  getTenantIdFromGConfig,
  getUfcShopComposedApi,
} from '../../../../singleton-ins';
import { getAllByPage } from '../../common/types';

export const updateExpressMethod = async (
  expressUpdatingBody: S.ExpressMethodRequest,
) => {
  return await getUfcShopComposedApi().umiRequestCompatibleApi.postUmi(
    `/inquiry/express/express_timing_rel`,
    {
      data: { ...expressUpdatingBody },
    },
  );
};

export const getExpressMethod = async () => {
  const {
    data,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.getUmi<{
    data: S.ExpressMethodResponse[];
  }>(`/inquiry/express/express_timing_rel`);
  return data || [];
};

export const updateExpressTiming = async (
  expressTimingBody: S.ExpressTiming,
) => {
  return await getUfcShopComposedApi().umiRequestCompatibleApi.postUmi(
    `/order/express/timing`,
    {
      data: {
        ...expressTimingBody,
      },
    },
  );
};

export const getExpressTimingList = async ({
  pageNum,
  pageSize,
  country,
}: {
  pageNum?: number;
  country?: string;
  pageSize?: number;
}) => {
  const {
    data,
    meta,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.getUmi<{
    data: S.ExpressTiming[];
    meta: S.Meta;
  }>(`/order/express/timing`, {
    params: {
      country,
      pageNum,
      pageSize: pageNum === undefined ? undefined : pageSize,
      asc: true,
    },
  });
  return { data, meta };
};
export const getExpressTimingListAll = async () => {
  return getAllByPage<any>(async ({ pageNum, pageSize }) => {
    const {
      data,
      meta,
    } = await getUfcShopComposedApi().umiRequestCompatibleApi.getUmi<{
      data: S.ExpressTiming[] | any;
      meta: S.Meta;
    }>(`/order/express/timing`, {
      params: {
        // pagination: {
        pageNum,
        pageSize: 100,
        asc: true,
        // },
      },
    });
    return { data, meta };
  });
};

export const updateExpressTimingStatus = async (
  expressTimingId: string,
  expressStatus: S.ExpressStatus,
) => {
  const {
    status,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.postUmi<{
    status: string;
  }>(`/order/express/timing/${expressTimingId}/status/${expressStatus}`);
  return { status };
};

export const getExpressMethodListForCustomer = async (tenantId?: S.Id) => {
  const {
    data,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.getUmi<{
    data: S.Express[];
  }>(`/customer/order/express`, {
    params: { tenantId: getTenantIdFromGConfig() },
  });
  return data || [];
};

export const getExpressTimingListForCustomer = async (tenantId?: S.Id) => {
  const {
    data,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.getUmi<{
    data: S.ExpressMethodResponse[];
  }>(`/customer/order/express/timing`, {
    params: { tenantId: getTenantIdFromGConfig() },
  });
  return data || [];
};

/**
 * 邮费计算
 */
export const calcExpressQuotation = async (orderId: S.Id) => {
  const {
    data,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.postUmi<{
    data: Record<string, S.CalcExpressQuotationResponse>;
  }>(`/inquiry_order/${orderId}/calc_express_quotation`, {
    data: {},
  });
  return data;
};

/** 获取询价单物流信息 */
export async function getInquiryExpressInfo(
  expressNumber: string,
  orderCode: string,
  tenantId: S.Id,
) {
  const {
    data,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.getUmi<{
    data: S.ExpressDetail;
  }>(`/inquiry/${orderCode}/express/${expressNumber}`, {
    params: { tenantId },
  });

  if (!!data && S.isValidArray(data.list)) {
    data.list.sort(
      (a, b) => new Date(b.time).getTime() - new Date(a.time).getTime(),
    );
  }

  return data;
}
