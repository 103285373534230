import * as S from '@unionfab/ufc-shop-commons';
import {
  i18nFormat,
  useBluePrintRequiredStore,
} from '@unionfab/ufc-shop-commons';
import { Form, message, Typography } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import cn from 'classnames';
import { CSSProperties, useEffect, useState } from 'react';

import UploadIcon from '@/assets/svgs/uploadIcon_dark.svg';
import { TechDrawingUploader } from '@/features/new_quote/components/uploader/TechDrawingUploader';
import {
  getUseShopQuoteStoreByCode,
  ShopOrderQuoteStoreModifiers,
} from '@/stores';

import { ControlledUploadFile } from '../../../../../components/uploader/RemarkUploader';
import { useCNCFormStore } from '../../ShopQuoteItemsTable/ShopQuoteTableItemAction/useCNCForm';
import styles from './index.module.less';

export interface ShopQuoteItemDrawingsProps {
  className?: string;
  style?: CSSProperties;

  orderCode: string;
  itemId: string;
}

export const ShopQuoteItemDrawings = ({
  className,
  style,
  orderCode,
  itemId,
}: ShopQuoteItemDrawingsProps) => {
  const [messageApi, contextHolder] = message.useMessage();
  const locationsAndOtherRemarkStatus =
    useBluePrintRequiredStore((d: any) => d.locationsAndOtherRemarkStatus) ||
    [];
  const curItemLocationsAndOtherRemarkStatus = [
    ...locationsAndOtherRemarkStatus,
  ].filter(status => status[0].startsWith(itemId));
  const { item, shopOrder } = getUseShopQuoteStoreByCode(orderCode)(s => ({
    shopOrder: s.shopOrderDraft,
    item: (s.shopOrderDraft.items || []).find(i => i.id === itemId),
  }));

  const isCNCTech = shopOrder.technology === 'CNC';

  const techCategory = S.ShopOrderAccessors.getCurTechCategory(shopOrder);

  /** 如果订单工艺类型是 3dp，是否需要上传图纸文件 */
  const isNeedUploadingFile_3DP =
    !isCNCTech &&
    ((techCategory && techCategory.type == 'CNC') ||
      S.get(item, i => i.nutCount, 0) > 0);

  /** 已设置过了 Location，则蓝图必传 */
  const setLocationsAndOtherRemark = curItemLocationsAndOtherRemarkStatus.some(
    pair => pair[1] === true,
  );
  /** 如果订单工艺类型是 cnc，是否需要上传图纸文件 */
  const isNeedUploadingFile_CNC = isCNCTech && setLocationsAndOtherRemark;
  const [uploadFileList, setList] = useState(
    (item?.remarkFileVO || []).map(f => ({
      id: f.id,
      uid: f.id,
      name: f.name,
      status: 'done',
      skipCreateFile: true,
    })),
  );

  const form = useFormInstance();

  const onFileAdd = (fileInfos: Record<'name' | 'id', string>[]) => {
    ShopOrderQuoteStoreModifiers.updateOrderDraft(orderCode, o => {
      const targetItem = o.items.find(i => i.id === itemId);
      if (targetItem) {
        targetItem.remarkFileVO = fileInfos;

        targetItem.remark.fileIds = (fileInfos || []).map(f => f.id);

        if (isCNCTech) {
          targetItem.cncRequirements = {
            ...targetItem.cncRequirements,
            bluePrintFileId: targetItem.remark.fileIds,
          };
          setTimeout(form.validateFields, 500); // 消除已有的 antd 表单红字验证提示
        }
      }
    });
    updateFileListWhenDone(fileInfos);
    message.success(
      i18nFormat('Technical drawing file has been uploaded successfully'),
    );
  };

  const { setAppliedRequired, openedDrawer } = useCNCFormStore();

  const onFileDelete = (fileId: string) => {
    setAppliedRequired(itemId, true);
    ShopOrderQuoteStoreModifiers.updateOrderDraft(orderCode, o => {
      const targetItem = o.items.find(i => i.id === itemId);

      if (targetItem) {
        targetItem.remarkFileVO = (targetItem.remarkFileVO || []).filter(
          f => f.id !== fileId,
        );

        targetItem.remark.fileIds = S.get(
          targetItem,
          t => t.remark.fileIds,
          [],
        ).filter(i => i !== fileId);
      }

      if (isCNCTech) {
        targetItem.cncRequirements = {
          ...targetItem.cncRequirements,
          bluePrintFileId: targetItem.remark.fileIds,
        };

        if (
          targetItem.remark.fileIds == null ||
          targetItem.remark.fileIds.length === 0
        )
          delete targetItem.cncRequirements.bluePrintFileId;
      }
    });

    setList(list => list.filter(f => f.uid !== fileId));
    message.success(
      i18nFormat('Technical drawing file has been removed successfully'),
    );
  };

  // 当关闭抽屉的时候，文件列表初始化
  useEffect(() => {
    if (!openedDrawer) {
      setList(
        (item?.remarkFileVO || []).map(f => ({
          id: f.id,
          uid: f.id,
          name: f.name,
          status: 'done',
          skipCreateFile: true,
        })),
      );
    }
  }, [openedDrawer]);

  return (
    <div className={cn(className, styles.container)} style={style}>
      {contextHolder}
      <div className={styles.flexStart} style={{ width: '80%' }}>
        <Form.Item
          rules={[
            {
              validator() {
                if (isNeedUploadingFile_CNC || isNeedUploadingFile_3DP) {
                  if (item?.remarkFileVO?.length > 0) return Promise.resolve();
                  return Promise.reject('Technical drawing is required');
                }
                return Promise.resolve();
              },
            },
          ]}
          name="blueprint"
          label={
            <Typography.Title level={5}>
              {i18nFormat('Technical drawing')}
            </Typography.Title>
          }
        >
          <TechDrawingUploader
            isRequired={isNeedUploadingFile_3DP || isNeedUploadingFile_CNC}
            uploaderClassName={styles.uploader}
            onDelete={onFileDelete}
            onFinishSolely={(_, fileInfos) => onFileAdd(fileInfos)}
            isCNCTip={isCNCTech}
            fileList={uploadFileList as ControlledUploadFile[]}
            onChange={updateFileListOnChange}
          >
            <div className={styles.uploaderContent}>
              <UploadIcon />
              <span className={styles.desc}>
                {i18nFormat('Upload 2D Drawing')}
              </span>
            </div>
          </TechDrawingUploader>
        </Form.Item>
      </div>
    </div>
  );

  /** fileList 受控，要在 onChange 的时候进行更新 */
  function updateFileListOnChange({ fileList }) {
    const fileArr = [];
    fileList.forEach(f => {
      fileArr.push({ ...f });
    });
    setList(fileArr);
  }

  /** fileList 受控，在文件上传完成后更新 */
  function updateFileListWhenDone(fileInfos: any[]) {
    const newUploadFileList = [];
    fileInfos.forEach(f => {
      newUploadFileList.push({
        ...f,
        uid: f.id || f.uid,
        name: f.name,
        status: 'done',
      });
    });
    setList(newUploadFileList);
  }
};

ShopQuoteItemDrawings.displayName = 'ShopQuoteItemDrawings';
