import { BaseEntity, File, Id } from '../../common';
import { User } from '../../user';

export const FeedbackReasonCodes = [
  'DEFORMED',
  'WASH',
  'SCRAPE',
  'PRINT',
  'POLISH',
  'LOST',
] as const;

export type FeedbackReasonCode = typeof FeedbackReasonCodes[number];

export const getInquiryOrderFeedbackDescByCode = (code: FeedbackReasonCode) => {
  switch (code) {
    case 'DEFORMED':
      return '变形';
    case 'WASH':
      return '洗坏';
    case 'SCRAPE':
      return '刮坏';
    case 'PRINT':
      return '打坏';
    case 'POLISH':
      return '磨坏';
    case 'LOST':
      return '洗丢';
    default:
      return '未知';
  }
};

export const DefectiveHandleMethods = [
  'REPRINT_PART',
  'HANDLER_FIX_PART',
] as const;

export type DefectiveHandleMethod = typeof DefectiveHandleMethods[number];

export class InquiryOrderFeedbackItem extends BaseEntity<InquiryOrderFeedbackItem> {
  /** 打印件数 */
  count: number;
  /** 打印套数 */
  partCountPerFile: number;

  fileId: Id;
  file: File;

  /** ！注意，这里的 orderItemId 实际上指的是 printFileId */
  orderItemId: Id;
  printFileId: Id;

  constructor(props: Partial<InquiryOrderFeedbackItem> = {}) {
    super(props);

    Object.assign(this, props);

    this.printFileId = this.orderItemId;
  }
}

export class InquiryOrderFeedback extends BaseEntity<InquiryOrderFeedback> {
  orderId: Id;
  reasonCode: FeedbackReasonCode;
  submitAt: string;

  submitUserId: Id;
  submitUser: User;

  /** 手动处理 or 放弃处理 */
  ignore: boolean;

  imageIds: Id[] = [];
  // 图片映射
  imageUrls: Record<string, string> = {};
  items: InquiryOrderFeedbackItem[];

  handleMethod: DefectiveHandleMethod;
  handledAt: string;
  handlerId: Id;
  handler: User;
  remakeOrderId: Id;
  remark?: string;

  delay: boolean;

  constructor(props: Partial<InquiryOrderFeedback> = {}) {
    super(props);

    Object.assign(this, props);

    if (typeof this.delay === 'undefined') {
      this.delay = false;
    }

    if (this.items) {
      this.items = this.items.map(i => new InquiryOrderFeedbackItem(i));
    }

    if (this.submitUser) {
      this.submitUser = new User(this.submitUser);
    }
  }
}
