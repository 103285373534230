import { Route, Routes } from 'react-router-dom';

import { QuoteEditorContainer } from './NewQuote';
import { QuoteEditor } from './NewQuote/QuoteEditor';

export default () => {
  return (
    <Routes>
      <Route path="/" element={<QuoteEditorContainer />}>
        <Route path="/" element={<QuoteEditor />} />
      </Route>
      <Route path="*" element={<QuoteEditor />} />
    </Routes>
  );
};
