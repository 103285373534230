import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Button, InputNumber, Modal } from 'antd';
import cn from 'classnames';

import { ThreadTable } from '@/commons/components/KnowledgeBase/ThreadTable';
import { useInquiryQuoteEditorStore } from '@/stores';

export const OrderItemPunchCount = ({
  orderItem,
  className,
}: {
  orderItem: S.InquiryOrderItem;
  className?: string;
}) => {
  const { updateInquiryOrder } = useInquiryQuoteEditorStore();
  const curPunchCount = orderItem.printFiles[0]?.punchCount || 0;

  const changePunchCount = (count: number | null) => {
    updateInquiryOrder('changeOrderItemPunchCount', () => {
      orderItem.printFiles[0].punchCount = count == null ? undefined : count;
    });
  };

  const punchCountText = (
    <Button
      type="link"
      style={{ marginLeft: 5, marginRight: 5, padding: 0 }}
      onClick={() => {
        Modal.info({
          icon: '',
          width: '632px',
          closable: true,
          okButtonProps: { style: { display: 'none' } },
          bodyStyle: { padding: 0 },
          content: <ThreadTable />,
        });
      }}
    >
      {i18nFormat('threads and Tapped')}
    </Button>
  );

  return (
    <span className={cn(className)}>
      <span>
        {i18nFormat('Specify the number of')}
        {punchCountText}
        {i18nFormat('in this part')}
      </span>
      <InputNumber
        size="small"
        style={{ marginLeft: 5 }}
        min={0}
        value={curPunchCount}
        onChange={changePunchCount}
      />
    </span>
  );
};
