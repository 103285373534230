import { AxiosError } from 'axios';
import {
  DefaultOptions,
  QueryClient,
  UseMutationOptions,
  UseQueryOptions,
} from 'react-query';
import { PromiseValue } from 'type-fest';

const queryConfig: DefaultOptions = {
  queries: {
    useErrorBoundary: true,
    refetchOnWindowFocus: false,
    retry: false,
    suspense: false,
    cacheTime: 3600,
  },
};

export const queryClient = new QueryClient({ defaultOptions: queryConfig });

// eslint-disable-next-line
export type QueryConfig<
  FetcherFnType extends (...args: any) => any
> = UseQueryOptions<PromiseValue<ReturnType<FetcherFnType>>>;

/* eslint-disable */
export type MutationConfig<
  FetcherFnType extends (...args: any) => any
> = UseMutationOptions<
  PromiseValue<ReturnType<FetcherFnType>>,
  AxiosError,
  Parameters<FetcherFnType>[0]
>;
/* eslint-enable */
