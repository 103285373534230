import * as S from '@unionfab/ufc-shop-commons';

import { OrderItemSummary as Desktop } from './OrderItemSummaryDesktop';
import { OrderItemSummary as Mobile } from './OrderItemSummaryMobile';

export const OrderItemSummary = ({
  size,
  orderItem,
}: {
  orderItem: S.InquiryOrderItem;
  size?: 'mobile' | 'desktop';
}) => {
  if (size === 'mobile') {
    return <Mobile orderItem={orderItem} />;
  } else {
    return <Desktop orderItem={orderItem} />;
  }
};
