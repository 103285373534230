import { isNil } from 'lodash';

import { Address } from '../../../common';
import { DeliverMethod, DeliverType } from './delivery-types';

export class DeliverAddress extends Address {
  // 交付方式
  deliverType: DeliverType;
  // 发货方式
  deliverMethod: DeliverMethod;
  // 交货人，暂时无用
  consignorName: string;
  // 收货人
  recipient: string;
  // 收货人联系电话
  contactInfo: string;

  // 物流单号
  expressNumber: string[] = [];
  // 物流公司
  expressCompany: string;

  // 自取-取件时间
  receiveTime: string;
  // 自取-取件人
  receiverName: string;
  // 自取-取件人联系电话
  receiverPhoneNumber: string;

  /** 收货地址全写 */
  receiveAddress: string;

  /** 是否选择该地址 */
  isSelected: boolean;

  /** 是否源自原订单的信息（需要手动标注） */
  isFromOriginrOrder?: boolean;

  /** 运输天数 */
  shippingDays?: number;

  /** 发货人联系方式 */
  senderPhoneNumber: string;

  transportMethodId: string;

  constructor(data?: Partial<DeliverAddress>) {
    super(data);

    Object.assign(this, data);

    if (!this.deliverType) {
      this.deliverType = 'EXPRESS_DELIVER';
    }

    if (isNil(this.isSelected) && this.id) {
      this.isSelected = true;
    }

    /**
    if (!this.expressCompany) {
      this.expressCompany = '顺丰速运';
    }
     **/

    if (!this.contactInfo) {
      this.contactInfo = this.phoneNumber;
    }

    if (!this.phoneNumber) {
      this.phoneNumber = this.contactInfo;
    }

    if (this.expressNumber && typeof this.expressNumber === 'string') {
      // 后端 expressNumber 为以 / 分隔的 string 类型这里转化为 string[]
      const expressNumberAsList = ((this.expressNumber as string) || '').split(
        '#',
      );
      this.expressNumber = expressNumberAsList;
    }
  }
}
