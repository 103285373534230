import { InfoCircleOutlined } from '@ant-design/icons';
import {
  getCustomerSurveyHistoryByPerson,
  getTenantIdFromGConfig,
  submitCustomerSurveyByPerson,
  useProfileStore,
} from '@unionfab/ufc-shop-commons';
import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Button, Input, message, Modal, Popover, Radio, Select } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import MY_VIP from '@/assets/svgs/MyVIP.svg';
import { useAppNavigate } from '@/features/shared';
import { useInquiryCouponStore } from '@/stores';

import styles from './index.module.less';

export interface InquirySurveyModalProps {
  className?: string;
  style?: Record<string, string | number>;
  finishQuestionnaire?: () => void;
}

export const InquirySurveyModal = ({ reload }: { reload?: () => void }) => {
  const nav = useAppNavigate();

  const newCouponInfo = useInquiryCouponStore(c => c.newCouponInfo);

  const [showModal, setShowModal] = React.useState<
    'survey' | 'congratulation'
  >();

  const [isShowVipMove, setIsShowVipMove] = React.useState<boolean>();

  const [discoverFromList, setDiscoverFromList] = React.useState<string[]>([]);
  const [otherDiscoverFrom, setOtherDiscoverFrom] = React.useState<string>('');

  const [position, setPosition] = React.useState<string>();
  const [industries, setIndustries] = React.useState<string>();
  const [applications, setApplications] = React.useState<string>();
  const [registerSurvey01, setRegisterSurvey01] =
    React.useState<S.InquirySurvey>();

  const { accountInfo } = useProfileStore(a => ({
    accountInfo: a.accountInfo,
  }));

  const loadSurvey = async () => {
    const survey01List = await getCustomerSurveyHistoryByPerson({
      type: 'registration_01',
      tenantId: getTenantIdFromGConfig(),
    });

    survey01List && survey01List[0] && setRegisterSurvey01(survey01List[0]);
  };

  // React.useEffect(() => {
  //   const lastPage = S.getUrlParameterByName('after');
  //   if (lastPage === 'register') {
  //     setShowModal('survey');

  //     loadSurvey();
  //   }
  // }, []);

  if (!showModal) {
    return <div />;
  }

  const discoverFromRadio = (str: string, tips: string) => {
    return (
      <div
        style={{
          width: '40%',
          display: 'flex',
          alignItems: 'center',
          marginBottom: 6,
        }}
      >
        <Radio
          value={str}
          checked={discoverFromList.includes(str)}
          onClick={() => {
            // if (discoverFromList.includes(str)) {
            //   setDiscoverFromList([...discoverFromList].filter((s) => s !== str));
            // } else {
            //   setDiscoverFromList([...discoverFromList, str]);
            // }

            // 暂时改成单选
            setDiscoverFromList([str]);
          }}
        >
          {str !== 'Other' && <span>{i18nFormat(str)}</span>}

          {str === 'Other' && (
            <Input
              value={otherDiscoverFrom}
              placeholder={i18nFormat(str)}
              onChange={e => setOtherDiscoverFrom(e.target.value)}
            />
          )}
        </Radio>

        {tips && (
          <Popover placement="bottomLeft" content={i18nFormat(tips)}>
            <InfoCircleOutlined style={{ marginTop: 4 }} />
          </Popover>
        )}
      </div>
    );
  };

  const onSubmit = async () => {
    const list = [...discoverFromList];
    const otherIndex = list.indexOf('Other');
    if (otherIndex && otherIndex > -1) {
      list[otherIndex] = otherDiscoverFrom || 'Other';
    }

    if (!list || !list[0]) {
      message.info(i18nFormat('Please tell us how did you discover Unionfab?'));
      return;
    }

    if (!position) {
      message.info(i18nFormat('Please select select the position.'));
      return;
    }

    if (!industries) {
      message.info(i18nFormat('Please select select the industries.'));
      return;
    }

    if (!applications) {
      message.info(i18nFormat('Please select select the applications.'));
      return;
    }

    const data = {
      'How did you discover Unionfab?': list,
      position,
      industries,
      applications,
    } as Record<string, string | string[] | number | number[]>;

    let personId = S.get(accountInfo, a => a.personId);

    if (!personId) {
      const resp =
        await S.getUfcShopComposedApi().userApi.getAccountProfileInfo();

      personId = resp.personId;
    }

    if (personId) {
      // 提交
      const res = await submitCustomerSurveyByPerson({
        inquirySurvey: new S.InquirySurvey({
          personId,
          tenantId: getTenantIdFromGConfig(),
          type: 'registration_02',
          data,
        }),
      });

      if (res) {
        const invitationCode = S.get(
          registerSurvey01,
          s => s.data['invitationCode'] as string,
        );

        if (invitationCode || discoverFromList.includes('Gift card(s)')) {
          setShowModal('congratulation');
        } else {
          message.info(i18nFormat('Thanks for submitting'));
          closeModal();
        }
      }
    } else {
      message.warning(i18nFormat('Person information is missing.'));
    }
  };

  const closeModal = () => {
    setShowModal(undefined);

    newCouponInfo.hasNew &&
      Modal.info({
        title: 'Congratulation!',
        okText: 'To Review',
        onOk: () => nav.navigateToReferral(),
        maskClosable: true,
        content: (
          <div>
            You have{' '}
            <span style={{ color: '#f9bf11' }}>{newCouponInfo.count}</span>{' '}
            coupon to use click to see how to use
          </div>
        ),
      });
  };

  return (
    <div id="InquirySurveyModal" className={cn(styles.container)}>
      <Modal
        width={800}
        open={showModal === 'survey'}
        onOk={() => closeModal()}
        onCancel={() => closeModal()}
        footer={false}
      >
        <div className={styles.modal}>
          <div className={styles.title}>
            {i18nFormat('How did you discover Unionfab?')}
          </div>
          <div className={styles.inputs}>
            {discoverFromRadio('Gift card(s)', '')}
            {discoverFromRadio('Google/Search Engine', '')}
            {discoverFromRadio(
              'Social Media',
              'Facebook/Linkedin/Youtube/etc.',
            )}
            {discoverFromRadio('Direct', 'Referral from our sales & marketing')}
            {discoverFromRadio('From a friend', '')}
            {discoverFromRadio('Other', '')}
          </div>

          <div className={styles.title}>
            To offer you the Best Quotation and Service, fill form for 10% off
            Discount Coupon.
          </div>

          <div className={styles.inputs}>
            <div style={{ width: '40%', marginBottom: 12 }}>
              <div>{i18nFormat('Position')}</div>
              <Select
                placeholder="select"
                style={{ width: 204, marginTop: 2 }}
                options={[
                  'CEO',
                  'VP',
                  'Engineer',
                  'Sales',
                  'Purchase',
                  'Marketing',
                  'Other',
                ].map(i => ({
                  label: i,
                  value: i,
                }))}
                value={position}
                onChange={e => setPosition(e)}
              ></Select>
            </div>

            <div style={{ width: '40%', marginBottom: 12 }}>
              <div>{i18nFormat('Industries')}</div>
              <Select
                placeholder="select"
                style={{ width: 204, marginTop: 2 }}
                options={[
                  'Industrial design',
                  'Consumer Products',
                  'Medical',
                  'Education',
                  'Aerospace',
                  'Automotive',
                  'Art and Fashion',
                  'Other',
                ].map(i => ({
                  label: i,
                  value: i,
                }))}
                value={industries}
                onChange={e => setIndustries(e)}
              ></Select>
            </div>

            <div style={{ width: '40%', marginBottom: 12 }}>
              <div>{i18nFormat('Applications')}</div>
              <Select
                placeholder="select"
                style={{ width: 204, marginTop: 2 }}
                options={[
                  'Manufacturing',
                  'Jigs and Fixtures',
                  'Production Parts',
                  'Rapid Prototyping',
                  'Packaging',
                  'Design',
                  'Composite Tooling',
                  'Other',
                ].map(i => ({
                  label: i,
                  value: i,
                }))}
                value={applications}
                onChange={e => setApplications(e)}
              ></Select>
            </div>
          </div>
        </div>

        <div className={styles.actions}>
          <Button type="primary" onClick={() => onSubmit()}>
            {i18nFormat('Submit')}
          </Button>
        </div>
      </Modal>

      <Modal
        open={showModal === 'congratulation'}
        onOk={() => closeModal()}
        onCancel={() => closeModal()}
        footer={false}
      >
        <div className={styles.title} style={{ justifyContent: 'center' }}>
          <div>{i18nFormat('Congratulation')}</div>

          <div
            id="modal_congratulation_vip"
            style={{
              fontSize: 32,
              margin: '0 0 12px 6px',
              color: '#f89902',
              opacity: isShowVipMove ? 0 : 1,
            }}
          >
            <MY_VIP />
          </div>
        </div>

        <div className={styles.desc}>
          {i18nFormat('You have got an exclusive offer !')}
        </div>

        <div className={styles.actions}>
          <Button
            type="primary"
            onClick={async () => {
              // 判断是否 menu account 是否有小钻石
              const invitationCode = S.get(
                registerSurvey01,
                s => s.data['invitationCode'] as string,
              );

              if (invitationCode || discoverFromList.includes('Gift card(s)')) {
                setIsShowVipMove(true);
                await S.sleep(1000);
              }

              setShowModal(undefined);

              nav.navToNewQuote();
              reload && reload();
            }}
          >
            {i18nFormat('Go to the enquiry')}
          </Button>
        </div>

        {isShowVipMove && (
          <div id="move_icon_vip" className={styles.moveIcon}>
            <MY_VIP />
          </div>
        )}
      </Modal>
    </div>
  );
};

InquirySurveyModal.displayName = 'InquirySurveyModal';
