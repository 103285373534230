import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Table } from 'antd';

import styles from './index.module.less';
import threadsData from './threads.json';

const columns = [
  {
    title: i18nFormat('Thread'),
    dataIndex: 'thread',
    align: 'center',
    render: (text: number[], _record: any) => {
      return <div style={{ fontWeight: 'bold' }}>{text}</div>;
    },
  },
  {
    title: 'Specification (mm)',
    dataIndex: 'spec',
    align: 'center',
    render: (text: number[], record: any) => {
      return (
        <div>
          {(text || []).map((insertValue: number, idx: number) => {
            return (
              <div key={`${record.spec}-spec-${insertValue}-${idx}`}>
                {insertValue}
              </div>
            );
          })}
        </div>
      );
    },
  },
  {
    title: 'Diameter size (mm)',
    dataIndex: 'diameter',
    align: 'center',
    render: (text: number[], record: any, idx: number) => {
      return (
        <div>
          {(text || []).map((insertValue: number, idx: number) => {
            return (
              <div key={`${record.spec}--${insertValue}-${idx}`}>
                {insertValue}
              </div>
            );
          })}
        </div>
      );
    },
  },
  {
    title: 'Hole Depth (mm)',
    dataIndex: 'hole',
    align: 'center',
    render: (text: any, record: any, idx: number) => {
      return <div key={`${record.spec}--${text}-${idx}`}>{text}</div>;
    },
  },
  {
    title: i18nFormat('Thread Depth (mm)'),
    dataIndex: 'depth',
    align: 'center',
    render: (text: any, record: any, idx: number) => {
      return <div key={`${record.spec}--${text}-${idx}`}>{text}</div>;
    },
  },
];

export const ThreadTable = () => {
  return (
    <div className={styles.threadTable}>
      <Table
        rowClassName={(_, idx) => (idx % 2 ? styles.oddRow : styles.evenRow)}
        pagination={false}
        title={() => {
          return (
            <div className="standard-inserts-table-title">
              {i18nFormat('Standard Threads')}
            </div>
          );
        }}
        dataSource={threadsData}
        columns={columns as any}
      />
    </div>
  );
};
