import * as S from '@unionfab/ufc-shop-commons';
import { D3ModelAvatar } from '@unionfab/ufc-shop-ui-commons';

import { useInquiryQuoteEditorStore } from '../../../../../../../stores';
import styles from './index.module.less';

export const OrderItemModelPreview = ({
  orderItem,
}: {
  orderItem: S.InquiryOrderItem;
}) => {
  const { modelFileMap } = useInquiryQuoteEditorStore();

  const printFile = orderItem.printFiles[0];
  const fileId = orderItem.modelFiles[0].id;
  const curModel = modelFileMap[fileId] || orderItem.modelFiles[0];
  const curLengthUnit = printFile.lengthUnit;

  const modelId = S.get(curModel, m => m.id)!;

  return (
    <div className={styles.orderItemModelPreview}>
      <D3ModelAvatar
        key={modelId}
        size={90}
        model={curModel}
        unit={curLengthUnit}
      />
      <img
        className={styles.orderItemCellSearchIcon}
        src={S.getBuiltinImageCollection().QUOTE_MODEL_AVATAR_SEARCH_ICON}
      />
    </div>
  );
};
