import * as S from '@unionfab/ufc-shop-commons';
import { Alert } from 'antd';
import * as React from 'react';
import { CSSTransition } from 'react-transition-group';

import styles from './index.module.less';

export const PanelAlert = ({
  isShow,
  message,
  ...rest
}: {
  isShow?: boolean;
  message: string;
  style?: React.CSSProperties;
}) => {
  return (
    <CSSTransition
      timeout={300}
      unmountOnExit={true}
      in={isShow}
      classNames="model-extend-type-alert"
    >
      <div className={styles.panelAlert} {...rest}>
        <Alert
          showIcon
          icon={
            <img
              width="13"
              height="13"
              src={
                S.getBuiltinImageCollection().QUOTE_ORDER_ITEM_HANDLER_WARNING
              }
            />
          }
          message={message}
          className="model-extend-type-alert"
        />
      </div>
    </CSSTransition>
  );
};
