import * as S from '../../../schema';

export interface RawUfcApiReturnTenantIdentityType {
  id: string /** userId */;
  tenantId: string;
  tenantName: string;
  username: string;
  nickname: string;
}

export interface RawUfcApiReturnType<
  D,
  E = {
    tenantIdentities?: RawUfcApiReturnTenantIdentityType[];
  }
> {
  status: 'ok' | 'error' | string;
  /** @Deprecated 后续接口直接使用 pagination  */
  meta: S.Meta;
  pagination: S.PaginationInfo;
  err?: {
    code: string | 'REQUIRES_CAPTCHA';
    data: {
      rateLimit?: {
        leftSeconds: number;
      };
      captcha?: {
        key: string;
        data: string;
      };
    };
  };
  data: D;
  extra?: E;
}

/** 按页获取你希望的所有的对象 */
export async function getAllByPage<T extends S.BaseEntity<T>>(
  fetcher: ({
    pageSize,
    pageNum,
  }: {
    pageSize: number;
    pageNum: number;
  }) => Promise<{ data: T[]; meta: S.Meta }>,
  {
    pageNum: startPageNum = 0,
    pageSize = 100,
    totalNum,
    onProgress,
  }: {
    pageNum?: number;
    pageSize?: number;
    totalNum?: number;
    // 当前获取到的进度
    onProgress?: (
      progress: number,
      {}: { pageNum?: number; pageSize?: number; totalPages?: number },
    ) => void;
  } = {},
): Promise<T[]> {
  let res: T[] = [];
  let totalPages = 1;
  let pageNum = startPageNum;

  do {
    if (onProgress) {
      S.get(pageNum, pageNum =>
        onProgress(pageNum / totalPages, {
          pageNum,
          pageSize,
          totalPages,
        }),
      );
    }

    const { data, meta } = await fetcher({ pageNum, pageSize });

    // 这里的 totalPages 取值来自于能够返回的最大值，或者希望获取到的
    totalPages = meta.pagination.totalPages || 1;

    if (Number.isInteger(totalNum)) {
      totalPages = Math.min(totalPages, Math.ceil(totalNum / pageSize));
    }

    pageNum++;

    res = [...res, ...(data || [])];
  } while (pageNum < totalPages);

  return res;
}

export interface PagedQueryParams {
  tenantId?: S.Id;
  creatorId?: S.Id;

  /** 分页参数 */
  pageStart?: S.Id;
  pageEnd?: S.Id;
  pageNum?: number;
  pageSize?: number;
  totalNum?: number;

  name?: string;
  searchText?: string;
  sortOrder?: {
    property: string;
    direction: 'ASC' | 'DESC';
  };

  dateProperty?: S.InquiryOrderQueryDateProperty;
  startDateTime?: string;
  endDateTime?: string;
  startDate?: string;
  endDate?: string;
}
