import { isNil } from 'lodash-es';

import * as S from '../../../schema';
import { getUfcShopComposedApi, hasUserLogined } from '../../../singleton-ins';

export interface GroupInfo {
  creatorId: string;
  currency: string;
  defaultGroup: boolean;
  displayName: string;
  ec: boolean;
  id: string;
  invitationCode: string;
  name: string;
  source: string;
  tenantId: string;
}

export function getPath(
  type: S.ConfigType,
  {
    userId,
    tenantId,
  }: {
    userId?: S.Id;
    tenantId?: S.Id;
  } = {},
) {
  // 在新的接口中，需要根据全局、租户、用户访问不同的接口
  let path;

  if (type === 'GLOBAL') {
    path = '/configs/global';
  } else if (type === 'TENANT') {
    path = `/configs/tenant/${tenantId}`;
  } else {
    path = `/configs/user/${userId}`;
  }

  // 如果是 NOAUTH 环境，则走 NOAUTH 接口
  if (!hasUserLogined() && type !== 'USER') {
    path = `/noauth${path}`;
  }

  return path;
}

// 转化为值
const mapValue = (v: any) => {
  if (typeof v === 'boolean') {
    return `${v}`;
  }

  if (v === 'undefined') {
    return undefined;
  }

  // 这里需要保留对于对象的关联
  if (typeof v === 'string' || typeof v === 'number') {
    return `${v}`;
  }

  return v;
};

/** 获取电商报价组 */
export async function getMallGroup(tenantId) {
  const groupInfo = await getUfcShopComposedApi().umiRequestCompatibleApi.getUmi<{
    status: string;
    data: GroupInfo;
  }>(`/order/quotation_group/ec`, {
    params: { source: 'OVERSEA', tenantId },
  });
  return groupInfo;
}

/** 获取租户级别/全局级别配置 */
export async function getConfig(
  keys: string[],
  type: S.ConfigType = 'USER',
  {
    userId,
    tenantId,
  }: {
    userId?: S.Id;
    tenantId?: S.Id;
  } = {},
) {
  const {
    data,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.getUmi<{
    data: Record<string, string>;
  }>(
    `${getPath(type, {
      userId,
      tenantId,
    })}`,
    {
      params: { keys },
    },
  );

  const obj: Record<string, any> = {};

  // 所有 bool 类型会被强制转化为字符串，统一使用 'true' 与 'false'
  (S.isValidArray(keys) ? keys : S.TenantConfigKeys).forEach((key: string) => {
    const value = (data || {})[key];

    if (isNil(value)) {
      return;
    }

    try {
      const v = JSON.parse(value);
      obj[key] = mapValue(v);
    } catch (e) {
      obj[key] = mapValue(value);
    } finally {
      if (isNil(obj[key])) {
        delete obj[key];
      }
    }
  });

  try {
    const taxRate = obj.taxRate;
    if (typeof taxRate === 'number') {
      obj.taxRate = obj.taxRate / 10;
    }

    if (typeof taxRate === 'string') {
      obj.taxRate = `${Number.parseFloat(obj.taxRate) / 10}`;
    }
  } catch (_) {
    console.error('>>>kv_config>>>getConfig>>>taxRate>>>', _);
  }

  return obj;
}
