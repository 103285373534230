import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Button, Col, message, Modal, Row } from 'antd';
import cn from 'classnames';
import { useEffect, useMemo, useState } from 'react';

import {
  ReceiveAddressCard,
  ReceiveAddressCreateCard,
} from '@/features/receive_address/components';
import { ReceiveAddressEditCard } from '@/features/receive_address/components';
import {
  getUseShopQuoteStoreByCode,
  useReceiveAddressMgtStore,
} from '@/stores';

import styles from './index.module.less';

interface BillingAddressSelectorProps {
  className?: string;
  orderCode: string;
  selectAddress?: (a: S.ReceiveAddress) => void;
}

export const BillingAddressSelector = ({
  className,
  orderCode,
}: BillingAddressSelectorProps) => {
  const [visible, toggleVisible] = useState<boolean>(false);
  const [addressConfig, setAddressConfig] = useState<{
    visible: boolean;
    type: 'edit' | 'new' | 'delete';
    address: S.ReceiveAddress;
  }>({
    type: 'new',
    visible: false,
    address: new S.ReceiveAddress({}),
  });

  const store = getUseShopQuoteStoreByCode(orderCode);
  const { billingAddress } = store(s => ({
    billingAddress: s.billingAddress,
  }));

  const {
    addressList = [],
    loadAddressList,
    updateAddress,
    createAddress,
    deleteAddress,
    setAsDefault,
  } = useReceiveAddressMgtStore();

  useEffect(() => {
    !S.isValidArray(addressList) && loadAddressList();
  }, []);

  const onChangeBillingAddrss = (addr: S.ReceiveAddress) => {
    if (!addr.phoneNumber || addr.phoneNumber === '') {
      message.warning(
        i18nFormat('Please complete your shipping address information'),
      );
      return;
    }

    store.setState({ billingAddress: addr });

    toggleVisible(false);
  };

  const onHandleAddress = async (addr: S.ReceiveAddress) => {
    if (addressConfig.type == 'new') {
      await createAddress(addr);
    }

    if (addressConfig.address.id === addr.id) {
      addressConfig?.type === 'edit' && (await updateAddress(addr));
      addressConfig.type === 'delete' && (await deleteAddress(addr));
    } else {
      console.warn('illegal state', addr, addressConfig);
    }
  };

  const addressContent = useMemo(() => {
    if (!billingAddress) {
      return (
        <div
          onClick={() => toggleVisible(true)}
          className={styles.selectReceiveAddressSelectNew}
        >
          <div className={styles.receiveAddressCreateCard}>
            <div>{i18nFormat('Select a billing address')}</div>
          </div>
        </div>
      );
    } else {
      return (
        <>
          <ReceiveAddressCard
            address={billingAddress}
            className={styles.billingAddress}
          />
          <Button
            block
            size="large"
            type="primary"
            onClick={() => toggleVisible(true)}
          >
            {i18nFormat('Change')}
          </Button>
        </>
      );
    }
  }, [billingAddress]);

  const addressListContent = useMemo(() => {
    const { visible, type, address } = addressConfig;

    return (
      <div className={styles.billingAddressSelectorAddressList}>
        <ReceiveAddressCreateCard
          address={address}
          modalVisible={type == 'new' && visible}
          createAddress={onHandleAddress}
          toggleModal={visible =>
            setAddressConfig({
              visible,
              type: 'new',
              address: new S.ReceiveAddress(),
            })
          }
        />
        {(addressList || []).map((a, i) => (
          <div key={i}>
            <ReceiveAddressEditCard
              address={a}
              updateAddress={addr => onHandleAddress(addr)}
              deleteAddress={() => onHandleAddress(a)}
              setAsDefault={async () => await setAsDefault(a)}
              toggleOperation={type =>
                setAddressConfig({ ...addressConfig, address: a, type })
              }
              operation={address.id == a.id ? (type as any) : undefined}
            />
            <Button
              block
              type="primary"
              onClick={() => onChangeBillingAddrss(a)}
            >
              {i18nFormat('Deliver to this address')}
            </Button>
          </div>
        ))}
      </div>
    );
  }, [addressList, addressConfig]);

  return (
    <div className={cn(styles.billingAddressSelectorContainer, className)}>
      {addressContent}
      <Modal
        width="80%"
        open={visible}
        footer={false}
        closable={false}
        destroyOnClose={true}
        onCancel={() => toggleVisible(false)}
      >
        {addressListContent}
      </Modal>
    </div>
  );
};
