import { uniqBy } from 'lodash';
import { create, GetState, StoreApi, UseBoundStore } from 'zustand';
import { devtools, NamedSet } from 'zustand/middleware';

import { getUfcShopComposedApi as apis } from '../../apis';
import { calcAddressId } from '../../schema';
import * as S from '../../schema';

interface Store {
  set: NamedSet<IAddressMgtState>;
  get: GetState<IAddressMgtState>;
}

export interface IAddressMgtState {
  loading: boolean;
  addressList: S.ReceiveAddress[];

  // 加载收货地址列表到 addressList；会为地址生成 ID，在重新加载前地址的 ID 不会发生改变
  loadAddressList: () => Promise<void>;

  // 将给定地址设为默认地址
  setAsDefault: (addr: S.ReceiveAddress) => Promise<boolean>;

  // 删除特定地址
  deleteAddress: (addr: S.ReceiveAddress) => Promise<boolean>;

  // 更新给定地址；更新完成触发
  updateAddress: (addr: S.ReceiveAddress) => Promise<boolean>;

  // 创建给定地址；创建成功会触发列表重新加载
  createAddress: (addr: S.ReceiveAddress) => Promise<boolean>;
}

function setAsDefault(addrId: string, addressList: S.ReceiveAddress[]) {
  addressList.forEach(v => (v.defaultAddress = v.id === addrId));
}

export async function doLoadAddressList(): Promise<S.ReceiveAddress[]> {
  let addressList = await apis().userApi.getAccountAddresses();

  // 生成地址 ID
  addressList = addressList.map(v => {
    const { firstName, lastName } = S.parseReceiverName(v);
    const { contactInfo, phoneNumber } = v;
    const attr = Object.assign(
      {},
      v,
      { id: calcAddressId(v) },
      { firstName, lastName, phoneNumber: phoneNumber || contactInfo },
    );
    return new S.ReceiveAddress(attr);
  });

  return uniqBy(addressList, a => a.id);
}

async function doUpdateAddress(s: Store, addressList: S.ReceiveAddress[]) {
  addressList = addressList.map(v => {
    // 地址 ID 是前端生成
    const attr = Object.assign({}, v, { id: undefined });
    const r = new S.ReceiveAddress(attr);
    if (
      r.phoneNumber != null &&
      (r.contactInfo == null || r.contactInfo.trim() === '')
    ) {
      r.contactInfo = r.phoneNumber;
    }
    return r;
  });

  const address = await apis().userApi.upadteAccountAddresses(
    addressList,
    'representation',
  );

  if (typeof address !== 'boolean' && !!address) {
    const addressList = uniqBy(
      address.map(v => {
        const { firstName, lastName } = S.parseReceiverName(v);
        const { contactInfo, phoneNumber } = v;
        const attr = Object.assign(
          {},
          v,
          { id: calcAddressId(v) },
          { firstName, lastName, phoneNumber: phoneNumber || contactInfo },
        );
        return new S.ReceiveAddress(attr);
      }),
      a => a.id,
    );

    addressList && s.set({ addressList });

    return true;
  }

  return false;
}

function checkEditable(
  toEdit: S.ReceiveAddress,
  addressList: S.ReceiveAddress[],
) {
  const editingAddrId = toEdit.id;
  if (!editingAddrId) {
    throw new Error(`error editing, id not found: ${toEdit}`);
  }

  const idx = addressList.findIndex(v => v.id === editingAddrId);
  if (idx === -1) {
    throw new Error(`error editing, address not found: ${toEdit}`);
  }

  // 编辑后地址 ID
  const addrId = calcAddressId(toEdit);
  if (
    addressList.findIndex(v => v.id !== editingAddrId && v.id === addrId) !== -1
  ) {
    throw new Error(`address already exists: ${toEdit}`);
  }

  addressList[idx] = toEdit;
}

export const useReceiveAddressMgtStore: UseBoundStore<
  StoreApi<IAddressMgtState>
> = create<IAddressMgtState>()(
  devtools(
    (set, get) => ({
      loading: false,

      addressList: [] as S.ReceiveAddress[],

      loadAddressList: async () => {
        try {
          set({ loading: true });

          const addressList = await doLoadAddressList();

          set({ addressList, loading: false });
        } catch (e) {
          set({ loading: false });

          console.log('>>>loadAddressList', e);
        }
      },

      setAsDefault: async (addr: S.ReceiveAddress) => {
        const s = get();
        const addressList = s.addressList;

        checkEditable(addr, addressList);
        setAsDefault(addr.id!, addressList);

        const resp = await doUpdateAddress({ set, get }, addressList);

        return resp;
      },

      deleteAddress: async (addr: S.ReceiveAddress) => {
        const s = get();
        const toRemove = s.addressList.find(v => v.id == addr.id);

        if (toRemove) {
          const addressList = s.addressList.filter(v => v !== toRemove);

          const resp = await doUpdateAddress({ set, get }, addressList);

          return resp;
        }
      },

      updateAddress: async (addr: S.ReceiveAddress) => {
        const s = get();
        const addressList = s.addressList;

        // 更新编辑中地址
        checkEditable(addr, addressList);

        if (addr.defaultAddress) {
          setAsDefault(addr.id!, addressList);
        }
        const resp = await doUpdateAddress({ set, get }, addressList);

        return resp;
      },

      createAddress: async (addr: S.ReceiveAddress) => {
        const s = get();
        const addressList = s.addressList;

        const addressId = calcAddressId(addr);

        if (addressList.findIndex(v => v.id === addressId) !== -1) {
          throw new Error(`address already exists: ${addr}`);
        }

        if (addr.defaultAddress) {
          setAsDefault(addressId, addressList);
        }

        const resp = await doUpdateAddress({ set, get }, [
          addr,
          ...addressList,
        ]);

        return resp;
      },
    }),
    { name: 'receiveAddressMgtStore' },
  ),
);
