import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useSize } from 'ahooks';
import { Button } from 'antd';
import cn from 'classnames';
import { head, last } from 'lodash-es';
import { useCallback, useEffect, useRef, useState } from 'react';

import styles from './index.module.less';

interface ImagesCarouselProps {
  urls: string[];
  className?: string;
}

export const ImagesCarousel = ({ urls, className }: ImagesCarouselProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const containerSize = useSize(containerRef);

  const [currentIndex, setCurrentIndex] = useState(1);

  const getThumbnails = useCallback(
    (loop = true) => {
      let resp: string[] = [];

      if (!!urls && urls.length === 0) {
        return resp;
      }

      urls.forEach((l, index) => {
        const arrayLength = (urls || []).length;

        resp.push(l);

        if (index + 1 === arrayLength && loop) {
          resp = [last(urls) || '', ...resp, head(urls) || ''];
        }
      });

      return resp;
    },
    [urls],
  );

  const thumbnails = getThumbnails();

  useEffect(() => {
    /** 处在第一张图片 */
    if (currentIndex === 0) {
      setTimeout(() => setCurrentIndex(thumbnails.length - 2), 100);
    }

    if (currentIndex + 1 === thumbnails.length) {
      setTimeout(() => setCurrentIndex(1));
    }
  }, [currentIndex, thumbnails]);

  const onSwap = useCallback(
    (type: 'left' | 'right') => {
      if (type === 'left' && currentIndex !== 0) {
        setCurrentIndex(currentIndex - 1);
        return;
      }

      if (type === 'right' && currentIndex + 1 !== thumbnails.length) {
        setCurrentIndex(currentIndex + 1);
        return;
      }
    },
    [currentIndex, thumbnails],
  );

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.slickWrapper} ref={containerRef}>
        <div
          className={styles.slickContent}
          style={{
            left: -currentIndex * (containerSize?.width || 0),
          }}
        >
          {thumbnails?.map((l, i) => {
            const style = {
              width: containerSize?.width,
              height: containerSize?.height,
              backgroundImage: `url(${l})`,
            };

            return <div key={i} className={styles.preview} style={style} />;
          })}
        </div>
        <div className={styles.arrows}>
          <Button
            type="link"
            icon={<LeftOutlined />}
            onClick={() => onSwap('left')}
          />

          <Button
            type="link"
            icon={<RightOutlined />}
            onClick={() => onSwap('right')}
          />
        </div>
      </div>

      <div className={styles.thumbnailsWrapper}>
        {getThumbnails(false)?.map((l, i) => {
          const isSelected = thumbnails[currentIndex] === l;

          return (
            <div
              key={i}
              className={styles.preview}
              style={{ borderColor: isSelected ? '#f89902' : 'transparent' }}
              onClick={() =>
                setCurrentIndex(thumbnails.findIndex(i => i === l))
              }
            >
              <img src={l} style={{ width: '4rem', height: '4rem' }} />
            </div>
          );
        })}
      </div>
    </div>
  );
};
