import { BaseEntity, Id } from '../../../common';
import { get } from '../../../utils';
import { InquiryMaterialSku } from '../../material';
import { ShipQuotations } from '..';
import { InquiryOrder } from '../InquiryOrder';
import { InquiryOrderItem } from '../InquiryOrderItem';
import { PayMethod } from './PayMethod';

// 订单使用特定快递公司的物流报价
export interface OrderExpressQuotation {
  expressId: string;
  // 客服给定的报价；注意给定负数将清空对应客服物流报价
  quotation?: number;
  // 系统自动计算的报价
  systemQuotation?: number;
  // 系统报价是否需要进行审核
  requiresManualQuote?: boolean;

  // 对应订单该快递是否开启
  enabled?: boolean;
  // 客服给定时效描述
  timingDesc?: string;
}

/** 包含报价相关数据，等价于之前的 InquiryPrice 类 */
export class InquiryOrderQuotation extends BaseEntity<InquiryOrderQuotation> {
  /** 税率，提交给后台的时候需要乘以 10 */
  taxRate: number;
  /** 后处理费用 */
  handleFee: number;
  /** 邮费 */
  postage: number;
  /** 包装费 */
  packingFee: number;
  /** 附加费 */
  surcharge: number;
  surchargeRemark: string; // 附加费备注
  /** 支付方式 */
  payMethod: PayMethod;

  /** 总价格，来自于后端 */
  totalPriceWithTax: number;
  /** 不含税总价格，来自于后端 */
  totalPriceWithoutTax: number;

  /**
   * 订单运费报价信息
   *
   * @deprecated 使用 shipExpressQuotations
   */
  shipQuotations?: ShipQuotations;

  // 订单各个支持的物流的报价信息
  shipExpressQuotations: OrderExpressQuotation[];

  /** 外部注入的信息 */
  getInquiryOrderRef: () => InquiryOrder;
  materialSpu: InquiryMaterialSku;

  constructor(
    data?: Partial<InquiryOrderQuotation> & {
      getInquiryOrderRef: () => InquiryOrder;
    },
  ) {
    super(data);

    Object.assign(this, data);

    this.getInquiryOrderRef = data.getInquiryOrderRef;

    this.shipQuotations = data.shipQuotations;
    this.shipExpressQuotations = data.shipExpressQuotations;

    this.payMethod = new PayMethod(data.payMethod);
  }
}

/** 给定整个报价条目的价格 */
export class QuoteByItem extends BaseEntity<QuoteByItem> {
  itemId: string; // 条目 Id
  price: number; // 条目价格

  constructor(data?: Omit<QuoteByItem, 'id'>) {
    super(data);

    Object.assign(this, data);
  }
}

/** 合并报价 */
export class QuoteByGroup extends BaseEntity<QuoteByGroup> {
  // 合并报价号
  offerNumber: number;

  // 合并价格
  price: number;

  // 该合并报价组中的报价条目
  itemIds: Id[] = [];

  constructor(data?: Omit<QuoteByGroup, 'id'>) {
    super(data);

    Object.assign(this, data);
  }
}

/** 给定条目中特定文件条目的单个文件价格 */
export class QuoteByFileItem extends BaseEntity<QuoteByFileItem> {
  itemId: Id;
  fileItemQuotations: OrderFileItemQuotation[];

  constructor(data?: Omit<QuoteByFileItem, 'id'>) {
    super(data);

    Object.assign(this, data);
  }
}

export class OrderFileItemQuotation extends BaseEntity<OrderFileItemQuotation> {
  // 文件编号
  fileItemId: Id;

  // 对特定文件单独调整 SPU 价格
  spuPrice: number;

  // 单套价格
  singleFilePrice: number;

  constructor(data?: Omit<OrderFileItemQuotation, 'id'>) {
    super(data);

    Object.assign(this, data);
  }
}

/** 从老的订单中创建出报价信息 */
export function createInquiryOrderQuotationFromLegacyInquiryOrder(
  createdItems: InquiryOrderItem[],
  legacyInquiryOrder: InquiryOrder,
) {
  const { payMethod } = legacyInquiryOrder;

  // 提取
  const {
    handleFee,
    packingFee,
    postage,
    surcharge,
    surchargeRemark,
    taxRate,
    shipQuotations,
    shipExpressQuotations,
  } = legacyInquiryOrder.price;

  // 从 createdItems 中提取 id 填充到 inquiryOrder 中
  legacyInquiryOrder.items.forEach(item => {
    if (item.isDraft) {
      // 从 createdItems 中提取出数据
      item.id = get(
        createdItems,
        cIs =>
          cIs.find(
            cI =>
              // 这里使用 modelFile 作为关联标识
              cI.printFiles[0].fileId === item.printFiles[0].fileId,
          ).id,
      );
    }
  });

  // 补齐 printFiles 的 id
  const createdPrintFiles = createdItems.flatMap(i => i.printFiles);
  legacyInquiryOrder.printFiles.forEach(pF => {
    if (pF.isDraft) {
      // 从 createdItems 中提取出数据
      pF.id = get(
        createdPrintFiles,
        cPFs =>
          cPFs.find(
            cPF =>
              // 这里使用 modelFile 作为关联标识
              cPF.fileId === pF.fileId,
          ).id,
      );
    }
  });

  const quotation = new InquiryOrderQuotation({
    getInquiryOrderRef: () => legacyInquiryOrder,

    handleFee,
    packingFee,
    postage,
    surcharge,
    surchargeRemark,
    taxRate,
    payMethod,
    shipQuotations,
    shipExpressQuotations,
  });

  return quotation;
}
