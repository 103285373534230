import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Tooltip, Typography } from 'antd';
import cn from 'classnames';

import defaultStyles from './index.module.less';

const { Paragraph, Text } = Typography;

export interface AddressTextProps {
  address: S.ReceiveAddress;

  className?: string;
  styles?: CSSModuleClasses;
}

export const ReceiveAddressCard = ({
  className,
  address,
  styles,
}: AddressTextProps) => {
  styles = styles || defaultStyles;

  const recipient = S.formatReceiverName(address);
  const companyName = S.get(address, a => a.companyName);

  const country = S.get(address, a => a.country);

  const street = S.get(address, a => a.street);
  const province = S.get(address, a => a.province);
  const detail = S.get(address, a => a.detail);
  const city = S.get(address, a => a.city);

  const phoneNumber = S.get(address, a => a.phoneNumber);

  const receiverText = [recipient, companyName].filter(v => !!v).join(' - ');
  const postcode = S.get(address, a => a.postcode);
  const cityState = [city, province, postcode].filter(v => !!v).join(' ');
  const addressLine = [street, detail].filter(v => !!v).join(', ');

  return (
    <div className={cn(styles.receiveAddressCard, className)}>
      <div className={styles.receiveAddressCardReceiver}>
        <Tooltip title={receiverText}>
          <Text strong ellipsis={true}>
            {receiverText}
          </Text>
        </Tooltip>
        {address.defaultAddress && (
          <div className={styles.receiveAddressCardDefaultTag}>
            {i18nFormat('Default')}
          </div>
        )}
      </div>
      {cityState && (
        <Paragraph
          className={styles.receiveAddressCardCityState}
          ellipsis={{ rows: 2, expandable: false, symbol: 'more' }}
        >
          {cityState}
        </Paragraph>
      )}
      {addressLine && (
        <Paragraph
          ellipsis={{
            rows: 2,
            expandable: false,
            symbol: 'more',
            tooltip: addressLine,
          }}
        >
          {addressLine}
        </Paragraph>
      )}
      {country && (
        <Paragraph className={styles.receiveAddressCardCountry}>
          {country}
        </Paragraph>
      )}
      {phoneNumber && (
        <Paragraph className={styles.receiveAddressCardPhoneNumber}>
          {i18nFormat('Phone')}: {phoneNumber}
        </Paragraph>
      )}
    </div>
  );
};
