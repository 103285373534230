import { Form, Radio, Typography } from 'antd';
import { useEffect } from 'react';

import {
  getUseShopQuoteStoreByCode,
  i18nFormat,
  ShopOrderQuoteStoreModifiers,
} from '../../../../../../../../../ufc-shop-commons/src';
import { ShopQuoteItemNotesProps } from '../ShopQuoteItemNotes';

export default function ShopQuoteItemAllowAdapt({
  orderCode,
  itemId,
}: ShopQuoteItemNotesProps) {
  const item = getUseShopQuoteStoreByCode(orderCode)(s =>
    (s.shopOrderDraft.items || []).find(i => i.id === itemId),
  );

  const onChange = (v: any) => {
    updateAllowProductAdapt(!v.target.value);
  };

  useEffect(() => {
    if (item?.cncRequirements?.allowProductAdapt == null)
      updateAllowProductAdapt(true);
    // 默认值为 true
    else updateAllowProductAdapt(item.cncRequirements.allowProductAdapt);
  }, []);

  const apa = item?.cncRequirements?.allowProductAdapt;
  const defaultVal = apa == null ? 0 : apa ? 0 : 1;
  return (
    <Form.Item
      name="allow-adapt"
      label={
        <Typography.Title level={5}>
          {i18nFormat(
            'Considering that the product structure cannot be manufactured as per the drawings, would you be open to either revising the drawings or producing the product in separate parts and assemblies?',
          )}
        </Typography.Title>
      }
      initialValue={defaultVal}
    >
      <Radio.Group onChange={onChange}>
        <Radio value={0}>Yes</Radio>
        <Radio value={1}>No</Radio>
      </Radio.Group>
    </Form.Item>
  );

  function updateAllowProductAdapt(v: boolean) {
    ShopOrderQuoteStoreModifiers.updateOrderDraft(orderCode, o => {
      const item = o.items.find(i => i.id === itemId);
      if (item != null) {
        item.cncRequirements = {
          ...item.cncRequirements,
          allowProductAdapt: v,
        };
      }
    });
  }
}
