import { BaseEntity, Id } from '../../common';

/** 材料标签 */
export class InquiryMaterialTag extends BaseEntity<InquiryMaterialTag> {
  tag: string;
  tagType: string;

  constructor(props: Partial<InquiryMaterialTag>) {
    super(props);

    Object.assign(this, props);
  }
}

/** 询价单材料类型 */
export class InquiryMaterialType extends BaseEntity<InquiryMaterialType> {
  /**
   * 材料类型名称
   */
  name: string;

  /**
   * 材料类型简称
   * - 注："NORMAL—MATERIAL" 为普通白料
   */
  simpleName: string;

  /**
   * 推广的权重系数
   * - 数值越小，优先级越高
   */
  ordinal: number;

  /** 工艺一级组名称 */
  categoryName: string;

  creatorId: Id;

  /** 后处理工艺支持 */
  handleMethods?: string[];

  constructor(props?: InquiryMaterialType) {
    super(props);

    Object.assign(this, props);
  }
}
