import {
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  ApmEventTrackRecord,
  createFile,
  D3ModelFile,
  getCacheablePreSignedUrl,
  i18nFormat,
  uploadRequest,
} from '@unionfab/ufc-shop-commons';
import * as S from '@unionfab/ufc-shop-commons';
import { genId } from '@unionfab/ufc-shop-commons';
import { Button, message, Upload } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import dayjs from 'dayjs';
import { UploadRequestOption } from 'rc-upload/lib/interface';

import { useGlobalStore } from '@/stores';

import {
  AbcFileUploaderComp,
  IAbcFileUploaderProps,
  IAbcFileUploaderState,
} from '../AbcFileUploader';

const AbortControllerMap: Record<string, AbortController> = {};

export interface IUfcFileUploaderProps extends IAbcFileUploaderProps {
  fileStores?: S.FileStore[];
  /** 图片上传数量限制 */
  fileCount?: number;
  /** 已上传文件数量 */
  uploadedFileCount?: number;
  defaultFileList?: UploadFile[];

  /** 是否上传文件夹 */
  enableDirectory?: boolean;

  /** 在 beforeUpload 中触发，如果返回 true，则表示继续执行；否则 false */
  beforeUpload?: (file: RcFile, fileList: RcFile[]) => Promise<boolean>;
}

export type IUfcFileUploaderState = IAbcFileUploaderState;

/** 基于 FileStore 的文件上传组件 */
export class _UfcFileUploader extends AbcFileUploaderComp<
  IUfcFileUploaderProps,
  IUfcFileUploaderState
> {
  static defaultProps: Partial<IUfcFileUploaderProps> = {
    type: 'IMAGE',
    showUploadList: false,
    buttonType: 'primary',
    maxFileSize: 1024 * 1024 * 1024 * 4,
  };

  /** 允许根据 UID 取消上传 */
  static cancelUploadByUid(uid: string) {
    if (AbortControllerMap[uid]) {
      AbortControllerMap[uid].abort();
    }
  }

  state: IUfcFileUploaderState = {
    storeAuth: {},
  };

  curRoundUploadedFileList: {
    rcFile: UploadFile;
    d3ModelFile: D3ModelFile;
  }[] = [];
  curWaitUploadFileList: RcFile[];

  // 记录本组件中所有已经上传过的 uid，在 onBatchUploadSuccess 的时候精确判断本次内容
  uploadedFiles: UploadFile[] = [];
  /** 记录文件 Uid 到追踪事件的映射 */
  private fileUidToTrackRecordMap: Record<
    string,
    Partial<ApmEventTrackRecord>
  > = {};

  beforeUpload = async (file: RcFile, fileList: RcFile[]) => {
    const { onEventHandler, beforeUpload } = this.props;

    if (beforeUpload && !(await beforeUpload(file, fileList))) {
      return false;
    }

    if (
      S.isValidArray(this.curWaitUploadFileList) &&
      !this.curWaitUploadFileList.map(f => f.uid).includes(file.uid)
    ) {
      message.error(i18nFormat('Please waiting upload finish'));
      return false;
    }

    if (this.state.isInitializing) {
      if (onEventHandler) {
        onEventHandler('onInitializing', file, {
          waitUploadFileList: fileList,
          uploadedFileList: this.curRoundUploadedFileList,
        });
      } else {
        message.warning(i18nFormat('Initializing Env'));
      }

      return false;
    }

    const fileName = S.escapeStringBugs(file.name);

    file.uid = genId();

    if (fileName.length > 250) {
      if (onEventHandler) {
        onEventHandler('onFileNameOverflow', file, {
          waitUploadFileList: fileList,
          uploadedFileList: this.curRoundUploadedFileList,
        });
      } else {
        message.error(i18nFormat('文件名过长'));
      }
      return false;
    }

    this.curWaitUploadFileList = fileList;

    const startAt = dayjs();
    this.fileUidToTrackRecordMap[file.uid] = {
      event_type: 'upload-file',
      attr_file_name: file.name,
      attr_file_format_type: S.getFileType(file.name),
      start_at: startAt.format(),
      start_at_unix_timestamp_milliseconds: startAt.valueOf(),
      attr_file_size: file.size,
    };

    return true;
  };

  onChange = async (info: UploadChangeParam) => {
    try {
      const {
        type,
        suffix,
        onEventHandler,
        onSuccess,
        onBatchUploadSuccess,
      } = this.props;

      const fileStore = this.getFileStore();
      const { storeAuth } = this.state;

      const fileName = S.escapeStringBugs(info.file.name);

      if (info.file.status === 'uploading') {
        const percent = info?.file?.percent || 0;

        this.setState({ isUploading: true, percent });

        info.file.percent = percent;

        if (onEventHandler) {
          onEventHandler('onUploading', info.file, {
            waitUploadFileList: this.curWaitUploadFileList,
            uploadedFileList: this.curRoundUploadedFileList,
          });
        }
      }

      const onFileSuccessOrError = () => {
        // 判断当前文件是否已经全部上传完毕
        if (
          this.curRoundUploadedFileList.length ===
          this.curWaitUploadFileList.length
        ) {
          if (onBatchUploadSuccess) {
            onBatchUploadSuccess(this.curRoundUploadedFileList);
          }

          // 如果已经对外汇报过，则清空
          this.curRoundUploadedFileList = [];
          this.curWaitUploadFileList = [];

          this.setState({
            isUploading: false,
          });
        }
      };

      const trackRecord = this.fileUidToTrackRecordMap[info.file.uid];
      if (info.file.status === 'error') {
        if (trackRecord) {
          const endAt = dayjs();
          trackRecord.event_result_status = 'error';
          trackRecord.finish_at = endAt.format();
          trackRecord.finish_at_unix_timestamp_milliseconds = endAt.valueOf();
          trackRecord.elapse_milliseconds = endAt.diff(
            dayjs(trackRecord.start_at),
            'millisecond',
          );

          S.AliyunSlsApmTrackerUtils.getAliyunSlsApmTracker().sendEventRecord(
            trackRecord,
          );
        }

        if (onEventHandler) {
          // 将出错的文件从待上传列表中移除
          this.curWaitUploadFileList = this.curWaitUploadFileList.filter(
            f => f.uid !== info.file.uid,
          );

          onEventHandler('onUploadError', info.file, {
            waitUploadFileList: this.curWaitUploadFileList,
            uploadedFileList: this.curRoundUploadedFileList,
          });

          // 如果移除后为空，重置内部变量
          onFileSuccessOrError();
        }
      }

      if (info.file.status === 'done') {
        this.setState({ isUploading: false });

        let storeKey;
        let fileUrl;
        if (fileStore.type == 'ALI_OSS') {
          const { url } = storeAuth;

          storeKey =
            info.file['storeKey'] || storeAuth.getStoreKey(`${fileName}`);

          fileUrl = `${url}/${storeKey}`;
        } else {
          const { data } = info.file.response;

          storeKey = S.get(data, data => data.data.id) || data.data;
          fileUrl = storeKey;
        }

        const attr = new S.D3FormatAttr({
          name: fileName,
          size: info.file.size,
          uid: info.file.uid,
          type:
            type === 'IMAGE'
              ? 'image'
              : ((
                  suffix || ''
                ).toLocaleLowerCase() as S.D3SupportUploadFormatType),
        });

        const fileId = await createFile(fileStore, storeKey, attr);

        if (trackRecord) {
          const endAt = dayjs();
          trackRecord.event_result_status = 'success';
          trackRecord.finish_at = endAt.format();
          trackRecord.finish_at_unix_timestamp_milliseconds = endAt.valueOf();
          trackRecord.elapse_milliseconds = endAt.diff(
            dayjs(trackRecord.start_at),
            'millisecond',
          );
          trackRecord.attr_file_id = fileId;

          S.AliyunSlsApmTrackerUtils.getAliyunSlsApmTracker().sendEventRecord(
            trackRecord,
          );
        }

        // 这里对于上传到局域网环境下的文件需要重新获取下载地址
        if (fileStore.type !== 'ALI_OSS') {
          fileUrl = await getCacheablePreSignedUrl(fileId, fileStore.id);
        }

        const file = new S.D3ModelFile({
          id: fileId,
          name: fileName,
          attr,
          url: fileUrl,
        });

        // 补齐 stores 信息
        file.stores = [
          {
            storeId: fileStore.id,
            acl: fileStore.acl,
            storeKey,
            attributeKey: undefined,
          },
        ];

        if (onSuccess) {
          onSuccess({ d3ModelFile: file, rcFile: info.file });
        }

        this.curRoundUploadedFileList.push({
          rcFile: info.file,
          d3ModelFile: file,
        });

        onFileSuccessOrError();
      }
    } catch (_) {
      console.error('>>>UfcFileUploader>>>onChange error:', _);
    }
  };

  render() {
    const {
      label,
      buttonType,
      children,
      multiple = true,
      showUploadList,
      disabled,
      listType,
      defaultFileList,
      enableDirectory,
    } = this.props;

    const { isUploading, isInitializing, storeAuth, percent } = this.state;

    const defaultButton =
      listType !== 'picture-card' ? (
        <Button
          disabled={isInitializing || isUploading || disabled}
          type={buttonType}
        >
          {isUploading ? <LoadingOutlined /> : <UploadOutlined />}
          {isInitializing || !storeAuth
            ? i18nFormat('网络初始化，请稍候')
            : isUploading
            ? S.formatPercent(percent || 0) || 0
            : label || `${i18nFormat('上传文件')}`}
        </Button>
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          {isUploading ? (
            <LoadingOutlined style={{ fontSize: 24, marginBottom: 12 }} />
          ) : (
            <PlusOutlined style={{ fontSize: 24, marginBottom: 12 }} />
          )}
          {isInitializing
            ? i18nFormat('网络初始化，请稍候')
            : isUploading
            ? S.formatPercent(percent || 0) || 0
            : label || `${i18nFormat('上传照片')}`}
        </div>
      );

    //上传地址
    const action = storeAuth.url;
    const data = { ...storeAuth.headers, storeAuth };

    return (
      <Upload
        action={action}
        accept={this.props.accept || this.accept}
        data={data}
        name="file"
        disabled={disabled}
        customRequest={(options: UploadRequestOption) => {
          const { abortController } = uploadRequest({
            ...options,
            addPrefix: true,
          } as any);

          AbortControllerMap[(options.file as RcFile).uid] = abortController;
        }}
        multiple={multiple}
        showUploadList={showUploadList}
        beforeUpload={this.beforeUpload}
        listType={listType}
        directory={enableDirectory}
        defaultFileList={defaultFileList}
        onChange={this.onChange}
      >
        {children || defaultButton}
      </Upload>
    );
  }
}

export const UfcFileUploader = (props: IUfcFileUploaderProps) => {
  const { stores } = useGlobalStore();

  return (
    <_UfcFileUploader
      {...props}
      fileStores={props.fileStores || (stores as any)}
    />
  );
};
