import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Input } from 'antd';

import { useInquiryQuoteCheckoutStore } from '@/stores';

import styles from './index.module.less';

export const OrderRemark = ({
  paymentSelection,
}: {
  // 当前支付中，只做用户要求的展示
  paymentSelection?: boolean;
}) => {
  const {
    order,
    remarkText: editingRemarkText,
    setRemarkText,
  } = useInquiryQuoteCheckoutStore();
  const remarkText = editingRemarkText || !!order ? order.remark : '';

  return (
    <div className={styles.orderRemark}>
      <div className={styles.orderRemarkContent}>
        <div className={styles.orderRemarkTextInput}>
          <Input.TextArea
            defaultValue={remarkText}
            disabled={paymentSelection}
            value={editingRemarkText}
            onChange={evt => setRemarkText(evt.target.value)}
            placeholder={i18nFormat('Please note your special requirements.')}
            autoSize
          />
        </div>
      </div>
    </div>
  );
};

// internal functions
