import * as S from '@unionfab/ufc-shop-commons';
import {
  i18nFormat,
  useReceiveAddressMgtStore,
} from '@unionfab/ufc-shop-commons';
import { Button, Col, message, Modal, Row } from 'antd';
import * as React from 'react';
import { ReactNode, useEffect, useState } from 'react';

import {
  ReceiveAddressCard,
  ReceiveAddressCreateCard,
  ReceiveAddressEditOperationType,
} from '@/features/receive_address/components';

import { DeliverToAddressCard } from './DeliverToAddressCard';
import styles from './index.module.less';

interface EditingAddress {
  type: ReceiveAddressEditOperationType;
  address: S.ReceiveAddress;
}

interface CreatingAddress {
  visible: boolean;
  address: S.ReceiveAddress;
}

export interface ReceiveAddressSelectProps {
  // 修改当前地址
  selectAddress: (address: S.ReceiveAddress) => Promise<void>;

  // 有子节点的情况下，下面两个属性用于控制编辑 modal 的展示
  showSelectionModal?: boolean;
  toggleSelectionModal?: (visible: boolean) => void;

  // 若无子节点，将默认展示当前地址或者显示新建地址按钮，下列属性，控制部分展示内容

  // 新增地址显示文本；如 Select a delivery address.
  createAndSelectText: string;
  // 若当前地址存在，该 boolean 值控制当前地址修改（Change）按钮是否展示
  hideChangeBtn?: boolean;
  // 当前选中地址，若无子节点，将默认展示该地址内容
  address?: S.ReceiveAddress;

  children?: ReactNode;
}

export const ReceiveAddressCheckCardGroup: React.FC<ReceiveAddressSelectProps> = ({
  showSelectionModal,
  toggleSelectionModal,
  createAndSelectText,
  hideChangeBtn,
  address,
  selectAddress,
  children,
}) => {
  const {
    addressList,
    loadAddressList,
    updateAddress,
    createAddress,
    deleteAddress,
    setAsDefault,
  } = useReceiveAddressMgtStore();

  const [selectAddressModalVisible, setSelectAddressModalVisible] = useState(
    false,
  );

  const [editing, setEditing] = useState<EditingAddress | undefined>();
  const [creating, setCreating] = useState<CreatingAddress>({
    address: new S.ReceiveAddress({}),
    visible: false,
  });

  useEffect(() => {
    loadAddressList();
  }, []);

  // 地址选择 modal 是否可见
  const isSelectAddressModalVisible = children
    ? showSelectionModal
    : selectAddressModalVisible;

  // 关闭地址选择 Modal 同时取消所有执行中的操作
  const toggleSelectAddressModal = (visible: boolean) => {
    children
      ? toggleSelectionModal && toggleSelectionModal(visible)
      : setSelectAddressModalVisible(visible);

    if (!visible) {
      setEditing(undefined);
    }
  };

  const _changeDeliverAddress = async (addr: S.ReceiveAddress) => {
    if (!addr.phoneNumber || addr.phoneNumber === '') {
      message.warning(
        i18nFormat('Please complete your shipping address information'),
      );
      return;
    }
    await selectAddress(addr);
    toggleSelectAddressModal(false);
  };

  const _createAddress = async (addr: S.ReceiveAddress) => {
    if (creating?.visible) {
      await createAddress(addr);
      setCreating({
        address: new S.ReceiveAddress({}),
        visible: creating?.visible,
      });
    } else {
      console.warn('illegal state', addr, editing);
    }
  };

  const _updateAddress = async (addr: S.ReceiveAddress) => {
    if (editing?.type === 'edit' && editing?.address.id === addr.id) {
      await updateAddress(addr);
    } else {
      console.warn('illegal state', addr, editing);
    }
  };

  const _deleteAddress = async (addr: S.ReceiveAddress) => {
    if (editing?.type === 'delete' && editing?.address.id === addr.id) {
      await deleteAddress(addr);
    } else {
      console.warn('illegal state', addr, editing);
    }
  };

  // 地址卡片列表
  const addressCols: JSX.Element[] = [
    // 新建地址
    <Col span={6} key="new-address">
      <ReceiveAddressCreateCard
        address={creating.address}
        modalVisible={creating.visible}
        createAddress={_createAddress}
        toggleModal={visible =>
          setCreating({ visible, address: creating.address })
        }
      />
    </Col>,

    ...addressList.map((address: S.ReceiveAddress, _) => {
      const operation =
        address.id === editing?.address.id ? editing?.type : undefined;
      return (
        <Col span={6} key={`${address.id}`}>
          <DeliverToAddressCard
            deliverToAddress={_changeDeliverAddress}
            address={address}
            operation={operation}
            toggleOperation={type =>
              setEditing(type ? { type, address } : undefined)
            }
            updateAddress={_updateAddress}
            deleteAddress={async () => await _deleteAddress(address)}
            setAsDefault={async () => await setAsDefault(address)}
          />
        </Col>
      );
    }),
  ];

  let finalChildren;
  if (children) {
    finalChildren = children;
  } else {
    finalChildren = (
      <>
        {address && (
          <div className={styles.selectReceiveAddressSelected}>
            <ReceiveAddressCard address={address} />
            {!hideChangeBtn && (
              <Button
                type="primary"
                className={styles.changeAddressBtn}
                onClick={() => toggleSelectAddressModal(true)}
              >
                {i18nFormat('Change')}
              </Button>
            )}
          </div>
        )}

        {!address && (
          <div
            className={styles.selectReceiveAddressSelectNew}
            onClick={() => toggleSelectAddressModal(true)}
          >
            <div className={styles.receiveAddressCreateCard}>
              <div>{createAndSelectText}</div>
            </div>
          </div>
        )}
      </>
    );
  }

  return (
    <div className={styles.selectReceiveAddress}>
      {finalChildren}
      <Modal
        open={isSelectAddressModalVisible}
        style={{ top: 80 }}
        width="80%"
        footer={null}
        onCancel={() => toggleSelectAddressModal(false)}
      >
        <div className={styles.selectReceiveAddressList}>
          <Row gutter={[16, 16]} style={{ width: '100%' }}>
            {addressCols}
          </Row>
        </div>
      </Modal>
    </div>
  );
};
