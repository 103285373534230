import { CheckCircleOutlined } from '@ant-design/icons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Button } from 'antd';
import { useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import { useAppNavigate } from '@/features/shared';
import { useInquiryQuoteCheckoutStore } from '@/stores';

import styles from './index.module.less';

export const OrderQuoteCheckoutSuccessPage = () => {
  const { order } = useInquiryQuoteCheckoutStore();
  const [searchParams] = useSearchParams();
  const nav = useAppNavigate();

  const orderCode = searchParams.get('orderCode') || order.code;
  const orderId = searchParams.get('orderId') || order.id;

  useEffect(() => {
    if (!orderCode) {
      // 该页面只应该由 checkout 转过来
      console.warn('illegal state, no order loaded (quote checkout page.');
      nav.navigateToHome();
    }
  }, []);

  if (!orderCode) {
    return <></>;
  }

  return (
    <section className={styles.container}>
      <div className={styles.result}>
        <div className={styles.successIcon}>
          <CheckCircleOutlined />
        </div>

        <div className={styles.thanks}>
          {i18nFormat('Thank you for your order')} !
        </div>
        <div className={styles.tips}>
          <span>{`We will complete reviewing your order in 24 hours (Except special order), `}</span>
          <span>{`and you can receive result from your email !`}</span>
        </div>

        <div className={styles.info}>
          <span>
            {i18nFormat('Your order code')}: {orderCode}
          </span>
          {/* <span style={{ margin: '0 10px' }}>|</span> */}
          {/* <span>Total Price: ${S.get(inquiryOrder, (order) => order.price.finalPrice) || '-'}</span> */}
        </div>

        <div className={styles.goDetail}>
          <Link to={`/orders_history/${orderCode}?id=${orderId}`}>
            <Button type="primary">{i18nFormat('See the Order Detail')}</Button>
          </Link>
        </div>
      </div>
    </section>
  );
};
