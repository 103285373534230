import * as S from '@unionfab/ufc-shop-commons';
import { debounce } from 'lodash-es';
import { useEffect } from 'react';

import { useInquiryQuoteEditorStore } from '@/stores';

export const QuoteEditorStateSubscriber = () => {
  const {
    inquiryOrder,
    onOrderChange,
    technology,
    updateInvalidItemIndexes,
  } = useInquiryQuoteEditorStore();

  useEffect(() => {
    if (inquiryOrder) {
      onOrderChange(inquiryOrder);
    }
  }, [inquiryOrder]);

  const _updateInvalidItemIndexes = debounce(
    (inquiryOrder: S.InquiryOrder, technology: S.TechCategory) => {
      const items = inquiryOrder?.items || [];
      const invalidItemIndexListForModelFileType = getInvalidItemIndexListForModelFileType(
        items,
        technology,
      );
      const invalidItemIndexListForRemark = getInvalidItemIndexListForRemark(
        items,
        technology,
      );
      updateInvalidItemIndexes('onOrderOrTechChange', {
        invalidItemIndexListForModelFileType,
        invalidItemIndexListForRemark,
      });
    },
  );

  useEffect(() => {
    if (inquiryOrder) {
      _updateInvalidItemIndexes(inquiryOrder, technology);
    }
  }, [inquiryOrder, technology]);

  return <></>;
};

/** 发现不符合备注要求的下标 */
function getInvalidItemIndexListForRemark(
  items: S.InquiryOrderItem[],
  tech?: S.TechCategory,
) {
  const indexList: number[] = [];
  if (tech == null) return indexList;

  for (let i = 0; i < items.length; i++) {
    const oi = items[i];

    const remarkFileCount: number = S.get(
      oi,
      oi => oi.remarkFileIds.length,
    ) as number;
    const curNutCount = S.get(oi, oi => oi.printFiles[0]?.nutCount) || 0;

    if (tech.type === 'CNC') {
      if (remarkFileCount === 0) {
        indexList.push(i + 1);
      }
    } else {
      if (curNutCount > 0 && remarkFileCount === 0) {
        indexList.push(i + 1);
      }
    }
  }

  return indexList;
}

function getInvalidItemIndexListForModelFileType(
  items: S.InquiryOrderItem[],
  tech?: S.TechCategory,
) {
  const indexList: number[] = [];
  if (tech == null) return [];

  for (let i = 0; i < items.length; i++) {
    const orderItem = items[i];
    const curModel: S.D3ModelFile = S.get(
      orderItem,
      o => o.printFiles[0].modelFile,
    ) as S.D3ModelFile;
    const fileNameOrType = S.get(curModel, m => m.attr.name || m.attr.type);
    if (!S.isFileFitTechCategory(tech, fileNameOrType || '')) {
      indexList.push(i + 1);
    }
  }

  return indexList;
}
