import { CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Alert, Progress, Spin } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import { useEffect, useState } from 'react';

import { _UfcFileUploader } from '../../../../../commons/components/file';
import styles from './index.module.less';

// 上传中的 3D 模型列表展示
export const QuoteFilesUploadingItem = ({ file }: { file: UploadFile }) => {
  const [uploadingStatus, setUploadingStatus] = useState<string | undefined>(
    'uploading',
  );
  const [percent, setPercent] = useState(0);
  const [fileName, setFileName] = useState('');
  const fileUid = file['uid'];

  const removeFile = () => {
    _UfcFileUploader.cancelUploadByUid(fileUid);
  };

  useEffect(() => {
    const percent = S.get(file, file => file.percent);
    const name = S.get(file, file => file.name);
    const status = S.get(file, file => file.status);

    setUploadingStatus(status);
    setPercent(percent || 0);
    setFileName(name || '');
  }, [file]);

  let content;

  if (uploadingStatus === 'error') {
    const failText = 'Sorry,this file failed to upload, please upload again';
    const warningImage = (
      <img
        width="13"
        height="13"
        src={S.getBuiltinImageCollection().QUOTE_ORDER_ITEM_HANDLER_WARNING}
      />
    );
    content = (
      <Alert
        className={styles.uploadError}
        showIcon
        icon={warningImage}
        afterClose={removeFile}
        message={i18nFormat(failText)}
        closable={true}
      />
    );
  } else {
    const isDone = uploadingStatus === 'done';
    content = (
      <div className={styles.uploading}>
        <div className={styles.uploadingInfoContainer}>
          <div className={styles.uploadingAvatar}>
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          </div>
          <div className={styles.uploadingInfoTxt}>
            <div className={styles.uploadingName}>{fileName}</div>
            <div className={styles.uploadingProgress}>
              <Progress
                showInfo={false}
                percent={percent}
                strokeColor="#192c59"
              />
              {i18nFormat('Uploading')}... {isDone ? 100 : percent}%
            </div>
          </div>
        </div>
        <div className={styles.closeIcon}>
          <CloseCircleOutlined onClick={removeFile} />
        </div>
      </div>
    );
  }

  return <div className={styles.uploadingBlockContainer}>{content}</div>;
};
