import { isNil, isNumber } from 'lodash-es';

import { get } from '../../../utils';
import { getLengthUnitDesc, LengthUnit } from '../../length';
import { D3ModelProcessInfo } from '../d3attr';
import { File } from '../File';
import { D3FormatAttr, D3SupportUploadFormatType } from './D3FormatAttr';
import { IdentifiedFile } from './IdentifiedFile';

export const ModelUploaderAccept =
  '.stl,.obj,.stp,.step,.3dm,.3ds,.3mf,.cob,.blender,.dxf,.ply,.x3d,.gitf,.gltf,.glb,.igs,.iges,.fbx,.3dxml,.catpart,.x_t,.x_b';

export const D3ModelFileProcessStages = [
  /** 本地压缩 */
  'COMPRESS',
  /** 本地截图 */
  'SCREENSHOT',
  /** 本地解析与读取属性 */
  'PARSE_AND_READ_ATTR',
  /** 上传 */
  'UPLOAD',
  /** 创建为文件 */
  'CREATE_FILE',
  /** 服务端解压缩中 */
  'SERVER_DECOMPRESS',
  /** 服务端转化中 */
  'SERVER_CONVERT',
  /** 服务端截图中 */
  'SERVER_SCREENSHOT',
  /** 服务端修复中 */
  'SERVER_REPAIR',
  /** 服务端解析与读取属性 */
  'SERVER_PARSE_AND_READ_ATTR',
  /** 服务端同步 */
  'SERVER_SYNC',
] as const;

export type D3ModelFileProcessStage = typeof D3ModelFileProcessStages[number];

export const defaultD3ModelFileProcessStages: D3ModelFileProcessStage[] = [
  'COMPRESS',
  'UPLOAD',
  'CREATE_FILE',
  'SERVER_DECOMPRESS',
  'SERVER_CONVERT',
  'SERVER_SCREENSHOT',
  'SERVER_REPAIR',
  'SERVER_PARSE_AND_READ_ATTR',
];

export type D3ModelFileProcessStageStatus =
  | 'NOT_RUNNING'
  | 'RUNNING'
  | 'SUCCESS'
  | 'FAIL';

export type D3ModelFileProcessStatusMap = Partial<
  Record<
    D3ModelFileProcessStage,
    {
      status: D3ModelFileProcessStageStatus;
      event?: string;
      updatedAt?: string;
    }
  >
>;

export class D3ModelFile extends File<D3FormatAttr> {
  identifiedFileVO: IdentifiedFile;

  // 指向当前服务端的处理结果
  ufcServerProcessInfoVO: D3ModelProcessInfo;

  // uid 是上传时候指定的唯一标识
  get uid() {
    return this.attr.uid;
  }

  /** 判断是否能够在本地解析 */
  get isSupportParse() {
    return ([
      'glb',
      'gltf',
      'ply',
      'stl',
      'obj',
      'stp',
      'step',
      'igs',
      'iges',
      '3mf',
    ] as D3SupportUploadFormatType[]).includes(this.attr.type);
  }

  constructor(data: Partial<D3ModelFile>) {
    super(data);

    Object.assign(this, data);

    this.attr = new D3FormatAttr(!!data ? data.attr : {});

    this.name = this.name || this.attr.name;
    this.attr.name = this.attr.name || this.name;
    this.size = this.size || this.attr.size;

    if (this.ufcServerProcessInfoVO) {
      this.ufcServerProcessInfoVO = new D3ModelProcessInfo(
        this.ufcServerProcessInfoVO,
      );
    }
  }
}

export interface D3ModelMetrics {
  sizeX?: number;
  sizeY?: number;
  sizeZ?: number;
  volume?: number;

  sizeXStr?: string;
  sizeYStr?: string;
  sizeZStr?: string;
  volumeStr?: string;

  sizeEstimateFinished: boolean;
  volumeEstimateFinished: boolean;
}

function calcDimensionSize(attr: any, n: 'X' | 'Y' | 'Z'): number | undefined {
  if (isNil(attr)) return undefined;

  if (attr['size' + n]) return attr['size' + n];
  if (
    attr.fixedSTlFileAttribute &&
    attr.fixedSTlFileAttribute['max' + n] !== undefined &&
    attr.fixedSTlFileAttribute['min' + n] !== undefined
  ) {
    return Math.abs(
      attr.fixedSTlFileAttribute['max' + n] -
        attr.fixedSTlFileAttribute['min' + n],
    );
  }
  if (
    attr.stlFileAttribute &&
    attr.stlFileAttribute['max' + n] !== undefined &&
    attr.stlFileAttribute['min' + n] !== undefined
  ) {
    return Math.abs(
      attr.stlFileAttribute['max' + n] - attr.stlFileAttribute['min' + n],
    );
  }
}

/** 根据模型，获取当前操作订单中该模型数据 */
export const calcModelMetrics = (curModel: D3ModelFile): D3ModelMetrics => {
  const curAttr = curModel?.attr as D3FormatAttr;

  const sizeX = calcDimensionSize(curAttr, 'X');
  const sizeY = calcDimensionSize(curAttr, 'Y');
  const sizeZ = calcDimensionSize(curAttr, 'Z');
  const volume = !isNil(curAttr) ? curAttr.volume : 0;
  const sizeEstimateFinished =
    [sizeX, sizeY, sizeZ].filter(v => isNumber(v) && v > 0).length === 3;
  const volumeEstimateFinished = volume !== undefined;

  return {
    sizeX,
    sizeY,
    sizeZ,
    volume,
    sizeXStr: sizeX?.toFixed(2),
    sizeYStr: sizeY?.toFixed(2),
    sizeZStr: sizeZ?.toFixed(2),
    volumeStr: volume?.toFixed(2),
    sizeEstimateFinished,
    volumeEstimateFinished,
  };
};

export const getModelFileMetrics = ({
  modelFile,
  length,
  withVolume = false,
}: {
  length: LengthUnit;
  modelFile: D3ModelFile;
  withVolume?: boolean;
}) => {
  if (!modelFile) return '-';

  const {
    sizeXStr = '-',
    sizeYStr = '-',
    sizeZStr = '-',
    volumeStr = '-',
  } = calcModelMetrics(modelFile);
  const unitStr = getLengthUnitDesc(length);
  const sizeStr = `${sizeXStr}${unitStr} * ${sizeYStr}${unitStr} * ${sizeZStr}${unitStr}`;

  if (withVolume) return sizeStr + ` | ${volumeStr}${unitStr}³`;

  return sizeStr;
};
