/* 基础模型，提供基础的数据赋值操作，以及将 createdAt, updatedAt 以及 deletedAt 进行格式转化 */

import { assignInConstructor } from '../../utils';

export type Id = string;

export type DeprecatedId = Id;
export type DeprecatedNumber = number;
export type DeprecatedString = string;

export class BaseEntity<T> {
  // 唯一主键，Snowflake
  id?: Id;

  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;

  // 所属的租户信息
  tenantId?: Id;

  readonly _apiBase?: string;

  static assignInConstructor(classIns, props?: any) {
    assignInConstructor(classIns, props || {});
  }

  public toString() {
    return JSON.stringify(this);
  }

  constructor(props?: Partial<BaseEntity<T>>) {
    if (!props) {
      Object.assign(this, props);
    }

    if (typeof this.id === 'object') {
      this.id = (this.id as { id: Id }).id;
    }
  }
}

export type TimeUnit =
  | 'NANOSECONDS'
  | 'MICROSECONDS'
  | 'MILLISECONDS'
  | 'SECONDS'
  | 'MINUTES'
  | 'HOURS'
  | 'DAYS'
  | 'DAY'
  | 'WEEK'
  | 'MONTH'
  | 'YEAR';
