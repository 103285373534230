import {
  createInquiryOrderToWaitSubmit,
  fetchOrderQuotation,
  getOverseaInquiryOrderByCode,
  updateInquiryOrderByCodeForOverseasCustomer,
} from '../../../apis';
import {
  mapOrderItemIdViaFileIds,
  newInquiryOrderFromExisting,
} from '../../../schema';
import * as S from '../../../schema';
import { UfcShopTokenUtils } from '../../../skeleton';
import { ScopedLocalStorage } from '../../../skeleton/env/local-storage-utils';

const KEY_EDITING_CACHE = 'EditingCache';
const KEY_EDITING_CODE = 'EditingCode';

// 当前 session 编辑中的内容
let __CACHED_EDITING: CacheResult | undefined = undefined;

const storage = new ScopedLocalStorage('quote');

interface CacheResult {
  order: S.InquiryOrder;
  estPrice?: S.OrderQuotationV2;
}

export interface OrderUpdateResult {
  order: S.InquiryOrder;
  estPrice?: S.OrderQuotationV2;
  updated: boolean;
}

export function clearQuoteCache() {
  storage.removeItem(KEY_EDITING_CODE);
  storage.removeItem(KEY_EDITING_CACHE);
  __CACHED_EDITING = undefined;
}

export async function loadInquiryQuoteOrderFromStorage(
  code?: string,
): Promise<OrderUpdateResult> {
  if (code != null) {
    // 加载给定编码数据
    clearQuoteCache();
    storage.setItem(KEY_EDITING_CODE, code);
  } else if (__CACHED_EDITING) {
    // TODO: 现在加载数据到 QuoteEditor 后，ModelFile 那个组件会重新触发 processing 结果，
    // 导致订单数据更新，触发卡死，这里不使用缓存，也手工重新加载以便产生进度条
    return { ...__CACHED_EDITING, updated: true };
  }

  code = storage.getItem(KEY_EDITING_CODE);

  let order: S.InquiryOrder;
  let estPrice: S.OrderQuotationV2 | undefined = undefined;

  if (code) {
    const tmp = await getOverseaInquiryOrderByCode(code, {
      withModelFilesInfo: true,
    });

    if (tmp) {
      order = tmp;
      try {
        estPrice = await fetchOrderQuotation(code);
      } catch (e) {
        console.error(
          '>>>order-storage>>>loadInquiryQuoteOrderFromStorage>>>error: ',
          e,
        );
        estPrice = undefined;
      }
    } else {
      const cached = storage.getItem(
        KEY_EDITING_CACHE,
        newInquiryOrderFromJson,
      );
      order = cached || new S.InquiryOrder({ currency: undefined });
    }
  } else {
    const cached = storage.getItem(KEY_EDITING_CACHE, newInquiryOrderFromJson);
    order = cached || new S.InquiryOrder({ currency: undefined });
  }

  __CACHED_EDITING = { order, estPrice };
  return { ...__CACHED_EDITING, updated: true };
}

let __handlers: OrderUpdateHandler[] = [];

export function newInquiryOrderUpdateHandler(order: S.InquiryOrder) {
  for (const h of __handlers) {
    h.cancel();
  }
  __handlers = __handlers.filter(v => !v.cancelled);
  const handler = new OrderUpdateHandler(order);
  __handlers.push(handler);
  return handler;
}

export class OrderUpdateHandler {
  toHandle: S.InquiryOrder;
  // 处理中的
  computing: S.InquiryOrder;

  public cancelled: boolean;

  constructor(toHandle: S.InquiryOrder) {
    this.cancelled = false;
    this.toHandle = toHandle;
    this.computing = toHandle;
  }

  cancel() {
    this.cancelled = true;
  }

  async handle(): Promise<OrderUpdateResult | undefined> {
    if (this.toHandle === __CACHED_EDITING?.order) {
      console.log('nothing to handle', this.toHandle);
      return { ...__CACHED_EDITING, updated: false };
    }

    // 订单编码不可用的，清空其中编码
    await this.checkAndSaintRemoteOrder();
    if (this.cancelled) return;

    let savedToRemote = false;
    if (this.canOrderSaveToRemote()) {
      try {
        await this.saveOrderToRemote();
        if (UfcShopTokenUtils.isDemoUser()) {
          this.saveToLocal();
        }
        savedToRemote = true;
      } catch (e) {
        // 保存到远端失败；缓存到本地
        console.warn('save to remote failed', e);
        this.saveToLocal();
      }
    } else {
      // 无法保存到远端的，保存到本地
      this.saveToLocal();
    }

    if (!savedToRemote) return;
    if (this.cancelled) return;
    const orderCode = this.computing.code;
    if (!orderCode) return;

    const estPrice = await fetchOrderQuotation(orderCode);
    if (estPrice) {
      this.computing = tryMapOrderItemIdByQuotation(estPrice, this.computing);
    }

    __CACHED_EDITING = { estPrice, order: this.computing };
    return { ...__CACHED_EDITING, updated: true };
  }

  private saveToLocal() {
    if (this.cancelled) return;

    const order = this.computing;
    if (order) {
      console.log('save to local', KEY_EDITING_CACHE);
      storage.setItem(KEY_EDITING_CACHE, order);
      storage.setItem(KEY_EDITING_CODE, order.code);
    } else {
      console.log('remove from local', KEY_EDITING_CACHE);
      storage.removeItem(KEY_EDITING_CACHE);
      storage.removeItem(KEY_EDITING_CODE);
    }
  }

  canOrderSaveToRemote() {
    const order = this.computing;

    // 订单条目存在才可能可以存储到后端
    if (!order.items) {
      console.warn('no item found', this.toHandle, this.computing);
      return false;
    }

    if (order.items.length === 0) {
      console.warn('no item found', this.toHandle, this.computing);
      return false;
    }

    const items = order.items;
    for (const item of items) {
      if (!item.spuId) {
        console.warn('item has no spu', item);
        return false;
      }
      if (!item.printFiles || item.printFiles.length === 0) {
        console.warn('item has no print files', item);
        return false;
      }
      if (!item.printFiles[0].fileId) {
        console.warn('item has no model file', item);
        return false;
      }
      if (item.printCount == null) {
        console.warn('item has no print count', item);
        return false;
      }
    }
    return true;
  }

  private async saveOrderToRemote() {
    if (this.cancelled) return;
    const order = this.computing;

    let code: string | undefined = order.code;
    if (code) {
      const toSave = new S.InquiryOrder({
        ...order,
        stashedChanges: ['ITEM', 'PRODUCT_REMARK', 'OTHER'],
      });

      await updateInquiryOrderByCodeForOverseasCustomer(code, toSave);
    } else {
      console.warn('creating new order on save to remote', order);
      code = await createInquiryOrderToWaitSubmit(order);
      let newOrder: S.InquiryOrder;
      // 获取后端新的正确的订单信息
      if (!code) {
        console.warn('error creating new', order);
        throw new Error('error creating new');
      } else {
        newOrder = (await getOverseaInquiryOrderByCode(code)) as S.InquiryOrder;
      }
      this.computing = new S.InquiryOrder({ ...(newOrder || order), code });
    }

    storage.setItem(KEY_EDITING_CODE, code);
    // 保存到远端，本地存储 cache 清空
    storage.removeItem(KEY_EDITING_CACHE);
  }

  // 订单存在编码，检查是否可用，不可用，将从该订单数据新建订单
  private async checkAndSaintRemoteOrder() {
    if (this.cancelled) return;
    const order = this.computing;

    if (!order.code) {
      return;
    }

    try {
      const remoteOrder = await getOverseaInquiryOrderByCode(order.code);

      if (!remoteOrder || remoteOrder.status !== 'WAIT_SUBMIT') {
        // 该订单不存在，或者不是待提交订单
        this.computing = newInquiryOrderFromExisting(order);
      } else {
        // 该订单存在，且是待提交订单；保存成功，映射远端存储的订单条目 ID
        this.computing = tryMapOrderItemId(remoteOrder, order);
      }
    } catch (e) {
      this.computing = newInquiryOrderFromExisting(order);
    }
  }
}

// 通过文件进行条目 ID 映射
function tryMapOrderItemIdByQuotation(
  quotation: S.OrderQuotationV2,
  order: S.InquiryOrder,
) {
  const fileIdToItemId = {} as Record<S.Id, S.Id>;
  for (const item of quotation.itemQuotations || []) {
    for (const fileId of S.get(item, i => i.fileIds, [])) {
      fileIdToItemId[fileId] = item.id;
    }
  }

  return mapOrderItemIdViaFileIds(fileIdToItemId, order);
}

// 将后端保存的 remoteOrder 的文件条目和 order 的文件条目通过文件 ID 进行映射，填充 order 中相关条目 ID
function tryMapOrderItemId(
  remoteOrder: S.InquiryOrder,
  order: S.InquiryOrder,
): S.InquiryOrder {
  if (order?.code !== remoteOrder?.code) {
    return order;
  }

  const fileIdToItemId = {} as Record<S.Id, S.Id>;

  // 使用文件 ID 进行保存前后订单条目的定位
  for (const item of remoteOrder.items || []) {
    for (const f of item.printFiles || []) {
      if (f.fileId) {
        fileIdToItemId[f.fileId as S.Id] = item.id as S.Id;
      }
    }
  }

  return mapOrderItemIdViaFileIds(fileIdToItemId, order);
}

function newInquiryOrderFromJson(json: any) {
  try {
    return new S.InquiryOrder(json) || new S.InquiryOrder({});
  } catch (e) {
    console.warn('error deserialize order', json);
    return new S.InquiryOrder({});
  }
}
