import { Space } from 'antd';
import cn from 'classnames';

import { ShopOrderAccessors } from '../../../../../../../../../../ufc-shop-commons/src';
import { getUseShopQuoteStoreByCode } from '../../../../../../../../stores';
import ShopQuoteItemAllowAdapt from '../../ShopQuoteItemAllowAdapt';
import { ShopQuoteItemDrawings } from '../../ShopQuoteItemDrawings';
import { ShopQuoteItemFinishSelect } from '../../ShopQuoteItemFinishSelect';
import { ShopQuoteItemInserts } from '../../ShopQuoteItemInserts';
import ShopQuoteItemInspection from '../../ShopQuoteItemInspection';
import { ShopQuoteItemLocationalFit } from '../../ShopQuoteItemLocationalFit';
import { ShopQuoteItemMaterialSelect } from '../../ShopQuoteItemMaterialSelect';
import { ShopQuoteItemNotes } from '../../ShopQuoteItemNotes';
import { ShopQuoteItemPartMarking } from '../../ShopQuoteItemPartMarking';
import { ShopQuoteItemPunchCount } from '../../ShopQuoteItemPunchCount';
import { ShopQuoteItemSurfaceRoughness } from '../../ShopQuoteItemSurfaceRoughness';
import { ShopQuoteItemTolerance } from '../../ShopQuoteItemTolerance';
import styles from './index.module.less';

export interface ShopQuoteItem3DPOptionsEditorProps {
  className?: string;
  style?: Record<string, string | number>;

  orderCode: string;
  itemId: string;
}

export const ShopQuoteItem3DPOptionsEditor = ({
  className,
  style,
  orderCode,
  itemId,
}: ShopQuoteItem3DPOptionsEditorProps) => {
  const { shopOrder } = getUseShopQuoteStoreByCode(orderCode)(s => ({
    shopOrder: s.shopOrder,
  }));
  const curTechCategory = shopOrder.technology;
  const isCNCType = curTechCategory === 'CNC';

  // 在没有 shopOrder.technology 的订单，cnc 上线之前的订单，走这个 if 逻辑
  if (
    shopOrder.technology == null &&
    ShopOrderAccessors.getCurTechCategory(shopOrder).type !== '3DPRINT'
  ) {
    return <></>;
  }

  return (
    <div
      id="ShopQuoteItem3DPOptionsEditor"
      className={cn(className, styles.container)}
      style={style}
    >
      <Space direction="vertical" size={26} style={{ width: '100%' }}>
        <ShopQuoteItemMaterialSelect orderCode={orderCode} itemId={itemId} />
        <ShopQuoteItemFinishSelect orderCode={orderCode} itemId={itemId} />
        {!isCNCType && (
          <>
            <ShopQuoteItemInserts orderCode={orderCode} itemId={itemId} />
            <ShopQuoteItemPunchCount orderCode={orderCode} itemId={itemId} />
          </>
        )}
        <ShopQuoteItemDrawings orderCode={orderCode} itemId={itemId} />
        {isCNCType && (
          <>
            <ShopQuoteItemTolerance orderCode={orderCode} itemId={itemId} />
            <ShopQuoteItemSurfaceRoughness
              orderCode={orderCode}
              itemId={itemId}
            />
            <ShopQuoteItemLocationalFit orderCode={orderCode} itemId={itemId} />
            <ShopQuoteItemPartMarking orderCode={orderCode} itemId={itemId} />
            <ShopQuoteItemAllowAdapt orderCode={orderCode} itemId={itemId} />
            <ShopQuoteItemInspection orderCode={orderCode} itemId={itemId} />
          </>
        )}
        <ShopQuoteItemNotes orderCode={orderCode} itemId={itemId} />
      </Space>
    </div>
  );
};

ShopQuoteItem3DPOptionsEditor.displayName = 'ShopQuoteItem3DPOptionsEditor';
