import {
  getUseShopQuoteStoreByCode,
  i18nFormat,
  ShopOrderQuoteStoreModifiers,
  useBluePrintRequiredStore,
} from '@unionfab/ufc-shop-commons';
import { Checkbox, Form, Space, Typography } from 'antd';
import { Fragment, useEffect, useRef, useState } from 'react';

import useCNCOptions from '../../../../../../../../../ufc-shop-commons/src/stores/quote/shop-order-quote/useCNCOptions';
import { ShopQuoteItemLocations } from '../ShopQuoteItemLocations';
import { LOCATIONS_OPTS } from '../ShopQuoteItemLocations';
import { ShopQuoteItemNotesProps } from '../ShopQuoteItemNotes';
import styles from './index.module.less';

export function ShopQuoteItemLocationalFit({
  orderCode,
  itemId,
}: ShopQuoteItemNotesProps) {
  const { options: FIT_OPTS = [] } =
    useCNCOptions(s => s.options).locationsFit || {};
  const item = getUseShopQuoteStoreByCode(orderCode)(s =>
    (s.shopOrderDraft.items || []).find(i => i.id === itemId),
  );
  /** 已经上传了蓝图吗 */
  const hasBlueprint = (item?.remarkFileVO || []).length > 0;

  const defaultGroupVal = (item?.cncRequirements?.locationFits || []).map(fit =>
    FIT_OPTS.indexOf(fit.option),
  );

  const [shownLocations, setShownLocations] = useState(
    defaultGroupVal.reduce((acc, cur) => {
      acc[cur] = true;
      return acc;
    }, []),
  );

  const setStatus = useBluePrintRequiredStore((d: any) => d.setStatus);
  // console.log(FIT_OPTS)
  useEffect(() => {
    const groupVal = (item?.cncRequirements?.locationFits || []).map(fit =>
      FIT_OPTS.indexOf(fit.option),
    );
    setShownLocations(
      groupVal.reduce((acc, cur) => {
        acc[cur] = true;
        return acc;
      }, []),
    );
    const fits = item?.cncRequirements?.locationFits || [];
    (FIT_OPTS || []).forEach((fit0, idx) => {
      if (idx > 0) {
        setStatus(
          itemId + `locations-fit-${idx}`,
          fits.some(fit => fit.option === fit0),
        );
      }
    });
  }, [item?.cncRequirements?.locationFits]);

  /** 每个复选框后面的 locations 选择器的值 */
  const locationsRef = useRef([]);

  const onChange = (val: number[]) => {
    locationsRef.current.forEach((_, idx) => {
      const openedLoc = val.includes(idx);
      if (!openedLoc) {
        locationsRef.current[idx] = null;
      }
    });
    ShopOrderQuoteStoreModifiers.updateOrderDraft(orderCode, o => {
      const item = o.items.find(i => i.id === itemId);
      item.cncRequirements = {
        ...item.cncRequirements,
        locationFits: val.map(fitIdx => ({
          option: FIT_OPTS[fitIdx],
          location: LOCATIONS_OPTS[locationsRef.current[fitIdx]],
        })),
      };
      if (val.length === 0) delete item.cncRequirements.locationFits;
    });
  };
  const onLocationsChange = (locationId: number) => {
    return (val: number) => {
      locationsRef.current[locationId] = val;

      ShopOrderQuoteStoreModifiers.updateOrderDraft(orderCode, o => {
        const item = o.items.find(i => i.id === itemId);
        item.cncRequirements = {
          ...item.cncRequirements,
          locationFits: (item?.cncRequirements?.locationFits || []).map(
            fit => ({
              ...fit,
              location: LOCATIONS_OPTS[locationsRef.current[locationId]],
            }),
          ),
        };
      });
    };
  };

  return (
    <div className={styles.shopQuoteItemLocationalFitTitle}>
      <Form.Item
        initialValue={defaultGroupVal}
        rules={[
          { required: !hasBlueprint, message: 'Locational Fit is required' },
        ]}
        name="locational-fit"
        label={
          <Typography.Title level={5}>
            {i18nFormat('Locational Fit')}
          </Typography.Title>
        }
      >
        <Checkbox.Group onChange={onChange}>
          <Space direction="vertical">
            {FIT_OPTS.map((opt, idx) => (
              <Fragment key={idx}>
                <Checkbox value={idx}>{i18nFormat(opt)}</Checkbox>
                {idx > 0 && shownLocations[idx] && (
                  <ShopQuoteItemLocations
                    onChange={onLocationsChange(idx)}
                    formValId={getFormValId(idx)}
                    formName={`fit-${idx}`}
                  />
                )}
              </Fragment>
            ))}
          </Space>
        </Checkbox.Group>
      </Form.Item>
    </div>
  );

  function getFormValId(idx: number) {
    const fitsData = item?.cncRequirements?.locationFits || [];
    const targetFitData = fitsData.find(fit => fit.option === FIT_OPTS[idx]);
    return LOCATIONS_OPTS.indexOf(targetFitData?.location);
  }
}

ShopQuoteItemLocationalFit.displayName = 'ShopQuoteItemLocationalFit';
