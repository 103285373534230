import * as S from '../../../../schema';
import {
  getTenantIdFromGConfig,
  getUfcShopComposedApi,
  getUfcShopRuntimeEnv,
} from '../../../../singleton-ins';

// 订单的支付订单数据
export interface OrderPayOrder {
  // 支付订单 ID
  id: S.Id;
  tenantId: S.Id;
  orderId: S.Id;
  amount: S.CurrencyAndAmount;

  status: 'WAIT_PAY' | 'COMPLETE_PAY' | 'EXCEPTION_PAY' | 'CANCEL_PAY';
  channel: 'WECHAT_PAY' | 'PAYPAL' | 'OCEAN_PAY';
  // PayPal 支付订单数据
  payPal?: { order: any };
  statusTriggerData?: any;
}

/** 获取当前有效的支付订单 */
export async function getOrderPayOrder(orderId: S.Id) {
  if (!orderId) return;

  try {
    const {
      data,
    } = await getUfcShopComposedApi().umiRequestCompatibleApi.getUmi<{
      data: OrderPayOrder;
    }>(`/orders/${orderId}/pay_order`, {
      params: { tenantId: getTenantIdFromGConfig() },
    });

    return data;
  } catch (e) {
    console.log('>>>getOrderPayOrder>>>err', e);
  }
}

export interface TriggerTransferNotify {
  orderCode: string;
  customerName: string;
  email: string;
}

export async function triggerTransferNotify(req: TriggerTransferNotify) {
  const r = await getUfcShopComposedApi().umiRequestCompatibleApi.postUmi(
    `/customer/order/transfer_notify`,
    {
      data: req,
    },
  );

  if ((r as any)?.status !== 'ok') {
    throw r;
  }
}

/** 获取汇率 */
export async function getCurrencyExchangeRate(
  source: S.CurrencySymbolType,
  target: S.CurrencySymbolType,
): Promise<number> {
  const {
    data,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.getUmi<{
    data: number;
  }>(`/inquiry_order/currency/trans/${source}/${target}`);
  return data || 1;
}
