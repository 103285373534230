import * as S from '@unionfab/ufc-shop-commons';
import {
  i18nFormat,
  triggerTransferNotify,
  useProfileStore,
} from '@unionfab/ufc-shop-commons';
import { Button, message } from 'antd';
import { useEffect, useMemo } from 'react';

import { useAppNavigate } from '@/features/shared';

import styles from './index.module.less';

export const OrderBankTransferBtn = ({
  orderCode,
  canPay,
  onOpenTransferPage,
}: {
  orderCode: string;
  canPay: boolean;
  onOpenTransferPage?: () => void;
}) => {
  const nav = useAppNavigate();
  const { accountInfo, loadPersonInfo } = useProfileStore(s => ({
    accountInfo: s.accountInfo,
    loadPersonInfo: s.loadPersonInfo,
  }));

  useEffect(() => {
    !accountInfo && loadPersonInfo();
  }, [accountInfo]);

  const customerName = useMemo(() => {
    if (!accountInfo) return;

    const { firstName, lastName, nickName, phoneNumber } = accountInfo;

    if (firstName || lastName) {
      return `${firstName} ${lastName}`;
    }

    return nickName || phoneNumber || '-';
  }, [accountInfo]);

  const email = useMemo(() => accountInfo?.email, [accountInfo]);

  const onClickBankTransfer = async () => {
    if (email && customerName) {
      try {
        await triggerTransferNotify({
          orderCode,
          customerName,
          email,
        });
      } catch (e) {
        if (email) {
          message.warning(
            i18nFormat(
              'Error sending the bank details email to {email}: check this page for details.',
            ).replace('{email}', email),
          );
        } else {
          message.warning(
            i18nFormat(
              'Error sending the bank details email: check this page for details.',
            ),
          );
        }
      }
    } else {
      console.warn('no user email found');
    }

    onOpenTransferPage ? onOpenTransferPage() : nav.navToBankTransfer();
  };

  return (
    <Button
      className={styles.payBtn}
      type="primary"
      disabled={!canPay || !accountInfo}
      onClick={onClickBankTransfer}
    >
      {i18nFormat('Go Bank Page')}
    </Button>
  );
};
