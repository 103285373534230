import * as S from '@unionfab/ufc-shop-commons';

import CompletedIcon from '@/assets/svgs/completedIcon.svg';
import PaymentIcon from '@/assets/svgs/paymentIcon.svg';
import ProcessIcon from '@/assets/svgs/processIcon.svg';
import ShippedIcon from '@/assets/svgs/shippedIcon.svg';
import SubmitIcon from '@/assets/svgs/submitIcon.svg';

export interface OverseaOrderSteps {
  label: string;
  updateAt?: string;
  isActive?: boolean;
  icon?: any;
  middleState?: Omit<OverseaOrderSteps, 'middleState'>;
}

const CONFIRMED_ORDER_STATUS = [
  'CUSTOM_CONFIRM',
  'WAIT_PRINT',
  'FILE_HANDLE',
  'PRINTING',
  'HANDLE',
  'WAIT_DELIVER',
  'WAIT_RECEIVE',
  'ORDER_COMPLETE',
  'ORDER_CLOSED',
];

export const getDefaultOverseaOrderSteps = (): OverseaOrderSteps[] => [
  {
    label: 'Submit Order',
    icon: SubmitIcon,
    isActive: false,
  },
  {
    isActive: false,
    icon: PaymentIcon,
    label: 'Payment Success',
    middleState: { label: 'Pre-Processing' },
  },
  {
    isActive: false,
    icon: PaymentIcon,
    label: 'Confirm Order',
    middleState: { label: 'Pre-Processing' },
  },
  {
    label: 'In-Process',
    icon: ProcessIcon,
    isActive: false,
    middleState: { label: 'To Ship' },
  },
  { label: 'Shipped', icon: ShippedIcon, isActive: false },
  { label: 'Completed', isActive: false, icon: CompletedIcon },
];

export const getOverseaOrderCurrentSteps = ({
  order,
  orderStepTimeMap,
}: {
  order: S.InquiryOrder;
  orderStepTimeMap: S.StepTimeMap;
}): OverseaOrderSteps[] => {
  let overseaSteps = getDefaultOverseaOrderSteps();

  if (!order || !S.isValid(orderStepTimeMap)) {
    return overseaSteps;
  }

  const { payTime, confirmTime, processTime, shipmentTime, completedTime } =
    orderStepTimeMap;

  const isCompleted = order.status == 'ORDER_COMPLETE';

  if (orderStepTimeMap.payTime.value) {
    overseaSteps = overseaSteps.filter(o => o.label !== 'Confirm Order');
  } else {
    overseaSteps = overseaSteps.filter(o => o.label !== 'Payment Success');
  }

  for (const step of overseaSteps) {
    if (step.label == 'Submit Order') {
      step.isActive = true;
      step.updateAt = order.createdAt;
    }

    if (step.label == 'Payment Success') {
      const csConfirmed = CONFIRMED_ORDER_STATUS.includes(order.status);

      !payTime.est && (step.updateAt = payTime.value);
      step.isActive = isCompleted || csConfirmed || !payTime.est; // est 为 false 代表暂未支付
      step.middleState.isActive = order.status == 'CUSTOM_CONFIRM';
    }

    if (step.label == 'Confirm Order') {
      const csConfirmed = CONFIRMED_ORDER_STATUS.includes(order.status);

      !confirmTime.est && (step.updateAt = confirmTime.value);
      step.isActive = isCompleted || csConfirmed || !payTime.est; // est 为 false 代表暂未支付
      step.middleState.isActive = order.status == 'CUSTOM_CONFIRM';
    }

    if (step.label == 'In-Process') {
      step.isActive = isCompleted || !processTime.est;
      !processTime.est && (step.updateAt = processTime.value);
      step.middleState.isActive = order.status == 'WAIT_DELIVER';
    }

    if (step.label == 'Shipped') {
      step.isActive = isCompleted || !shipmentTime.est;
      !shipmentTime.est && (step.updateAt = shipmentTime.value);
    }

    if (step.label == 'Completed') {
      step.isActive = isCompleted || !completedTime.est;
      !completedTime.est && (step.updateAt = completedTime.value);
    }
  }

  /** 防止出现后续节点已激活前置节点未激活的状态 */
  overseaSteps.forEach((s, idx) => {
    const nextStep = overseaSteps[idx + 1];

    if (!s.isActive && nextStep && nextStep.isActive) {
      s.isActive = true;
      s.middleState.isActive = false;
    }

    if (!s.isActive && s.middleState && s.middleState.isActive) {
      s.isActive = true;
    }
  });

  return overseaSteps;
};
