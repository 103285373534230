import { CheckCircleOutlined } from '@ant-design/icons';
import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { getOrderPayOrder } from '@unionfab/ufc-shop-commons';
import { Button, Spin } from 'antd';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { getUseShopQuoteStoreByCode } from '@/stores';

import styles from './index.module.less';

export const ShopQuotePaymentSuccessPage = () => {
  const navigate = useNavigate();
  const orderCode = useParams().orderCode;
  const useShopQuoteStore = getUseShopQuoteStoreByCode(orderCode);

  const shopOrder = useShopQuoteStore(s => s.shopOrder);

  const { isLoading, data: payOrder } = useQuery(['payOrder', shopOrder.id], {
    queryFn: () => {
      if (!shopOrder || !shopOrder.id) return;

      return getOrderPayOrder(shopOrder.id);
    },
    onSuccess: data => {
      /** 仅有订单支付完成后展示当前页面 */
      if (!data || data.status !== 'COMPLETE_PAY') {
        navigate(-1);
      } else {
        useShopQuoteStore.setState({ isProcessing: false });
      }
    },
  });

  useEffect(() => {
    if (!shopOrder) {
      // 该页面只应该由 payment 转过来
      console.warn('illegal state, no order loaded (payment success)');
      navigate('/');
    }
  }, []);

  if (!shopOrder) {
    return <></>;
  }

  return (
    <section className={styles.container}>
      <Spin spinning={isLoading}>
        <div className={styles.result}>
          <div className={styles.successIcon}>
            <CheckCircleOutlined />
          </div>

          <div className={styles.thanks}>
            {i18nFormat('Thank you for your order')} !
          </div>

          <div className={styles.info}>
            <span>
              {i18nFormat('Your order code')}: {orderCode}
            </span>
            {payOrder && (
              <span>
                {i18nFormat('Total Price')}:{' '}
                {S.toPriceStringWithOption(Number(payOrder.amount.amount), {
                  currency: payOrder.amount.currency,
                })}
              </span>
            )}
          </div>

          <div className={styles.goDetail}>
            <Link to={`/orders_history/${orderCode}?id=${shopOrder.id}`}>
              <Button type="primary">
                {i18nFormat('See the Order Detail')}
              </Button>
            </Link>
          </div>
        </div>
      </Spin>
    </section>
  );
};
