import {
  getUfcShopComposedApi as api,
  i18nFormat,
} from '@unionfab/ufc-shop-commons';
import * as S from '@unionfab/ufc-shop-commons';
import { Spin } from 'antd';
import cn from 'classnames';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

export const OrderTotalPrice = ({
  order,
  className,
}: {
  order: S.ShopOrder;
  className?: string;
}) => {
  const { code, status, price } = order;

  const isUnpaid = S.UnpaidInquiryOrderStatusList.includes(status);

  const { data: priceInfo, isLoading } = useQuery({
    refetchOnWindowFocus: false,
    enabled: isUnpaid && !!order,
    queryKey: ['priceInfo', order],
    refetchInterval: q => (q && q.hasComputingItem ? 3000 : false),
    queryFn: () => {
      const expressId = S.get(
        order,
        o => o.requirements.customerSelections.delivery.expressId,
      );

      return api().shopOrderQueryApi.calcPrice(code, { expressId });
    },
  });

  const totalPrice = useMemo(() => {
    if (!isUnpaid) return S.get(price, p => p.total);

    if ((priceInfo && priceInfo.hasComputingItem) || isLoading) return <Spin />;

    if (status == 'WAIT_SUBMIT') return S.get(priceInfo, p => p.subTotal);

    return S.get(priceInfo, p => p.total);
  }, [priceInfo, price, isLoading, isUnpaid]);

  const quotationUpdateStr = useMemo(() => {
    if (
      priceInfo &&
      !priceInfo.customerCheckedAt &&
      order.status == 'WAIT_CUSTOM_CONFIRM'
    ) {
      return (
        <div style={{ color: '#ff4d4f', marginTop: 4 }}>
          !{i18nFormat('updated')}
        </div>
      );
    }

    return <></>;
  }, [priceInfo, order]);

  return (
    <span className={cn(className)}>
      {totalPrice}
      {quotationUpdateStr}
    </span>
  );
};
