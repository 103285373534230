import {
  getOceanpayInfo,
  getTenantIdFromGConfig,
  getUfcShopComposedApi as apis,
  getUfcShopRuntimeEnv,
  i18nFormat,
  noticeOceanPayRes,
  useProfileStore,
} from '@unionfab/ufc-shop-commons';
import * as S from '@unionfab/ufc-shop-commons';
import { Button, message } from 'antd';
import { useState } from 'react';

import { useAppNavigate } from '@/features/shared';
import { useInquiryQuoteCheckoutStore } from '@/stores';

import styles from './index.module.less';

interface OceanpaymentProps {
  oceanpaymentRef: any;
  saveOrderShipOptionForPayment: (antdMessageKey: string) => Promise<void>;
}

export const OrderOceanpaymentBtn = ({
  oceanpaymentRef,
  saveOrderShipOptionForPayment,
}: OceanpaymentProps) => {
  const tenantId = getTenantIdFromGConfig();
  const nav = useAppNavigate();

  const accountInfo = useProfileStore(a => a.accountInfo);

  const {
    order,
    coupon,
    isWaitingUserPay,
    canPay,
    setOnPay,
    billingAddress,
    oceanpaymentEndpoint,
    setOceanpaymentState,
    setOceanpaymentPayUrlWindowOpen,
    setPaymentResult,
  } = useInquiryQuoteCheckoutStore();
  const [payOrderId, setPayOrderId] = useState<
    { payOrderId: string; oceanPayOrderNumber: string } | undefined
  >();

  if (!order) {
    console.warn('PaypalButton: illegal state, order not loaded');
    return <></>;
  }

  const orderId = order.id!;
  const couponCode = coupon?.code;

  const getBillingEmail = async () => {
    try {
      const resp =
        accountInfo || (await apis().userApi.getAccountProfileInfo());

      if (resp.email != null) return resp.email;

      if (resp.personId != null) {
        const s = resp.personId.split('-', 2);

        if (s.length === 2) {
          return `${s[1]}@unionfab-billing.com`;
        } else {
          return `${s}@unionfab-billing.com`;
        }
      }
    } catch (e) {
      console.warn('error get user info', e);
    }
    return (
      [billingAddress?.firstName, billingAddress?.lastName]
        .filter(v => !!v)
        .join('-')
        .substring(0, 25) + '@unionfab-billing.com'
    );
  };

  const createOceanPayOrder = async (antdMessageKey: string) => {
    if (
      !billingAddress ||
      !billingAddress.firstName ||
      !billingAddress.lastName
    ) {
      message.warning({
        content: 'Billing information is not completed',
        key: antdMessageKey,
      });

      setPaymentResult({
        success: false,
        failureMessage: 'Billing information is not completed',
      });

      return;
    }

    await saveOrderShipOptionForPayment(antdMessageKey);

    // 钱海对该字段描述：没有该值可以默认传[消费者id@域名/简称.com]
    // 长度有限制 50
    const billingEmail = await getBillingEmail();

    /** 获取支付参数 */
    message.info({
      content: i18nFormat('Creating pay order'),
      key: antdMessageKey,
    });
    return await getOceanpayInfo(
      orderId,
      tenantId,
      billingEmail,
      billingAddress,
      {
        couponCode,
        methods: 'Credit Card',
      },
    );
  };

  const onOceanpaySuccessMessage = async (orderNumber: string, msg: string) => {
    if (!payOrderId) {
      console.warn('recv', orderNumber, 'no cur order');
      return;
    }
    if (orderNumber !== payOrderId?.oceanPayOrderNumber) {
      console.warn('recv', orderNumber, 'cur', payOrderId);
      return;
    }

    console.log('recv', orderNumber, msg);

    try {
      await noticeOceanPayRes(tenantId, payOrderId.payOrderId as S.Id, msg);
      setPaymentResult({ success: true });
    } catch (e) {
      console.warn('error on success response', msg, e);
      setPaymentResult({
        success: false,
        failureMessage: 'Something wrong with the payment',
      });
    }
  };

  const payViaOceanpay = async () => {
    const antdMessageKey = 'create_order';

    if (!oceanpaymentRef || !oceanpaymentRef.current) {
      setPaymentResult({
        success: false,
        failureMessage: 'Oceanpay component is not loaded correctly',
      });
      return;
    }

    const payOrder = await createOceanPayOrder(antdMessageKey);

    setOnPay(true, undefined, 'modal');

    if (!payOrder) {
      setPaymentResult({
        success: false,
        failureMessage: 'Oceanpay pay order is not correctly created',
      });
      return;
    }

    const payOrderId = payOrder.payOrderId;
    const oceanPayOrderNumber = payOrder.orderNumber;
    setPayOrderId({ payOrderId, oceanPayOrderNumber });

    // 注册当前支付订单的支付回调
    const cb = createOceanpayPayResCb(
      oceanpaymentEndpoint,
      (payUrl, state) => {
        console.log('>>> requires auth code', payUrl, state);
        setOceanpaymentState(payUrl, state);
        if (payUrl) {
          setOceanpaymentPayUrlWindowOpen(true);
        }
        window.removeEventListener('message', cb);
      },
      async (orderNumber, rawXmlMsg) => {
        await onOceanpaySuccessMessage(orderNumber, rawXmlMsg);
        window.removeEventListener('message', cb);
      },
      (failureMessage: string) => {
        setPaymentResult({ success: false, failureMessage });
        window.removeEventListener('message', cb);
      },
    );
    window.addEventListener('message', cb);

    // 回调到后端的接口地址
    const noticeUrl = getOceanpayNoticeUrl(tenantId, payOrderId);
    // 支付完成跳转回的地址
    const backUrl = window.location.href;
    const resp = S.transformOceanpaymentInfo({
      ...payOrder,
      backUrl,
      noticeUrl,
    });
    console.log(
      'posting',
      oceanpaymentRef.current.contentWindow,
      resp,
      oceanpaymentEndpoint,
    );
    oceanpaymentRef.current.contentWindow.postMessage(
      resp,
      oceanpaymentEndpoint,
    );
  };

  const pay = async () => {
    try {
      await payViaOceanpay();
    } catch (_e) {
      setPaymentResult({ success: false, failureMessage: 'Payment failed' });
    }
  };

  return (
    <div className={styles.orderOceanpayBtn}>
      <div className={styles.btnWrapper}>
        <Button
          type="primary"
          className="payBtn"
          disabled={!canPay}
          loading={isWaitingUserPay}
          onClick={() => pay()}
          style={{ opacity: canPay ? 1 : 0.6 }}
        >
          {i18nFormat('Pay now')}
        </Button>

        <div className={styles.btnTips}>
          <span className={styles.privacyPolicy}>
            Powered by stripe &nbsp;
            <a onClick={() => nav.navigateToPdfPageNewTab('privacy_policy')}>
              {i18nFormat('Privacy Policy')}
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

/**
 * 处理给定支付订单的支付结果消息
 *
 * @param endpoint 处理来自该源地址的消息
 */
function createOceanpayPayResCb(
  endpoint: string,
  onPayUrl: (
    payUrl?: string,
    state?: { detailMessage: string; solution: string },
  ) => void,
  onSuccess: (orderNumber: string, msg: string) => Promise<void>,
  onPayFailed: (msg: string) => void,
) {
  return async (e: MessageEvent) => {
    if (!e || !(e.origin || '').includes('oceanpayment.com')) {
      console.log('callback ignored: ', e.origin, endpoint, e);
      return;
    }

    const msg = S.parseOceanpayCbEvent(e);
    console.log('recv payment callback', e, msg);

    if (!msg || msg.type !== 'paymentRes') {
      return;
    }

    if (msg.data.payUrl != null && msg.data.payUrl.trim() !== '') {
      onPayUrl(msg.data.payUrl, {
        detailMessage: msg.data.detailMessage,
        solution: msg.data.paymentSolution,
      });
    } else {
      switch (msg.data.paymentStatus) {
        case '1':
          onSuccess(msg.data.orderNumber, msg.data.rawXmlMsg);
          break;
        case '-1':
          // 支付待处理
          if (msg.data.paymentAuthType === '1') {
            // 信用卡预授权，启用预授权才会有
            // 展示付款成功页面给消费者看
            // 订单需要人工审核
            console.log('unexpected status: ', msg);
          } else {
            onPayFailed(msg.data.detailMessage);
          }
          break;
        case '0':
        default:
          onPayFailed(msg.data.detailMessage);
          break;
      }
    }
  };
}

function getOceanpayNoticeUrl(tenantId: string, payOrderId: string) {
  return `${
    getUfcShopRuntimeEnv().apiEndpoint
  }/order/pay/notify/oceanpay/notice?tenantId=${tenantId}&payOrderId=${payOrderId}`;
}
