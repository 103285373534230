import { get, i18nFormat } from '@unionfab/ufc-shop-commons';
import * as S from '@unionfab/ufc-shop-commons';
import { Typography } from 'antd';
import { compact, uniq, uniqBy } from 'lodash';
import { CSSProperties, useMemo } from 'react';

import { QuoteFinishSelect } from '@/features/new_quote/components/select/QuoteFinishSelect';
import {
  getUseShopQuoteStoreByCode,
  ShopOrderQuoteStoreModifiers,
} from '@/stores';

import styles from './index.module.less';
export interface ShopQuoteItemsFinishBulkSelectorProps {
  style?: CSSProperties;

  orderCode: string;
}

export const ShopQuoteItemsFinishBulkSelector = ({
  style,
  orderCode,
}: ShopQuoteItemsFinishBulkSelectorProps) => {
  const { shopOrderDraft } = getUseShopQuoteStoreByCode(orderCode)(s => ({
    shopOrderDraft: s.shopOrderDraft,
  }));

  const materialTypeIds = uniq(
    compact(
      (shopOrderDraft.items || []).map(i =>
        S.get(i, i => i.materialVO.materialTypeId),
      ),
    ),
  );

  const onUpdateFinish = ({
    finishMethod,
    color,
  }: {
    finishMethod: string;
    color: S.HandleMethodColor;
  }) => {
    ShopOrderQuoteStoreModifiers.updateOrderDraft(orderCode, o => {
      for (const item of o.items) {
        item.handleRemark = {
          desc: color.name,
          color: color.name,
          method:
            finishMethod && finishMethod !== 'Standard' ? [finishMethod] : [],
        };
      }
    });
  };

  const value = useMemo(() => {
    const resp = (shopOrderDraft.items || []).map(i => i.handleRemark);
    const isSameHandleMethod =
      uniqBy(resp, r => get(r, r => r.method[0])).length == 1;

    if (isSameHandleMethod) return resp[0];
  }, [shopOrderDraft.items]);

  return (
    <div
      style={style}
      id="ShopQuoteItemsMaterialBulkSelector"
      className={styles.shopQuoteBulkConfigurationItem}
    >
      <div className={styles.title}>
        <Typography.Title level={5}>{i18nFormat('Finish')}</Typography.Title>
        <a
          href={'https://www.unionfab.com/service_cat/finishing'}
          target="_blank"
          rel="noreferrer"
        >
          {i18nFormat('Learn about our finishes')}
        </a>
      </div>
      <QuoteFinishSelect
        value={value}
        onChange={onUpdateFinish}
        materialTypeIds={materialTypeIds}
      />
    </div>
  );
};

ShopQuoteItemsFinishBulkSelector.displayName =
  'ShopQuoteItemsFinishBulkSelector';
