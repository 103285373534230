import { useShopQuoteUiStore } from '@unionfab/ufc-shop-commons';

import { QuoteFilesUploadingItem } from './QuoteFilesUploadingItem';

export const QuoteFilesUploadingList = () => {
  const { uploadingMap } = useShopQuoteUiStore();
  const isUploading = Object.keys(uploadingMap).length > 0;

  const uploadingItems = Object.keys(uploadingMap)
    .filter(uid => !!uploadingMap[uid])
    .map(uid => {
      const file = uploadingMap[uid];
      return <QuoteFilesUploadingItem key={uid} file={file} />;
    });

  return <span>{isUploading && uploadingItems}</span>;
};
