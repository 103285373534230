import { fetchCountries, i18nFormat } from '@unionfab/ufc-shop-commons';
import * as S from '@unionfab/ufc-shop-commons';
import { Button, Checkbox, Form, Input, Space } from 'antd';
import { Rule } from 'antd/lib/form';
import cn from 'classnames';
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js';
import { useEffect, useState } from 'react';

import { CountrySelect, PhoneNumberInput } from '@/commons/components';
import { ProvinceSelect } from '@/commons/components/Country/ProvinceSelect';

import styles from './index.module.less';

export interface ReceiveAddressFormValue {
  firstName?: string;
  lastName?: string;
  street?: string;
  detail?: string;
  // companyName?: string;
  country?: string;
  phoneNumber?: string;
  province?: string;
  city?: string;
  postcode?: string;
  email?: string;
  defaultAddress: boolean;
}

function convertDeliverAddressToReceiveAddress(
  addr: S.ReceiveAddress,
  form: ReceiveAddressFormValue,
): S.ReceiveAddress {
  const props = {
    recipient: `${form.firstName} ${form.lastName}`,
    firstName: form.firstName,
    lastName: form.lastName,
    defaultAddress: form.defaultAddress,
    // companyName: form.companyName,
    phoneNumber: form.phoneNumber,
    country: form.country,
    province: form.province,
    city: form.city,
    street: form.street,
    detail: form.detail,
    postcode: form.postcode,
    email: form.email,
  };
  return new S.ReceiveAddress(Object.assign({}, addr, props));
}

function convertToReceiveAddressForm(
  addr: S.ReceiveAddress | undefined,
): ReceiveAddressFormValue {
  return {
    firstName: addr?.firstName,
    lastName: addr?.lastName,
    defaultAddress: addr?.defaultAddress || false,
    // companyName: addr?.companyName,
    phoneNumber: addr?.phoneNumber,
    country: addr?.country,
    province: addr?.province,
    city: addr?.city,
    street: addr?.street,
    detail: addr?.detail,
    postcode: addr?.postcode,
    email: addr.email,
  };
}

export const ReceiveAddressForm = ({
  onClose,
  address,
  onSubmit,
  okText,
  layout = 'horizontal',
}: {
  onClose: () => void;
  address: S.ReceiveAddress;
  layout?: 'horizontal' | 'vertical';
  onSubmit: (address: S.ReceiveAddress) => Promise<void>;
  okText?: string;
}) => {
  const [form] = Form.useForm<ReceiveAddressFormValue>();
  const [okBtnLoading, setOkBtnLoading] = useState(false);
  const [phoneNumberEdited, setPhoneNumberEdited] = useState(
    S.get(address, a => a.phoneNumber) != null &&
      S.get(address, a => a.phoneNumber, '').trim() !== '',
  );

  const submit = async (fieldValues: ReceiveAddressFormValue) => {
    try {
      setOkBtnLoading(true);

      const toSubmit = convertDeliverAddressToReceiveAddress(
        address,
        fieldValues,
      );

      await onSubmit(toSubmit);
    } finally {
      setOkBtnLoading(false);
    }
  };

  // 可用国家列表
  const [countries, setCountries] = useState<S.Country[]>([]);
  useEffect(() => {
    fetchCountries().then(v => {
      setCountries(v);

      const formValues = convertToReceiveAddressForm(address);
      form.setFieldsValue(formValues);
    });
  }, []);

  function getCountry(countryName: string | undefined) {
    if (!countryName) return;
    return countries.find(v => v.name === countryName);
  }

  const formRules: Partial<Record<keyof ReceiveAddressFormValue, Rule[]>> = {
    firstName: [
      {
        required: true,
        message: i18nFormat('First name is required.'),
      },
      {
        max: 60,
        message: i18nFormat('The 80 character limit was exceeded.'),
      },
    ],
    lastName: [
      {
        required: true,
        message: i18nFormat('Last name is required.'),
      },
      {
        max: 60,
        message: i18nFormat('The 80 character limit was exceeded.'),
      },
    ],
    defaultAddress: [],
    // companyName: [
    //   {
    //     max: 150,
    //     message: 'The 150 character limit was exceeded.',
    //   },
    // ],
    phoneNumber: [
      {
        validator: async (_, value) => {
          if (typeof value !== 'string' || value == '') {
            throw new Error(i18nFormat('Phone Number is required.'));
          }
          const curCountry = getCountry(form.getFieldsValue().country);
          if (!isValidPhoneNumber(value, curCountry?.iso2 as CountryCode)) {
            throw new Error(i18nFormat('Invalid phone number'));
          }
        },
        message: i18nFormat('Invalid phoneNumber.'),
      },
      {
        max: 40,
        message: i18nFormat('The 40 character limit was exceeded.'),
      },
    ],
    country: [
      {
        validator: async (_, value) => {
          if (typeof value !== 'string' || value == '') {
            throw new Error(i18nFormat('Country is required'));
          }
          const curCountry = getCountry(form.getFieldsValue().country!);
          if (!curCountry?.iso2) {
            throw new Error(i18nFormat('Invalid country'));
          }
        },
      },
    ],
    street: [
      {
        required: true,
        message: i18nFormat('Address is required.'),
      },
      {
        max: 150,
        message: i18nFormat('The 150 character limit was exceeded.'),
      },
    ],
    detail: [
      {
        required: true,
        message: i18nFormat('Detailed Address is required.'),
      },
      {
        max: 200,
        message: i18nFormat('The 200 character limit was exceeded.'),
      },
    ],
    province: [
      {
        required: true,
        message: i18nFormat('State is required.'),
      },
    ],
    city: [
      {
        required: true,
        message: i18nFormat('City is required.'),
      },
      {
        max: 60,
        message: i18nFormat('The 60 character limit was exceeded.'),
      },
    ],
    postcode: [
      {
        required: true,
        message: i18nFormat('Zip Code is required.'),
      },
      {
        max: 30,
        message: i18nFormat('The 30 character limit was exceeded.'),
      },
    ],
    email: [],
  };

  const isPropertyChanged = (propName: keyof ReceiveAddressFormValue) => (
    prev: ReceiveAddressFormValue,
    after: ReceiveAddressFormValue,
  ) => prev[propName] !== after[propName];

  const formProps = {
    layout,
    labelCol: { span: layout == 'vertical' ? 24 : 8 },
    wrapperCol: { span: layout == 'vertical' ? 24 : 16 },
  };

  return (
    <div className={styles.receiveAddressForm}>
      <Form {...formProps} form={form} onFinish={submit} labelAlign="left">
        <div
          className={cn(
            styles.receiveAddressFormItems,
            layout == 'vertical' && styles.verticalFormItems,
          )}
        >
          <Form.Item
            label={i18nFormat('First Name')}
            name="firstName"
            rules={formRules.firstName}
            className={styles.receiveAddressFormItemNameFirst}
          >
            <Input placeholder={i18nFormat('First Name')} />
          </Form.Item>
          <Form.Item
            label={i18nFormat('Last Name')}
            name="lastName"
            rules={formRules.lastName}
            className={styles.receiveAddressFormItemNameLast}
          >
            <Input placeholder={i18nFormat('Last Name')} />
          </Form.Item>
          {/* <Form.Item
            label={i18nFormat('Company Name')}
            name="companyName"
            rules={formRules.companyName}
          >
            <Input placeholder={i18nFormat('Company Name')} />
          </Form.Item> */}
          <Form.Item
            label={i18nFormat('Street & Building')}
            name="street"
            rules={formRules.street}
          >
            <Input placeholder={i18nFormat('Street & building')} />
          </Form.Item>
          <Form.Item
            required
            label={i18nFormat('Detailed Address')}
            name="detail"
            rules={formRules.detail}
          >
            <Input placeholder={i18nFormat('Detailed Address')} />
          </Form.Item>
          <Form.Item
            required
            label={i18nFormat('Country & Region')}
            shouldUpdate={isPropertyChanged('country')}
            rules={formRules.country}
          >
            {({ getFieldsValue, setFieldsValue }) => {
              const fieldsValue = getFieldsValue();
              return (
                <Form.Item noStyle name="country" rules={formRules.country}>
                  <CountrySelect
                    country={{ type: 'name', value: fieldsValue.country || '' }}
                    onCountryChange={v => {
                      const changed: Partial<ReceiveAddressFormValue> = {
                        country: v.name,
                      };
                      if (v.callingCode && !phoneNumberEdited) {
                        changed['phoneNumber'] = `+${v.callingCode}`;
                      }
                      setFieldsValue(changed);
                    }}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item
            label={i18nFormat('Phone Number')}
            rules={formRules.phoneNumber}
            shouldUpdate={isPropertyChanged('phoneNumber')}
          >
            {({ getFieldsValue, setFieldsValue }) => (
              <Form.Item
                noStyle
                name="phoneNumber"
                rules={formRules.phoneNumber}
              >
                <PhoneNumberInput
                  value={getFieldsValue().phoneNumber}
                  onChange={phoneNumber => {
                    setFieldsValue({ phoneNumber });
                    setPhoneNumberEdited(true);
                  }}
                  defaultCountryIso2={
                    getCountry(getFieldsValue().country)?.iso2
                  }
                />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item
            label={i18nFormat('State')}
            rules={formRules.province}
            shouldUpdate={isPropertyChanged('country')}
          >
            {({ getFieldsValue, setFieldsValue }) => {
              const fieldsValue = getFieldsValue();
              return (
                <Form.Item noStyle name="province" rules={formRules.province}>
                  <ProvinceSelect
                    countryIso2={getCountry(fieldsValue.country)?.iso2}
                    province={fieldsValue.province}
                    onProvinceChange={province => setFieldsValue({ province })}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item
            label={i18nFormat('City')}
            name="city"
            rules={formRules.city}
          >
            <Input placeholder={i18nFormat('City')} />
          </Form.Item>

          <Form.Item
            label={i18nFormat('Zip Code')}
            name="postcode"
            rules={formRules.postcode}
          >
            <Input placeholder={i18nFormat('Zip Code')} />
          </Form.Item>
          <Form.Item
            name="email"
            label={i18nFormat('Email')}
            rules={formRules.email}
          >
            <Input placeholder={i18nFormat('Email')} />
          </Form.Item>
        </div>
        <div className={styles.receiveAddressFormFooter}>
          <Form.Item noStyle shouldUpdate={isPropertyChanged('country')}>
            {({ getFieldsValue, setFieldsValue }) => {
              const fieldsValue = getFieldsValue();
              return (
                <Form.Item noStyle name="defaultAddress">
                  <Checkbox
                    className={styles.defaultAddress}
                    checked={fieldsValue.defaultAddress}
                    onChange={evt =>
                      setFieldsValue({ defaultAddress: evt.target.checked })
                    }
                  >
                    {i18nFormat('Make this my default address')}
                  </Checkbox>
                </Form.Item>
              );
            }}
          </Form.Item>
          <Space className={styles.actionButtons}>
            <Button onClick={onClose}>{i18nFormat('Cancel')}</Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={okBtnLoading}
              className={styles.confirmBtn}
            >
              {i18nFormat(okText || 'Save')}
            </Button>
          </Space>
        </div>
      </Form>
    </div>
  );
};
