import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Button, InputNumber, Modal } from 'antd';
import cn from 'classnames';

import { InsertTable } from '@/commons/components/KnowledgeBase/InsertTable';
import { useInquiryQuoteEditorStore } from '@/stores';

export const OrderItemInserts = ({
  orderItem,
  className,
}: {
  orderItem: S.InquiryOrderItem;
  className?: string;
}) => {
  const { updateInquiryOrder } = useInquiryQuoteEditorStore();
  const curNutCount = orderItem.printFiles[0]?.nutCount || 0;

  const changeNutCount = (count: number | null) => {
    updateInquiryOrder('changeOrderItemNutCount', () => {
      const printFile = orderItem.printFiles[0];
      if (printFile != null)
        printFile.nutCount = count == null ? undefined : count;
    });
  };

  const insertTableText = (
    <Button
      type="link"
      style={{ marginLeft: 5, marginRight: 5, padding: 0 }}
      onClick={() => {
        Modal.info({
          className: 'order-item-more-options-inserts-modal',
          icon: '',
          width: '380px',
          closable: true,
          okButtonProps: { style: { display: 'none' } },
          bodyStyle: { padding: 0 },
          content: <InsertTable />,
        });
      }}
    >
      {i18nFormat('standard inserts')}
    </Button>
  );

  return (
    <span className={cn(className)}>
      <span>
        {i18nFormat('Specify the number of')}
        {insertTableText}
        {i18nFormat('in this part')}
      </span>
      <InputNumber
        size="small"
        min={0}
        style={{ marginLeft: 5 }}
        value={curNutCount}
        onChange={changeNutCount}
      />
    </span>
  );
};
