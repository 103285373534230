import { i18nFormat } from '@unionfab/ufc-shop-commons';
import * as S from '@unionfab/ufc-shop-commons';
import { Button } from 'antd';
import { ButtonType } from 'antd/lib/button/buttonHelpers';

import { useAppNavigate } from '@/features/shared';

export const OrderInvoiceBtn = ({
  order,
  className,
  buttonType = 'link',
}: {
  order: S.ShopOrder;
  className?: string;
  buttonType?: ButtonType;
}) => {
  const nav = useAppNavigate();

  if (!order) return <></>;

  const { code, status } = order;

  let invoiceType = undefined;

  if (['WAIT_CUSTOM_CONFIRM', 'WAIT_PRINT'].includes(status)) {
    invoiceType = 'Proforma';
  }

  if (
    [
      'CUSTOM_CONFIRM',
      'FILE_HANDLE',
      'PRINTING',
      'HANDLE',
      'WAIT_DELIVER',
      'WAIT_RECEIVE',
      'ORDER_COMPLETE',
    ].includes(status)
  ) {
    invoiceType = 'Commercial';
  }

  if (!invoiceType) {
    return <></>;
  }
  return (
    <Button
      type={buttonType}
      className={className}
      onClick={() => nav.navToInvoicePage(code, invoiceType, true)}
    >
      {i18nFormat(`${invoiceType} Invoice`)}
    </Button>
  );
};
