import { Route, Routes } from 'react-router-dom';

import { ReceiveAddressMgt } from './ReceiveAddressMgt';

export const ReceiveAddress = () => {
  return (
    <Routes>
      <Route path="/" element={<ReceiveAddressMgt />} />
    </Routes>
  );
};
