import {
  get,
  getOrderPayOrder,
  i18nFormat,
  OrderPayOrder,
  UnionfabEventTracker,
} from '@unionfab/ufc-shop-commons';
import { useAsyncEffect } from 'ahooks';
import { Spin } from 'antd';
import { useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { toggleUnionfabWpHeaderVisibility } from '@/commons/utils/unionfab-site';
import { useAppNavigate } from '@/features/shared';
import { useInquiryQuoteCheckoutStore } from '@/stores';

import styles from './index.module.less';
import { OrderPaymentCheckoutFields } from './OrderPaymentCheckoutFields';
import { OrderPaymentModal } from './OrderPaymentModal';
import { OrderPaymentSummary } from './OrderPaymentSummary';

export * from './OrderPaymentSuccessPage';

export const OrderPaymentCheckoutPage = () => {
  const {
    isWaitingUserPay,
    paymentResult,
    setPaymentResult,
    loadOrder,
    isLoadingOrder,
    paymentPrice,
  } = useInquiryQuoteCheckoutStore();

  const navigation = useAppNavigate();
  const [searchParams] = useSearchParams();
  const orderCode = searchParams.get('orderCode');

  let order = useInquiryQuoteCheckoutStore().order;
  order = order && order?.code === orderCode ? order : undefined;

  const oceanpaymentRef = useRef();
  const nav = useAppNavigate();

  useEffect(() => {
    const prevVisible = toggleUnionfabWpHeaderVisibility(false);
    return () => {
      toggleUnionfabWpHeaderVisibility(prevVisible);
    };
  }, []);

  useAsyncEffect(async () => {
    if (!orderCode) {
      console.log('No order found, nav to home (from checkout page).');
      navigation.navigateToHome();
    } else {
      await loadOrder(orderCode);
    }
  }, [orderCode]);

  const orderId = get(order, o => o.id);

  const onPayOrder = (payOrder: OrderPayOrder) => {
    if (!payOrder) {
      return;
    }

    if (payOrder.status === 'COMPLETE_PAY') {
      useInquiryQuoteCheckoutStore.setState({
        isWaitingUserPay: false,
        paymentChannel: undefined,
      });

      UnionfabEventTracker.getIns().addUserEventRecord({
        typeName: 'purchase',
        value: paymentPrice.total,
      });

      nav.navigateToPaymentSuccess(orderCode!, orderId);
    } else if (payOrder.status === 'CANCEL_PAY') {
      setPaymentResult({
        success: false,
        failureMessage: i18nFormat(
          'The current pay order seems to be canceled.',
        ),
      });
    } else if (payOrder.status === 'EXCEPTION_PAY') {
      setPaymentResult({
        success: false,
        failureMessage:
          payOrder.statusTriggerData?.data?.paymentDetails ||
          'Payment failed, please try again or contact our customer service for help',
      });
    }
  };

  useAsyncEffect(async () => {
    if (!isWaitingUserPay || !paymentResult) {
      return;
    }

    if (orderId) {
      const payOrder = await getOrderPayOrder(orderId!);
      if (!payOrder) {
        console.warn('pay order not found', orderId!);
      } else {
        onPayOrder(payOrder);
      }
    }
  }, [orderId, paymentResult]);

  // 等待订单支付状态
  useQuery(
    ['payment_result', order?.id],
    () => {
      if (!!orderId) {
        return getOrderPayOrder(orderId!);
      }
    },
    {
      enabled: isWaitingUserPay && !paymentResult,
      refetchInterval: 3000,
      onSuccess: onPayOrder,
    },
  );

  useEffect(() => {
    if (order) {
      switch (order.status) {
        case 'WAIT_SUBMIT':
          console.warn('wait submit, nav to detail page');
          nav.navigateToOrderDetail(order.code);
          break;
        case 'WAIT_REVIEW':
        case 'WAIT_CUSTOM_CONFIRM':
          break;
        default:
          console.warn('wait submit, nav to detail page on ', order.status);
          nav.navigateToOrderDetail(order.code);
      }
    }
  }, [order, nav]);

  return (
    <div className={styles.checkoutContainer}>
      <Spin spinning={isLoadingOrder}>
        <div className={styles.checkoutBody}>
          <OrderPaymentCheckoutFields oceanpaymentRef={oceanpaymentRef} />
          <div className={styles.summaryWrapper}>
            <OrderPaymentSummary oceanpaymentRef={oceanpaymentRef} />
          </div>
        </div>
      </Spin>

      <OrderPaymentModal />
    </div>
  );
};
