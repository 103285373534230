import { throttle } from 'lodash-es';

import * as S from '../../../../schema';
import { getUfcShopComposedApi } from '../../../../singleton-ins';
import { getAllByPage } from '../../common';

/** 获取工艺列表 */
export async function getInquiryMaterialType() {
  const {
    data,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.getUmi<{
    data: S.InquiryMaterialType[];
  }>(`/inquiry/material/type`);

  return (data || []).map(d => new S.InquiryMaterialType(d));
}

export interface GetInquiryMaterialsOptions {
  customerContactPersonId?: S.Id;
  searchText?: string;
  tenantId?: S.Id;
  withCreatorInfo?: boolean;
  withImageUrls?: boolean;
  withMaterialTypeInfo?: boolean;
  withSpuInfo?: boolean;
  materialId?: S.Id[];
}

export const defaultGetInquiryMaterialsOptions = {
  withCreatorInfo: true,
  withImageUrls: true,
  withMaterialTypeInfo: true,
  withSpuInfo: false,
  withAttachmentUrls: true,
};

/**
 * 获取纯粹的材料列表
 */
export async function getInquiryMaterials(
  params: GetInquiryMaterialsOptions = defaultGetInquiryMaterialsOptions,
) {
  return getAllByPage<S.InquiryMaterial>(async ({ pageSize, pageNum }) => {
    const {
      data,
      meta,
    } = await getUfcShopComposedApi().umiRequestCompatibleApi.getUmi<{
      data: S.InquiryMaterial[];
      meta: S.Meta;
    }>(`/order/material`, {
      params: {
        ...defaultGetInquiryMaterialsOptions,
        ...params,
        pageNum,
        pageSize,
        withTags: true,
        withCreatorInfo: true,
        withMaterialTypeInfo: true,
      },
    });

    return { data: (data || []).map(m => new S.InquiryMaterial(m)), meta };
  });
}

export const throttledGetInquiryMaterials = throttle(getInquiryMaterials, 500);

/** 新增材料 */
export async function addOrUpdateInquiryMaterial({
  id,
  color,
  density,
  desc,
  ductilityGrade,
  heatResistantCelsius,
  imageIds,
  attachmentIds,
  materialTypeId,
  name,
  strengthGrade,
  tagIds,
  startingPrice,
  startingPrices,
}: Partial<S.InquiryMaterial>) {
  const data: Partial<S.InquiryMaterial> = {
    color,
    density,
    desc,
    ductilityGrade,
    heatResistantCelsius,
    imageIds,
    attachmentIds,
    materialTypeId,
    name,
    strengthGrade,
    tagIds,
  };

  if (startingPrices) {
    data.startingPrices = startingPrices;
  } else {
    data.startingPrice = startingPrice;
  }

  const { status } = await getUfcShopComposedApi().umiRequestCompatibleApi[
    id ? 'patchUmi' : 'putUmi'
  ]<{
    status: string;
  }>(id ? `/order/material/${id}` : `/order/material`, {
    data,
  });

  return status === 'ok';
}

/** @area 下述为原有的控制询价材料的一系列接口 */

/** 获取快递方式 */
export async function getExpress() {
  const {
    data,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.getUmi<{
    data: S.Express[];
  }>(`/inquiry/express`);

  return data;
}

/** 新增快递方式 */
export async function newExpress(name: string) {
  const {
    status,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.postUmi<{
    status: string;
  }>(`/inquiry/express`, {
    data: { name },
  });

  return status === 'ok';
}

/** 删除快递方式 */
export async function deleteExpress(expressId: S.Id) {
  const {
    status,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.deleteUmi<{
    status: string;
  }>(`/inquiry/express/${expressId}`);

  return status === 'ok';
}

/** 获取付款方式 */
export async function getPaymentMethod() {
  const {
    data,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.getUmi<{
    data: S.PaymentMethod[];
  }>(`/inquiry/pay_style`);

  return data;
}

/** 新增付款方式 */
export async function newPaymentMethod(name: string) {
  const {
    status,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.postUmi<{
    status: string;
  }>(`/inquiry/pay_style`, {
    data: { name },
  });

  return status === 'ok';
}

/** 删除付款方式 */
export async function deletePaymentMethod(id: S.Id) {
  const {
    status,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.deleteUmi<{
    status: string;
  }>(`/inquiry/pay_style/${id}`);

  return status === 'ok';
}
