import { i18nFormat } from '@unionfab/ufc-shop-commons';
import * as S from '@unionfab/ufc-shop-commons';
import { TrackerUtils } from '@unionfab/ufc-shop-commons';
import { Button, Divider, message, Spin, Tooltip } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import cn from 'classnames';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  doLoadAddressList,
  getNavbarUserEntryStore,
  getUseShopQuoteStoreByCode,
  ShopOrderQuoteStoreModifiers,
  useGlobalStore,
} from '../../../../../../stores';
import { useCNCFormStore } from '../ShopQuoteItemsTable/ShopQuoteTableItemAction/useCNCForm';
import styles from './index.module.less';
import { ShopOrderQuotationInfo } from './ShopOrderQuotationInfo';

export interface ShopQuoteEditorSummaryProps {
  className?: string;
  style?: Record<string, string | number>;

  orderCode: string;

  onAnonymousLoginOrRegister?: () => void;
  onAnonymousLoginOrRegisterCancel?: () => void;
}

export const ShopQuoteEditorSummary = ({
  className,
  style,

  orderCode,
  onAnonymousLoginOrRegister,
  onAnonymousLoginOrRegisterCancel,
}: ShopQuoteEditorSummaryProps) => {
  const navigate = useNavigate();
  const [loading, toggleLoading] = useState<boolean>(false);

  const {
    isLoading,
    shopOrder,
    shopOrderQuotation,
  } = getUseShopQuoteStoreByCode(orderCode)(s => ({
    shopOrder: s.shopOrder,
    isLoading: s.isLoadingOrComputing,
    shopOrderQuotation: s.shopOrderQuotation,
  }));

  const { isDemoUser, hasAuthenticated } = useGlobalStore();

  const isRfq = S.get(shopOrderQuotation, q => q.production.status) !== 'OK';
  const {
    form: cncForm,
    appliedRequiredAfterUpdating,
    drawerTrigger,
  } = useCNCFormStore();

  const onSubmit = async () => {
    if (!hasAuthenticated || (hasAuthenticated && isDemoUser)) {
      message.warning(i18nFormat('Please log in or register an account.'));

      const entryStore = getNavbarUserEntryStore();

      const onSignedOrRegistered = () => {
        /** 匿名用户登录后移除登录前创建的 store */
        navigate(
          `/new_shop_quote/fork-order/${TrackerUtils.getUserSessionId()}/${orderCode}`,
        );
      };

      if (entryStore) {
        window.localStorage.setItem('anonymous_order_code', orderCode);

        entryStore.setState({
          entryType: 'Register',
          signInSignUpModalVisible: true,
          onSigned: onSignedOrRegistered,
          onRegistered: onSignedOrRegistered,
          isAnonymousLoginOrRegister: true,
          onSignUpModalClose: onAnonymousLoginOrRegisterCancel,
        });

        typeof onAnonymousLoginOrRegister == 'function' &&
          onAnonymousLoginOrRegister();
      }

      return;
    }

    if (shopOrder.technology === 'CNC') {
      let invalidItemId = null;
      try {
        const itemIds = shopOrder.items.map(it => it.id);
        for (let i = 0; i < itemIds.length; ++i) {
          const itemId = itemIds[i];
          const form = cncForm[itemId];
          invalidItemId = itemId;
          await form.validateFields();
          // 打开 cnc 面板
          if (appliedRequiredAfterUpdating[itemId]) {
            drawerTrigger[itemId](true);
            message.warning(
              'Please apply to upload design and configure parts',
            );
            return;
          }
        }
      } catch (e: any) {
        const errTxt = e?.errorFields?.[0]?.errors?.[0];
        if (errTxt && typeof errTxt === 'string') {
          message.warning(errTxt);
          drawerTrigger[invalidItemId](true);
          return;
        }
      }
    }
    const hasAddress = S.get(shopOrder, o => o.delivery.items, []).length > 0;

    try {
      if (!hasAddress) {
        toggleLoading(true);

        let targetAddress;
        /** 更新订单收货地址 */
        const addressList = await doLoadAddressList();

        if (S.isValidArray(addressList)) {
          const defaultAddress =
            addressList.find(a => a.defaultAddress) || addressList[0];

          targetAddress = S.ShopOrderDeliveryItemRequestParams.fromReceiveAddress(
            defaultAddress,
          );
        }

        ShopOrderQuoteStoreModifiers.updateOrder({
          orderCode,
          onChange: o => {
            if (targetAddress && !hasAddress) {
              o.delivery.items = [targetAddress];
            }
          },
        });

        toggleLoading(false);
      }

      navigate(`/new_shop_quote/${orderCode}/checkout`);
    } catch (e) {
      toggleLoading(false);
      console.log('>>>ShopQuoteEditorSummary>>>onSubmit>>>error', e);
    }
  };

  const continueButton = useMemo(() => {
    const btnProps: ButtonProps = {
      loading,
      block: true,
      type: 'primary',
      onClick: onSubmit,
      className: styles.continueButton,
      children: i18nFormat(isRfq ? 'Continue' : 'Continue To Order'),
    };

    if (S.isValidArray(S.get(shopOrder, o => o.items, []))) {
      return <Button {...btnProps} />;
    }

    return (
      <Tooltip title={i18nFormat('Please upload the model file first')}>
        <Button {...btnProps} disabled={true} />
      </Tooltip>
    );
  }, [shopOrder, loading, appliedRequiredAfterUpdating, drawerTrigger]);

  return (
    <div
      style={style}
      id="ShopQuoteEditorSummary"
      className={cn(className, styles.container)}
    >
      <Spin spinning={isLoading}>
        <ShopOrderQuotationInfo orderCode={orderCode} />
      </Spin>

      <Divider />
      <div className={styles.rfqInfo}>
        {continueButton}
        <div className={styles.rfqDesc}>
          {i18nFormat(
            'Shipping Will Be Recalculated at the Checkout Based On Your Shipping Address.',
          )}
        </div>
      </div>
    </div>
  );
};

ShopQuoteEditorSummary.displayName = 'ShopQuoteEditorSummary';
