import { compact, flatten, uniq } from 'lodash-es';
import { create } from 'zustand';
import { devtools, NamedSet } from 'zustand/middleware';

import {
  getCacheablePreSignedUrl,
  getHandleMethod,
  getTenantIdFromGConfig,
  searchMediaImagesByPostHandleMethodIds,
} from '../../apis';
import { isValidArray } from '../../schema';
import * as S from '../../schema';

export interface InquiryHandleMethodStoreState {
  inquiryHandleMethods: S.HandleMethod[];
  handleMethodPrevMap: Record<string, string[]>;
  materialTypeIdToHandleMethods: Record<string, S.HandleMethod[]>;

  loadInquiryHandleMethods: () => Promise<void>;
  loadHandleMethodPrevImages: (handleMethodId: string) => Promise<void>;
}

export const useInquiryHandleMethod = create<InquiryHandleMethodStoreState>()(
  devtools(
    (set: NamedSet<InquiryHandleMethodStoreState>, get) => {
      const loadInquiryHandleMethods = async () => {
        const { materialTypeIdToHandleMethods } = get();

        const handleMethods = await getHandleMethod(getTenantIdFromGConfig());

        const postHandleMethodIds: string[] = compact(
          handleMethods.map(m => m.id),
        );

        /** 获取后处理预览图文件 */
        const handleMethodMediaImgs = await searchMediaImagesByPostHandleMethodIds(
          postHandleMethodIds,
          getTenantIdFromGConfig(),
        );

        handleMethods.forEach(h => {
          const targetMediaImgs = (handleMethodMediaImgs || []).filter(
            i => i.postHandleMethodId === h.id,
          );
          h.prevFileIds = targetMediaImgs.map(i => i.fileId);
        });

        /** 对后处理进行分组 */
        const materialTypeIds: string[] = uniq(
          flatten((handleMethods || []).map(h => h.materialTypeIds || [])),
        );

        const _materialTypeIdToHandleMethods: Record<
          string,
          S.HandleMethod[]
        > = {};

        for (const materialTypeId of materialTypeIds) {
          if (!materialTypeIdToHandleMethods[materialTypeId]) {
            const targetHandleMethods = handleMethods.filter(h =>
              (h.materialTypeIds || []).includes(materialTypeId),
            );

            _materialTypeIdToHandleMethods[
              materialTypeId
            ] = targetHandleMethods;
          }
        }

        set({
          inquiryHandleMethods: handleMethods,
          materialTypeIdToHandleMethods: {
            ...materialTypeIdToHandleMethods,
            ..._materialTypeIdToHandleMethods,
          },
        });
      };

      const loadHandleMethodPrevImages = async (handleMethodId: string) => {
        const { inquiryHandleMethods, handleMethodPrevMap } = get();

        if (isValidArray(handleMethodPrevMap[handleMethodId])) {
          return;
        }

        const targetHandleMethod = (inquiryHandleMethods || []).find(
          h => h.id === handleMethodId,
        );

        if (!!targetHandleMethod) {
          const { prevFileIds } = targetHandleMethod;

          const urls: string[] = [];

          for (const fileId of prevFileIds) {
            const url = await getCacheablePreSignedUrl(fileId);

            urls.push(url);
          }

          set({
            handleMethodPrevMap: {
              ...handleMethodPrevMap,
              [handleMethodId]: urls,
            },
          });
        }
      };

      return {
        inquiryHandleMethods: [],
        handleMethodPrevMap: {},
        materialTypeIdToHandleMethods: {},

        loadInquiryHandleMethods,
        loadHandleMethodPrevImages,
      };
    },
    { name: 'handleMethodStore' },
  ),
);
