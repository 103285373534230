import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Button, Checkbox, Divider, Empty, Space } from 'antd';
import cn from 'classnames';
import { compact, uniq, uniqBy } from 'lodash';
import { CSSProperties, useMemo, useState } from 'react';

import { Ellipsis } from '@/commons/components/Element/ellipsis';
import { useInquiryMaterialStore } from '@/stores';

import styles from './index.module.less';

export interface ShopQuoteItemMaterialGalleryProps {
  className?: string;
  style?: CSSProperties;

  techCategory: S.TechCategory;
  onChange: (material: S.InquiryMaterial) => void;
}

export const ShopQuoteItemMaterialGallery = ({
  techCategory,
  className,
  style,
  onChange,
}: ShopQuoteItemMaterialGalleryProps) => {
  const { techTypeToMaterials } = useInquiryMaterialStore();
  const [selectedMaterialTypeIds, selectMaterialTypeIds] = useState<string[]>(
    [],
  );
  const [selectedMaterialGroups, selectMaterialGroups] = useState<string[]>([]);

  const materials = S.get(
    techTypeToMaterials,
    t => t[techCategory.type],
  ) as S.InquiryMaterial[];

  /** 材料类型筛选 */
  const materialTypeSeletor = useMemo(() => {
    const materialTypes = uniqBy(
      compact((materials || []).map(m => m.id && m.materialType)),
      i => i.id,
    );

    if (!S.isValidArray(materialTypes)) return <></>;

    return (
      <Checkbox.Group
        value={selectedMaterialTypeIds}
        onChange={(ids: string[]) => selectMaterialTypeIds(ids)}
      >
        <Space direction="vertical">
          {materialTypes.map(i => (
            <Checkbox key={i.id} value={i.id}>
              <Ellipsis maxWidth={120}>{i.name}</Ellipsis>
            </Checkbox>
          ))}
        </Space>
      </Checkbox.Group>
    );
  }, [materials, selectedMaterialTypeIds]);
  /** 材料分组筛选 */
  const materialGroupSeletor = useMemo(() => {
    const materialGroups = uniq(
      compact((materials || []).map(m => m.materialGroup)),
    );

    if (!S.isValidArray(materialGroups)) return <></>;

    return (
      <Checkbox.Group
        value={selectedMaterialGroups}
        onChange={(i: string[]) => selectMaterialGroups(i)}
      >
        <Space direction="vertical">
          {materialGroups.map(i => (
            <Checkbox key={i} value={i}>
              <Ellipsis maxWidth={120}>{i}</Ellipsis>
            </Checkbox>
          ))}
        </Space>
      </Checkbox.Group>
    );
  }, [materials, selectedMaterialGroups]);
  /** 筛选过后的材料 */
  const filteredMaterials = useMemo(() => {
    if (!S.isValidArray(materials)) return [];

    return materials.filter(m => {
      let resp = false;
      if (S.isValidArray(selectedMaterialTypeIds)) {
        resp = selectedMaterialTypeIds.includes(m.materialTypeId);
      }
      if (S.isValidArray(selectedMaterialGroups)) {
        resp = selectedMaterialGroups.includes(m.materialGroup);
      }

      return resp;
    });
  }, [materials, selectedMaterialGroups, selectedMaterialTypeIds]);

  const filteredMaterialsList = useMemo(() => {
    if (!S.isValidArray(filteredMaterials)) return <Empty />;

    return filteredMaterials.map(m => (
      <div key={m.id} className={styles.materialsCard}>
        {m.name}
        <Space direction="vertical">
          <Button className={styles.btn}>{i18nFormat('See more')}</Button>
          <Button
            type="primary"
            className={styles.btn}
            onClick={() => onChange(m)}
          >
            {i18nFormat('Select')}
          </Button>
        </Space>
      </div>
    ));
  }, [filteredMaterials]);

  return (
    <div
      style={style}
      id="ShopQuoteItemMaterialGallery"
      className={cn(className, styles.container)}
    >
      <div className={styles.materialsFilter}>
        {materialGroupSeletor}
        <Divider style={{ margin: '1rem 0' }} />
        {materialTypeSeletor}
      </div>
      <div
        className={cn(
          styles.materialsWrapper,
          !S.isValidArray(filteredMaterialsList) && styles.empty,
        )}
      >
        {filteredMaterialsList}
      </div>
    </div>
  );
};

ShopQuoteItemMaterialGallery.displayName = 'ShopQuoteItemMaterialGallery';
