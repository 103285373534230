import * as S from '../../../schema';
import {
  getTenantIdFromGConfig,
  getUfcShopComposedApi,
} from '../../../singleton-ins';

/** 更新 Person 信息 */
export async function updatePersonInfo(
  personId: S.Id,
  {
    username,
    email,
    nickname,
    password,
    phoneNumber,
    englishName,
    avatarFileId,
  }: {
    username?: string;
    email?: string;
    nickname?: string;
    englishName?: string;
    password?: string;
    phoneNumber?: string;
    avatarFileId?: S.Id;
  },
) {
  const {
    status,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.postUmi<{
    status: string;
  }>(`/person/${personId}`, {
    data: {
      username,
      email,
      nickname,
      englishName,
      password,
      phoneNumber,
      avatarFileId,
    },
  });

  return status === 'ok';
}

/** 邀请已注册用户 */
export async function inviteAccount({
  phoneNumber,
  roleIds,
  username,
}: {
  roleIds: string[];
  username?: string;
  phoneNumber?: string;
}) {
  const {
    status,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.postUmi<{
    status: string;
  }>(`/person/invite`, {
    data: {
      phoneNumber: !!phoneNumber ? phoneNumber : undefined,
      roleIds,
      username: !!username ? username : undefined,
    },
  });

  return status === 'ok';
}

/** 查询 PersonInfo */
export async function getCurrentUserPersonInfoByParam(
  phoneNumber: string,
  username: string,
) {
  const {
    data,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.getUmi<{
    data: S.Person;
  }>(``, {
    params: {
      phoneNumber,
      username,
    },
  });

  return new S.Person(data);
}

/** 根据当前登录的person身份，获取相关个人客户信息 */
export async function getInquiryCustomerByPerson(
  tenantId: S.Id,
  params: {
    withCustomerReceiveAddress?: boolean;
    withQuotationGroups?: boolean;
    withQuotationGroupIds?: boolean;
  } = {
    withCustomerReceiveAddress: true,
    withQuotationGroups: true,
    withQuotationGroupIds: true,
  },
) {
  const {
    data,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.getUmi<{
    data: S.InquiryCustomer;
  }>(`/person/order/customer`, {
    params: { tenantId, ...params },
  });

  return data;
}

/** 根据当前登录的 Person 身份，更新相关个人客户信息 */
export async function updateInquiryCustomerByPerson(
  tenantId: S.Id,
  inquiryCustomer: S.InquiryCustomer,
) {
  const {
    status,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.patchUmi<{
    status: string;
  }>(`/person/order/customer`, {
    params: {
      tenantId,
    },
    data: {
      attr: inquiryCustomer.attr || {},
      address: inquiryCustomer.address || '',
      receiveAddress: inquiryCustomer.receiveAddress || [],
    },
  });

  return status === 'ok';
}

/** 获取用户的销售代表信息 */
export async function getMaintainerUserInfo(): Promise<{
  email: string;
  phoneNumber: string;
  nickname?: string;
}> {
  const tenantId = getTenantIdFromGConfig();

  const params = {
    tenantId,
    pageNum: 0,
    pageSize: 10,
    asc: true,
    withCustomerReceiveAddress: false,
  };

  const {
    data,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.getUmi<{
    data: S.InquiryCustomer[];
  }>(`/person/order/customers`, {
    params,
  });

  const defaultEmail = 'hello@unionfab.com';
  const defaultPhoneNumber = '+86 182 2160 3447';

  if ((data || []).length > 0) {
    const maintainerUser = (data[0].maintainerUser as unknown) as S.Person;

    if (!!maintainerUser) {
      return {
        nickname: maintainerUser.nickname || '',
        email: maintainerUser.email || defaultEmail,
        phoneNumber: maintainerUser.phoneNumber || defaultPhoneNumber,
      };
    }

    return { email: defaultEmail, phoneNumber: defaultPhoneNumber };
  }

  return { email: defaultEmail, phoneNumber: defaultPhoneNumber };
}
