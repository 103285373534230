import { InfoCircleOutlined } from '@ant-design/icons';
import { i18nFormat, PaymentChannel } from '@unionfab/ufc-shop-commons';
import { Popover } from 'antd';

import styles from './index.module.less';

const tips: Record<PaymentChannel, JSX.Element> = {
  paypal: <span>{i18nFormat('You can bind credit card on your paypal.')}</span>,
  oceanpay: (
    <span>
      {i18nFormat(
        'You can pay with credit card: Visa, Master Card, American Express, JCB, Diners Club, Discover.',
      )}
      <span style={{ color: 'red' }}>
        {i18nFormat(
          '3D Secure will be requested when the amount is over $2000.',
        )}
      </span>
    </span>
  ),
  'bank-transfer': (
    <span>
      {i18nFormat(
        'To pay by wire transfer using your bank account, you need to visit your nearest bank branch with the wire transfer information.',
      )}
    </span>
  ),
};

export const QuotePaymentTips = ({
  paymentChannel,
}: {
  paymentChannel: PaymentChannel;
}) => {
  const tip = tips[paymentChannel];

  if (!tip) {
    return <></>;
  } else {
    return (
      <Popover placement="topLeft" content={tip}>
        <InfoCircleOutlined className={styles.paymentTip} />
      </Popover>
    );
  }
};
