import { Row } from 'antd';
import { useState } from 'react';

import { useDoRegisterAndContinue } from '@/stores';

import { openLoginPage } from '../../../../../../../../shared';
import { GoOrderCheckoutBtn } from '../../../GoOrderCheckoutBtn';
import styles from './index.module.less';

export const OrderSubmitActions = () => {
  const [signUpState, setSignUpState] = useState<
    { onSignUpSuccess: () => void } | undefined
  >();

  useDoRegisterAndContinue(openLoginPage, signUpState?.onSignUpSuccess);

  return (
    <div>
      <Row gutter={16}>
        <GoOrderCheckoutBtn
          className={styles.orderSubmitorBtn}
          style={{ flex: '1 1 1px' }}
          signUp={onSignUpSuccess => setSignUpState({ onSignUpSuccess })}
        />
      </Row>
    </div>
  );
};
