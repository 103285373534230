import { devtools, subscribeWithSelector } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import { getModelFileMapByIdsWithCache, updateFileAttr } from '../../../apis';
import * as S from '../../../schema';

type StoreState = Record<string, S.D3ModelFile>;

export const useD3ModelStoreByIds = (fileIds: string[]) =>
  useD3ModelStore(state => {
    return fileIds.reduce((prev, cur) => {
      prev[cur] = state[cur];
      return prev;
    }, {});
  });

export const useD3ModelStore = createWithEqualityFn<StoreState>()(
  subscribeWithSelector(devtools(() => ({}), { name: 'useD3ModelStore' })),
  // 这里的 shallow 用于 selector 结果比较
  shallow,
);

/** 加载某些模型信息 */
export async function loadD3ModelFileByIdsInStore(
  fileIds: string[],
  {
    forceRefresh = false,
  }: {
    // 如果是 forceRefresh，则强制刷新
    forceRefresh?: boolean;
  } = {},
) {
  // 这里判断是抓取全部，还是仅抓取不存在的
  let toFetchFileIds = fileIds;

  const currentModelFileMap = useD3ModelStore.getState();

  if (!forceRefresh) {
    toFetchFileIds = fileIds.filter(fileId => !currentModelFileMap[fileId]);
  }

  // 这里获取模型数据的时候，使用的是缓存接口；仅 ShopD3ModelProcessManager 中会使用非缓存接口获取模型数据
  const modelFileMap = await getModelFileMapByIdsWithCache({
    fileIds: toFetchFileIds,
    useCache: !forceRefresh,
  });

  // TODO: 判断如果文件没解析完毕，则进行监听

  useD3ModelStore.setState({ ...(modelFileMap || {}) });
}

export async function updateThumbnailInStoreAndSyncToServer({
  modelFileId,
  thumbnailFileId,
  thumbnailFileUrl,
}: {
  modelFileId: string;
  thumbnailFileId: S.Id;
  thumbnailFileUrl: string;
}) {
  if (!thumbnailFileId || !thumbnailFileUrl) {
    return;
  }

  // 更改模型图片
  await updateFileAttr(modelFileId, {
    thumbnailFileId: thumbnailFileId,
    thumbnail: thumbnailFileUrl,
  });

  // 强制刷新数据
  loadD3ModelFileByIdsInStore([modelFileId], { forceRefresh: true });
}
