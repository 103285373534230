import { i18nFormat } from '../../../i18n';
import { BaseEntity, CurrencySymbolType, Id, TimeUnit } from '../../common';
import { formatDate, get, isValidArray, replaceAll } from '../../utils';
import { DeliverLeadTime } from '../inquiry-order/quotation';
import { InquiryMaterial } from './InquiryMaterial';
import { InquiryMaterialType } from './InquiryMaterialType';

export type DeprecatedInquiryMaterialType = InquiryMaterialType;
export interface SpuLeadTimeAmount {
  /** 发货周期管理列表中的ID 可选 */
  id?: Id;

  amount: number;
  desc: string;
  onlyOversea?: boolean;
}

export const SpuLeadTimeAmounts: SpuLeadTimeAmount[] = [
  { amount: 24, desc: '24 hours' },
  { amount: 48, desc: '2 days' },
  { amount: 72, desc: '3 days' },
  { amount: 96, desc: '3-7 days' },
  { amount: 168, desc: 'a week', onlyOversea: true },
];

export interface InquiryMaterialSpuPrice {
  pricePerGram: {
    amount: number;
    currency: CurrencySymbolType;
  };
  startingPrice: {
    amount: number;
    currency: CurrencySymbolType;
  };
}

export type DeprecatedInquiryMaterialSpu = InquiryMaterialSku;

export type InquiryMaterialSpuStatus = 'EDITING' | 'ONLINE' | 'OFFLINE';

export class InquiryMaterialSku extends BaseEntity<InquiryMaterialSku> {
  // 当前 SPU 从哪个 SPU fork 而来
  parentId: Id;
  status: InquiryMaterialSpuStatus;
  /** 是否为最新可用的 Spu */
  /** @deprecated */
  latest: boolean;

  name: string;
  /** @deprecated */
  color: string;

  imageId: Id;
  imageIds: Id[];

  /** 描述 */
  desc: string;

  get materialTypeId() {
    if (this.materialSnapshot) {
      return this.materialSnapshot.materialTypeId;
    }

    return '-';
  }

  materialId: Id;
  /** @deprecated */
  inquiryMaterialId: Id;

  material: InquiryMaterial;

  materialSnapshot: InquiryMaterial;
  /** 获取关联的材料名称 */
  get materialNameStr() {
    let rawName = this.materialSnapshot.name || '-';

    const invalidSuffix = ['-24', '-48', '-72', '-96', '外协', '【】'];

    for (const s of invalidSuffix) {
      rawName = replaceAll(rawName, s, '');
    }

    return rawName;
  }
  /** 密度描述 */
  get densityStr(): string {
    return `${get(this, t => t.materialSnapshot.density)} g/cm³`;
  }

  /** 交付时间 */
  leadTime: {
    type: 'exact';
    amount: number;
    unit: TimeUnit;
  };

  /** 交付周期 ID */
  deliverLeadTimeId?: Id;
  /** 交付周期 */
  deliverLeadTime?: DeliverLeadTime;

  /** 交付时间描述  */
  get leadTimeStrFromHours() {
    if (!this.leadTime) return '-';

    const { amount = 0, unit = '' } = this.leadTime;

    if (unit == 'HOURS') {
      const spuLeadTimeAmount = SpuLeadTimeAmounts.find(
        s => s.amount === amount,
      );
      if (spuLeadTimeAmount && spuLeadTimeAmount.desc) {
        return i18nFormat(spuLeadTimeAmount.desc);
      }
      // 兼容其他发货时间
      if (amount === 192) return i18nFormat('a week');

      if (amount === 96) return i18nFormat(`3-7 days`);

      if (amount === 48) return i18nFormat(`2-3 days`);
    }

    if (unit == 'DAYS') return `${amount} ${i18nFormat('business days')}`;

    return `${amount} ${unit.toLocaleLowerCase()}`;
  }

  spuPrice: InquiryMaterialSpuPrice; // 包含币种的价格
  get price() {
    return Number(this.spuPrice.pricePerGram.amount);
  }
  get startingPrice() {
    return Number(this.spuPrice.startingPrice.amount);
  }
  pricingFactor: number;

  /** 服务端返回的报价组信息 */
  quotationGroupIds: Id[];
  /** 客户端兼容使用的报价组信息，也可以表示当前上下文中应该使用的报价组信息 */
  quotationGroupIdVO: Id;

  currency: CurrencySymbolType;

  get spuCategoryNameStr() {
    const { material, materialSnapshot } = this;

    if (material.materialType.categoryName) {
      return material.materialType.categoryName;
    }

    if (materialSnapshot.materialType.categoryName) {
      return materialSnapshot.materialType.categoryName;
    }

    return '-';
  }

  constructor(props?: Partial<InquiryMaterialSku>) {
    super(props);

    delete (props as any).delivery;
    delete (props as any).materialTypeId;
    delete (props as any).price;
    delete (props as any).startingPrice;

    Object.assign(this, props);

    if (this.materialSnapshot) {
      this.materialSnapshot = new InquiryMaterial(this.materialSnapshot || {});
    }

    if (this.deliverLeadTime) {
      this.deliverLeadTime = new DeliverLeadTime(this.deliverLeadTime || {});
    }

    if (!this.leadTime) {
      this.leadTime = {
        amount: 96,
        unit: 'HOURS',
        type: 'exact',
      };
    }

    // 兼容旧有属性
    const materialId = this.materialId || this.inquiryMaterialId;

    this.materialId = materialId;

    this.inquiryMaterialId = materialId;

    if (isValidArray(this.quotationGroupIds) && !this.quotationGroupIdVO) {
      this.quotationGroupIdVO = this.quotationGroupIds[0];
    }

    try {
      // 初始化数据
      if (!this.spuPrice) {
        this.spuPrice = {
          pricePerGram: { amount: 0, currency: 'CNY' },
          startingPrice: { amount: 0, currency: 'CNY' },
        };
      }

      if (typeof this.spuPrice.pricePerGram.amount === 'string') {
        this.spuPrice.pricePerGram.amount = Number.parseFloat(
          this.spuPrice.pricePerGram.amount,
        );
      }

      if (typeof this.spuPrice.startingPrice.amount === 'string') {
        this.spuPrice.startingPrice.amount = Number.parseFloat(
          this.spuPrice.startingPrice.amount,
        );
      }
    } catch (_) {
      console.error(
        '>>>schema>>>InquiryMaterial>>>constructor>>>formatPrice',
        _,
      );
    }
  }
}
