import { i18nFormat } from '../../../../i18n';
import { get } from '../../../utils';
import { InquiryCoupon } from './InquiryCoupon';

export class InquiryCouponValidator {
  /** 判断某个 Coupon 是否可用 */
  static validateCouponAndReturnErrorMsg(
    coupon: InquiryCoupon,
    {
      price,
      ratioFromUsdToOrderCurrency,
      countryList,
      isOnlySelectMetal,
      isOnlySelectSla,
    }: {
      ratioFromUsdToOrderCurrency: number;
      price: {
        subtotal: number;
        discount: number;
        shipping?: number;
        total?: number;
      };
      countryList: string[];
      isOnlySelectMetal?: boolean;
      isOnlySelectSla?: boolean;
    },
  ): string | undefined {
    if (!coupon.code) {
      return i18nFormat('The coupon code is incorrect.');
    }

    if (coupon.status === 'USED') {
      return i18nFormat('The coupon code has been used.');
    }

    if (coupon.status === 'EXPIRED') {
      return 'The coupon code is expired.';
    }

    if (!price) {
      return 'Order price is not available';
    }

    if (
      coupon.type === 'AMOUNT_OFF' &&
      coupon.amountOffWithAmount > price.subtotal
    ) {
      return `This order's price is less than $ ${coupon.amountOffWithAmount}.`;
    }

    if (ratioFromUsdToOrderCurrency == null) {
      return 'Ratio is not loaded';
    }

    const usdSubtotal = price.subtotal / ratioFromUsdToOrderCurrency;

    if (!!coupon.maxUseBound && usdSubtotal > coupon.maxUseBound) {
      return `Coupons don't meet usage requirement: usdSubtotal {0} exceed maxUseBound {1}.`
        .replace('{0}', `${usdSubtotal}`)
        .replace('{1}', `${coupon.maxUseBound}`);
    }

    if (coupon.type === 'FREE_PRINTING') {
      if (usdSubtotal > 50) {
        return `Coupons don't meet usage requirement: usdSubtotal exceed 50.`;
      }

      if (price.shipping == null) {
        return `Choose ship option and get ship quotation first.`;
      }
    }

    if (coupon.type === 'FREE_PRINTING_FOR_REFERRAL') {
      if (usdSubtotal > 20) {
        return `Coupons don't meet usage requirement: usdSubtotal > 20.`;
      }
      if (price.shipping == null) {
        return `Choose ship option and get ship quotation first.`;
      }
    }

    if (coupon.type === 'FREE_PRINTING_METAL') {
      if (!isOnlySelectMetal) {
        return `Coupons don't meet usage requirement: only support Metal.`;
      }

      if (price.shipping == null) {
        return `Choose ship option and get ship quotation first.`;
      }
    }

    if (coupon.type === 'PERCENT_OFF') {
      if (price.shipping == null) {
        return `Choose ship option and get ship quotation first.`;
      }

      if (
        get(coupon, () => coupon.parsedRuleVO.technology.includes('SLA')) &&
        !isOnlySelectSla
      ) {
        return `Coupons don't meet usage requirement: only support SLA.`;
      }
    }

    if (coupon.type === 'FREE_PRINTING_FOR_KIRI') {
      if (usdSubtotal > 50) {
        return `Coupons don't meet usage requirement: usdSubtotal exceed 50.`;
      }

      if (countryList.includes('China')) {
        return 'Overseas customers only (Excluding China)';
      }

      if (price.shipping == null) {
        return `Choose ship option and get ship quotation first.`;
      }
    }

    return undefined;
  }
}
