import * as S from '../../../../schema';
import {
  getTenantIdFromGConfig,
  getUfcShopComposedApi,
} from '../../../../singleton-ins';
import { getAllByPage } from '../../common';
import { GetInquiryMaterialsOptions } from './inquiry_material';

const defaultOptions: S.PageableGetParams = {
  pageNum: 0,
  pageSize: 100,
};

/** 获取全局全部的 MaterialSpus */
export async function getInquiryMaterialSpus(
  options: S.PageableGetParams = defaultOptions,
) {
  const { data } =
    (await getUfcShopComposedApi().umiRequestCompatibleApi.getUmi<{
      data: S.InquiryMaterialSku[];
      meta: S.Meta;
    }>(`/order/material_spu`, {
      params: {
        ...defaultOptions,
        tenantId: getTenantIdFromGConfig(),
        ...options,
      },
    })) || {};

  return (data || []).map(m => new S.InquiryMaterialSku(m));
}

/** 这里加上 Spu 缓存 */

const spuCache = {};

/** 批量查询 Spu */
export async function getInquiryMaterialSpusByIds(spuIds: S.Id[]) {
  const cachedSpuList: S.InquiryMaterialSku[] = [];
  const uncachedSpuIds: S.Id[] = [];

  for (const id of spuIds) {
    if (spuCache[id]) {
      cachedSpuList.push(spuCache[id]);
    } else {
      uncachedSpuIds.push(id);
    }
  }

  let fetchedSpuList: S.InquiryMaterialSku[] = [];

  if (S.isValidArray(uncachedSpuIds)) {
    const {
      data,
    } = await getUfcShopComposedApi().umiRequestCompatibleApi.postUmi<{
      data: S.InquiryMaterialSku[];
      meta: S.Meta;
    }>(`/order/material_spu/list`, {
      data: {
        materialSpuIds: uncachedSpuIds,
        tenantId: getTenantIdFromGConfig(),
      },
    });

    fetchedSpuList = (data || []).map(m => new S.InquiryMaterialSku(m));
  }

  // 添加到缓存中
  const spuList = [...fetchedSpuList, ...cachedSpuList];

  spuList.forEach(s => {
    spuCache[s.id] = s;
  });

  return spuList;
}

/** 根据报价组 Spu 列表获取用于报价的材料列表 */
export async function getInquiryMaterialsByQuotationGroup({
  groupId,
  params,
}: {
  groupId: S.Id;
  params?: GetInquiryMaterialsOptions;
}) {
  // 如果没有 quotationGroupId，返回空
  if (!groupId) {
    return [];
  }

  const spuList = await getInquiryMaterialSpusByQuotationGroupId(
    groupId,
    {
      tenantId: getTenantIdFromGConfig(),
      ...params,
    },
    // 用于报价的仅限于上架的 Spu
    ['ONLINE'],
  );

  const materialIdSet = new Set(
    spuList.map(s => {
      // 顺道补齐 quotationGroupId
      s.quotationGroupIdVO = groupId;

      return s.materialId;
    }),
  );

  // const materials = await getInquiryMaterials(params, runOptions);
  const materials = await getUfcShopComposedApi().shopMaterialQueryApi.queryShopMaterials(
    { groupId },
  );

  const filteredMaterials = materials.filter(m => materialIdSet.has(m.id));

  const inquiryMaterials = S.groupSpusByInquiryMaterial(
    spuList,
    filteredMaterials,
  );

  return inquiryMaterials;
}

/** 获取某个报价组的 Spu 列表 */
export async function getInquiryMaterialSpusByQuotationGroupId(
  groupId: S.Id,
  params: S.PageableGetParams = defaultOptions,
  status?: S.InquiryMaterialSpuStatus[],
) {
  if (!groupId) {
    return [];
  }

  return getAllByPage<S.InquiryMaterialSku>(async ({ pageSize, pageNum }) => {
    const {
      data,
      meta,
    } = await getUfcShopComposedApi().umiRequestCompatibleApi.getUmi<{
      data: S.InquiryMaterialSku[];
      meta: S.Meta;
    }>(
      // 这里未分组的 Spu 是独立获取
      groupId === ('ungrouped_material_spu' as S.Id)
        ? `/order/quotation_group/${groupId}`
        : `/order/quotation_group/${groupId}/material_spu`,
      {
        params: {
          tenantId: getTenantIdFromGConfig(),
          status,
          pageNum,
          pageSize,
        },
      },
    );
    return { data: (data || []).map(m => new S.InquiryMaterialSku(m)), meta };
  }, params);
}
