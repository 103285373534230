import { isNil, omitBy, trim } from 'lodash-es';

import { isDev } from '../../../skeleton/env/dev';
import { BaseEntity } from '../../common';
import { ReceiveAddress } from '../../crm';
import { get } from '../../utils';
import { CreateOceanpaymentPayOrderParams } from './Payment';

export class OceanpaymentBilling extends BaseEntity<OceanpaymentBilling> {
  lastName!: string;
  firstName!: string;
  email!: string;
  country!: string;
  countryIso2!: string;
  state!: string;
  stateCode!: string;
  city!: string;
  address!: string;
  zip!: string;
  ip!: string;
  phone!: string;

  constructor(props: Partial<OceanpaymentBilling> = {}) {
    super(props);

    Object.assign(this, props);

    if (!this.ip) {
      this.ip = '0.0.0.0';
    }
  }
}

export class OceanpaymentShip extends BaseEntity<OceanpaymentShip> {
  lastName!: string;
  firstName!: string;
  email!: string;
  phone!: string;
  country!: string;
  countryIso2!: string;
  state!: string;
  stateCode!: string;
  city!: string;
  addr!: string;
  zip!: string;

  constructor(props: Partial<OceanpaymentShip>) {
    super(props);

    Object.assign(this, props);

    if (!this.zip) {
      this.zip = 'N/A';
    }
  }
}

export class OceanpaymentInfo extends BaseEntity<OceanpaymentInfo> {
  description!: string;
  /** 金额 */
  amount!: string;
  /** 交易币种 */
  currencyCode!: string;
  /** 返回地址 */
  backUrl!: string;
  /** 服务器回调URL地址 */
  noticeUrl!: string;
  /** 支付方式 */
  methods!: string;
  /** 帐户号 */
  account!: string;
  /** 终端号 */
  terminal!: string;
  /** Key-公钥 */
  publicKey!: string;
  /** 钱海订单号 */
  orderNumber!: string;

  /** Unionfab 支付订单 ID */
  payOrderId!: string;

  signValue!: string;

  /** 产品名称 */
  productName!: string;
  /** 账单信息 */
  billing!: OceanpaymentBilling;
  /** 发货信息 */
  ship!: OceanpaymentShip;

  product!: {
    name: string;
    num: string;
    sku: string;
  };

  constructor(props: Partial<OceanpaymentInfo> = {}) {
    super(props);

    Object.assign(this, props);

    this.billing = new OceanpaymentBilling(this.billing);
    if (this.ship) {
      this.ship = new OceanpaymentShip(this.ship);
    }

    if (!this.methods) {
      this.methods = 'Credit Card';
    }
  }
}

export const transformOceanpaymentInfo = (data: Partial<OceanpaymentInfo>) => {
  return omitBy(
    {
      key: get(data, d => d.publicKey),
      terminal: get(data, d => d.terminal),
      signValue: get(data, d => d.signValue),
      account: get(data, d => d.account),
      backUrl: get(data, d => d.backUrl, ''),
      noticeUrl: get(data, d => d.noticeUrl, ''),

      billing_firstName: trim(get(data, d => d.billing.firstName, 'N/A')),
      billing_lastName: trim(get(data, d => d.billing.lastName, 'N/A')),
      billing_email: get(data, d => d.billing.email, 'N/A'),
      billing_phone: get(data, d => d.billing.phone, 'N/A'),
      billing_country: get(
        data,
        d => d.billing.countryIso2 || d.billing.country,
        'N/A',
      ),
      billing_state: get(
        data,
        d => d.billing.stateCode || d.billing.state,
        'N/A',
      ),
      billing_city: get(data, d => d.billing.city, 'N/A'),
      billing_address: get(data, d => d.billing.address, 'N/A'),
      billing_zip: get(data, d => d.billing.zip, 'N/A'),
      billing_ip: get(data, d => d.billing.ip, 'N/A'),

      ship_firstName: get(data, d => d.ship.firstName, 'N/A'),
      ship_lastName: get(data, d => d.ship.lastName, 'N/A'),
      ship_email: get(data, d => d.ship.email, 'N/A'),
      ship_phone: get(data, d => d.ship.phone, 'N/A'),
      ship_country: get(data, d => d.ship.countryIso2 || d.ship.country, 'N/A'),
      ship_state: get(data, d => d.ship.stateCode || d.ship.state, 'N/A'),
      ship_city: get(data, d => d.ship.city, 'N/A'),
      ship_addr: get(data, d => d.ship.addr, 'N/A'),
      ship_zip: get(data, d => d.ship.zip, 'N/A'),

      productSku: get(data, d => d.product.sku, 'N/A'),
      productNum: get(data, d => d.product.num, 'N/A'),
      productName: get(data, d => d.product.name, 'N/A'),

      methods: get(data, d => d.methods),
      order_amount: get(data, d => d.amount),
      order_currency: get(data, d => d.currencyCode),
      order_number: get(data, d => d.orderNumber),
    },
    isNil,
  );
};

export const getCreateOceanpaymentPayOrderOptions = ({
  billingAddress,
  billingEmail,
  ...options
}: {
  billingEmail: string;
  billingAddress: ReceiveAddress;
  methods: 'Credit Card';
  backUrl?: string;
  couponCode?: string;
  description?: string;
}): CreateOceanpaymentPayOrderParams => {
  return omitBy(
    {
      ...options,
      type: 'OCEAN_PAY',
      ship: {
        email: billingEmail,
        firstName: billingAddress.firstName,
        lastName: billingAddress.lastName,
        phone: billingAddress.phoneNumber,
        country: billingAddress.country,
        state: billingAddress.province,
        city: billingAddress.city,
        addr: billingAddress.street,
        zip: billingAddress.postcode,
      },
      billing: {
        ip: '0.0.0.0',
        email: billingEmail,
        firstName: billingAddress.firstName,
        lastName: billingAddress.firstName,
        country: billingAddress.country,
        state: billingAddress.province,
        city: billingAddress.city,
        zip: billingAddress.postcode,
        address: billingAddress.street,
        phone: billingAddress.phoneNumber,
      },
    },
    isNil,
  ) as CreateOceanpaymentPayOrderParams;
};

export type OceanpayCbMessage =
  | {
      type: 'informationInvalid';
      data: {
        code: string;
        msg: string;
      };
    }
  | {
      type: 'paymentRes';
      data: {
        rawXmlMsg: string;
        paymentStatus: string;
        paymentAuthType: string;
        detailMessage: string;
        orderNumber: string;
        paymentSolution: string;
        payUrl?: string;
      };
    };

export function parseOceanpayCbEvent(
  e: MessageEvent,
): OceanpayCbMessage | undefined {
  if (typeof e.data === 'string') {
    const parser = new DOMParser();

    const xmlDom = parser.parseFromString(e.data, 'text/xml');
    const paymentStatusCollection = xmlDom.getElementsByTagName(
      'payment_status',
    );
    const paymentAuthTypeCollection = xmlDom.getElementsByTagName(
      'payment_authType',
    );
    const detailMessageCollection = xmlDom.getElementsByTagName(
      'payment_details',
    );
    const paymentSolutionsCollection = xmlDom.getElementsByTagName(
      'payment_solutions',
    );
    const orderNumberCollection = xmlDom.getElementsByTagName('order_number');
    const payUrlCollection = xmlDom.getElementsByTagName('pay_url');
    if (
      paymentStatusCollection.length === 0 ||
      paymentAuthTypeCollection.length === 0 ||
      detailMessageCollection.length === 0 ||
      orderNumberCollection.length === 0
    ) {
      console.debug('illegal ocean pay cb', e);
      return;
    }

    let payUrl: string | undefined;
    if (payUrlCollection.length !== 0) {
      payUrl = payUrlCollection[0].innerHTML.trim();
      if (payUrl.length === 0) {
        payUrl = undefined;
      }
    }

    return {
      type: 'paymentRes',
      data: {
        rawXmlMsg: e.data,
        paymentStatus: paymentStatusCollection[0].innerHTML.trim(),
        paymentAuthType: paymentAuthTypeCollection[0].innerHTML.trim(),
        detailMessage: detailMessageCollection[0].innerHTML.trim(),
        paymentSolution: (
          paymentSolutionsCollection[0]?.innerHTML || ''
        ).trim(),
        orderNumber: orderNumberCollection[0].innerHTML.trim(),
        payUrl,
      },
    };
  } else if (typeof e.data === 'object') {
    if (e.data.code === -1) {
      if (!e.data.msg) {
        e.data.msg = 'Please check you card info';
      }
      return {
        type: 'informationInvalid',
        data: e.data,
      };
    }
  }
  return;
}

export const getOceanpaymentEndpoint = () => {
  return isDev()
    ? 'https://test-secure.oceanpayment.com'
    : 'https://secure.oceanpayment.com/gateway/service/pay';
};

export const getOceanpaymentCheckoutPageUrl = () => {
  return isDev()
    ? 'https://test-secure.oceanpayment.com/gateway/direct/checkpage'
    : 'https://secure.oceanpayment.com/gateway/direct/checkpage';
};

export const OceanpaymentPageCssUrl =
  'https://ufc-oversea.oss-accelerate.aliyuncs.com/oceanpayment.css';

/**
 * 处理给定支付订单的支付结果消息
 *
 * @param endpoint 处理来自该源地址的消息
 */
export function createOceanpayPayResCb(
  onPayUrl: (
    payUrl?: string,
    state?: { detailMessage: string; solution: string },
  ) => void,
  onSuccess: (orderNumber: string, msg: string) => Promise<void>,
  onPayFailed: (msg: string) => void,
) {
  return async (e: MessageEvent) => {
    if (!e || !(e.origin || '').includes('oceanpayment.com')) {
      console.log('callback ignored: ', e.origin, getOceanpaymentEndpoint(), e);
      return;
    }

    const msg = parseOceanpayCbEvent(e);
    console.log('recv payment callback', e, msg);

    if (!msg || !msg.type) return;

    if (msg.type == 'paymentRes') {
      if (msg.data.payUrl != null && msg.data.payUrl.trim() !== '') {
        onPayUrl(msg.data.payUrl, {
          detailMessage: msg.data.detailMessage,
          solution: msg.data.paymentSolution,
        });
      } else {
        switch (msg.data.paymentStatus) {
          case '1':
            onSuccess(msg.data.orderNumber, msg.data.rawXmlMsg);
            break;
          case '-1':
            // 支付待处理
            if (msg.data.paymentAuthType === '1') {
              // 信用卡预授权，启用预授权才会有
              // 展示付款成功页面给消费者看
              // 订单需要人工审核
              console.log('unexpected status: ', msg);
            } else {
              onPayFailed(msg.data.detailMessage);
            }
            break;
          case '0':
          default:
            onPayFailed(msg.data.detailMessage);
            break;
        }
      }
    } else if (msg.type == 'informationInvalid') {
      onPayFailed(msg.data.msg);
    }
  };
}

export function getOceanpayBackUrl(o: {
  host: string;
  tenantId: string;
  orderCode: string;
  redirectTo: string;
}) {
  return `${o.host}/v2/shop/order/${
    o.orderCode
  }/pay_order/{{payOrderId}}/ocean_pay/back?tenantId=${
    o.tenantId
  }&redirectTo=${encodeURIComponent(o.redirectTo)}`;
}

export function getOceanpayNoticeUrl(o: {
  host: string;
  tenantId: string;
  payOrderId: string;
  orderCode: string;
}) {
  // return `${o.host}/order/pay/notify/oceanpay/notice?tenantId=${o.tenantId}&payOrderId=${o.payOrderId}`;
  return `${o.host}/v2/shop/order/${o.orderCode}/pay_order/${o.payOrderId}/ocean_pay/notice?tenantId=${o.tenantId}`;
}
