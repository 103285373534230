import { BaseEntity } from '../../common';
import { Area } from '../../common/address/Area';

export class BaseTenant extends BaseEntity<BaseTenant> {
  name: string;
  companyId: number;

  createdAt: string;
  updatedAt: string;

  areaCode: string;
  area: Area;

  /** 自身作为供应商的编号 */
  supplyCode: string;

  constructor(props?: Partial<BaseTenant>) {
    super(props);

    Object.assign(this, props);
  }
}
