import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Form, message } from 'antd';
import cn from 'classnames';
import { uniq } from 'lodash-es';

import { TechDrawingUploader } from '@/features/new_quote/components/uploader/TechDrawingUploader';
import { useInquiryQuoteEditorStore } from '@/stores';

export const isTechnicalDrawingRequired = (
  technology: S.TechCategory,
  orderItem: S.InquiryOrderItem,
) => {
  const isCNC = technology.type === 'CNC';
  const printFile = orderItem.printFiles[0];
  return S.get(printFile, () => isCNC || printFile?.nutCount! > 0) as boolean;
};

export const OrderTechItemDrawings = ({
  editing,
  orderItem,
  className,
}: {
  editing: boolean;
  orderItem: S.InquiryOrderItem;
  className?: string;
}) => {
  const { technology, updateInquiryOrder } = useInquiryQuoteEditorStore();
  const isCNC = technology.type === 'CNC';
  const printFile = orderItem.printFiles[0];

  const isNeedUploadingFile = S.get(
    printFile,
    p => isCNC || p?.nutCount! > 0,
  ) as boolean;

  if (editing) {
    return (
      <Form.Item
        style={{ width: '100%' }}
        required={isNeedUploadingFile}
        className={cn('order-item-', className)}
      >
        <TechDrawingUploader
          isRequired={isNeedUploadingFile}
          onFinishSolely={fileIds => {
            updateInquiryOrder('onUpdateOrderItemRemarkFileId', o => {
              const targetItem = o.items.find(i => i.id === orderItem.id);

              if (targetItem) {
                targetItem.remarkFileIds = uniq([
                  ...(orderItem.remarkFileIds || []),
                  ...fileIds,
                ]);
              }
            });

            message.success(
              'Technical drawing file has been uploaded successfully',
            );
          }}
          onDelete={fileId => {
            updateInquiryOrder('onRemoveOrderItemRemarkFileId', o => {
              const targetItem = o.items.find(i => i.id === orderItem.id);

              if (targetItem) {
                const finalIds = (orderItem.remarkFileIds || []).filter(
                  i => i !== fileId,
                );
                targetItem.remarkFileIds = finalIds;
              }
            });

            message.success(
              i18nFormat(
                'Technical drawing file has been removed successfully',
              ),
            );
          }}
        />
      </Form.Item>
    );
  } else {
    return (S.get(orderItem, oi => oi.remarkFileIds) || []).length > 0 ? (
      <span>
        <span>{i18nFormat('Drawing')}</span>
        <span>
          {`${
            (S.get(orderItem, oi => oi.remarkFileIds) || []).length
          } ${i18nFormat('files')}`}
        </span>
      </span>
    ) : (
      <></>
    );
  }
};
