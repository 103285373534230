import axios from 'axios';
import dayjs from 'dayjs';

import * as S from '../../../../schema';
import { UfcShopTokenUtils } from '../../../../skeleton/env';
import { getUfcShopRuntimeEnv } from '../../..';
import { TrackerUtils } from '../../shared/TrackerUtils';
import { trackGA4BuiltinEvent } from '../ga-tracker/GaEventTracker';
import { IUserEventTracker, UserTrackEventRecord } from '../types';
import { mapUserTrackEventTypeToGA4Type } from '../types';

const UOMS_REMOTE_CLIENT_INFO_KEY = 'uoms||remoteClientInfo';
const UOMS_REMOTE_CLIENT_INFO_REFETCH_INTERVAL = 3600_000;
const FLUSH_INTERVAL = 30_000;
const UOMS_PLACE_ORDER_ISSUE_ORDERS_KEY = 'uoms||placeOrderIssueOrders';

/** 滑动窗口时长 */
export const TrackSlidingWindowMilliseconds = 24 * 60 * 60 * 1000;

interface CachedRemoteClientInfo {
  cachedAt: number;
  data: RemoteClientInfo;
}

interface RemoteClientInfo {
  ipAddress?: string;
  userAgent?: string;
  clientData?: Record<string, any>;
}

interface ClientInfo extends RemoteClientInfo {
  origin?: string;
}

let _unionfabEventTracker: UnionfabEventTracker;

export class UnionfabEventTracker implements IUserEventTracker {
  id: string;

  conversationId?: string;
  isNewConversation?: boolean;

  waitSubmitRecords: Partial<UserTrackEventRecord>[] = [];
  flushInterval: ReturnType<typeof setInterval>;
  eventTypeToReportedAtMap: Record<string, number> = {};

  static getIns() {
    if (!_unionfabEventTracker) {
      _unionfabEventTracker = new UnionfabEventTracker();
    }

    return _unionfabEventTracker;
  }

  registerConversationId() {
    const lastConversationId = localStorage.getItem('uoms_conversation_id');

    if (lastConversationId) {
      const timeStr = lastConversationId.split('#')[1];

      if (
        timeStr &&
        Math.abs(dayjs().diff(dayjs(timeStr), 'milliseconds')) <
          TrackSlidingWindowMilliseconds
      ) {
        this.conversationId = lastConversationId;
        return;
      }
    }

    this.isNewConversation = true;
    this.conversationId = `${TrackerUtils.getUserSessionId()}#${dayjs().format(
      'YYYY-MM-DD-HH-mm-ss',
    )}`;
    localStorage.setItem('uoms_conversation_id', this.conversationId);
  }

  constructor() {
    this.id = S.genId();
    this.registerConversationId();

    // 判断是否要上报
    if (this.isNewConversation) {
      this.addUserEventRecord({
        typeName: TrackerUtils.getIsNewUser()
          ? 'newpreson_start_conversation'
          : 'oldperson_start_conversation',
        accessSource: this.getOrigin() || this.getReferer(),
      });
    }

    const storedEventTypeToReportedAtMapStr = localStorage.getItem(
      'uoms_event_tracker_event_type_to_reported_at_map',
    );

    if (storedEventTypeToReportedAtMapStr) {
      try {
        this.eventTypeToReportedAtMap =
          JSON.parse(storedEventTypeToReportedAtMapStr) || {};
      } catch (e) {
        this.eventTypeToReportedAtMap = {};
      }
    }

    // 每 30s 上报一次数据
    this.flushInterval = setInterval(async () => {
      const currentPage = document.location.href;

      this.onKeepAlive(currentPage);

      requestIdleCallback(() => {
        this.flush();
      });
    }, FLUSH_INTERVAL);

    window.addEventListener(
      'beforeunload',
      () => {
        // 判断是否在注册页面
        if (location.href.includes('register')) {
          this.addUserEventRecord({ typeName: 'register_page_end' });
        } else if (location.href.includes('login')) {
          this.addUserEventRecord({ typeName: 'login_page_end' });
        } else if (location.href.includes('upload_model')) {
          this.addUserEventRecord({ typeName: 'placeorder_page_end' });
        } else if (location.href.includes('checkout')) {
          this.addUserEventRecord({ typeName: 'unpaid_page_end' });
        } else {
          this.addUserEventRecord({ typeName: 'person_end_conversion' });
        }

        this.flush();
      },
      false,
    );
  }

  reportPlaceOrderQuoteIssue = async (orderCode: string, detail?: string) => {
    const issueOrders: string[] = [];
    try {
      const tmp = JSON.parse(
        localStorage.getItem(UOMS_PLACE_ORDER_ISSUE_ORDERS_KEY) || '[]',
      );
      if (tmp.length) {
        issueOrders.push(...tmp);
      }
    } catch (e) {
      //
    }
    if (issueOrders.find(v => v === orderCode)) {
      return;
    }

    if (issueOrders.length > 10) {
      issueOrders.shift();
    }
    issueOrders.push(orderCode);
    localStorage.setItem(
      UOMS_PLACE_ORDER_ISSUE_ORDERS_KEY,
      JSON.stringify(issueOrders),
    );
    const eventData: Record<string, string> = { orderCode };
    if (detail) eventData.detail = detail;
    await this.addUserEventRecord({
      typeName: 'placeOrderQuoteIssue',
      eventData,
    });
  };

  addUserEventRecord = async (r: Partial<UserTrackEventRecord> = {}) => {
    if (r.typeName) {
      // 记录事件触发信息
      this.eventTypeToReportedAtMap[r.typeName] = Date.now();
    }

    const record = new UserTrackEventRecord({
      sessionId: TrackerUtils.getUserSessionId(),
      conversationId: this.conversationId,
      terminal: S.isMobile() ? 'MOBILE' : 'PC',
      origin: this.getOrigin(),
      referer: this.getReferer(),
      ...r,
    });

    this.waitSubmitRecords.push(record);

    if (r.typeName !== 'page_view') {
      // 将事件转化为用户记录事件
      trackGA4BuiltinEvent(mapUserTrackEventTypeToGA4Type(r.typeName), r);
    }
  };

  onKeepAlive = async (viewedPage: string) => {
    if (viewedPage === window.location.href) {
      this.addUserEventRecord({
        typeName: 'keep_live',
        urlId: viewedPage,
        // 单位是秒（该值目前实际无效，后端会去合并多条同页面的 keep_live 记录计算在页面的停留时间）
        stayTime: 12.5,
      });
    }
  };

  /** 执行数据上报 */
  flush = async () => {
    localStorage.setItem(
      'uoms_event_tracker_event_type_to_reported_at_map',
      JSON.stringify(this.eventTypeToReportedAtMap),
    );

    if (S.isValidArray(this.waitSubmitRecords)) {
      const records = await this.prepareRecords([...this.waitSubmitRecords]);
      this.waitSubmitRecords = [];
      // 异步保存
      this.saveEventRecords(records);
    }
  };

  prepareRecords = async (records: Partial<UserTrackEventRecord>[]) => {
    /** 判断是否有 inviteCode */
    const inviteCode = localStorage.getItem('inviteCode');

    const clientInfo = await this.fetchClientInfo();
    // 注意这里，对于记录中给定了客户端相关信息的话，会做保留
    return records.map(r => {
      let record;

      if (typeof inviteCode === 'string' && inviteCode !== '') {
        record = { ...r, inviteCode };

        delete record.origin;
      } else {
        record = r;
      }

      return Object.assign({}, clientInfo, record);
    });
  };

  saveEventRecords = async (records: Partial<UserTrackEventRecord>[]) => {
    const host = getUfcShopRuntimeEnv().apiEndpoint;
    const url = `${host}/user_track_event_record?tenantId=${window.gConfig.tenantId}`;

    const headers = UfcShopTokenUtils.isTokenValid()
      ? { Authorization: `Bearer ${UfcShopTokenUtils.getAccessToken()}` }
      : {};
    try {
      await axios.post(url, records, { headers });
    } catch (_) {
      console.error('>>>UnionfabEventTracker>>>saveRecords>>>error:', _);
    }
  };

  fetchClientInfo = async (): Promise<ClientInfo> => {
    const res: ClientInfo = {};

    // 补全 origin
    const origin = window.localStorage.getItem('origin');
    if (origin) {
      res.origin = origin;
    }

    const remoteClientInfo = await this.fetchRemoteClientInfoCached();

    return Object.assign(res, remoteClientInfo);
  };

  fetchRemoteClientInfoCached = async (): Promise<
    RemoteClientInfo | undefined
  > => {
    const clientInfo = this.getCachedRemoteClientInfo();
    if (clientInfo != null) {
      if (
        clientInfo.cachedAt - Date.now() >
          UOMS_REMOTE_CLIENT_INFO_REFETCH_INTERVAL ||
        clientInfo.data.ipAddress == null
      ) {
        // 缓存超时，或者缓存的数据无效，进行异步更新
        this.fetchRemoteClientInfo().then(
          v => v != null && this.persistCachedRemoteClientInfo(v),
        );
      }
      return clientInfo.data;
    }

    const r = await this.fetchRemoteClientInfo();
    if (r != null) this.persistCachedRemoteClientInfo(r);
    return r;
  };

  getValByLowerCaseKey = (obj: { [key: string]: string }, key: string) => {
    if (!key) return;
    key = key.toLowerCase();
    const k = Object.keys(obj).find(v => v != null && v.toLowerCase() === key);
    return k == null ? undefined : obj[k];
  };

  persistCachedRemoteClientInfo = (data: RemoteClientInfo) => {
    const toSave: CachedRemoteClientInfo = { data, cachedAt: Date.now() };
    localStorage.setItem(UOMS_REMOTE_CLIENT_INFO_KEY, JSON.stringify(toSave));
  };

  getCachedRemoteClientInfo = (): CachedRemoteClientInfo | undefined => {
    const d = localStorage.getItem(UOMS_REMOTE_CLIENT_INFO_KEY);
    if (d == null) return undefined;
    try {
      const r: CachedRemoteClientInfo = JSON.parse(d);

      // simple type check
      if (r.cachedAt == null) throw new Error();
      if (typeof r.data !== 'object') throw new Error();

      return r;
    } catch (e) {
      console.warn(`error reading: ${UOMS_REMOTE_CLIENT_INFO_KEY}`, d);
      localStorage.removeItem(UOMS_REMOTE_CLIENT_INFO_KEY);
      return;
    }
  };

  fetchRemoteClientInfo = async (): Promise<RemoteClientInfo | undefined> => {
    const host =
      window.gConfig.CLIENT_INFO_CLIENT || 'https://echo.unionfab.com';
    const url = `${host}/api/echoClientInfo`;
    const res: RemoteClientInfo = {
      userAgent: navigator.userAgent,
    };

    try {
      const { address, headers } = (
        await axios.get<{
          address: string;
          headers: { [key: string]: string };
        }>(url)
      ).data;
      if (address) {
        res.ipAddress = address;
      }

      if (headers) {
        const xForwardedFor = this.getValByLowerCaseKey(
          headers,
          'x-forwarded-for',
        );
        if (xForwardedFor) {
          res.ipAddress = xForwardedFor;
        }

        const userAgent = this.getValByLowerCaseKey(headers, 'user-agent');
        if (userAgent) {
          res.userAgent = userAgent;
        }

        res.clientData = { headers };

        return res;
      }
    } catch (e) {
      console.error('>>>UnionfabEventTracker>>>fetchClientInfo>>>error:', e);
      return res;
    }
  };

  getOrigin = () => {
    return (
      window.localStorage.getItem('origin') || S.getUrlParameterByName('origin')
    );
  };

  getReferer = () => {
    return (
      window.localStorage.getItem('referer') ||
      S.getUrlParameterByName('referer')
    );
  };
}
