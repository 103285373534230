import { Id } from '../../common';

export type TenantConfigViewTypeEnum =
  | 'minimum'
  | 'middle'
  | 'normal'
  | 'verbose';
export type TenantConfigEncryptedViewTypeEnum =
  /** 完全加密 */
  | 'ENCRYPTION'
  /** 半加密 */
  | 'HALF_ENCRYPTION'
  /** 全部显示 */
  | 'SHOW';
export type TenantUploadPolicyEnum = 'OSS' | 'LOCAL' | 'CUSTOM_OSS';
export type TenantConfigBooleanEnum = 'true' | 'false';
export type TenantConfigInquiryOrderConstraintEnum =
  | 'ignore' // 不进行限制
  | 'deny-quote' // 拒绝下单
  | 'deny-product'; // 拒绝下发生产
export type TenantConfigHintEnum =
  | 'false' // 不进行限制
  | 'prompt' // 提示
  | 'modify'; // 直接修改
export type PeriodUnit = 'day-3' | 'day-7' | 'day-15' | 'day-30';
export const CustomerEditionList = [
  'CLOUD',
  'BASIC',
  'STANDARD',
  'ADVANCED',
  'ULTIMATE',
  'CUSTOMIZED',
  'PRIVATE',
  // 以下为其他项目级别的版本
  'RENT',
] as const;
export type CustomerEditionEnum = typeof CustomerEditionList[number];

/** 租户配置项，注意，租户配置项中的存储值，全部为字符串类型 */
export class TenantConfig {
  /** 基础信息 */
  tenantId: Id;

  /** 模型文件优先存储 */
  modelStoreId: Id;

  /** 海外默认报价组 */
  overseaQuotationGroup: string;

  calculatedPriceType:
    | 'WITH_TAX' // 表示计算出的总价是含税价格
    | 'WITHOUT_TAX' = 'WITH_TAX'; // 表示计算出的总价不是含税价格

  constructor(props?: Partial<TenantConfig>) {
    Object.assign(this, props);
  }
}

export type TenantConfigKey = keyof TenantConfig;

let defaultTenantConfig = new TenantConfig({});

export function getDefaultTenantConfig() {
  return defaultTenantConfig;
}

export function setDefaultTenantConfig(t: TenantConfig) {
  if (t) {
    defaultTenantConfig = t;
  }
}

export const TenantConfigKeys = [
  'modelStoreId',
  'overseaQuotationGroup',
  'calculatedPriceType',
] as const;
