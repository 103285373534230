import {
  get,
  getModelFileMetrics,
  i18nFormat,
  loadD3ModelFileByIdsInStore,
  useD3ModelStore,
} from '@unionfab/ufc-shop-commons';
import { D3ModelAvatar } from '@unionfab/ufc-shop-ui-commons';
import { Popover, Skeleton, Typography } from 'antd';
import cn from 'classnames';
import { useEffect } from 'react';

import { getUseShopQuoteStoreByCode, useInquiryMaterialStore } from '@/stores';

import styles from './index.module.less';
import PopoverContent from './PopoverContent';

export interface ShopQuoteTableItemInfoProps {
  className?: string;
  style?: Record<string, string | number>;

  orderCode: string;
  itemId: string;
}

export const ShopQuoteTableItemInfo = ({
  className,
  style,

  orderCode,
  itemId,
}: ShopQuoteTableItemInfoProps) => {
  const item = getUseShopQuoteStoreByCode(orderCode)(s =>
    s.shopOrder.items.find(i => i.id === itemId),
  );

  const { materialTypes = [] } = useInquiryMaterialStore();
  const modelFile = useD3ModelStore(s => (item ? s[item.fileId] : undefined));

  useEffect(() => {
    loadD3ModelFileByIdsInStore([item.fileId]);
  }, [item && item.fileId]);

  if (!item || !modelFile) {
    return <Skeleton avatar paragraph={{ rows: 2 }} />;
  }

  const curLengthUnit = item.lengthUnit;

  const getConfiguration = () => {
    const materialVO = get(item, item => item.materialVO);
    const materialTypeId =
      get(materialVO, m => m.materialTypeId) ||
      get(materialVO, m => m.materialType.id);
    const materialType = materialTypes.find(m => m.id == materialTypeId);
    const categoryName = get(materialType, m => m.categoryName);
    const handleMethodStr = get(item, i => i.handleRemark.method.join('/'), '');

    return (
      (categoryName ? categoryName + '/' : '') +
      get(materialVO, m => m.name, '') +
      (handleMethodStr !== '' ? '/' + handleMethodStr : '')
    );
  };

  const renderEllipsisText = ({
    txt = '',
    rows = 1,
    suffixCount = 8,
  }: {
    txt: string;
    rows?: number;
    suffixCount?: number;
  }) => {
    // const start = txt.slice(
    //   0,
    //   txt.length > suffixCount ? txt.length - suffixCount : 0,
    // );
    // const suffix = txt.slice(-suffixCount).trim();

    return (
      <Typography.Paragraph
        style={{ margin: 0, fontSize: 12, marginLeft: 3 }}
        ellipsis={{ rows, tooltip: txt }}
      >
        {txt}
      </Typography.Paragraph>
    );
  };

  return (
    <div
      id="ShopQuoteTableItemInfo"
      className={cn(className, styles.container)}
      style={style}
    >
      <div className={styles.file}>
        <D3ModelAvatar key={itemId} model={modelFile} size={60} />
        <div className={styles.fileInfo}>
          {renderEllipsisText({ txt: modelFile.name, rows: 2 })}
          {renderEllipsisText({
            txt: getModelFileMetrics({ modelFile, length: curLengthUnit }),
          })}
        </div>
      </div>
      <Popover
        content={<PopoverContent orderCode={orderCode} itemId={itemId} />}
      >
        <div className={styles.popTrigger}>
          <div className={styles.flexStart}>
            {i18nFormat('Requirements')}:
            {renderEllipsisText({ txt: getConfiguration() })}
          </div>
          <div className={styles.flexStart}>
            {i18nFormat('Description')}:
            {renderEllipsisText({
              txt: get(item, i => i.remark.remark, '-'),
              rows: 2,
            })}
          </div>
        </div>
      </Popover>
    </div>
  );
};

ShopQuoteTableItemInfo.displayName = 'ShopQuoteTableItemInfo';
