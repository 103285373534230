import { InfoCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import * as S from '@unionfab/ufc-shop-commons';
import {
  getBuiltinImageCollection,
  i18nFormat,
} from '@unionfab/ufc-shop-commons';
import { Select, Space, Spin, Tooltip } from 'antd';
import { useState } from 'react';

import { openLoginPage, useAppNavigate } from '@/features/shared';
import { useDoRegisterAndContinue, useInquiryQuoteEditorStore } from '@/stores';

import { Ellipsis } from '../../../../../../../../commons/components/Element/ellipsis';
import { GoOrderCheckoutBtn } from '../../GoOrderCheckoutBtn';
import styles from './index.module.less';

let hasShowTooltipForCurrencyLimit = false;

export const QuoteSubmitorDesktop = () => {
  const newQuoteStore = useInquiryQuoteEditorStore();
  const { updateInquiryOrder } = newQuoteStore;
  const {
    inquiryOrder,
    estPrice,
    isLoadingEstPrice,
    leadTimeInfo,
  } = newQuoteStore;
  /** 最低订单差额 */
  const minOrderFee = inquiryOrder.price.minProductCostFee;
  const [signUpState, setSignUpState] = useState<
    { onSignUpSuccess: () => void } | undefined
  >();
  const [
    isCurrencyLimitTooltipVisible,
    setIsCurrencyLimitTooltipVisible,
  ] = useState(false);

  const nav = useAppNavigate();

  useDoRegisterAndContinue(openLoginPage, signUpState?.onSignUpSuccess);

  const isRFQ =
    estPrice?.subtotalRequiresManualQuote == null
      ? true
      : estPrice.subtotalRequiresManualQuote;
  const estimatedSubtotalPrice = estPrice?.subtotal;

  const dueDate = `${leadTimeInfo.maxLeadtimeInDays}-${
    leadTimeInfo.maxLeadtimeInDays + 1
  }`;
  const shipmentDate = leadTimeInfo.shipmentDate;

  let paragraph = '';
  if (!estimatedSubtotalPrice) {
    paragraph = i18nFormat(
      `Note: There is no system quotation for the time being. Please wait for the engineer's feedback after placing the order for the final quotation, post-processing fee, and freight.`,
    );
  } else if (isRFQ) {
    paragraph = i18nFormat(
      `Note: The quote need to be reviewed. Please wait for the engineer's feedback after placing the order for the final quotation, post-processing fee, and freight.`,
    );
  }

  return (
    <div className={styles.quoteSubmitor}>
      <div className={styles.orderSubmitorPanel}>
        <Space direction="vertical" className={styles.orderSubmitorHeader}>
          <div className={styles.firstItem}>
            <span>
              {i18nFormat('Subtotal')}
              <Tooltip
                title={i18nFormat(
                  'Dear customer, now PayPal can only pay in US dollars and Euros. If you choose other currencies, you can only pay by credit card or bank transfer.',
                )}
                open={isCurrencyLimitTooltipVisible}
                onOpenChange={setIsCurrencyLimitTooltipVisible}
              >
                <InfoCircleOutlined
                  style={{ color: '#faad14', marginLeft: 4 }}
                />
              </Tooltip>
            </span>

            <Spin spinning={isLoadingEstPrice}>
              <span className={styles.estPrice}>
                {estimatedSubtotalPrice == null ? (
                  <Ellipsis maxWidth={180}>
                    {isLoadingEstPrice
                      ? 'Waiting'
                      : 'Manual Quotation Required'}
                  </Ellipsis>
                ) : (
                  <span className={styles.orderItemPriceDigital}>
                    <div />
                    <Select
                      value={inquiryOrder?.currency}
                      style={{ marginRight: 10 }}
                      options={S.getSupportedCurrencyList().map(el => ({
                        label: el,
                        value: el.split(' ')[0],
                        key: el,
                      }))}
                      onChange={v => {
                        if (!(v === 'USD' || v === 'EUR')) {
                          if (!hasShowTooltipForCurrencyLimit) {
                            hasShowTooltipForCurrencyLimit = true;
                            setIsCurrencyLimitTooltipVisible(true);
                          }
                        }

                        updateInquiryOrder('changeCurrency', order => {
                          order.currency = v;
                        });
                      }}
                    />

                    {S.toPriceStringWithOption(estimatedSubtotalPrice, {
                      rfq: isRFQ,
                      currency: inquiryOrder?.currency,
                    })}
                  </span>
                )}

                {!estimatedSubtotalPrice && (
                  <Tooltip
                    color={'rgba(248, 153, 2)'}
                    overlayInnerStyle={{ width: '250px' }}
                    overlayClassName={styles.overlayClass}
                    title={i18nFormat(
                      'We will complete reviewing your order in 24 hours. (Except special order!)',
                    )}
                    placement="bottomLeft"
                    trigger={['click']}
                    defaultVisible={true}
                  >
                    <img
                      className="order-item-header-right-price-bulb"
                      src={
                        getBuiltinImageCollection()
                          .QUOTE_ORDER_ITEM_HANDLER_BULB
                      }
                      alt=""
                      style={{ marginLeft: 8 }}
                    />
                  </Tooltip>
                )}
              </span>
            </Spin>
          </div>

          <div className={styles.spaceBetween} style={{ margin: '8px 0' }}>
            <span className={styles.light}>
              {i18nFormat('Minimum Order Fee')}
              <Tooltip
                title={i18nFormat(
                  'The production cost of your models is currently under Unionfab’s production cost   minimum. If you would like to optimize for the best price, you can add more models to your order, increase the quantity of your existing models or simply proceed with it as is.',
                )}
                className={styles.vatIcon}
              >
                <QuestionCircleOutlined style={{ margin: '0 3px' }} />
              </Tooltip>
              :
            </span>
            <span>
              {estPrice?.minProductCostFee &&
                `${S.toPriceStringWithOption(estPrice.minProductCostFee, {
                  currency: inquiryOrder.currency,
                })}`}
            </span>
          </div>
        </Space>

        <div className={styles.orderSubmitorContent}>
          {!isRFQ && (
            <div className={styles.orderSubmitorContentItem}>
              <span className={styles.orderSubmitorContentLeft}>
                {i18nFormat('Lead Time')}
                {':'}
              </span>
              <span className={styles.orderSubmitorContentRight}>
                {`${dueDate} ${i18nFormat('Business Days')}`}
              </span>
            </div>
          )}

          {!isRFQ && (
            <div className={styles.orderSubmitorContentItem}>
              <span className={styles.orderSubmitorContentLeft}>
                {i18nFormat('Estimated shipment')}:
              </span>
              <span
                className={styles.orderSubmitorContentRight}
              >{`${shipmentDate}`}</span>
            </div>
          )}
          <div className={styles.orderSubmitorContentThird}>
            <span>{paragraph}</span>
          </div>
        </div>
      </div>
      <div className={styles.orderSubmitorBtnWrapper}>
        <GoOrderCheckoutBtn
          className={styles.orderSubmitorBtn}
          style={{ flex: '1 1 1px' }}
          signUp={onSignUpSuccess => setSignUpState({ onSignUpSuccess })}
        />
      </div>
      <div className={styles.orderSubmitorTerms}>
        {i18nFormat('By submit request,you agree to the ')}
        <a
          onClick={() => nav.navigateToPdfPageNewTab('csa')}
          style={{ color: '#f89902' }}
        >
          {i18nFormat('Terms of Service')}.
        </a>{' '}
        {i18nFormat('and')}{' '}
        <a
          onClick={() => nav.navigateToPdfPageNewTab('privacy_policy')}
          style={{ color: '#f89902' }}
        >
          {i18nFormat('Privacy Policy & Agreement')}.
        </a>
      </div>
    </div>
  );
};
