import * as S from '@unionfab/ufc-shop-commons';
import { getUfcShopComposedApi, i18nFormat } from '@unionfab/ufc-shop-commons';
import {
  compareBySequence,
  MATERIAL_SHOW_ORDERS,
  MATERIAL_TYPE_SHOW_ORDERS,
} from '@unionfab/ufc-shop-commons';
import { Form, Popover, Select } from 'antd';
import cn from 'classnames';
import { CSSProperties, useEffect } from 'react';

import { getRecursiveChildText } from '@/commons/utils';
import { useInquiryMaterialStore } from '@/stores';

import styles from './index.module.less';
import { InquiryMaterialShowCard } from './InquiryMaterialShowCard';

const { Option, OptGroup } = Select;

export interface InquiryMaterialSkuSelectProps {
  className?: string;
  style?: CSSProperties;

  filterOptions: { materialGroup?: string; techCategory: S.TechCategory };

  /** 表示 skuId */
  value?: string;

  onChange: (
    skuId: string,
    {
      material,
      materialSku,
    }: { material: S.InquiryMaterial; materialSku: S.InquiryMaterialSku },
  ) => void;
}

/**
 * 询价材料选择
 * TODO: 如果扩充要显示价格的话，建议以 renderProps 或者 children 方式传入
 */
export const InquiryMaterialSkuSelect = ({
  className,
  style,

  value,
  onChange,

  filterOptions,
}: InquiryMaterialSkuSelectProps) => {
  const { materials, materialTypes, techTypeToMaterials } =
    useInquiryMaterialStore();

  const techCategory = S.get(filterOptions, f => f.techCategory);

  const technologyMaterials = S.get(
    techTypeToMaterials,
    t => t[S.get(techCategory, t => t.type)],
  ) as S.InquiryMaterial[];

  const curTechMaterialTypes = materialTypes
    .filter(m => m.categoryName === S.get(techCategory, t => t.name))
    .sort((a, b) =>
      compareBySequence(a.name, b.name, MATERIAL_TYPE_SHOW_ORDERS),
    );
  // 材料默认展示内容（颜色 + 名称）
  const renderMaterialLabel = (m: S.InquiryMaterial) => {
    return (
      <div className={styles.materialOptionItem}>
        <span
          className={styles.materialOptionItemColorSample}
          style={{ backgroundColor: m.color }}
        />
        {i18nFormat(m.name)}
      </div>
    );
  };

  const renderMaterialOption = (
    mT: S.InquiryMaterial,
    searchTxt: string,
    key: string,
  ) => {
    const materialLabel = renderMaterialLabel(mT);

    return (
      <Option label={materialLabel} key={key} value={mT.id}>
        <div className={styles.materialSelectorOption}>
          {/* <Popover
            placement="right"
            overlayClassName={styles.materialPopup}
            zIndex={1060}
            content={<InquiryMaterialShowCard material={mT} />}
          >
            <div className={styles.materialSelectorOptionContent}>
              {materialLabel}
            </div>
          </Popover> */}
          <div className={styles.materialSelectorOptionContent}>
            {materialLabel}
          </div>
          <span hidden={true}>{searchTxt}</span>
        </div>
      </Option>
    );
  };

  // 可选材料 Sku
  const renderMaterialOptions = () =>
    curTechMaterialTypes
      .filter(mT => materials.find(m => m.materialTypeId === mT.id))
      .map(mT => {
        const materials = technologyMaterials
          .filter(m => m.materialTypeId === S.get(mT, () => mT.id))
          .filter(m => m.spuList.length > 0)
          .filter(m =>
            filterOptions.materialGroup
              ? m.materialGroup === filterOptions.materialGroup
              : true,
          )
          .sort((a, b) =>
            compareBySequence(a.name, b.name, MATERIAL_SHOW_ORDERS),
          );

        if (materials.length === 0) {
          return <></>;
        }

        return (
          <OptGroup key={`material-type-${mT.id}`} label={mT.name}>
            {materials.map(m =>
              renderMaterialOption(m, mT.name, `material-type${mT.id}-${m.id}`),
            )}
          </OptGroup>
        );
      })
      .filter(v => !!v);

  // 找到对应的 materialId
  const material = materials.find(m => (m.spuIds || []).includes(value));

  return (
    <div
      id="InquiryMaterialSkuSelect"
      className={cn(className, styles.container)}
      style={style}
    >
      <Form.Item
        name="material-select"
        initialValue={material ? material.id : value}
      >
        <Select
          className={styles.orderItemMaterialSpuSelect}
          value={material ? material.id : value}
          autoClearSearchValue={false}
          dropdownMatchSelectWidth={false}
          filterOption={(inp, opt) => {
            const text = getRecursiveChildText(opt);
            return (
              !inp || (text || '').toLowerCase().includes(inp.toLowerCase())
            );
          }}
          showSearch={true}
          optionLabelProp="label"
          placeholder={i18nFormat('Please select a material')}
          onChange={materialId => {
            const material = technologyMaterials.find(
              (m: S.InquiryMaterial) => m.id === materialId,
            );

            const materialSku = material.spuList[0];

            onChange(materialSku.id, { material, materialSku });
          }}
        >
          {renderMaterialOptions()}
        </Select>
      </Form.Item>
    </div>
  );
};

InquiryMaterialSkuSelect.displayName = 'InquiryMaterialSkuSelect';
