import dayjs from 'dayjs';
import _ from 'lodash';

import { BaseEntity, Id } from '../../common';
import { InquiryOrderDispatchUser } from './InquiryOrderUser';
import { InquiryOrderStatus } from './InquiryStatus';

export type InquiryChangeRecordType =
  | 'ORDER_STATUS_UPDATED'
  | 'ORDER_DISPATCH_UPDATED'
  | 'ORDER_PRICE_UPDATED'
  | 'ORDER_TYPE_UPDATED'
  | 'ORDER_LAST_DELIVER_UPDATED'
  | 'ORDER_SPLIT_UPDATE';

/** 订单更新类型 */
export type InquiryOrderChangeRecordLogType =
  | 'ORDER_FILE_ITEM_UPDATED' // 订单中零件(或材料)更新
  | 'ORDER_CUSTOMER_UPDATED' // 订单中客户更新
  | 'ORDER_PRODUCT_REMARK_UPDATED' // 订单中附加要求更新
  | 'ORDER_PRICE_UPDATED' // 订单中报价更新
  | 'ORDER_LAST_DELIVERY_AT_UPDATED'; // 订单中最晚交期更新

export class InquiryChangeRecord extends BaseEntity<InquiryChangeRecord> {
  beforeStatus: InquiryOrderStatus;
  afterStatus: InquiryOrderStatus;
  orderId: string;
  // 额外属性
  attr: {
    operator: string;
    operatorId: Id;
    beforeDispatchUser: InquiryOrderDispatchUser;
    afterDispatchUser: InquiryOrderDispatchUser;
    isCustomerOperate: boolean;
    afterLastDeliverTime: string;
    beforeLastDeliverTime: string;
    orderClosedAt: string;
    /** 记录外发被回退的原因 */
    orderRejectRemark: string;
    // 拆单的日志
    nextSubOrderCode: string[];
    prevSubOrderCode: string[];
  };
  // 状态变更发生时间
  createdAt: string;
  type: InquiryChangeRecordType;

  get afterLastDeliverTimeStr() {
    if (!this.attr.afterLastDeliverTime) {
      return '-';
    }

    return dayjs(this.attr.afterLastDeliverTime).format('MM/DD HH:00');
  }

  constructor(data?: Partial<InquiryChangeRecord>) {
    super(data);

    Object.assign(this, data);
  }
}

export class InquiryOrderStep extends BaseEntity<InquiryOrderStep> {
  label: string;
  beforeStatus: InquiryOrderStatus;
  afterStatus: InquiryOrderStatus;
  isCustomerOperate: boolean;

  // 操作人编号
  operatorId: Id;
  // 操作人名
  operator: string;

  // 是否激活过
  isActive = false;

  constructor(props?: Partial<InquiryOrderStep>) {
    super(props);

    Object.assign(this, props);
  }
}

export const getInquiryOrderStepList = (
  isSynergy = false,
): InquiryOrderStep[] =>
  (_.compact([
    {
      label: '创建订单',
      beforeStatus: 'WAIT_SUBMIT',
      afterStatus: 'WAIT_REVIEW',
    },
    {
      label: '审核报价',
      beforeStatus: 'WAIT_REVIEW',
      afterStatus: 'WAIT_CUSTOM_CONFIRM',
    },
    {
      label: '确认下单',
      beforeStatus: 'WAIT_CUSTOM_CONFIRM',
      afterStatus: 'CUSTOM_CONFIRM',
    },
    {
      label: isSynergy ? '下发外协' : '下发生产',
      beforeStatus: 'CUSTOM_CONFIRM',
      afterStatus: isSynergy ? 'WAIT_DELIVER' : 'WAIT_PRINT',
    },
    !isSynergy && {
      label: '数据处理',
      beforeStatus: 'WAIT_PRINT',
      afterStatus: 'FILE_HANDLE',
    },
    !isSynergy && {
      label: '上机打印',
      beforeStatus: 'FILE_HANDLE',
      afterStatus: 'PRINTING',
    },
    !isSynergy && {
      label: '后处理',
      beforeStatus: 'PRINTING',
      afterStatus: 'HANDLE',
    },
    !isSynergy && {
      label: '质检/打包',
      beforeStatus: 'HANDLE',
      afterStatus: 'WAIT_DELIVER',
    },
    {
      label: '发货',
      beforeStatus: 'WAIT_DELIVER',
      afterStatus: 'WAIT_RECEIVE',
    },
    {
      label: '确认收货',
      beforeStatus: 'WAIT_RECEIVE',
      afterStatus: 'ORDER_COMPLETE',
    },
  ]) as {
    label: string;
    afterStatus: InquiryOrderStatus;
  }[]).map(s => s && new InquiryOrderStep(s));

/** 从状态记录中推导出步骤列表 */
export const getInquiryOrderStepListFromStatusRecord = (
  statusRecords: InquiryChangeRecord[] = [],
  isSynergy?: boolean,
) => {
  const defaultSteps = getInquiryOrderStepList(isSynergy);

  for (const step of defaultSteps) {
    // 判断当前激活的应用
    for (const record of statusRecords) {
      if (
        !step.isActive &&
        (step.afterStatus === record.afterStatus ||
          (record.afterStatus === 'WAIT_REVIEW' &&
            record.afterStatus === step.afterStatus))
      ) {
        step.isActive = true;
        step.operator = record.attr.operator;
        step.operatorId = record.attr.operatorId;
        step.updatedAt = record.createdAt;
        step.isCustomerOperate = record.attr.isCustomerOperate;
      }
    }
  }

  // 这里判断是否有关闭的情况
  const closeRecord = statusRecords.find(r => r.afterStatus === 'ORDER_CLOSED');

  if (closeRecord) {
    defaultSteps.push(
      new InquiryOrderStep({
        label: '订单关闭',
        ...closeRecord,
        ...(closeRecord.attr || {}),
        updatedAt: closeRecord.createdAt,
      }),
    );
  }

  return defaultSteps;
};
