import * as S from '@unionfab/ufc-shop-commons';
import { useD3ModelStore } from '@unionfab/ufc-shop-commons';
import { D3ModelAvatar } from '@unionfab/ufc-shop-ui-commons';
import { Select } from 'antd';
import cn from 'classnames';

import {
  getUseShopQuoteStoreByCode,
  ShopOrderQuoteStoreModifiers,
} from '../../../../../../../../stores';
import styles from './index.module.less';

export interface ShopQuoteItemPreviewProps {
  className?: string;
  style?: Record<string, string | number>;

  orderCode: string;
  itemId: string;
}

export const ShopQuoteItemPreview = ({
  className,
  style,

  orderCode,
  itemId,
}: ShopQuoteItemPreviewProps) => {
  const store = getUseShopQuoteStoreByCode(orderCode);
  const { shopOrderDraft } = store(s => ({ shopOrderDraft: s.shopOrderDraft }));
  const item = (shopOrderDraft.items || []).find(i => i.id === itemId);

  const modelFile = useD3ModelStore(s => (item ? s[item.fileId] : undefined));

  const itemLengthUnitSelector = (
    <Select
      showArrow
      size="small"
      value={item?.lengthUnit}
      style={{ marginLeft: 8, width: 65 }}
      onChange={v =>
        ShopOrderQuoteStoreModifiers.updateOrderDraft(orderCode, o => {
          o.items.find(i => i.id == itemId).lengthUnit = v;
        })
      }
      options={S.LengthUnitList.map(i => ({
        label: S.getLengthUnitDesc(i),
        value: i,
      }))}
    />
  );

  return (
    <div
      id="ShopQuoteItemPreview"
      className={cn(className, styles.container)}
      style={style}
    >
      <div className={styles.modelSize}>
        <span>
          {S.getModelFileMetrics({
            modelFile,
            length: item?.lengthUnit,
            withVolume: true,
          })}
        </span>
        {itemLengthUnitSelector}
      </div>
      <D3ModelAvatar
        className={styles.d3ModelAvatar}
        key={itemId}
        model={modelFile}
        size={200}
      />
    </div>
  );
};

ShopQuoteItemPreview.displayName = 'ShopQuoteItemPreview';
