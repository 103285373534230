import { debounce } from 'lodash-es';
import { create, GetState, StoreApi, UseBoundStore } from 'zustand';
import { devtools, NamedSet } from 'zustand/middleware';

import {
  copyFile,
  getDefaultSpuByMaterialId,
  getModelFilesByIds,
  getTenantIdFromGConfig,
  newInquiryOrderItem,
  UnionfabEventTracker,
} from '../../../apis';
import * as S from '../../../schema';
import {
  FileId,
  getBuiltinTechCategoryList,
  getDefaultTechCategory,
  getLeadTimeInfo,
  getModelFilesMapFromInquiryOrder,
  ILeadTimeInfo,
  TechCategory,
} from '../../../schema';
import { useInquiryMaterialStore } from '../../offerings/useInquiryMaterialStore';
import {
  clearQuoteCache,
  loadInquiryQuoteOrderFromStorage,
  newInquiryOrderUpdateHandler,
} from './order-storage';

type OrderModifier = (o: S.InquiryOrder) => void;

// Quote 编辑（目前 WAIT_SUBMIT 状态的订单即认为是询价单）
export interface IQuoteEditorState {
  // 加载之前编辑中或初始化新的询单
  loadInquiryOrder: (orderCode?: string) => Promise<void>;
  // 订单改变监听
  onOrderChange: (order: S.InquiryOrder) => void;
  // 当前询单
  inquiryOrder?: S.InquiryOrder;

  // 待编辑询单加载中
  isInquiryOrderLoading: boolean;
  d3ModelUploaderId: string;

  // 询单预估价
  isLoadingEstPrice: boolean;
  estPrice?: S.OrderQuotationV2;

  // 当前工艺
  curTechCategoryName: string;
  technology: TechCategory;
  changeTechNameInInquiryQuoteEditorStore: (techCategoryName: string) => void;

  // tech + inquiryOrder.items
  // 文件类型不符合要求的条目索引
  invalidItemIndexListForModelFileType: number[];
  // 未上传附件的条目索引
  invalidItemIndexListForRemark: number[];
  updateInvalidItemIndexes: (
    actionName: string,
    data: {
      invalidItemIndexListForModelFileType: number[];
      invalidItemIndexListForRemark: number[];
    },
  ) => void;

  // inquiryOrder.items
  // 模型文件
  modelFileMap: Partial<Record<S.Id, S.D3ModelFile>>;

  modelFileD3AttrWatcher?: NodeJS.Timer;
  // 轮询监听 modelFileMap 中未处理完的模型属性，会触发 modelFileMap 的更新
  toggleModelFileD3AttrWatcher: (enable?: boolean) => void;

  // inquiryOrder
  // 按当前选中材料 SPU 计算的交期信息
  leadTimeInfo: ILeadTimeInfo;

  // 更新当前询单
  updateInquiryOrder: (actionName: string, onModify: OrderModifier) => void;

  // 重置当前在编辑的询价单
  resetInquiryOrder: () => void;
  onUnloadQuoteEditor: () => void;

  // 通过模型文件新增订单条目；这种方式，同一个文件（用文件 ID 标识）只能存在于一个订单条目
  addOrUpdateOrderItemByFiles: (
    actionName: string,
    modelFiles: S.D3ModelFile[],
  ) => void;
  // 和 addOrUpdateOrderItemByFiles 对应
  removeOrderItemViaFiles: (modelFileIds: S.Id[]) => void;
  // 拷贝订单条目
  copyOrderItems: (orderItems: S.InquiryOrderItem[]) => Promise<void>;

  // 选中要编辑的模型 ID
  setModelFileIdsToEdit: (ids: S.Id[]) => void;
  // 选中待编辑的模型 ID
  modelFileIdsToEdit: S.Id[];

  // 当前在编辑的模型 ID
  toggleOrderItemFileEditing: (
    checked: boolean,
    orderItem: S.InquiryOrderItem,
  ) => void;
  editingFileIds: S.Id[];

  // 订单条目对应所选材料分组名称
  orderItemMaterialGroupMap: Partial<Record<S.Id, string>>;
  toggleOrderItemMaterialGroupMap: (itemId: S.Id, groupName: string) => void;
  processingFileMap?: Record<string, FileId>;
  addToProcessingFileMap?: (fileId: FileId) => void;
  removeFromProcessFileMap?: (fileId: FileId) => void;
}

function getInitStates() {
  return {
    inquiryOrder: undefined,
    isInquiryOrderLoading: false,
    d3ModelUploaderId: 'quote-editor-d3model-uploader',
    technology: getDefaultTechCategory(),
    curTechCategoryName: getDefaultTechCategory().name,

    // derived from items & tech
    invalidItemIndexListForModelFileType: [],
    invalidItemIndexListForRemark: [],

    // derived from order
    modelFileMap: {} as Partial<Record<S.Id, S.D3ModelFile>>,
    leadTimeInfo: {} as ILeadTimeInfo,

    modelFileIdsToEdit: [] as S.Id[],
    editingFileIds: [] as S.Id[],
    isLoadingEstPrice: false as boolean,

    orderItemMaterialGroupMap: {},
    processingFileMap: {} as Record<string, FileId>,
  };
}

interface Store {
  set: NamedSet<IQuoteEditorState>;
  get: GetState<IQuoteEditorState>;
}

function initQuoteEditorStore(s: Store): IQuoteEditorState {
  const loadInquiryOrder = async (orderCode?: string) =>
    await _loadInquiryOrder(s, orderCode);
  const updateInquiryOrder = (actionName: string, onModify: OrderModifier) =>
    _updateInquiryOrder(s, actionName, onModify);
  const onOrderChange = debounce(onOrderChangeInQuoteEditor(s), 300);
  const setModelFileIdsToEdit = (modelFileIdsToEdit: S.Id[]) =>
    s.set({ modelFileIdsToEdit }, false, 'setModelFileIdsToEdit');

  const toggleModelFileD3AttrWatcher = (enable?: boolean) => {
    s.set(({ modelFileD3AttrWatcher }) => {
      if (enable && !modelFileD3AttrWatcher) {
        return { modelFileD3AttrWatcher: _startModelFileD3AttrWatcher(s) };
      } else if (!enable && modelFileD3AttrWatcher) {
        clearInterval((modelFileD3AttrWatcher as unknown) as number);
        return { modelFileD3AttrWatcher: null };
      } else {
        return {};
      }
    });
  };

  const addOrUpdateOrderItemByFiles = (
    actionName: string,
    modelFiles: S.D3ModelFile[],
  ) => _addOrUpdateOrderItemByFiles(s, actionName, modelFiles);

  const updateInvalidItemIndexes = (
    actionName: string,
    data: {
      invalidItemIndexListForModelFileType: number[];
      invalidItemIndexListForRemark: number[];
    },
  ) => s.set(data, false, `updateInvalidItemIndexes/${actionName}`);

  const toggleOrderItemMaterialGroupMap = (itemId: S.Id, groupName: string) => {
    const { orderItemMaterialGroupMap } = s.get();

    const finalMap = { ...orderItemMaterialGroupMap };

    if (itemId in finalMap && groupName === finalMap[itemId]) {
      delete finalMap[itemId];
    } else {
      finalMap[itemId] = groupName;
    }

    s.set({ orderItemMaterialGroupMap: finalMap });
  };

  const addToProcessingFileMap = (fileId: FileId) => {
    const processingFileMap = s.get().processingFileMap || {};
    const newMap = { ...processingFileMap };
    newMap[fileId] = fileId;
    s.set({ processingFileMap: newMap });
  };

  const removeFromProcessFileMap = (fileId: FileId) => {
    const processingFileMap = s.get().processingFileMap || {};
    const newMap = { ...processingFileMap };
    delete newMap[fileId];
    s.set({ processingFileMap: newMap });
  };

  return {
    ...getInitStates(),

    updateInvalidItemIndexes,

    loadInquiryOrder,
    onOrderChange,
    updateInquiryOrder,

    setModelFileIdsToEdit,

    resetInquiryOrder: () => resetInquiryOrder(s),
    onUnloadQuoteEditor: () => onUnloadQuoteEditor(s),

    toggleModelFileD3AttrWatcher,
    addOrUpdateOrderItemByFiles,

    removeOrderItemViaFiles: modelFileIds =>
      removeOrderItemViaFiles(s, modelFileIds),
    copyOrderItems: async orderItems => await copyOrderItems(s, orderItems),
    changeTechNameInInquiryQuoteEditorStore: techCategoryName =>
      changeTechNameInInquiryQuoteEditorStore(s, techCategoryName),
    toggleOrderItemFileEditing: (checked, orderItem) =>
      toggleOrderItemFileEditing(s, checked, orderItem),

    toggleOrderItemMaterialGroupMap,
    addToProcessingFileMap,
    removeFromProcessFileMap,
  };
}

export const useInquiryQuoteEditorStore: UseBoundStore<
  StoreApi<IQuoteEditorState>
> = create<IQuoteEditorState>()(
  devtools((set, get) => initQuoteEditorStore({ set, get }), {
    name: 'quoteEditorStore',
  }),
);

// Private functions.

export function _updateInquiryOrder(
  s: Store,
  name: string,
  onModify: OrderModifier,
) {
  const { set } = s;
  set(
    prev => {
      onModify(prev.inquiryOrder || new S.InquiryOrder({}));

      const inquiryOrder = new S.InquiryOrder({ ...prev.inquiryOrder });

      const modelFileMap = getModelFilesMapFromInquiryOrder(inquiryOrder);
      const leadTimeInfo = getLeadTimeInfo(inquiryOrder);

      return { inquiryOrder, modelFileMap, leadTimeInfo };
    },
    false,
    `updateInquiryOrder/${name}`,
  );
}

async function _loadInquiryOrder(s: Store, orderCode?: string) {
  try {
    s.set({ isInquiryOrderLoading: true }, false, 'isInquiryOrderLoading/on');
    const { order, estPrice } = await loadInquiryQuoteOrderFromStorage(
      orderCode,
    );

    const modelFileMap = getModelFilesMapFromInquiryOrder(order);
    const leadTimeInfo = getLeadTimeInfo(order);

    s.set(
      {
        isLoadingEstPrice: false,
        inquiryOrder: order,
        estPrice,
        modelFileMap,
        leadTimeInfo,
      },
      false,
      'isInquiryOrderLoading/off_onOrderLoadSuccess',
    );
  } catch (e) {
    console.error('>>>loadInquiryOrder>>>error: ', e);
  } finally {
    s.set(
      { isInquiryOrderLoading: false },
      false,
      'isInquiryOrderLoading/off_cleanup',
    );
  }
}

function onOrderChangeInQuoteEditor(s: Store) {
  return async function _onOrderChange(order: S.InquiryOrder) {
    const { set } = s;
    set(
      { isLoadingEstPrice: true },
      false,
      'isInquiryOrderLoading/on_onOrderChange',
    );
    let cancelled = false;
    try {
      const handler = newInquiryOrderUpdateHandler(order);
      const res = await handler.handle();
      if (handler.cancelled) {
        cancelled = true;
      }
      if (!handler.cancelled && !!res) {
        const cur = useInquiryQuoteEditorStore.getState().inquiryOrder;
        if (cur === order) {
          set(
            {
              isLoadingEstPrice: false,
              inquiryOrder: res.order,
              estPrice: res.estPrice,
            },
            false,
            'isInquiryOrderLoading/on_onOrderChangeHandleSuccess',
          );
        } else {
          console.log('result ignored...');
        }
      }
    } finally {
      if (!cancelled) {
        set(
          {
            isLoadingEstPrice: false,
          },
          false,
          'isInquiryOrderLoading/on_onOrderChangeHandleCancelled',
        );
      }
    }
  };
}

function changeTechNameInInquiryQuoteEditorStore(
  s: Store,
  techCategoryName: string,
) {
  const { updateInquiryOrder } = s.get();

  // 这里需要更新当前条目的材料
  const inquiryMaterialStore = useInquiryMaterialStore.getState();
  const { techTypeToMaterials } = inquiryMaterialStore;

  const techs = getBuiltinTechCategoryList();
  const technology =
    techs.find(v => v.name === techCategoryName) || getDefaultTechCategory();

  s.set(
    {
      curTechCategoryName: techCategoryName,
      technology,
      estPrice: undefined,
      isLoadingEstPrice: false,
    },
    false,
    'changeTechNameInInquiryQuoteEditorStore',
  );
  localStorage.setItem('techCategoryType', technology.type);

  updateInquiryOrder('resetSpuOnTechnologyChange', o => {
    for (const item of o.items) {
      item.materialSpu = getDefaultSpuByMaterialId(
        techTypeToMaterials[technology.type]![0].id!,
        inquiryMaterialStore.materials,
      );
    }
  });
}

function toggleOrderItemFileEditing(
  s: Store,
  checked: boolean,
  orderItem: S.InquiryOrderItem,
) {
  s.set(
    prev => {
      const { editingFileIds } = prev;
      if (checked) {
        return {
          editingFileIds: Array.from(
            new Set([...editingFileIds, orderItem.modelFiles[0].id as string]),
          ) as S.Id[],
        };
      } else {
        return {
          editingFileIds: editingFileIds.filter(
            i => i !== orderItem.modelFiles[0].id,
          ),
        };
      }
    },
    undefined,
    'toggleOrderItemFileEditing',
  );
}
function resetInquiryOrder(s: Store) {
  clearQuoteCache();
  onUnloadQuoteEditor(s);
}

function onUnloadQuoteEditor(s: Store) {
  s.set(() => ({ ...getInitStates() }), false, 'unloadQuoteEditor');
}

function shouldWatchModelD3Attr(m: S.D3ModelFile): boolean {
  // 已经处理失败了，不再轮询监听
  if (m?.attr?.hasProcessedByServer) {
    return false;
  }
  // 体积和截图都已经出来了，不再轮询监听
  if (
    m?.attr?.volume != null &&
    (m?.attr?.thumbnail != null || m?.attr?.thumbnailFileId != null)
  ) {
    return false;
  }
  return true;
}

function _startModelFileD3AttrWatcher(s: Store): NodeJS.Timer {
  return setInterval(async () => {
    const modelFileMap = s.get().modelFileMap as Record<S.Id, S.D3ModelFile>;
    const toWatchFileIds = Object.keys(modelFileMap).filter(fileId => {
      const m: S.D3ModelFile = modelFileMap[fileId];
      const hasVolume = m?.attr?.volume != null;
      if (hasVolume) {
        s.get().removeFromProcessFileMap(m.id);
      } else {
        s.get().addToProcessingFileMap(m.id);
      }
      return shouldWatchModelD3Attr(m);
    }) as S.Id[];

    if (toWatchFileIds.length !== 0) {
      const modelByFileIds = await getModelFilesByIds(toWatchFileIds);
      s.set(prev => {
        let someVolumeAppeared = false;
        const prevMap = prev.modelFileMap;
        for (const fileId of Object.keys(modelByFileIds)) {
          const mOld = prevMap[fileId] as S.D3ModelFile;
          const mNew = modelByFileIds[fileId] as S.D3ModelFile;
          if (mOld?.attr?.volume == null && mNew?.attr?.volume != null) {
            someVolumeAppeared = true;
          }
          prevMap[fileId] = mNew;
        }
        if (someVolumeAppeared) {
          return {
            modelFileMap: { ...prevMap },
            inquiryOrder: new S.InquiryOrder({ ...prev.inquiryOrder }),
          };
        } else {
          return {
            modelFileMap: { ...prevMap },
          };
        }
      });
    }
  }, 3000);
}

function _addOrUpdateOrderItemByFiles(
  s: Store,
  name: string,
  modelFiles: S.D3ModelFile[],
) {
  const { inquiryOrder, updateInquiryOrder, technology } = s.get();
  const inquiryMaterialStore = useInquiryMaterialStore.getState();
  const {
    techTypeToMaterials,
    materials: inquiryMaterials,
  } = inquiryMaterialStore;

  updateInquiryOrder(`addOrUpdateOrderItemByFiles/${name}`, o => {
    UnionfabEventTracker.getIns().addUserEventRecord({
      typeName: 'upload_file',
      fileIds: modelFiles.map(f => f.id),
    });

    for (const modelFile of modelFiles) {
      // 判断该文件是否存在
      const existedOrderItem = (inquiryOrder?.items || []).find(
        i =>
          S.isValidArray(i.modelFiles) && i.modelFiles[0].id === modelFile.id,
      );

      if (existedOrderItem) {
        const existedOrderItemIndex = (inquiryOrder?.items || []).findIndex(
          i => i.modelFiles[0].id === modelFile.id,
        );

        existedOrderItem.printFiles = [
          new S.InquiryPrintFile({
            ...existedOrderItem.printFiles[0],
            modelFile: new S.D3ModelFile(modelFile),
          }),
        ];

        o.items[existedOrderItemIndex] = new S.InquiryOrderItem(
          existedOrderItem,
        );
      } else {
        const orderItem = newInquiryOrderItem({
          model: modelFile,
          materialSpu: getDefaultSpuByMaterialId(
            S.get(techTypeToMaterials, m => m[technology.type][0].id) as S.Id,
            inquiryMaterials,
          ),
          printCount: 1,
          tenantId: getTenantIdFromGConfig(),
          defaultOrder: new S.InquiryOrder({}),
          handle: { method: [''], desc: '' },
          remark: '',
          remarkFileIds: [],
          lengthUnit: S.LengthUnitList[1],
        });
        o.items.push(orderItem);
      }
    }
  });
}

async function copyOrderItems(s: Store, orderItems: S.InquiryOrderItem[]) {
  const { addOrUpdateOrderItemByFiles } = s.get();
  const copiedModelFiles: S.D3ModelFile[] = [];

  // 复制模型，然后复制条目
  for (const orderItem of orderItems) {
    const copiedModelFileId = await copyFile(orderItem.modelFiles[0]);
    copiedModelFiles.push(
      new S.D3ModelFile({ ...orderItem.modelFiles[0], id: copiedModelFileId }),
    );
  }

  addOrUpdateOrderItemByFiles('copyOrderItem', copiedModelFiles);
}

function removeOrderItemViaFiles(s: Store, modelFileIds: S.Id[]) {
  const { updateInquiryOrder } = s.get();

  s.set(
    {
      modelFileIdsToEdit: [],
      editingFileIds: [],
    },
    undefined,
    'removeOrderItemViaFiles',
  );

  updateInquiryOrder('removeOrderItemViaFiles', o => {
    const newItems = o.items.filter(
      item => !modelFileIds.includes(item.modelFiles[0].id!),
    );
    o.items = newItems;
  });
}
