import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import cn from 'classnames';

import styles from './index.module.less';

export interface RfqTooltipProps {
  className?: string;
  style?: Record<string, string | number>;
}

export const RfqTooltip = ({ className, style }: RfqTooltipProps) => {
  return (
    <span
      id="RfqTooltip"
      className={cn(className, styles.container)}
      style={style}
    >
      <Tooltip title={'The quote need to be manual reviewed.'}>
        <InfoCircleOutlined style={{ color: '#faad14' }} />
      </Tooltip>
    </span>
  );
};

RfqTooltip.displayName = 'RfqTooltip';
