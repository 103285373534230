import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import * as S from '@unionfab/ufc-shop-commons';
import {
  i18nFormat,
  searchMaterialImageUrls,
} from '@unionfab/ufc-shop-commons';
import { Carousel, Empty, Image, Tag } from 'antd';
import * as React from 'react';
import { useEffect, useState } from 'react';

import styles from './index.module.less';

export interface InquiryMaterialShowCardProps {
  material: S.InquiryMaterial;
}

export const InquiryMaterialShowCard: React.FC<InquiryMaterialShowCardProps> = ({
  material,
}) => {
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const materialId = material.id!;

  useEffect(() => {
    searchMaterialImageUrls(materialId).then(vs => setImageUrls(vs));
  }, []);

  return (
    <div
      onClick={e => e.stopPropagation()}
      className={styles.materialShowCardContainer}
    >
      <div className={styles.imgs}>
        {S.isValidArray(imageUrls) ? (
          <Carousel
            dots={false}
            arrows={true}
            prevArrow={<CaretLeftOutlined />}
            nextArrow={<CaretRightOutlined />}
          >
            {imageUrls.map((url, i) => (
              <Image key={i} src={url} width="5rem" height="5rem" />
            ))}
          </Carousel>
        ) : (
          <Empty
            imageStyle={{ width: '5rem', height: '5rem' }}
            description="No preview image"
          />
        )}
      </div>
      <div className={styles.intro}>
        <div className={styles.title}>Build Size</div>
        <div className={styles.content}>
          {material.maxBuildSize || i18nFormat('No Data')}
        </div>
      </div>
      {material.marketLabels && material.marketLabels.length > 0 && (
        <div className={styles.intro}>
          <div className={styles.title}>Label</div>
          <div className={styles.content}>
            {material.marketLabels.map((value: string) => (
              <Tag>{value}</Tag>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
