import {
  getUseShopQuoteStoreByCode,
  i18nFormat,
  ShopOrderQuoteStoreModifiers,
} from '@unionfab/ufc-shop-commons';
import { Form, Select, Typography } from 'antd';

import useCNCOptions from '../../../../../../../../../ufc-shop-commons/src/stores/quote/shop-order-quote/useCNCOptions';
import { ShopQuoteItemNotesProps } from '../ShopQuoteItemNotes';

export function ShopQuoteItemPartMarking({
  orderCode,
  itemId,
}: ShopQuoteItemNotesProps) {
  const { options: OPTS = [] } =
    useCNCOptions(s => s.options).partMarking || {};
  const onChange = (val: number) => {
    ShopOrderQuoteStoreModifiers.updateOrderDraft(orderCode, o => {
      const item = o.items.find(i => i.id === itemId);
      item.cncRequirements = {
        ...item.cncRequirements,
        partMarking: {
          option: OPTS[val],
        },
      };
      if (val == null) delete item.cncRequirements.partMarking;
    });
  };
  const antdSelectOpts = OPTS.map((opt, idx) => ({
    label: opt,
    value: idx,
  }));
  const item = getUseShopQuoteStoreByCode(orderCode)(s =>
    (s.shopOrderDraft.items || []).find(i => i.id === itemId),
  );
  const originValId = OPTS.indexOf(item?.cncRequirements?.partMarking?.option);
  const formValId = originValId < 0 ? undefined : originValId;
  return (
    <Form.Item
      name="part-marking"
      label={
        <Typography.Title level={5}>
          {i18nFormat('Part Marking')}
        </Typography.Title>
      }
      initialValue={formValId}
    >
      <Select
        allowClear
        placeholder={i18nFormat('Tap to Select')}
        style={{ maxWidth: 220 }}
        options={antdSelectOpts}
        onChange={onChange}
        value={formValId}
      />
    </Form.Item>
  );
}

ShopQuoteItemPartMarking.displayName = 'ShopQuoteItemPartMarking';
