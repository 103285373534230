import { BaseEntity, CurrencyAndAmount } from '../../../common';
import { get } from '../../../utils';
import { toPriceStringWithOption } from '../../utils';
import { ShopOrderQuotationStatus } from '../quotation/types';

export class ShopOrderPrice extends BaseEntity<ShopOrderPrice> {
  /** 报价版本 */
  revision: string;
  /** 报价截止时间 */
  insuredUntil: string;
  /** 报价客户确认时间 */
  customerCheckedAt: string;

  status: ShopOrderQuotationStatus;

  price: CurrencyAndAmount;

  /** 制造费用 */
  production: {
    status: ShopOrderQuotationStatus;

    price: CurrencyAndAmount;
    items: {
      id: string;
      status: ShopOrderQuotationStatus;
      setPrice: CurrencyAndAmount;
      price: CurrencyAndAmount;
    }[];
    minProductionFee: CurrencyAndAmount;
  };

  /** 物流交付费用 */
  delivery: {
    expressId: string;
    price: CurrencyAndAmount;
    status: ShopOrderQuotationStatus;
  };

  /** 选择优惠券后展示的价格 */
  couponApplied: {
    productionPrice: CurrencyAndAmount;
    deliveryPrice: CurrencyAndAmount;
    price: CurrencyAndAmount;
  };

  get hasComputingItem() {
    return (
      get(this, t => t.status) === 'COMPUTING' ||
      get(this, t => t.delivery.status) == 'COMPUTING' ||
      get(this, t => t.production.status) == 'COMPUTING'
    );
  }

  get subTotal() {
    const { production } = this;
    const currency = get(this, t => t.price.currency);

    if (get(production, p => p.status) == 'RFQ') return 'RFQ';

    const productPrice = get(production, p => p.price.amount, 0);

    return toPriceStringWithOption(productPrice, {
      currency,
    });
  }

  get productionFee() {
    const { production } = this;
    const currency = get(this, t => t.price.currency);
    const minProductionFee = get(production, p => p.minProductionFee.amount, 0);

    if (get(production, p => p.status) == 'RFQ') return '-';

    const productPrice = get(production, p => p.price.amount, 0);

    return toPriceStringWithOption(productPrice - minProductionFee, {
      currency,
    });
  }

  get shipping() {
    const currency = get(this, t => t.price.currency);

    if (get(this, t => t.delivery.status) == 'RFQ') return 'RFQ';

    return toPriceStringWithOption(
      get(this.delivery, o => o.price.amount),
      { currency, rfq: true },
    );
  }

  get discount() {
    const { price, couponApplied } = this;

    if (!couponApplied) return '-';

    const amount =
      get(price, p => p.amount, 0) - get(couponApplied, c => c.price.amount, 0);
    const currency = get(this, t => t.price.currency);

    return `- ${toPriceStringWithOption(amount, { currency })}`;
  }

  get total() {
    const { status, couponApplied, price } = this;
    const currency = get(this, t => t.price.currency);

    if (status == 'RFQ') return 'RFQ';

    if (!couponApplied) {
      return toPriceStringWithOption(
        get(price, o => o.amount),
        { currency },
      );
    }

    return toPriceStringWithOption(
      get(couponApplied, c => c.price.amount),
      { currency },
    );
  }

  constructor(data: Partial<ShopOrderPrice> = {}) {
    super(data);

    BaseEntity.assignInConstructor(this, data);
  }
}
