import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Form, Select } from 'antd';

import styles from './index.module.less';

export const LOCATIONS_OPTS = ['1', '2', '3', '4', '5', 'more than 5', 'all'];

/** 这个组件中对 Location 的赋值，也和 ShopQuoteItemDrawings 组件有关，当被赋值，ShopQuoteItemDrawings 组件则必填 */
export function ShopQuoteItemLocations({
  onChange,
  formValId,
  formName,
}: {
  /** 在 type 不是 tolerance 和 surfaceRoughness 的时候传入，改变选择器值的时候触发 */
  onChange?: (val: number) => void;
  /** 在 type 不是 tolerance 和 surfaceRoughness 的时候传入，作为选择器的值 */
  formValId?: number;
  /** 在 type 不是 tolerance 和 surfaceRoughness 的时候传入 */
  formName?: string;
}) {
  const _formName = `locations-${formName || 'other'}`;

  const antdSelectOpts = LOCATIONS_OPTS.map((opt, idx) => ({
    label: opt,
    value: idx,
  }));

  return (
    <>
      <Form.Item
        rules={[{ required: true, message: 'Locations is required' }]}
        name={_formName}
        label={<h6 className={styles.h6}>{i18nFormat('Locations')}</h6>}
        initialValue={formValId < 0 ? null : formValId}
      >
        <Select
          allowClear
          placeholder={i18nFormat('Tap to Select')}
          style={{ maxWidth: 220 }}
          options={antdSelectOpts}
          value={formValId}
          onChange={onChange}
        />
      </Form.Item>
    </>
  );
}

ShopQuoteItemLocations.displayName = 'ShopQuoteItemLocations';
