import { DownOutlined } from '@ant-design/icons';
import {
  i18nFormat,
  InquiryCoupon,
  isValidArray,
} from '@unionfab/ufc-shop-commons';
import { Dropdown, Menu, message, Space, Spin } from 'antd';
import { useEffect, useState } from 'react';

import CouponIcon from '@/assets/svgs/coupon.svg';
import { useInquiryCouponStore, useInquiryQuoteCheckoutStore } from '@/stores';

import { CouponRedeemInput } from '../../../../../../../coupon/components/redeem/CouponRedeemInput';
import styles from './index.module.less';

export const OrderCouponSelector = () => {
  const { coupon, checkCoupon, applyCoupon } = useInquiryQuoteCheckoutStore();

  const { coupons, loadInquiryCoupons } = useInquiryCouponStore();

  const [loading, setLoading] = useState<boolean>(false);

  /** 等待提交的优惠券 Code */
  const [waitToSelectedCouponCode, setWaitToSelectedCouponCode] = useState<
    string | undefined
  >();

  useEffect(() => {
    loadInquiryCoupons();
  }, []);

  useEffect(() => {
    if (isValidArray(coupons) && typeof waitToSelectedCouponCode === 'string') {
      const targetCoupon = coupons.find(
        c => c.code === waitToSelectedCouponCode,
      );

      /** 检验当前优惠券是否可用 */
      if (targetCoupon && !checkCoupon(targetCoupon)) {
        !!targetCoupon && onSelectCoupon(targetCoupon);
      }

      setWaitToSelectedCouponCode(undefined);
    }
  }, [waitToSelectedCouponCode, coupons]);

  const onSelectCoupon = async (c: InquiryCoupon) => {
    if (!!c.code && c.code === coupon?.code) {
      return;
    }

    try {
      setLoading(true);

      if (!c.code && c.code === "Don't choose coupon") {
        await applyCoupon(undefined);
      }

      if (!!c.code && c.code.length > 0) {
        await applyCoupon(c.code);
      }

      setLoading(false);
    } catch (_) {
      message.error('Failed to use coupon');
    } finally {
      setLoading(false);
    }
  };

  const renderCoupons = () => {
    const canUseCoupons = (coupons || []).filter(c => !checkCoupon(c));

    const menuItems = [
      new InquiryCoupon({ code: "Don't choose coupon" }),
      ...(coupons || []).filter(c => c.status === 'UNUSED'),
    ].map((c, index) => ({
      key: c.code,
      label: index === 0 ? c.code : c.descWithExpires,
      disabled: index === 0 ? false : typeof checkCoupon(c) === 'string',
      onClick: () => onSelectCoupon(c),
    }));

    let selectedCouponDesc = '';

    if (!coupon) {
      selectedCouponDesc = `${canUseCoupons.length} ${i18nFormat(
        'coupon(s) available',
      )}`;
    } else if (!!coupon && coupon.code.length > 0) {
      selectedCouponDesc = coupon.descWithExpires;
    }

    return (
      <Spin spinning={loading}>
        <Dropdown
          trigger={['click']}
          placement="bottomRight"
          overlay={
            <Menu
              items={menuItems}
              style={{ maxHeight: '400px', overflowY: 'auto' }}
            />
          }
          getPopupContainer={() =>
            document.getElementById('area') as HTMLElement
          }
        >
          <div className={styles.selectedCoupon} id="area">
            {!coupon || !coupon.code ? (
              <>
                <div className={styles.flexCenter}>
                  <CouponIcon />
                  &nbsp;
                  <span>{i18nFormat('Choose a coupon')}</span>
                </div>
                <Space>
                  <span>{selectedCouponDesc}</span>
                  <DownOutlined />
                </Space>
              </>
            ) : (
              <>
                <span>{selectedCouponDesc}</span>
                <DownOutlined />
              </>
            )}
          </div>
        </Dropdown>
      </Spin>
    );
  };

  return (
    <div className={styles.container}>
      {renderCoupons()}
      <CouponRedeemInput
        onSuccess={code => {
          setWaitToSelectedCouponCode(code);
        }}
      />
    </div>
  );
};
