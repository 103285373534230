import * as S from '../../../../schema';
import {
  getTenantIdFromGConfig,
  getUfcShopComposedApi,
} from '../../../../singleton-ins';

/** 根据订单信息计算运费 */
export async function fetchOrderQuotation(orderCode: string) {
  const {
    data,
    status,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.postUmi<{
    data: S.OrderQuotationV2;
    status: string;
  }>(
    `/customer/order/${orderCode}/quotation?tenantId=${getTenantIdFromGConfig()}`,
  );

  if (status && status === 'ok' && data) {
    if (S.get(data, d => d.shipQuotationsCalculating, false)) {
      (data.shipExpressQuotations || []).forEach(e => {
        e.systemQuotation = undefined;
      });
    }

    return data;
  } else {
    return {
      itemQuotations: [],
      requiresManualQuote: true,
      shipExpressQuotations: [],
      shipQuotationsRequiresManualQuote: true,
      subtotalRequiresManualQuote: true,
    };
  }
}

export async function getOrderQuotationDiscount(
  orderCode: string,
  couponCodes?: string[],
): Promise<{
  withoutCoupon?: S.OrderQuotationV2;
  withCoupons?: { [couponCode: string]: S.OrderQuotationV2 };
}> {
  const {
    data,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.postUmi(
    `/customer/order/${orderCode}/quotation/discount?tenantId=${getTenantIdFromGConfig()}`,
    couponCodes ? { params: { couponCode: couponCodes } } : {},
  );
  const quotes = data as S.OrderQuotationV2[];

  const withoutCoupon = quotes.find(v => v.couponCode == null);
  const withCoupons: { [couponCode: string]: S.OrderQuotationV2 } = {};
  for (const q of quotes) {
    if (q.couponCode) {
      withCoupons[q.couponCode] = q;
    }
  }

  return {
    withoutCoupon,
    withCoupons,
  };
}

export async function fetchPaidOrderQuotationV2(
  orderCode: string,
): Promise<S.OrderQuotationV2 | undefined> {
  const {
    data,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.postUmi(
    `/customer/order/${orderCode}/quotation/discount?tenantId=${getTenantIdFromGConfig()}`,
    { body: JSON.stringify([]) },
  );

  const quotes = data as S.OrderQuotationV2[];

  return quotes[0];
}

/** 触发服务端客户提交草稿的通知 */
export async function triggerSaveDraftNotify(req: {
  orderCode: string;
  customerName: string;
  email: string;
}) {
  const r = await getUfcShopComposedApi().umiRequestCompatibleApi.postUmi(
    `/customer/order/draft_notify`,
    {
      data: req,
    },
  );
  if ((r as any)?.status !== 'ok') {
    throw r;
  }
}
