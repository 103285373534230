import { divide, isEqual } from 'lodash-es';
import { create, GetState, StoreApi, UseBoundStore } from 'zustand';
import { devtools, NamedSet } from 'zustand/middleware';

import {
  fetchOrderQuotation,
  getCurrencyExchangeRate,
  getInquiryOrderDetailByCodeForOverseasCustomer,
  getTenantIdFromGConfig,
  getUfcShopComposedApi as api,
  updateCustomerOrderRemark,
  updateCustomerShipOption,
  updateInquiryOrderByCodeForOverseasCustomer,
} from '../../../apis';
import {
  convertReceiveAddressToDeliverAddress,
  isOnlyMetalType,
  isOnlySLAType,
  PaymentChannel,
  readOrderReceiveAddress,
} from '../../../schema';
import * as S from '../../../schema';
import { isDev } from '../../../skeleton/env';

export interface UserPaymentResult {
  success?: boolean;
  failureMessage?: string;
}

function getOceanpaymentEndpoint(): string {
  return isDev()
    ? 'https://test-secure.oceanpayment.com'
    : 'https://secure.oceanpayment.com/gateway/service/pay';
}

function getOceanpaymentCheckoutPageUrl(): string {
  return isDev()
    ? 'https://test-secure.oceanpayment.com/gateway/direct/checkpage'
    : 'https://secure.oceanpayment.com/gateway/direct/checkpage';
}

export interface InquiryQuoteCheckoutStoreState {
  userEmail?: string;
  loadUserEmail: () => void;

  isLoadingOrder: boolean;
  // 当前页订单
  order?: S.InquiryOrder;

  // 加载当前页面订单
  loadOrder: (code: string) => Promise<void>;
  ratioFromUsdToOrderCurrency?: number;

  // 当前订单（若处于待审核状态），是否可以无需自动确认、支付
  canAutoConfirmAndWaitPay?: boolean;

  // 当前订单是否可以支付
  canPay: boolean;
  // 订单支付价格；加载订单信息会自动计算该值
  paymentPrice?: S.OrderPaymentPrice;

  // 当前订单是否处于支付中（即跳转、弹窗第三方进行支付中）
  isWaitingUserPay: boolean;
  // 支付过程中消息提示展示位置
  onPayMessagePosition?: 'modal';
  setOnPay: (
    isWaitingUserPay: boolean,
    // 默认 false
    removePaymentResultWhenToggleOffOnPay?: boolean,
    onPayMessagePosition?: 'modal',
  ) => void;
  paymentResult?: UserPaymentResult;
  setPaymentResult: (res?: UserPaymentResult) => void;

  // 订单当前收货地址
  orderDeliverAddress?: S.ReceiveAddress | undefined;
  // 更新订单收货地址
  updateOrderDeliverAddress: (addr: S.ReceiveAddress) => Promise<void>;

  // 账单地址
  billingAddress?: S.ReceiveAddress;
  // 设定账单地址
  setBillingAddress: (addr?: S.ReceiveAddress) => void;

  shipOptionDraft?: S.OrderShipOption;
  setShipOptionDraft: (shipOption: S.OrderShipOption) => void;
  estDeliveryTime?: string;
  setEstDeliveryTime: (v?: string) => void;
  saveShipOption: () => Promise<void>;

  remarkText?: string;
  setRemarkText: (remarkText: string) => void;
  saveRemarkText: () => Promise<void>;

  // 物流报价
  shipQuotations?: S.OrderExpressQuotation[];

  // 当前应用的优惠码
  coupon?: S.InquiryCoupon;
  // 优惠券使用问题
  couponError?: string;
  // 应用优惠码
  applyCoupon: (couponCode?: string) => Promise<void>;
  // 校验优惠券是否可用
  checkCoupon: (coupon: S.InquiryCoupon) => string | undefined;

  // 支付方式
  paymentChannel?: PaymentChannel;
  setPaymentChannel: (ch: PaymentChannel) => void;

  // 钱海支付相关
  oceanpaymentEndpoint: string;
  oceanpaymentCheckoutPageUrl: string;

  // 用于触发钱海支付组件重新加载
  oceanpaymentFrameVersion: number;
  // 二次验证窗口是否打开
  oceanpaymentPayUrlWindowOpen: boolean;
  // 钱海支付二次验证链接，安全码输入
  oceanpaymentPayUrl?: string;
  // 钱海支付中间状态信息
  oceanpaymentPayState?: { detailMessage: string; solution: string };

  // 钱海支付状态
  // payUrl: 二次验证链接；tips: 提示信息
  setOceanpaymentState: (
    payUrl?: string,
    state?: { detailMessage: string; solution: string },
  ) => void;
  setOceanpaymentPayUrlWindowOpen: (opened: boolean) => void;
  updateOceanpaymentFrameVersion: () => void;

  // 是否同意服务条款（Term of Service）
  agreeTermOfService: boolean;
  setAgreeTermOfService: (b: boolean) => void;

  // 订单询价
  quotation?: S.OrderQuotationV2;
  // 计算当前订单报价；报价和物流及材料相关
  loadQuotation: () => Promise<void>;
}

interface Store {
  set: NamedSet<InquiryQuoteCheckoutStoreState>;
  get: GetState<InquiryQuoteCheckoutStoreState>;
}

const INIT_STATE: Partial<InquiryQuoteCheckoutStoreState> = {
  isLoadingOrder: false,
  canAutoConfirmAndWaitPay: false,
  canPay: false,
  isWaitingUserPay: false,
  remarkText: undefined,
  onPayMessagePosition: 'modal' as const,
  shipOptionDraft: undefined,
  paymentPrice: undefined,
  quotation: undefined,
  paymentChannel: undefined,
  billingAddress: undefined,
  coupon: undefined,
  couponError: undefined,
  estDeliveryTime: undefined,
  oceanpaymentFrameVersion: 0,
  oceanpaymentEndpoint: getOceanpaymentEndpoint(),
  oceanpaymentCheckoutPageUrl: getOceanpaymentCheckoutPageUrl(),
  oceanpaymentPayUrlWindowOpen: false,
  agreeTermOfService: false,
};

export const useInquiryQuoteCheckoutStore: UseBoundStore<
  StoreApi<InquiryQuoteCheckoutStoreState>
> = create<InquiryQuoteCheckoutStoreState>()(
  devtools(
    (set, get) => {
      const s: Store = { set, get };

      const loadUserEmail = async () => {
        const u = await api().userApi.getAccountProfileInfo();

        set({ userEmail: u.email });
      };

      const setOceanpaymentPayUrlWindowOpen = (opened: boolean) =>
        set({ oceanpaymentPayUrlWindowOpen: opened });

      const setOceanpaymentState = (
        payUrl?: string,
        state?: { detailMessage: string; solution: string },
      ) =>
        set({
          oceanpaymentPayUrl: payUrl,
          oceanpaymentPayState: state,
        });

      const setPaymentResult = (r?: UserPaymentResult) => {
        const before = get().paymentResult;
        if (
          r?.success !== before?.success &&
          r?.failureMessage !== before?.failureMessage
        ) {
          set({ paymentResult: r, isWaitingUserPay: false });
        }
      };

      const setOnPay = (
        isWaitingUserPay: boolean,
        removePaymentResultWhenToggleOffOnPay?: boolean,
        onPayMessagePosition?: 'modal',
      ) => {
        if (isWaitingUserPay || removePaymentResultWhenToggleOffOnPay) {
          set({
            isWaitingUserPay,
            onPayMessagePosition,
            paymentResult: undefined,
            oceanpaymentPayUrl: undefined,
            oceanpaymentPayState: undefined,
          });
        } else {
          set({ isWaitingUserPay, onPayMessagePosition });
        }
      };

      const state: InquiryQuoteCheckoutStoreState = {
        ...INIT_STATE,

        loadUserEmail,

        setOnPay,
        setPaymentResult,

        loadOrder: async code => await loadOrder(s, code),

        loadQuotation: async () => await loadQuotation(s),

        updateOrderDeliverAddress: async addr =>
          await doUpdateOrderDeliverAddress(s, addr),
        setBillingAddress: billingAddress => s.set({ billingAddress }),

        setEstDeliveryTime: estDeliveryTime => set({ estDeliveryTime }),
        setShipOptionDraft: shipOption => setShipOptionDraft(s, shipOption),
        saveShipOption: async () => await saveShipOption(s),

        setRemarkText: remarkText => s.set({ remarkText }),
        saveRemarkText: () => saveRemarkText(s),

        applyCoupon: async couponCode => await applyCoupon(s, couponCode),
        checkCoupon: coupon => checkCoupon(s, { coupon }),

        setPaymentChannel: ch => setPaymentChannel(s, ch),

        setOceanpaymentPayUrlWindowOpen,
        setOceanpaymentState,
        updateOceanpaymentFrameVersion: () =>
          set({
            oceanpaymentFrameVersion: get().oceanpaymentFrameVersion + 1,
          }),

        agreeTermOfService: false,
        setAgreeTermOfService: agreeTermOfService =>
          updateAgreeTermOfService(s, agreeTermOfService),
      } as InquiryQuoteCheckoutStoreState;

      return state;
    },
    { name: 'checkoutPageStore' },
  ),
);

//// Private functions
function setShipOptionDraft(s: Store, shipOptionDraft: S.OrderShipOption) {
  s.set({ shipOptionDraft });

  s.get().order && (s.get().order.customerShipOption = shipOptionDraft);

  calcPaymentPrice(s);
}

async function loadOrder(s: Store, code: string) {
  try {
    s.set({ isLoadingOrder: true });

    // 加载订单
    const order = await getInquiryOrderDetailByCodeForOverseasCustomer(
      code,
      getTenantIdFromGConfig(),
    );

    // 订单派生状态
    const orderDeliverAddress = readOrderReceiveAddress(order);
    const canAutoConfirmAndWaitPay = checkCanOrderAutoConfirm(order);

    const shipQuotations: S.OrderExpressQuotation[] =
      (order.price as any).shipExpressQuotations || [];

    const ratioFromUsdToOrderCurrency = await getCurrencyExchangeRate(
      'USD',
      order.currency,
    );
    const shipOptionDraft = s.get().shipOptionDraft;
    const shipOptions = S.get(order, o => o.customerShipOption);

    await loadQuotation(s, order);

    if (
      S.isValid(shipOptions) &&
      S.isValid(shipOptionDraft) &&
      !isEqual(shipOptions, shipOptionDraft)
    ) {
      await setShipOptionDraft(s, shipOptions);
    } else {
      await calcPaymentPrice(s, { order });
    }
    await updateAgreeTermOfService(s, s.get().agreeTermOfService, order);

    s.set({
      // ...INIT_STATE,
      ratioFromUsdToOrderCurrency,
      order,
      orderDeliverAddress,
      canAutoConfirmAndWaitPay,
      shipQuotations,
    } as InquiryQuoteCheckoutStoreState);

    s.set({ isLoadingOrder: false });
  } catch (_e) {
    s.set({ isLoadingOrder: false });

    console.error('>>>useInquiryQuoteCheckoutStore>>>loadOrder>>>error', _e);
  }
}

async function updateAgreeTermOfService(
  s: Store,
  agreeTermOfService: boolean,
  order?: S.InquiryOrder,
) {
  const { paymentChannel } = s.get();

  order = order || s.get().order;

  let canPay;
  if (agreeTermOfService || paymentChannel === 'paypal') {
    switch (order?.status) {
      case 'WAIT_REVIEW':
        canPay = order.canAutoQuote;
        break;
      case 'WAIT_CUSTOM_CONFIRM':
        canPay = true;
        break;
      default:
        canPay = false;
    }
  } else {
    canPay = false;
  }
  if (order?.isQuotating) {
    canPay = false;
  }
  s.set({ agreeTermOfService, canPay });
}

async function applyCoupon(s: Store, couponCode?: string) {
  const inquiryCoupons = await api().userApi.getAccountCoupon();

  const coupon = inquiryCoupons.find(c => c.code === couponCode);
  await calcPaymentPrice(s, { coupon, clearCoupon: coupon == null });

  if (couponCode && !coupon) {
    s.set({ couponError: 'The coupon is not found.' });
  }
}

async function calcPaymentPrice(
  s: Store,
  {
    coupon,
    clearCoupon,
    order,
  }: Partial<{
    clearCoupon: boolean;
    order: S.InquiryOrder;
    coupon: S.InquiryCoupon;
  }> = {},
) {
  if (!coupon && !clearCoupon) {
    coupon = s.get().coupon;
  }

  if (!order) {
    order = s.get().order;
  }

  const nakedPrice = calcOrderNakedPaymentPrice(s);

  if (coupon) {
    const couponError = checkCoupon(s, { coupon, nakedPrice });

    if (couponError) {
      s.set({ coupon, paymentPrice: nakedPrice, couponError });
    } else {
      const paymentPrice = await applyCouponToPrice(
        nakedPrice!,
        coupon!,
        order?.currency || 'USD',
      );

      s.set({ coupon, paymentPrice, couponError });
    }
  } else {
    s.set({ coupon, paymentPrice: nakedPrice, couponError: undefined });
  }
}

function checkCoupon(
  s: Store,
  {
    coupon,
    nakedPrice,
  }: {
    coupon: S.InquiryCoupon;
    nakedPrice?: S.OrderPaymentPrice;
  },
): string | undefined {
  const { order, ratioFromUsdToOrderCurrency } = s.get();

  const _nakedPrice = nakedPrice || calcOrderNakedPaymentPrice(s);

  const isOnlySelectMetal = isOnlyMetalType(order?.items || []);

  const isOnlySelectSla = isOnlySLAType(order?.items || []);

  const deliveryAddress = S.get(order, o => o.deliver.deliverAddress, []);

  const countryList = deliveryAddress.map(d => d.country);

  return S.InquiryCouponValidator.validateCouponAndReturnErrorMsg(coupon, {
    price: _nakedPrice,
    ratioFromUsdToOrderCurrency,
    countryList,
    isOnlySelectMetal,
    isOnlySelectSla,
  });
}

async function applyCouponToPrice(
  price: S.OrderPaymentPrice,
  coupon: S.InquiryCoupon,
  sourceCurrency: S.CurrencySymbolType,
): Promise<S.OrderPaymentPrice> {
  /** 获取 订单原始币种 => USD 汇率 */
  const currency = await getCurrencyExchangeRate(sourceCurrency, 'USD');

  const { total: nakedTotal, subtotal, shipping: nakedShipping } = price;

  let total = nakedTotal;
  let shipping = nakedShipping || 0;
  let discount = 0;
  if (coupon.type === 'PERCENT_OFF') {
    if (nakedShipping && shipping && coupon.percentOff) {
      /** 判断该优惠券是否含有折扣上限 */
      if (!!coupon.limitDiscount && typeof coupon.limitDiscount === 'number') {
        /** 优惠券折扣上限统一为 USD */
        const _discount = S.toFixedNumber(
          subtotal * coupon.percentOff * currency,
          2,
        );

        if (_discount > coupon.limitDiscount) {
          /** 由 USD 换算为订单原始币种 */
          discount = S.toFixedNumber(divide(coupon.limitDiscount, currency));

          total =
            subtotal +
            shipping -
            S.toFixedNumber(divide(coupon.limitDiscount, currency), 2);
        } else {
          discount = S.toFixedNumber(subtotal * coupon.percentOff, 2);
          total = subtotal + shipping - discount;
        }
      } else {
        discount = S.toFixedNumber(subtotal * coupon.percentOff, 2);
        total = subtotal + shipping - discount;
      }
    }
  } else if (coupon.type === 'AMOUNT_OFF') {
    // 将优惠券金额转化为订单原始币种
    const translatedCouponAmountOff = S.toFixedNumber(
      divide(coupon.amountOff, currency),
      2,
    );

    if (subtotal && coupon.amountOff && shipping) {
      discount =
        subtotal < translatedCouponAmountOff
          ? subtotal
          : translatedCouponAmountOff;
      total = subtotal + shipping - discount;
    }
  } else if (coupon.type === 'FREE_PRINTING') {
    discount = subtotal;
    // 邮费由 USD 转化为订单原始币种
    shipping = S.toFixedNumber(divide(50, currency), 2);
    total = shipping;
  } else if (coupon.type === 'FREE_PRINTING_FOR_REFERRAL') {
    discount = subtotal;
    total = shipping;
  } else if (coupon.type === 'FREE_PRINTING_METAL') {
    discount = subtotal;
    total = shipping;
  } else if (coupon.type === 'FREE_PRINTING_FOR_KIRI') {
    const couponAmountOff = S.toFixedNumber(divide(50, currency), 2);

    discount = couponAmountOff > subtotal ? subtotal : couponAmountOff;
    total = subtotal + shipping - discount;
  }

  return { ...price, discount, total, shipping };
}

// 计算订单支付价格（不包含优惠券）
function calcOrderNakedPaymentPrice(s: Store): S.OrderPaymentPrice | undefined {
  const { order, shipOptionDraft, quotation } = s.get();

  if (!order) {
    console.warn('calcOrderNakedPaymentPrice: illegal state, no order given');
    return;
  }

  const { price, customerShipOption } = order;

  const {
    postage: orderPostage,
    totalPriceWithTax: total,
    shipExpressQuotations: shipQuotations = [],
  } = price;

  const shipOption = shipOptionDraft || customerShipOption;

  let postage, nonPostage;

  if (!shipQuotations) {
    // 未给定按物流的报价，直接使用邮费一口价报价
    postage = orderPostage;
    nonPostage = total - postage;
  } else {
    const expressQuotation = (shipQuotations || []).find(v => {
      const shipOptionExpressId = S.get(shipOption, s => s.expressId, '');
      return v.expressId === shipOptionExpressId;
    });

    /** 物流报价是否完成 */
    if (S.get(quotation, q => q.shipQuotationsCalculating)) {
      postage = undefined;
      nonPostage = total - orderPostage;
    } else {
      postage =
        expressQuotation?.quotation != null
          ? expressQuotation?.quotation
          : expressQuotation?.requiresManualQuote != null &&
            !expressQuotation.requiresManualQuote
          ? expressQuotation.systemQuotation
          : undefined;
      nonPostage = total - orderPostage;
    }
  }

  let finalTotal: number | undefined = undefined;
  if (typeof nonPostage == 'number' && typeof postage == 'number') {
    finalTotal = nonPostage + postage;
  }

  return {
    subtotal: nonPostage,
    shipping: postage,
    discount: 0,
    total: finalTotal,
  };
}

async function loadQuotation(s: Store, order?: S.InquiryOrder) {
  const finalOrder = order || s.get().order;

  if (finalOrder.code) {
    const quotation = await fetchOrderQuotation(finalOrder.code);

    if (order?.status != null && S.isOrderQuotedStatus(order.status)) {
      quotation.requiresManualQuote = false;
      quotation.shipQuotationsCalculating = false;
      quotation.subtotalRequiresManualQuote = false;
      quotation.shipQuotationsRequiresManualQuote = false;
      quotation.shipExpressQuotations =
        (order.price as any).shipExpressQuotations || [];
    }
    s.set({ quotation });
  } else {
    console.error('illegal state - loadQuotation: order not found', order);
  }
}

async function doUpdateOrderDeliverAddress(s: Store, addr: S.ReceiveAddress) {
  const { order, loadOrder } = s.get();
  if (order && order.code) {
    try {
      if (!order.deliver) {
        order.deliver = new S.Deliver({});
      }
      order.deliver.deliverAddress = [
        convertReceiveAddressToDeliverAddress(addr),
      ];

      const resp = await updateInquiryOrderByCodeForOverseasCustomer(
        order.code,
        order,
      );

      resp && (await loadOrder(order.code));
    } catch (_e) {
      console.log('>>>doUpdateOrderDeliverAddress>>>', _e);
    }
  }
}

async function saveShipOption(s: Store) {
  const { shipOptionDraft, order, loadOrder } = s.get();
  if (!order) {
    console.warn('saveShipOption: illegal state, order not found');
    return;
  }

  if (shipOptionDraft) {
    await updateCustomerShipOption(order.code, shipOptionDraft);
    await loadOrder(order.code);
  }
}

async function saveRemarkText(s: Store) {
  const { order, remarkText } = s.get();
  if (!order) {
    console.warn('saveShipOption: illegal state, order not found');
    return;
  }
  if (remarkText != null) {
    await updateCustomerOrderRemark(order.code, remarkText);
  }
}

function setPaymentChannel(s: Store, ch: PaymentChannel) {
  s.set({ paymentChannel: ch });

  /** 切换付款方式更新 can pay */
  updateAgreeTermOfService(s, s.get().agreeTermOfService);
}

function checkCanOrderAutoConfirm(order?: S.InquiryOrder): boolean {
  if (!order) return false;

  return order.status === 'WAIT_REVIEW' && order.canAutoQuote;
}
