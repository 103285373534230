import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Image, Radio } from 'antd';

import PaypalLogo from '@/assets/svgs/paypal-logo.svg';
import { useInquiryQuoteCheckoutStore } from '@/stores';

import { QuotePaymentTips } from '../../../../../components/info/QuotePaymentTips';
import { OrderOceanPaymentForm } from '../../OrderPaymentCheckoutPage/OrderPaymentPaySubmitters/OrderOceanpayment';
import styles from './index.module.less';
import { BillInfoEditor } from './OceanpayBillingAddress';

export const OrderPaymentOptions = ({
  oceanpaymentRef,
}: {
  oceanpaymentRef: any;
}) => {
  const {
    paymentChannel,
    setPaymentChannel,
    order,
  } = useInquiryQuoteCheckoutStore();

  return (
    <div className={styles.paymentOptions}>
      <Radio.Group
        className={styles.paymentMethods}
        onChange={e => setPaymentChannel(e.target.value)}
        value={paymentChannel}
      >
        {order && ['USD', 'EUR'].includes(order.currency) && (
          <Radio value={'paypal'}>
            <div className={styles.paymentMethodItem}>
              <span>{i18nFormat('PayPal & Credit Card')}</span>
              <QuotePaymentTips paymentChannel="paypal" />
              <span className={styles.paypalLogo}>
                <PaypalLogo />
                <span className={styles.visaLogo}>
                  <Image
                    width={120}
                    preview={false}
                    src="https://ufc-oversea.oss-accelerate.aliyuncs.com/uoms/images/payment methods_1.png"
                  />
                </span>
              </span>
            </div>
          </Radio>
        )}

        <Radio value={'bank-transfer'}>
          <div className={styles.paymentMethodItem}>
            <span>{i18nFormat('Bank Transfer')}</span>
            <QuotePaymentTips paymentChannel="bank-transfer" />
          </div>
        </Radio>
        <Radio value={'oceanpay'}>
          <div className={styles.paymentMethodItem}>
            <span>{i18nFormat('Credit Card')}</span>
            <QuotePaymentTips paymentChannel="oceanpay" />
            <span className={styles.oceanpayLogo}>
              <Image
                width={120}
                preview={false}
                src="https://ufc-oversea.oss-accelerate.aliyuncs.com/uoms/images/payment methods_1.png"
              />
            </span>
          </div>
          <div className={styles.paymentMethodOptions}>
            <span
              style={{
                display: paymentChannel === 'oceanpay' ? 'block' : 'none',
              }}
            >
              {order && (
                <OrderOceanPaymentForm oceanpaymentRef={oceanpaymentRef} />
              )}

              <span className={styles.payBillInfoContainer}>
                <BillInfoEditor />
              </span>
            </span>
          </div>
        </Radio>
      </Radio.Group>
    </div>
  );
};
