import { wrapSingletonAsyncFunc } from '@m-fe/ts-async';

import * as S from '../../../../schema';
import { getUfcShopComposedApi } from '../../../../singleton-ins';

const getHandleMethodCache: Record<string, S.HandleMethod[]> = {};

/** 获取后处理方式  */
export async function getHandleMethod(tenantId: S.Id) {
  if (getHandleMethodCache[tenantId]) {
    return getHandleMethodCache[tenantId];
  }

  const { data } = await wrapSingletonAsyncFunc<
    boolean,
    { data: S.HandleMethod[] }
  >(
    async () => {
      return await getUfcShopComposedApi().umiRequestCompatibleApi.getUmi<{
        data: S.HandleMethod[];
      }>(`/inquiry/handle_method`, {
        params: { tenantId },
      });
    },
    { fnKey: 'getHandleMethod', serialize: () => 'getHandleMethod' },
  )();

  getHandleMethodCache[tenantId] = data;

  return data;
}
