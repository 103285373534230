/**
 * @param caseInsensitive  默认 true
 * @param val 需要被高亮的字符串
 */
export function highlight(
  text: string,
  val?: string,
  caseInsensitive?: boolean,
): { text: string; highlight: boolean }[] {
  if (caseInsensitive == null) {
    caseInsensitive = true;
  }

  if (!val || val.length === 0 || !matches(text, val, caseInsensitive)) {
    return [{ text, highlight: false }];
  } else {
    return createHighlight(text, val);
  }
}

function matches(text: string, val: string, caseInsensitive: boolean) {
  if (caseInsensitive) {
    return text.toLowerCase().indexOf(val.toLowerCase()) !== -1;
  } else {
    return text.indexOf(val) !== -1;
  }
}

function createHighlight(text: string, val: string) {
  const ss = text.toLowerCase().split(val.toLowerCase());
  const valLen = val.length;

  const res = [];

  let curIdx = 0;

  for (const s of ss) {
    res.push({
      text: text.substring(curIdx, curIdx + s.length),
      highlight: false,
    });
    curIdx += s.length;

    if (curIdx < text.length) {
      res.push({
        text: text.substring(curIdx, curIdx + valLen),
        highlight: true,
      });
      curIdx += valLen;
    }
  }

  if (curIdx < text.length) {
    res.push({
      text: text.substring(curIdx, curIdx + valLen),
      highlight: true,
    });
  }

  return res;
}
