import { isNil } from 'lodash-es';

import { BaseEntity } from '../../../common';
import { Address, File, Id } from '../../../common';
import { getCustomerShortName, SourceType } from '../../../crm';
import { isValidArray } from '../../../utils';
import { DeliverAddress } from './DeliverAddress';
import { DeliverMethod } from './delivery-types';

export class Deliver extends BaseEntity<Deliver> {
  /** 客户名称 @reference InquiryOrder.customerName */
  customerName: string;

  /** 获取简写的客户名 */
  get customerShortName() {
    return getCustomerShortName(this.customerName);
  }

  /** 客户代表 */
  representativeName: string;
  /** 客户代表联系方式 */
  representativeContactInfo: string;
  /** 客户代表联系方式 */
  representativePersonId?: Id;
  /** 客户公司地址 */
  address: string;

  /** 发货图片 ID */
  deliverPicIds: Id[];
  deliverPictures: File[];

  /** 发货日期 */
  deliveredAt: string;
  // 预计运送时间(天)
  // transportDays: number;

  /**
   * 最晚交期
   * @type {string}
   * @memberof Deliver
   */
  lastDeliveredAt: string;

  /** 客户收货地址 */
  deliverAddress: DeliverAddress[];

  /**
   * 收货码
   * @type {string}
   * @memberof Deliver
   */
  receiveCode: string;

  /** 收货凭证 */
  receiptCertificate?: Id[];

  /** 发货人联系电话 */
  senderPhoneNumber: string;

  get isFillComplete() {
    let errorMsg;

    if (!this.customerName) {
      errorMsg = '客户名称为空，请选择客户';
    }

    // if (!this.representativeContactInfo) {
    //   errorMsg = '该客户代表暂无联系方式，请先添加客户代表联系方式';
    // }

    // if (!this.representativeName) {
    //   errorMsg = '该客户暂无客户代表，请先添加客户代表';
    // }

    if (!this.lastDeliveredAt) {
      errorMsg = '请选择最晚交期';
    }

    const isFillRequired =
      !!this.customerName &&
      // !!this.representativeName &&
      // !!this.representativeContactInfo &&
      !!this.lastDeliveredAt;

    return { isFillRequired, errorMsg };
  }

  constructor(data?: Partial<Deliver>) {
    super(data);

    Object.assign(this, data);

    if (!this.deliverAddress) {
      this.deliverAddress = [];
    }

    if (isValidArray(this.deliverAddress)) {
      this.deliverAddress = this.deliverAddress.map(d => {
        let senderPhoneNumber;

        if (!isNil(d.senderPhoneNumber)) {
          senderPhoneNumber = d.senderPhoneNumber;
        } else {
          senderPhoneNumber = this.senderPhoneNumber;
        }

        return new DeliverAddress({ ...d, senderPhoneNumber });
      });
    }
  }
}

export interface DeliverRecordUser {
  id: string;
  tenantId: string;
  personId: string;
  username: string;
  nickname: string;
  person: {
    id: string;
    username: string;
    nickname: string;
    phoneNumber: string;
    email: string;
    source: SourceType;
  };
}

/** 发货详情文件记录项 */
export interface DeliverRecordDetailFileItem {
  fileItemId: string;
  fileId: string;
  count: number;
}

export interface DeliverRecordExpressNumber {
  // 发货地址 ID
  orderDeliverAddressId: string;
  // 发货地址
  deliverAddress: Address;
  // 物流单号
  expressNumber: string;
}

/** 发货记录 */
export class DeliverRecord extends BaseEntity<DeliverRecord> {
  /** 发货记录 ID */
  deliverRecordId: string;
  // 发货记录 撤销时间
  revokeTime: string;
  // 发货记录 撤销人
  revocator: DeliverRecordUser;
  // 发货日期
  deliverAt: string;
  // 发货人
  deliverer: DeliverRecordUser;
  expressNumbers: DeliverRecordExpressNumber[];
  // 发货项目
  deliverDetailFileItems: DeliverRecordDetailFileItem[];

  constructor(data?: Partial<DeliverRecord>) {
    super(data);

    Object.assign(this, data);
  }
}

// /** 发货信息 */
export declare class DeliverInfo extends BaseEntity<DeliverInfo> {
  fileItemDeliveredCount: Record<string, number>;
  isSupplyChain: boolean;
  singleDeliver: boolean;
  deliverMethod: DeliverMethod;
  constructor(data?: Partial<DeliverInfo>);
}
