import { create, StoreApi, UseBoundStore } from 'zustand';
import { devtools } from 'zustand/middleware';

import { getShopExpresses } from '../../apis';
import * as S from '../../schema';

interface ShopExpressStoreState {
  shopExpresses: S.ShopExpress[];

  loadShopExpresses: (country: string) => void;
}

export const useShopExpressStore: UseBoundStore<
  StoreApi<ShopExpressStoreState>
> = create<ShopExpressStoreState>()(
  devtools(
    (set, get) => {
      const loadShopExpresses = async country => {
        const resp = await getShopExpresses(country);

        set({ shopExpresses: resp });
      };

      return {
        shopExpresses: [],

        loadShopExpresses,
      };
    },
    {
      name: 'shopExpressStore',
    },
  ),
);
