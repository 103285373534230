import { QuestionCircleOutlined } from '@ant-design/icons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Tooltip } from 'antd';

export const ShopQuoteMinOrderFeeTips = () => {
  return (
    <Tooltip
      title={i18nFormat(
        'The production cost of your models is currently under Unionfab’s production cost   minimum. If you would like to optimize for the best price, you can add more models to your order, increase the quantity of your existing models or simply proceed with it as is.',
      )}
    >
      <QuestionCircleOutlined style={{ color: '#192C59', marginLeft: 4 }} />
    </Tooltip>
  );
};

ShopQuoteMinOrderFeeTips.displayName = 'ShopQuoteMinOrderFeeTips';
