import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat, UnionfabEventTracker } from '@unionfab/ufc-shop-commons';
import { Form, Input, Modal, Radio } from 'antd';
import cn from 'classnames';
import { useState } from 'react';

import { QuoteFinishSelectModal } from '@/features/new_quote/components/select/QuoteFinishSelect/QuoteFinishSelectModal';
import { useInquiryQuoteEditorStore } from '@/stores';
import { useInquiryMaterialStore } from '@/stores';

import { InquiryMaterialSkuSelect } from '../../../../components/select/InquiryMaterialSkuSelect';
import styles from './index.module.less';

export interface QuoteOrderItemBulkConfigurationProps {
  className?: string;
  style?: Record<string, string | number>;

  children?: React.ReactNode;
}

export const QuoteOrderItemBulkConfiguration = ({
  className,
  style,

  children,
}: QuoteOrderItemBulkConfigurationProps) => {
  const {
    changeTechNameInInquiryQuoteEditorStore,
    technology,
    curTechCategoryName,
    updateInquiryOrder,
    modelFileIdsToEdit,
  } = useInquiryQuoteEditorStore();

  const { materialTypes: inquiryMaterialTypes } = useInquiryMaterialStore();

  const [
    selectedMaterialSpu,
    setMaterialSku,
  ] = useState<S.InquiryMaterialSku>();
  const [selectedFinishMethod, setFinishMethod] = useState('');

  const [
    isBulkConfigurationModalVisible,
    setIsBulkConfigurationModalVisible,
  ] = useState(false);
  const [isFinishSelectModalVisible, setIsFinishSelectModalVisible] = useState(
    false,
  );

  const onApply = () => {
    // 判断是否选择了材料
    if (selectedMaterialSpu) {
      updateInquiryOrder('changeItemMaterialSpu', order => {
        const items = order.items.filter(item => {
          if (S.isValidArray(modelFileIdsToEdit)) {
            return modelFileIdsToEdit.includes(item.modelFiles[0].id);
          }

          return true;
        });

        items.forEach(item => {
          item.materialSpu = selectedMaterialSpu;
          item.spuId = item.materialSpu?.id as S.Id;
        });
      });

      UnionfabEventTracker.getIns().addUserEventRecord({
        typeName: 'bulk_choose_material',
      });
    }

    if (selectedFinishMethod) {
      updateInquiryOrder('changeItemFinish', order => {
        const items = order.items.filter(item => {
          if (S.isValidArray(modelFileIdsToEdit)) {
            return modelFileIdsToEdit.includes(item.modelFiles[0].id);
          }

          return true;
        });

        items.forEach(item => {
          item.printFiles[0].handle = {
            method:
              selectedFinishMethod && selectedFinishMethod !== 'Standard'
                ? [selectedFinishMethod]
                : [],
            desc: '',
          };
        });
      });

      UnionfabEventTracker.getIns().addUserEventRecord({
        typeName: 'bulk_choose_material',
      });
    }

    setIsBulkConfigurationModalVisible(false);
  };

  const renderFinishSelect = () => {
    const curMaterialType = S.get(inquiryMaterialTypes, i =>
      i.find(m => selectedMaterialSpu.materialTypeId === m.id),
    );

    return (
      <Form.Item label={i18nFormat('Finish')}>
        <Input
          value={selectedFinishMethod}
          placeholder={i18nFormat('Select a finish to bulk set')}
          allowClear={selectedFinishMethod !== 'Standard'}
          onClick={() =>
            setIsFinishSelectModalVisible(!isFinishSelectModalVisible)
          }
        />
        <QuoteFinishSelectModal
          visible={isFinishSelectModalVisible}
          materialTypeId={curMaterialType?.id}
          toggleVisible={() =>
            setIsFinishSelectModalVisible(!isFinishSelectModalVisible)
          }
          onChange={({
            finishMethod,
            color,
          }: {
            finishMethod: string;
            color: S.HandleMethodColor;
          }) => {
            setFinishMethod(finishMethod);
          }}
        />
      </Form.Item>
    );
  };

  const body = (
    <div>
      <Form name="basic" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
        <Form.Item label={i18nFormat('Service')}>
          <Radio.Group
            value={curTechCategoryName}
            options={S.getBuiltinTechCategoryList().map(techCategory => {
              return {
                label: i18nFormat(techCategory.displayName),
                value: techCategory.name,
              };
            })}
            onChange={e =>
              changeTechNameInInquiryQuoteEditorStore(e.target.value)
            }
          />
        </Form.Item>
        <Form.Item label={i18nFormat('Material')}>
          <InquiryMaterialSkuSelect
            filterOptions={{ techCategory: technology }}
            onChange={(_, { materialSku }) => {
              setMaterialSku(materialSku);
            }}
          />
        </Form.Item>
        {selectedMaterialSpu && renderFinishSelect()}
      </Form>
    </div>
  );

  return (
    <div
      id="QuoteOrderItemBulkConfiguration"
      className={cn(className, styles.container)}
      style={style}
    >
      <Modal
        title={i18nFormat('Bulk Configuration')}
        open={isBulkConfigurationModalVisible}
        okText={i18nFormat('Apply')}
        cancelText={i18nFormat('Cancel')}
        onOk={onApply}
        onCancel={() => {
          setIsBulkConfigurationModalVisible(false);
        }}
      >
        {body}
      </Modal>
      <div
        onClick={() => {
          setIsBulkConfigurationModalVisible(true);
        }}
      >
        {children}
      </div>
    </div>
  );
};

QuoteOrderItemBulkConfiguration.displayName = 'QuoteOrderItemBulkConfiguration';
