import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Ellipsis } from '../../../../commons/components/Element/ellipsis';

export const OrderCheckoutButton = ({
  className,
  code,
  status,
  hasAddress,
}: {
  className?: string;
  code: string;
  hasAddress: boolean;
  status: S.InquiryOrderStatus;
}) => {
  const nav = useNavigate();

  const canCheckout =
    status === 'WAIT_SUBMIT' ||
    status === 'WAIT_REVIEW' ||
    status === 'WAIT_CUSTOM_CONFIRM';

  if (!canCheckout) return <></>;

  return (
    <Button
      onClick={() => {
        if (hasAddress) {
          nav(`/new_shop_quote/${code}/checkout`);
        } else {
          nav(`/new_shop_quote/${code}`);
        }
      }}
      type="primary"
      className={className}
    >
      <Ellipsis maxWidth={88}>
        {i18nFormat(status == 'WAIT_CUSTOM_CONFIRM' ? 'Checkout' : 'Continue')}
      </Ellipsis>
    </Button>
  );
};

OrderCheckoutButton.displayName = 'OrderCheckoutButton';
