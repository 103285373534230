import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Button } from 'antd';

import { useInquiryQuoteEditorStore } from '@/stores';

export const OrderItemEditBtn = ({
  orderItem,
}: {
  orderItem: S.InquiryOrderItem;
}) => {
  const {
    editingFileIds,
    toggleOrderItemFileEditing: toggleOrderItemCardEditing,
  } = useInquiryQuoteEditorStore();

  const editing = !!editingFileIds.find(
    v => v === orderItem.printFiles[0].fileId,
  );

  const toggle = () => toggleOrderItemCardEditing(!editing, orderItem);

  return (
    <>
      {editing && (
        <Button type="link" onClick={toggle}>
          {i18nFormat('Save')}
        </Button>
      )}

      {!editing && (
        <Button type="link" onClick={toggle}>
          {i18nFormat('Edit')}
        </Button>
      )}
    </>
  );
};
