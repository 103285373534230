import * as S from '../../../../schema';
import { getTenantIdFromGConfig } from '../../../../singleton-ins';
import { BaseAxiosApi } from '../../shared';

export class UserApi extends BaseAxiosApi {
  /** @deprecated 获取账号信息 */
  async getShopAccountInfo(): Promise<S.ShopAccountInfo> {
    const { data } = await this.get<S.ShopAccountInfo>({
      url: `${this.apiConfig.apiEndpoint}/v2/shop/account/me`,
      params: { tenantId: getTenantIdFromGConfig() },
    });

    return new S.ShopAccountInfo(data);
  }
  /** 获取账号信息 */
  async getAccountProfileInfo(): Promise<S.AccountInfo> {
    const { data } = await this.get<S.AccountInfo>({
      url: `${this.apiConfig.apiEndpoint}/v2/shop/account/me/profile`,
      params: { tenantId: getTenantIdFromGConfig() },
    });

    return new S.AccountInfo(data);
  }

  /**
   * @param returnType  representation 返回值为更新后的 AccountInfo，minimal 返回值为 status
   */
  async updateAccountInfo(
    accountInfo: Partial<S.AccountInfo>,
    returnType: 'minimal' | 'representation' = 'minimal',
  ) {
    const { status, data } = await this.post<S.AccountInfo>({
      url: `${this.apiConfig.apiEndpoint}/v2/shop/account/me/profile`,
      data: accountInfo,
      params: { tenantId: getTenantIdFromGConfig(), return: returnType },
    });

    if (returnType == 'representation') return data;

    return status == 'ok';
  }

  /** 获取账号优惠券信息 */
  async getAccountCoupon({
    type,
    status,
  }: {
    type?: S.InquiryCouponType[];
    status?: S.InquiryCouponStatus[];
  } = {}): Promise<S.InquiryCoupon[]> {
    const { data } = await this.get<S.InquiryCoupon[]>({
      url: `${this.apiConfig.apiEndpoint}/v2/shop/account/coupon`,
      params: { tenantId: getTenantIdFromGConfig(), status, type },
    });

    return (data || []).map(c => new S.InquiryCoupon(c));
  }

  /** 获取账号用户推广信息 */
  async getAccountReferralInfo(): Promise<S.ReferralStatistics> {
    const { data } = await this.get<S.ReferralStatistics>({
      url: `${this.apiConfig.apiEndpoint}/v2/shop/account/referral/statistics`,
      params: { tenantId: getTenantIdFromGConfig() },
    });

    return data;
  }

  /** 获取账号收货地址 */
  async getAccountAddresses(): Promise<S.ReceiveAddress[]> {
    const { data } = await this.get<S.ReceiveAddress[]>({
      params: { tenantId: getTenantIdFromGConfig() },
      url: `${this.apiConfig.apiEndpoint}/v2/shop/account/addresses`,
    });

    return data;
  }

  /** 更新账号收货地址 */
  async upadteAccountAddresses(
    addresses: S.ReceiveAddress[],
    returnType: 'minimal' | 'representation' = 'minimal',
  ) {
    const { status, data } = await this.post<S.ReceiveAddress[]>({
      data: addresses,
      params: { return: returnType },
      url: `${this.apiConfig.apiEndpoint}/v2/shop/account/addresses`,
    });

    if (returnType == 'representation') {
      return (data || []).map(d => new S.ReceiveAddress(d));
    }

    return status == 'ok';
  }
}
