import { LeftOutlined } from '@ant-design/icons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { useEffect } from 'react';

import { useAppNavigate } from '@/features/shared';
import { useInquiryQuoteCheckoutStore } from '@/stores';

import { OrderBankTransferDetails } from '../../../../../components/pay/bank-transfer/OrderBankTransferDetails';
import styles from './index.module.less';

export const OrderBankTransferPage = () => {
  const nav = useAppNavigate();
  const {
    order,
    paymentPrice,
    userEmail,
    loadUserEmail,
  } = useInquiryQuoteCheckoutStore();

  // 目前 subtotal 即材料费
  const total = paymentPrice?.total;

  useEffect(() => {
    loadUserEmail();
  }, [loadUserEmail]);

  useEffect(() => {
    if (!order || total == null) {
      nav.navigateToHome();
    }
  }, [loadUserEmail, nav, order, total]);

  if (!order || total == null) {
    console.warn('illegal state');
    return <></>;
  }

  return (
    <div className={styles.bankTransferPage}>
      <OrderBankTransferDetails
        code={order.code!}
        total={total}
        currency={order.currency}
        userEmail={userEmail}
      />
      <div
        className={styles.backToCheckout}
        onClick={() => nav.navigateToOrderPayment(order.code)}
      >
        <LeftOutlined />
        {i18nFormat('Change payment method')}
      </div>
    </div>
  );
};
