import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { BaseEntity } from '../../common';

export class ShopExpress extends BaseEntity<ShopExpress> {
  /** 物流名称 */
  name: string;

  /** 物流时效 */
  leadTime: Record<'min' | 'max', number> = { min: 0, max: 0 };

  /** 限制条件 */
  restrictionDescription: string;

  get deliveryStr() {
    const { leadTime } = this;

    return `${leadTime.min || 0} - ${leadTime.max || 0} ${i18nFormat('Business days')}`;
  }

  constructor(props?: Partial<ShopExpress>) {
    super(props);

    Object.assign(this, props);
  }
}
