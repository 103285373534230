import { groupBy } from 'lodash-es';
import { create } from 'zustand';
import { devtools, NamedSet } from 'zustand/middleware';

import {
  getHandleMethodByMaterialType,
  getInquiryMaterialsByQuotationGroup,
  getTenantIdFromGConfig,
  getUfcShopComposedApi as apis,
} from '../../apis';
import {
  compareBySequence,
  MATERIAL_SHOW_ORDERS,
  MATERIAL_TYPE_SHOW_ORDERS,
  TechCategoryType,
} from '../../schema';
import * as S from '../../schema';

export interface IInquiryMaterialStore {
  quotationGroupId?: string;
  materialTypes: S.InquiryMaterialType[];
  materials: S.InquiryMaterial[];
  materialTypeIdToHandleMethods: Record<string, string[]>;

  techTypeToMaterials: Record<Partial<TechCategoryType>, S.InquiryMaterial[]>;

  loadMaterialTypes?: () => Promise<void>;
  loadMaterialTypeHandleMethods?: (ids: S.Id[]) => Promise<void>;
  loadMaterials?: (groupId: string) => Promise<void>;
}

export const useInquiryMaterialStore = create<IInquiryMaterialStore>()(
  devtools(
    (set: NamedSet<IInquiryMaterialStore>, get) => {
      const loadMaterialTypes = async () => {
        let { materialTypes } = get();

        if (materialTypes.length === 0) {
          materialTypes = await apis().shopMaterialQueryApi.queryShopMaterialTypes();

          set({ materialTypes }, false, 'loadMaterialTypes');
        }

        // 默认加载首个后处理工艺
        if (S.isValidArray(materialTypes)) {
          loadMaterialTypeHandleMethods([materialTypes[0].id!]);
        }
      };

      const loadMaterialTypeHandleMethods = async (
        inquiryMaterialTypeIds: S.Id[],
      ) => {
        const { materialTypeIdToHandleMethods } = get();

        const finalHandleMethodMap = { ...materialTypeIdToHandleMethods };

        for (const id of inquiryMaterialTypeIds) {
          let methods = [];

          if (S.isValidArray(materialTypeIdToHandleMethods[id])) {
            methods = materialTypeIdToHandleMethods[id];
          } else {
            methods = await getHandleMethodByMaterialType(
              id,
              getTenantIdFromGConfig(),
            );
          }

          materialTypeIdToHandleMethods[id] = methods || [];
        }

        set(
          prev => ({
            materialTypeIdToHandleMethods: finalHandleMethodMap,
          }),
          false,
          'loadMaterialTypeHandleMethods',
        );
      };

      const loadMaterials = async (groupId: string) => {
        let { materials } = get();
        const { quotationGroupId } = get();

        if (materials.length === 0 || quotationGroupId != groupId) {
          materials = await getInquiryMaterialsByQuotationGroup({
            groupId: groupId as any,
            params: {
              tenantId: getTenantIdFromGConfig(),
              withMaterialTypeInfo: true,
            },
          });

          const techTypeToMaterials = getTechCollectionTypeToMaterials(
            materials,
          );

          set(
            {
              quotationGroupId: groupId,
              materials: materials,
              techTypeToMaterials,
            },
            false,
            'loadInquiryMaterials',
          );
        }
      };

      return {
        materialTypes: [] as S.InquiryMaterialType[],
        materials: [] as S.InquiryMaterial[],
        materialTypeIdToHandleMethods: {},
        techTypeToMaterials: {} as Record<
          TechCategoryType,
          S.InquiryMaterial[]
        >,

        loadMaterialTypes,
        loadMaterialTypeHandleMethods,
        loadMaterials,
      };
    },
    { name: 'materialStore' },
  ),
);

function getTechCollectionTypeToMaterials(materials: S.InquiryMaterial[]) {
  const res = groupBy(materials, material => {
    const typeName = (material.materialType.categoryName || '').toUpperCase();
    if (typeName.includes('CNC')) {
      return 'CNC' as TechCategoryType;
    } else {
      return '3DPRINT' as TechCategoryType;
    }
  });

  return {
    CNC: (res['CNC'] || []).sort(compareMaterial),
    '3DPRINT': (res['3DPRINT'] || []).sort(compareMaterial),
    VACUUM_CASTING: [],
  };
}

function compareMaterial(a: S.InquiryMaterial, b: S.InquiryMaterial) {
  if (a?.materialType?.name && b?.materialType?.name) {
    const r = compareBySequence(
      a.materialType.name,
      b.materialType.name,
      MATERIAL_TYPE_SHOW_ORDERS,
    );
    if (r !== 0) {
      return r;
    }
  }

  return compareBySequence(a.name, b.name, MATERIAL_SHOW_ORDERS);
}
