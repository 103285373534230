export const CurrencySymbolMap = {
  USD: '$',
  EUR: '€',
  CNY: '¥',
  GBP: '£',
  JPY: '¥',
  RUB: '₽',
  CAD: 'C$',
  AUD: 'A$',
  BRL: 'R$',
  SGD: 'S$',
  MXN: 'Mex$',
} as const;

export const getSupportedCurrencyList = (): string[] => {
  return [
    'USD $',
    'GBP £',
    'EUR €',
    'JPY ¥',
    'RUB ₽',
    'CAD C$',
    'AUD A$',
    'BRL R$',
    'SGD S$',
    'MXN Mex$',
  ];
};

/** 支持的货币类型 */
export type CurrencySymbolType = keyof typeof CurrencySymbolMap;

export interface CurrencyAndAmount {
  currency: CurrencySymbolType;
  amount: number;
}

/** 获取给定货币类型的单位 */
export function getCurrencySymbolByType(type: CurrencySymbolType): string {
  return CurrencySymbolMap[type];
}

/** 根据订单获取价格单位描述 */
// FIXME 逻辑有点混用了
export const getOrderCurrency = (currency: string): string => {
  return (
    getSupportedCurrencyList()
      .find(el => el.includes(currency))
      ?.split(' ')[1] || '$'
  );
};
