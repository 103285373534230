import { loadScript } from '../../../schema/utils/env/load-assets';
import { IsomorphicLocalStorage } from '../../../skeleton/env/local-storage-utils';

export interface ClientGeoInfo {
  ip: string;
  country: string;
  country_code: string;
  city: string;
}

let _clientGeoInfoHolder: ClientGeoInfoApi;

export class ClientGeoInfoApi {
  geoInfo: Partial<ClientGeoInfo> = {};

  static getIns() {
    if (!_clientGeoInfoHolder) {
      _clientGeoInfoHolder = new ClientGeoInfoApi();
    }

    return _clientGeoInfoHolder;
  }

  async init() {
    // 首先加载本地数据
    if (IsomorphicLocalStorage.hasItem('client-geo-info')) {
      this.geoInfo = IsomorphicLocalStorage.getItem('client-geo-info');
    }

    // 通过本地其他数据补齐
    await this.queryGeoInfoByIpApi();

    if (this.geoInfo.country) {
      // 写入到本地
      IsomorphicLocalStorage.setItem('client-geo-info', this.geoInfo);
    }
  }

  /** 根据需要建立 GPDR 的 Cookie 选择 */
  async setupGpdrConsent() {
    if (!this.geoInfo.country) {
      await this.init();
    }

    // 判断国家是否需要启用 GPDR
    const gpdrCountryCodes = [
      'CN',
      'BE',
      'ES',
      'HU',
      'SK',
      'BG',
      'FR',
      'MT',
      'FI',
      'CZ',
      'HR',
      'NL',
      'SE',
      'DK',
      'IT',
      'AT',
      'DE',
      'CY',
      'PL',
      'IS',
      'EE',
      'LV',
      'PT',
      'LI',
      'IE',
      'LT',
      'RO',
      'NO',
      'EL',
      'LU',
      'SI',
      'UK',
    ];

    if (gpdrCountryCodes.includes(this.geoInfo.country_code)) {
      loadScript('cookie-consent', '/cookieconsent-config.js?t=4', 'module');
    }
  }

  /**
   * 获取当前的 IP 地址
   * $ curl 'https://api.ipify.org?format=json'
   */
  async queryCurrentIp() {}

  async queryGeoInfoByUnionfab() {}

  /** 从 IpApi 从获取到 IP 信息 */
  async queryGeoInfoByIpApi() {
    try {
      const resp = await (await fetch('https://ipapi.co/json/')).json();

      this.geoInfo.ip = resp.ip;
      this.geoInfo.country = resp.country;
      this.geoInfo.country_code = resp.country_code;
      this.geoInfo.city = resp.city;
    } catch (_) {
      console.error('>>>queryGeoInfoByIpApi error:', _);
    }
  }

  ipToAliyunGeoInfoCache = {};
  // curl -i -k --get --include 'https://c2ba.api.huachen.cn/ip?ip=218.18.228.178'  -H 'Authorization:${appCode}'
  async queryGeoInfoByAliyun(ip: string, appCode: string) {
    try {
      if (this.ipToAliyunGeoInfoCache[ip]) {
        return this.ipToAliyunGeoInfoCache[ip];
      }

      const resp = await (
        await fetch(`https://c2ba.api.huachen.cn/ip?ip=${ip}`, {
          headers: {
            Authorization: appCode,
          },
        })
      ).json();

      this.ipToAliyunGeoInfoCache[ip] = resp.data;

      return resp.data;
    } catch (_) {
      return {};
    }
  }
}
