export * from './QuoteEditor/QuoteSubmitPage';

import { memo } from 'react';
import { Outlet } from 'react-router-dom';

import { useOrderOfferingsLoad } from '../../hooks/useOrderOfferingsLoad';

export const QuoteEditorContainer = memo(() => {
  useOrderOfferingsLoad();

  return <Outlet />;
});
