import { fetchStates, i18nFormat } from '@unionfab/ufc-shop-commons';
import * as S from '@unionfab/ufc-shop-commons';
import { AutoComplete } from 'antd';
import { orderBy } from 'lodash-es';
import { useEffect, useState } from 'react';

export interface ProvinceSelectProps {
  // 当前国家
  countryIso2?: string;
  province?: string;
  onProvinceChange: (v: string) => void;
}

export const ProvinceSelect = ({
  countryIso2,
  province,
  onProvinceChange,
}: ProvinceSelectProps) => {
  // 当前选中国家的州、省列表
  const [states, setStates] = useState<S.Country[]>([]);

  useEffect(() => {
    // 加载当前选中国家可用州、省
    if (countryIso2) {
      fetchStates(countryIso2).then(setStates);
    }
  }, [countryIso2]);

  const provinceNameStyle = {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  };

  return (
    <AutoComplete
      allowClear
      placeholder={i18nFormat('State')}
      value={province}
      onChange={onProvinceChange}
    >
      {orderBy(states || [], 'name').map(value => (
        <AutoComplete.Option key={'state-' + value.name} value={value.name}>
          <div style={provinceNameStyle}>
            <span>{value.name}</span>
          </div>
        </AutoComplete.Option>
      ))}
    </AutoComplete>
  );
};
