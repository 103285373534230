import * as S from '../../../../schema';
import {
  getTenantIdFromGConfig,
  getUfcShopComposedApi,
} from '../../../../singleton-ins';

/**
 * 获取历史上报记录
 * - map Record<CustomerId, InquirySurvey[]>
 */
export async function getCustomerSurveyHistory({
  customerId,
  type,
  tenantId,
}: {
  type: string[];
  customerId?: string[];
  tenantId?: S.Id;
}) {
  const {
    data,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.getUmi<{
    data: Record<S.Id, S.InquirySurvey[]>;
  }>(`/inquiry/customer/survey`, {
    params: {
      tenantId: tenantId || getTenantIdFromGConfig(),
      type,
      customerId,
    },
  });

  const res: Record<S.Id, S.InquirySurvey[]> = {} as Record<
    S.Id,
    S.InquirySurvey[]
  >;

  Object.keys(data || {}).forEach((key: string) => {
    const list = data[key] || [];
    res[key] = list.map((i: S.InquirySurvey) => new S.InquirySurvey({ ...i }));
  });

  return res;
}

/** 获取 person 的历史上报记录 */
export async function getCustomerSurveyHistoryByPerson({
  type,
  tenantId,
}: {
  type: string;
  tenantId?: S.Id;
}) {
  const {
    data,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.getUmi<{
    data: S.InquirySurvey[];
  }>(`/person/order/customer/survey`, {
    params: { type, tenantId },
  });

  return (data || []).map(d => new S.InquirySurvey({ ...d }));
}

/** 提交 person 上报记录 */
export async function submitCustomerSurveyByPerson({
  inquirySurvey,
  tenantId,
}: {
  inquirySurvey: S.InquirySurvey;
  tenantId?: S.Id;
}) {
  const {
    status,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.postUmi<{
    status: string;
  }>(`/person/order/customer/survey`, {
    params: { tenantId: tenantId || getTenantIdFromGConfig() },
    data: {
      ...inquirySurvey,
    },
  });

  return status === 'ok';
}

/** 提交 person 上报记录 */
export async function submitCustomerSurvey({
  inquirySurvey,
}: {
  inquirySurvey: S.InquirySurvey;
}) {
  const {
    status,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.postUmi<{
    status: string;
  }>(`/inquiry/customer/survey`, {
    data: {
      ...inquirySurvey,
    },
  });

  return status === 'ok';
}
