import {
  processModelAsyncAndWaitInServer,
  updateModelFileAttr,
} from '../../../apis';
import * as S from '../../../schema';
import { useD3ModelStore } from './useD3ModelStore';

export class ShopD3ModelProcessManager {
  /** 已经提交的模型 ID */
  private processedModelFileIdSet: Set<string>;
  /** 已经提交但是被取消的模型 ID */
  private canceledModelFileIdSet: Set<string>;

  constructor() {
    // 定期执行
    this.canceledModelFileIdSet = new Set();
    this.processedModelFileIdSet = new Set();
  }

  /** 取消某个执行过程 */
  async cancelProcess(modelFileId: string) {
    this.canceledModelFileIdSet.add(modelFileId);
  }

  /** 添加任务并等待任务完成 */
  async processModelFileAndWait(
    modelFile: S.D3ModelFile,
    {
      forceRetry = false,
    }: {
      forceRetry?: boolean;
    },
  ) {
    let returnedModelFile: S.D3ModelFile;

    if (this.processedModelFileIdSet.has(modelFile.id)) {
      return;
    }

    this.processedModelFileIdSet.add(modelFile.id);

    // 是否上次选择了服务端处理，本次仍然是服务端处理
    const isContinueWaitServerProcessResult =
      // 存在 processStatuses 表示已经提交给服务端进行处理
      modelFile.attr.processStatuses &&
      !modelFile.attr.hasProcessedByServer &&
      !forceRetry;

    // 判断当前是否已经处理过，如果是已经提交服务器处理；否则进入重新处理的逻辑
    // 目前仅前次是服务端处理、本次依然是服务端处理，才会触发轮询逻辑
    if (isContinueWaitServerProcessResult) {
      returnedModelFile = await this.processInServerByUfc(modelFile, {
        skipSubmit: true,
      });
    } else {
      returnedModelFile = await this.processInServerByUfc(modelFile, {
        skipSubmit: false,
      });
    }

    // 处理完毕后，将数据写入 store，同时写入到服务端 attr 属性
    // 这里有可能处理失败
    await updateModelFileAttr(modelFile.id, returnedModelFile.attr);

    useD3ModelStore.setState({
      [modelFile.id]: new S.D3ModelFile(returnedModelFile),
    });

    return returnedModelFile;
  }

  private async processInServerByUfc(
    modelFile: S.D3ModelFile,
    { skipSubmit }: { skipSubmit?: boolean },
  ) {
    return processModelAsyncAndWaitInServer(modelFile, {
      skipSubmit,
      onProgress: (processInfo: S.D3ModelProcessInfo) => {
        // 处理的中途仅更新进度信息
        useD3ModelStore.setState({
          [modelFile.id]: new S.D3ModelFile({
            ...modelFile,
            ufcServerProcessInfoVO: processInfo,
          }),
        });

        if (this.canceledModelFileIdSet.has(modelFile.id)) {
          this.canceledModelFileIdSet.delete(modelFile.id);
          return { isCancelled: true };
        }

        return { isCancelled: false };
      },
    });
  }

  onIntervalProcess = async () => {};

  destroy() {}
}

export const defaultD3ModelProcessManager = new ShopD3ModelProcessManager();
