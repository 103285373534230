import * as S from '../../../../schema';
import { getUfcShopComposedApi } from '../../../../singleton-ins';

/** 兑换优惠券 */
export async function getInquiryCouponByRedeemKey(tenantId: S.Id, key: string) {
  const data = (await getUfcShopComposedApi().umiRequestCompatibleApi.postUmi<{
    data: string;
  }>(`/order/coupon/redeem`, {
    data: { key },
    params: { tenantId },
  })) as any;

  return data;
}
