import { isNil, omitBy } from 'lodash';

import * as S from '../../../../schema';
import { LK_SELECTED_COUPON_CODE } from '../../../../stores';
import { getTenantIdFromGConfig } from '../../..';
import { BaseAxiosApi } from '../../shared';

export class UfcPaymentApi extends BaseAxiosApi {
  async createPaymentOrderOnPaypal({
    orderId,
    orderCode,
    couponCode,
  }: {
    orderId: string;
    orderCode: string;
    couponCode?: string;
  }) {
    const key = `${LK_SELECTED_COUPON_CODE}_${orderCode}`;
    const couponCodeFromStorage = localStorage.getItem(key);
    const _couponCode = couponCode || couponCodeFromStorage;

    const params: { couponCode?: string | null } = { couponCode: _couponCode };

    if (params.couponCode == "Don't choose coupon") {
      delete params.couponCode;
    }

    const { data } = await this.post<{
      payOrderId: string;
      payPalOrderId: string;
    }>({
      url: `${
        this.apiConfig.apiEndpoint
      }/order/${orderId}/pay/paypal?tenantId=${getTenantIdFromGConfig()}`,
      data: params,
    });

    return data;
  }

  completePaymentOrderOnPaypal = async (
    orderId: string,
    payPalOrderId: string,
    payerId: string,
  ) => {
    if (!orderId || !payPalOrderId || !payerId) {
      return Promise.reject(
        new Error('orderId, payPalOrderId, payerId 不能为空'),
      );
    }

    return await this.post({
      url: `${
        this.apiConfig.apiEndpoint
      }/order/${orderId}/pay/paypal/${payPalOrderId}?tenantId=${getTenantIdFromGConfig()}`,
      data: {
        payerId,
      },
    });
  };

  noticeOceanPayRes = async (o: {
    orderCode: string;
    tenantId: S.Id;
    payOrderId: S.Id;
    xmlData: string;
  }) => {
    // const res = await this.post({
    //   url: `${this.apiConfig.apiEndpoint}/order/pay/notify/oceanpay/notice`,
    //   params: { tenantId, payOrderId },
    //   data: xmlData,
    // });

    const res = await this.post({
      data: o.xmlData,
      params: { tenantId: o.tenantId },
      url: `${this.apiConfig.apiEndpoint}/v2/shop/order/${o.orderCode}/pay_order/${o.payOrderId}/ocean_pay/notice`,
    });

    return 'receive-ok' === ((res as unknown) as string);
  };

  //////////////////////@start v2支付接口/////////////////////////

  /** 创建支付订单 */
  async createPaypalPayOrder({
    options,
    tenantId,
    orderCode,
  }: {
    orderCode: string;
    tenantId: string;
    options: S.CreatePaypalPayOrderParams;
  }) {
    const key = `${LK_SELECTED_COUPON_CODE}_${orderCode}`;
    const couponCodeFromStorage = localStorage.getItem(key);
    const couponCode = S.get(options, d => d.couponCode, couponCodeFromStorage);

    couponCode == "Don't choose coupon" && delete options.couponCode;

    const { data } = await this.post<S.PaypalPayOrder>({
      url: `${this.apiConfig.apiEndpoint}/v2/shop/order/${orderCode}/pay_order`,
      params: { tenantId },
      data: omitBy({ ...options, couponCode }, isNil),
    });

    const payPalOrderId = S.get(data, d => d.payPalOrder.id);
    S.setPayOrderIdToLocalStorage({ tenantId, orderCode, payOrderId: data.id });

    return { payOrderId: data.id, payPalOrderId, validSec: data.validSec };
  }

  async createOceanpaymentPayOrder({
    options,
    tenantId,
    orderCode,
  }: {
    orderCode: string;
    tenantId: string;
    options: S.CreateOceanpaymentPayOrderParams;
  }) {
    const key = `${LK_SELECTED_COUPON_CODE}_${orderCode}`;
    const couponCodeFromStorage = localStorage.getItem(key);
    const couponCode = S.get(options, d => d.couponCode, couponCodeFromStorage);

    couponCode == "Don't choose coupon" && delete options.couponCode;

    const { data } = await this.post<S.OceanpaymentPayOrder>({
      url: `${this.apiConfig.apiEndpoint}/v2/shop/order/${orderCode}/pay_order`,
      params: { tenantId },
      data: omitBy({ ...options, couponCode }, isNil),
    });

    S.setPayOrderIdToLocalStorage({ tenantId, orderCode, payOrderId: data.id });

    return data;
  }

  /** 获取当前有效的支付订单 */
  async getPayOrderInfo(o: {
    orderCode: string;
    payOrderId: string;
    tenantId: string;
  }) {
    const { data } = await this.get<S.PaypalPayOrder | S.OceanpaymentPayOrder>({
      params: { tenantId: o.tenantId },
      url: `${this.apiConfig.apiEndpoint}/v2/shop/order/${o.orderCode}/pay_order/${o.payOrderId}`,
    });

    return data;
  }

  async approvePaypalPayOrder(o: {
    orderCode: string;
    payOrderId: string;
    tenantId: string;
  }) {
    const { status } = await this.post<{ status: string }>({
      url: `${this.apiConfig.apiEndpoint}/v2/shop/order/${o.orderCode}/pay_order/${o.payOrderId}/pay_pal/on_approve`,
      params: { tenantId: o.tenantId },
    });

    return status == 'ok';
  }

  /** 关闭支付订单 */
  async closePayOrder(o: {
    tenantId: string;
    orderCode: string;
    payOrderId: string;
  }) {
    const { status } = await this.post<{ status: string }>({
      url: `${this.apiConfig.apiEndpoint}/v2/shop/order/${o.orderCode}/pay_order/${o.payOrderId}/cancel`,
      params: { tenantId: o.tenantId },
    });

    return status == 'ok';
  }

  //////////////////////@end v2支付接口/////////////////////////
}
