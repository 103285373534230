import { getUrlParameterByName } from '../schema/utils';
import ar from './ar';
import de from './de';
import en from './en';
import errorMap from './error';
import es from './es';
import fr from './fr';
import it from './it';
import ja from './ja';
import pt from './pt';
import ru from './ru';
import tr from './tr';
import zhCN from './zh-CN';

export type UnionfabLocale =
  | 'en'
  | 'zh-CN'
  | 'ru'
  | 'fr'
  | 'ar'
  | 'de'
  | 'es'
  | 'fr'
  | 'it'
  | 'ja'
  | 'pt'
  | 'tr';

let locale: UnionfabLocale;

export function getDefaultLocale() {
  let i = window.navigator.language;

  for (const lang of [
    'en',
    'zh',
    'ru',
    'fr',
    'ar',
    'de',
    'es',
    'fr',
    'it',
    'ja',
    'pt',
    'tr',
  ]) {
    if (i === lang || (i.startsWith && i.startsWith(lang))) {
      i = lang;
    }
  }

  return i as UnionfabLocale;
}

export function setLocale(_i: UnionfabLocale) {
  let i = _i;

  if (!i) {
    i =
      (localStorage.getItem('unionfab_lang') as UnionfabLocale) ||
      getDefaultLocale();
  }

  if (i != null) localStorage.setItem('unionfab_lang', i);

  locale = i;
}

export function getLocale() {
  if (locale) {
    return locale;
  }

  // 判断 url 中是否有 locale 字段
  const localeFromUrl = getUrlParameterByName('locale', location.href);

  // en/en, zh/zh
  return (
    localeFromUrl || localStorage.getItem('unionfab_lang') || getDefaultLocale()
  );
}

// 对日期进行国际化-Intl.DateTimeFormat: https://developer.mozilla.org/zh-CN/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat#%E4%BD%BF%E7%94%A8_locale
export function i18nDateTimeFormat(t: string) {
  // 避免传入错误的时间，导致Intl.DateTimeFormat报错
  const time = new Date(t);
  if (isNaN(time.getTime())) {
    return t;
  }

  let res = getLocale();

  switch (res) {
    case 'ja':
      res = 'ja-JP';
      break;
    case 'es':
      res = 'es-ES';
      break;
    case 'ru':
      res = 'ru-RU';
      break;
    default:
      res = 'en-US';
  }
  return new Intl.DateTimeFormat(res, {
    weekday: 'long',
    month: 'short',
    day: '2-digit',
  }).format(time);
}

const missingKeySet = new Set();

export function i18nFormat(id: string, defaultMessage?: string) {
  // 判断当前语言包
  let r: string;

  if (errorMap[id]) {
    return errorMap[id];
  }

  switch (getLocale()) {
    case 'en':
      r = en[id];
      break;

    case 'ru':
      r = ru[id];
      break;

    case 'ar':
      r = ar[id];
      break;

    case 'de':
      r = de[id];
      break;

    case 'es':
      r = es[id];
      break;

    case 'fr':
      r = fr[id];
      break;

    case 'it':
      r = it[id];
      break;

    case 'ja':
      r = ja[id];
      break;

    case 'pt':
      r = pt[id];
      break;

    case 'tr':
      r = tr[id];
      break;

    case 'zh-CN':
      r = zhCN[id];
      break;

    default:
      r = en[id];
      break;
  }

  if (r) return r;

  if (getLocale() !== 'en') {
    // 如果未找到数据，则打印出来
    missingKeySet.add(id);

    const jsonObj: Record<string, string> = {};
    for (const key of Array.from(missingKeySet)) {
      jsonObj[key as string] = `{{${key}}}`;
    }

    // console.log('>>>missing keys>>>', JSON.stringify(jsonObj));
  }

  return defaultMessage ? defaultMessage : id;
}
