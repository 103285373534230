import { compact } from 'lodash-es';

import { BaseEntity, Id, LengthUnit } from '../../common';
import { D3FormatAttr, D3ModelFile } from '../../common/file';
import { get, isNil, isValidArray, toFixedNumber } from '../../utils';
import { InquiryMaterialSku, ShopCncRequirements } from '../material';
import { ThinInquiryOrder } from './InquiryOrder';
import { InquiryOrderItem } from './InquiryOrderItem';

export class InquiryHandleMethod extends BaseEntity<InquiryPrintFile> {
  method: string[];
  desc: string; // 除喷漆外的描述字段
  desc1?: string; // 喷漆描述字段

  constructor(data: Partial<InquiryHandleMethod>) {
    super(data);

    Object.assign(this, data);
  }
}

/** 询价中专属的打印文件信息 */
export class InquiryPrintFile extends BaseEntity<InquiryPrintFile> {
  get isDraft() {
    return !this.id || this.id.includes('new');
  }

  cncRequirements?: ShopCncRequirements;

  fileId: Id; // 关联的模型文件的 ID
  orderId: Id;
  orderItemId: Id;
  previewFileId: string;
  enabledPrint: boolean; // 是否打印
  postHandleRemark: string; // 后处理备注
  parentItemId?: Id;
  /** 兼容后处理，需要展示的数据从此处取 */
  handle: {
    method: string[];
    desc: string; // 除喷漆外的描述字段
    desc1?: string; // 喷漆描述字段
  };

  /**
   * 用于外发后查看供应商的订单，文件信息来自于第三方的公有云
   */
  modelInfo?: {
    d3ModelAttribute: D3FormatAttr;
    fileName: string;
  };

  get handleMethodStr() {
    const method = compact(get(this, t => t.handle.method, []));

    if (isValidArray(method)) {
      /**
       *  统一后处理字符串排序
       * 用于后处理单分组
       */
      return method.sort((a, b) => a.localeCompare(b)).join('、');
    } else {
      return 'Standard';
    }
  }

  /** 打印数目，套数 */
  printCount = 1;
  /** 文件中零件个数（件数/文件）*/
  partCountPerFile = 1;
  /** 该条目实际要的打印件数，若未给定，默认就是 printCount*partCountPerFile，对于重打单，能够单独指定件数 */
  partCount: number;

  /** 螺母数量 */
  nutCount?: number;
  /** 打孔数量 */
  punchCount?: number;

  /** 由服务端返回的单套价格，也可能是手动设置的修正单套价格 */
  price: number = null;
  // 一口价，总价
  correctionPrice: number = null;
  // 临时存放的 spu 单价，该属性在初始化 PrintFile 对象时候会自动从 Spu 这提取该值并存入
  materialUnitPrice: number = null;
  // 以 lastModifiedField 为准，获取最终的单价
  lastModifiedField: 'price' | 'correctionPrice';
  /** 是否强制使用起步价 */
  isForceUseStartingPriceVO = false;

  /** 模型长度单位 */
  lengthUnit: LengthUnit;

  get handleDescStr() {
    if (get(this.handle, h => h.method, []).includes('喷漆')) {
      return (
        (get(this.handle, h => h.desc) ? this.handle.desc + '、' : '') +
        get(this.handle, h => h.desc1, '')
      );
    } else {
      return get(this.handle, h => h.desc, '-');
    }
  }

  get sizeDesc() {
    const { sizeX = '-', sizeY = '-', sizeZ = '-' } = this.modelFile.attr || {};

    return `
        ${toFixedNumber(sizeX, 2) || '-'} *
        ${toFixedNumber(sizeY, 2) || '-'} *
        ${toFixedNumber(sizeZ, 2) || '-'} mm
      `;
  }

  /** 最终的总的价格 */
  get finalPrice() {
    // 如果设置了一口价，则直接返回一口价
    if (this.lastModifiedField === 'correctionPrice') {
      return this.correctionPrice;
    } else if (this.lastModifiedField === 'price') {
      return toFixedNumber(this.price * this.printCount);
    } else {
      return undefined;
    }
  }

  volume: number;
  supportVolume: number;
  get finalVolume() {
    if (this.volume) {
      return (this.volume || 0) + (this.supportVolume || 0);
    }

    return this.modelFile.attr.volume || 0;
  }

  // 衍生属性
  modelFile: D3ModelFile;
  materialSpu: InquiryMaterialSku;
  thinInquiryOrder?: ThinInquiryOrder; // 创建的时候由父 Item 动态传入

  getInquiryOrderItemRef?: () => InquiryOrderItem;

  constructor(
    // 这里将 materialSpu 设置为必填
    data: Partial<InquiryPrintFile> & { materialSpu: InquiryMaterialSku } = {
      materialSpu: new InquiryMaterialSku(),
    },
  ) {
    super(data);

    Object.assign(this, data);

    // 单位默认为 mm
    if (!this.lengthUnit) {
      this.lengthUnit = 'MILLIMETER';
    }

    // 这里优先判断 correctionPrice，然后判断 price
    if (!isNil(this.correctionPrice)) {
      this.lastModifiedField = 'correctionPrice';
      // 这里针对单套价格设置值，仅仅用来显示用
      this.price = toFixedNumber(this.correctionPrice / this.printCount);
    } else if (!isNil(this.price)) {
      this.lastModifiedField = 'price';
    }

    const newHandleMethod = new InquiryHandleMethod({
      method: get(data, d => d.handle.method) || [],
      desc: get(data, d => d.handle.desc) || '',
      desc1: get(data, d => d.handle.desc1) || '',
    });

    /**
     * 兼容旧数据
     * 无后处理方式数据或为字符串
     * 后处理方式转换成数组结构
     */
    if (
      get(data, d => d.handle.method) &&
      typeof data.handle.method === 'string'
    ) {
      newHandleMethod.method = [data.handle.method as any].filter(
        h => h !== 'null',
      );
    }

    this.handle = newHandleMethod;

    // 兼容 partsNum
    if (!this.partCountPerFile) {
      this.partCountPerFile = get(this.modelFile, m => m.attr.partsNum, 1);
    }

    // 补齐零件数
    if (!this.partCount) {
      this.partCount = this.partCountPerFile * this.printCount;
    }

    if (!this.thinInquiryOrder) {
      console.error(
        '>>>InquiryPrintFile>>>constructor>>>thinInquiryOrder is undefined',
      );
    }

    this.modelInfo = data.modelInfo;

    try {
      if (
        data.modelInfo &&
        data.modelInfo.d3ModelAttribute &&
        data.modelInfo.fileName
      ) {
        if (!this.modelFile || !this.modelFile.id) {
          this.modelFile = new D3ModelFile({
            attr: data.modelInfo.d3ModelAttribute,
            name: data.modelInfo.fileName,
            id: data.fileId,
          });
        }
      } else if (!this.modelFile || !this.modelFile.id) {
        this.modelFile = new D3ModelFile({});
      }
    } catch (error) {
      console.error(
        '>>>InquiryPrintFile>>>constructor>>>modelFile',
        this,
        data,
        error,
      );
    }
  }
}
