import styles from './index.module.less';
import { QuoteEditorBody } from './QuoteEditorBody';
import { QuoteEditorStateSubscriber } from './QuoteEditorStateSubscriber';

export const QuoteEditor = () => {
  return (
    <div className={styles.container}>
      <QuoteEditorBody />
      {/** 监听 Quote 中订单状态变化，并提交更新价格 */}
      <QuoteEditorStateSubscriber />
    </div>
  );
};
