import { CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Button, message, Modal, Tooltip } from 'antd';

import { EllipsisMiddle } from '@/commons/components/Element';
import { useInquiryQuoteEditorStore } from '@/stores';

import styles from './index.module.less';

export const OrderItemTitle = ({
  orderItem,
}: {
  orderItem: S.InquiryOrderItem;
}) => {
  const {
    removeOrderItemViaFiles: removeModelFiles,
    copyOrderItems,
  } = useInquiryQuoteEditorStore();

  const curModel = orderItem.modelFiles[0];

  const copyItemBtn = (
    <Button
      className={styles.orderItemHeaderRightFirstLineModelHandlerBtn}
      icon={<CopyOutlined />}
      type="link"
      style={{ color: '#1F1F2C' }}
      onClick={() => {
        Modal.confirm({
          wrapClassName: 'order_items_creator_file_indicator_delete_modal',
          title: i18nFormat(`Are you sure you want to copy this part?`),
          okText: i18nFormat('Copy'),
          okButtonProps: {
            style: { textShadow: 'none', boxShadow: 'none' },
          },
          cancelText: i18nFormat('Cancel'),
          onOk: async () => {
            await copyOrderItems([orderItem]);
            message.success(i18nFormat('Item copied successfully'));
          },
        });
      }}
    />
  );

  const removeItemBtn = (
    <Button
      icon={<DeleteOutlined />}
      type="link"
      style={{ color: '#1F1F2C' }}
      onClick={() => {
        Modal.confirm({
          wrapClassName: 'order_items_creator_file_indicator_delete_modal',
          title: i18nFormat(`Are you sure you want to delete this part?`),
          okText: i18nFormat('Delete'),
          okButtonProps: {
            style: { textShadow: 'none', boxShadow: 'none' },
          },
          cancelText: i18nFormat('Cancel'),
          onOk: () => {
            removeModelFiles([curModel.id!]);
          },
        });
      }}
    />
  );

  return (
    <div className={styles.orderItemDetailsTitle}>
      <div className={styles.orderItemDetailsTitleModelName}>
        <EllipsisMiddle suffixCount={20}>
          {S.get(curModel, m => m.name)}
        </EllipsisMiddle>
      </div>
      <div className={styles.orderItemDetailsTitleModelBtns}>
        <Tooltip title={i18nFormat('Copy this item')}>{copyItemBtn}</Tooltip>
        <Tooltip title={i18nFormat('Remove this item')}>
          {removeItemBtn}
        </Tooltip>
      </div>
    </div>
  );
};
