import { WarningOutlined } from '@ant-design/icons';
import {
  getLatestBillingAddress,
  getTenantIdFromGConfig,
  i18nFormat,
} from '@unionfab/ufc-shop-commons';
import * as S from '@unionfab/ufc-shop-commons';
import { Button, Space, Tooltip } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import {
  getUseShopQuoteStoreByCode,
  useInquiryQuoteCheckoutStore,
} from '@/stores';

import { BillingAddressSelector } from './BillingAddressSelector';
import styles from './index.module.less';

interface OceanpayBillingAddressProps {
  orderCode: string;
}

export const OceanpayBillingAddress = ({
  orderCode,
}: OceanpayBillingAddressProps) => {
  const store = getUseShopQuoteStoreByCode(orderCode);
  const { order } = store(s => ({
    order: s.shopOrder,
    billingAddress: s.billingAddress,
  }));

  const orderDeliverAddress = useMemo(() => {
    if (order) return S.readShopOrderReceiveAddress(order as S.ShopOrder);
  }, [order]);

  const { data: lastBillingAddress } = useQuery('lastBillingAddress', {
    queryFn: () => getLatestBillingAddress(getTenantIdFromGConfig()),
  });

  const useShippingAddressBtn = useMemo(() => {
    if (!orderDeliverAddress) return <></>;

    return (
      <Button
        type="dashed"
        onClick={() => store.setState({ billingAddress: orderDeliverAddress })}
      >
        {i18nFormat('Use shipping address')}
      </Button>
    );
  }, [orderDeliverAddress]);

  const useLastBillingInfo = useMemo(() => {
    if (!lastBillingAddress) return <></>;

    return (
      <Button
        type="dashed"
        onClick={() => store.setState({ billingAddress: lastBillingAddress })}
      >
        {i18nFormat('Use last billing info')}
      </Button>
    );
  }, [lastBillingAddress]);

  return (
    <div className={styles.billInfoContainer}>
      <Space className={styles.flexStart}>
        {useShippingAddressBtn}
        {useLastBillingInfo}
      </Space>
      <BillingAddressSelector
        orderCode={orderCode}
        className={styles.billingInfoContent}
        selectAddress={async addr => store.setState({ billingAddress: addr })}
      />
    </div>
  );
};
