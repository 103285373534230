import {
  getUfcShopComposedApi as api,
  i18nFormat,
} from '@unionfab/ufc-shop-commons';
import * as S from '@unionfab/ufc-shop-commons';
import { Button, Input, message, Modal } from 'antd';
import cn from 'classnames';
import { useEffect, useState } from 'react';

import { useInquiryCouponStore } from '../../../../../stores';
import styles from './index.module.less';

export interface CouponRedeemInputProps {
  className?: string;
  style?: Record<string, string | number>;
  disabled?: boolean;
  onSuccess?: (couponCode: string, coupon: S.InquiryCoupon) => void;
}

export const CouponRedeemInput = ({
  className,
  style,
  disabled,
  onSuccess,
}: CouponRedeemInputProps) => {
  const { redeemInquiryCoupon, loadInquiryCoupons } = useInquiryCouponStore();

  const [redeemKey, setRedeemKey] = useState<string>();
  const redeemErrorStatusDescArr = [
    'REDEEMED',
    'PROMOTION_REDEEMED',
    'NOT_FOUND',
    'EXPIRED',
  ];
  const [redeemStatus, setRedeemStatus] = useState<
    null | 'success' | 'error'
  >();
  const [redeemErrorStatusDesc, setRedeemErrorStatusDesc] = useState<
    'REDEEMED' | 'PROMOTION_REDEEMED' | 'NOT_FOUND' | 'EXPIRED'
  >();

  useEffect(() => {
    if (!!redeemStatus && redeemStatus === 'success') {
      message.success(i18nFormat('Coupon Successfully Applied'));
    } else if (!!redeemStatus && redeemStatus === 'error') {
      handleError();
    }
    /** 延时自动关闭提示 */
    if (!!redeemStatus && redeemStatus.length > 0) {
      setTimeout(() => setRedeemStatus(null), 3 * 1000);
    }
  }, [redeemStatus]);

  const onRedeemCouponClick = async () => {
    if (typeof redeemKey === 'string' && redeemKey.length > 0) {
      try {
        const code = await redeemInquiryCoupon(redeemKey);
        if (!!code) {
          setRedeemKey(undefined);
          await loadInquiryCoupons();

          if (onSuccess) {
            const newCoupons = await api().userApi.getAccountCoupon();
            const newCoupon = newCoupons.find(c => c.code === code);

            onSuccess(code, newCoupon);
          }

          setRedeemStatus('success');
        } else {
          setRedeemKey(undefined);
          setRedeemStatus('error');
        }
      } catch (e: any) {
        setRedeemErrorStatusDesc(
          redeemErrorStatusDescArr.includes(e?.code) ? e?.code : '',
        );
        setRedeemStatus('error');
      }
    }
  };
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleError = () => {
    if (!redeemErrorStatusDesc || redeemErrorStatusDesc.length === 0) {
      return;
    }
    const errorMap = {
      // 兑换券已被兑换
      REDEEMED: i18nFormat(
        'This coupon code has already been redeemed and is no longer valid. Please enter another coupon code.',
      ),
      // 已参与过兑换券所属活动
      PROMOTION_REDEEMED: i18nFormat(
        'You have already redeemed a coupon for this promotion. Each user is limited to one redemption and cannot redeem again.',
      ),
      // 兑换券不存在
      NOT_FOUND: i18nFormat(
        'Sorry, the coupon code you entered is incorrect. Please try again.',
      ),
      //  兑换券活动过期
      EXPIRED: i18nFormat(
        'Sorry, this coupon has expired and cannot be redeemed.',
      ),
    };
    Modal.info({
      title: i18nFormat('Invalid Coupon Code'),
      content: (
        <div>
          <p>{i18nFormat(errorMap[redeemErrorStatusDesc])}</p>
        </div>
      ),
      keyboard: false,
      footer: (
        <Button
          type="primary"
          style={{
            float: 'right',
            // 其他样式...
            ...(isHovered && {
              backgroundColor: '#2d4066',
              color: 'white',
            }),
          }}
          onClick={() => {
            Modal.destroyAll();
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          OK
        </Button>
      ),
    });
  };
  const renderRedeemCouponInput = () => {
    return (
      <>
        <div className={styles.promotionCodeInput}>
          <Input
            value={redeemKey}
            disabled={disabled}
            placeholder={i18nFormat('Promotion Code')}
            onChange={({ target: { value } }) => setRedeemKey(value)}
          />
          <Button
            type="primary"
            disabled={disabled || !redeemKey}
            onClick={onRedeemCouponClick}
          >
            {i18nFormat('Apply')}
          </Button>
        </div>
      </>
    );
  };

  return (
    <div
      id="CouponRedeemInput"
      className={cn(className, styles.container)}
      style={style}
    >
      {renderRedeemCouponInput()}
    </div>
  );
};

CouponRedeemInput.displayName = 'CouponRedeemInput';
