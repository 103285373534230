import { BaseEntity } from '../../../common';
import { get } from '../../../utils';
import { ShopOrderDeliveryQuotation } from './ShopOrderDeliveryQuotation';
import { ShopOrderProductionQuotation } from './ShopOrderProductionQuotation';
import { ShopOrderQuotationStatus } from './types';

export type ShopOrderQuotationRfqReasonType =
  | 'large-production-price' // 产品材料费（所有文件总材料费用）> 500美元
  | 'large-print-weight' // 打印材料总重量 > 30KG
  | 'small-model' // 单个文件体积 <= 1cm³
  | 'large-edge' // 单个文件单面长度超过 350mm
  | 'special-material' // 选择 CNC、金属SLM、Ployget 工艺
  | 'some-handle-method' // 选择了某一项后处理服务
  | 'large-delivery-price' // 物流费用 > 100美元；
  | 'computing'; // 模型解析中或正在获取报价

export interface ShopOrderQuotationRfqReason {
  type: ShopOrderQuotationRfqReasonType;
  detail: string;
}

/**
 * Quotation 会包含多个可选的报价项，因此不具备最终价格数据
 */
export class ShopOrderQuotation extends BaseEntity<ShopOrderQuotation> {
  /** 报价版本 */
  revision: string;
  /** 报价截止时间 */
  insuredUntil: string;
  /** 报价客户确认时间 */
  customerCheckedAt: string;
  /** 报价状态 */
  status: ShopOrderQuotationStatus;
  /** 产品报价 */
  production: ShopOrderProductionQuotation;
  /** 物流报价 */
  delivery: ShopOrderDeliveryQuotation;

  get hasComputingItem() {
    return (
      get(this, t => t.status) === 'COMPUTING' ||
      get(this, t => t.delivery.status) == 'COMPUTING' ||
      get(this, t => t.production.status) == 'COMPUTING'
    );
  }

  constructor(data: Partial<ShopOrderQuotation> = {}) {
    super(data);

    BaseEntity.assignInConstructor(this, data);

    this.production = new ShopOrderProductionQuotation(this.production);

    this.delivery = new ShopOrderDeliveryQuotation(this.delivery);
  }
}
