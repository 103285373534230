// retries < 1, will retry forever
export function retryFn(
  fn: any,
  retries = -1,
  interval = 1000,
  retried = 0,
  name: string | undefined = undefined,
) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error: any) => {
        setTimeout(() => {
          if (retries > 0 && retried > retries) {
            reject(error);
          } else {
            if (name) {
              console.warn(`retry run ${name}`, error);
            }
            retryFn(fn, retries, interval, retried + 1, name).then(
              resolve,
              reject,
            );
          }
        }, interval);
      });
  });
}

// https://impedans.me/web/searching-the-inner-text-content-of-nested-react-nodes/
export function getRecursiveChildText(reactNode: any): string | undefined {
  if (typeof reactNode === 'string') {
    return reactNode;
  }
  const children = reactNode?.props?.children || undefined;
  if (Array.isArray(reactNode)) {
    // Multiple children
    const joinedNodes: string[] = [];
    reactNode.forEach(node => {
      if (typeof node === 'object')
        joinedNodes.push(getRecursiveChildText(node) || '');
      else if (typeof node === 'string') joinedNodes.push(node);
    });
    return joinedNodes.join(' ');
  }
  if (children === undefined) {
    if (typeof reactNode === 'string') return reactNode;
    else return ' ';
  }
  if (typeof children === 'object') {
    // Found direct child
    return getRecursiveChildText(reactNode.props.children);
  }
  if (typeof children === 'string') {
    // Found searchable string
    return reactNode.props.children;
  }
}
