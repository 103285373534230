import {
  CurrencyAndAmount,
  CurrencySymbolType,
  getCurrencySymbolByType,
} from '../../common';
import { get, toFixedNumber } from '../../utils';

export const toPriceString = (n?: number) => {
  return n == null ? '' : toFixedNumber(n).toFixed(2);
};

export const toPriceStringWithCurrency = (p: CurrencyAndAmount) => {
  const amount = get(p, p => p.amount);
  const currency = get(p, p => p.currency);
  return toPriceStringWithOption(amount, { currency });
};

export const toPriceStringWithOption = (
  n: number | undefined,
  params: {
    rfq?: boolean;
    currency?: CurrencySymbolType;
  },
) => {
  const rfq = params.rfq == null ? false : params.rfq;
  const currency = params.currency == null ? 'USD' : params.currency;
  const currencySymbol = getCurrencySymbolByType(currency);

  if (typeof n === 'number') {
    return `${currencySymbol}${toPriceString(n)}`;
  }

  return rfq ? 'RFQ' : '-';
};
