import { Typography } from 'antd';
import * as React from 'react';

const { Text } = Typography;

export const EllipsisMiddle = ({ suffixCount, children }: any) => {
  const [content, setContent] = React.useState(children);
  const [suffix, setSuffix] = React.useState('');

  React.useEffect(() => {
    setContent(children);
  }, [children]);

  return (
    <Text
      style={{ maxWidth: '550px', color: '#000' }}
      ellipsis={{
        tooltip: children,
        suffix,
        onEllipsis: () => {
          const start = children.slice(0, children.length - suffixCount).trim();
          const suffix = children.slice(-suffixCount).trim();
          setContent(start);
          setSuffix(suffix);
        },
      }}
    >
      {content}
    </Text>
  );
};
