import { indexOf, intersection } from 'lodash-es';

import { isValidArray } from '../../utils';

export const InquiryOrderStatusList = [
  /** 待提交 */
  'WAIT_SUBMIT',
  /** 报价待审核 */
  'WAIT_REVIEW',
  /** 待客户确认 */
  'WAIT_CUSTOM_CONFIRM',
  /** 客户已确认， */
  'CUSTOM_CONFIRM',
  /** 待生产 */
  'WAIT_PRINT',
  /** 数据处理中 */
  'FILE_HANDLE',
  /** 生产中 */
  'PRINTING',
  /** 后处理 */
  'HANDLE',
  /** 待发货 */
  'WAIT_DELIVER',
  /** 待收货 */
  'WAIT_RECEIVE',
  /** 交易关闭 */
  'ORDER_CLOSED',
  /** 交易完成 */
  'ORDER_COMPLETE',
  /** 订单被退回 */
  // 'ORDER_RETURNED',
] as const;

export const InquiryOrderReviewStatusList = [
  //审核通过
  'REVIEW_PASS',
  //审核中
  'REVIEW_PROCESSING',
  //审核驳回
  'REVIEW_REJECTED',
] as const;

export type InquiryOrderStatus = typeof InquiryOrderStatusList[number];

/** 订单审核状态 */
export type InquiryOrderReviewStatus = typeof InquiryOrderReviewStatusList[number];

// 订单未付款之前的状态
export const UnpaidInquiryOrderStatusList = [
  /** 待提交 */
  'WAIT_SUBMIT',
  /** 报价待审核 */
  'WAIT_REVIEW',
  /** 待客户确认 */
  'WAIT_CUSTOM_CONFIRM',
];

// 进入生产及之后的状态列表
export const ProductInquiryOrderStatusList = InquiryOrderStatusList.filter(
  s =>
    s === 'WAIT_PRINT' ||
    s === 'FILE_HANDLE' ||
    s === 'PRINTING' ||
    s === 'HANDLE' ||
    s === 'WAIT_RECEIVE' ||
    s === 'WAIT_DELIVER' ||
    s === 'ORDER_CLOSED' ||
    s === 'ORDER_COMPLETE',
);

// 生产之前的状态列表
export const BeforeProductInquiryOrderStatusList = InquiryOrderStatusList.filter(
  s => !ProductInquiryOrderStatusList.includes(s),
);

export const HandlerWorkbenchOrderStatus: InquiryOrderStatus[] = [
  'PRINTING',
  'HANDLE',
  'WAIT_DELIVER',
  'WAIT_RECEIVE',
  'ORDER_CLOSED',
  'ORDER_COMPLETE',
];

// 这里仅考虑，下发生产之后的，才是叫超时的
export const TimeoutInquiryOrderStatusList = ProductInquiryOrderStatusList.filter(
  s =>
    s !== 'WAIT_SUBMIT' &&
    s !== 'ORDER_CLOSED' &&
    s !== 'ORDER_COMPLETE' &&
    s !== 'WAIT_RECEIVE',
);

// 不考虑权限时可编辑的订单状态
export const EditableInquiryOrderStatusList = InquiryOrderStatusList.filter(
  s => s !== 'ORDER_CLOSED' && s !== 'ORDER_COMPLETE',
);

export const FinishedInquiryOrderStatusList: InquiryOrderStatus[] = InquiryOrderStatusList.filter(
  s => s === 'ORDER_CLOSED' || s === 'ORDER_COMPLETE',
);

export const InquiryOrderStatusMap = {
  WAIT_SUBMIT: '待提交',
  WAIT_REVIEW: '报价待审核',
  WAIT_CUSTOM_CONFIRM: '待客户确认',
  CUSTOM_CONFIRM: '客户已确认',
  WAIT_PRINT: '待生产',
  FILE_HANDLE: '数据处理中',
  PRINTING: '打印中',
  HANDLE: '后处理',
  WAIT_DELIVER: '待发货',
  WAIT_RECEIVE: '待收货',
  ORDER_COMPLETE: '交易完成',
  ORDER_CLOSED: '交易关闭',
};

/** 获取某个询价单关联的描述信息 */
export function getInquiryOrderStatusDesc(
  status: InquiryOrderStatus,
): {
  label: string;
} {
  switch (status) {
    case 'WAIT_SUBMIT': {
      return {
        label: '待提交',
      };
    }
    case 'WAIT_REVIEW': {
      return {
        label: '报价待审核',
      };
    }
    case 'WAIT_CUSTOM_CONFIRM': {
      return {
        label: '待客户确认',
      };
    }
    case 'CUSTOM_CONFIRM': {
      return {
        label: '客户已确认',
      };
    }
    case 'WAIT_PRINT': {
      return {
        label: '待生产',
      };
    }
    case 'PRINTING': {
      return {
        label: '打印中',
      };
    }
    case 'WAIT_DELIVER': {
      return {
        label: '待发货',
      };
    }
    case 'WAIT_RECEIVE': {
      return {
        label: '待收货',
      };
    }
    case 'ORDER_CLOSED': {
      return {
        label: '交易关闭',
      };
    }
    case 'ORDER_COMPLETE': {
      return {
        label: '交易完成',
      };
    }
    case 'FILE_HANDLE': {
      return {
        label: '数据处理',
      };
    }
    case 'HANDLE': {
      return {
        label: '后处理',
      };
    }
    default: {
      return {
        label: '未知状态',
      };
    }
  }
}

export type INQUIRY_ORDER_FILE_HANDLER_STATUS =
  | 'UNCLAIMED'
  | 'PRE_HANDLE'
  | 'TYPE_SET'
  | 'PRINT'
  | 'UNSET';

// A boundary defining the scope ofrollbacking
export const OrderRollbackBoundary = [
  'WAIT_CUSTOM_CONFIRM',
  /** 客户已确认 */
  'CUSTOM_CONFIRM',
  /** 待生产 */
  'WAIT_PRINT',
  /** 数据处理中 */
  'FILE_HANDLE',
  /** 生产中 */
  'PRINTING',
  /** 后处理 */
  'HANDLE',
  /** 待发货 */
  'WAIT_DELIVER',
  /** 待收货 */
  'WAIT_RECEIVE',
];

// Locate the previous status within the scope of rollbacking
export const locatePreStatus = (
  currentStatus: InquiryOrderStatus,
): InquiryOrderStatus => {
  return OrderRollbackBoundary[
    indexOf(OrderRollbackBoundary, currentStatus) - 1
  ] as InquiryOrderStatus;
};
// Make sure current status within limits
export const guaranteedToBeWithScope = (
  currentStatus: InquiryOrderStatus,
): boolean => {
  return indexOf(OrderRollbackBoundary, currentStatus) > 0;
};

// Request info for the rollbacking
export interface OrderRollbackInfo {
  orderCode: string;
  reason: string;
  desiredStatus: InquiryOrderStatus;
}

/** 判断状态是否相交 */
export function isStatusIntersected(
  status: InquiryOrderStatus | InquiryOrderStatus[],
  statusList: InquiryOrderStatus[],
) {
  return isValidArray(
    intersection(Array.isArray(status) ? status : [status], statusList),
  );
}
