import { BaseEntity, CurrencySymbolType, Id } from '../../common';

export type AccountType = 'BUSINESS' | 'INDIVIDUAL';

export interface AccountSetup {
  defaultCustomerId: Id;
  companyName: string;
  taxNumber: string;
}

export class AccountInfo extends BaseEntity<AccountInfo> {
  dtcAccountId: string;

  personId: string;
  /** 被邀请注册的 code */
  referralCode: string;
  /** 用户邮箱 */
  email: string;
  /** 用户联系方式 */
  phoneNumber: string;
  /** 注册邀请码 */
  inviteCode: string;
  /** 公司名称 */
  companyName: string;
  /** 职位信息 */
  position: string;
  /** 行业信息 */
  industries: string;
  /** 应用 */
  applications: string;
  /**  发现渠道 */
  discoverChannels: string;
  /** 用户昵称 */
  nickName: string;

  firstName: string;

  lastName: string;

  vat: string;

  // 用户自主退订邮件
  // 'NOT_RECEIVE_ALL': 退订邮件
  // 'NONE': 不退订
  mailPreference: 'NONE' | 'NOT_RECEIVE_ALL';
  mailPreferenceValue: boolean;

  quotationGroup: {
    id: string;
    name: string;
    displayName: string;
    currency: CurrencySymbolType;
  };

  constructor(data: Partial<AccountInfo>) {
    super(data);

    BaseEntity.assignInConstructor(this, data);
  }
}

/** @deprecated 获取账号信息 */
export class ShopAccountInfo extends BaseEntity<AccountInfo> {
  /** 用户邮箱 */
  email: string;
  /** 用户名 */
  username: string;

  firstName: string;

  lastName: string;

  /** 用户昵称 */
  nickname: string;

  /** 用户联系方式 */
  phoneNumber: string;

  accountType: AccountType;

  accountSetup: Partial<AccountSetup>;

  customerIdentities: {
    id: string;
    email: string;
    taxNumber: string;
    companyName: string;
    phoneNumber: string;
    address: string;
  }[];

  constructor(data: Partial<AccountInfo>) {
    super(data);

    BaseEntity.assignInConstructor(this, data);
  }
}
