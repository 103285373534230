import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { useState } from 'react';

import { OrderSubmitActions } from '../common/OrderSubmitActions';
import { OrderSubmitDetailsMobile } from '../common/OrderSubmitDetailsMobile';
import { OrderSubmitTerms } from '../common/OrderSubmitTerms';
import { OrderSubtotal } from '../common/OrderSubtotal';
import styles from './index.module.less';

export const QuoteSubmitorMobile = () => {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <div className={styles.quoteSubmitor}>
      {showDetails && (
        <div className={styles.quoteSubmitorDetails}>
          <OrderSubmitDetailsMobile />
          <OrderSubmitTerms />
        </div>
      )}
      <div className={styles.quoteSubmitorBody}>
        <div className={styles.quoteSubmitorSubtotal}>
          <div
            className={styles.quoteSubmitorDetailsBtn}
            onClick={() => setShowDetails(!showDetails)}
          >
            {showDetails ? <DownOutlined /> : <UpOutlined />}
          </div>
          <OrderSubtotal />
        </div>

        <div className={styles.quoteSubmitorActions}>
          <OrderSubmitActions />
        </div>
      </div>
    </div>
  );
};
