import { DownloadOutlined } from '@ant-design/icons';
import * as S from '@unionfab/ufc-shop-commons';
import { getModelFilesByIds, i18nFormat } from '@unionfab/ufc-shop-commons';
import { Button, message, Modal, Spin, Typography } from 'antd';
import cn from 'classnames';
import React from 'react';

import styles from './index.module.less';

const downloadFilesSet = new Set<string>();

export interface FileDownloaderProps {
  fileId?: S.Id;
  file?: S.File;
  fileIds?: S.Id[];

  fallbackUrl?: string;
  children?: React.ReactNode;
  flagField?: string;

  className?: string;
  style?: React.CSSProperties;
}

export const OssFileDownloader = () => {};

export const UfcFileDownloader = ({
  fileId,
  fileIds,
  file: sourceFile,
  children,

  className,
  style,
}: FileDownloaderProps) => {
  const [fileMap, setFileMap] = React.useState<
    Partial<Record<S.Id, S.D3ModelFile>>
  >({});

  React.useEffect(() => {
    (async () => {
      const ids = S.isValidArray(fileIds) ? fileIds : [fileId];

      const fileMap = await getModelFilesByIds(ids);

      if (sourceFile) {
        fileMap[sourceFile.id] = sourceFile as S.D3ModelFile;
      }

      setFileMap(fileMap);
    })();
  }, []);

  if (!fileId && !S.isValidArray(fileIds) && !sourceFile) {
    return <span />;
  }

  // 这里对于单个与多个文件的处理方法不一样
  const downloadUrl = async (url = '', name: string) => {
    const blob = await (await fetch(url, { cache: 'force-cache' })).blob();
    const ab = await new Response(blob).arrayBuffer();

    S.downloadArraybuffer(ab, 'application/pdf', name);

    downloadFilesSet.add(name);
  };

  const downloadAll = async () => {
    message.loading({
      key: i18nFormat('loading'),
      content: i18nFormat('Start download.'),
      duration: 0,
    });

    for (const fileId of fileIds) {
      const file = fileMap[fileId];
      if (!file) {
        continue;
      }

      await downloadUrl(file.url, file.name);
    }

    message.destroy(i18nFormat('loading'));
  };

  if (S.isValidArray(fileIds)) {
    return (
      <div
        className={cn(className, styles.container)}
        style={style}
        onClick={downloadAll}
      >
        {children || (
          <span style={{ color: 'rgb(104, 116, 226)', cursor: 'pointer' }}>
            {i18nFormat('Download all')}
            <DownloadOutlined
              style={{ color: 'rgb(104, 116, 226)', marginLeft: 4 }}
            />
          </span>
        )}
      </div>
    );
  }

  // 展示单个文件
  const innerFile = sourceFile || fileMap[fileId];

  if (!innerFile) {
    return (
      <span style={{ margin: '0 16px' }}>
        <Spin size="small" />
      </span>
    );
  }

  return (
    <div
      className={cn(className, styles.container)}
      style={style}
      onClick={() => {
        // 判断是否为图片
        if (S.isImageFile(innerFile.name)) {
          Modal.info({
            title: innerFile.name,
            maskClosable: true,
            content: <img style={{ width: '100%' }} src={innerFile.url} />,
            width: 800,
          });
          return;
        }

        // 判断是否为 PDF，如果为 PDF 则跳转到 PDF 预览页
        if (S.getFileType(innerFile.name) === 'pdf') {
          window.open(
            `/#/auth/${innerFile.id}/pdf`,
            '__blank',
            'noopener noreferrer',
          );

          return;
        }

        downloadUrl(innerFile.url, innerFile.name);

        message.success(i18nFormat(`The file is beginning to download`));
      }}
    >
      <Button type="link" style={{ padding: 0, width: '100%' }}>
        {children || (
          <Typography.Paragraph
            className={styles.filename}
            ellipsis={{ rows: 1, tooltip: innerFile.name }}
          >
            {innerFile.name}
          </Typography.Paragraph>
        )}
      </Button>
    </div>
  );
};
