import { isNil } from 'lodash-es';

import * as S from '../../../../schema';
import { DefaultNullD3Model } from '../../../../schema';
import {
  getTenantIdFromGConfig,
  getUfcShopComposedApi,
} from '../../../../singleton-ins';
import { getModelFileMapByIdsWithCache } from '../../common';
import {
  getInquiryMaterialSpusByIds,
  InquiryOrderFillOptions,
  mapInquiryOrderToRequestParams,
} from '../../order';

export async function changeInquiryOrderFromWaitSubmitToWaitReview(
  code: string,
): Promise<boolean> {
  const tenantId = getTenantIdFromGConfig();
  const { status } =
    (await getUfcShopComposedApi().umiRequestCompatibleApi.postUmi(
      `/customer/order/${code}/submit`,
      {
        params: { tenantId },
      },
    )) || {};

  return status === 'ok';
}

export async function getOverseaInquiryOrderByCode(
  code: string,
  params?: {
    withModelFilesInfo?: boolean;
    withPreprocessFilesInfo?: boolean;
    withInquiryMaterialSpus?: boolean;
    withCustomerShipOption?: boolean;
  },
) {
  const withModelFilesInfo =
    params?.withModelFilesInfo == null ? false : params.withModelFilesInfo;
  const withPreprocessFilesInfo =
    params?.withPreprocessFilesInfo == null
      ? false
      : params.withPreprocessFilesInfo;
  const withInquiryMaterialSpus =
    params?.withInquiryMaterialSpus == null
      ? false
      : params.withInquiryMaterialSpus;
  const withCustomerShipOption =
    params?.withCustomerShipOption == null
      ? false
      : params.withCustomerShipOption;

  const { data } =
    (await getUfcShopComposedApi().umiRequestCompatibleApi.getUmi(
      `/customer/order/${code}`,
      {
        params: { tenantId: getTenantIdFromGConfig(), withCustomerShipOption },
      },
    )) || {};

  if (!data) {
    return undefined;
  } else {
    const order = new S.InquiryOrder({ ...data });

    await fillInquiryOrdersForOverseasCustomer([order], {
      withModelFilesInfo,
      withPreprocessFilesInfo,
      withInquiryMaterialSpus,
    });

    return order;
  }
}

export async function getShopOrderDetailByCodeForOverseaCustomer(code: string) {
  const shopOrder = await getUfcShopComposedApi().shopOrderQueryApi.queryShopOrderByCode(
    code,
  );
  return shopOrder;
}

export async function getInquiryOrderDetailByCodeForOverseasCustomer(
  code: string,
  tenantId: S.Id,
) {
  const params = {
    withModelFilesInfo: true,
    withChangeRecord: false,
    withPreprocessFilesInfo: false,
    withInquiryMaterialSpus: true,
  };

  const { data } =
    (await getUfcShopComposedApi().umiRequestCompatibleApi.getUmi(
      `/customer/order/${code}`,
      {
        params: {
          tenantId,
          withCustomerShipOption: true,
          withCustomer: true,
          withQuotationUserInfo: true,
        },
      },
    )) || {};

  const order = new S.InquiryOrder({ ...data });

  await fillInquiryOrdersForOverseasCustomer([order], params);

  return order;
}

export async function updateCustomerShipOption(
  code: string,
  customerShipOption: S.CustomerShipOption,
) {
  const res = (await getUfcShopComposedApi().umiRequestCompatibleApi.postUmi<{
    status: string;
  }>(`/customer/order/${code}`, {
    data: {
      tenantId: getTenantIdFromGConfig(),
      customerShipOption,
      submitOnCustomerUpdate: false,
      submitOnUpdate: false,
    },
  })) || { status: 'error' };

  return res.status === 'ok';
}

export async function updateCustomerOrderRemark(code: string, remark?: string) {
  const res = (await getUfcShopComposedApi().umiRequestCompatibleApi.postUmi<{
    status: string;
  }>(`/customer/order/${code}`, {
    data: {
      tenantId: getTenantIdFromGConfig(),
      remark,
      submitOnCustomerUpdate: false,
      submitOnUpdate: false,
    },
  })) || { status: 'error' };

  return res.status === 'ok';
}

export async function createInquiryOrderForOverseaCustomer(
  toCreate: S.InquiryOrder,
  token?: string,
) {
  const data = mapInquiryOrderToRequestParams(toCreate);
  data.submitOnCreate = false;
  data.source = 'SITE_OVERSEA';

  const expressTimingType = toCreate.customerShipOption?.expressTimingType;
  if (expressTimingType === 'OTHERS') {
    data.customerShipOption = { remark: toCreate.customerShipOption?.remark };
  } else {
    const expressId = toCreate.customerShipOption?.expressId;
    data.customerShipOption = { expressTimingType, expressId };
  }

  // eslint-disable-next-line prefer-const
  const res = (await getUfcShopComposedApi().umiRequestCompatibleApi.postUmi<{
    status: string;
    data?: string;
  }>(`/customer/order`, {
    data,
    headers: token ? { Authorization: `Bearer ${token}` } : {},
  })) || { status: 'error' };

  // 判断是否需要更新订单的报价信息
  if (res.status !== 'ok') {
    return;
  } else {
    return res?.data;
  }
}

export async function updateInquiryOrderByCodeForOverseasCustomer(
  code: string,
  inquiryOrder: S.InquiryOrder,
) {
  if (!inquiryOrder) {
    return false;
  }

  const tenantId = getTenantIdFromGConfig();
  const byPerson = true;

  let status;

  const data = mapInquiryOrderToRequestParams(inquiryOrder);

  // 更新条目时候，也设置为报价中
  data.isQuoting = true;
  data.source = 'SITE_OVERSEA';

  data.customerShipOption = inquiryOrder.customerShipOption;
  if (inquiryOrder.customerShipOption?.expressTimingType === 'OTHERS') {
    data.customerShipOption = {
      remark: S.get(inquiryOrder, i => i.customerShipOption.remark, ''),
    };
  } else {
    data.customerShipOption = {
      expressTimingType: inquiryOrder.customerShipOption?.expressTimingType,
      expressId: inquiryOrder.customerShipOption?.expressId,
    };
  }

  data.submitOnCustomerUpdate = byPerson ? false : true;

  // eslint-disable-next-line prefer-const
  const res = (await getUfcShopComposedApi().umiRequestCompatibleApi.postUmi<{
    status: string;
  }>(`/customer/order/${code}`, {
    data,
  })) || { status: 'error' };

  // 判断是否需要更新订单的报价信息
  if (res.status !== 'ok') {
    return false;
  }

  if (byPerson) {
    return true;
  }

  return status === 'ok';
}

export async function doConfirmOrderByCustomer(
  tenantId: string,
  orderCode: string,
) {
  const {
    status,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.postUmi<{
    status: string;
  }>(`/customer/order/${orderCode}/status/auto_confirm`, {
    params: { tenantId },
    data: {},
  });
  return status == 'ok';
}

export async function fillInquiryOrdersForOverseasCustomer(
  inquiryOrders: S.InquiryOrder[],
  {
    withModelFilesInfo = true,
    withInquiryMaterialSpus = true,
  }: InquiryOrderFillOptions = {},
) {
  if (!S.isValidArray(inquiryOrders)) {
    return [];
  }

  // 进行必要的处理
  const spuIds = new Set<string>();

  let materialSpuMap: Record<any, any> = {};

  if (withInquiryMaterialSpus) {
    try {
      if (S.isValidArray(Array.from(spuIds))) {
        // 装配 materialSpu 信息
        const materialSpus = await getInquiryMaterialSpusByIds(
          Array.from(spuIds) as S.Id[],
        );
        materialSpuMap = S.groupByDistinctly(materialSpus, 'id');
      }
    } catch (_) {
      console.error(
        '>>>fillInquiryOrdersForOverseasCustomer>>>getInquiryMaterialSpusByIds>>>',
        _,
      );
    }
  }

  inquiryOrders.forEach(i => {
    // 设置 Spu
    i.items.forEach(i => {
      if (i.spuId && materialSpuMap[i.spuId]) {
        i.materialSpu = materialSpuMap[i.spuId];
      }
    });

    // 统一对于税率进行处理，从服务端拿到的除以 10
    i.price.taxRate = i.price.taxRate / 10;
  });

  if (withModelFilesInfo) {
    // 提取出所有的 modelFileId
    const modelFileIdSet = inquiryOrders.reduce((prev, i) => {
      (i.modelFiles || []).forEach(m => prev.add(m.id!));

      (i.contractFileIds || []).forEach(r => prev.add(r));

      (i.remarkFileIds || []).forEach(r => prev.add(r));

      (i.proofApplicationFileIds || []).forEach(r => prev.add(r));

      (i.deliver.deliverPicIds || []).forEach(r => prev.add(r));

      (i.attr.qcImageIds || []).forEach(r => prev.add(r));

      return prev;
    }, new Set<S.Id>());

    const modelFileIds = Array.from(modelFileIdSet);

    // 批量获取模型信息
    let modelFileMap: Record<any, any> = {};

    modelFileMap = await getModelFileMapByIdsWithCache({
      fileIds: modelFileIds.filter(i => !!i),
    });

    inquiryOrders.forEach(i => {
      i.printFiles.forEach(p => {
        p.modelFile = modelFileMap[p.fileId]
          ? modelFileMap[p.fileId]
          : DefaultNullD3Model;

        // 判断体积是否有，没有则补全
        if (!p.volume) {
          p.volume = S.get(p, p => p.modelFile.attr.volume);
        }
      });

      i.remarkFiles = (i.remarkFileIds || []).map(
        r => modelFileMap[r] || new S.File({}),
      );

      i.contractFiles = (i.contractFileIds || []).map(
        r => modelFileMap[r] || new S.File({}),
      );

      i.proofApplicationFiles = (i.proofApplicationFileIds || []).map(
        r => modelFileMap[r] || new S.File({}),
      );

      i.deliver.deliverPictures = (i.deliver.deliverPicIds || []).map(
        r => modelFileMap[r] || new S.File({}),
      );

      i.attr.qcImages = (i.attr.qcImageIds || []).map(
        r => modelFileMap[r] || new S.File({}),
      );

      i.deliver.deliverAddress = (i.deliver.deliverAddress || []).map(
        d =>
          new S.DeliverAddress({
            ...d,
            isSelected: isNil(d.isSelected) ? true : d.isSelected,
          }),
      );
    });
  }
  return inquiryOrders;
}

export interface OrderItemFileItemAttr {
  count: number;
  volume: number;
  fileAttributes: S.D3FormatAttr;
  density: number;
}

export interface ExpressMetaData {
  expressTimingType: S.ExpressTimingType;
  expressTiming: S.ExpressTiming;
  expressTimingId: number;
  quotation: number | undefined;
  expressQuotation: S.ExpressQuotation[] | undefined;
  timingDesc?: string;
  expressId?: string;
}

export interface InquiryOrderEstPrice {
  requiresManualQuote: boolean;
  materialPrice: number | null; // 材料价格
  postage: number | null; // 运费
  fileItemPrices: OrderItemEstPrice[]; // 条目报价
  expressTimingQuotations: ExpressMetaData[]; // 物流信息
}

export interface OrderItemEstPrice {
  id: string;
  price: number | null;
  perPrice: number | null;
  count: number;
  requiresManualQuote: boolean;
}

export async function getAvailableMaterialSpu() {
  const {
    data,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.getUmi<{
    data: S.InquiryMaterialSku[];
  }>(
    `/customer/order/material_spu?tenantId=${getTenantIdFromGConfig()}&source=SITE_OVERSEA`,
  );
  return data;
}

/** 创建一个订单子项 */
export const newInquiryOrderItem = ({
  model,
  materialSpu,
  printCount,
  tenantId,
  defaultOrder,
  handle,
  remark,
  remarkFileIds,
  nutCount,
  punchCount,
  lengthUnit,
}: {
  model: S.D3ModelFile;
  materialSpu: S.InquiryMaterialSku;
  printCount: number;
  tenantId: S.Id;
  defaultOrder: S.InquiryOrder;
  handle: { method: string[]; desc: string; desc1?: string };
  remark: string;
  remarkFileIds: S.Id[];
  nutCount?: number;
  punchCount?: number;
  lengthUnit: S.LengthUnit;
}) => {
  return new S.InquiryOrderItem({
    tenantId,
    spuId: materialSpu && materialSpu.id ? materialSpu.id : ('' as S.Id),
    materialSpu,
    printFiles: [
      new S.InquiryPrintFile({
        fileId: model.id,
        materialSpu,
        modelFile: new S.D3ModelFile(model),
        enabledPrint: true,
        postHandleRemark: '',
        handle: handle || { method: [''], desc: '' },
        printCount: printCount,
        thinInquiryOrder: defaultOrder.thinInquiryOrder,
        isForceUseStartingPriceVO: true,
        nutCount,
        punchCount,
        lengthUnit,
      }),
    ],
    remark,
    remarkFileIds,
    thinInquiryOrder: defaultOrder.thinInquiryOrder,
  });
};

export const getAvailableMaterialSpuList = (
  materialTypeId: S.Id,
  availableSpuGroupByProcess: Record<string, S.InquiryMaterialSku[]>,
  allAvailableSpu?: S.InquiryMaterialSku[],
) => {
  return materialTypeId
    ? availableSpuGroupByProcess[materialTypeId]
    : allAvailableSpu || [];
};

export const initialMaterialOptions = (
  materialTypeId: S.Id,
  availableSpuGroupByProcess: Record<string, S.InquiryMaterialSku[]>,
  allAvailableSpu: S.InquiryMaterialSku[],
) => {
  return (
    getAvailableMaterialSpuList(
      materialTypeId,
      availableSpuGroupByProcess,
      allAvailableSpu,
    ) || []
  ).map(spu => {
    return {
      label: spu.materialSnapshot.name,
      value: spu.id!,
    };
  });
};

/** 获取一个默认材料Id */
export const getDefaultSpuByMaterialId = (
  id: S.Id,
  materials: S.InquiryMaterial[],
) => {
  const material = (materials || []).find(m => m.id === id);
  const spuList = material && material.spuList ? material.spuList : [];

  return spuList[0];
};

export async function getUploadAuthForOverseasCustomer(
  uploadFileType: S.UPLOAD_FILE_TYPE,
  endpoints: { endpoint: string; storeId: S.Id }[] = [],
): Promise<S.FileStoreAuth> {
  const {
    data,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.postUmi<{
    data: S.OssAuth;
  }>(`/files/get_upload_auth`, {
    data: {
      uploadFileType,
      endpoints,
      expireSeconds: 3600,
      bizName: 'overseaModelStore',
    },
  });

  return new S.FileStoreAuth(data);
}

export const updateShipQuotation = async (
  code: string,
  customerShipOption: S.CustomerShipOption,
) => {
  const customerShipOptionToUpdate = { ...customerShipOption };

  if (customerShipOptionToUpdate.expressTimingType === 'OTHERS') {
    customerShipOptionToUpdate.expressTimingType = undefined;
  } else {
    customerShipOptionToUpdate.remark = undefined;
  }

  const params = {
    customerShipOption: customerShipOptionToUpdate,
    tenantId: getTenantIdFromGConfig(),
  };

  const { status } =
    (await getUfcShopComposedApi().umiRequestCompatibleApi.postUmi<{
      status: string;
    }>(`/customer/order/${code}`, {
      data: params,
    })) || {};
  return status === 'ok';
};

export async function closeInquiryOrderByCustomer({
  tenantId,
  orderCode,
  reason,
}: {
  tenantId: S.Id;
  orderCode: string;
  reason: string;
}): Promise<boolean> {
  const {
    status,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.postUmi<{
    status: string;
  }>(`/customer/order/${orderCode}/${tenantId}/transaction_closed`, {
    data: reason,
  });

  return status === 'ok';
}

export async function getInquiryChangeRecord(
  code: string,
  type: S.InquiryChangeRecordType,
) {
  const { data } =
    (await getUfcShopComposedApi().umiRequestCompatibleApi.getUmi<{
      data: S.InquiryChangeRecord[];
    }>(`/inquiry/order/${code}/record`, {
      params: { type },
    })) || {};

  return (data || []).map(d => new S.InquiryChangeRecord(d));
}

/**
 * 获取某个报价单的编辑记录
 * @param orderCode
 */
export async function getQuotationEditRecord(orderCode: string) {
  const {
    data,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.getUmi<{
    data: { data: Record<string, string> };
  }>(`/inquiry/order/${orderCode}/quotation/form`, {
    params: { orderCode, tenantId: getTenantIdFromGConfig() },
  });

  return data || { data: {} };
}
