import { DownloadOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { get, i18nFormat, isValidArray } from '@unionfab/ufc-shop-commons';
import { Button, Drawer, Modal, Space } from 'antd';
import cn from 'classnames';
import { CSSProperties, useMemo, useState } from 'react';

import {
  getUseShopQuoteStoreByCode,
  ShopOrderQuoteStoreModifiers,
} from '@/stores';

import { UfcFileDownloader } from '../../../../../../../commons/components/file/UfcFileDownloader';
import { ShopQuoteBulkConfiguration } from '../ShopQuoteBulkConfiguration';
import styles from './index.module.less';

interface ShopQuoteItemsTableActionsProps {
  orderCode: string;

  style?: CSSProperties;
  className?: string;
}

export const ShopQuoteItemsTableActions = ({
  orderCode,
  style,
  className,
}: ShopQuoteItemsTableActionsProps) => {
  const [isBulkModalVisible, setIsBulkModalVisible] = useState(false);

  const store = getUseShopQuoteStoreByCode(orderCode);
  const {
    isLoading,
    shopOrder,
    allowEdit,
    shopOrderDraft,
    selectedOrderItemIds = [],
  } = store();

  const allItemIds = get(shopOrder, o => o.items, []).map(i => i.id);

  const batchConfigDrawerTitle = useMemo(() => {
    const count = (selectedOrderItemIds || []).length;

    return i18nFormat(`Batch edit ({0} parts selected)`).replace('{0}', count);
  }, [selectedOrderItemIds]);

  const onSelectAllItems = () => {
    if ((selectedOrderItemIds || []).length == allItemIds.length) {
      store.setState({ selectedOrderItemIds: [] });
    } else {
      store.setState({ selectedOrderItemIds: allItemIds });
    }
  };

  const onDeleteItems = () => {
    const modeFileIds = (shopOrder.items || [])
      .filter(i => selectedOrderItemIds.includes(i.id))
      .map(i => i.fileId);

    if (isValidArray(modeFileIds)) {
      Modal.confirm({
        okText: i18nFormat('Delete'),
        cancelText: i18nFormat('Cancel'),
        icon: <ExclamationCircleFilled style={{ color: '#192C59' }} />,
        title: i18nFormat('Are you sure you want to to delete these items?'),
        onOk: () =>
          ShopOrderQuoteStoreModifiers.removeOrderItemByFileIds(
            orderCode,
            modeFileIds,
          ),
      });
    }
  };

  return (
    <div style={style} className={cn(styles.container, className)}>
      <Space>
        <Button disabled={!allowEdit} onClick={onSelectAllItems}>
          {i18nFormat('Select all')}
        </Button>
        <Button
          onClick={() => setIsBulkModalVisible(true)}
          disabled={!allowEdit || !isValidArray(selectedOrderItemIds)}
        >
          {i18nFormat('Configure')}
        </Button>
        <Button
          onClick={onDeleteItems}
          disabled={!allowEdit || !isValidArray(selectedOrderItemIds)}
        >
          {i18nFormat('Delete')}
        </Button>
      </Space>
      <div>
        <UfcFileDownloader fileIds={(shopOrder.items || []).map(o => o.fileId)}>
          <Button icon={<DownloadOutlined />} />
        </UfcFileDownloader>
      </div>

      <Drawer
        width="45%"
        open={isBulkModalVisible}
        title={batchConfigDrawerTitle}
        onClose={() => setIsBulkModalVisible(false)}
      >
        <ShopQuoteBulkConfiguration
          orderCode={orderCode}
          itemIds={selectedOrderItemIds}
        />

        <div className={styles.shopQuoteBulkConfigurationFooter}>
          <Button
            type="primary"
            loading={isLoading}
            onClick={() => {
              store.setState({ shopOrder: shopOrderDraft });

              ShopOrderQuoteStoreModifiers.debouncedOnOrderUpdateSideEffect(
                orderCode,
                () => setIsBulkModalVisible(false),
              );
            }}
          >
            {i18nFormat('Apply')}
          </Button>
        </div>
      </Drawer>
    </div>
  );
};
