const __CACHE = {} as Record<string, any>;

export class IsomorphicLocalStorage {
  static removeItem(key: string) {
    delete __CACHE[key];
    window.localStorage.removeItem(key);
  }

  // should be JSON serializable
  static setItem<T>(key: string, value: T) {
    __CACHE[key] = value;

    if (typeof window === 'undefined') return;

    if (value == null) {
      window.localStorage.removeItem(key);
    } else {
      window.localStorage.setItem(key, JSON.stringify(value));
    }
  }

  static hasItem(key: string) {
    if (Object.keys(__CACHE).includes(key)) {
      return true;
    } else {
      if (typeof window === 'undefined') return false;

      return !!window.localStorage.getItem(key);
    }
  }

  static getItem<T>(
    key: string,
    deserializer?: (json: string) => T | undefined,
  ): T | undefined {
    if (Object.keys(__CACHE).includes(key)) {
      return __CACHE[key];
    }

    if (typeof window === 'undefined') return undefined;

    const savedStr = window.localStorage.getItem(key);

    if (savedStr == null) {
      return undefined;
    } else {
      try {
        return deserializer
          ? deserializer(savedStr)
          : (JSON.parse(savedStr) as T);
      } catch (e) {
        console.warn('illegal value', key, savedStr);
        return undefined;
      }
    }
  }
}

export class ScopedLocalStorage {
  namespace: string;

  constructor(namespace: string) {
    if (namespace.includes('||')) {
      throw new Error(`namespace cant contain ||: ${namespace}`);
    }
    this.namespace = namespace;
  }

  private getKey(key: string) {
    if (key.includes('||')) {
      throw new Error(`key cant contain ||: ${key}`);
    }
    return `${this.namespace}||${key}`;
  }

  hasItem(key: string) {
    return IsomorphicLocalStorage.hasItem(this.getKey(key));
  }

  setItem<T>(key: string, value: T) {
    IsomorphicLocalStorage.setItem(this.getKey(key), value);
  }

  removeItem(key: string) {
    IsomorphicLocalStorage.removeItem(this.getKey(key));
  }

  getItem<T>(
    key: string,
    deserializer?: (json: string) => T | undefined,
  ): T | undefined {
    return IsomorphicLocalStorage.getItem<T>(this.getKey(key), deserializer);
  }
}
