import { i18nFormat } from '@unionfab/ufc-shop-commons';

import { useInquiryQuoteEditorStore } from '@/stores';

import styles from './index.module.less';

export const OrderSubmitDetailsMobile = () => {
  const newQuoteStore = useInquiryQuoteEditorStore();
  const { estPrice, leadTimeInfo } = newQuoteStore;

  const rfq =
    estPrice?.subtotalRequiresManualQuote == null
      ? true
      : estPrice.subtotalRequiresManualQuote;
  const curPrice = estPrice?.subtotal;

  const dueDate = `${leadTimeInfo.maxLeadtimeInDays}-${
    leadTimeInfo.maxLeadtimeInDays + 1
  }`;
  const shipmentDate = leadTimeInfo.shipmentDate;

  let paragraph = '';
  if (!curPrice) {
    paragraph = i18nFormat(
      `Note: There is no system quotation for the time being. Please wait for the engineer's feedback after placing the order for the final quotation, post-processing fee, and freight.`,
    );
  } else if (rfq) {
    paragraph = i18nFormat(
      `Note: The quote need to be reviewed. Please wait for the engineer's feedback after placing the order for the final quotation, post-processing fee, and freight.`,
    );
  }

  return (
    <div className={styles.orderSubmitorContent}>
      <div className={styles.orderSubmitorContentFirst}>
        <span className={styles.orderSubmitorContentFirstLeft}>
          {!rfq && i18nFormat('Lead Time:')}
        </span>
      </div>
      <div className={styles.orderSubmitorContentSecond}>
        <span className={styles.orderSubmitorContentSecondUp}>
          {!rfq && `${dueDate} ${i18nFormat('Business Days')}`}
        </span>
        <span className={styles.orderSubmitorContentSecondDown}>
          {!rfq && `${i18nFormat('Estimated shipment')}: ${shipmentDate}`}
        </span>
      </div>
      <div className={styles.orderSubmitorContentThird}>
        <span>{paragraph}</span>
      </div>
    </div>
  );
};
