import {
  getBuiltinTechCategoryList,
  TechCategory,
} from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import cn from 'classnames';

import CheckIcon from '@/assets/svgs/checkDark.svg';
import ProcessedMachineIcon from '@/assets/svgs/processedMachine.svg';
import SafeBoxIcon from '@/assets/svgs/safeBox.svg';

import styles from './index.module.less';

export interface TechCategorySelectorProps {
  onChange: (curTechCategoryName: string, t: TechCategory) => void;
  tech: TechCategory;

  className?: string;
}

export const TechCategorySelector = ({
  onChange,
  tech,
  className,
}: TechCategorySelectorProps) => {
  return (
    <div className={cn(styles.serviceCard, className)}>
      {getBuiltinTechCategoryList().map(techCategory => {
        const isActive = tech!.name === techCategory.name;
        const disabled = techCategory.type == 'VACUUM_CASTING';

        const className = cn(
          styles.serviceSelectorBtn,
          disabled && styles.disabledBtn,
          isActive && styles.selectedBtn,
        );

        return (
          <div
            className={className}
            key={techCategory.name}
            onClick={() => {
              !disabled && onChange(techCategory.name, techCategory);
            }}
          >
            {isActive && (
              <span className={styles.checkIcon}>
                <CheckIcon />
              </span>
            )}
            {techCategory.type === '3DPRINT' ? (
              <SafeBoxIcon />
            ) : (
              <ProcessedMachineIcon />
            )}
            <div className={styles.serviceBtnTxt}>
              <div className={styles.serviceTechCategoryName}>
                {i18nFormat(techCategory.displayName)}
              </div>
              <div className={styles.serviceTechCategoryDetail}>
                {i18nFormat(techCategory.processes)}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
