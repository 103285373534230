import { CloseCircleFilled } from '@ant-design/icons';
import { WhatsAppQrcodeUrl } from '@unionfab/ufc-shop-commons';
import dayjs from 'dayjs';
import * as React from 'react';
import { useEffect } from 'react';

import { useGlobalStore } from '@/stores';

export const ContactModal = () => {
  const { hasAuthenticated, isDemoUser } = useGlobalStore();
  const [showContactModal, setShowContactModal] = React.useState<boolean>(
    false,
  );
  let timer: any;
  const showContactModalInterval = (delay = 2500) => {
    timer = setInterval(() => {
      const isLoggedIn = hasAuthenticated && !isDemoUser;
      const displayTime = localStorage.getItem(
        'RapidConsultModal_display_time',
      );
      if (isLoggedIn) {
        if (!displayTime) {
          setShowContactModal(true);
          localStorage.setItem(
            'RapidConsultModal_display_time',
            dayjs().format(),
          );
        } else {
          if (dayjs().unix() - dayjs(displayTime).unix() >= 10) {
            setShowContactModal(false);
            clearInterval(timer);
          }
        }
      } else {
        clearInterval(timer);
      }
    }, delay);
  };

  useEffect(() => {
    showContactModalInterval();
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <>
      {!showContactModal && (
        <img
          style={{
            backgroundColor: '#fff',
            width: 60,
            height: 60,
            position: 'fixed',
            cursor: 'pointer',
            right: 28,
            bottom: 96,
            zIndex: 999999,
            display: 'none',
          }}
          onClick={() => {
            setShowContactModal(true);
          }}
          src={WhatsAppQrcodeUrl}
        />
      )}
      {showContactModal && (
        <div
          style={{
            width: 358,
            height: 496,
            padding: 12,
            fontSize: 16,
            position: 'fixed',
            right: 48,
            bottom: 96,
            zIndex: 999999,
            zoom: 0.75,
          }}
        >
          <div
            style={{
              cursor: 'pointer',
              position: 'absolute',
              right: 8,
              top: 4,
              fontSize: 24,
              opacity: 0.5,
            }}
            onClick={() => {
              setShowContactModal(false);
              localStorage.setItem(
                'RapidConsultModal_display_time',
                dayjs().subtract(20, 'm').format(),
              );
            }}
          >
            <CloseCircleFilled />
          </div>
          <img
            style={{ width: '100%', height: '100%' }}
            src="https://ufc-oversea.oss-accelerate.aliyuncs.com/assets/WhatsAppQrCode03.png"
          />
        </div>
      )}
    </>
  );
};
