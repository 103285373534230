import { BaseEntity, Id } from '../../../common';

export interface QualityInspectionFileInfo {
  name: string;
  md5: string;
  size: number;
  url: string;
}

export class QualityInspectionInfo extends BaseEntity<QualityInspectionInfo> {
  remark: string;
  imageIds: Id[] = [];
  attachmentIds: Id[] = [];

  filesMap: Record<Id, QualityInspectionFileInfo> = {};

  constructor(data: Partial<QualityInspectionInfo> = {}) {
    super(data);

    BaseEntity.assignInConstructor(this, data);
  }
}
