import { isNil, omitBy } from 'lodash';

import * as S from '../../../../schema';
import {
  getTenantIdFromGConfig,
  getUfcShopComposedApi,
} from '../../../../singleton-ins';
import { getAxiosAgent } from '../../..';

export interface RegionAreaConfig {
  /** 父节点code */
  pc: string;
  /** code */
  c: string;
  /** name */
  n: string;
  /** 子节点 */
  children: RegionAreaConfig[];
}

export const CustomAreaMap: Record<string, string> = {
  CA: 'CA',
  DE: 'DE',
  FR: 'FR',
  GB: 'GB',
  US: 'US',
};

/** 获取所有国家 */
export async function getCountries() {
  const {
    data,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.getUmi<{
    data: S.Country[];
  }>(`/world_info/country`);

  /** 转换成 Area 类型 */
  return (data || []).map(c => new S.Country({ ...c }));
}

/** 获取国家下属州（区）信息 */
export async function getStatesByCountryCode(countryCode: string) {
  const {
    data,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.getUmi<{
    data: S.State[];
  }>(`/world_info/state`, {
    params: { countryCode },
  });

  return (data || []).map(c => new S.Area({ name: c.name, iso2: c.iso2 }));
}

/** 全量获取所有国家信息 */
export async function getCountriesFully() {
  const {
    data,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.getUmi<{
    data: S.Country[];
  }>(`/world_info/country`);

  /** 转换成 Area 类型 */
  return data || [];
}

export async function getRegionListByAreaCode(
  code?: string,
): Promise<RegionAreaConfig[]> {
  const axiosAgent = getAxiosAgent();

  if (!code) return [];
  if (!CustomAreaMap[code]) return [];

  // oss 拉取主要地区地址 json
  const data: any = await axiosAgent.get(`${code}.json`, {
    baseURL:
      'https://ufc-oversea.oss-accelerate.aliyuncs.com/uoms/assets/data/',
  });

  const list = S.get(data, d => d.data.addressList) || [];

  return list.map((i: RegionAreaConfig) => i as RegionAreaConfig);
}

export async function updateShippingAddressByPerson({
  receiveAddress,
  address,
}: {
  receiveAddress: S.ReceiveAddress[];
  address?: string;
}) {
  const {
    status,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.patchUmi(
    `/person/order/customer`,
    {
      params: { tenantId: getTenantIdFromGConfig() },
      data: omitBy({ address, receiveAddress }, isNil),
    },
  );
  return status == 'ok';
}

export async function getCountrySimpleName(country: string[]) {
  const {
    data,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.postUmi<{
    data: Partial<S.InquiryOverseaAddress>[];
  }>(`/world_info/country`, {
    data: country,
  });
  const res = (data || []).map(el => new S.InquiryOverseaAddress(el));
  return res;
}
