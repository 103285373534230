import { BaseEntity } from '../../type';

/** 文件解析状态 枚举 */
export const D3ModelProcessStatusList = [
  'PENDING',
  'RUNNING',
  'FAILED',
  'TIMEOUT',
  'SUCCESS',
];

/** 文件解析状态 类型 */
export type D3ModelProcessStatus = typeof D3ModelProcessStatusList[number];

/** 文件解析状态 */
export class D3ModelUfcServerProcessStatusMap extends BaseEntity<D3ModelUfcServerProcessStatusMap> {
  /** 解压 进度状态 */
  decompress: D3ModelProcessStatus;

  /** 文件类型转换 进度状态 */
  stlConvert: D3ModelProcessStatus;

  /** 读取原始 Stl 属性 进度状态 */
  readRawStlAttribute: D3ModelProcessStatus;

  /** 修复 Stl 进度状态 */
  fixStl: D3ModelProcessStatus;

  /** 读取修复的 Stl 文件属性 进度状态 */
  readFixedStlAttribute: D3ModelProcessStatus;

  /** 获取缩略图 进度状态 */
  thumbnailStl: D3ModelProcessStatus;

  /** 开始解析的时间 */
  startsAt: string;

  constructor(props: Partial<D3ModelUfcServerProcessStatusMap> = {}) {
    super(props);

    Object.assign(this, props);
  }

  get hasFinished(): boolean {
    const {
      decompress,
      stlConvert,
      readRawStlAttribute,
      fixStl,
      readFixedStlAttribute,
      thumbnailStl,
    } = this || {};

    for (const v of [
      decompress,
      stlConvert,
      readRawStlAttribute,
      fixStl,
      readFixedStlAttribute,
      thumbnailStl,
    ]) {
      if (v != null && v !== 'FAILED' && v !== 'TIMEOUT' && v !== 'SUCCESS') {
        return false;
      }
    }

    return true;
  }
}
