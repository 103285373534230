import { i18nFormat, getLocale } from '@unionfab/ufc-shop-commons';
import * as S from '@unionfab/ufc-shop-commons';
import { Button, Modal, Typography } from 'antd';
import { CSSProperties, useState } from 'react';

import {
  getUseShopQuoteStoreByCode,
  ShopOrderQuoteStoreModifiers,
} from '@/stores';

import { InquiryMaterialSkuSelect } from '../../../../../components/select/InquiryMaterialSkuSelect';
import styles from './index.module.less';
export interface ShopQuoteItemsMaterialBulkSelectorProps {
  style?: CSSProperties;

  orderCode: string;
}

export const ShopQuoteItemsMaterialBulkSelector = ({
  style,
  orderCode,
}: ShopQuoteItemsMaterialBulkSelectorProps) => {
  const { shopOrderDraft } = getUseShopQuoteStoreByCode(orderCode)(s => ({
    shopOrderDraft: s.shopOrderDraft,
  }));

  const onUpdateItemMaterial = (
    material: S.InquiryMaterial,
    sku?: S.InquiryMaterialSku,
  ) => {
    ShopOrderQuoteStoreModifiers.updateOrderDraft(orderCode, o => {
      for (const item of o.items) {
        item.materialVO = material;
        if (sku || S.isValidArray(material.spuList)) {
          item.skuId = sku ? sku.id : material.spuList[0].id;
          item.materialSkuVO = sku || material.spuList[0];
        }
      }
    });
  };

  return (
    <div
      style={style}
      id="ShopQuoteItemsMaterialBulkSelector"
      className={styles.shopQuoteBulkConfigurationItem}
    >
      <div className={styles.title}>
        <Typography.Title level={5}>{i18nFormat('Material')}</Typography.Title>
        <a
          href={'https://www.unionfab.com/materials'}
          target="_blank"
          rel="noreferrer"
        >
          {i18nFormat('Learn about our materials')}
        </a>
        {getLocale() === 'ru' && (
          <a
            className={styles.comparePrice}
            href={
              'https://www.unionfab.com/blog/2024/06/quotation-guide-russian'
            }
            target="_blank"
            rel="noreferrer"
          >
            {i18nFormat('Quotation Guide')}
          </a>
        )}
      </div>
      <div className={styles.flexStart}>
        <InquiryMaterialSkuSelect
          style={{ flex: '1 1 1px' }}
          filterOptions={{
            techCategory:
              S.ShopOrderAccessors.getCurTechCategory(shopOrderDraft),
          }}
          onChange={(_skuId, { material, materialSku }) => {
            onUpdateItemMaterial(material, materialSku);
          }}
        />
      </div>
    </div>
  );
};

ShopQuoteItemsMaterialBulkSelector.displayName =
  'ShopQuoteItemsMaterialBulkSelector';
