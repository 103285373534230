import { findLast } from 'lodash-es';

import * as S from '../../../../schema';
import {
  getTenantIdFromGConfig,
  getUfcShopComposedApi,
  getUfcShopRuntimeEnv,
} from '../../../../singleton-ins';
import { InquiryOrderFillOptions, InquiryPagedQueryParams } from '../../order';
import { fillInquiryOrdersForOverseasCustomer } from './order_query';

export const customerInquiryOrderFillOptions: InquiryOrderFillOptions = {
  withModelFilesInfo: true,
  withChangeRecord: false,
  withPreprocessFilesInfo: false,
  withInquiryMaterialSpus: false,
};

export type OverseaOrderStatus =
  | 'ALL'
  | 'WAIT_SUBMIT'
  | 'REVIEWING'
  | 'WAIT_PAYMENT'
  | 'PENDING'
  | 'PRINTING'
  | 'TOSHIP'
  | 'SHIPPED'
  | 'COMPLETED'
  | 'CANCELED';

export const CAN_CHECKOUT_ORDER_STATUS_LIST: OverseaOrderStatus[] = [
  'WAIT_SUBMIT',
  'REVIEWING',
  'WAIT_PAYMENT',
];

export const PLACED_ORDER_STATUS_LIST: OverseaOrderStatus[] = [
  'ALL',
  'PENDING',
  'PRINTING',
  'TOSHIP',
  'SHIPPED',
  'COMPLETED',
  'CANCELED',
];

export const OrderStatusTitleMap: Record<OverseaOrderStatus, string> = {
  ALL: 'All',
  WAIT_SUBMIT: 'Draft',
  REVIEWING: 'Reviewing',
  WAIT_PAYMENT: 'Unpaid',
  PENDING: 'Pre-Processing',
  PRINTING: 'In-Process',
  TOSHIP: 'To Ship',
  SHIPPED: 'Shipped',
  COMPLETED: 'Completed',
  CANCELED: 'Canceled',
};

// 兼容国内平台状态
export const ShopOrderStatusToInquiryOrderStatus: Record<
  OverseaOrderStatus,
  S.InquiryOrderStatus[]
> = {
  ALL: [
    'CUSTOM_CONFIRM',
    'WAIT_PRINT',
    'FILE_HANDLE',
    'PRINTING',
    'HANDLE',
    'WAIT_DELIVER',
    'WAIT_RECEIVE',
    'ORDER_COMPLETE',
    'ORDER_CLOSED',
  ],
  WAIT_SUBMIT: ['WAIT_SUBMIT'],
  REVIEWING: ['WAIT_REVIEW'],
  WAIT_PAYMENT: ['WAIT_CUSTOM_CONFIRM'],
  PENDING: ['CUSTOM_CONFIRM', 'WAIT_PRINT'],
  PRINTING: ['FILE_HANDLE', 'PRINTING', 'HANDLE'],
  TOSHIP: ['WAIT_DELIVER'],
  SHIPPED: ['WAIT_RECEIVE'],
  COMPLETED: ['ORDER_COMPLETE'],
  CANCELED: ['ORDER_CLOSED'],
};

export const getOverseaOrderStatusByInquiryOrderStatus = (
  status: S.InquiryOrderStatus,
) => {
  const statusList = Object.keys(
    ShopOrderStatusToInquiryOrderStatus,
  ) as OverseaOrderStatus[];

  const resp: OverseaOrderStatus = findLast(statusList, o =>
    ShopOrderStatusToInquiryOrderStatus[o].includes(status),
  );

  return resp;
};

export const getOrderShopStatusByInquiryOrderStatus = (
  states: S.InquiryOrderStatus,
) => {
  const curOverseaKey = getOverseaOrderStatusByInquiryOrderStatus(states);

  return curOverseaKey && OrderStatusTitleMap[curOverseaKey];
};

export async function getInquiryOrdersByMultiStatusByPerson(
  status: S.InquiryOrderStatus[],
  {
    startDateTime,
    endDateTime,
    pageNum,
    pageSize,
    searchText,
    sortBy,
    withCustomerShipOption,
  }: InquiryPagedQueryParams,
  // 是否仅获取订单列表
  _thin = false,
  tenantId?: S.Id,
): Promise<{ data: S.InquiryOrder[]; meta: S.Meta }> {
  const {
    data,
    meta,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.getUmi<{
    data: S.InquiryMaterial[];
    meta: S.Meta;
  }>(`/customer/orders`, {
    params: {
      startDateTime,
      endDateTime,
      pageNum,
      pageSize,
      searchText,
      status,
      tenantId: tenantId || getTenantIdFromGConfig(),
      sortBy,
      withCustomerShipOption,
    },
  });

  const thinOrders = (data || []).map(m => new S.InquiryOrder(m));

  const orders = await fillInquiryOrdersForOverseasCustomer(
    thinOrders,
    customerInquiryOrderFillOptions,
  );

  return { data: (orders || []).map(o => new S.InquiryOrder({ ...o })), meta };
}

/** 获取订单对应的支付信息 */
export const getPaymentInfo = async (orderId: string) => {
  const { data } =
    (await getUfcShopComposedApi().umiRequestCompatibleApi.getUmi<{
      data: Record<string, any>;
    }>(`/orders/${orderId}/pay_order`, {
      params: {
        tenantId: getTenantIdFromGConfig(),
      },
    })) || {};
  return data as S.PaymentInfo;
};
