import { BaseEntity } from '../../type';

/** 面片 信息 */
export class D3ModelProcessFacetStatus extends BaseEntity<D3ModelProcessFacetStatus> {
  /** 面片数量 */
  facetsNum: number;

  facetsWith1DisEdge: number;
  facetsWith2DisEdge: number;
  facetsWith3DisEdge: number;

  totalDisFacets: number;

  constructor(props: Partial<D3ModelProcessFacetStatus> = {}) {
    super(props);

    Object.assign(this, props);
  }
}
