import dayjs, { Dayjs } from 'dayjs';
import { countBy, includes, last, sortBy } from 'lodash-es';

import { BaseEntity, Id } from '../../common';
import { CurrencySymbolType, File } from '../../common';
import { D3ModelFile } from '../../common/file';
import { InquiryCustomer } from '../../crm/customer/InquiryCustomer';
import { getDefaultTenantConfig, Person, User } from '../../user';
import { get, isValidArray } from '../../utils';
import { CustomerShipOption } from '../express/InquiryExpressMethod';
import { Deliver } from './delivery';
import { InquiryOrderAttr } from './InquiryOrderAttr';
import { InquiryOrderFeedback } from './InquiryOrderFeedback';
import { InquiryOrderItem } from './InquiryOrderItem';
import { InquiryPrintFile } from './InquiryPrintFile';
import {
  EditableInquiryOrderStatusList,
  InquiryOrderReviewStatus,
  InquiryOrderStatus,
  InquiryOrderStatusList,
} from './InquiryStatus';
import { InquiryPrice, PayMethod } from './quotation';
import { InquiryOrderQuotation } from './quotation/InquiryOrderQuotation';

export type InquiryOrderSynergyType = 'OUTSOURCE' | 'SHIFT';

export type InquiryOrderQueryType = 'NORMAL' | 'FILE_HANDLE' | 'HANDLE';

export type InquiryOrderDispatchType =
  /** 订单负责人 */
  | 'ADMIN'
  /** 指定的可对订单进行报价的人 */
  | 'QUOTABLE_MAN'
  /** 销售/客服 */
  | 'SALE_MAN'
  /** 文件处理员 */
  | 'FILE_HANDLER'
  /** 后处理员 */
  | 'HANDLER'
  /** 发货员 */
  | 'SHIPPER';

export type InquiryOrderQueryDateProperty =
  /** 订单创建时间 */
  | 'createTime'
  /** 订单最晚交期 */
  | 'deliverDeadline'
  /** 发货时间 */
  | 'deliverTime'
  /** 下发生产时间 */
  | 'switchWaitProductAt'
  /** 实际付款时间 */
  | 'paymentTime'
  /** 申请时间 */
  | 'applyTime';

export type InquiryOrderSortByProperty /** 新版的排序规则 */ =
  | 'asc(createTime)'
  | 'desc(createTime)'
  | 'asc(updateTime)'
  | 'desc(updateTime)'
  | 'asc(deliverDeadline)'
  | 'desc(deliverDeadline)'
  | 'asc(switchWaitProductAt)'
  | 'desc(switchWaitProductAt)'
  | 'asc(urgent)'
  | 'desc(urgent)'
  | 'asc(orderType)'
  | 'desc(orderType)'
  | 'asc(materialId)'
  | 'desc(materialId)'
  | 'asc(materialTypeId)'
  | 'desc(materialTypeId)';

export interface InquiryFilterOptions {
  type?: InquiryOrderType;

  pageNum: number;
  pageSize: number;
  searchText: string;
  searchScope: 'ALL' | 'ACTIVE';
  createdAtRange: [Dayjs, Dayjs];
  dateProperty: InquiryOrderQueryDateProperty;
  status: InquiryOrderStatus | InquiryOrderStatus[];
  source?: InquiryOrderSource | InquiryOrderSource[];

  urgent: boolean; // 是否是加急单，不传显示全部
  isSynergy: boolean; // 是否为外协单，不传显示全部
  isSynergyReceived: boolean; // 是否为外协收货状态，不传显示全部
  damage: boolean; // 是否为坏件，不传显示全部
  hasUnhandledFeedback: boolean; // 是否包含未处理的坏件订单
  withCustomer: boolean;
  contractState?: 'DE_UPLOA' | 'NOT_UNLOAD' | 'UPLOADED'; // 订单合同是否已经上传
  logisticsCertState?: 'DE_UPLOA' | 'NOT_UNLOAD' | 'UPLOADED'; // 收货凭证是否上传

  /** @deprecated */
  sort:
    | 'BY_URGENT_ORDER_TYPE_DELIVER' // 综合排序
    | 'BY_UPDATE_TIME_DESC' // 按更新时间降序
    | 'BY_DELIVER_TIME_ASC'; // 按最晚交期升序
  sortBy: InquiryOrderSortByProperty;

  /** 是否为超时订单 */
  timeoutOrder: boolean;
  /** 当前后处理进度 */
  handleProcessId: string;
  /** 文件是否预处理 */
  preFileHandle: boolean;
  /** 生产备注信息 */
  withProductRemark?: boolean;

  /** 指派或者关联的用户信息 */
  userId?: Id;
  /** 指派的类型 */
  dispatchType?: InquiryOrderDispatchType | InquiryOrderDispatchType[];
  /** 材料 Id */
  materialId?: Id;
  /** 材料名 */
  materialName?: string;
  /** 一级加工工艺 */
  materialTypeCategoryName?: string;
  /** 材料类型 Id (二级加工工艺) */
  materialTypeId?: Id;

  synergyType?: '' | 'OUTSOURCE' | 'SHIFT';
  /** 是否查询外发 */
  isOutsourceOrder?: boolean;
}

export class InquirySynergy extends BaseEntity<InquirySynergy> {
  // 外协供应商
  supplier: string;
  // 供应商联系人
  representativeName: string;
  // 金额
  totalAmount: number;
  // 克重
  weight: number;
  // 确认外协件收货人
  receiverId: Id;
  // 确认外协件收货时间
  receiveAt: string;

  constructor(props?: Partial<InquirySynergy>) {
    super(props);

    Object.assign(this, props);
  }
}

// 外发订单预检查时返回的数据类型
export interface preCreateSynergyOrderResult {
  inspectTypeToInspectInfo?: Record<
    string,
    Array<{ tagGroup: string; reason: string; tag: string }>
  >;
}

export const InquiryOrderTypeList = [
  /** 普通订单 */
  'NORMAL_ORDER',
  /** 项目订单 */
  'PROJECT_ORDER',
  /** 重打订单 */
  'REMAKE_ORDER',
  /** 打样订单 */
  'PROOF_ORDER',
];

export type InquiryOrderType = typeof InquiryOrderTypeList[number];

export function getInquiryOrderTypeDesc(t: InquiryOrderType) {
  switch (t) {
    case 'NORMAL_ORDER':
      return '普通订单';
    case 'PROJECT_ORDER':
      return '项目订单';
    case 'PROOF_ORDER':
      return '打样订单';
    case 'REMAKE_ORDER':
      return '重打订单';
    default:
      return '未知';
  }
}

export function getInquiryOrderSourceDesc(s: InquiryOrderSource) {
  switch (s) {
    case 'INTERNAL':
      return '内部订单';
    case 'INTERNAL_OFFLINE':
      return '线下订单';
    case 'INTERNAL_TMALL_HUITONG':
      return '天猫店';
    case 'INTERNAL_TAOBO_HUITONG':
      return '淘宝店';
    case 'INTERNAL_JIANBO_HUITONG':
      return '简播';
    case 'INTERNAL_XIYAN_TMALL_HUITONG':
      return '昔言天猫';
    case 'INTERNAL_SHANGXIN_1688_HUITONG':
      return '尚鑫1688';
    case 'INTERNAL_ZHONGSHAN_ALI':
      return '中山阿里';
    case 'INTERNAL_ZHONGSHAN_PDD':
      return '中山拼多多';
    case 'OPENAPI':
      return '开放接口';
    case 'WECHAT_MINI_PROGRAM':
      return '小程序';
    case 'SITE_UNIONFAB':
      return '线上官网';
    default:
      return '未知';
  }
}

export const InquiryOrderSourceList = [
  /** 内部创建 */
  'INTERNAL',
  /** 线下订单 */
  'INTERNAL_OFFLINE',
  /** 汇通天猫店 */
  'INTERNAL_TMALL_HUITONG',
  /** 汇通淘宝店 */
  'INTERNAL_TAOBO_HUITONG',
  /** 汇通简播 */
  'INTERNAL_JIANBO_HUITONG',
  /** 汇通 昔言天猫 */
  'INTERNAL_XIYAN_TMALL_HUITONG',
  'INTERNAL_SHANGXIN_1688_HUITONG',
  'INTERNAL_ZHONGSHAN_ALI',

  /** 开放接口创建 */
  'OPENAPI',
  /** 小程序创建 */
  'WECHAT_MINI_PROGRAM',
  /** Unionfab 官网 */
  'SITE_UNIONFAB',

  /** Unionfab 海外官网 */
  'SITE_OVERSEA',
  /** 平台下的海外单 */
  'INTERNAL_SITE_OVERSEA',

  /** 来自供应链的外发 */
  'SUPPLY_CHAIN',
];

export type InquiryOrderSource = typeof InquiryOrderSourceList[number];

/** 订单修改源的问题 */
export type InquiryOrderChangeSource =
  | 'ITEM'
  | 'QUOTATION'
  | 'CUSTOMER'
  | 'DELIVERY'
  | 'PRODUCT_REMARK'
  | 'OTHER';

/** 分享类型 */
export type InquiryOrderShareType = 'RECEIVE' | 'SHARE';

export class ThinInquiryOrder extends BaseEntity<ThinInquiryOrder> {
  name: string;
  code: string;
  // 父订单ID
  parentOrderId?: string;
  // 父订单Code
  parentOrderCode?: string;

  source: InquiryOrderSource; // 订单来源
  type: InquiryOrderType; // 订单类型：普通订单、外协订单
  calculatedPriceTaxType: 'WITH_TAX' | 'WITHOUT_TAX';
  currency: CurrencySymbolType; // 订单币种

  status: InquiryOrderStatus;
  /** 是否是加急单 */
  urgent: boolean;
  /** 是否是超时订单 */
  timeout: boolean;
  /** 是否为外协单 */
  isSynergy: boolean = undefined;
  /** 是否为外协单收获状态 */
  isSynergyReceived: boolean;
  /** 参考订单号，淘宝单号 */
  sourceOrderCode: string;

  /** 订单拆分状态 */
  splitType?: // 拆分过的子订单
  | 'SPLIT'
    // 从未拆过的订单
    | 'NOT_SPLIT'
    // 拆分过的父订单
    | 'SPLIT_PARENT';

  /**是否可以重拆 */
  canReSplit?: boolean;
  /**
   * OUTSOURCE: 该订单已经外发
   * SHIFT: 该订单已转移
   */
  synergyType: 'OUTSOURCE' | 'SHIFT';

  get shortCode(): string {
    return get(this, p => p.code.split('-')[1]) as string;
  }

  constructor(props: Partial<ThinInquiryOrder> = {}) {
    super(props);

    Object.assign(this, props);
  }
}

export class InquiryOrder extends ThinInquiryOrder {
  get key(): string {
    return this.code;
  }

  get searchStr() {
    return `${this.code}-${this.customerName}`;
  }

  get thinInquiryOrder() {
    return new ThinInquiryOrder({
      name: this.name,
      code: this.code,
      type: this.type,
      source: this.source,
      status: this.status,
      urgent: this.urgent,
      isSynergy: this.isSynergy,
      isSynergyReceived: this.isSynergyReceived,
      timeout: this.timeout,
      calculatedPriceTaxType: this.calculatedPriceTaxType,
    });
  }

  set thinInquiryOrder(_: ThinInquiryOrder) {
    console.error('>>>InquiryOrder>>>set thinInquiryOrder>>>', _);
  }

  get isDirty() {
    // 有新创建的条目或者文件，则为 dirty
    return (
      this.items.filter(i => i.isDraft).length > 0 ||
      this.printFiles.filter(i => i.isDraft).length > 0
    );
  }

  /** 是否是海外订单 */
  get isOversea() {
    return ['SITE_OVERSEA', 'INTERNAL_SITE_OVERSEA'].includes(this.source);
  }

  /** 外发订单发货方式 */
  deliverMethod?: 'DIRECT_TERMINAL_USER' | 'SEND_BACK_CUSTOMER';
  //////////////////////@start 状态相关/////////////////////////
  status: InquiryOrderStatus;

  // 是否为坏件
  damage = false;

  get allowEdit() {
    if (this.status) {
      return includes(EditableInquiryOrderStatusList, this.status);
    }
    return true;
  }

  /** 当前订单是否正在报价 */
  isQuotating: boolean;

  /** 订单审核状态 */
  reviewStatus: InquiryOrderReviewStatus;

  // 是否成交
  get hasConfirmedByCustomer() {
    if (this.status) {
      const DealDoneStatusList = InquiryOrderStatusList.filter(
        s =>
          s !== 'WAIT_SUBMIT' &&
          s !== 'WAIT_REVIEW' &&
          s !== 'WAIT_CUSTOM_CONFIRM' &&
          s !== 'ORDER_CLOSED',
      );

      return DealDoneStatusList.includes(this.status);
    }
    return false;
  }

  rowSpan: number;
  // 本次设计到的修改
  stashedChanges: InquiryOrderChangeSource[] = [];
  //////////////////////@end 状态相关/////////////////////////

  // 是否为创建子订单
  isCreateChild?: boolean;
  //////////////////////@start 条目相关/////////////////////////
  /** 询价单具体项目列表 */
  itemIds: Id[];
  items: InquiryOrderItem[];

  /** 工艺 */
  technology?: 'CNC' | '3D_PRINTING';

  /** 总的打印的模型的套数，这里一个模型里可能包含多个零件 */
  get printCount() {
    return this.printFiles.reduce((prev: number, cur: InquiryPrintFile) => {
      return prev + cur.printCount;
    }, 0);
  }

  /** 这里是总的零件数目，涉及到一个模型多个零件的场景 */
  get partCount() {
    return this.printFiles.reduce((prev: number, cur: InquiryPrintFile) => {
      return prev + cur.partCount;
    }, 0);
  }

  // 提取出该询价单中所有关联到的打印文件
  get printFiles(): InquiryPrintFile[] {
    const printFiles: InquiryPrintFile[] = [];

    const addedPrintFileIdSet = new Set();

    (this.items || []).forEach(i => {
      i.printFiles.forEach(p => {
        // 避免才创建的时候 id 为 undefined 的情况
        if (p.id && addedPrintFileIdSet.has(p.id)) {
          return;
        }

        printFiles.push(p);
        addedPrintFileIdSet.add(p.id);
      });
    });

    return printFiles;
  }

  // 提取出全部的模型文件
  get modelFiles(): D3ModelFile[] {
    const modelFiles: D3ModelFile[] = [];
    const printFiles = this.printFiles;

    const addedIdSet = new Set();

    (printFiles || []).forEach(printFile => {
      const modelFile = printFile.modelFile;

      if (!modelFile || !modelFile.id) {
        return;
      }

      if (addedIdSet.has(modelFile.id)) {
        return;
      }

      modelFiles.push(modelFile);
      addedIdSet.add(modelFile.id);
    });

    return modelFiles;
  }

  //////////////////////@end 条目相关/////////////////////////

  //////////////////////@start 报价相关/////////////////////////
  /** 支付方式 */
  payMethod: PayMethod;
  /** 支付状态 */
  payStatus: 'NOT_PAY' | 'ON_PAY' | 'COMPLETE_PAY';
  /** 客服最终报价 - 需要支付的金额 */
  confirmedPrice: number;

  /** 价格与支付相关信息，该属性即将移除 @deprecated */
  price: InquiryPrice;
  /** 这里用于记录后端送来的价格 */
  priceFromServer?: InquiryPrice;

  quotation: InquiryOrderQuotation;
  /** 报价人Id */
  quotationUserId: Id;
  /** 报价人信息 */
  quotationUser: Partial<User>;
  /** 自动报价相关 */
  canAutoQuote: boolean; // 是否可自动报价
  autoQuoted: boolean; // 是否自动报价
  manualIntervened: boolean; // 是否手动修改报价
  // 询价单外协信息
  synergy: InquirySynergy;
  //////////////////////@end 报价相关/////////////////////////

  //////////////////////@start 关联的用户/////////////////////////
  // 关联的客服的 UserId
  salesManId: string;
  creatorId: string;
  creatorInfo: Person;
  customerId: Id;
  customer: InquiryCustomer;
  customerCreated: boolean; // 是否是客户自己创建;
  /** 订单级别的用户名，是多种类型的封装 */
  get customerName() {
    try {
      // 值得注意的是，这里的 customer.name 是根据客户实时情况改变的，而 deliver.customerName 一旦客户下单之后就不会改变
      let name =
        get(this, () => this.customer.name) ||
        get(this, () => this.deliver.customerName);

      // 判断是否来自于客户创建的
      if (!name && this.customerCreated) {
        name = this.creatorInfo
          ? `用户-${this.creatorInfo.nickname}`
          : this.creatorId;
      }

      if (!name) {
        name = this.name || '-';
      }

      return name;
    } catch (_) {
      return '-';
    }
  }
  //////////////////////@end 关联的用户/////////////////////////

  //////////////////////@start 交付相关/////////////////////////
  // 订单交付信息
  deliver: Deliver;

  get lastDeliverDayCnt() {
    if (!this.deliver) {
      return 3;
    }

    const diff = Math.abs(
      dayjs(this.deliver.lastDeliveredAt || '').diff(dayjs(), 'd'),
    );

    return this.deliver.lastDeliveredAt
      ? Math.abs(diff > 365 ? diff - 365 : diff)
      : 3;
  }

  // 订单合同是否上传
  contractState:
    | 'DE_UPLOAD' // 不需要上传物
    | 'NOT_UPLOAD' // 未上传
    | 'UPLOADED'; // 已上传

  // 订单收货凭证是否上传
  logisticsCertState:
    | 'DE_UPLOAD' // 不需要上传物
    | 'NOT_UPLOAD' // 未上传
    | 'UPLOADED'; // 已上传;

  //////////////////////@end 交付相关/////////////////////////

  //////////////////////@start 属性及记录数据/////////////////////////

  /** 坏件记录 */
  feedbacks: InquiryOrderFeedback[];
  get feedbackImages() {
    return (this.feedbacks || []).reduce((prev, cur) => {
      prev.push(
        ...Object.keys(cur.imageUrls || {}).map(
          (id: Id) =>
            new File({
              id,
              url: get(cur, c => c.imageUrls[id]) || '',
            }),
        ),
      );
      return prev;
    }, []);
  }
  // 是否含有预处理文件
  preFileHandle: boolean;
  preprocessFileIds: Id[];
  /** 合同文件 */
  contractFileIds: Id[] = [];
  contractFiles: File[] = [];
  /** 打样信息相关 */
  proofApplicationFileIds: Id[]; // 打样文件申请书
  proofApplicationFiles: File[] = [];

  /** 报价体积重量 (kg) */
  volumeWeightInKg: number;

  //////////////////////@end 属性及记录数据/////////////////////////

  //////////////////////@start 备注相关/////////////////////////
  // 客户本身的备注
  orderRemark: {
    fileIds: Id[];
    remark: string;
  };
  // 来自客户端填写的数据，实际上就是 orderRemark 里的 remark 字段
  remark: string;
  // 前端自行 Derived 的数据，fillInquiryOrders 会对该字段进行补全
  remarkFiles: File[] = [];
  // 备注文件 id，目前后端该字段就是来自于 orderRemark.fileIds
  remarkFileIds: Id[] = [];
  // 备注 兼容旧数据
  get fullRemark(): string {
    if (isValidArray(this.attr.standardizedRequirements)) {
      return this.attr.standardizedRequirements
        .map(s => {
          const requirementRemark = get(
            this.attr,
            a => a.requirementRemark[s],
            '',
          );

          return requirementRemark !== ''
            ? s + `（备注：${requirementRemark}）`
            : s;
        })
        .join('、');
    }

    return '';
  }
  //////////////////////@end 备注相关/////////////////////////

  //////////////////////@start 生产相关/////////////////////////
  // 打印关联信息
  printInfo: { orderId: Id; prints: object[] };

  // 提交时间
  submitAt: string;
  // 切换到生产的时间
  switchWaitProductAt: string;

  // 关联打印单的剩余时间 秒
  estimatedTime: number;

  get estimatedDateStr() {
    if (!this.estimatedTime || this.estimatedTime < 100) {
      return '-';
    }

    return dayjs().add(this.estimatedTime, 's').format('MM/DD HH:mm');
  }

  /** 是否被下发到生产 */
  get hasDispatchedToProduct() {
    return !!this.switchWaitProductAt;
  }

  /** 关联打印单打印进度 */
  progress: number;

  /** 用户提交的物流选项 */
  customerShipOption?: CustomerShipOption;
  public setCustomerShipOption(customerShipOption?: CustomerShipOption) {
    if (customerShipOption) {
      this.customerShipOption = customerShipOption;
    }
  }

  /** 是否为某用户的首次下单 */
  firstOrder?: boolean;

  /** 安全时间 */
  safeTime?: string;

  get safeTimeUnix() {
    return dayjs(this.submitAt).valueOf() - dayjs(this.safeTime).valueOf();
  }

  //////////////////////@end 生产相关/////////////////////////

  // 额外属性，后端使用不到的属性
  attr: Partial<InquiryOrderAttr>;

  // 首单天数
  fromFirstOrderDays?: number;

  // 父订单ID
  parentOrderId?: Id;

  constructor(data: Partial<InquiryOrder> = {}) {
    super(data);

    Object.assign(this, data);

    if (!this.customerShipOption) {
      this.customerShipOption = null;
    }

    if (!this.calculatedPriceTaxType) {
      this.calculatedPriceTaxType = getDefaultTenantConfig().calculatedPriceType;
    }

    this.currency = data.currency;

    try {
      // 单元格合并信息
      const mergeInfo: Record<number, number> = countBy(
        this.items || [],
        i => i.offerNumber,
      );

      // 这里主要对询价单子项进行转化
      this.items = sortBy(this.items || [], i => i.offerNumber).reduce(
        (prev, cur, index) => {
          const target =
            cur.offerType === 'MERGE_OFFER'
              ? new InquiryOrderItem({
                  ...cur,
                  thinInquiryOrder: this.thinInquiryOrder,
                  rowSpan:
                    // 第一个 item rowSpan 直接赋值，之后的 item 判断 offerNumber 是否与上一个 item 相同
                    index === 0
                      ? mergeInfo[cur.offerNumber]
                      : cur.offerNumber !== last(prev).offerNumber
                      ? mergeInfo[cur.offerNumber]
                      : 0,
                })
              : new InquiryOrderItem({
                  ...cur,
                  thinInquiryOrder: this.thinInquiryOrder,
                });

          prev.push(target);

          return prev;
        },
        [],
      );
    } catch (_) {
      console.error('>>>InquiryOrder>>>mergeInfo>>>>error:', _, this.items);
    }

    // 构造 inquiryOrderItem 对象
    this.items = (this.items || []).map(i => new InquiryOrderItem({ ...i }));

    if (isValidArray(this.items) && !isValidArray(this.itemIds)) {
      this.itemIds = this.items.map(i => i.id);
    }

    // 构造 Deliver 信息
    if (!this.deliver) {
      this.deliver = new Deliver();
    } else {
      this.deliver = new Deliver({ ...this.deliver });
    }

    // 这里针对个人客户，默认将客户代表设置为自己
    if (this.deliver && this.customer && this.customer.type === 'PERSON') {
      this.deliver.representativeName = this.customer.name;
      this.deliver.representativeContactInfo = this.customer.phoneNumber;
    }

    // 保证 attr 不为空
    this.attr = new InquiryOrderAttr({
      ...this.attr,
    } as InquiryOrderAttr);

    // 根据提交的标准化需求备注筛选出提交订单时需要校验的费用
    this.price = new InquiryPrice({
      ...(data.price || {}),
      getInquiryOrderRef: () => this,
    });

    this.price.items = [...this.items];

    // 默认为普通订单
    if (!this.type) {
      this.type = 'NORMAL_ORDER';
    }

    if (this.feedbacks) {
      this.feedbacks = this.feedbacks.map(f => new InquiryOrderFeedback(f));
    }

    if (!this.orderRemark) {
      this.orderRemark = { fileIds: [], remark: '' };
    }

    // 默认保证 remarkFileIds 包含有值
    if (
      this.orderRemark &&
      !isValidArray(this.remarkFileIds) &&
      isValidArray(this.orderRemark.fileIds)
    ) {
      this.remarkFileIds = this.orderRemark.fileIds;
    }

    this.payMethod = new PayMethod({ ...this.payMethod });

    if (this.creatorInfo) {
      this.creatorInfo = new Person(this.creatorInfo);
    }

    // 初始化报价对象
    this.quotation = new InquiryOrderQuotation({
      ...data.quotation,
      getInquiryOrderRef: () => this,
    });

    this.synergy = new InquirySynergy({
      ...(this.synergy || {}),
    });

    // 滤除一些订单中不使用的冗余属性
    (this.items || []).forEach((item, index) => {
      if (get(item, i => i.materialSpu.materialSnapshot)) {
        this.items[index].materialSpu.materialSnapshot.allSpuList = [];
        this.items[index].materialSpu.materialSnapshot.spuIds = [];
      }

      if (get(item, i => i.materialSpu.materialSnapshot.spuList)) {
        get(item, i => i.materialSpu.materialSnapshot.spuList).forEach(
          (spu, j) => {
            if (get(spu, s => s.materialSnapshot)) {
              this.items[index].materialSpu.materialSnapshot.spuList[
                j
              ].materialSnapshot.allSpuList = [];
              this.items[index].materialSpu.materialSnapshot.spuList[
                j
              ].materialSnapshot.spuIds = [];
              this.items[index].materialSpu.materialSnapshot.spuList[
                j
              ].materialSnapshot.spuList = [];
            }
          },
        );
      }

      if (get(item, i => i.printFiles[0].materialSpu.materialSnapshot)) {
        this.items[
          index
        ].printFiles[0].materialSpu.materialSnapshot.allSpuList = [];
        this.items[
          index
        ].printFiles[0].materialSpu.materialSnapshot.spuIds = [];
      }

      if (
        get(item, i => i.printFiles[0].materialSpu.materialSnapshot.spuList)
      ) {
        get(
          item,
          i => i.printFiles[0].materialSpu.materialSnapshot.spuList,
        ).forEach((spu, j) => {
          if (get(spu, s => s.materialSnapshot)) {
            this.items[
              index
            ].printFiles[0].materialSpu.materialSnapshot.spuList[
              j
            ].materialSnapshot.allSpuList = [];
            this.items[
              index
            ].printFiles[0].materialSpu.materialSnapshot.spuList[
              j
            ].materialSnapshot.spuIds = [];
            this.items[
              index
            ].printFiles[0].materialSpu.materialSnapshot.spuList[
              j
            ].materialSnapshot.spuList = [];
          }
        });
      }
    });
  }
}
