export const loadScript = (
  scriptId: string,
  scriptUrl: string,
  scriptType = 'text/javascript',
) => {
  return new Promise<any>((res, rej) => {
    const s = document.getElementById(scriptId);
    if (!s) {
      const script = document.createElement('script');
      script.id = scriptId;
      script.src = scriptUrl;
      script.type = scriptType;
      script.onerror = rej;
      script.async = true;
      script.onload = res;
      script.addEventListener('error', rej);
      script.addEventListener('load', res);
      (document.head || document.documentElement).appendChild(script);
    } else {
      res(undefined);
    }
  });
};

export const loadStyle = (styleId: string, styleUrl: string) => {
  return new Promise<any>((res, rej) => {
    const s = document.getElementById(styleId);
    if (!s) {
      const lnk = document.createElement('link');
      lnk.id = styleId;
      lnk.href = styleUrl;
      lnk.rel = 'stylesheet';
      lnk.type = 'text/css';
      lnk.onload = res;
      lnk.addEventListener('error', rej);
      lnk.addEventListener('load', res);
      (document.head || document.documentElement).appendChild(lnk);
    } else {
      res(undefined);
    }
  });
};
