import { ceil } from 'lodash-es';

import { i18nFormat } from '../../../i18n';
import { IsomorphicLocalStorage } from '../../../skeleton/env/local-storage-utils';
import { getBuiltinImageCollection } from '../../types';
import * as S from '../../utils';

export const formateRegularPrice = (price: any) => {
  return price ? `${ceil(price, 2).toFixed(2)}` : '-';
};

export type TechCategoryType = 'CNC' | '3DPRINT' | 'VACUUM_CASTING';

export interface TechCategory {
  name: string;
  displayName: string;
  type: TechCategoryType;
  icon: string;
  processes: string;
  extensions: string[];
}

/**
 * 给定技术支持的文件扩展名类型描述
 */
export function getTechnologySupportedFiles(
  tech: TechCategory | undefined,
): string {
  return S.get(tech, t => t.extensions, [])
    .map(v => `*.${v}`)
    .join(',');
}

/**
 * 给定类型文件（后缀）是否使用适用给定生产技术
 */
export function isFileFitTechCategory(
  tech: TechCategory | undefined,
  nameOrExtension: string | string,
): boolean {
  nameOrExtension = nameOrExtension
    ? nameOrExtension.trim().toLowerCase()
    : nameOrExtension || '';
  for (let ext of tech?.extensions || []) {
    ext = (ext || '').toLowerCase();
    if (nameOrExtension.endsWith(ext)) {
      return true;
    }
  }
  return false;
}

export const getBuiltinTechCategoryList: () => TechCategory[] = () => {
  return [
    {
      // 注意，这里的 name 会用于进行材料关联筛选，所以设置了线上线下不一致
      name:
        typeof window !== 'undefined' && window.gConfig?.ENV === 'dev'
          ? '3D 打印'
          : '3D Printing',
      displayName: '3D Printing',
      type: '3DPRINT',
      icon: S.get(
        '',
        () => getBuiltinImageCollection().QUOTE_SERVICE_ICON_3D_PRINTING,
      ) as string,
      processes: 'SLA, MJF, SLS, SLM, FDM',
      extensions: ['stl', 'obj', 'step', 'stp', 'igs', 'iges'],
    },
    {
      name: 'CNC Machining',
      type: 'CNC',
      displayName: 'CNC',
      icon: S.get(
        '',
        () => getBuiltinImageCollection().QUOTE_SERVICE_ICON_CNC,
      ) as string,
      processes: 'Milling, Turning, Horizontal',
      extensions: ['step', 'stp', 'dxml', 'catpart', 'x_t', 'igs', 'iges'],
    },
    {
      name: 'Vacuum Casting',
      type: 'VACUUM_CASTING',
      displayName: 'Vacuum Casting',
      icon: S.get(
        '',
        () => getBuiltinImageCollection().QUOTE_SERVICE_ICON_CNC,
      ) as string,
      processes: 'Sillicone Mold',
      extensions: ['step', 'stp', 'dxml', 'catpart', 'x_t', 'igs', 'iges'],
    },
  ];
};

export const lengthUnitPriceFactor: Record<string, number> = {
  MILLIMETER: 1,
  CENTIMETER: 10,
  INCH: 25.4,
};

export const getDefaultTechCategory = () => {
  const localTechType = IsomorphicLocalStorage.getItem('techCategoryType');

  const defaultTechCategory = localTechType
    ? (getBuiltinTechCategoryList().find(
        el => el.type === localTechType,
      ) as TechCategory) || getBuiltinTechCategoryList()[0]
    : getBuiltinTechCategoryList()[0];

  return defaultTechCategory;
};
