import * as S from '../../../schema';
import { getConfig } from '../common/kv_config';

/** 获取及更新被禁止的模块 */
export async function getConfigAndParse(
  key: S.TenantConfigKey,
  tenantId?: S.Id,
) {
  const resp = tenantId
    ? await getConfig([key], 'TENANT', { tenantId })
    : await getConfig([key], 'GLOBAL');

  try {
    const v = JSON.parse(resp[key as string]);
    return typeof v === 'boolean' ? `${v}` : v;
  } catch (e) {
    return typeof resp[key] === 'boolean' ? `${resp[key]}` : resp[key];
  }
}

export async function getTenantConfigByKeys(tenantId: S.Id, keys: string[]) {
  return (await getConfig(keys, 'TENANT', { tenantId })) || {};
}
