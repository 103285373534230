import Typography from 'antd/lib/typography';
import cn from 'classnames';

export interface EllipsisProps {
  className?: string;
  style?: Record<string, string | number>;

  children: string | number;
  maxWidth: number | string;

  fontColor?: 'white' | 'black' | 'inherit';
}

export const Ellipsis = ({
  className,
  style = {},
  children,
  maxWidth = 120,
  fontColor = 'inherit',
}: EllipsisProps) => {
  return (
    <Typography.Paragraph
      id="Ellipsis"
      className={cn(className)}
      ellipsis={{ tooltip: children }}
      style={{
        maxWidth,
        marginBottom: 0,
        overflow: 'hidden',
        display: 'inline-block',
        color: fontColor,
        ...style,
      }}
    >
      {children}
    </Typography.Paragraph>
  );
};

Ellipsis.displayName = 'Ellipsis';
