import { CheckCircleOutlined } from '@ant-design/icons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Button } from 'antd';
import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { getUseShopQuoteStoreByCode } from '@/stores';

import styles from './index.module.less';

export const ShopQuoteCheckoutSuccessPage = () => {
  const navigate = useNavigate();
  const orderCode = useParams().orderCode;

  const { shopOrder } = getUseShopQuoteStoreByCode(orderCode)(s => ({
    shopOrder: s.shopOrder,
  }));

  useEffect(() => {
    if (!orderCode) {
      // 该页面只应该由 checkout 转过来
      console.warn('illegal state, no order loaded (quote checkout page.');
      navigate('/');
    }
  }, []);

  if (!orderCode) {
    return <></>;
  }

  return (
    <section className={styles.container}>
      <div className={styles.result}>
        <div className={styles.successIcon}>
          <CheckCircleOutlined />
        </div>

        <div className={styles.thanks}>
          {i18nFormat('Thank you for your order')} !
        </div>
        <div className={styles.tips}>
          <span>{`We will complete reviewing your order in 24 hours (Except special order), `}</span>
          <span>{`and you can receive result from your email !`}</span>
        </div>

        <div className={styles.info}>
          <span>
            {i18nFormat('Your order code')}: {orderCode}
          </span>
        </div>

        <div className={styles.goDetail}>
          <Link to={`/orders_history/${orderCode}?id=${shopOrder.id}`}>
            <Button type="primary">{i18nFormat('See the Order Detail')}</Button>
          </Link>
        </div>
      </div>
    </section>
  );
};
