import { notification } from 'antd';

import {
  ApiErrorContext,
  getUfcShopComposedApi,
  getUfcShopRuntimeEnv,
  setUfcShopComposedApi,
  UfcShopApiConfig,
  UfcShopComposedApi,
} from '../../apis';
import * as S from '../../schema';

/** 动态设置请求头 */
export function setupDtcRequestApi(headers: Record<string, string>) {
  const prevApiConfig = getUfcShopComposedApi()?.apiConfig || {};

  setUfcShopComposedApi(
    new UfcShopComposedApi(
      new UfcShopApiConfig({
        apiEndpoint: getUfcShopRuntimeEnv().apiEndpoint,

        ...prevApiConfig,

        headers,
        onError: (e: ApiErrorContext) => {
          const url = e.requestConfig.url;
          const { pathname } = S.parseUrl(url);

          if (e.error.statusCode === 401) {
          }

          // TODO: 执行接口异常上报

          // 仅提示 500 的异常
          notification.error({
            key: 'request-error',
            message: `Request error, ${e.error.code}(${e.error.statusCode})`,
            description: `${pathname || url}(Exception ID: ${
              e.error.exceptionId
            }): ${e.error.message}`,
          });
        },
      }),
    ),
  );
}
