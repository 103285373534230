import { SearchOutlined } from '@ant-design/icons';
import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { DatePicker, Input, Select, Space } from 'antd';
import { ConfigProvider } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import styles from './DateRangeSelector.module.less';

import locale_ru from 'antd/locale/ru_RU';
import locale_en from 'antd/locale/en_US';

import 'dayjs/locale/ru';

import { getLocale } from '@unionfab/ufc-shop-commons';

export interface SelectInputProps {
  searchText: string;
  setSearchText: (val: string) => void;
  setSearchType?: (val: string) => void;
  onSearch?: () => void;
}

function ErrorFallback({
  error,
  resetErrorBoundary,
}: {
  error: Error;
  resetErrorBoundary: () => void;
}) {
  React.useEffect(() => {
    console.log('>>>DateRangeSelector>>>', error.message);
  }, []);

  return (
    <div role="alert">
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

export const SelectInput = ({
  searchText,
  setSearchText,
  onSearch,
}: SelectInputProps) => {
  const prefix = (
    <div className={styles.prefix}>
      <SearchOutlined style={{ fontSize: '1rem' }} />
    </div>
  );

  return (
    <Input
      allowClear
      prefix={prefix}
      value={searchText}
      placeholder={i18nFormat('Search')}
      className={styles.search}
      defaultValue={searchText}
      onPressEnter={() => onSearch && onSearch()}
      onChange={({ target: { value } }) => setSearchText(value)}
    />
  );
};

export interface CreateTimePickerProps {
  sort: S.InquiryOrderSortByProperty;
  date?: [Dayjs | undefined, Dayjs | undefined];
  setDate: (val: [Dayjs | undefined, Dayjs | undefined]) => void;
  setSortType: (val: S.InquiryOrderSortByProperty) => void;
}

export const CreateTimePicker = ({
  date,
  sort,
  setDate,
  setSortType,
}: CreateTimePickerProps) => {
  if (getLocale() === 'ru') {
    dayjs.locale('ru');
  } else {
    dayjs.locale('en');
  }

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={e => {
        console.error('>>>DateRangeSelector>>>onError>>>error>>>', e);
      }}
      onReset={() => {}}
    >
      <Space size={38} className={styles.createTimePicker}>
        <Space>
          <span className={styles.label}>{i18nFormat('Sort By')}</span>
          <Select
            value={sort}
            className={styles.createTimeSortSelector}
            options={[
              { label: i18nFormat('Default'), value: 'desc(createTime)' },
              { label: i18nFormat('Total'), value: 'desc(price)' },
              { label: i18nFormat('Last Updated'), value: 'desc(updateTime)' },
            ].map(({ label, value }) => ({ label, value }))}
            onChange={value => setSortType(value)}
          />
        </Space>
        <Space>
          <span className={styles.label}>{i18nFormat('Create Date')}</span>
          <React.Suspense fallback={<></>}>
            <ConfigProvider
              locale={getLocale() == 'ru' ? locale_ru : locale_en}
            >
              <DatePicker.RangePicker
                value={date}
                className={styles.createTimeRangePicker}
                placeholder={[i18nFormat('Start Date'), i18nFormat('End Date')]}
                disabledDate={date => date.isAfter(dayjs())}
                onChange={(values, _) => {
                  values ? setDate(values) : setDate([undefined, undefined]);
                }}
              />
            </ConfigProvider>
          </React.Suspense>
        </Space>
      </Space>
    </ErrorBoundary>
  );
};
