import dayjs from 'dayjs';

export const GA4BuiltinEvents = [
  'page_view',
  'screen_view',
  'search',
  'login',
  'select_content',
  'sign_up',
  'purchase',
  'add_payment_info',
  'add_shipping_info',
  'add_to_cart',
  'remove_from_cart',
  'add_to_wishlist',
  'begin_checkout',
  'checkout_progress',
  'set_checkout_option',
  'generate_lead',
  'refund',
  'remove_from_cart',
  'select_item',
  'select_promotion',
  'view_cart',
  'view_item',
  'view_item_list',
  'view_promotion',
  'view_search_results',
  'quotation_submitted',
  'quotation_saved',
  'refund',

  'request_quote_3dp_ytads',
  'submit_info_freemetal',
  'page_view_homepage_from_googles',
  'website_click_3dp_ytads',
] as const;

export type GA4BuiltinEventType = typeof GA4BuiltinEvents[number];

/**
 *
 * 不管任何事件必填参数：

未登录用户ID：sessionId；会话ID：conversationId；平台：terminal(PC/PHONE)；事件发生时间：occurrenceTime；事件类型名称：typeName；
 */

export type UserTrackEventType =
  /** 以下是 GA 的标准事件 */
  | GA4BuiltinEventType
  /** 以下是 Unionfab 自定义事件 */
  | 'login_failed'
  | 'invitation_to_register' // 邀请注册
  | 'typed_email_on_registration' // 注册时，填写邮箱
  | 'keep_live' // 用户在任何界面停留超过10秒（对于未登录用户，通过识别用户曾经登录的ID在Cookie中的记录，进行无登录日志记录）；【typeName=keep_live ; 页面URL_ID: urlId；停留时长: stayTime;】
  | 'upload_file' // 用户上传文件；【typeName= upload_file；文件个数: fileCount；文件名（前3即可存对应文件ID集合）: fileIds】
  | 'preview_file' // 预览模型文件
  | 'bulk_choose_material' // 批量修改
  | 'choose_material' // 用户选择材料；【typeName= choose_material；材料名称 (存对应材料ID): materialId】
  | 'newperson_login' // 新用户进行登录； 【typeName=newperson_login】
  | 'oldperson_login' // 老用户进行登录；【typeName=oldperson_login】
  | 'enter_placeorder' // 用户进入下单界面；【typeName= enter_placeorder】
  | 'enter_order_wait_confirm' // 用户进入订单待确认界面；【typeName=enter_order_wait_confirm；询价金额: inquiryMoney】
  | 'pay_way' // 用户调用支付接口；【typeName=pay_way；待支付金额: unpaidMoney，支付方式: paymentMethod】
  | 'order_success' // 订单完成创建；【typeName=order_success；订单编号: orderCode，订单金额 : orderMoney】
  | 'placeorder_page_end' // 用户在下单界面结束会话（离开网站）；【typeName=placeorder_page_end；询价金额 inquiryMoney】
  | 'login_page_end' // 用户在登录界面结束会话（离开网站）；【typeName=login_page_end】
  | 'register_page_end' // 用户在注册界面结束会话（离开网站）；【typeName=register_page_end】
  | 'unpaid_page_end' // 用户在待支付界面结束会话（离开网站）；【typeName=unpaid_page_end；待支付金额: unpaidMoney，支付方式: paymentMethod】
  | 'order_wait_confirm_end' // 用户在订单待确认界面结束会话（离开网站）；【typeName=order_wait_confirm_end；询价金额: inquiryMoney】
  | 'person_end_conversion' // 用户结束会话；【typeName=person_end_conversion ;结束会话前的最后页面URL_ID: urlId；停留时长 : stayTime;】
  | 'newpreson_start_conversation' // 新用户开启会话；【typeName=newpreson_start_conversation；访问来源: accessSource】
  | 'oldperson_start_conversation' // 老用户开启会话； 【typeName=oldperson_start_conversation；访问来源: accessSource】
  | 'placeOrderQuoteIssue'; // 发现报价存在异常

export function mapUserTrackEventTypeToGA4Type(
  type: UserTrackEventType,
): GA4BuiltinEventType {
  if (GA4BuiltinEvents.includes(type as GA4BuiltinEventType)) {
    return type as GA4BuiltinEventType;
  }

  switch (type) {
    case 'upload_file':
      return 'add_to_cart';

    case 'order_success':
      return 'quotation_submitted';

    case 'pay_way':
      return 'purchase';

    case 'choose_material':
    case 'bulk_choose_material':
      return 'select_item';

    default:
      return type as GA4BuiltinEventType;
  }
}

export class UserTrackEventRecord {
  /** 前端给定的客户唯一标识，实际用户是根据 Token 来给定 */
  sessionId!: string;

  /** 会话值，根据自定义窗口期判断是否需要更改会话 */
  conversationId!: string;

  /** 终端 */
  terminal!: 'PC' | 'MOBILE';

  occurrenceTime: string;

  typeName!: UserTrackEventType;

  accessSource!: string;

  urlId!: string;

  stayTime?: number;

  fileCount?: number;

  fileIds?: string[];

  materialId?: string;

  inquiryMoney?: number;

  unpaidMoney?: number;

  paymentMethod?: string;

  orderCode?: string;

  orderMoney?: number;

  ipAddress?: string;

  userAgent?: string;

  clientData?: Record<string, any>;

  eventData?: Record<string, string | number>;

  origin?: string;

  referer?: string;

  value?: number;

  constructor(data: Partial<UserTrackEventRecord>) {
    Object.assign(this, data);

    this.occurrenceTime = dayjs().toISOString();

    if (!this.urlId) {
      this.urlId = window.location.href;
    }
  }
}
