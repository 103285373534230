import { wrapSingletonAsyncFunc } from '@m-fe/ts-async';

import * as S from '../../../../schema';
import {
  getUfcShopComposedApi,
  hasUserLogined,
} from '../../../../singleton-ins';
import { getModelFileMapByIdsLoader } from './d3_model_query';

const getFileByIdCache: Record<string, S.File> = {};

export async function getCacheableFileById(fileId: S.Id): Promise<S.File> {
  if (!fileId) {
    return undefined;
  }

  if (getFileByIdCache[fileId]) {
    return getFileByIdCache[fileId];
  }

  const file = await getFileById(fileId);
  getFileByIdCache[fileId] = file;

  return file;
}

/** 获取某个文件详情 */
async function getFileById(fileId: S.Id) {
  const { data } =
    (await getUfcShopComposedApi().umiRequestCompatibleApi.getUmi<{
      data: S.File;
    }>(!hasUserLogined() ? `/noauth/files/${fileId}` : `/files/${fileId}`)) ||
    {};

  const file = new S.File(data);

  // 如果不存在 URL，则进行获取
  if (!file.url && S.isValidArray(file.stores)) {
    // 不存在文件 url 则进行预签名
    const store = file.stores[0];

    file.url = await getCacheablePreSignedUrl(fileId, store.storeId);
  }

  return file;
}

export async function getFilesByIds(
  fileIds: S.Id[],
): Promise<Record<string, S.File>> {
  // 如果没有 fileIds，则直接返回
  if (!S.isValidArray(fileIds)) {
    return {};
  }

  const { data = [] } =
    (await getUfcShopComposedApi().umiRequestCompatibleApi.postUmi<{
      data: S.File[];
    }>(`/files/batch`, {
      data: fileIds.filter(i => !!i).filter(i => !i.includes(' ')),
    })) || {};

  const files = data.map(d => new S.File(d));

  return files.reduce((prev, cur) => {
    prev[cur.id] = cur;

    return prev;
  }, {});
}

/** 获取图片文件的下载地址 */
export async function getImagePreSignedUrl(fileId: S.Id, storeId?: S.Id) {
  return getCacheablePreSignedUrl(fileId, storeId);
}

const preSignedUrlCache = {};

export async function getCacheablePreSignedUrl(fileId: S.Id, storeId?: S.Id) {
  if (!fileId) {
    return '';
  }

  if (preSignedUrlCache[`${fileId}-${storeId}`]) {
    return preSignedUrlCache[`${fileId}-${storeId}`];
  }

  const singletonGetPreSignedUrl = wrapSingletonAsyncFunc<S.Id>(
    getPreSignedUrl,
    {
      fnKey: 'getCacheablePreSignedUrl',
      serialize: ([fileId]) => {
        return `${fileId}`;
      },
    },
  );

  const url = await singletonGetPreSignedUrl(fileId, storeId);

  preSignedUrlCache[`${fileId}-${storeId}`] = url;

  return url;
}

/**
 * 获取某个文件的下载地址
 * TODO: 该接口添加缓存，缓存为一天
 */
export async function getPreSignedUrl(fileId: S.Id, storeId?: S.Id) {
  if (!fileId) {
    return '';
  }

  const { data } =
    (await getUfcShopComposedApi().umiRequestCompatibleApi.postUmi<{
      data: Record<S.Id, { url: string }>;
    }>(`/v2/shop/file/presign_urls`, {
      data: { fileIds: [fileId] },
    })) || {};

  if (!data) return '';

  let url = S.get(data, d => d[fileId].url, '');

  // 这里对 OSS 进行额外处理，如果是 OSS 的则强制为 https
  if (url.indexOf('aliyuncs.com') > -1) {
    url = url.replace('http://', 'https://');
  }

  return url;
}

/**
 * 创建文件
 * 这里也支持直接以 URL 创建文件，storeKey 就是 URL，fileStore 选择当前租户类型为 HTTP_READ_ONLY 的存储
 */
export async function createFile(
  fileStore: S.FileStore,
  storeKey: string,
  attrs: Partial<S.D3FormatAttr>,
): Promise<S.Id> {
  const { data } =
    (await getUfcShopComposedApi().umiRequestCompatibleApi.postUmi<{
      data: { id: S.Id };
    }>(!hasUserLogined() ? `/noauth/files` : `/files`, {
      data: {
        acl: fileStore.acl,
        attributes: attrs,
        storeId: fileStore.id,
        storeKey,
      },
    })) || {};

  // 返回生成的文件 ID
  return (S.get(data, data => data.id) || data) as S.Id;
}

/** 复制某个文件 */
export async function copyFile(file: S.File) {
  const storeInfo = file.stores[0];

  return createFile(
    new S.FileStore({
      id: storeInfo.storeId,
      acl: storeInfo.acl as S.FileStoreAclType,
    }),
    storeInfo.storeKey,
    file.attr,
  );
}

/** 同步某个文件到其他的 store */
export async function syncFileToStore(
  fileId: S.Id,
  storeId: S.Id,
  storeUploadOption?: S.FileStoreUploadOption,
) {
  const data: any = {
    fileId,
    storeId,
  };

  if (storeUploadOption) {
    data.option = { storeUploadOption };
  }

  const { status } =
    (await getUfcShopComposedApi().umiRequestCompatibleApi.postUmi<{
      status: string;
    }>(`/files/sync`, {
      data,
    })) || {};

  return status === 'ok';
}

export async function updateModelFileAttr(
  fileId: S.Id,
  attr: Partial<S.D3FormatAttr> = {}, // 这里传入的是实际需要更新的属性
) {
  // 清除本地缓存
  getModelFileMapByIdsLoader.removeInCache([fileId]);

  // 清除本地模型缓存
  return updateFileAttr(fileId, attr);
}

// 更新文件的属性
export async function updateFileAttr(
  fileId: S.Id,
  attr: Partial<S.D3FormatAttr> = {}, // 这里传入的是实际需要更新的属性
) {
  const newAttr = { ...attr };

  delete newAttr.name;
  delete newAttr.size;
  delete newAttr.md5;

  // 不存在文件，则返回异常
  if (!fileId) {
    return 'error';
  }

  // 判断是否存在了无效的体积，如果存在，强弹窗提示异常，但是还是允许更新
  if (attr.volume === null || attr.volume === 0) {
    // 这里需要将无效的 volume 属性移除
    delete attr.volume;
  }

  const {
    status,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.putUmi(
    !hasUserLogined()
      ? `/noauth/files/${fileId}/attribute`
      : `/files/${fileId}/attribute`,
    {
      data: newAttr,
    },
  );

  return status === 'ok';
}
