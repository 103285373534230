import * as S from '@unionfab/ufc-shop-commons';
import { Select } from 'antd';
import { useEffect, useMemo } from 'react';

import { useInquiryHandleMethod, useInquiryQuoteEditorStore } from '@/stores';
import { useInquiryMaterialStore } from '@/stores';

import styles from './index.module.less';

// 订单条目后处理方式选择
export const OrderItemFinishSelect = ({
  orderItem,
}: {
  orderItem: S.InquiryOrderItem;
}) => {
  const { updateInquiryOrder } = useInquiryQuoteEditorStore();

  const {
    materialTypeIdToHandleMethods,
    loadInquiryHandleMethods,
  } = useInquiryHandleMethod();

  const {
    materialTypes: inquiryMaterialTypes,
    loadMaterialTypeHandleMethods,
  } = useInquiryMaterialStore();

  const curMaterialType = S.get(inquiryMaterialTypes, i =>
    i.find(m => orderItem.materialSpu.materialTypeId === m.id),
  );
  const printFile = orderItem.printFiles[0];

  useEffect(() => {
    if (curMaterialType?.id) {
      loadMaterialTypeHandleMethods([curMaterialType.id]);
    }
  }, [curMaterialType]);

  useEffect(() => {
    if (!S.isValid(materialTypeIdToHandleMethods)) {
      loadInquiryHandleMethods();
    }
  }, [materialTypeIdToHandleMethods]);

  const onUpdateInquiryOrderHandleMethod = (
    finish: string,
    color: S.HandleMethodColor,
  ) => {
    updateInquiryOrder('updateFileItemHandleMethod', () => {
      orderItem.printFiles[0].handle = {
        desc: color.name,
        method: finish && finish !== 'Standard' ? [finish] : [],
      };
    });
  };

  const availableHandleMethods = useMemo(() => {
    return materialTypeIdToHandleMethods[curMaterialType?.id] || [];
  }, [materialTypeIdToHandleMethods, curMaterialType?.id]);

  const value = useMemo(() => {
    const colorDesc = S.get(printFile, f => f.handle.desc, '');

    const handleMethods = (printFile.handle.method || []).filter(h => h !== '');
    const initialHandleMethod =
      handleMethods.length > 0 ? handleMethods[0] : 'Standard';

    return `${initialHandleMethod} ${
      colorDesc.length > 0 ? `-PANTONE ${colorDesc}` : ''
    }`.trim();
  }, [printFile]);

  return (
    <div className={styles.container}>
      <Select
        allowClear
        value={value}
        size="small"
        options={(availableHandleMethods || []).map(m => ({
          value: m.name,
          label: m.name,
        }))}
        onChange={v =>
          onUpdateInquiryOrderHandleMethod(v, { name: '', color: '' })
        }
      />
    </div>
  );
};
