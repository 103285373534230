import { BaseEntity, CurrencyAndAmount, Id } from '../../common';
import {
  OceanpaymentBilling,
  OceanpaymentInfo,
  OceanpaymentShip,
} from './oceanpayment';

export type PaymentChannel = 'paypal' | 'oceanpay' | 'bank-transfer';

export class PaymentMethod extends BaseEntity<PaymentMethod> {
  id: Id;
  name: string;
  enable: boolean;
  support: boolean;
  tenantId: Id;

  constructor(props?: Partial<PaymentMethod>) {
    super(props);

    Object.assign(this, props);
  }
}

export interface CreatePaypalPayOrderParams {
  /** 支付类型 */
  type: 'PAYPAL';
  /** 优惠券 */
  couponCode?: string;

  returnUrl?: string;
  cancelUrl?: string;
}

export interface CreateOceanpaymentPayOrderParams {
  /** 支付类型 */
  type: 'OCEAN_PAY';
  /** 优惠券 */
  couponCode?: string;
  description?: string;

  backUrl?: string;
  methods: 'Credit Card';
  ship: Partial<OceanpaymentShip>;
  billing: Partial<OceanpaymentBilling>;
}

export const PayOrderStatusList = [
  'WAIT_PAY',
  'COMPLETE_PAY',
  'EXCEPTION_PAY',
  'CANCEL_PAY',
  'CAPTURING',
];

export const CanOrderPayStatusList = ['WAIT_PAY', 'CAPTURING'];

export type PayOrderStatus = typeof PayOrderStatusList[number];

/** 创建的支付订单 */
export interface PayOrder {
  payOrderType: 'PAYPAL' | 'OCEAN_PAY';

  id: string;
  tenantId: string;
  orderId: string;
  payerId: string;
  amount: CurrencyAndAmount;
  status: PayOrderStatus;
  orderPayStatus: PayOrderStatus;

  outsideCode: string;
  couponCode?: string;

  createdAt: string;
  updatedAt: string;
  completedAt?: string;

  /** 过期时间，单位为 秒 为 0 则代表支付订单过期 */
  validSec: number;
  statusTriggerData: any;
}

export interface PaypalPayOrder extends PayOrder {
  payPalOrder: {
    id: string;
    status:
      | 'CREATED'
      | 'SAVED'
      | 'APPROVED'
      | 'VOIDED'
      | 'PAYER_ACTION_REQUIRED'
      | 'COMPLETED';
  };
}

export interface OceanpaymentPayOrder extends PayOrder {
  oceanPayOrder: Partial<OceanpaymentInfo>;
}

const LK_PAY_ORDER_ID = 'LK_PAY_ORDER_ID';

export const setPayOrderIdToLocalStorage = (o: {
  tenantId: string;
  orderCode: string;
  payOrderId: string;
}) => {
  if (typeof window !== 'undefined') {
    const key = `${LK_PAY_ORDER_ID}_${o.tenantId}_${o.orderCode}`;

    window.localStorage.setItem(key, o.payOrderId);
  }
};

export const getPayOrderIdFromLocalStorage = (
  orderCode: string,
  tenantId: string,
) => {
  if (typeof window !== 'undefined') {
    const key = `${LK_PAY_ORDER_ID}_${tenantId}_${orderCode}`;

    return window.localStorage.getItem(key);
  }
};

export const removeLocalStoragePayOrderId = (
  orderCode: string,
  tenantId: string,
) => {
  if (typeof window !== 'undefined') {
    const key = `${LK_PAY_ORDER_ID}_${tenantId}_${orderCode}`;

    window.localStorage.removeItem(key);
  }
};
