export const DefaultObjectAvatarPlaceholder =
  'https://ufc-assets.oss-cn-shanghai.aliyuncs.com/migration/22.png?x-oss-process=image/resize,w_150';

export const DefaultNullD3Model = undefined;

/** 可选的交付时间 */
export const DeliverySelections = [24, 48, 72, 96];

/** 可选的幅面大小 */
export const DeviceSizeSelections = ['普通幅面', '1400', '2100'];
