import { useAsyncEffect } from 'ahooks';
import { Spin } from 'antd';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { toggleUnionfabWpHeaderVisibility } from '@/commons/utils/unionfab-site';
import { useOrderSubmitState } from '@/features/new_quote/hooks/useOrderSubmitState';
import { useAppNavigate } from '@/features/shared';
import { useInquiryQuoteCheckoutStore } from '@/stores';

import { useOrderPrices } from '../../../../hooks/useOrderPrices';
import { InquiryOrderCheckoutStep } from '../order-checkout-fields/types';
import styles from './index.module.less';
import { OrderQuoteCheckoutPanelLayout } from './OrderQuoteCheckoutPanelLayout';
import { OrderQuoteCheckoutSummary } from './OrderQuoteCheckoutSummary';
export * from './OrderQuoteCheckoutSuccessPage';

export interface CheckoutPageProps {
  className?: string;
  style?: Record<string, string | number>;
}

export const OrderQuoteCheckoutPage = ({
  style,
  className,
}: CheckoutPageProps) => {
  const store = useInquiryQuoteCheckoutStore();
  const navigation = useAppNavigate();
  const { isLoadingOrder } = store;

  const [searchParams] = useSearchParams();
  const orderCode = searchParams.get('orderCode');
  const submitState = useOrderSubmitState();

  let order = store.order;
  order = order && order.code === orderCode ? order : undefined;

  const [visibleSteps, setVisibleSteps] = useState<InquiryOrderCheckoutStep[]>([
    'select-ship-address',
    'select-ship-option',
    'order-remark',
  ]);

  useEffect(() => {
    const prevVisible = toggleUnionfabWpHeaderVisibility(false);
    return () => {
      toggleUnionfabWpHeaderVisibility(prevVisible);
    };
  }, []);

  useEffect(() => {
    if (order) {
      switch (order.status) {
        case 'WAIT_SUBMIT':
        case 'WAIT_REVIEW':
          break;
        case 'WAIT_CUSTOM_CONFIRM':
          // 待客户确认状态，直接进入支付页面
          navigation.navigateToOrderPayment(order.code);
          break;
        default:
          // 其它状态，转入订单详情
          navigation.navigateToOrderDetail(order.code);
      }
    }
  }, [order, navigation]);

  useAsyncEffect(async () => {
    if (!orderCode) {
      console.log('No order found, nav to home (from checkout page).');
      navigation.navigateToHome();
    } else {
      await store.loadOrder(orderCode);
    }
  }, [orderCode]);

  const quoteOrderPrices = useOrderPrices();

  return (
    <div className={cn(className, styles.container)} style={{ ...style }}>
      <Spin spinning={isLoadingOrder}>
        <div className={styles.checkoutBody}>
          <OrderQuoteCheckoutPanelLayout
            visibleSteps={visibleSteps}
            setVisibleSteps={setVisibleSteps}
            quoteOrderPrices={quoteOrderPrices}
            submitState={submitState}
          />

          <div className={styles.summaryWrapper}>
            <OrderQuoteCheckoutSummary
              submitState={submitState}
              quoteOrderPrices={quoteOrderPrices}
            />
          </div>
        </div>
      </Spin>
    </div>
  );
};
