import 'react-phone-number-input/style.css';

import classnames from 'classnames';
import { lazy, Suspense, useMemo } from 'react';
import PhoneInput, {
  Country,
  getCountries,
  getCountryCallingCode,
} from 'react-phone-number-input';

import styles from './index.module.less';

const CountryFlag = lazy(() => import('../../Country/CountryFlag'));

export const PhoneNumberInput = ({
  value,
  defaultCountryIso2,
  onChange,
  className,
}: {
  className?: string;
  value?: string;
  defaultCountryIso2?: string;
  onChange: (phoneNumber: string) => void;
}) => {
  const countries = useMemo(() => getCountries(), []);

  try {
    getCountryCallingCode(defaultCountryIso2 as any);
  } catch (e) {
    defaultCountryIso2 = undefined;
  }

  return (
    <PhoneInput
      international={true}
      className={classnames(styles.phoneNumberInput, className)}
      flagComponent={p => {
        // TW  as an area of China
        const iso2 = p.country === 'TW' ? 'CN' : p.country;
        return (
          <span style={{ width: '22px' }}>
            <Suspense fallback={<span></span>}>
              <CountryFlag iso2={iso2} title={p.countryName} />
            </Suspense>
          </span>
        );
      }}
      countries={countries}
      value={value}
      onChange={v => onChange(v as string)}
      defaultCountry={defaultCountryIso2 as Country}
    />
  );
};
