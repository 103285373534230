import 'animate.css';
import './index.module.less';

import { getLocale, isMobile } from '@unionfab/ufc-shop-commons';
import { useInterval } from 'ahooks';
import { ConfigProvider } from 'antd';
import { useEffect, useMemo, useState } from 'react';

import { useGlobalStore } from '@/stores';

import { AppContextProvider } from './app-context';
import { AppRoutes } from './app-routes';
import { ContactModal } from './ContactModal';

const toggleHeader = (visible: boolean) => {
  const x: any = document.getElementById('site-header');

  if (x == null || x.style == null) {
    return;
  }
  if (visible && x.style.display === 'none') {
    x.style.display = '';
  }
  if (!visible && x.style.display !== 'none') {
    x.style.display = 'none';
  }
};

export function App() {
  const { init, initialized, showHeader } = useGlobalStore(g => ({
    init: g.init,
    loadUser: g.loadUser,
    showHeader: g.showHeader,
    initialized: g.initialized,
    hasAuthenticated: g.hasAuthenticated,
    isDemoUser: g.isDemoUser,
  }));

  /** antd 语言包 */
  const [antdLocale, setLocale] = useState<any>();

  // 设置 antd 语言包
  useEffect(() => {
    getLocalePackage();
    async function getLocalePackage() {
      const lanMap = new Map([
        ['ru', async () => await import('antd/es/locale/ru_RU')],
        // 后续可能有其他语言
      ]);
      const lanImport = lanMap.get(getLocale() as string);
      const lanPackage = await lanImport?.();
      setLocale(lanPackage?.default);
    }
  }, []);

  useEffect(() => {
    !initialized && init();
  }, [initialized]);

  useEffect(() => {
    toggleHeader(showHeader);
  }, [showHeader]);

  useInterval(() => {
    toggleHeader(showHeader);
  }, 3000);

  const rootStyle: React.CSSProperties = useMemo(() => {
    if (showHeader) {
      return {
        overflow: 'hidden',
        height: 'calc(100vh - 90px)',
        marginTop: isMobile() ? '0.5rem' : '0',
      };
    }

    return { height: '100%' };
  }, [showHeader]);

  const content = useMemo(() => {
    if (!initialized) return <></>;

    return (
      <AppContextProvider>{initialized && <AppRoutes />}</AppContextProvider>
    );
  }, [initialized]);

  return (
    <div style={rootStyle} className="pl-0 flex">
      <ConfigProvider
        locale={antdLocale}
        theme={{ token: { colorPrimary: '#192C59', colorLink: '#192C59' } }}
      >
        {content}
        <ContactModal />
      </ConfigProvider>
    </div>
  );
}
