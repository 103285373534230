import { PlusCircleOutlined } from '@ant-design/icons';
import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { message, Modal } from 'antd';

import { ReceiveAddressForm } from '../ReceiveAddressForm';
import defaultStyles from './index.module.less';

export const ReceiveAddressCreateCard = ({
  address,
  createAddress,

  modalVisible,
  toggleModal,

  styles,
}: {
  address: S.ReceiveAddress;
  createAddress?: (addr: S.ReceiveAddress) => Promise<void>;

  modalVisible: boolean;
  toggleModal: (visible: boolean) => void;

  styles?: CSSModuleClasses;
}) => {
  styles = styles || defaultStyles;

  async function onAddressFormSubmit(addr: S.ReceiveAddress) {
    const key = 'createReceiveAddress';
    try {
      await createAddress(addr);
      toggleModal(false);
    } catch (e) {
      message.error({ content: i18nFormat('Create Failed'), key });
    } finally {
      message.destroy(key);
    }
  }

  return (
    <div>
      <div
        className={styles.receiveAddressCreateCard}
        onClick={() => toggleModal(true)}
      >
        <PlusCircleOutlined style={{ fontSize: 40 }} />
        <div>{i18nFormat('Add New Address')}</div>
      </div>
      <Modal
        footer={null}
        closable={false}
        open={modalVisible}
        destroyOnClose={true}
        onCancel={() => toggleModal(false)}
        className={styles.newAddressModal}
      >
        <ReceiveAddressForm
          address={address}
          onClose={() => toggleModal(false)}
          onSubmit={onAddressFormSubmit}
        />
      </Modal>
    </div>
  );
};
