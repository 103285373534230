export const LengthUnitList = [
  /** 米 */
  // 'METER',
  /** 厘米 */
  'CENTIMETER',
  /** 毫米 */
  'MILLIMETER',
  /** 英寸 */
  'INCH',
];
export enum LengthUnitEnumValue {
  /** 米 */
  'METER' = 'METER',
  /** 厘米 */
  'CENTIMETER' = 'CENTIMETER',
  /** 毫米 */
  'MILLIMETER' = 'MILLIMETER',
  /** 英寸 */
  'INCH' = 'INCH',
}

export type LengthUnit = typeof LengthUnitList[number];

export const getLengthUnitDesc = (l: LengthUnit): string => {
  switch (l) {
    case 'METER':
      return 'm';
    case 'CENTIMETER':
      return 'cm';
    case 'MILLIMETER':
      return 'mm';
    case 'INCH':
      return 'inch';
    default:
      return '未知';
  }
};
