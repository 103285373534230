import { QuestionCircleOutlined } from '@ant-design/icons';
import * as S from '@unionfab/ufc-shop-commons';
import { CurrencySymbolType, i18nFormat } from '@unionfab/ufc-shop-commons';
import { Button } from 'antd';
import Tooltip from 'antd/es/tooltip';
import { useEffect, useMemo } from 'react';

import { OrderSubmitState } from '@/features/new_quote/hooks/useOrderSubmitState';
import { useInquiryQuoteCheckoutStore } from '@/stores';

import { RfqTooltip } from '../../../../../../../commons/components/Element/RfqTooltip';
import { QuoteOrderPrices } from '../../../../../hooks/useOrderPrices';
import styles from './index.module.less';

export interface OrderQuoteCheckoutSummaryProps {
  submitState: OrderSubmitState;
  quoteOrderPrices: QuoteOrderPrices;
}

const renderPriceEle = ({
  price,
  rfq,
  highlight,
  currency,
}: {
  price?: number;
  rfq?: boolean;
  highlight?: boolean;
  currency?: CurrencySymbolType;
}) => {
  if (!price) {
    rfq = true;
  }

  if (rfq) {
    return (
      <span className={highlight ? styles.highlightPrice : ''}>
        <span className={styles.priceText}>
          {S.toPriceStringWithOption(price, { rfq, currency: currency })}
        </span>
      </span>
    );
  } else {
    return (
      <span className={highlight ? styles.highlightPrice : ''}>
        <span className={styles.priceText}>
          {S.toPriceStringWithOption(price!, { currency: currency })}
        </span>
      </span>
    );
  }
};

export const OrderQuoteCheckoutSummary = ({
  submitState,
  quoteOrderPrices,
}: OrderQuoteCheckoutSummaryProps) => {
  const {
    order,
    estDeliveryTime,
    orderDeliverAddress,
    quotation,
  } = useInquiryQuoteCheckoutStore();

  const { submitting, submitOrder } = submitState;
  const {
    isTotalRfq,
    total,
    shipping,
    shippingRfq,
    subtotal,
    subtotalRfq,
  } = quoteOrderPrices;

  const estDeliveryTimeDesc = useMemo(() => {
    return S.calcEstDeliverTime(estDeliveryTime, subtotalRfq);
  }, [estDeliveryTime, subtotalRfq]);

  useEffect(() => {
    if (!!order && !!order?.code && isTotalRfq) {
      S.UnionfabEventTracker.getIns().reportPlaceOrderQuoteIssue(
        order?.code,
        '订单需要人工报价',
      );
    }
  }, [order, isTotalRfq]);

  const orderCode = S.get(order, o => o.code);
  const currency = S.get(order, o => o.currency);

  const { maxLeadTimeStr, dueDate } = S.calcOrderLeadTimes(order);

  let warningText;

  if (!orderDeliverAddress) {
    warningText = 'Please select a shipping address';
  }

  const disabledQuote =
    !!warningText || S.get(quotation, q => q.shipQuotationsCalculating, false);

  return (
    <div className={styles.orderSummary}>
      <div className={styles.orderBodyBox}>
        <div className={styles.orderBodyTitle}>
          {i18nFormat('Order Summary')}
        </div>
        <div className={styles.orderDetail}>
          <div className={styles.orderInfos}>
            <div className={styles.orderCode}>
              {orderCode ? `Code: ${orderCode}` : i18nFormat('Wait Submit')}
            </div>

            <div className={styles.spaceBetweenEST}>
              <span className={styles.light}>{i18nFormat('Due Date')}:</span>
              <div className={styles.spaceBetweenRight}>
                <span>
                  {!subtotalRfq ? (
                    maxLeadTimeStr
                  ) : (
                    <span className={styles.rfqText}>
                      {maxLeadTimeStr} <RfqTooltip />
                    </span>
                  )}
                </span>
                <div style={{ fontSize: 12 }}>
                  {`(Lead Time: ${dueDate} days)`}
                  {subtotalRfq && <RfqTooltip />}
                </div>
              </div>
            </div>
            <div className={styles.spaceBetween}>
              <span className={styles.light}>{i18nFormat('Delivery')}:</span>
              <span>{estDeliveryTimeDesc}</span>
            </div>
          </div>

          <div className={styles.price} style={{ marginTop: '18px' }}>
            <div className={styles.spaceBetween}>
              <span className={styles.light}>{i18nFormat('Subtotal')}:</span>
              <span>
                {renderPriceEle({
                  price: subtotal,
                  rfq: subtotalRfq,
                  currency: currency,
                })}
              </span>
            </div>

            <div className={styles.spaceBetween}>
              <span className={styles.light}>{i18nFormat('Shipping')}:</span>
              <span className={styles.rfqText}>
                {renderPriceEle({
                  price: shipping,
                  rfq: shippingRfq,
                  currency: currency,
                })}
              </span>
            </div>
          </div>
        </div>

        <div className={styles.totalPrice}>
          <div className={styles.spaceBetween}>
            <span>
              <span className={styles.light}>{i18nFormat('Total')}:</span>
              <span className={styles.vatTip}>
                ( i18nFormat('VAT excluded') )
              </span>
              <Tooltip
                title={i18nFormat(
                  'According to the Trade Terms: DAP, our price excluded import tariffs.',
                )}
                className={styles.vatIcon}
              >
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
            <span
              style={{
                fontSize: '20px',
                fontWeight: 'bold',
                color: '#F89902',
              }}
            >
              {renderPriceEle({
                price: total,
                rfq: isTotalRfq,
                highlight: true,
                currency: currency,
              })}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.submitBtn}>
        {warningText ? (
          <Tooltip title={warningText}>
            <Button type="primary" disabled={true} size="large">
              {i18nFormat('Request a Quote')}
            </Button>
          </Tooltip>
        ) : (
          <Button
            size="large"
            type="primary"
            loading={submitting}
            disabled={disabledQuote}
            onClick={() => submitOrder(!isTotalRfq)}
          >
            {isTotalRfq
              ? i18nFormat('Request a Quote')
              : i18nFormat('Continue to Pay')}
          </Button>
        )}
      </div>
    </div>
  );
};
