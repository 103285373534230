import axios, { AxiosRequestHeaders } from 'axios';
import { v4 as uuidv4 } from 'uuid';

import * as S from '../../../../schema';
import { getUfcShopComposedApi } from '../../..';

// 用来避免多个上传冲突的情况，变为逐个上传
let isUploading = false;

export function uploadRequest({
  action,
  data,
  file,
  filename,
  headers,
  withCredentials = true,

  onError,
  onProgress,
  onSuccess,
  onUploadCompleted,
  addPrefix = false,
}: {
  action: string;
  data?: any;
  file: S.IdentifiedFile;
  filename: string;

  headers?: Record<string, unknown>;
  withCredentials?: boolean;
  onError?: (...args: any[]) => void;
  onProgress?: (...args: any[]) => void;
  onSuccess?: (...args: any[]) => void;
  onUploadCompleted?: (...args: any[]) => void;
  addPrefix?: boolean;
}) {
  const controller = new AbortController();
  const formData = new FormData();

  // @ts-ignore
  delete data.callback;

  const { storeAuth, ...restData }: { storeAuth: S.FileStoreAuth } = data;
  const fileName = file.name;
  let storeKey = fileName;
  if (restData) {
    Object.keys(restData).forEach(key => {
      if (restData[key]) {
        formData.append(key, restData[key]);
      }
    });

    const uuid = uuidv4();
    const prefix = uuid.substring(uuid.lastIndexOf('-') + 1);

    // 如果是 Oss，则添加额外的 key 信息，这里不使用默认文件名，而使用自定义文件名
    if (action.includes('aliyuncs.com')) {
      storeKey = storeAuth.getStoreKey(
        `${fileName}`,
        '',
        addPrefix ? prefix : '',
      );
      formData.delete('key');
      formData.append('key', storeKey);
    }
  }

  // 这个 filename，指的的上传的字段名，一般为 file
  formData.append(filename, file);

  const doUpload = () => {
    isUploading = true;

    const uploadAgent = axios.create({ timeout: 30 * 60 * 1000 });

    // 需要注意的是，这里的 uploadAgent 不能包含 Authorization 头
    const agent = action.includes('aliyuncs.com')
      ? uploadAgent
      : getUfcShopComposedApi().axiosAgent;

    agent
      .post(action, formData, {
        withCredentials,
        headers: headers as AxiosRequestHeaders,
        responseType: 'text',
        signal: controller.signal,
        onUploadProgress: ({ total, loaded }) => {
          if (onProgress) {
            onProgress({ percent: Math.round((loaded / total) * 100) }, file);
          }
        },
      })
      .then(() => {
        file.storeKey = storeKey;

        if (onSuccess) {
          onSuccess(file);
        }

        if (onUploadCompleted) {
          onUploadCompleted({ uid: file.uid, storeKey });
        }
      })
      .catch(err => {
        if (onError) {
          onError(S.get(err, err => err.response.data.err.reason || err, err));
        }
      })
      .finally(() => {
        isUploading = false;
      });
  };

  const wakeAndMonitor = () => {
    if (!isUploading) {
      doUpload();
    } else {
      setTimeout(() => {
        wakeAndMonitor();
      }, 1 * 1000);
    }
  };

  if (!isUploading) {
    doUpload();
  } else {
    wakeAndMonitor();
  }

  return {
    abortController: controller,
  };
}
