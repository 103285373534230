import { create } from 'zustand';

interface CNCOptions {
  options: any;
  setOpts: any;
}

const useCNCOptions = create<CNCOptions>(set => ({
  options: {},
  setOpts(opts) {
    set({
      options: opts,
    });
  },
}));

export default useCNCOptions;
