import {
  get,
  i18nFormat,
  OverseaOrderStatus,
} from '@unionfab/ufc-shop-commons';
import { Pagination, Spin, Typography } from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import { useEffect, useMemo } from 'react';

import {
  loadOrderCountsInOrderListStore,
  loadOrdersInOrderListStore,
  setQueryParamsInOrderListStore,
  useOrderListStore,
} from '@/stores';

import { CreateTimePicker, SelectInput } from '../../components';
import { OrdersTable } from '../../components/OrdersTable';
import { StatusTabs } from '../../components/OrderStatusTab';
import styles from './index.module.less';

export const OrderHistoryList = () => {
  const {
    orderList,
    orderCounts,
    orderPagination,
    isOrderListLoading,
    queryParams,
  } = useOrderListStore(o => ({
    orderList: o.orderList,
    queryParams: o.queryParams,
    orderCounts: o.orderCounts,
    isOrderListLoading: o.isLoading,
    orderPagination: o.orderPagination,
  }));

  useEffect(() => {
    onRefresh();
  }, []);

  const onRefresh = () => {
    loadOrdersInOrderListStore();
    loadOrderCountsInOrderListStore();
  };

  const setSearchText = (searchText: string) => {
    if (isNil(searchText) || searchText == '') {
      setQueryParamsInOrderListStore({
        ...queryParams,
        pageNum: 1,
        searchText: null,
      });
    } else {
      useOrderListStore.setState({
        queryParams: { ...queryParams, searchText, pageNum: 1 },
      });
    }
  };

  const { sortBy, startDateTime, endDateTime, orderStatus } = queryParams;

  const OrderPagination = useMemo(() => {
    const pageNum = get(queryParams, q => q.pageNum, 1);

    return (
      <Pagination
        size="small"
        showSizeChanger
        current={pageNum}
        defaultPageSize={3}
        className={styles.pagination}
        pageSizeOptions={[3, 10, 15, 20]}
        total={get(orderPagination, o => o.totalElements, 0)}
        onChange={(pageNum, pageSize) => {
          setQueryParamsInOrderListStore({
            ...queryParams,
            pageNum,
            pageSize,
          });
        }}
      />
    );
  }, [orderPagination]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography.Title level={3}>{i18nFormat('Orders')}</Typography.Title>
        <div className={styles.selectorLine}>
          <CreateTimePicker
            sort={sortBy}
            date={
              startDateTime && endDateTime
                ? [dayjs(startDateTime), dayjs(endDateTime)]
                : null
            }
            setSortType={sortBy =>
              setQueryParamsInOrderListStore({ ...queryParams, sortBy })
            }
            setDate={([start, end]) => {
              const endDateTime = end ? end.endOf('D').format() : null;
              const startDateTime = start ? start.startOf('D').format() : null;

              setQueryParamsInOrderListStore({
                ...queryParams,
                startDateTime,
                endDateTime,
                pageNum: 1,
              });
            }}
          />
          <SelectInput
            onSearch={onRefresh}
            setSearchText={setSearchText}
            searchText={queryParams.searchText || ''}
          />
        </div>
      </div>

      <div className={styles.content}>
        <StatusTabs
          statusStats={orderCounts}
          activeKey={orderStatus}
          onTabsChange={(orderStatus: string) =>
            setQueryParamsInOrderListStore({
              ...queryParams,
              pageNum: 1,
              orderStatus: orderStatus as OverseaOrderStatus,
            })
          }
        />
        <div className={cn(isOrderListLoading && styles.blur)}>
          <OrdersTable
            isQuoteTable={false}
            orders={orderList || []}
            className={styles.ordersTable}
            loadOrders={onRefresh}
          />
          {OrderPagination}
          <Spin className={styles.spin} spinning={isOrderListLoading} />
        </div>
      </div>
    </div>
  );
};
