import { i18nFormat } from '@unionfab/ufc-shop-commons';
import * as S from '@unionfab/ufc-shop-commons';
import { Popover, Typography } from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs';
import { useMemo } from 'react';

import styles from './index.module.less';

export const OrderStatus = ({
  order,
  className,
}: {
  order: S.ShopOrder;
  className?: string;
}) => {
  const overseaOrderStatus: S.OverseaOrderStatus | null = useMemo(
    () => S.getOverseaOrderStatusByInquiryOrderStatus(order.status),
    [order],
  );

  const shouldLoadShipmentAt = useMemo(() => {
    return (
      overseaOrderStatus &&
      (overseaOrderStatus == 'PENDING' ||
        overseaOrderStatus == 'PRINTING' ||
        overseaOrderStatus == 'TOSHIP')
    );
  }, [overseaOrderStatus]);

  const shipmentAt = useMemo(() => {
    if (!shouldLoadShipmentAt) return <></>;

    const shipmentTime = S.get(
      order,
      o => o.delivery.capabilities.estimatedShipmentAt,
    );

    return (
      <span className={styles.shipmentAt}>
        {i18nFormat('Start Shipment:')}
        {shipmentTime ? dayjs(shipmentTime).format('MM/DD YYYY') : '-'}
      </span>
    );
  }, [order, overseaOrderStatus]);

  const shipInfo = useMemo(() => {
    if (overseaOrderStatus == 'SHIPPED') {
      const expressInfos = S.get(order, o => o.delivery.logistics.items, []);

      const expressCompany = expressInfos.map(e => e.expressName).join(',');
      const expressNumber = expressInfos.map(e => e.expressNumber).join(',');

      const content = (
        <div className={styles.orderStatusDeliverMethod}>
          <div>
            <span>{i18nFormat('Shipping Method')}:</span>&nbsp;
            <span>{expressCompany}</span>
          </div>
          <div>
            <span>Tracking Number:</span>&nbsp;
            <span>{expressNumber}</span>
          </div>
        </div>
      );

      return (
        <Popover trigger="hover" content={content}>
          <span className={styles.shipInfo}>
            {i18nFormat('Logistics Information')}
          </span>
        </Popover>
      );
    }

    return <></>;
  }, [order, overseaOrderStatus]);

  const canceledReason = useMemo(() => {
    if (overseaOrderStatus == 'CANCELED') {
      const reason = i18nFormat(S.get(order, o => o.cancelReason.remark, ''));

      if (reason == '') return <></>;

      return (
        <div className={styles.closeReason}>
          <Popover content={reason} overlayInnerStyle={{ maxWidth: '500px' }}>
            <Typography.Paragraph
              ellipsis={{ rows: 2 }}
              style={{ color: '#9A9A9C' }}
            >
              {`${i18nFormat('Reason')}: ${i18nFormat(reason)}`}
            </Typography.Paragraph>
          </Popover>
        </div>
      );
    }
  }, [order, overseaOrderStatus]);

  return (
    <span className={cn(styles.orderStatus, className)}>
      {i18nFormat(S.getOrderShopStatusByInquiryOrderStatus(order.status))}
      {shipmentAt}
      {shipInfo}
      {canceledReason}
    </span>
  );
};
