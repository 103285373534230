import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { InputNumber } from 'antd';

import { useInquiryQuoteEditorStore } from '@/stores';

import styles from './index.module.less';

export const OrderItemCount = ({
  orderItem,
  showLabel,
}: {
  orderItem: S.InquiryOrderItem;
  showLabel?: boolean;
}) => {
  showLabel = showLabel == null ? true : showLabel;

  const { updateInquiryOrder } = useInquiryQuoteEditorStore();
  const printFile = orderItem.printFiles[0];
  const changeCount = (count: number | null) => {
    updateInquiryOrder('changeOrderItemCount', () => {
      orderItem.printFiles[0].printCount = count == null ? 1 : count;
    });
  };

  return (
    <span className={styles.orderItemCount}>
      {showLabel && (
        <span className={styles.label}>{i18nFormat('Quantity')}:</span>
      )}
      <span className={styles.orderItemCountNum}>
        <InputNumber
          min={1}
          size="small"
          value={printFile.printCount}
          onChange={changeCount}
        />
      </span>
    </span>
  );
};
