import { BaseEntity } from '../../../common';
import { User } from '../../../user';

export class DeliverLeadTime extends BaseEntity<DeliverLeadTime> {
  name: string;
  enName: string;

  get fullName(): string {
    return `${this.name} (${this.enName})`;
  }

  /** 排序参数 */
  seq: number;

  /** 天数 */
  amount: number;

  active: boolean;

  createdAt: string;
  creatorId: string;
  creator: User;

  constructor(props?: Partial<DeliverLeadTime>) {
    super(props);

    Object.assign(this, props);

    if (props.creator) {
      this.creator = new User(props.creator);
    }
  }
}
