import { CacheableBatchDataLoader } from '@m-fe/ts-async';
import dayjs from 'dayjs';

import * as S from '../../../../schema';
import { getUfcShopComposedApi } from '../../../../singleton-ins';
import {
  AliyunSlsApmTrackerUtils,
  ApmEventTrackRecord,
} from '../../../vendor-apis/aliyun-apm';

/** 根据 fileId 获取到当前处理信息 */
export async function getD3ModelProcessInfoById(fileId: S.Id) {
  const resp = await getD3ModelProcessAttributeMapDataLoader.loadByKeys({
    keys: [fileId],
  });

  return (resp || {})[fileId];
}

const getD3ModelProcessAttributeMapDataLoader = new CacheableBatchDataLoader<
  string,
  S.D3ModelProcessInfo
>(getD3ModelProcessInfoMap, {
  instantiateObj: v => new S.D3ModelProcessInfo(v),
  cacheOptions: { maxAgeInSeconds: 5 * 1000 },
});

/** 批量获取 模型文件的解析属性 */
export async function getD3ModelProcessInfoMap(
  fileIds: string[],
): Promise<Record<S.Id, S.D3ModelProcessInfo>> {
  const ids = (fileIds || []).filter(id => !!id);

  if (ids.length < 1) {
    return {} as Record<S.Id, S.D3ModelProcessInfo>;
  }

  const {
    data,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.postUmi<{
    data: Record<S.Id, S.D3ModelProcessInfo>;
  }>(`/v2/shop/file/d3_model/attributes`, {
    data: { fileIds: (fileIds || []).filter(id => !!id) },
  });

  const res: Record<S.Id, S.D3ModelProcessInfo> = {} as Record<
    S.Id,
    S.D3ModelProcessInfo
  >;

  Object.keys(data).forEach(key => {
    res[key] = new S.D3ModelProcessInfo({ ...data[key] });
  });

  return res;
}

/**
 * 异步处理模型文件
 * @param fileId 文件id
 * @param format 文件后缀
 * @param readRawStlAttribute 读取原始 Stl 文件的属性
 * @param zlibCompressed 压缩
 * @param createThumbnail 创建缩略图
 * @returns
 */
export async function submitD3ModelToAsyncProcess({
  fileIds,
  format,
  readRawStlAttribute,
  zlibCompressed,
  createThumbnail,
}: {
  fileIds: S.Id[];
  format: string;
  readRawStlAttribute: boolean;
  zlibCompressed: boolean;
  createThumbnail: boolean;
}): Promise<boolean> {
  const {
    status,
  } = await getUfcShopComposedApi().umiRequestCompatibleApi.postUmi<{
    status: string;
  }>(`/v2/shop/file/d3_model/async_process`, {
    data: {
      fileIds,
      format,
      readRawStlAttribute,
      zlibCompressed,
      createThumbnail,
    },
  });

  return status === 'ok';
}

/** 一站式进行异步模型处理 */
export async function processModelAsyncAndWaitInServer(
  modelFile: S.D3ModelFile,
  {
    skipSubmit,
    onProgress,
  }: {
    skipSubmit?: boolean;
    /** 汇报解析进度 */
    onProgress?: (processInfo) => { isCancelled?: boolean };
  } = {},
): Promise<S.D3ModelFile> {
  const fileId = modelFile.id;

  const needDecompress = modelFile.attr.compressType === 'zlib';

  console.log(
    '>>>processModelAsyncAndWaitInServer>>>fileId: ',
    fileId,
    '>>>skipSubmit>>>',
    skipSubmit,
  );

  return new Promise(async (resolve, reject) => {
    try {
      const startAt = dayjs();
      const trackRecord: Partial<ApmEventTrackRecord> = {
        event_type: 'process-file',
        attr_file_id: fileId,
        attr_file_name: modelFile.attr.name,
        attr_file_size: modelFile.attr.size,
        start_at: startAt.format(),
        start_at_unix_timestamp_milliseconds: startAt.valueOf(),
      };

      const onFinishReport = (status: 'success' | 'error', msg?: string) => {
        const finishAt = dayjs();
        trackRecord.finish_at = dayjs().format();
        trackRecord.finish_at_unix_timestamp_milliseconds = dayjs().valueOf();
        trackRecord.event_result_status = status;
        trackRecord.event_result_msg = msg;
        trackRecord.elapse_milliseconds = finishAt.diff(startAt, 'millisecond');

        AliyunSlsApmTrackerUtils.getAliyunSlsApmTracker().sendEventRecord(
          trackRecord,
        );
      };

      if (!skipSubmit) {
        const isSubmitSuccess = await submitD3ModelToAsyncProcess({
          createThumbnail: true,
          fileIds: [modelFile.id],
          readRawStlAttribute: true,
          format: modelFile.attr.type,
          zlibCompressed: needDecompress ? true : false,
        });

        if (!isSubmitSuccess) {
          onFinishReport('error', 'submit error');
          reject('Submit async process error');
          return;
        }
      }

      // 每 2.5s 轮询一次，最多轮询 500 次
      for (let i = 0; i < 500; i++) {
        await S.sleep(2.5 * 1000);

        // 获取属性
        // const processInfo = await getD3ModelProcessInfoById(fileId);
        const processInfo = ((await getD3ModelProcessInfoMap([fileId])) || {})[
          fileId
        ];

        processInfo && processInfo.applyToD3FormatAttr(modelFile.attr);

        // 判断是否处理失败
        if (processInfo && processInfo.isFailed) {
          onFinishReport('error', 'process failed');
          reject('Process failed');
          return;
        }

        // 基本上都判断 thumbnail 是否获取到，如果获取到则判断 OK
        if (processInfo && processInfo.isSuccess) {
          onFinishReport('success', '');
          resolve(modelFile);
          return;
        }

        // 还在处理中则返回信息
        const { isCancelled } = onProgress(processInfo);

        if (isCancelled) {
          // 如果被终止，则直接进入终止程序；
          // TODO: 是否需要调用服务端终止程序
          onFinishReport('error', 'process cancelled');
          reject('Process cancelled');
          return;
        }
      }

      onFinishReport('error', 'process timeout');
      reject('Process timeout');
    } catch (_) {
      console.error('>>>processModelAsyncAndWaitInServer>>>error: ', _);
    }
  });
}
