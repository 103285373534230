import { BaseEntity, Id } from '../../common';
import {
  isValidArray,
  stringifyWithCircularRef,
  toFixedNumber,
} from '../../utils';
import { InquiryMaterialSku } from '../material';
import { ThinInquiryOrder } from './InquiryOrder';
import { InquiryPrintFile } from './InquiryPrintFile';

export class InquiryOrderItem extends BaseEntity<InquiryOrderItem> {
  get isDraft() {
    return !this.id || this.id.includes('new');
  }

  // 关联的询价单编号
  orderId: Id;
  thinInquiryOrder: ThinInquiryOrder; // 这里由创建的时候动态传入的简单的 InquiryOrder 对象

  tenantId: Id;
  remark: string; // 备注
  remarkFileIds: Id[];

  itemRemark: { remark: string; fileIds: Id[] };

  spuId: Id;
  materialSpu: InquiryMaterialSku;
  printFileIds: Id[];
  printFiles: InquiryPrintFile[];

  /** 合并报价相关 */
  rowSpan: number; // 用于单元格合并
  get isMerge() {
    return this.offerType === 'MERGE_OFFER';
  }
  offerType: 'COMMON_OFFER' | 'MERGE_OFFER'; // 报价类型，普通报价或者合并报价
  offerNumber: number; // 合并报价的顺序

  /** 总的打印套数 */
  get printCount() {
    return this.printFiles.reduce((prev, cur) => prev + cur.printCount, 0);
  }

  /** 总的打印件数 */
  get partCount() {
    return this.printFiles.reduce((prev, cur) => prev + cur.partCount, 0);
  }

  // 判断某个询价单项目是否为非空，非空的意思是包含打印数目不为零的文件
  get isBlank() {
    for (const f of this.printFiles || []) {
      if (f.printCount > 0) {
        return false;
      }
    }

    return true;
  }

  get searchStr() {
    return `${this.materialSpu.name}-${this.remark}-${(this.printFiles || [])
      .map(p => p.modelFile.name)
      .join(',')}`;
  }

  get modelFiles() {
    return this.printFiles.map(p => p.modelFile);
  }

  get maxModelSideLength() {
    return Math.max(
      ...this.modelFiles
        .map(f => [f.attr.sizeX, f.attr.sizeY, f.attr.sizeZ])
        .flatMap(i => i),
      0, // 保证不会小于 0
    );
  }

  // 条目总价
  correctionPrice: number;

  /** 订单子项的材料费预估 */
  get finalPrice() {
    let res = 0;

    // 如果总打印数目为 0，则返回的价格也就是 0
    if (this.printCount === 0) {
      return 0;
    }

    this.printFiles &&
      this.printFiles.forEach((printFile: InquiryPrintFile) => {
        res += printFile.finalPrice;
      });

    // 注意，这里不再进行起步价的限制
    return toFixedNumber(res, 2);
  }

  clone() {
    return new InquiryOrderItem(JSON.parse(stringifyWithCircularRef(this)));
  }

  constructor(
    data?: Partial<InquiryOrderItem> & { thinInquiryOrder: ThinInquiryOrder },
  ) {
    super(data);

    Object.assign(this, data);

    if (!this.offerType) {
      // 默认普通报价
      this.offerType = 'COMMON_OFFER';
    }

    if (this.rowSpan === undefined || this.rowSpan === null) {
      this.rowSpan = 1;
    }

    this.materialSpu = new InquiryMaterialSku(this.materialSpu || {});

    // 构建包含的 PrintFile 文件
    this.printFiles = (this.printFiles || []).map(
      f =>
        new InquiryPrintFile({
          ...f,
          // 这里补齐 MaterialSpu 信息以及订单信息
          materialSpu: this.materialSpu,
          correctionPrice: this.correctionPrice,
          thinInquiryOrder: this.thinInquiryOrder,

          getInquiryOrderItemRef: () => this,
        }),
    );

    // 规避 ID 列表为空的情况
    if (isValidArray(this.printFiles)) {
      this.printFileIds = this.printFiles.map(p => p.fileId);
    }

    if (!this.thinInquiryOrder) {
      console.error(
        '>>>InquiryOrderItem>>>constructor>>>thinInquiryOrder is undefined',
      );
    }
  }
}
