import { BaseEntity, Id } from '../../common';
import { Tenant } from '../../user/tenant/Tenant';
import { formatDate } from '../../utils';
import { Person } from './Person';
import { UserConfig } from './UserConfig';

export type UserAuthority = 'SYS_ADMIN' | 'TENANT_USER' | 'TENANT_ADMIN';

export class User extends BaseEntity<User> {
  get name() {
    if (this.person && this.person.nickname) {
      return this.person.nickname;
    }

    return this.nickname || this.username;
  }

  get creationTime() {
    if (!this.createdAt) {
      return '-';
    }

    return formatDate(this.createdAt);
  }

  avatarUrl?: string;
  creator?: Person;

  get creatorNickname() {
    if (!this.creator) {
      return '-';
    }

    return this.creator.nickname;
  }

  remark: string;
  creatorId: number;
  tenant: Tenant;
  credentials?: UserCredentials;
  nickname?: string;
  username?: string;

  englishName?: string;

  // 基础权限
  authority: UserAuthority = 'TENANT_USER';
  // 自定义角色
  roleIds: Id[];

  // 关联的自然人信息
  person: Person;
  personId?: Id;

  // 扩展信息，目前由个人配置接口存放，为了兼容，保留 jobPosition
  jobPosition: string;
  userConfig: UserConfig = new UserConfig({});

  /** 是否禁用, 明确为 false 时，才表示为已禁用 */
  enable?: boolean;

  constructor(data: Partial<User> = {}) {
    super(data);

    Object.assign(this, data);

    const { tenant, person } = this;

    this.tenant = new Tenant(tenant);
    this.person = new Person(person);

    if (!this.avatarUrl) {
      this.avatarUrl = 'https://s1.ax1x.com/2020/08/12/ajKkCR.png';
    }
  }
}

/** 新版登录选择客户的信息 */
export interface CustomerIdentity {
  id: string;
  type: 'ENTERPRISE' | 'PERSON';

  name?: string;
  companyName?: string;

  email: string;
  taxNumber: string;
  phoneNumber: string;
  address: string;
}

/** 用户登录凭证 */
export class UserCredentials extends BaseEntity<UserCredentials> {
  userId: number;
  isEnabled: number;
  activateToken: string;
  password: string;
  resetToken: string;

  constructor(props?: Partial<UserCredentials>) {
    super(props);

    Object.assign(this, props);
  }
}

export class UserRoleRelation extends BaseEntity<UserRoleRelation> {
  userId: number;
  roleId: number;
  isDisabled: number;

  constructor(props?: Partial<UserRoleRelation>) {
    super(props);

    Object.assign(this, props);
  }
}

export class UserPermissionRelation extends BaseEntity<UserPermissionRelation> {
  userId: number;
  permissionId: number;
  isDisabled: number;
  creatorId: number;

  constructor(props?: Partial<UserPermissionRelation>) {
    super(props);

    Object.assign(this, props);
  }
}

export interface SearchUser {
  pageNum: number;
  pageSize: number;
  searchText?: string;
  roleId?: string;
  withDisabled?: boolean;
  enable?: boolean;
}

/** 匿名用户文件解析次数 */
export interface AnonymousUserQuotaInfo {
  withinDay: {
    modelProcess: {
      inProcessCount: number;
      processedCount: number;
      failedCount: number;
    };
  };
}
