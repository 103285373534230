import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Tooltip, Typography } from 'antd';
import cn from 'classnames';

import styles from './index.module.less';

const { Paragraph, Text } = Typography;

export interface AddressTextProps {
  address: S.ReceiveAddress;

  className?: string;
}

export const ShopOrderAddressCard = ({
  className,
  address,
}: AddressTextProps) => {
  const recipient = S.formatReceiverName(address);
  const companyName = S.get(address, a => a.companyName);

  const country = S.get(address, a => a.country);
  const phoneNumber = S.get(address, a => a.phoneNumber);

  const street = S.get(address, a => a.street);
  const province = S.get(address, a => a.province);
  const detail = S.get(address, a => a.detail);
  const city = S.get(address, a => a.city);

  const receiverText = [recipient, companyName].filter(v => !!v).join(' - ');
  const postcode = S.get(address, a => a.postcode);
  const addressLine = [street, detail, city, province, postcode]
    .filter(v => !!v)
    .join(', ');

  return (
    <div className={cn(styles.receiveAddressCard, className)}>
      <Paragraph
        ellipsis={{ rows: 2, tooltip: addressLine }}
        className={styles.receiveAddressCardReceiver}
      >
        {receiverText}
      </Paragraph>

      {addressLine && (
        <Paragraph
          ellipsis={{
            rows: 2,
            expandable: false,
            symbol: 'more',
            tooltip: addressLine,
          }}
          className={styles.receiveAddressCardInfo}
        >
          {addressLine}
        </Paragraph>
      )}
      {country && (
        <Paragraph className={styles.receiveAddressCardInfo}>
          {country}, {phoneNumber}
        </Paragraph>
      )}
    </div>
  );
};
