import * as S from '@unionfab/ufc-shop-commons';
import { useCloseOrderStore } from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Button, Input, Modal, Radio, Space } from 'antd';
import { ButtonType } from 'antd/lib/button/buttonHelpers';
import * as React from 'react';

import { Ellipsis } from '../../../../commons/components/Element/ellipsis';

export const OrderCloseButton = ({
  className,
  order,
  onClose,
  buttonType = 'ghost',
}: {
  className?: string;
  order: S.ShopOrder;
  style?: React.CSSProperties;
  buttonType?: ButtonType;

  onClose?: () => void;
}) => {
  const { status, code } = order;
  const { toggleClosing, code: closeCose } = useCloseOrderStore();

  const isQuote = status === 'WAIT_SUBMIT';
  const btnContent = isQuote ? 'Delete' : 'Cancel Order';
  const canCloseOrder = [
    'WAIT_SUBMIT',
    'WAIT_REVIEW',
    'WAIT_CUSTOM_CONFIRM',
  ].includes(status);

  const onBtnClick = () => {
    toggleClosing(code, status === 'WAIT_SUBMIT');
  };

  return (
    <>
      <Button
        className={className}
        type={buttonType}
        disabled={!canCloseOrder}
        onClick={onBtnClick}
      >
        <Ellipsis maxWidth={88}>{i18nFormat(btnContent)}</Ellipsis>
      </Button>
      {closeCose && closeCose == code && <CloseOrderModal onClose={onClose} />}
    </>
  );
};

OrderCloseButton.displayName = 'OrderCloseButton';

const CloseOrderModal = ({ onClose }: { onClose?: () => void }) => {
  const {
    code,
    toggleClosing,
    availableReasons,
    standardReason,
    setStandardReason,
    customReason,
    setCustomReason,
    close,
  } = useCloseOrderStore();

  return (
    <Modal
      open={!!code}
      onOk={() => close(onClose)}
      onCancel={() => toggleClosing(undefined)}
      title={i18nFormat('Select the Reason.')}
      destroyOnClose={true}
    >
      <Space size={12} direction="vertical">
        {availableReasons.map(reason => (
          <div key={reason} style={{ display: 'flex', alignItems: 'center' }}>
            <Radio
              value={reason}
              checked={standardReason === reason}
              onClick={() => setStandardReason(reason)}
            >
              {i18nFormat(reason)}
            </Radio>

            {reason === 'Other(s)' && (
              <Input
                style={{ width: 340 }}
                placeholder={i18nFormat('Please enter your reason.')}
                value={customReason}
                onChange={e => setCustomReason(e.target.value)}
              />
            )}
          </div>
        ))}
      </Space>
    </Modal>
  );
};
