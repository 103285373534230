import {
  getUseShopQuoteStoreByCode,
  i18nFormat,
  ShopOrderQuoteStoreModifiers,
  useBluePrintRequiredStore,
} from '@unionfab/ufc-shop-commons';
import { Form, Input, Select, Typography } from 'antd';
import { ChangeEvent, useEffect } from 'react';

import useCNCOptions from '../../../../../../../../../ufc-shop-commons/src/stores/quote/shop-order-quote/useCNCOptions';
import { ShopQuoteItemGrayHint } from '../ShopQuoteItemGrayHint';
import { ShopQuoteItemNotesProps } from '../ShopQuoteItemNotes';
import styles from './index.module.less';

export function ShopQuoteItemTolerance({
  orderCode,
  itemId,
}: ShopQuoteItemNotesProps) {
  const { options: TOLERANCES_OPTS = [] } =
    useCNCOptions(s => s.options).tolerance || {};
  const item = getUseShopQuoteStoreByCode(orderCode)(s =>
    (s.shopOrderDraft.items || []).find(i => i.id === itemId),
  );
  const setStatus = useBluePrintRequiredStore((s: any) => s.setStatus);
  /** 已经上传了蓝图吗 */
  const hasBlueprint = (item?.remarkFileVO || []).length > 0;

  const antdSelectOpts = TOLERANCES_OPTS.map((opt, idx) => ({
    label: opt,
    value: idx,
  }));
  const originValId = TOLERANCES_OPTS.indexOf(
    item?.cncRequirements?.tolerance?.option,
  );

  const formValId = originValId < 0 ? undefined : originValId;

  useEffect(() => {
    const option = item?.cncRequirements?.tolerance?.option;
    const idx = TOLERANCES_OPTS.findIndex(opt => opt === option);
    setStatus(itemId + 'tolerance', idx > 0);
  }, [item]);

  const onChange = (val: number) => {
    ShopOrderQuoteStoreModifiers.updateOrderDraft(orderCode, o => {
      const item = o.items.find(i => i.id === itemId);
      item.cncRequirements = {
        ...item.cncRequirements,
        tolerance: {
          ...item.cncRequirements?.tolerance,
          option: TOLERANCES_OPTS[val],
        },
      };

      if (val == null) delete item.cncRequirements.tolerance;

      if (val < 3 && item?.cncRequirements?.tolerance?.otherRemark != null) {
        delete item.cncRequirements.tolerance.otherRemark;
      }
    });
  };

  /** 是否选中了 Other，选中则需要在输入框中填写具体值 */
  const isOtherOpt = item?.cncRequirements?.tolerance?.option === 'Other';

  const blurIpt = (e: ChangeEvent<HTMLInputElement>) => {
    const iptVal = e?.target?.value;
    const hasVal = iptVal != null;
    if (hasVal) {
      ShopOrderQuoteStoreModifiers.updateOrderDraft(orderCode, o => {
        const item = o.items.find(i => i.id === itemId);
        item.cncRequirements = {
          ...item.cncRequirements,
          tolerance: {
            ...item.cncRequirements?.tolerance,
            otherRemark: iptVal || '',
          },
        };
      });
    }
  };

  return (
    <>
      <div className={styles.shopQuoteItemToleranceTitle}>
        <Form.Item
          rules={[
            {
              required: !hasBlueprint,
              message: i18nFormat('Tolerance is required.'),
            },
          ]}
          name="tolerance"
          label={
            <Typography.Title level={5}>
              {i18nFormat('Tolerance')}
            </Typography.Title>
          }
          initialValue={formValId}
        >
          <Select
            allowClear
            placeholder={i18nFormat('Tap to Select')}
            style={{ maxWidth: 220 }}
            value={formValId}
            onChange={onChange}
            options={antdSelectOpts}
          />
        </Form.Item>
        {isOtherOpt && (
          <Form.Item
            style={{ maxWidth: 220 }}
            name="tolerance-other"
            rules={[
              { required: true, message: i18nFormat('Tolerance is required.') },
            ]}
            initialValue={item?.cncRequirements?.tolerance?.otherRemark}
          >
            <Input
              placeholder={i18nFormat('Please input Tolerance value')}
              onBlur={blurIpt}
            />
          </Form.Item>
        )}
        <ShopQuoteItemGrayHint>
          {i18nFormat(
            'Tolerances will be held to +/-.005"(+/-.127mm) in accordance with ISO 2768 unless otherwise specified.',
          )}
        </ShopQuoteItemGrayHint>
      </div>
    </>
  );
}

ShopQuoteItemTolerance.displayName = 'ShopQuoteItemTolerance';
