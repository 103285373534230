import dayjs from 'dayjs';
import { create, GetState, StoreApi, UseBoundStore } from 'zustand';
import { devtools, NamedSet } from 'zustand/middleware';

import {
  getInquiryCouponByRedeemKey,
  getTenantIdFromGConfig,
  getUfcShopComposedApi as api,
} from '../../apis';
import { isOnlyMetalType } from '../../schema';
import * as S from '../../schema';

export interface InquiryCouponState {
  /** 优惠券列表 */
  coupons: S.InquiryCoupon[];

  newCouponInfo: { count: number; hasNew: boolean };

  loadInquiryCoupons: () => Promise<S.InquiryCoupon[]>;

  checkNewCoupon: () => Promise<void>;

  checkCouponWithoutShipping: (o: {
    orderPrice: number;
    coupon: S.InquiryCoupon;
    order: S.InquiryOrder;
  }) => string | undefined;

  redeemInquiryCoupon: (redeemKey: string) => Promise<string>;
}

interface Store {
  set: NamedSet<InquiryCouponState>;
  get: GetState<InquiryCouponState>;
}

export const useInquiryCouponStore: UseBoundStore<
  StoreApi<InquiryCouponState>
> = create<InquiryCouponState>()(
  devtools(
    (set, get) => {
      const s: Store = { set, get };

      return {
        coupons: [],
        newCouponInfo: { count: 0, hasNew: false },
        checkCouponWithoutShipping: o => checkCouponWithoutShipping(o),
        loadInquiryCoupons: async () => await loadInquiryCoupons(s),
        checkNewCoupon: async () => await checkNewCoupon(s),
        redeemInquiryCoupon: async redeemKey =>
          await _redeemInquiryCoupon(s, redeemKey),
      };
    },
    { name: 'inquiryCouponStore' },
  ),
);

const _redeemInquiryCoupon = async (s: Store, redeemKey: string) => {
  const newCouponCode = await getInquiryCouponByRedeemKey(
    getTenantIdFromGConfig(),
    redeemKey,
  );

  /** 重新加载优惠券列表 */
  await loadInquiryCoupons(s);

  return newCouponCode;
};

const loadInquiryCoupons = async (s: Store) => {
  const coupons = await api().userApi.getAccountCoupon();

  s.set({ coupons });

  return coupons;
};

const checkNewCoupon = async (s: Store) => {
  /** 重新获取最新数据 */
  const coupons = await s.get().loadInquiryCoupons();

  let count = 0;
  let hasNew = false;

  for (const coupon of coupons || []) {
    if (
      coupon.status === 'UNUSED' &&
      dayjs().isBefore(dayjs(coupon.startTime).add(5, 'd'))
    ) {
      count += 1;

      if (!hasNew) {
        hasNew = true;
      }
    }
  }

  s.set({ coupons, newCouponInfo: { count, hasNew } });
};

const checkCouponWithoutShipping = ({
  coupon,
  orderPrice,
  order,
}: {
  orderPrice: number;
  coupon: S.InquiryCoupon;
  order: S.InquiryOrder;
}) => {
  if (!coupon.code) {
    return 'The coupon code is incorrect.';
  }

  if (coupon.status === 'USED') {
    return 'The coupon code has been used.';
  }

  if (coupon.status === 'EXPIRED') {
    return 'The coupon code is expired.';
  }

  if (!orderPrice) {
    return 'Order price is not available';
  }

  if (coupon.type === 'AMOUNT_OFF' && coupon.amountOffWithAmount > orderPrice) {
    return `This order's price is less than $ ${coupon.amountOffWithAmount}.`;
  }

  if (coupon.type === 'FREE_PRINTING' && orderPrice > 50) {
    return `Coupons don't meet usage requirement.`;
  }

  if (coupon.type === 'FREE_PRINTING_FOR_REFERRAL' && orderPrice > 20) {
    return `Coupons don't meet usage requirement.`;
  }

  if (
    coupon.type === 'FREE_PRINTING_METAL' &&
    (orderPrice > 25 || !isOnlyMetalType(order.items || []))
  ) {
    return `Coupons don't meet usage requirement.`;
  }

  if (coupon.type === 'FREE_PRINTING_FOR_KIRI') {
    const deliveryAddress = S.get(order, o => o.deliver.deliverAddress, []);

    if (deliveryAddress.map(d => d.country).includes('China')) {
      return 'Overseas customers only (Excluding China)';
    }
  }
};
