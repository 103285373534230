import { UfcShopComposedApi } from './apis/shop-class-apis/composition';
import * as S from './schema';
import { UfcShopRuntimeEnv } from './schema';

let ufcShopRuntimeEnv: UfcShopRuntimeEnv = {} as UfcShopRuntimeEnv;

export function getUfcShopRuntimeEnv(): UfcShopRuntimeEnv {
  return ufcShopRuntimeEnv;
}

export function setUfcShopRuntimeEnv(partialEnv: Partial<UfcShopRuntimeEnv>) {
  ufcShopRuntimeEnv = { ...ufcShopRuntimeEnv, ...partialEnv };
}

/** 判断是否登录 */
export function hasUserLogined() {
  // Node 环境下，必须带 Auth 头请求
  if (typeof window === 'undefined') {
    return true;
  }

  return S.get(
    window,
    w => !!window.getRuntimeEnv().person || !!window.getRuntimeEnv().user,
    false,
  );
}

let ufcShopComposedApi: UfcShopComposedApi;

export function getUfcShopComposedApi() {
  return ufcShopComposedApi;
}

export function setUfcShopComposedApi(_api: UfcShopComposedApi) {
  ufcShopComposedApi = _api;
}

export function getAxiosAgent() {
  return ufcShopComposedApi.axiosAgent;
}

export function getTenantIdFromGConfig() {
  return (typeof window === 'undefined'
    ? undefined
    : S.get(
        window,
        // 这里为了兼容 UFS 与小程序端
        window => window.gConfig.tenantId,
      )) as S.Id;
}
