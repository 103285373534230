import { create, StoreApi, UseBoundStore } from 'zustand';
import { devtools } from 'zustand/middleware';

import { getUfcShopComposedApi } from '../../apis';
import * as S from '../../schema';

interface ProfileStoreState {
  loading: boolean;
  accountInfo: Partial<S.AccountInfo>;

  loadPersonInfo: () => Promise<S.AccountInfo>;
}

type ProfileStore = UseBoundStore<StoreApi<ProfileStoreState>>;

export const useProfileStore: ProfileStore = create<ProfileStoreState>()(
  devtools(
    set => {
      const loadPersonInfo = async () => {
        try {
          set({ loading: true });

          const accountInfo = await getUfcShopComposedApi().userApi.getAccountProfileInfo();

          set({ accountInfo, loading: false });

          return accountInfo;
        } catch (_e) {
          set({ loading: false });

          console.error('>>>useProfileStore>>>loadPersonInfo', _e);
        }
      };

      return {
        loading: false,
        accountInfo: new S.AccountInfo({}),

        loadPersonInfo,
      };
    },
    { name: 'profileStore' },
  ),
);
