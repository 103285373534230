import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Button, Divider, Empty, Space } from 'antd';
import cn from 'classnames';
import { CSSProperties, ReactNode, useMemo } from 'react';

import AddressIcon from '@/assets/svgs/addressFilled.svg';
import {
  getUseShopQuoteStoreByCode,
  ShopOrderQuoteStoreModifiers,
} from '@/stores';

import styles from './index.module.less';
import { ShopOrderAddressCard } from './ShopOrderAddressCard';

interface ShopOrderAddressSelectorProps {
  orderCode: string;
  defaultAddressList: S.ReceiveAddress[];

  extra?: ReactNode;
  className?: string;
  style?: CSSProperties;
  onSelectedAddress?: () => void;
  onEdit?: (a: S.ReceiveAddress) => void;
}

export const ShopOrderAddressSelector = ({
  className,
  style,
  orderCode,
  onEdit,
  defaultAddressList,
  onSelectedAddress,
}: ShopOrderAddressSelectorProps) => {
  const { shopOrderDraft, shopOrder, allowEdit } = getUseShopQuoteStoreByCode(
    orderCode,
  )(s => ({
    shopOrder: s.shopOrder,
    allowEdit: s.allowEdit,
    shopOrderDraft: s.shopOrderDraft,
  }));

  const draftOrderAddress = useMemo(() => {
    if (!shopOrderDraft) return null;

    if (S.isValidArray(S.get(shopOrderDraft, o => o.delivery.items))) {
      return shopOrderDraft.delivery.items[0];
    }
  }, [shopOrderDraft]);

  const finalAddressList = useMemo(() => {
    if (!S.isValidArray(defaultAddressList)) return [];

    const defaultAddress = S.get(
      shopOrder,
      o => o.delivery.items[0],
    ) as S.ShopOrderDeliveryItem;

    if (!defaultAddress) return defaultAddressList;

    /** 找出选中的地址 */
    const index = defaultAddressList.findIndex(address => {
      const d = S.ShopOrderDeliveryItemRequestParams.fromReceiveAddress(
        address,
      );
      return defaultAddress.isEqual(d);
    });

    if (index == -1) {
      return defaultAddressList;
    }

    return [defaultAddressList[index]].concat(
      defaultAddressList.filter((_d, i) => i !== index),
    );
  }, [shopOrder, defaultAddressList]);

  const onConfirm = (address: S.ShopOrderDeliveryItemRequestParams) => {
    ShopOrderQuoteStoreModifiers.updateOrder({
      orderCode,
      onChange: o => {
        o.delivery.items = [address];
      },
    });

    onSelectedAddress();
  };

  const renderAddressCard = (address: S.ReceiveAddress, idx) => {
    if (!address) return <></>;

    const d = S.ShopOrderDeliveryItemRequestParams.fromReceiveAddress(address);
    const checked = draftOrderAddress ? draftOrderAddress.isEqual(d) : false;

    return (
      <div key={idx} className={styles.addressCardWrapper}>
        <div className={styles.addressInfo}>
          <div className={styles.icon}>{checked && <AddressIcon />}</div>
          <ShopOrderAddressCard
            address={address}
            className={styles.addressContent}
          />
        </div>
        <div className={styles.actions}>
          <Button
            type="primary"
            disabled={!allowEdit}
            onClick={() => onConfirm(d)}
          >
            {i18nFormat('Confirm')}
          </Button>
          <Button
            type="link"
            disabled={!allowEdit}
            style={{ color: '#1890ff' }}
            onClick={() => onEdit && onEdit(address)}
          >
            {i18nFormat('Edit')}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className={cn(styles.container, className)} style={style}>
      {!S.isValidArray(finalAddressList) ? (
        <Empty description={i18nFormat('No address yet')} />
      ) : (
        <Space
          size={0}
          direction="vertical"
          style={{ width: '100%' }}
          split={<Divider style={{ margin: '12px 0' }} />}
        >
          {(finalAddressList || []).map(renderAddressCard)}
        </Space>
      )}
    </div>
  );
};

ShopOrderAddressSelector.displayName = 'ShopOrderAddressSelector';
